import React, { ErrorInfo } from 'react';
import curry from 'lodash/curry';

function errorHandler(
  errorCallback: ((error: Error, info: ErrorInfo, props: any) => void) | null,
  FallbackComponent?: any,
  Component?: any
): React.ComponentClass {
  return class WithErrorHandler extends React.Component {
    public displayName = `withErrorHandler(${Component ? Component.displayName : 'Component'})`;

    public state = {
      hasError: false,
      error: null,
      errorInfo: null
    };

    public componentDidCatch(error: Error, info: ErrorInfo) {
      this.setState({ hasError: true, error, errorInfo: info });

      if (errorCallback) {
        errorCallback(error, info, this.props);
      }
    }

    public render() {
      if (this.state.hasError) {
        const { error, errorInfo } = this.state;
        return <FallbackComponent {...this.props} error={error} errorInfo={errorInfo} />;
      }

      return <Component {...this.props} />;
    }
  };
}

export default curry(errorHandler);
