import * as React from 'react';
import styled from '@emotion/styled/macro';
import { StepIconProps } from '@mui/material/StepIcon';

import { IThemed } from 'utils/styled';
import CheckMark from './icons/step-complete.svg';

export const StepperIconSize = 16;

// TODO apply ui design
const FlrStepIcon = (props: StepIconProps) => {
  const { active, completed } = props;
  return <>{!!completed ? <StyledCircleComplete /> : !!active ? <StyledCircleActive /> : <StepperRootIcon />}</>;
};

const StepperRootIcon = styled<any>('div')(({ theme }: IThemed) => ({
  position: 'relative',
  width: StepperIconSize,
  height: StepperIconSize,
  border: `1px solid ${theme.palette.common.disabledBlack}`,
  margin: '0 8px',
  backgroundColor: theme.palette.common.disabledBlackBackground,
  borderRadius: '50%',

  '&:after': {
    position: 'absolute',
    top: 3,
    left: 3,
    width: StepperIconSize / 2,
    height: StepperIconSize / 2,
    display: 'block',
    content: "''",
    borderRadius: '50%',
    backgroundColor: theme.palette.common.disabledBlack
  }
}));

const StyledCircleActive = styled<any>(StepperRootIcon)(({ theme }: IThemed) => ({
  backgroundColor: theme.palette.common.primaryClickablePressBackground,
  borderColor: theme.palette.primary.main,
  '&:after': {
    backgroundColor: theme.palette.primary.main
  }
}));

const StyledCircleComplete = styled<any>(StepperRootIcon)(({ theme }: IThemed) => ({
  background: `${theme.palette.primary.main} url("${CheckMark}") center center no-repeat`,

  '&:after': {
    display: 'none'
  }
}));

export default FlrStepIcon;
