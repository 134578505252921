import { IFeatureFlag } from './feature-flag';
import { UserProfile } from './user-profile';

export type UserStatus = 'active' | 'deleted';
export type UserRole = 'admin' | 'user' | 'client';

export class User {
  public username: string;
  public email: string;
  public phone: string;
  public phoneVerified: boolean;
  public featureFlags: IFeatureFlag[];
  public createdAt: string;
  public updatedAt: string;
  public deletedAt: string;
  public status: UserStatus;
  public role: UserRole;
  public profile: UserProfile;
  public preferences: IPreferencesData;

  constructor(user: any) {
    this.username = user.username;
    this.email = user.email;
    this.phone = user.phone;
    this.phoneVerified = user.phoneVerified;
    this.featureFlags = user.featureFlags;
    this.createdAt = user.createdAt;
    this.updatedAt = user.updatedAt;
    this.deletedAt = user.deletedAt;
    this.status = user.status;
    this.role = user.role;
    this.preferences = user.preferences;
    this.profile = new UserProfile(user.profile);
  }
}

export interface INotificationsData {
  email: boolean;
  sms: boolean;
  telegram: boolean;
  viber: boolean;
  whatsapp: boolean;
}

export interface IPaymentMethods {
  cash: boolean;
  liqpay: boolean;
  credit: boolean;
}

export interface IPreferencesData {
  language: string;
  subscribe: boolean;
  notifications: INotificationsData;
  supplier: string;
  paymentMethods: IPaymentMethods;
  deliveryRegion: string;
  doNotShowDeliveryModal?: boolean;
}

export interface IUserLoginResponse {
  payload: User;
  token: string;
  success: true;
}

export interface IUserRegisterResponse {
  authData: IUserLoginResponse;
  payload: User;
}
