import * as React from 'react';
import { withStyles } from '@mui/styles';

import { useTrackInitialPageLoad } from 'controllers';

import AuthPageWrapper from './shared/AuthPageWrapper';
import FlrStepper from 'components/shared/stepper';
import { TitleH1, TextBody2 } from 'components/shared/text';

import Steps from './registration/steps';
import { registrationFormStepStorageKey, registrationTokenStorageKey } from 'shared/constants';
import messages from 'translations/auth/registration';
import styles from './styles';

interface IProps {
  classes?: any;
}
const RegistrationPage: React.FC<IProps> = ({ classes }) => {
  useTrackInitialPageLoad();

  const hasRegToken = window.localStorage.getItem(registrationTokenStorageKey);
  return (
    <main className={classes.root}>
      <AuthPageWrapper classes={classes} registration>
        <TitleH1 className={classes.header} align={'center'}>
          {messages.header.defaultMessage}
        </TitleH1>
        <TextBody2 className={classes.headerCaptionRegistration} align={'center'}>
          {messages.headerCaption.defaultMessage}
        </TextBody2>

        <FlrStepper steps={Steps} storeLastCompletedStepByKey={hasRegToken ? registrationFormStepStorageKey : ''} />
      </AuthPageWrapper>
    </main>
  );
};

export default withStyles<any>(styles)(RegistrationPage);
