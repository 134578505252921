import { IApplicationState } from "store/reducers";

export const getCheckout = (state: IApplicationState) => state.checkout;
export const getCheckoutLoadingState = (state: IApplicationState) => getCheckout(state).loading;
export const getCheckoutLoaded = (state: IApplicationState) => getCheckout(state).loaded;
export const getCheckoutData = (state: IApplicationState) => getCheckout(state).data;
export const getCheckoutOrders = (state: IApplicationState) => getCheckoutData(state).orders;
export const getCheckoutActiveOrder = (state: IApplicationState) => getCheckoutData(state).activeOrder;
export const getCheckoutSelectedOrderItem = (state: IApplicationState) => getCheckoutData(state).selectedOrderItem;
export const getCheckoutFinalizedOrders = (state: IApplicationState) => getCheckoutData(state).finalizedOrders;
export const getCheckoutFinalizingOrders = (state: IApplicationState) => getCheckoutData(state).finalizingOrders;
