import { IApplicationState } from 'store/reducers';
import { ISearchData, ISelectedProduct } from './actions';

export const getCatalog = (state: IApplicationState) => state.catalog;
export const getCatalogLoadingState = (state: IApplicationState) => getCatalog(state).loading;
export const getCatalogSortedState = (state: IApplicationState) => getCatalog(state).sorted;
export const getCatalogFetchingProducts = (state: IApplicationState) => getCatalog(state).data.fetchingProducts;
export const getCatalogProductsMap = (state: IApplicationState) => getCatalog(state).data.list.productsMap;
export const getCatalogSort = (state: IApplicationState) => getCatalog(state).data.sort;
export const getCatalogData = (state: IApplicationState) => getCatalog(state).data.list;
export const getCatalogLoadedState = (state: IApplicationState) => getCatalogData(state).loaded;
export const getCatalogDocsShown = (state: IApplicationState) => getCatalog(state).data.list.docsShown;
export const getCatalogLayout = (state: IApplicationState) => getCatalog(state).data.layout;
export const getCatalogLayoutFiltersOpened = (state: IApplicationState) => getCatalogLayout(state).leftOpened;
export const getCatalogAlphabet = (state: IApplicationState) => getCatalog(state).data.list.alphabet;
export const getCatalogHighlightedAlphabet = (state: IApplicationState) => getCatalog(state).data.highlightedAlphabet;
export const getCatalogFilters = (state: IApplicationState) => getCatalog(state).data.filters;
export const getCatalogPostFilteredFilters = (state: IApplicationState) => getCatalog(state).data.postFilteredFilters;
export const getCatalogInStock = (state: IApplicationState) => getCatalogFilters(state).inStock;
export const getCatalogSpecialOffers = (state: IApplicationState) => getCatalogFilters(state).specialOffers;
export const getCatalogProductType = (state: IApplicationState) => getCatalogFilters(state).productType;
export const getCatalogFastFilters = (state: IApplicationState) => getCatalogFilters(state).fast;

export const getSelectedProduct = (state: IApplicationState) => getCatalog(state).data.selectedProduct;
export const getSelectedProductLoadingState = (state: IApplicationState) => getSelectedProduct(state).loading;
export const getSelectedProductData = (state: IApplicationState): ISelectedProduct => getSelectedProduct(state).data;

export const getSearchData = (state: IApplicationState) => getCatalog(state).data.searchData;
export const getSearchDataLoadingState = (state: IApplicationState) => getSearchData(state).loading;
export const getSearchDataLoadedState = (state: IApplicationState) => getSearchData(state).loaded;
export const getSearchDataData = (state: IApplicationState): ISearchData => getSearchData(state).data;
