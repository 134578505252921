import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "cart.timer.title",
    description: "cart.timer.title",
    defaultMessage: "Час до повернення товарів у Каталог"
  },
  body: {
    id: "cart.timer.body",
    description: "cart.timer.body",
    defaultMessage:
      "З усіх товарів, для котрих не буде оформлена доставка до завершення відліку часу, бронювання буде знято"
  },
  bodyCheckout: {
    id: "cart.timer.bodyCheckout",
    description: "cart.timer.bodyCheckout",
    defaultMessage: "На сторінці оформлення доставки відлік часу не звійснюється"
  },
});
