import React from 'react';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { IThemed } from 'utils/styled';
import { IProductAlphaLetter } from 'models/product';
import { getCatalogHighlightedAlphabet } from 'store/catalog/selectors';

interface IProps {
  onSelect?: (productIndex: IProductAlphaLetter) => void;
  isDesc: boolean;
  useDeviceStyle?: boolean;
}

export const AlphabetNav: React.FC<IProps> = ({ onSelect, useDeviceStyle, isDesc }) => {
  const onClickHandler = (letter: IProductAlphaLetter) => () => {
    if (onSelect) {
      onSelect(letter);
    }
  };

  const alphabet = useSelector(getCatalogHighlightedAlphabet);

  return alphabet && alphabet.length ? (
    <ComponentWrapper className={clsx({ device: useDeviceStyle, desc: isDesc })}>
      {alphabet.map((letter: IProductAlphaLetter, idx: number) => {
        if (!letter.enabled) {
          return <ExcludedChar key={idx}>{letter.name}</ExcludedChar>;
        }

        return letter.selected ? (
          <SelectedChar key={idx}>{letter.name}</SelectedChar>
        ) : (
          <Char key={idx} onClick={onClickHandler(letter)}>
            {letter.name}
          </Char>
        );
      })}
    </ComponentWrapper>
  ) : null;
};

const ComponentWrapper = styled<any>('div')(({ theme }: IThemed) => ({
  color: theme.colors.brandSecondary,
  margin: '3px 16px',
  padding: '8px 12px',
  width: 'calc(100% - 40px)',
  borderTop: `1px solid ${theme.colors.borderGray}`,
  borderBottom: `1px solid ${theme.colors.borderGray}`,
  display: 'grid',
  gridColumnGap: 3,
  gridAutoColumns: '1fr',
  gridAutoFlow: 'column',
  '&.desc': {
    direction: 'rtl'
  },
  '&.device': {
    padding: '4px 0',
  }
}));

const Char = styled<any>('a')(({ theme }: IThemed) => ({
  padding: '3px 0',
  cursor: 'pointer',
  textDecoration: 'none',
  textAlign: 'center',
  borderBottom: '2px solid transparent',
  borderRadius: 4,
  boxSizing: 'content-box',
  font: `${theme.fontSizes.default} / 21px ${theme.fonts.headings}`,
  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  }
}));

const SelectedChar = styled<any>(Char)(({ theme }: IThemed) => ({
  background: theme.palette.common.primaryClickablePressBackground,
  color: theme.palette.primary.main,
  cursor: 'auto'
}));

const ExcludedChar = styled<any>(Char)(({ theme }: IThemed) => ({
  color: theme.palette.common.helperBlack,
  opacity: 0.5,
  cursor: 'auto',
  '&:hover': {
    color: theme.palette.common.helperBlack,
    borderBottom: '2px solid transparent'
  }
}));
