import * as React from "react";
import { IStaticPageProps } from "./BasicPage";
import BasicPageHtml from "./BasicPageHtml";

import StaticOfertaHtml from "./html/oferta";
import StaticDeliveryHtml from "./html/delivery";
import StaticReplacementHtml from "./html/replacement";
import StaticAboutUs from "./html/about-us";

const getUrl = (slug: string) => `https://docs.google.com/document/d/${slug}/export?format=pdf`;

export const staticPages: Record<string, IStaticPageProps> = {
  oferta: {
    pageTitle: "Оферта",
    docUrl: getUrl("1IIGqXmpQ8nFBcLUekuAZefztOsfWPwG8-eoFvHk2nx0")
  },
  delivery: {
    pageTitle: "Доставка та оплата",
    docUrl: getUrl("1bp3HbOQ2M8gYz2PhNnosleJXELrLDZCoXJTdGEPksws")
  },
  replacement: {
    pageTitle: "Заміна та повернення товарів",
    docUrl: getUrl("169538q8Erfy-pEdp3FYSYmTGn7mKWRJxLMYW7BEDHYg")
  },
  "about-us": {
    pageTitle: "Про нас"
  }
};

// export const StaticOferta: React.FC = () => <BasicPage {...staticPages.oferta} />;
export const StaticOferta: React.FC = () => <BasicPageHtml staticHtml={<StaticOfertaHtml />} />;
// export const StaticDelivery: React.FC = () => <BasicPage {...staticPages.delivery} />;
export const StaticDelivery: React.FC = () => <BasicPageHtml staticHtml={<StaticDeliveryHtml />} />;
// export const StaticReplacement: React.FC = () => <BasicPage {...staticPages.replacement} />;
export const StaticReplacement: React.FC = () => <BasicPageHtml staticHtml={<StaticReplacementHtml />} />;

export const AboutUs: React.FC = () => <BasicPageHtml staticHtml={<StaticAboutUs />} />;
