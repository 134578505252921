import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

import { Text } from 'components/layout/content/Text';
import { TitleH2 } from '../shared/text';
import { Icon } from '../shared';
import styles from './styles';

import messagesExpired from 'translations/cart/expired';

interface IProps {
  type: 'inStock' | 'transit' | undefined;
  className?: string;
  preOrder?: boolean;
  expired?: boolean;
  transit?: boolean;
  bold?: boolean;
  useExpireTimer?: boolean;
  classes?: any;
}

const IconCartTimer: React.FC<IProps> = ({
  type,
  className,
  useExpireTimer,
  expired,
  preOrder,
  classes,
  transit,
  bold
}) => {
  const [timers, setTimer] = useState<any>(null);
  const timer = timers && type ? timers[type] : null;

  const storageHandler = (event: any) => {
    setTimer((t: any) => ({ ...t, ...event.detail }));
  };

  useEffect(() => {
    window.addEventListener('timersStore', storageHandler);

    return () => {
      window.removeEventListener('timersStore', storageHandler);
    };
  }, []);

  if (!timer || !type) {
    return null;
  }
  const { minutes, seconds } = timer;

  return (
    <Grid item className={clsx(className, classes.timerBlock, { expired })}>
      <TitleH2 className={classes.noVerticalMargin} style={{ color: 'inherit' }}>
        {expired && !useExpireTimer ? (
          messagesExpired.noTimeLeft.defaultMessage
        ) : !preOrder ? (
          <Text bold={bold}>
            {minutes}:{seconds}
          </Text>
        ) : null}
      </TitleH2>
      {!preOrder && <Icon type={'timer'} size={16} leftOffset={8} opacity={bold ? 1 : undefined} />}
    </Grid>
  );
};

export default withStyles<any>(styles)(IconCartTimer);
