import * as React from 'react';
import styled from '@emotion/styled/macro';
import Container from 'components/layout/Container';
import { ReactComponent as LogoTextUnder } from 'components/shared/icons/logo-textunder-ru.svg';
import { TitleH2 } from 'components/shared/text';
import bgImage from './auth/assets/bg.png';
import { IThemed } from 'utils/styled';

interface IProps {
  message: string;
}

const PlaceholderPage: React.FC<IProps> = ({ message }) => (
  <>
    <StyledBg />
    <LogoWrapper>
      <LogoContainer>
        <div>
          <LogoTextUnder />
          <TitleH2>{message}</TitleH2>
        </div>
      </LogoContainer>
    </LogoWrapper>
  </>
);

const LogoWrapper = styled<any>('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',
  zIndex: 1
}));

const LogoContainer = styled<any>(Container)(({ theme }: IThemed) => ({
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  '& > div': {
    minWidth: '30vw',
    color: theme.colors.background,
    textAlign: 'center'
  }
}));

const StyledBg = styled<any>('div')(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: `linear-gradient(0deg, rgba(74, 50, 48, 0.3), rgba(74, 50, 48, 0.3)), url(${bgImage}) center center no-repeat`,
  backgroundBlendMode: 'multiply, normal',
  backgroundSize: 'cover'
}));

export default PlaceholderPage;
