import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import Root from 'components/layout/content/Root';
import AccountPage from 'pages/account';
import AccountRefund from 'pages/account/refund'; // ui demo
import ForgotPassword from 'pages/auth/forgot-password/forgotPassword';
import LoginPage from 'pages/auth/login';
import RegistrationPage from 'pages/auth/registration';
import CartPage from 'pages/cart/Cart';
import CatalogPage from 'pages/catalog';
import CheckoutPage from 'pages/checkout/Checkout';
import IndexPage from 'pages/main';
import PlaceholderPage from 'pages/placeholder';
import { AboutUs, StaticDelivery, StaticOferta, StaticReplacement } from 'pages/static';
import { ParseUrlFilters } from 'services';
import { baseUrl, categories, defaultCatalogInStock, defaultCatalogProductType } from 'shared/constants';
import { GuestRoute, PrivateRoute } from 'utils/CustomRoutes';
import { withErrorHandler } from 'utils/errorHandler';

/*
If your app is big + you have routes with a lot of components, you should consider
code-splitting your routes! If you bundle stuff up with Webpack, I recommend `react-loadable`.

$ yarn add react-loadable
$ yarn add --dev @types/react-loadable

The given `pages/` directory provides an example of a directory structure that's easily
code-splittable.
*/

export interface ICatalogMatchParams {
  type: string;
  inStock?: string;
  filter?: string;
}

interface IAccountMatchParams {
  tab: string;
}

// replace text to new one
const WipPlaceholder = () => <PlaceholderPage message="На сайті проводяться технічні роботи" />;
const NotFoundPlaceholder = () => <PlaceholderPage message="Сторінка не знайдена" />;

const Routes: React.FC = () => {
  return (
    <Root>
      <Switch>
        <Route exact path={`${baseUrl}/`} component={IndexPage} />
        <GuestRoute exact path={`${baseUrl}/login`} component={LoginPage} />
        <GuestRoute exact path={`${baseUrl}/registration`} component={RegistrationPage} />
        <GuestRoute exact path={`${baseUrl}/forgot-password`} component={ForgotPassword} />
        <PrivateRoute exact path={`${baseUrl}/demo-ui`} component={AccountRefund} />
        <PrivateRoute
          path={`${baseUrl}/account/:tab?`}
          component={({ match }: RouteComponentProps<IAccountMatchParams>) => <AccountPage tab={match.params.tab} />}
        />
        <PrivateRoute
          path={`${baseUrl}/catalog/`}
          exact
          component={() => <Redirect to={`${baseUrl}/catalog/${defaultCatalogProductType}`} />}
        />
        <PrivateRoute
          path={`${baseUrl}/catalog/:type/:filter?`}
          component={({ match }: RouteComponentProps<ICatalogMatchParams>) => {
            const type = match.params.type;
            if (
              !match.params.filter ||
              !match.params.filter.match(/preOrder=1|transitOffers=1|inStock=1|specialOffers=1|specialTransitOffers=1/)
            ) {
              return (
                <Redirect to={`${baseUrl}/catalog/${type}/${defaultCatalogInStock}${match.params.filter || ''}`} />
              );
            }
            if (!categories.hasOwnProperty(type)) {
              return <Redirect to={`${baseUrl}/catalog/${defaultCatalogProductType}`} />;
            }
            return (
              <ParseUrlFilters>
                <CatalogPage />
              </ParseUrlFilters>
            );
          }}
        />
        <PrivateRoute exact path={`${baseUrl}/cart`} component={CartPage} />
        <PrivateRoute exact path={`${baseUrl}/checkout`} component={CheckoutPage} />
        <Route exact path={`${baseUrl}/maintenance-mode`} component={WipPlaceholder} />
        <Route exact path={`${baseUrl}/oferta`} component={StaticOferta} />
        <Route exact path={`${baseUrl}/delivery`} component={StaticDelivery} />
        <Route exact path={`${baseUrl}/replacement`} component={StaticReplacement} />
        <Route exact path={`${baseUrl}/about-us`} component={AboutUs} />
        <Route exact path={`${baseUrl}/not-found`} component={NotFoundPlaceholder} />
        <Route path="*" component={() => <Redirect to={`${baseUrl}/not-found`} />} />
      </Switch>
    </Root>
  );
};

export default withErrorHandler(Routes);
