import * as React from 'react';
import styled from '@emotion/styled/macro';
import Stepper, { Orientation } from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { IThemed } from 'utils/styled';
import FlrStepConnector from './FlrStepConnector';
import FlrStepIcon, { StepperIconSize } from './FlrStepIcon';

import { IFlrSteps } from '.';

// this one is almost same as StepperProps from "@mui/material/Stepper", the difference is not required children
export interface IStepperProps extends IFlrSteps {
  activeStep?: number;
  alternativeLabel?: boolean;
  children?: React.ReactNode;
  connector?: React.ReactElement | React.ReactNode;
  nonLinear?: boolean;
  orientation?: Orientation;
}

export default class FlrStepperHeader extends React.Component<IStepperProps> {
  public static defaultProps = {
    nonLinear: false,
    activeStep: 0
  };

  public render(): React.ReactNode {
    const { activeStep, main, ...other } = this.props;
    return (
      <>
        <ComponentContainer
          alternativeLabel
          activeStep={activeStep}
          connector={(<FlrStepConnector />) as any}
          {...other}
        >
          {main.map(step => (
            <Step key={step.title.id}>
              <StepLabel StepIconComponent={FlrStepIcon}>{step.title.defaultMessage}</StepLabel>
            </Step>
          ))}
        </ComponentContainer>
      </>
    );
  }
}

const StepLabelRule = '.MuiStepLabel-root.MuiStepLabel-alternativeLabel';

const ComponentContainer = styled<any>(Stepper)(({ theme }: IThemed) => ({
  '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
    marginTop: 4
  },
  '&.MuiStepper-root': {
    padding: '24px 0'
  },
  '& .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel': {
    zIndex: 1,
    backgroundColor: '#ffffff'
  },
  '& .MuiStepConnector-lineHorizontal': {
    borderTopWidth: 2
  },
  '& .MuiStep-root.MuiStep-alternativeLabel:not(.MuiStep-vertical)': {
    '&.MuiStep-horizontal': {
      paddingLeft: 0,
      paddingRight: 0
    },
    '& > .MuiStepConnector-alternativeLabel': {
      top: `${StepperIconSize / 2}px`,
      left: `calc(-50% + ${StepperIconSize / 2}px)`,
      right: `calc(50% + ${StepperIconSize / 2}px)`
    },
    [`&:first-child > ${StepLabelRule}:before, &:last-child > ${StepLabelRule}:after`]: {
      flex: '1 1 auto',
      top: `${StepperIconSize / 2}px`,
      height: 2,
      width: `calc(50% - ${StepperIconSize / 2}px)`,
      position: 'absolute',
      content: "''"
    },
    [`&:first-child > ${StepLabelRule}:before`]: {
      left: 0,
      background: theme.palette.primary.main
    },
    [`&:last-child > ${StepLabelRule}:after`]: {
      right: 0,
      background: theme.palette.common.disabledBlack
    },
    [`&.MuiStep-completed > ${StepLabelRule}:after`]: {
      background: theme.palette.primary.main
    },

    [`> ${StepLabelRule} .MuiStepLabel-label`]: {
      color: theme.colors.gray,
      fontFamily: theme.fonts.body,
      letterSpacing: theme.letterSpacing,
      lineHeight: 1,

      '&.MuiStepLabel-active, &.MuiStepLabel-completed': {
        fontSize: theme.fontSizes.small,
        fontWeight: 'normal'
      },
      '&.MuiStepLabel-active': {
        color: theme.palette.common.black
      },
      '&.MuiStepLabel-completed': {
        color: theme.palette.common.helperBlack
      }
    }
  }
}));
