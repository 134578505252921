import { defineMessages } from "react-intl";

export default defineMessages({
  selectLoading: {
    id: "select.loading",
    description: "",
    defaultMessage: "Завантаження"
  },
  selectNoOptions: {
    id: "select.noOptions",
    description: "",
    defaultMessage: "немає варіантів"
  },
  requiredFiled: {
    id: "validation.requiredField",
    description: "",
    defaultMessage: "Це поле обовязкове для заповнення"
  },
  validEmail: {
    id: "validation.email",
    description: "validation.email",
    defaultMessage: "Будь ласка, введіть дійсну електронну пошту"
  }
});
