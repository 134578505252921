import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { Grid } from '@mui/material';

import FlrLoader from 'components/loading/LoadingSpinner';
import { useTrackInitialPageLoad } from 'controllers';
import { baseUrl } from 'shared/constants';
import { fetchAccountDiscountsAsync } from 'store/account-discounts/actions';
import { getAccountDiscountsLoadingState } from 'store/account-discounts/selectors';
import messages from 'translations/account/loyalty';

import BalanceDiscount from './BalanceDiscount';

interface IProps {}

export const loyaltyBreadcrumb = {
  label: messages.title.defaultMessage,
  path: `/account/loyalty`
};

export const loyaltyBreadcrumbMobile = {
  label: messages.titleMobile.defaultMessage,
  path: `/account/loyalty`
};

export const AccountLoyalty: React.FC<IProps> = () => {
  useTrackInitialPageLoad();

  const dispatch = useDispatch();
  const discountConditionsLoading = useSelector(getAccountDiscountsLoadingState);

  useEffect(() => {
    if (!discountConditionsLoading) {
      dispatch(fetchAccountDiscountsAsync.request());
    }
    // eslint-disable-next-line
  }, []);

  if (discountConditionsLoading) {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <Grid item>
          <FlrLoader size={100} />
        </Grid>
      </Grid>
    );
  }

  return <Route exact path={`${baseUrl}/account/loyalty`} component={BalanceDiscount} />;
};
