import { Cart, CartItem } from 'models';
import { ISelectedProduct } from 'store/catalog/actions';
import { IApplicationState } from 'store/reducers';

export const getCartState = (state: IApplicationState) => state.cart;
export const getCartLoadingState = (state: IApplicationState) => state.cart.loading;
export const getCartLoaded = (state: IApplicationState) => state.cart.loaded;
export const getCart = (state: IApplicationState) => getCartState(state).data.cart;
export const getCartGroups = (state: IApplicationState) => getCartState(state).data.cart.groups;
export const getMiniCart = (state: IApplicationState) => getCartState(state).data.miniCart;
export const getMiniCartMultiUpdate = (state: IApplicationState) => getCartState(state).data.miniCart.saving;
export const getMiniCartMultiUpdateLoading = (state: IApplicationState) => getMiniCartMultiUpdate(state).loading;
export const getMiniCartMultiUpdateDone = (state: IApplicationState) => getMiniCartMultiUpdate(state).loaded;
export const getMiniCartUpdatingProduct = (state: IApplicationState) =>
  getCartState(state).data.miniCart.editingProduct;
export const getCartTimer = (state: IApplicationState) => getCartState(state).data.timer;
export const getCartTimerTime = (state: IApplicationState) => getCartTimer(state).time;
export const getCartTimerShouldReset = (state: IApplicationState) => getCartTimer(state).shouldResetTimer;
export const getCartTransitTimer = (state: IApplicationState) => getCartState(state).data.transitTimer;
export const getCartTransitTimerTime = (state: IApplicationState) => getCartTransitTimer(state).time;
export const getCartTransitTimerShouldReset = (state: IApplicationState) => getCartTransitTimer(state).shouldResetTimer;
export const getExpiredCartLayoutExpanded = (state: IApplicationState) => getCartState(state).data.layout.expanded;
export const getCartActiveItem = (state: IApplicationState) => getCartState(state).data.layout.activeItem;
export const getCartWarningShowed = (state: IApplicationState) => getCartState(state).data.layout.warningShowed;
export const getCartMobileTab = (state: IApplicationState) => getCartState(state).data.layout.mobileTab;

export const getSelectedProductState = (state: IApplicationState) => getCartState(state).data.selectedProduct;
export const getSelectedProduct = (state: IApplicationState): ISelectedProduct => getSelectedProductState(state).data;
export const getSelectedProductLoadingState = (state: IApplicationState) => getSelectedProductState(state).loading;
export const getSelectedProductLoaded = (state: IApplicationState) => getSelectedProductState(state).loaded;

export const getTransitModalState = (state: IApplicationState) => getCartState(state).data.transitModal.isOpen;
export const getTransitModalOrders = (state: IApplicationState) => getCartState(state).data.transitModal.orders;
export const getTransitModalDisableActions = (state: IApplicationState) =>
  getCartState(state).data.transitModal.disableActions;
export const getTransitModalChecking = (state: IApplicationState) => getCartState(state).data.transitModal.checking;

export const findCartItem = (cart: Cart, offerId: string) =>
  cart.items.find((item: CartItem) => item.offer.id === offerId);

export const getCartItem = (state: IApplicationState, offerId: string) =>
  findCartItem(getCartState(state).data.cart, offerId);

export const createCartItemQtySelector = (id: string) => (state: IApplicationState) =>
  getCart(state)
    .items.filter((cartItem) => id === cartItem.product.id)
    .reduce((acc, cur) => cur.qty + acc, 0);

export const getCartItemQtyByTypeSelector = (id: string, offerType: string) => (state: IApplicationState) =>
  getCart(state)
    .items.filter((cartItem) => id === cartItem.product.id && offerType === cartItem.offer.offerType)
    .reduce((acc, cur) => cur.qty + acc, 0);
