import { ITheme, defaultMaterialTheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  root: {
    flex: 1,

    // form labels alining
    '& form label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      top: -6
    }
  },
  settingsContainer: {
    marginBottom: theme.spacing(3)
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: defaultMaterialTheme.spacing(2)
  },
  title: {
    marginBottom: 0,
    color: theme.palette.common.black
  },
  titleGroupContainer: {
    marginBottom: theme.spacing(1)
  },
  groupCaption: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5)
  },
  hiddenButton: {
    visibility: 'hidden'
  },
  titleGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  subTitleGroup: {},
  subTitleGroupCaption: {
    color: theme.palette.text.secondary
  },
  addressBar: {
    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.common.disabledBlackBackground}`
  },
  paper: {
    height: '100%',
    padding: defaultMaterialTheme.spacing(2),
    color: defaultMaterialTheme.palette.text.secondary
  },
  dataSection: {
    marginBottom: theme.spacing(3)
  },
  dataSectionDocs: {
    display: 'flex',
    alignItems: 'center'
  },
  dataSectionLabel: {
    marginBottom: theme.spacing(0.5)
  },
  dataSectionValue: {
    color: theme.palette.text.primary
  },
  commonControl: {
    marginBottom: theme.spacing(3)
  },
  commonSelectControl: {
    marginBottom: theme.spacing(5)
  },
  linkButton: {
    textAlign: 'right',
    marginTop: theme.spacing(-3),
    marginBottom: theme.spacing(4)
  },
  saveButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  cancelButton: {
    marginBottom: theme.spacing(3)
  },
  socialContainer: {
    marginBottom: theme.spacing(3)
  },
  socialItem: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderTop: `1px solid ${theme.palette.common.disabledBlack}`,

    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.common.disabledBlack}`
    }
  },
  socialAction: {
    marginLeft: 'auto'
  },
  dialogContainer: {
    paddingBottom: theme.spacing(0)
  },
  dialogTitleContainer: {
    paddingBottom: theme.spacing(1)
  },
  dialogTitle: {
    margin: 0,
    fontSize: 24
  },
  dialogCaption: {
    marginBottom: theme.spacing(5)
  },
  dialogContent: {
    marginBottom: 0
  },
  dialogActions: {
    width: '100%',
    paddingBottom: theme.spacing(3)
  },
  changePasswordControl: {
    marginBottom: theme.spacing(2)
  },
  changePasswordButton: {
    marginRight: theme.spacing(1)
  },
  outletHead: {
    marginTop: 0,
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  outletHeadCaption: {
    marginBottom: 0
  },
  addFormContainer: {
    marginTop: theme.spacing(3)
  }
});

export default styles;
