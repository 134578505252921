import React from 'react';
import moment from 'moment';

import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Grid, Hidden, Avatar } from '@mui/material';
import { bindActionCreators, Dispatch } from 'redux';
import { Breakpoint } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Slider from 'react-slick';

import { Testimonial } from 'models';
import { IApplicationState } from 'store/reducers';
import { getTestimonialsData } from 'store/main/selectors';
import { mainTestimonialsAsync } from 'store/main/actions';

import { TitleH1, TitleH2, TextBody1, TextBody2, TextSubTitle } from 'components/shared/text';
import Icon from 'components/shared/Icon';

import styles from './styles';
import messages from 'translations/main/testimonials';

import { defaultLocale } from 'shared/constants';

const defaultSettings = {
  dots: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  respondTo: 'min',
  infinite: false,
  initialSlide: 0,
  nextArrow: <React.Fragment />,
  prevArrow: <React.Fragment />,

  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 840,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

interface IOwnProps {
  // put own props here
}

interface IStateProps {
  testimonials: Testimonial[];

  width?: Breakpoint;
  classes?: any;
}

interface IDispatchProps {
  loadTestimonials: typeof mainTestimonialsAsync.request;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const MainTestimonials: React.FC<IProps> = ({ classes, testimonials, loadTestimonials }) => {
  React.useEffect(() => {
    loadTestimonials();
  }, [loadTestimonials]);

  const sliderContent = testimonials.map((item, i) => {
    return (
      <div key={i} className={classes.testimonialsItem}>
        <Avatar className={classes.testimonialsAvatar} src={item.avatar} alt="">
          {item.name[0]}
        </Avatar>

        <div className={classes.testimonialsMain}>
          <TextSubTitle className={classes.testimonialsName}>{item.name}</TextSubTitle>
          <TextBody2 className={classes.testimonialsFirm}>{item.brand}</TextBody2>
          <TitleH2 className={classes.testimonialsTitle}>{item.title}</TitleH2>
          <TextBody2 className={classes.testimonialsDate} component={'span'}>
            <Icon type={'calendar'} size={20} offset={8} />

            {item.date &&
              moment(item.date)
                .locale(defaultLocale)
                .format('LL')}
          </TextBody2>
          <TextBody1 className={classes.testimonialsText}>{item.text}</TextBody1>
        </div>
      </div>
    );
  });

  return (
    <div className={classes.wrapper}>
      <Grid container className={classes.root} component={'section'}>
        <Grid item xs={1} implementation="css" smDown component={Hidden} />

        <Grid item className={classes.container} lg={11} md={11}>
          <TitleH1 className={classes.title} color={'inherit'}>
            {messages.title.defaultMessage}
          </TitleH1>
        </Grid>

        <Grid item xs={1} implementation="css" smDown component={Hidden} />

        <Grid item className={classes.container} lg={11} md={11}>
          <Slider {...defaultSettings}>{sliderContent}</Slider>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state: IApplicationState) => ({
  testimonials: getTestimonialsData(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      loadTestimonials: mainTestimonialsAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(MainTestimonials as any));
