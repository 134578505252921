import { FC } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { IconButton } from '@mui/material';

import CardCreditLine from 'components/cards/account/CardCreditLine';
import BalanceCard from 'components/cards/shared/BalanceCard';
import FlrCard from 'components/shared/card/FlrCard';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import Icon from 'components/shared/Icon';
import { TextSubTitle, TitleH1 } from 'components/shared/text';
import { useTrackInitialPageLoad } from 'controllers';
import { User } from 'models';
import { baseUrl } from 'shared/constants';
import { getAccountLoadingState, getUserAccount } from 'store/account/selectors';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/account/finance';

import CardDepositDiscount from './CardDepositDiscount';
import DataCardSkeleton from './CardSkeleton';
import TransactionsTable from './Table';

import classes from './Finance.module.scss';

interface IAccountFinanceProps {
  account: User;
  accountLoadingState: boolean;
}

const AccountFinance: FC<IAccountFinanceProps> = ({ account, accountLoadingState }) => {
  useTrackInitialPageLoad();

  return (
    <div className={classes.container}>
      <TitleH1 className={classes.title}>{messages.title.defaultMessage}</TitleH1>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <BalanceCard withoutTitle classNameWrapper={classes.card} />
          <CardCreditLine withoutChart isFinance classNameWrapper={classes.card} />
          <FlrCard className={classes.card} hoverShadow>
            <FlrCardTitle isUnderline>
              <TextSubTitle align={'left'}>{messages.depositDiscount.defaultMessage}</TextSubTitle>
              <IconButton
                size={'small'}
                component={NavLink}
                to={`${baseUrl}/account/loyalty`}
                className={classes.buttonPadding}
              >
                <Icon type="btnArrow" opacity="1" size={24} />
              </IconButton>
            </FlrCardTitle>
            {!accountLoadingState && account ? <CardDepositDiscount account={account} /> : <DataCardSkeleton />}
          </FlrCard>
        </div>
        <TransactionsTable />
      </div>
    </div>
  );
};

const mapStateToProps: MapStateToProps<{}, {}, IApplicationState> = (state: IApplicationState) => ({
  account: getUserAccount(state),
  accountLoadingState: getAccountLoadingState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountFinance);
