import { FC } from 'react';
import { upperFirst } from 'lodash';
import moment from 'moment';
import { Button, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';

import ProductDetails from 'components/cards/shared/ProductDetails';
import { SplitedPrice } from 'components/prices';
import Icon from 'components/shared/Icon';
import FlrDialogTitle from 'components/shared/modals/components/DialogTitle';
import { TextBody2 } from 'components/shared/text';
import { DetailedProduct, Offer, Product } from 'models';
import commonMessages from 'translations/cart/common';
import messages from 'translations/catalog/mini-details';

import { useModalStyles } from './styles';

interface IProps {
  state: boolean;
  transitOffer?: Offer;
  product: DetailedProduct | Product | null;
  handleClose: any;
  handleTransitSubmit: any;
  handlePreorderSubmit: any;
}

const TransitOfferModal: FC<IProps> = ({
  state,
  transitOffer,
  handleClose,
  handleTransitSubmit,
  handlePreorderSubmit,
  product
}) => {
  const { fullName } = product || {};
  const { endDate, inStock, price, discount = 1 } = transitOffer || {};
  const classes = useModalStyles();

  const handlePreorderBuy = () => {
    handlePreorderSubmit();
  };

  const handleTransitBuy = () => {
    handleTransitSubmit(transitOffer);
  };

  const priceWithDiscount = price ? Number(Object.values(price)[0]) * (1 - discount) : 0;

  return (
    <Dialog
      open={state}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll={'body'}
      classes={{
        paperScrollBody: classes.dialogContainer
      }}
    >
      {handleClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16
          }}
          className={classes.closeButton}
        >
          <Icon type={'cross'} size={12} />
        </IconButton>
      ) : null}
      <FlrDialogTitle text={messages.transitOfferModalTitle} />
      <TextBody2 className={classes.titleSecondary}>{messages.transitModalTitleSecondary.defaultMessage}</TextBody2>
      <Grid className={classes.rowContainer} alignItems="center" justifyContent="center" container>
        <TextBody2 className={classes.productName}>{fullName}</TextBody2>
        <ProductDetails
          product={product as Product}
          className={'nowrap'}
          attrsListStyle={{ justifyContent: 'center' }}
          attrsOnly
        />
      </Grid>

      <Grid
        className={classes.transitProductContainer}
        container
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
        spacing={3}
      >
        <Grid item container direction="column" xs={6}>
          <Grid className={classes.transitProductColumnTitle} item>
            {messages.transitProductColumn1.defaultMessage}
          </Grid>
          <Grid item>{upperFirst(moment(endDate).format('dd DD.MM'))}</Grid>
        </Grid>
        <Grid item container direction="column" xs={6}>
          <Grid className={classes.transitProductColumnTitle} item>
            {messages.transitProductColumn2.defaultMessage}
          </Grid>
          <Grid item style={{ whiteSpace: 'nowrap' }}>
            <span className={classes.perhapsDeliveryDate}>{upperFirst(moment(endDate).format('dd DD.MM'))}&nbsp;-&nbsp;</span>
            <span className={classes.perhapsDeliveryDateLast}>{upperFirst(moment(endDate).add(6, 'days').format('dd DD.MM'))}</span>
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={6}>
          <Grid className={classes.transitProductColumnTitle} item>
            {messages.transitProductColumn3.defaultMessage}
          </Grid>
          <Grid item>{inStock || '-'}</Grid>
        </Grid>
        <Grid item container direction="column" xs={6} className={classes.sumContainer}>
          <Grid className={classes.transitProductColumnTitle} item>
            {messages.transitProductColumn4.defaultMessage}
          </Grid>
          <Grid item>
            <SplitedPrice fontColor="inherit" fontSize={'h5'} value={priceWithDiscount} className={classes.priceWithDiscount}/>
          </Grid>
        </Grid>
      </Grid>

      <TextBody2 className={classes.transitModalBottomText}>{messages.transitModalBottomText.defaultMessage}</TextBody2>
      <DialogActions classes={{ root: classes.actionButtonsContainer }}>
        <Button className={classes.actionButton} variant={'outlined'} color={'primary'} onClick={handlePreorderBuy}>
          {commonMessages.preOrder.defaultMessage}
        </Button>
        <Button className={classes.actionButton} variant={'contained'} color={'primary'} onClick={handleTransitBuy}>
          {commonMessages.buy.defaultMessage}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { TransitOfferModal };
