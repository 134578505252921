import { createReducer, PayloadAction, createAsyncAction, createAction } from 'typesafe-actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push, replace } from 'connected-react-router';
import {
  baseUrl,
  cartGroupCheckoutStorageKey,
  registrationFormStepStorageKey,
  registrationTokenStorageKey
} from 'shared/constants';
import {
  loadedDataWrapper,
  REQUEST_ACTIONS,
  IAsyncDataWrapper,
  loadingDataWrapper,
  errorDataWrapper
} from 'store/actions';
import { User } from 'models/user';
import { AuthRepository } from './request';
import { fetchAccountAsync } from '../account/actions';

const httpClient = new AuthRepository();

export const prefix = '@@auth/';

export const AUTH_CHECK = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const AUTH_CHECK_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const AUTH_CHECK_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

export const AUTH_LOGIN_REQUEST = `${prefix}login/${REQUEST_ACTIONS.REQUEST}`;
export const AUTH_LOGIN_REQUEST_SUCCESS = `${prefix}login/${REQUEST_ACTIONS.SUCCESS}`;
export const AUTH_LOGIN_REQUEST_FAILURE = `${prefix}login/${REQUEST_ACTIONS.FAILURE}`;

export const AUTH_FORGOT_PASSWORD_REQUEST = `${prefix}forgot-password/${REQUEST_ACTIONS.REQUEST}`;
export const AUTH_FORGOT_PASSWORD_REQUEST_SUCCESS = `${prefix}forgot-password/${REQUEST_ACTIONS.SUCCESS}`;
export const AUTH_FORGOT_PASSWORD_REQUEST_FAILURE = `${prefix}forgot-password/${REQUEST_ACTIONS.FAILURE}`;

export const AUTH_FORGOT_PASSWORD_STEP_2_REQUEST = `${prefix}forgot-password-2/${REQUEST_ACTIONS.REQUEST}`;
export const AUTH_FORGOT_PASSWORD_STEP_2_REQUEST_SUCCESS = `${prefix}forgot-password-2/${REQUEST_ACTIONS.SUCCESS}`;
export const AUTH_FORGOT_PASSWORD_STEP_2_REQUEST_FAILURE = `${prefix}forgot-password-2/${REQUEST_ACTIONS.FAILURE}`;

export const CHANGE_FORGOT_PASSWORD_REQUEST = `${prefix}forgot-password-change/${REQUEST_ACTIONS.REQUEST}`;
export const CHANGE_FORGOT_PASSWORD_REQUEST_SUCCESS = `${prefix}forgot-password-change/${REQUEST_ACTIONS.SUCCESS}`;
export const CHANGE_FORGOT_PASSWORD_REQUEST_FAILURE = `${prefix}forgot-password-change/${REQUEST_ACTIONS.FAILURE}`;

export const AUTH_LOGOUT = `${prefix}LOGOUT`;

interface IForgotPasswordData {
  expiresOn: string;
  token: string;
  resetKey?: string;
}

export interface IForgotPasswordObject {
  authId: string;
  method: string;
  isValid?: boolean;
  isSuccess?: boolean;
  error: any;
  resetKey: number;
  _sentData: IForgotPasswordData;
}

interface IAuthStateSync {
  user: User | null;
  forgotPassword: IForgotPasswordObject | null;
}

export type IAuthState = IAsyncDataWrapper<IAuthStateSync>;

export const authInitialState: IAuthState = {
  loading: false,
  loaded: false,
  data: {
    user: null,
    forgotPassword: null
  },
  error: null
};

type AuthLoginActionTypes =
  | typeof AUTH_LOGIN_REQUEST
  | typeof AUTH_LOGIN_REQUEST_SUCCESS
  | typeof AUTH_LOGIN_REQUEST_FAILURE;

type AuthForgotPasswordActionTypes =
  | typeof AUTH_FORGOT_PASSWORD_REQUEST
  | typeof AUTH_FORGOT_PASSWORD_REQUEST_SUCCESS
  | typeof AUTH_FORGOT_PASSWORD_REQUEST_FAILURE;
type AuthForgotPasswordStep2ActionTypes =
  | typeof AUTH_FORGOT_PASSWORD_STEP_2_REQUEST
  | typeof AUTH_FORGOT_PASSWORD_STEP_2_REQUEST_SUCCESS
  | typeof AUTH_FORGOT_PASSWORD_STEP_2_REQUEST_FAILURE;

type ChangeForgotPasswordActionTypes =
  | typeof CHANGE_FORGOT_PASSWORD_REQUEST
  | typeof CHANGE_FORGOT_PASSWORD_REQUEST_SUCCESS
  | typeof CHANGE_FORGOT_PASSWORD_REQUEST_FAILURE;

export const fetchLoginAsync = createAsyncAction(
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_REQUEST_SUCCESS,
  AUTH_LOGIN_REQUEST_FAILURE
)<any, User, Error>();

export const fetchForgotPasswordAsync = createAsyncAction(
  AUTH_FORGOT_PASSWORD_REQUEST,
  AUTH_FORGOT_PASSWORD_REQUEST_SUCCESS,
  AUTH_FORGOT_PASSWORD_REQUEST_FAILURE
)<any, User, Error>();

export const fetchForgotPasswordStep2Async = createAsyncAction(
  AUTH_FORGOT_PASSWORD_STEP_2_REQUEST,
  AUTH_FORGOT_PASSWORD_STEP_2_REQUEST_SUCCESS,
  AUTH_FORGOT_PASSWORD_STEP_2_REQUEST_FAILURE
)<any, User, Error>();

export const changeForgotPasswordAsync = createAsyncAction(
  CHANGE_FORGOT_PASSWORD_REQUEST,
  CHANGE_FORGOT_PASSWORD_REQUEST_SUCCESS,
  CHANGE_FORGOT_PASSWORD_REQUEST_FAILURE
)<any, User, Error>();

export const checkAuthAsync = createAsyncAction(AUTH_CHECK, AUTH_CHECK_SUCCESS, AUTH_CHECK_FAILURE)<
  void,
  void,
  Error
>();

export const logout = createAction(AUTH_LOGOUT)();

function* authCheckSaga(action: ReturnType<typeof checkAuthAsync.success>) {
  try {
    yield call(() => httpClient.check());
    yield put(checkAuthAsync.success());
  } catch (err) {
    yield put(checkAuthAsync.failure(err as Error));
  }
}

function* intervalCheckAuthSaga(action: ReturnType<typeof checkAuthAsync.success>): Generator {
  let token = window.localStorage.getItem('token');
  while (token) {
    try {
      const auth: any = yield call(() => httpClient.check());
      if (
        auth.response &&
        (auth.response.status === 401 || auth.response.status === 403 || auth.response.status === 503)
      ) {
        if (auth.response.data && auth.response.data === 'Site Maintenance') {
          token = null;
          yield put(replace('/maintenance-mode'));
        } else {
          token = null;
          window.localStorage.setItem('redirect_after_login', window.location.pathname);
          yield put(logout());
          window.location.href = '/login';
        }
      } else {
        yield put(checkAuthAsync.success());
      }
    } catch (error) {
      yield put(checkAuthAsync.failure(error as Error));
      yield put(logout());
      window.localStorage.setItem('redirect_after_login', window.location.pathname);
      window.location.href = '/login';
    }
    // check auth every 30 sec
    yield delay(30 * 1000);
  }
}

function* loginRedirectSaga(action: ReturnType<typeof fetchLoginAsync.success>) {
  yield put(push(`${baseUrl}/catalog`));
}

function* logoutRedirectSaga(action: ReturnType<typeof logout>) {
  const isMaintenance = window.location.pathname.includes('maintenance-mode');
  if (!isMaintenance) {
    yield put(push(`${baseUrl}`));
  }
}

function* notLoggedRedirectSaga(action: ReturnType<typeof checkAuthAsync.failure>) {
  yield put(push(`${baseUrl}/login`));
}

function* authLoginSaga(action: ReturnType<typeof fetchLoginAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.login(action.payload));

    yield put(fetchLoginAsync.success(response));
  } catch (err) {
    yield put(fetchLoginAsync.failure(err as Error));
  }
}
function* authForgotPasswordSaga(action: ReturnType<typeof fetchForgotPasswordAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.forgotPassword({ authId: action.payload }));

    yield put(fetchForgotPasswordAsync.success({ ...response.data, authId: action.payload }));
  } catch (err) {
    yield put(fetchForgotPasswordAsync.failure(err as Error));
  }
}
function* authForgotPasswordStep2Saga(action: ReturnType<typeof fetchForgotPasswordStep2Async.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.forgotPassword2(action.payload));

    yield put(fetchForgotPasswordStep2Async.success({ ...response.data, ...action.payload }));
  } catch (err) {
    yield put(fetchForgotPasswordStep2Async.failure(err as Error));
  }
}
function* changeForgotPasswordSaga(action: ReturnType<typeof changeForgotPasswordAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.changeForgotPassword(action.payload));

    yield put(changeForgotPasswordAsync.success({ ...response.data, authId: action.payload }));
  } catch (err) {
    yield put(changeForgotPasswordAsync.failure(err as Error));
  }
}

export function* authRequestSaga() {
  yield takeEvery(checkAuthAsync.request, authCheckSaga);
  yield takeEvery(fetchAccountAsync.success, intervalCheckAuthSaga);
  yield takeEvery(checkAuthAsync.failure, notLoggedRedirectSaga);
  yield takeEvery(fetchLoginAsync.request, authLoginSaga);
  yield takeEvery(fetchLoginAsync.success, loginRedirectSaga);
  yield takeEvery(fetchForgotPasswordAsync.request, authForgotPasswordSaga);
  yield takeEvery(fetchForgotPasswordStep2Async.request, authForgotPasswordStep2Saga);
  yield takeEvery(changeForgotPasswordAsync.request, changeForgotPasswordSaga);
  yield takeEvery(logout, logoutRedirectSaga);
}

export default createReducer(authInitialState)
  .handleAction(checkAuthAsync.failure, (state: IAuthState) => authInitialState)
  .handleAction(fetchLoginAsync.request, (state: IAuthState) => loadingDataWrapper(state.data))
  .handleAction(fetchLoginAsync.success, (state: IAuthState, action: PayloadAction<AuthLoginActionTypes, User>) => {
    window.localStorage.removeItem(cartGroupCheckoutStorageKey);
    window.localStorage.removeItem(registrationFormStepStorageKey);
    window.localStorage.removeItem(registrationTokenStorageKey);
    return loadedDataWrapper({
      ...state.data,
      user: action.payload
    });
  })
  .handleAction(fetchLoginAsync.failure, (state: IAuthState) =>
    errorDataWrapper(state.data, new Error('Failed to load user auth'))
  )
  .handleAction(logout, () => {
    window.localStorage.removeItem('token');
    return authInitialState;
  })
  .handleAction(
    fetchForgotPasswordAsync.request,
    (state: IAuthState, action: PayloadAction<AuthForgotPasswordActionTypes, object>) => {
      return loadingDataWrapper({
        ...state.data,
        forgotPassword: {
          ...state.data.forgotPassword,
          error: false
        }
      });
    }
  )
  .handleAction(
    fetchForgotPasswordAsync.failure,
    (state: IAuthState, action: PayloadAction<AuthForgotPasswordActionTypes, IForgotPasswordObject>) => {
      return loadedDataWrapper({
        ...state.data,
        forgotPassword: {
          ...state.data.forgotPassword,
          error: true
        }
      });
    }
  )
  .handleAction(
    fetchForgotPasswordAsync.success,
    (state: IAuthState, action: PayloadAction<AuthForgotPasswordActionTypes, IForgotPasswordObject>) => {
      return loadedDataWrapper({
        ...state.data,
        forgotPassword: action.payload
      });
    }
  )
  .handleAction(
    fetchForgotPasswordStep2Async.request,
    (state: IAuthState, action: PayloadAction<AuthForgotPasswordStep2ActionTypes, object>) => {
      return loadingDataWrapper({
        ...state.data,
        forgotPassword: {
          ...state.data.forgotPassword,
          error: false
        }
      });
    }
  )
  .handleAction(
    fetchForgotPasswordStep2Async.failure,
    (state: IAuthState, action: PayloadAction<AuthForgotPasswordStep2ActionTypes, IForgotPasswordObject>) => {
      return loadedDataWrapper({
        ...state.data,
        forgotPassword: {
          ...state.data.forgotPassword,
          error: true
        }
      });
    }
  )
  .handleAction(
    fetchForgotPasswordStep2Async.success,
    (state: IAuthState, action: PayloadAction<AuthForgotPasswordStep2ActionTypes, IForgotPasswordObject>) => {
      return loadedDataWrapper({
        ...state.data,
        forgotPassword: {
          ...state.data.forgotPassword,
          resetKey: action.payload.resetKey,
          isValid: action.payload.isValid
        }
      });
    }
  )
  .handleAction(
    changeForgotPasswordAsync.request,
    (state: IAuthState, action: PayloadAction<ChangeForgotPasswordActionTypes, object>) => {
      return loadingDataWrapper({
        ...state.data,
        forgotPassword: {
          ...state.data.forgotPassword,
          error: false
        }
      });
    }
  )
  .handleAction(
    changeForgotPasswordAsync.failure,
    (state: IAuthState, action: PayloadAction<ChangeForgotPasswordActionTypes, object>) => {
      return loadedDataWrapper({
        ...state.data,
        forgotPassword: { ...state.data.forgotPassword, error: true }
      });
    }
  )
  .handleAction(
    changeForgotPasswordAsync.success,
    (state: IAuthState, action: PayloadAction<ChangeForgotPasswordActionTypes, IForgotPasswordObject>) => {
      return loadedDataWrapper({
        ...state.data,
        forgotPassword: { ...state.data.forgotPassword, isSuccess: true }
      });
    }
  );
