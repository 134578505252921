import React, { MouseEvent } from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import clsx from 'clsx';

import 'react-step-progress-bar/styles.css';

import { isMobileDevice } from 'environment';

import { TextSubTitle } from 'components/shared/text';

import classes from './FlrProgressBar.module.scss';

const isMobile = isMobileDevice(window.navigator);

export interface IFlrProgressBarStep {
  label: React.ReactNode;
  value: React.ReactNode;
}

export interface IFlrProgressBarProps {
  steps: IFlrProgressBarStep[];
  activeIndex?: number;
  progress: number;
}

interface IProgressbarStep {
  accomplished: boolean;
  index: number;
}

const FlrProgressBar: React.FC<IFlrProgressBarProps> = ({ steps, progress, activeIndex }) => {
  const onMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // * horizontal scroll with left mouse button down ONLY on desktop
    if (e.nativeEvent.buttons === 1) {
      e.currentTarget.scrollLeft += -e.movementX;
    }
  };

  const disabled = activeIndex && activeIndex === -1;

  return (
    <div
      className={clsx(classes.root, { [classes.scrolledRoot]: steps.length > 7 })}
      {...(!isMobile ? { onMouseMove } : {})}
    >
      <ProgressBar percent={progress} width={steps.length > 7 ? 65 * steps.length : '100%'}>
        {steps.map((step, stepIndex: number) => {
          return (
            <Step key={stepIndex}>
              {({ accomplished, index }: IProgressbarStep) => (
                <div
                  className={clsx(classes.step, {
                    [classes.accomplished]: accomplished && !disabled,
                    [classes.active]: (activeIndex || 0) === index
                  })}
                >
                  <TextSubTitle className={classes.value}>{step.value}</TextSubTitle>
                  <div className={classes.point} />
                  <p className={classes.label}>{step.label}</p>
                </div>
              )}
            </Step>
          );
        })}
      </ProgressBar>
    </div>
  );
};

export default FlrProgressBar;
