import { FC, SyntheticEvent } from 'react';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import clsx from 'clsx';
import moment from 'moment/moment';
import { IconButton } from '@mui/material';

import { Product } from '../../../models';
import cardMessages from '../../../translations/catalog/details';
import { Text } from '../../layout/content/Text';
import { SplitedPrice } from '../../prices';
import FlrCard from '../../shared/card/FlrCard';
import Icon from '../../shared/Icon';
import { ColorCircle } from '../../shared/product/ColorCircle';
import Tooltip from '../../shared/tooltip';
import { CardDiscountIcon } from '../shared/CardDiscountIcon';
import { ProductCardImage } from './product-card-image';

import classes from './DesktopCard.module.scss';

interface IDesktopCardCatalogProps {
  item: Product;
  withPrice?: boolean;
  onClick?: (product: Product) => void;
  onFavoriteClick?: (ev: SyntheticEvent | MouseEvent) => void;
  isWatch?: boolean;
  isSelected: boolean;
  isPriceByPackage?: boolean;
}

const DesktopCard: FC<IDesktopCardCatalogProps> = ({
  item,
  withPrice,
  onClick = () => null,
  onFavoriteClick,
  isSelected,
  isWatch,
  isPriceByPackage
}) => {
  if (!item) {
    return null;
  }
  const productCountryFlag = item.attributes.country || {};
  const productManufacturer = item.attributes.manufacturer || {};
  const productColor = item.attributes.color || {};
  return (
    <FlrCard
      hoverShadow
      zeroPadding
      onClick={() => onClick(item)}
      className={clsx({ [classes.selectedProduct]: isSelected }, classes.catalogCard)}
    >
      <div className={clsx(classes.gridContainer, { [classes.selectedProduct]: isSelected })}>
        <div className={clsx(classes.manufacturerContainer, { [classes.selectedProduct]: isSelected })}>
          <div className={classes.flag}>
            {productCountryFlag.value ? <FlagIcon code={productCountryFlag.value as FlagIconCode} size={16} /> : null}
            <span className={classes.manufacturerLabel}>{productManufacturer.value}</span>
            <div className={classes.specialSigns}>
              {item.specialPrice && !isSelected ? (
                <Icon opacity={1} type="fireBordered" size={24} className={classes.fireBordered} />
              ) : null}
              {item.hasTransit && !isSelected ? (
                <Icon opacity={1} type="goodsComingBordered" size={24} className={classes.goodsComingBordered} />
              ) : null}
            </div>
          </div>
        </div>
        <ProductCardImage
          isSelected={isSelected}
          productImages={item.images}
          productId={item.id}
          productUnit={item.unit}
          classNameWrapper={clsx(classes.photoContainer, { [classes.selectedProduct]: isSelected })}
          className={clsx(classes.photo, { [classes.selectedProduct]: isSelected })}
        />
        <div className={clsx(classes.container, { [classes.selectedProduct]: isSelected })}>
          <div className={clsx(classes.mainContainer, classes.info)}>
            <div className={clsx(classes.name, { [classes.selectedProduct]: isSelected })}>
              {item.fullName.length > 36 && !isSelected ? (
                <Tooltip title={<span>{item.displayName}</span>}>
                  <span className={classes.mainTitle}>{item.fullName}</span>
                </Tooltip>
              ) : (
                <span className={classes.mainTitle}>{item.fullName}</span>
              )}
            </div>
            <span className={clsx(classes.mainType, { [classes.selectedProduct]: isSelected })}>
              {item.catalogCategory}
            </span>
          </div>
          {isSelected ? (
            <div className={classes.selectedAttributesContainer}>
              <ColorCircle color={productColor.value} />
              {item.attributesList.map((attr) => (
                <div
                  key={attr.code}
                  className={clsx(classes.attributeContainer, { [classes.selectedAttr]: !!isSelected })}
                >
                  <Icon
                    size={30}
                    type={attr.alias === 'height' ? 'heightWhite' : attr.alias}
                    leftOffset={11}
                    offset={6}
                    className={classes.icon}
                    opacity={1}
                  />
                  <p className={classes.otherAttributeValue}>{attr.value}</p>
                </div>
              ))}
            </div>
          ) : (
            <div className={classes.attributesContainer}>
              <div className={classes.packageInfo}>
                <Icon type="package" size={18} offset={10} />
                <span>{item.inPackageCount}</span>
                &nbsp;
                <span>{item.unit}</span>
              </div>
              <div className={classes.otherAttributesContainer}>
                {item.attributesList?.length ? (
                  <>
                    <div className={clsx(classes.otherAttributesItem, classes.colorContainer)}>
                      <div className={classes.attributeContainer}>
                        <ColorCircle color={productColor.value} className={classes.colorCircle} />
                        <span className={classes.color}>Колір</span>
                      </div>
                      {item.attributesList[0] ? (
                        <div className={classes.attributeContainer}>
                          <Icon type={item.attributesList[0].alias} size={19} offset={12} />
                          <p className={classes.otherAttributeValue}>
                            {item.attributesList[0].value} {item.attributesList[0].format}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className={classes.otherAttributesItem}>
                      {item.attributesList.slice(1, 3).map((attr) => (
                        <div key={attr.code} className={classes.attributeContainer}>
                          <Icon size={19} type={attr.alias} offset={12} />
                          <p className={classes.otherAttributeValue}>{attr.value}</p>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          )}
        </div>
        <div
          className={clsx(classes.cardFooter, { [classes.noPrice]: !withPrice, [classes.selectedProduct]: isSelected })}
        >
          <div className={clsx(classes.price, { [classes.selectedProduct]: isSelected })}>
            <CardDiscountIcon
              discount={item.specialPriceDiscount > item.discount ? item.specialPriceDiscount : item.discount}
              details={item.discountDetails}
              className={classes.discount}
              specialPriceDiscount={item?.specialPriceDiscount}
            />
            {item.priceRange ? (
              <div className={classes.cardFooterContainer}>
                {!item.priceRangeEquals ? <span className={classes.minPriceLabel}>Від</span> : null}
                <SplitedPrice
                  fontColor={'#000000'}
                  fontSize={'h3'}
                  value={item.minPrice * (isPriceByPackage ? item.inPackageCount : 1)}
                  className={classes.splitedPrice}
                />
              </div>
            ) : item.hasPreOrder && item.preOrderPrice ? (
              <div className={clsx(classes.cardFooterPricePreOrder, { [classes.preOrder]: item.hasPreOrder })}>
                {moment(item?.preOrderPriceDate).isValid() ? (
                  <Text className={classes.preOrderText}>На {moment(item.preOrderPriceDate).format('DD.MM.YY')}</Text>
                ) : null}
                <>
                  <span className={classes.boldText}>~</span>
                  <SplitedPrice
                    value={item.preOrderPriceWithDiscount * (isPriceByPackage ? item.inPackageCount : 1)}
                    fontSize={'h3'}
                    className={classes.splitedPriceDiscount}
                    fontColor={'inherit'}
                  />
                </>
              </div>
            ) : (
              cardMessages.notInStock.defaultMessage
            )}
          </div>
        </div>
        {withPrice && (
          <IconButton
            size="small"
            onClick={onFavoriteClick}
            className={clsx(classes.favourite, {
              [classes.productSelected]: isSelected,
              [classes.selected]: isWatch
            })}
          >
            <Icon type={isWatch ? 'heartFull' : 'heart'} size={26} />
          </IconButton>
        )}
      </div>
    </FlrCard>
  );
};

export default DesktopCard;
