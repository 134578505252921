import * as React from "react";
import { Hidden, Drawer } from "@mui/material";
import clsx from "clsx";

import { Product } from "models/product";

import MiniDetails from "pages/catalog/layout/right-bar/MiniDetails";
import AsideDeviceTopPanel from "components/layout/aside-device/header";

interface IProps {
  product: Product | null;
  onClose: () => void;
}

const ProductDetailsMobile: React.FC<IProps> = ({ product, onClose }) => {
  return (
    <Hidden smUp>
      <Drawer
        className={clsx("rightDrawer", "temporary")}
        open={!!product}
        onClose={onClose}
        variant={"temporary"}
        anchor={"right"}
        classes={{
          paper: "leftDrawerPaper"
        }}
      >
        {product && <AsideDeviceTopPanel onClose={onClose} title={"Детальніше про товар"} />}
        <MiniDetails noOffers mobileProduct={product} />
      </Drawer>
    </Hidden>
  );
};

export default ProductDetailsMobile;
