import React from 'react';
import { useSnackbar } from 'notistack';
import { Button, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form';

import SnackbarContent from 'components/layout/notistack-provider/SnackbarWithTitle';

import messages from 'translations/main/contacts';
import messagesControls from 'translations/layout/controls';
import { HttpClient } from 'utils/http';
import env from 'environment';
import styles from './styles';
import FlrInputPhone from 'components/shared/form-elements/textfield/FlrInputPhone';
import { validatePhoneMask } from 'utils/helpers';

interface IFormData {
  name: string;
  phone: string;
  email: string;
  message: string;
}

interface IProps {
  // component own props
  classes: any;
}

const ContactsForm: React.FC<IProps> = ({ classes }) => {
  const { handleSubmit, errors, control, reset } = useForm<IFormData>();
  const [isLoadingDev, setIsLoadingDev] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit((fields): any => {
    setIsLoadingDev(true);

    const httpClient = new HttpClient(`${env.apiUrl}/form/contact-us`);
    const token = window.localStorage.getItem('token') || '';
    const headers = token ? { Authorization: token } : {};
    httpClient
      .post('', fields, { headers })
      .then(() => {
        reset({});
        enqueueSnackbar(<SnackbarContent>{messages.notificationSuccess.defaultMessage}</SnackbarContent>, {
          variant: 'success'
        });
      })
      .catch(res => {
        if (!res.ok) {
          enqueueSnackbar(<SnackbarContent>{messages.notificationError.defaultMessage}</SnackbarContent>, {
            variant: 'error'
          });
          if (res.parsedErrors) {
            // todo handle error
          }
        }
      })
      .finally(() => {
        setIsLoadingDev(false);
      });
  });

  return (
    <form onSubmit={onSubmit} noValidate className={classes.form}>
      <Controller
        className={classes.input}
        error={Boolean(errors.name)}
        helperText={errors.name && errors.name.message}
        as={TextField}
        rules={{ required: messagesControls.requiredFiled.defaultMessage }}
        name="name"
        control={control}
        defaultValue=""
        label={messages.namePlaceholder.defaultMessage}
        fullWidth
        variant={'outlined'}
      />
      <Controller
        className={classes.input}
        error={Boolean(errors.phone)}
        helperText={errors.phone && errors.phone.message}
        as={FlrInputPhone}
        rules={{
          required: messagesControls.requiredFiled.defaultMessage,
          validate: validatePhoneMask
        }}
        name="phone"
        type="phone"
        control={control}
        defaultValue=""
        label={messages.phonePlaceholder.defaultMessage}
        fullWidth
        variant={'outlined'}
      />
      <Controller
        className={classes.input}
        error={Boolean(errors.email)}
        helperText={errors.email && errors.email.message}
        as={TextField}
        rules={{
          required: messagesControls.requiredFiled.defaultMessage,
          pattern: {
            value: /^\S+@\S+$/i,
            message: messagesControls.validEmail.defaultMessage
          }
        }}
        name="email"
        type="email"
        control={control}
        defaultValue=""
        label={messages.emailPlaceholder.defaultMessage}
        fullWidth
        variant={'outlined'}
      />
      <Controller
        className={classes.input}
        error={Boolean(errors.message)}
        helperText={errors.message && errors.message.message}
        as={TextField}
        rules={{ required: messagesControls.requiredFiled.defaultMessage }}
        name="message"
        type="text"
        control={control}
        defaultValue=""
        label={messages.textPlaceholder.defaultMessage}
        fullWidth
        variant={'outlined'}
        multiline
        rows={5}
      />
      <div className={classes.buttonContainer}>
        <Button
          className={classes.buttonSubmit}
          size={'large'}
          disabled={isLoadingDev}
          color={'primary'}
          variant={'contained'}
          type={'submit'}
        >
          {messages.submit.defaultMessage}
        </Button>
      </div>
    </form>
  );
};

export default withStyles<any>(styles)(ContactsForm);
