import { defineMessages } from 'react-intl';

export default defineMessages({
  tableViewHeaderName: {
    id: 'tableView.Header.Name',
    description: 'tableView.Header.',
    defaultMessage: 'Товар'
  },
  tableViewHeaderManufacturer: {
    id: 'tableView.Header.Manufacturer',
    description: 'tableView.Header.Manufacturer',
    defaultMessage: 'Виробник'
  },
  tableViewHeaderAttrs: {
    id: 'tableView.Header.Attrs',
    description: 'tableView.Header.Attrs',
    defaultMessage: 'Характеристики'
  },
  tableViewHeaderCategory: {
    id: 'tableView.Header.Category',
    description: 'tableView.Header.Category',
    defaultMessage: 'Підкатегорія'
  },
  tableViewHeaderPrice: {
    id: 'tableView.Header.Price',
    description: 'tableView.Header.Price',
    defaultMessage: 'Ціна'
  },
  tableViewHeaderDiscount: {
    id: 'tableView.Header.Discount',
    description: 'tableView.Header.Discount',
    defaultMessage: 'Знижка'
  },
  tableViewHeaderUnit: {
    id: 'tableView.Header.Unit',
    description: 'tableView.Header.Unit',
    defaultMessage: 'Шт. в уп.'
  },
  tableViewHeaderInCartCount: {
    id: 'tableView.Header.InCartCount',
    description: 'tableView.Header.InCartCount',
    defaultMessage: 'В кошику'
  },
  tableViewHeaderAvailable: {
    id: 'tableView.Header.Available',
    description: 'tableView.Header.Available',
    defaultMessage: 'Наявно'
  }
});
