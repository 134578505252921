import React from 'react';
import GooglePlacesAutocomplete from 'react-places-autocomplete';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getOutlets } from '../../../../store/outlets/selectors';
import { CircularProgress, TextField } from '@mui/material';
import { Outlet } from '../../../../models';
import { isEmpty, noop } from 'lodash/fp';
import { withStyles } from '@mui/styles';
import styles from '../../modals/outlet/styles';
import clsx from 'classnames';
import Autocomplete from '@mui/lab/Autocomplete/Autocomplete';
import { ICoordinates } from '../../contacts/OfficesMap';
import MapWithWrapper from './MapWithWrapper';
// import Icon from '../../Icon';

const AddressAutocomplete = ({
  value: address,
  onChange: setAddress,
  geo,
  setGeo, // cb to set form value
  withNoOptions,
  editInTable,
  helperText,
  outlet,
  inputVariant = 'outlined',
  classes,
  withOutlets,
  setValue = noop,
  onAddressDidMount = noop,
  ...props
}: any) => {
  const { data: outlets } = useSelector(getOutlets);
  const [inputValue, onInputChange] = React.useState(address || (outlet && outlet.address) || '');
  const [geoFetchingError, setGeoFetchingError] = React.useState('');
  const [place, setPlace] = React.useState<ICoordinates | null>(null);
  const [mapOpened, setMapOpened] = React.useState(false);
  const [geoData, setGeoData] = React.useState(outlet);

  const getGeo = (value: any) =>
    axios
      .get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          key: 'AIzaSyCNYRpmzZRVAtfmhrLbTg4TyypO-T_dj18',
          address: value,
          language: 'uk',
          region: 'ua'
        }
      })
      .then(res => {
        let parsedData;

        if (setGeo) {
          if (res.data.results.length) {
            parsedData = setGeo(res.data, value);
            setGeoData(parsedData);

            if (geoFetchingError) {
              setGeoFetchingError('');
            }
          } else {
            setGeoFetchingError('Невірна адреса');
          }
        }
        return [res, parsedData];
      })
      .catch((...err) => {
        if (value) {
          setGeoFetchingError('Невірна адреса');
        }
      });

  const onSetPlace = (cords: ICoordinates) => {
    setPlace(cords);
    onInputChange('');
    setValue('customAddress', true);
    // tslint:disable-next-line:only-arrow-functions
    getGeo(`${cords.lat}, ${cords.lng}`).then(function(response) {
      const data = response[1];
      onInputChange(new Outlet(data).address);
    });
  };

  const onSelect = (value: any) => {
    setAddress(value);
    onInputChange(value);

    if (setGeo) {
      getGeo(value);
    }
  };

  const onChange = (newValue: string) => {
    if (geoFetchingError) {
      setGeoFetchingError('');
    }
    onInputChange(newValue);
    setValue('customAddress', false);
    getGeo(newValue);
  };

  React.useEffect(() => {
    if (editInTable && !address) {
      onInputChange(new Outlet(props.rowData).address);
    }
    // eslint-disable-next-line
  }, []);

  // allow presetting address
  React.useEffect(() => {
    if (onAddressDidMount) {
      onAddressDidMount(onChange);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <GooglePlacesAutocomplete
      value={inputValue}
      onChange={onChange}
      onSelect={onSelect}
      searchOptions={{ language: 'uk', componentRestrictions: { country: ['ua'] } }}
    >
      {({ getInputProps, suggestions, loading }: any) => {
        const hasSuggestions = !isEmpty(suggestions);
        const options = hasSuggestions ? suggestions : loading || !withOutlets ? [] : outlets;

        return (
          <div style={{ position: 'relative' }} className={'autocomplete-wrapper'}>
            <Autocomplete
              className={clsx(classes.controlContainer)}
              style={{ display: mapOpened ? 'none' : 'block' }}
              filterOptions={(x: any) => x}
              renderInput={params => (
                <TextField
                  label={'Введіть повну адресу'}
                  {...getInputProps()}
                  {...params}
                  value={inputValue}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      height: 43,
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: 2
                    },
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress className={classes.loader} color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                        {/* <Icon
                          type={'world'}
                          title={'Вибрати на карті'}
                          style={{
                            color: 'rgba(0, 0, 0, 0.6)',
                            cursor: 'pointer'
                          }}
                          onClick={() => setMapOpened(true)}
                        /> */}
                      </React.Fragment>
                    )
                  }}
                  required={true}
                  variant={inputVariant}
                  helperText={geoFetchingError || helperText}
                  error={Boolean(geoFetchingError || helperText)}
                />
              )}
              options={options}
              getOptionLabel={(option: any) => option.description || option.address || option}
              openOnFocus
              onChange={(e: any, item: any) => {
                if (item) {
                  onSelect(item.description || item.address);
                }
              }}
              value={inputValue}
              noOptionsText={'Немає результатів'}
            />

            {mapOpened && (
              <MapWithWrapper
                setMapOpened={setMapOpened}
                place={place || geoData}
                address={inputValue}
                setPlace={onSetPlace}
              />
            )}
          </div>
        );
      }}
    </GooglePlacesAutocomplete>
  );
};

export const parseGeoData = (geo: any, requestedValue: string, setValue: (name: string, value: string) => void) => {
  const {
    address_components,
    geometry: {
      location: { lat, lng }
    }
  } = geo.results[0];

  function findPlaceDataByType(type: string) {
    return (address_components.find((info: any) => info.types[0] === type) || { long_name: '' }).long_name;
  }

  let region = findPlaceDataByType('administrative_area_level_1');
  let city = findPlaceDataByType('locality');
  const building = findPlaceDataByType('street_number');
  let street = findPlaceDataByType('route');
  let potentialBuilding;

  // setValue("city", city);

  // case with Kyiv region
  if (!region && city === 'Київ') {
    region = 'Київська область';
  }

  // case with Unnamed Road
  if (street === 'Unnamed Road') {
    street = '';
  }

  // case with empty city
  if (!city) {
    const district = findPlaceDataByType('administrative_area_level_2');
    // if (district) {
    city = district || region || 'no city';
    // }
  }

  // case with empty building.
  // If Google does not include building number in data (perhaps not registered),
  // try to extract that from address string.
  if (!building) {
    const isNumeric = (val: string = ''): boolean => !isNaN(+val.trim());
    const isInteger = (val: number = 0): boolean => isFinite(val) && Math.floor(val) === val;
    let value;

    potentialBuilding = requestedValue.split(',').find(
      (item: string) => isNumeric(item) && +item > 0 && isInteger(+item) // ignore coordinates values
    );

    value = (potentialBuilding || '').trim();
    setValue('building', value);
  } else {
    setValue('building', building);
  }

  setValue('street', street);
  setValue('city', city);
  setValue('latitude', lat);
  setValue('longitude', lng);
  setValue('region', region);
  return { region, city, street, building: building || potentialBuilding, lat, lng };
};

export default withStyles(styles)(AddressAutocomplete);
