import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { filterAndSortCatalog, IFilterAndSortProps, IFiltersData } from 'store/catalog/actions';
import { Drawer, Button, List, ListItem, ListItemText } from '@mui/material';
import Icon from 'components/shared/Icon';
import AsideDeviceTopPanel from 'components/layout/aside-device/header';
import { selectOptionsArray, selectOptions } from 'pages/catalog/layout/top-bar/SortingSelect';
import messages from 'translations/catalog/sort';
import useStyles from './styles';

interface IProps {
  filtersData: IFiltersData;
  sort: string;
  locale: string;
}

type IHeaderSort = (props: IProps) => JSX.Element;

export const HeaderSort: IHeaderSort = ({ filtersData, sort, locale }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sortingKeyState, setSortingKeyState] = useState<string>(sort || selectOptions[0]);
  const toggleDrawer = () => setDrawerOpen(state => !state);

  const setSort = (newSort: string) => {
    setSortingKeyState(newSort);
    dispatch(
      filterAndSortCatalog({ ...filtersData, sort: newSort, locale, fast: filtersData.fast } as IFilterAndSortProps)
    );
    toggleDrawer();
  };

  return (
    <div className={classes.searchBlock}>
      <div className="iconWrapper">
        <Button variant={'outlined'} onClick={toggleDrawer} className={classes.sortButton}>
          <Icon type={'sort'} size={24} />
        </Button>
      </div>
      <Drawer
        open={drawerOpen}
        anchor={'top'}
        classes={{
          paper: classes.mobileDrawer
        }}
      >
        <AsideDeviceTopPanel title={messages.mobileModalTitle.defaultMessage} onClose={toggleDrawer} />
        <List classes={{ root: classes.sortListWrapper }}>
          {selectOptionsArray.map(({ label, value }) => (
            <ListItem key={value} onClick={() => setSort(value)}>
              <span className={classes.iconWrapper}>
                {sortingKeyState === value ? <Icon type={'checkMark'} width={14} height={10} /> : null}
              </span>
              <ListItemText
                classes={{ primary: clsx({ [classes.active]: sortingKeyState === value }) }}
                primary={label}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};
