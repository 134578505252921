import React from 'react';
// import { Route, Link, Redirect } from "react-router-dom";
import { Route, Redirect } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, MapStateToProps } from 'react-redux';

// import { Box, Tabs, Tab } from "@mui/material";
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';

import { IApplicationState } from 'store/reducers';
import { useTrackInitialPageLoad } from 'controllers';
import { baseUrl } from 'shared/constants';
import AccountAssistFaq from './Faq';
// import AccountAssistSupport from "./Support";
import styles from './styles';
import messages from 'translations/account/assist';
import { TitleH1 } from 'components/shared/text';

const assistRouteRoot = `${baseUrl}/account/assist`;
const assistRouteFaq = `${baseUrl}/account/assist/faq`;
// const assistRouteSupport = `${baseUrl}/account/assist/support`;

interface IProps {
  // component own props
  classes: any;
}

interface IStateProps {
  // Props passed to the component by `connect`
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

const AccountAssist: React.FC<IComponentProps> = ({ classes }) => {
  useTrackInitialPageLoad();

  // const { setCurrentUrl, breadCrumbs } = React.useContext(breadCrumbsContext);
  // React.useEffect(() => {
  //   setCurrentUrl([...breadCrumbs, { label: messages.title.defaultMessage, path: assistRouteRootClean }]);
  //   return () => {
  //     setCurrentUrl([]);
  //   };
  // }, [setCurrentUrl]);

  // const isRootRoute = useRouteMatch(assistRouteRoot);
  // const isFaqRoute = useRouteMatch(assistRouteFaq) || isRootRoute;
  // const [value, setValue] = React.useState(isFaqRoute ? 0 : 1);

  if (useRouteMatch({ path: assistRouteRoot, exact: true })) {
    return <Redirect to={assistRouteFaq} />;
  }

  // const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
  //   setValue(newValue);
  // };

  return (
    <Box className={'actual-content'}>
      <TitleH1>{messages.title.defaultMessage}</TitleH1>

      {/*<Tabs className={classes.TabsRoot} value={value} onChange={handleChange} indicatorColor={"primary"}>*/}
      {/*  <Tab label={messages.titleFaq.defaultMessage} component={Link} to={assistRouteFaq} />*/}
      {/*  <Tab disabled label={messages.titleSupport.defaultMessage} component={Link} to={assistRouteSupport} />*/}
      {/*</Tabs>*/}

      <div className={classes.RootContent}>
        <Route path={assistRouteRoot} component={AccountAssistFaq} />
        {/*<Route path={assistRouteSupport} component={AccountAssistSupport} />*/}
      </div>
    </Box>
  );
};

const mapStateToProps: MapStateToProps<{}, {}, IApplicationState> = (state: IApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(AccountAssist));
