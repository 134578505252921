import React from "react";
import clsx from "clsx";
import { SnackbarProvider, useSnackbar } from "notistack";
import { withStyles } from "@mui/styles";
import { IconButton } from "@mui/material";

import AlertIconSuccess from "components/shared/Alert/AlertIconSuccess";
import AlertIconError from "components/shared/Alert/AlertIconError";
import AlertIconWarning from "components/shared/Alert/AlertIconWarning";
import AlertIconInfo from "components/shared/Alert/AlertIconInfo";
import { Icon } from "components/shared";
import Notifier from "./Notifier";

import styles from "./styles";

export interface IProps {
  classes?: any;
  children?: any;
}

/**
 * Close button component
 */
type Key = string | number | undefined;
interface ICloseSnackbarProps {
  keyMessage: Key;
  classes?: any;
}
const CloseButton: React.FC<ICloseSnackbarProps> = ({ keyMessage, classes }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      className={classes.closeButton}
      aria-label="Close notification"
      color="inherit"
      size={"small"}
      onClick={() => closeSnackbar(keyMessage)}
    >
      <Icon type={"cancel"} size={12} offset={16} />
    </IconButton>
  );
};

const NotistackProvider: React.FC<IProps> = ({ children, classes }) => {
  return (
    <SnackbarProvider
      preventDuplicate
      action={key => <CloseButton keyMessage={key} classes={classes} />}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      iconVariant={{
        success: <AlertIconSuccess />,
        error: <AlertIconError />,
        warning: <AlertIconWarning />,
        info: <AlertIconInfo />,
        default: <AlertIconInfo />
      }}
      classes={{
        contentRoot: classes.common,
        variantSuccess: clsx(classes.common, classes.success),
        variantError: clsx(classes.common, classes.error),
        variantWarning: clsx(classes.common, classes.warning),
        variantInfo: clsx(classes.common, classes.info),
        anchorOriginTopRight: classes.anchorOriginTopRight
      } as any}
    >
      <Notifier />
      {children}
    </SnackbarProvider>
  );
};

export default withStyles<any>(styles)(NotistackProvider);
