import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Switch, TextField } from '@mui/material';

import { FormLink, NewCustomerMessage } from '../styles';
import { baseUrl } from 'shared/constants';
import FlrLoader from 'components/loading/LoadingSpinner';
import messagesControls from 'translations/layout/controls';
import AuthPageWrapper from '../shared/AuthPageWrapper';
import messages from 'translations/auth/forgotPassword';
import { fetchForgotPasswordAsync } from 'store/auth/actions';
import Grid from '@mui/material/Grid';
import { TitleH1 } from 'components/shared/text';
import { Text } from '../../../components/layout/content/Text';
import FlrInputPhone from '../../../components/shared/form-elements/textfield/FlrInputPhone';
import { extractDigitsFromString } from '../../../utils/helpers';

interface IFormData {
  authId: string;
}

interface IProps {
  // component own props
  classes?: any;
  isError: boolean;
  isLoading: boolean;
  fetchForgotPassword: typeof fetchForgotPasswordAsync.request;
}

const AuthIdScreen: React.FC<IProps> = ({ classes, isError, isLoading, fetchForgotPassword }) => {
  const { handleSubmit, errors, control, setValue } = useForm<IFormData>();
  const [useEmailInput, setEmailInput] = useState(true);
  const toggleEmailInput = () => {
    setValue('authId', '');
    setEmailInput(state => !state);
  };

  const onSubmit = handleSubmit(({ authId }): any => {
    if (isLoading) {
      return;
    }

    fetchForgotPassword(useEmailInput ? authId : extractDigitsFromString(authId));
  });

  return (
    <AuthPageWrapper classes={classes}>
      <TitleH1 style={{ fontSize: 28 }}>{messages.header.defaultMessage}</TitleH1>
      <NewCustomerMessage>
        {messages.alreadyRegistered.defaultMessage}
        <FormLink to={`${baseUrl}/login`}>{messages.login.defaultMessage}</FormLink>
      </NewCustomerMessage>

      <form onSubmit={onSubmit}>
        <Controller
          disabled={isLoading}
          error={Boolean(errors.authId || isError)}
          helperText={
            (errors.authId && errors.authId.message) || isError ? messages.formFieldLoginError.defaultMessage : ''
          }
          rules={{
            required: messagesControls.requiredFiled.defaultMessage
          }}
          as={useEmailInput ? TextField : FlrInputPhone}
          name="authId"
          control={control}
          defaultValue=""
          className={classes.loginControl}
          fullWidth
          variant={'outlined'}
          label={useEmailInput ? 'Пошта' : 'Телефон'}
        />

        <Grid container alignItems={'center'} spacing={0} style={{ marginBottom: 13, marginTop: -10 }}>
          <Switch value={useEmailInput} onChange={toggleEmailInput} />
          <Text>{useEmailInput ? 'Пошта' : 'Телефон'}</Text>
        </Grid>

        <Button color={'primary'} variant={'contained'} type={'submit'}>
          {messages.formRestorePasswordButton.defaultMessage}
        </Button>
      </form>

      {isLoading && <FlrLoader withOverlay={true} />}
    </AuthPageWrapper>
  );
};

export default AuthIdScreen;
