import React, { CSSProperties } from 'react';
import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';

interface IProps extends TypographyProps {
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  className?: string;
  style?: CSSProperties;
}

const TextSubTitle: React.FC<IProps> = ({ children, component, color, className, style, ...other }) => {
  return (
    <Typography
      className={className}
      color={color || 'textPrimary'}
      variant={'subtitle1'}
      component={component || 'p'}
      style={style}
      {...other}
    >
      {children}
    </Typography>
  );
};

export default TextSubTitle;
