import { ExpandPanel } from '../../../shared';
import { useState, FC } from 'react';
import messages from 'translations/cart/common';
import classes from './CartItemCategory.module.scss'
import { countriesMap } from '../../../../shared/countriesMap';

interface ICartItemCategoryProps {
  catalogCategory: string;
  productGroup: string;
  inPackageCount: number;
  manufacturerName: string;
  country: string;
  code: string;
}
interface ICategoryData {
  label: string;
  value: string | number;
}
const CartItemCategory: FC<ICartItemCategoryProps> = ({
  catalogCategory,
  productGroup,
  country,
  manufacturerName,
  code,
  inPackageCount
}) => {
  const [expand, setExpand] = useState(false);

  const handleChange = () => {
    setExpand(prevState => !prevState);
  };

  const categoryData:ICategoryData[] = [
    {label: messages.catalogCategory.defaultMessage, value: catalogCategory},
    {label: messages.productGroup.defaultMessage, value: productGroup},
    {label: messages.inPackageCount.defaultMessage, value: inPackageCount},
    {label: messages.manufacturerName.defaultMessage, value: manufacturerName},
    {label: messages.country.defaultMessage, value: countriesMap[country]?.name},
    {label: messages.code.defaultMessage, value: code},
  ];

  return (
    <ExpandPanel
      onChange={handleChange}
      label={expand ? messages.hideAllCategories.defaultMessage : messages.showAllCategories.defaultMessage}
      classNameHeading={classes.heading}
      classNameDetails={classes.details}
      classNameSummary={classes.summary}
    >
      {categoryData.map((item, key)=>(
        <div key={key} className={classes.container}>
          <span className={classes.label}>{item.label}</span>
          <span className={classes.value}>{item.value}</span>
        </div>
      ))}
    </ExpandPanel>
  );
};

export default CartItemCategory;
