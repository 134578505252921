import { FC } from 'react';
import clsx from 'clsx';

import { ExpandPanel } from 'components/shared';
import FlrCard from 'components/shared/card/FlrCard';
import { OrderItem } from 'models';

import OrderCardItemDeviceData from './OrderCardItemData';
import OrderCardItemLabel from './OrderCardItemLabel';

import classes from './OrderCardItem.module.scss';
import { GridDivider } from '../../../shared/table/dividers';

interface IOrderCardItemDeviceProps {
  isFirst: boolean;
  removeDisabled: boolean;
  max?: number;
  item: OrderItem;
  onChangeAmount?: (qty: number) => void;
  onRemove?: () => void;
  withDiscount?: boolean;
  withItemRemove?: boolean;
  isNotEditable?: boolean;
  selected?: boolean;
  isPreOrder?: boolean;
  onSelect: () => void;
  onUnselect: () => void;
}

const OrderCardItemDevice: FC<IOrderCardItemDeviceProps> = ({
  selected,
  isNotEditable,
  isFirst,
  removeDisabled,
  max,
  item,
  onChangeAmount,
  onRemove,
  onSelect,
  onUnselect,
  withDiscount,
  isPreOrder
}) => {
  const handleChange = () => {
    if (selected) {
      onUnselect();
    } else {
      onSelect();
    }
  };

  const OrderContent = () => {
    return (
      <ExpandPanel
        label={<OrderCardItemLabel item={item} selected={selected} onSelect={onSelect} onUnselect={onUnselect} />}
        onChange={handleChange}
        leftIcon
        expanded={selected}
        classNameDetails={classes.details}
      >
        <OrderCardItemDeviceData
          isNotEditable={isNotEditable}
          isPreOrder={isPreOrder}
          max={max}
          onRemove={onRemove}
          onChangeAmount={onChangeAmount}
          item={item}
          withDiscount={withDiscount}
          withItemRemove={!isNotEditable}
          removeDisabled={removeDisabled}
        />
      </ExpandPanel>
    );
  };

  return (
    <>
      <div className={clsx(classes.tableItem, { firstItem: isFirst, [classes.outlineMargin]: selected })}>
        {selected ? (
          <FlrCard className={clsx(classes.cartCardItemAnchor, classes.active)}>
            <OrderContent />
          </FlrCard>
        ) : (
          <OrderContent />
        )}
      </div>
      <GridDivider />
    </>
  );
};

export default OrderCardItemDevice;
