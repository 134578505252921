import React from 'react';

import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { withStyles } from '@mui/styles';

import { TitleH1 } from 'components/shared/text';

import messages from 'translations/account/order';
import styles from './styles';

interface IProps {
  classes: any;
}

const AccountOrderReviewPanelSkeleton: React.FC<IProps> = ({ classes }) => {
  return (
    <>
      <div className={classes.reviewContainer}>
        <TitleH1 className={classes.reviewTitle} align={'center'}>
          {messages.reviewTitle.defaultMessage}
        </TitleH1>

        <div className={classes.ratingsContainer}>
          <Grid className={classes.ratingsItem} container alignItems={'center'} justifyContent={'space-between'}>
            <Skeleton variant="rectangular" width={'25%'} height={16} style={{ marginBottom: 12 }} />

            <Skeleton variant="rectangular" width={'25%'} height={16} style={{ marginBottom: 12 }} />
          </Grid>

          <Grid className={classes.ratingsItem} container alignItems={'center'} justifyContent={'space-between'}>
            <Skeleton variant="rectangular" width={'25%'} height={16} style={{ marginBottom: 12 }} />

            <Skeleton variant="rectangular" width={'25%'} height={16} style={{ marginBottom: 12 }} />
          </Grid>

          <Grid className={classes.ratingsItem} container alignItems={'center'} justifyContent={'space-between'}>
            <Skeleton variant="rectangular" width={'25%'} height={16} style={{ marginBottom: 12 }} />

            <Skeleton variant="rectangular" width={'25%'} height={16} style={{ marginBottom: 12 }} />
          </Grid>
        </div>

        <Skeleton variant="rectangular" width={'80%'} height={16} style={{ marginBottom: 12 }} />

        <Skeleton variant="rectangular" width={150} height={16} style={{ marginBottom: 12 }} />

        <Skeleton variant="rectangular" width={150} height={16} style={{ marginBottom: 12 }} />
      </div>
    </>
  );
};

export default withStyles<any>(styles)(AccountOrderReviewPanelSkeleton);
