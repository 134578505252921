import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';

import { DEFAULT_AMOUNT } from 'components/cards/shared/BalanceCard';
import { ExpandPanel } from 'components/shared';
import Alert from 'components/shared/Alert/Alert';
import PaymentModal from 'components/shared/modals/payment/PaymentModal';
import { TitleH1 } from 'components/shared/text';
import { getAccountDiscountsData } from 'store/account-discounts/selectors';
import { getUserAccount } from 'store/account/selectors';
import messages from 'translations/account/loyalty';

import { breadCrumbsContext } from '../breadCrumbsState';
import DashboardLoyalty from '../dashboard/DashboardLoyalty';
import { BalanceHistoryCard } from './cards';
import { Deposit } from './deposit/Deposit';
import GroupProducts from './group-products/GroupProducts';
import { loyaltyBreadcrumb, loyaltyBreadcrumbMobile } from './index';

import classes from './BalanceDiscount.module.scss';

const BalanceDiscount: FC = () => {
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalPayOpen, setModalPayOpen] = useState(false);
  const [tab, setTab] = useState<number>(0);
  const account = useSelector(getUserAccount);
  const discountConditions = useSelector(getAccountDiscountsData);
  const breadcrumb = screenMobile ? loyaltyBreadcrumbMobile : loyaltyBreadcrumb;
  const { setCurrentUrl } = useContext(breadCrumbsContext);
  const updateBreadCrumbs = useCallback(
    (bc) => {
      setCurrentUrl([{ label: bc.label, path: bc.path }]);
    },
    [setCurrentUrl]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    updateBreadCrumbs(breadcrumb);

    return () => {
      setCurrentUrl([]);
    };
  }, [breadcrumb, setCurrentUrl, updateBreadCrumbs]);

  const handlePayCancel = () => {
    setModalPayOpen(false);
  };

  const handleOpenPayModal = () => {
    setModalPayOpen(true);
  };

  const creditUsed = account && account.profile.balance < 0 ? -1 * account.profile.balance : DEFAULT_AMOUNT;

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const discountMessages = {
    increase: [
      {
        text: (
          <span>
            Якщо на <b>початок тижня (00:01 понеділка)</b> депозит відповідає умовам, Ви отримаєте відповідну знижку на
            всі товари Флоротеки впродовж поточного тижня.
            <p className={classes.maxDiscount}>Максимальна знижка - 3%.</p>
          </span>
        )
      },
      {
        text: (
          <span>
            Кількість куплених товарів з групи накопичується з 1 по останнє число поточного місяця. Від кількості
            куплених товарів залежатиме відсоток знижки у наступному місяці.
            <p className={classes.maxDiscount}>Максимальна знижка - 10%.</p>
          </span>
        )
      }
    ],
    reduction: [
      {
        text: (
          <span>
            Якщо на початок тижня (00:01 понеділка) депозит <b>НЕ відповідає</b> умовам, Ваша знижка дорівнюватиме 0%.
          </span>
        )
      },
      {
        text: (
          <span>
            Кількість повернутих товарів віднімається від накопиченої кількості та може призвести до зниження знижки.
          </span>
        )
      }
    ]
  };

  const ruleText = (isDiscountReduction = false) => {
    const type = isDiscountReduction ? 'reduction' : 'increase';
    return discountMessages[type][tab]?.text || null;
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <TitleH1 className={classes.title}>{messages.titlePromotions.defaultMessage}</TitleH1>
          <Alert type="info" title={messages.alertPromotions.defaultMessage} className={classes.outlineMargin}>
            {messages.alertPromotionsText.defaultMessage}
          </Alert>
          <Tabs
            onChange={handleTabChange}
            value={tab}
            indicatorColor="primary"
            variant="standard"
            className={classes.tabs}
          >
            <Tab className={classes.tab} label={screenMobile ? 'Депозит' : 'Знижка за депозит'} />
            <Tab className={classes.tab} label={screenMobile ? 'Група товарів' : 'Знижки на групи товарів'} />
            <Tab className={classes.tab} label="Діючі акції" />
          </Tabs>
          <div className={clsx(classes.tabContent, { [classes.lastTabContent]: tab === 2 })}>
            {tab === 0 ? (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                <Deposit
                  onClick={handleOpenPayModal}
                  progressValue={discountConditions?.accountBalanceDiscount?.discountData.progressValue || 0}
                  balance={discountConditions?.balance?.[0]}
                />
                <BalanceHistoryCard mobile={screenMobile} />
              </div>
            ) : null}
            {tab === 1 ? <GroupProducts discounts={discountConditions} /> : null}
            {tab === 2 ? (
              <DashboardLoyalty
                discountConditions={discountConditions?.permanentDiscounts}
                hideTitle
                hideProfileDiscount
                smallCards
                isBalanceLoyaltyCard
              />
            ) : null}

            {tab === 0 || tab === 1 ? (
              <div className={classes.wrapperRules}>
                {screenMobile ? (
                  <ExpandPanel
                    classNameLabel
                    classNameHeading={classes.heading}
                    classNameSummary={classes.summary}
                    classNameDetails={classes.details}
                    className={classes.expandPanel}
                    label="Правила"
                  >
                    <div className={classes.increaseDiscountContainer}>
                      <p className={classes.ruleTitle}>Підвищення знижки</p>
                      {ruleText(false)}
                      <p className={classes.ruleTitle}>Зниження знижки</p>
                      {ruleText(true)}
                    </div>
                  </ExpandPanel>
                ) : (
                  <div className={classes.increaseDiscountContainer}>
                    <p className={classes.ruleTitle}>Підвищення знижки</p>
                    {ruleText(false)}
                    <p className={classes.ruleTitle}>Зниження знижки</p>
                    {ruleText(true)}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {modalPayOpen && <PaymentModal open={modalPayOpen} handleClose={handlePayCancel} amount={creditUsed} />}
    </div>
  );
};

export default BalanceDiscount;
