import React, { useEffect, useMemo, useRef, useState } from 'react';
import { findDOMNode } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AutoSizer, List, ListRowProps, ScrollParams, Size } from 'react-virtualized';
import clsx from 'clsx';
import { isMobileDevice } from 'environment';
import { debounce } from 'lodash';

import { CatalogProductCardMemoized, CatalogProductRowContainer } from 'components/cards/product';
import { IProductAlphabet, IProductAlphaLetter, Product } from 'models/product';
import {
  productCardHeight as defaultCardHeight,
  headerHeight,
  productCardMinWidth,
  productCardsMargin,
  productListPadding
} from 'shared/constants';
import {
  CATALOG_SET_COLUMNS,
  CATALOG_TOGGLE_DETAILS,
  CATALOG_UPDATE_HIGHLIGHTED_ALPHABET,
  filterAndSortCatalog,
  ICatalogLayout,
  IFiltersData,
  ISelectedProduct
} from 'store/catalog/actions';
import {
  getCatalogAlphabet,
  getCatalogFilters,
  getCatalogInStock,
  getCatalogLayout,
  getCatalogLoadedState,
  getCatalogLoadingState,
  getCatalogSort,
  getCatalogSortedState,
  getCatalogSpecialOffers
} from 'store/catalog/selectors';
import { getFilterOptionsLoadingState } from 'store/filter-options/selectors';
import { usePrevious } from 'utils/hooks';
import { defaultMaterialTheme } from 'utils/styled';

import Skeletons from '../components/Skeletons';
import CatalogTopBar from '../layout/top-bar';
import { CatalogTableHeader } from './catalog-table-header';
import { ComponentWrapper, Wrapper } from './styles';

const isMobile = isMobileDevice(window.navigator);

// type IOnRowsRendered = (params: {
//   overscanStartIndex: number;
//   overscanStopIndex: number;
//   startIndex: number;
//   stopIndex: number;
// }) => void;

interface IProductCatalogProps {
  cardHeight?: number;
  withPrice: boolean;
  productRows: Product[][];
  selected: ISelectedProduct;
  onSelectProduct: (product: Product) => void;
  noAlphabet?: boolean;
  catalogLoaded: boolean;
  filters: IFiltersData;
  filterOptionsLoading: boolean;
  catalogLoading: boolean;
  sorted: boolean;
  sort: string;
  alphabet: IProductAlphabet;
  layout: ICatalogLayout;
  // productsMap: Map<any, any>;
  loadMoreRows: (args: { startIndex: number; stopIndex: number }) => Promise<any>;
  debouncedLoadRows?: any;
  isRowLoaded: (args: { index: number }) => boolean;
  locale: string;
  filterAndSortCatalog: typeof filterAndSortCatalog;
}

const ItemsNotFound = () => (
  <div style={{ margin: 16, textAlign: 'center', width: '100%' }}>
    За наданими параметрами фільтрації товарів не знайдено
  </div>
);

const ProductCatalog: React.FC<IProductCatalogProps> = (props) => {
  const {
    cardHeight,
    productRows,
    onSelectProduct,
    selected,
    withPrice,
    noAlphabet,
    catalogLoaded,
    filters,
    filterOptionsLoading,
    catalogLoading,
    sorted,
    sort,
    alphabet,
    layout,
    // productsMap,
    // loadMoreRows,
    // debouncedLoadRows,
    // isRowLoaded,
    filterAndSortCatalog,
    locale
  } = props;
  let topRow = 0;
  const loadingPrev = false;
  const productCardHeight = cardHeight || defaultCardHeight;
  const [lastProductSeen, setLastProductSeen] = useState(0);
  const isTableLayout: boolean = window.localStorage.getItem('layoutType') === 'table';
  const [visibleRows, setVisibleRows] = useState(
    Math.round((window.innerHeight - headerHeight) / (productCardHeight + (isTableLayout ? 0 : productCardsMargin)))
  );
  const dispatch = useDispatch();
  const productsListRef: React.RefObject<List> = useRef(null);
  const loading = filterOptionsLoading || catalogLoading;
  const layoutColumns = layout.columns;
  const listRef = productsListRef && productsListRef.current;
  const selectedProductId = selected && selected.id;
  const node = useMemo(() => findDOMNode(listRef) as HTMLElement, [listRef]);

  const handleSelectProduct = (product: Product) => {
    onSelectProduct(product);
    dispatch({ type: CATALOG_TOGGLE_DETAILS, payload: true });
  };
  const catalogSpecialOffers = useSelector(getCatalogSpecialOffers);
  const catalogInStock = useSelector(getCatalogInStock);

  const hasStockOffers = catalogInStock || catalogSpecialOffers;
  const rowRenderer = ({ index, key, style, isVisible }: ListRowProps) => {
    if (!isVisible) {
      return null;
    }

    const productRow = productRows[index];

    if (isTableLayout) {
      if (index === 0 && isMobile) {
        return <CatalogTableHeader key={key} style={style} isMobile={isMobile} showStockColumn={hasStockOffers} />;
      }
      return (
        <div
          key={key}
          style={{
            ...style,
            top: isMobile ? Number(style.top) - 34 : Number(style.top) - 36,
            height: isMobile ? Number(style.height) + 12 : style.height,
            gridAutoRows: isMobile ? 78 : style.gridAutoRows
          }}
        >
          {productRow &&
            productRow.map((product) => (
              <CatalogProductRowContainer
                key={`product-card-${index}-${product.id}`}
                item={product}
                onClick={handleSelectProduct}
                isSelected={selectedProductId === product.id}
                withPrice={withPrice}
                isMobile={isMobile}
                showStockColumn={hasStockOffers}
              />
            ))}
        </div>
      );
    }

    return (
      <div key={key} style={{ ...style, height: isMobile ? style.height : Number(style.height) - 10 }}>
        {productRow &&
          productRow.map((product) => (
            <CatalogProductCardMemoized
              key={`product-card-${index}-${product.id}`}
              item={product}
              onClick={handleSelectProduct}
              isSelected={selectedProductId === product.id}
              withPrice={withPrice}
              isMobile={isMobile}
            />
          ))}
      </div>
    );
  };

  // const _rowRenderer = ({ index, key, style, isVisible }: ListRowProps) => {
  //   const productRow = productRows[index];
  //   const selectedProductId = selected && selected.id;

  //   if (isTableLayout) {
  //     if (index === 0) {
  //       return <CatalogTableHeader key={key} style={style} />;
  //     }

  //     return (
  //       <CatalogProductRowMemoized
  //         key={key}
  //         style={style}
  //         index={index}
  //         product={productsMap.get(productRow && productRow.length ? productRow[0].id : null)}
  //         handleSelectProduct={handleSelectProduct}
  //         selectedProductId={selectedProductId}
  //         withPrice={withPrice}
  //         isMobile={isMobile}
  //       />
  //     );
  //   }

  //   return (
  //     <CatalogProductCardContainer
  //       key={key}
  //       prodsMap={productsMap}
  //       style={style}
  //       index={index}
  //       productRow={productRow}
  //       handleSelectProduct={handleSelectProduct}
  //       selectedProductId={selectedProductId}
  //       withPrice={withPrice}
  //       isMobile={isMobile}
  //     />
  //   );
  // };

  // debounce to prevent multiple redux changes during the transition
  const updateColumns = (size: Size) => {
    const catalogContainer = document.getElementById('catalog-container');
    // add 4px for block width to prevent cards layout overlays on fullHD screens
    const blockSize = Math.min((catalogContainer && catalogContainer.offsetWidth) || window.outerWidth, size.width);
    const newColumnsNumber =
      window.localStorage.getItem('layoutType') === 'table'
        ? 1
        : Math.max(
            2,
            Math.floor(
              (blockSize - productCardsMargin - productListPadding) / (productCardMinWidth + productCardsMargin)
            )
          );
    if (newColumnsNumber !== layout.columns) {
      dispatch({
        type: CATALOG_SET_COLUMNS,
        payload: newColumnsNumber
      });
      const { inStock } = filters;
      const filtersFast = filters.fast || [];
      dispatch(filterAndSortCatalog({ ...filters, sort, locale, fast: filtersFast, inStock }));
    }
    return newColumnsNumber;
  };

  const handleResize =
    loading || !(sorted || catalogLoaded)
      ? updateColumns
      : debounce((size) => {
          const newColumnsNumber = updateColumns(size);
          if (productsListRef.current && lastProductSeen > 0 && !selected) {
            productsListRef.current.scrollToPosition(
              Math.ceil(lastProductSeen / newColumnsNumber) * (productCardHeight + productCardsMargin)
            );
          }
        }, defaultMaterialTheme.transitions.duration.enteringScreen);

  // check is we need scroll to top on product deselect
  const filtersString = JSON.stringify(filters);
  const filtersStringPrev = usePrevious(filtersString);
  const filtersChanged = filtersStringPrev !== filtersString;

  // scroll catalog to the top on sort or filters change
  useEffect(() => {
    if (!selected && productsListRef.current && catalogLoaded && !filtersStringPrev?.includes('"product":"')) {
      // scroll to top on filters change except product selection/deselection
      productsListRef.current.scrollToPosition(0);
    }
    // eslint-disable-next-line
  }, [filters]);

  // scroll to selected product row
  useEffect(() => {
    if (productsListRef.current) {
      // console.log('scroll', selected, layoutColumns, productsListRef, productRows, loadingPrev, loading);
      const productRowIndex =
        selected && productRows.findIndex((item1) => item1.findIndex((item2) => item2.id === selected.id) > -1);

      if (selected && productRowIndex) {
        // scroll to product if it exists
        if (productRowIndex && productRowIndex > -1) {
          // setTimeout fix scroll to top hook
          setTimeout(() => {
            return ((listRef2) => {
              // setLastProductSeen(productRowIndex * layoutColumns);

              if (listRef2) {
                // ! old version
                // listRef2!.scrollToRow(productRowIndex);

                // scroll to particular row coords
                const coords = listRef2!.getOffsetForRow({
                  alignment: 'auto',
                  index: productRowIndex
                });
                if (isTableLayout) {
                  const coordsEnd = listRef2!.getOffsetForRow({
                    alignment: 'end',
                    index: productRowIndex
                  });
                  if (coords - coordsEnd < 50) {
                    listRef2!.scrollToPosition(coords + 50);
                  } else {
                    listRef2!.scrollToPosition(coords);
                  }
                } else {
                  listRef2!.scrollToPosition(coords);
                }
                // setTimeout(() => {
                //   loadMoreRows({
                //     startIndex: productRowIndex - (isTableLayout ? 50 : 10),
                //     stopIndex: productRowIndex + (isTableLayout ? 50 : 10)
                //   });
                // }, 50);
              }
            })(productsListRef!.current);
          }, 0);
        } else if (productRowIndex && productRowIndex === -1) {
          // scroll to top (product not selected or absent)
          productsListRef.current.scrollToPosition(Math.random());
        }
      } else if (filtersChanged) {
        // scroll to top (product not selected or absent)
        // * scroll after component was build
        setTimeout(() => {
          if (productsListRef.current) {
            productsListRef.current.scrollToPosition(Math.random());
          }
        }, 10);
      }
    }
    // eslint-disable-next-line
  }, [selected, layoutColumns, productsListRef, loadingPrev, loading, sorted]);

  useEffect(() => {
    if (noAlphabet) {
      return;
    }
    updateAlphabet(0, (node && node.offsetHeight) || 0);
    // eslint-disable-next-line
  }, [alphabet]);

  const handleLetterClick = (letter: IProductAlphaLetter) => {
    const index = letter.startsAt;
    if (index > -1 && productsListRef && productsListRef.current) {
      const listEl = productsListRef.current;
      const scrollTop =
        Math.floor(index / layout.columns) * (productCardHeight + (isTableLayout ? 0 : productCardsMargin * 1.5));
      listEl.scrollToPosition(scrollTop);
      // loadMoreRows({ startIndex: scrollTop, stopIndex: scrollTop + (isTableLayout ? 70 : 20) });
    }
  };

  const handleScroll = (params: ScrollParams) => {
    if (noAlphabet) {
      return;
    }
    if (params.scrollTop < 1 && params.scrollTop !== 0) {
      return;
    }
    topRow = Math.round((params.scrollTop / params.scrollHeight) * productRows.length);
    updateAlphabet(topRow, params.clientHeight);
  };

  const updateAlphabet = (topRowDisplayed: number, clientHeight: number) => {
    setLastProductSeen(topRowDisplayed * layout.columns);
    if (!sorted) {
      return;
    }
    if (['name_asc', 'name_desc'].indexOf(sort) > -1) {
      const itemHeight = productCardHeight + (isTableLayout ? 0 : productCardsMargin);
      const rowsToShow: number = Math.round(clientHeight / itemHeight) || visibleRows;
      setVisibleRows(rowsToShow);

      const updatedAlphabet: IProductAlphaLetter[] = alphabet.map((letter: IProductAlphaLetter) => {
        const firstRowIndex = Math.floor(letter.startsAt / layout.columns);
        const lastRowIndex = Math.floor(letter.endsAt / layout.columns);

        const startsAfter = firstRowIndex > topRowDisplayed + rowsToShow;
        const endsBefore = lastRowIndex < topRowDisplayed;
        const hasProductsDisplayed = !(startsAfter || endsBefore);
        return { ...letter, selected: hasProductsDisplayed };
      });

      dispatch({ type: CATALOG_UPDATE_HIGHLIGHTED_ALPHABET, payload: updatedAlphabet || [] });
    }
  };

  // const rowsCount = isTableLayout && !isMobile ? productRows.length + 1 : productRows.length;

  // const onRowsRenderedHandler = (cb: any, rest: any) => {
  //   if (!loading && catalogLoaded) {
  //     debouncedLoadRows({ startIndex: rest.overscanStartIndex, stopIndex: rest.overscanStopIndex });
  //   }

  //   cb(rest);
  // };

  return (
    <ComponentWrapper>
      <CatalogTopBar onNavClick={handleLetterClick} />
      <Wrapper data-items={layout.columns}>
        {(loading || !catalogLoaded || !sorted) && (
          <Skeletons
            withPrice={withPrice}
            productCardHeight={productCardHeight}
            productCardsMargin={productCardsMargin}
          />
        )}
        {!(loading || !catalogLoaded || !sorted) && productRows.length === 0 && <ItemsNotFound />}
        {!(loading || !catalogLoaded || !sorted) && productRows.length !== 0 && (
          <div style={{ width: '100%', height: '100%' }}>
            {!isMobile && isTableLayout ? (
              <div className='stickyHeader'>
                <CatalogTableHeader showStockColumn={hasStockOffers} />
              </div>
            ) : null}
            <AutoSizer onResize={handleResize}>
              {({ width, height }: any) => {
                const rowsCount = isTableLayout && !isMobile ? productRows.length + 1 : productRows.length;
                return (
                  <List
                    className={clsx({ withPrice, 'table-layout-wrapper': isTableLayout })}
                    ref={productsListRef}
                    height={height}
                    width={width}
                    rowRenderer={rowRenderer}
                    rowCount={loading ? Math.ceil(height / productCardHeight) : rowsCount}
                    // this row height is taken as 260 - ProductCard Height + 12px margin, same as grid-gap
                    rowHeight={productCardHeight + (isTableLayout ? 0 : productCardsMargin)}
                    onScroll={debounce(handleScroll, 200)}
                    overscanRowCount={1}
                  />
                );
              }}
            </AutoSizer>
          </div>
        )}

        {/* {(loading || !catalogLoaded || !sorted) && (
          <Skeletons
            withPrice={withPrice}
            productCardHeight={productCardHeight}
            productCardsMargin={productCardsMargin}
          />
        )}
        {!loading && productRows.length === 0 && catalogLoaded && <ItemsNotFound />}
        {!loading && productRows.length !== 0 && catalogLoaded && sorted && (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={rowsCount}
            minimumBatchSize={isTableLayout ? (isMobile ? 40 : 70) : 20}
            threshold={20}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer onResize={handleResize}>
                {({ height, width }) => (
                  <List
                    // ref={registerChild}
                    ref={productsListRef}
                    className={clsx({ withPrice, 'table-layout-wrapper': isTableLayout })}
                    height={height}
                    onRowsRendered={args => onRowsRenderedHandler(onRowsRendered, args)}
                    // onRowsRendered={onRowsRendered}
                    rowCount={rowsCount}
                    onScroll={throttle(handleScroll, 300)}
                    overscanRowCount={10}
                    rowHeight={productCardHeight + (isTableLayout ? 0 : productCardsMargin * 1.5)}
                    rowRenderer={_rowRenderer}
                    width={width}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        )} */}
      </Wrapper>
    </ComponentWrapper>
  );
};

export const ProductCatalogMemoized = (props: any) => {
  // const dispatch = useDispatch();
  const sort = useSelector(getCatalogSort);
  const alphabet = useSelector(getCatalogAlphabet);
  const filters = useSelector(getCatalogFilters);
  const sorted = useSelector(getCatalogSortedState);
  // const productsMap = useSelector(getCatalogProductsMap);
  const filterOptionsLoading = useSelector(getFilterOptionsLoadingState);
  const catalogLoading = useSelector(getCatalogLoadingState);
  const catalogLoaded = useSelector(getCatalogLoadedState);
  const layout = useSelector(getCatalogLayout);

  const { filterAndSortCatalog, locale } = props;
  // const isTableLayout: boolean = window.localStorage.getItem('layoutType') === 'table';

  // useEffect(() => {
  //   if (sorted) {
  //     loadMoreRows({ startIndex: 0, stopIndex: isTableLayout ? 70 : 20 });
  //   }
  //   // eslint-disable-next-line
  // }, [sorted, filters]);

  // const loadMoreRows = ({ startIndex, stopIndex }: any) => {
  //   let promiseResolver;
  //   // ! move productRowsToLoad to preload queue
  //   const productRowsToLoad = productRows.slice(startIndex, stopIndex + 1);
  //   const productIdsToLoad = productRowsToLoad
  //     .map((r: Product[]) => r.map(product => (!productsMap.has(product.id) ? product.id : null)).filter(Boolean))
  //     .filter((a: any) => a.length)
  //     .reduce((acc: any, r: any) => acc.concat(r), []);

  //   return new Promise(resolve => {
  //     promiseResolver = resolve;
  //     if (sorted && productIdsToLoad && productIdsToLoad.length) {
  //       dispatch(
  //         catalogPreloadProductsAsync.request({
  //           productsIds: productIdsToLoad,
  //           callback: promiseResolver
  //         })
  //       );
  //     }
  //   });
  // };

  // const debouncedLoadRows = debounce(loadMoreRows, 300);

  // const isRowLoaded = ({ index }: any) => {
  //   const isNotLoaded =
  //     productRows &&
  //     productRows[index] &&
  //     productRows[index].reduce((acc: any, i: Product) => acc || productsMap.has(i.id), false);
  //   return isNotLoaded;
  // };

  return (
    <ProductCatalog
      {...props}
      // loadMoreRows={loadMoreRows}
      // debouncedLoadRows={debouncedLoadRows}
      // isRowLoaded={isRowLoaded}
      catalogLoaded={catalogLoaded}
      filters={filters}
      filterOptionsLoading={filterOptionsLoading}
      catalogLoading={catalogLoading}
      sorted={sorted}
      sort={sort}
      alphabet={alphabet}
      layout={layout}
      // productsMap={productsMap}
      filterAndSortCatalog={filterAndSortCatalog}
      locale={locale}
    />
  );
};
export default ProductCatalog;
