import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTrackInitialPageLoad } from 'controllers';
import { IStaticPageData } from 'models/static-page';
import { fetchStaticPagesAsync } from 'store/static-page/actions';
import { getOfertaData } from 'store/static-page/selectors';
import { decodeHtmlFields } from '../../../utils/transforms';

const StaticOfertaHtml = () => {
  useTrackInitialPageLoad();
  const dispatch = useDispatch();
  const staticPageData = useSelector(getOfertaData);

  useEffect(() => {
    dispatch(fetchStaticPagesAsync.request('oferta'));
    // eslint-disable-next-line
  }, []);

  const decodedRecord = decodeHtmlFields<IStaticPageData>(staticPageData ? staticPageData : undefined, ['content']);
  let publicOfertaData = '';
  try {
    const jsonArray = JSON.parse(decodedRecord?.content || '[]');
    if (jsonArray && jsonArray[0]) {
      publicOfertaData = jsonArray[0].content;
    }
  } catch (error) {
    console.log('Error parsing JSON:', error);
  }

  return (
    <div className="c8" style={{ maxWidth: 1024 }}>
      <h3 className="c11" style={{ marginBottom: 50 }}>
        {staticPageData?.title}
      </h3>
      <div dangerouslySetInnerHTML={{ __html: publicOfertaData }} />
    </div>
  );
};

export default StaticOfertaHtml;