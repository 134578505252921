import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import clsx from 'clsx';
import { useConfirm } from 'material-ui-confirm';
import { Button, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import FlrCard from 'components/shared/card/FlrCard';
import FlrDialogTitle from 'components/shared/modals/components/DialogTitle';
import AddForm from 'components/shared/modals/outlet/OutletModal';
import { Link2, TextBody2, TextSubTitle, TitleH1, TitleH2 } from 'components/shared/text';
import { useTrackInitialPageLoad } from 'controllers';
import { Outlet, User } from 'models';
import { updateAccountAsync } from 'store/account/actions';
import { getAccountLoadingState, getUserAccount } from 'store/account/selectors';
import { logout } from 'store/auth/actions';
import { removeOutletAsync } from 'store/outlets/actions';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/account/settings';
// import messagesMenu from "translations/account/menu";
import { clearPopovers } from 'utils/helpers';

import BusinessData from './BusinessData';
import BusinessDataForm from './BusinessDataForm';
import ChangePasswordModal from './ChangePasswordModal';
import DataCardSkeleton from './DataSkeleton';
import PersonalData from './PersonalData';
import PersonalDataForm from './PersonalDataForm';
import OutletsTable from './Table';

import classes from './Setting.module.scss';

interface IProps {
  // component own props
  classes: any;
  account: User;
  accountLoadingState: boolean;
}

interface IStateProps {
  // Props passed to the component by `connect`
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
  updateAccount: typeof updateAccountAsync.request;
  removeOutlet: typeof removeOutletAsync.request;
  logout: typeof logout;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

const AccountSettings: FC<IComponentProps> = ({
  account,
  accountLoadingState,
  updateAccount: updateUser,
  removeOutlet,
  logout: logoutUser
}) => {
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [addOutletModal, setAddOutletModal] = useState(false);
  const [modifiedAccount, setAccount] = useState(account);
  const [outletToEdit, setOutletToEdit] = useState('');
  const [closePopovers, setClosePopovers] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [personalInfoEdit, setPersonalInfoEdit] = useState(false);
  const [businessInfoEdit, setBusinessInfoEdit] = useState(false);

  useTrackInitialPageLoad();

  const confirm = useConfirm();
  const { formatMessage } = useIntl();

  const toggleChangePasswordModal = () => {
    setChangePasswordModal((state) => !state);
  };
  const toggleAddOutletModal = () => {
    const outletShown = addOutletModal;
    if (outletShown) {
      setOutletToEdit('');
      if (closePopovers) {
        clearPopovers(true);
        setClosePopovers(false);
      }
    }
    setAddOutletModal(!outletShown);
  };

  const toggleEditOutletModal = (id: string, clearAfter?: boolean) => {
    setOutletToEdit(id);
    toggleAddOutletModal();
    setClosePopovers(!!clearAfter);
  };

  const confirmOutletDelete = (outlet: Outlet, clearAfter?: boolean) => {
    confirm({
      confirmationText: formatMessage({ ...messages.deleteBtnLabel }),
      cancellationText: formatMessage({ ...messages.cancelBtnLabel }),
      title: <FlrDialogTitle text={messages.deleteOutletModalTitle} />,
      description: formatMessage({ ...messages.deleteOutletModalText }, { name: outlet.name })
    })
      .then(() => {
        removeOutlet(outlet.id);
        clearPopovers(clearAfter);
      })
      .catch(() => {
        clearPopovers(clearAfter);
      });
  };

  const editPersonalInfo = () => {
    if (businessInfoEdit) {
      setModalOpen(true);
    } else {
      setPersonalInfoEdit(true);
    }
  };
  const closeEditPersonalInfo = () => {
    setPersonalInfoEdit(false);
  };

  const editBusinessInfo = () => {
    if (personalInfoEdit) {
      setModalOpen(true);
    } else {
      setBusinessInfoEdit(true);
    }
  };
  const closeEditBusinessInfo = () => {
    setBusinessInfoEdit(false);
  };

  const handleModalConfirm = () => {
    handleAccountUpdate(modifiedAccount);
    if (personalInfoEdit) {
      closeEditPersonalInfo();
      setBusinessInfoEdit(true);
    } else {
      closeEditBusinessInfo();
      setPersonalInfoEdit(true);
    }
    setModalOpen(false);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleAccountUpdate = (updatedAccount: User) => {
    updateUser(updatedAccount);
  };

  return (
    <div className={classes.container}>
      {changePasswordModal ? (
        <ChangePasswordModal
          logoutUser={logoutUser}
          account={account}
          modalOpen={changePasswordModal}
          handleCancel={toggleChangePasswordModal}
        />
      ) : null}
      <div className={classes.topBar}>
        <TitleH1 className={classes.title}>{messages.title.defaultMessage}</TitleH1>
        <Button
          onClick={toggleChangePasswordModal}
          variant={'outlined'}
          color={'primary'}
          className={classes.changePasswordBtn}
        >
          {messages.changePassword.defaultMessage}
        </Button>
      </div>

      <div className={classes.settingsContainer}>
        <div className={classes.personalDataContainer}>
          <FlrCard className={classes.paper}>
            <div className={classes.personalDataWrapper}>
              <div className={classes.titleGroupContainer}>
                <div className={classes.titleWrapper}>
                  <TitleH2 align={'left'} className={classes.titleGroup}>
                    {messages.personalData.defaultMessage}
                  </TitleH2>
                  <>
                    <Button
                      color={'primary'}
                      onClick={editPersonalInfo}
                      className={clsx(classes.btn, {
                        [classes.hiddenButton]: personalInfoEdit
                      })}
                    >
                      {messages.editButton.defaultMessage}
                    </Button>
                    <Link2
                      onClick={editPersonalInfo}
                      className={clsx(classes.link, {
                        [classes.hiddenButton]: personalInfoEdit
                      })}
                    >
                      {messages.editButton.defaultMessage}
                    </Link2>
                  </>
                </div>
                <TextSubTitle align={'left'} className={classes.subTitleGroup}>
                  {messages.personalDataSubtitle.defaultMessage}
                </TextSubTitle>
                <TextBody2 align={'left'} className={classes.subTitleGroupCaption}>
                  {messages.personalDataSubtitleCaption.defaultMessage}
                </TextBody2>
              </div>

              {!accountLoadingState && account ? (
                personalInfoEdit ? (
                  <PersonalDataForm
                    onCancel={closeEditPersonalInfo}
                    onSave={handleAccountUpdate}
                    onChange={setAccount}
                    account={account}
                  />
                ) : (
                  <PersonalData
                    toggleChangePasswordModal={toggleChangePasswordModal}
                    account={account}
                    onEditClick={editPersonalInfo}
                  />
                )
              ) : (
                <DataCardSkeleton columns={2} />
              )}
            </div>
          </FlrCard>
        </div>

        <div className={classes.businessContainer}>
          <Paper className={classes.paper}>
            <div className={classes.businessWrapper}>
              <div className={classes.titleWrapper}>
                <TitleH2 align={'left'} className={classes.titleGroup}>
                  {messages.businessData.defaultMessage}
                </TitleH2>
                <>
                  <Button
                    color={'primary'}
                    onClick={editBusinessInfo}
                    className={clsx(classes.btn, {
                      [classes.hiddenButton]: businessInfoEdit
                    })}
                  >
                    {messages.editButton.defaultMessage}
                  </Button>
                  <Link2
                    onClick={editBusinessInfo}
                    className={clsx(classes.link, {
                      [classes.hiddenButton]: businessInfoEdit
                    })}
                  >
                    {messages.editButton.defaultMessage}
                  </Link2>
                </>
              </div>
              {!accountLoadingState && account ? (
                businessInfoEdit ? (
                  <BusinessDataForm
                    onCancel={closeEditBusinessInfo}
                    onSave={handleAccountUpdate}
                    onChange={setAccount}
                    account={account}
                  />
                ) : (
                  <BusinessData account={account} />
                )
              ) : (
                <DataCardSkeleton />
              )}
            </div>
          </Paper>
        </div>
      </div>

      <FlrCard>
        <OutletsTable
          toggleAddOutletModal={toggleAddOutletModal}
          toggleEditOutletModal={toggleEditOutletModal}
          confirmDeleteOutlet={confirmOutletDelete}
        />
        <AddForm closePopOvers={closePopovers} toggle={toggleAddOutletModal} id={outletToEdit} open={addOutletModal} />
      </FlrCard>

      <Dialog
        open={modalOpen}
        onClose={handleCancel}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{messages.saveChangesConfirmTitle.defaultMessage}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            <FormattedMessage
              {...messages.saveChangesConfirmText}
              values={{
                dataType: personalInfoEdit ? messages.personalData.defaultMessage : messages.businessData.defaultMessage
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={'outlined'} onClick={handleCancel} color="primary">
            {messages.saveChangesConfirmContinue.defaultMessage}
          </Button>
          <Button onClick={handleModalConfirm} color="primary">
            {messages.saveBtnLabel.defaultMessage}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps: MapStateToProps<{}, {}, IApplicationState> = (state: IApplicationState) => ({
  account: getUserAccount(state),
  accountLoadingState: getAccountLoadingState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      updateAccount: updateAccountAsync.request,
      removeOutlet: removeOutletAsync.request,
      logout
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
