import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Button } from '@mui/material';

import HeaderSearch from 'components/layout/header-main/search/HeaderSearch';
import FlrSelect2 from 'components/shared/form-elements/select2/FlrSelect2';
import Icon from 'components/shared/Icon';
import { baseUrl } from 'shared/constants';
import { ACCOUNT_OPEN_NAV } from 'store/account/actions';
import { getAccountLoadingState, getLayoutNavOpened } from 'store/account/selectors';
import {
  CATALOG_SET_COLUMNS,
  CATALOG_TOGGLE_FILTERS,
  filterAndSortCatalog,
  IFiltersData,
  layoutListColumnsDefault
} from 'store/catalog/actions';
import { getCatalogFilters, getCatalogLayoutFiltersOpened, getCatalogSort } from 'store/catalog/selectors';
import { IApplicationState } from 'store/reducers';

import { HeaderSort } from './device/HeaderSort';
import HeaderBottomSkeleton from './HeaderBottomSkeleton';
import HeaderCartTabs from './HeaderCartTabs';
import HeaderCatalog from './HeaderCatalog';
import { usePriceType } from './usePriceType';
import UserInfo from './UserInfo';

import classes from './HeaderBottom.module.scss';

const layoutOptions = [
  {
    label: 'Картки',
    value: 'list'
  },
  {
    label: 'Список',
    value: 'table'
  }
];

const unitOptions = [
  {
    label: 'Уп',
    value: 'package'
  },
  {
    label: 'Шт',
    value: 'unit'
  }
];

interface IProps {
  useDeviceStyle?: boolean;
}

function isFiltersEnabled(filters: IFiltersData): boolean {
  const { inStock, fast, discount, preOrder, watchOnly, transitOffers } = filters;
  return Boolean(inStock || discount || preOrder || watchOnly || transitOffers || (fast && fast.length));
}

export const CatalogHeaderBottom: FC<IProps> = ({ useDeviceStyle }) => {
  const [layout, setLayout] = useState(window.localStorage.getItem('layoutType') || 'list');
  const userLoading = useSelector(getAccountLoadingState);
  const navOpened = useSelector(getLayoutNavOpened);
  const filtersOpened = useSelector(getCatalogLayoutFiltersOpened);
  const filtersData = useSelector(getCatalogFilters);
  const sort = useSelector(getCatalogSort);
  const locale = useSelector((s: IApplicationState) => s.locale);
  const { updatePriceType, priceType } = usePriceType();

  const isCatalog = window.location.pathname.startsWith(`${baseUrl}/catalog`);
  const isAccount = window.location.pathname.startsWith(`${baseUrl}/account`);
  const isCart = window.location.pathname.startsWith(`${baseUrl}/cart`);

  const dispatch = useDispatch();
  const openFilters = () => dispatch({ type: CATALOG_TOGGLE_FILTERS, payload: true });
  const openNav = () => dispatch({ type: ACCOUNT_OPEN_NAV });

  const onChange = (e: any) => {
    const { value } = e.target;
    setLayout(value);
    const columns: number = value === 'table' ? 1 : layoutListColumnsDefault;
    window.localStorage.setItem('layoutType', value);
    dispatch({
      type: CATALOG_SET_COLUMNS,
      payload: columns
    });
    const { inStock } = filtersData;
    const filtersFast = filtersData.fast || [];
    dispatch(filterAndSortCatalog({ ...filtersData, sort, locale, fast: filtersFast, inStock }));
  };

  const handlePriceTypeChange = (e: any) => {
    updatePriceType(e?.target?.value);
  };

  const isAnyFilters = isFiltersEnabled(filtersData);

  return (
    <div
      className={clsx(classes.headerBottom, {
        [classes.headerBottomDevice]: useDeviceStyle,
        [classes.headerBottomDeviceCatalog || '']: useDeviceStyle && isCatalog
      })}
    >
      <div className={classes.headerNavGroup}>
        <HeaderCatalog />
      </div>

      <div className={classes.headerGroup}>
        {isAccount && (
          <div className={classes.iconWrapper}>
            <Button
              variant="outlined"
              onClick={() => openNav()}
              className={clsx(classes.iconLink, { [classes.iconActive || '']: navOpened })}
            >
              <Icon type="list" size={24} />
            </Button>
          </div>
        )}
        {isCart && useDeviceStyle ? <HeaderCartTabs /> : <HeaderSearch />}
        {isCatalog && useDeviceStyle ? <HeaderSort filtersData={filtersData} sort={sort} locale={locale} /> : null}
        {isCatalog && (
          <div className={classes.iconWrapper}>
            <Button
              variant={'outlined'}
              onClick={openFilters}
              className={clsx(classes.iconLink, {
                [classes.iconActive || '']: filtersOpened,
                [classes.filtersEnabled]: isAnyFilters
              })}
            >
              <Icon type="filterMobile" size={24} />
            </Button>
          </div>
        )}
        {isCatalog && useDeviceStyle ? (
          <FlrSelect2
            fullWidth
            dropdownMenu
            labelIconRight={<Icon type="arrow" size={10} offset={4} />}
            disableWrapper
            value={priceType}
            options={unitOptions}
            onChange={handlePriceTypeChange}
            selectClasses={{ singleValue: clsx(classes.mobileLayoutSelect, classes.mobileItem) }}
            controlClasses={{ inputDropdownMenu: classes.mobileInputDropdownMenu }}
            menuItemClasses={{ menuItem: classes.mobileMenuItem }}
            inputDropdownMenuOpenClasses={{ inputDropdownMenuOpen: classes.inputDropdownMenuOpen }}
          />
        ) : null}
        {isCatalog && useDeviceStyle ? (
          <FlrSelect2
            fullWidth
            dropdownMenu
            labelIconRight={<Icon type="arrow" size={10} offset={4} />}
            disableWrapper
            value={layout}
            options={layoutOptions}
            onChange={onChange}
            selectClasses={{ singleValue: classes.mobileLayoutSelect }}
            controlClasses={{ inputDropdownMenu: classes.mobileInputDropdownMenu }}
            menuItemClasses={{ menuItem: classes.mobileMenuItem }}
            inputDropdownMenuOpenClasses={{ inputDropdownMenuOpen: classes.inputDropdownMenuOpen }}
          />
        ) : null}
        <div className={classes.actions}>
          {userLoading ? (
            <div className={classes.infoGroup}>
              <HeaderBottomSkeleton />
            </div>
          ) : (
            <UserInfo className={classes.infoGroup} />
          )}
        </div>
      </div>
    </div>
  );
};
