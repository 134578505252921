import React from 'react';
import { Box, Button, Grid, IconButton } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { withStyles } from '@mui/styles';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import {
  TitleH1,
  TitleH2,
  Link1,
  Link2,
  Link3,
  TextBody1,
  TextBody2,
  TextCaption,
  TextCoins,
  TextFounds,
  TextHelper,
  TextSubTitle
} from 'components/shared/text';
import FlrSwitchWithLabel from 'components/shared/form-elements/FlrSwitch';
import FlrSelect2 from 'components/shared/form-elements/select2/FlrSelect2';
import FlrCheckboxWithLabel from 'components/shared/form-elements/FlrCheckbox';
import Tooltip from 'components/shared/tooltip';
import Icon from 'components/shared/Icon';

import styles from '../finance/styles';
import FlrDatePicker from '../../../components/shared/form-elements/FlrDatePicker';
import { connect, MapStateToProps } from 'react-redux';
import { IApplicationState } from '../../../store/reducers';
import { getAccountLoadingState, getUserAccount } from '../../../store/account/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import AddForm from 'components/shared/modals/outlet/OutletModal';
import Alert from 'components/shared/Alert/Alert';
import { useSnackbar } from 'notistack';
import SnackbarContent from '../../../components/layout/notistack-provider/SnackbarWithTitle';

const DatePickerDemo = () => {
  const [dateXlsFrom, setDateXlsFrom] = React.useState<Date | null>(new Date(Date.now() - 60 * 60 * 24 * 7 * 1000));
  const handleDateChange = () => (date: any) => {
    setDateXlsFrom(date);
  };

  return (
    <>
      <Grid container justifyContent="space-around">
        <FlrDatePicker label={'Test DP'} value={dateXlsFrom} onChange={handleDateChange()} />
        <DateTimePicker
          autoOk
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="DD.MM.YYYY"
          margin="normal"
          id="date-from2"
          label={'Test DP'}
          value={dateXlsFrom}
          onChange={handleDateChange()}
        />
      </Grid>
    </>
  );
};

const AccountRefund: React.FC<any> = () => {
  /** demo */
  const [val, setVal] = React.useState('');

  const [value, setValue] = React.useState('female');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const demoInputs = (
    <>
      <TitleH1>Inputs</TitleH1>

      <ValidatorForm
        noValidate
        autoComplete="off"
        onSubmit={() => ({})}
        onError={() => ({})}
        style={{ gridArea: 'form' }}
      >
        <TextValidator
          variant={'outlined'}
          name="name"
          label="Имя"
          value={val}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setVal(event.target.value);
          }}
          validators={['required', 'maxStringLength:3']}
          errorMessages={['this field is required', 'String it soo short']}
          helperText={'Helper text'}
        />

        <TextValidator
          disabled
          variant={'outlined'}
          name="name"
          label="Имя"
          placeholder="placeholder"
          value={''}
          onChange={() => ({})}
          validators={['required', 'minStringLength:30']}
          errorMessages={['this field is required', 'String it soo short']}
        />

        <TextValidator
          disabled
          variant={'outlined'}
          name="name"
          label="Имя"
          placeholder="placeholder"
          value={'ччч'}
          onChange={() => ({})}
          validators={['required', 'minStringLength:30']}
          errorMessages={['this field is required', 'String it soo short']}
        />
        <br />
        <FlrSwitchWithLabel
          // defaultChecked={inStock}
          label={'Switch'}
          // onChange={this.handleInStockChange}
        />
        <br />

        <FlrSelect2
          value={'1'}
          options={[
            { label: '111', value: '1' },
            { label: '222', value: '2' }
          ]}
          onChange={() => ({})}
        />

        <FlrCheckboxWithLabel color={'primary'} />
        <FlrCheckboxWithLabel checked indeterminate />

        <FlrCheckboxWithLabel color={'primary'} disabled />
        <FlrCheckboxWithLabel checked disabled />
        <FlrCheckboxWithLabel checked indeterminate disabled />

        <FlrCheckboxWithLabel label={'Test'} />
      </ValidatorForm>
    </>
  );

  const demoTooltip = (
    <div style={{ marginTop: 16 }}>
      <TitleH1>Tooltips</TitleH1>
      <Tooltip
        isControlled
        icon={<Icon type={'loyalty'} size={24} offset={8} />}
        title={
          <div>
            <TextSubTitle color={'inherit'} style={{ marginTop: 0 }}>
              Title
            </TextSubTitle>
            <TextBody2 color={'inherit'}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut, autem beatae consequatur cum, deleniti
              dolor dolorem doloremque explicabo facilis laboriosam nemo nihil pariatur provident quam reprehenderit
              sint ut. Animi, porro.
            </TextBody2>

            <Link1 color={'secondary'} href={'#ff'}>
              Details
            </Link1>
          </div>
        }
      >
        <span>Click</span>
      </Tooltip>
      <div style={{ width: 24, margin: 16 }} />
      <Tooltip
        icon={<Icon type={'loyalty'} size={24} offset={8} />}
        title={
          <div>
            <TextSubTitle color={'inherit'} style={{ marginTop: 0 }}>
              Title
            </TextSubTitle>
            <TextBody2 color={'inherit'}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut, autem beatae consequatur cum, deleniti
              dolor dolorem doloremque explicabo facilis laboriosam nemo nihil pariatur provident quam reprehenderit
              sint ut. Animi, porro.
            </TextBody2>
          </div>
        }
      >
        <span>tooltip example hover</span>
      </Tooltip>{' '}
      <div style={{ width: 24, margin: 16 }} />
      <Tooltip
        icon={<Icon type={'loyalty'} size={24} offset={8} />}
        title={
          <div>
            <TextSubTitle color={'inherit'} style={{ marginTop: 0 }}>
              Title
            </TextSubTitle>
            <TextBody2 color={'inherit'}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut, autem beatae consequatur cum, deleniti
              dolor dolorem doloremque explicabo facilis laboriosam nemo nihil pariatur provident quam reprehenderit
              sint ut. Animi, porro.
            </TextBody2>
          </div>
        }
      >
        <span>
          <Icon type={'delivery'} size={24} offset={8} />
        </span>
      </Tooltip>
    </div>
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined = 'default') => () => {
    enqueueSnackbar(
      <SnackbarContent title={'Title'}>
        <div>
          <strong>Lorem Ipsum</strong> - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum
          является стандартной "рыбой" для текстов на латинице с начала XVI века.
        </div>
      </SnackbarContent>,
      {
        variant
      }
    );
  };

  const demoNotistack = (
    <>
      <TitleH1>Notifications</TitleH1>

      <Button variant={'contained'} onClick={handleClick()}>
        def
      </Button>
      <Button variant={'contained'} onClick={handleClick('success')}>
        succ
      </Button>
      <Button variant={'contained'} onClick={handleClick('error')}>
        err
      </Button>
      <Button variant={'contained'} onClick={handleClick('warning')}>
        warn
      </Button>
      <Button variant={'contained'} onClick={handleClick('info')}>
        info
      </Button>
    </>
  );

  const demoAlerts = (
    <>
      <TitleH1>Alerts</TitleH1>

      <Alert closable type={'info'} title={'Title'}>
        Lorem
      </Alert>
      <Alert closable type={'success'} title={'Title'}>
        Lorem
      </Alert>
      <Alert closable type={'warning'} title={'Title'}>
        Lorem
      </Alert>
      <Alert closable type={'error'} title={'Title'}>
        Lorem
      </Alert>
      <Alert closable type={'error'} title={'Title'} icon={'wallet'}>
        Lorem
      </Alert>
    </>
  );

  const demoRadio = (
    <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
      <FormControlLabel value="female" control={<Radio />} label="Female" />
      <FormControlLabel value="male" control={<Radio />} label="Male" />
      <FormControlLabel value="other" control={<Radio />} label="Other" />
      <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />
    </RadioGroup>
  );

  const demoButtons = (
    <>
      <TitleH1>Кнопки</TitleH1>

      <div style={{ width: 100 }}>
        <FlrSelect2
          label={'Test Test Test  Test '}
          value={''}
          options={[
            { label: 'Afghanistan Afghanistan Afghanistan', value: 'Afg', desc: 'Description', amount: 100 },
            { label: 'Aland Islands', value: 'Ala' },
            {
              label: 'Albania',
              value: 'Alb',
              onClick: () => ({})
            },
            {
              label: 'Nigeria',
              value: 'nig',
              onClick: () => ({}),
              icon: 'chevron'
            }
          ]}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <div>Icon Button</div>
        <IconButton size="large">
          <Icon type="btnArrow" opacity="1" size={24} />
        </IconButton>
        <span style={{ marginLeft: 16 }} />

        <IconButton color={'secondary'} size="large">
          <Icon type="btnArrow" opacity="1" size={24} />
        </IconButton>
        <span style={{ marginLeft: 16 }} />

        <IconButton disabled size="large">
          <Icon type="btnArrow" opacity="1" size={24} />
        </IconButton>
        <br />

        <IconButton size={'small'}>
          <Icon type="btnArrow" opacity="1" size={16} />
        </IconButton>
        <span style={{ marginLeft: 16 }} />

        <IconButton size={'small'} color={'secondary'}>
          <Icon type="btnArrow" opacity="1" size={16} />
        </IconButton>

        <span style={{ marginLeft: 16 }} />

        <IconButton size={'small'} color={'secondary'} disabled>
          <Icon type="btnArrow" opacity="1" size={16} />
        </IconButton>
      </div>

      <div style={{ marginBottom: 16 }}>
        <div>contained</div>
        <Button color={'primary'} variant={'contained'}>
          contained
        </Button>
        <span style={{ marginLeft: 16 }} />
        <Button color={'primary'} variant={'contained'} size={'small'}>
          small
        </Button>
        <span style={{ marginLeft: 16 }} />
        <Button color={'primary'} variant={'contained'} disabled>
          disabled
        </Button>
        <span style={{ marginLeft: 16 }} />
        <Button color={'primary'} variant={'contained'} disabled size={'small'}>
          disabled
        </Button>
      </div>

      <div style={{ marginBottom: 16 }}>
        <div>outlined</div>
        <Button color={'primary'} variant={'outlined'}>
          outlined
        </Button>
        <span style={{ marginLeft: 16 }} />
        <Button color={'primary'} variant={'outlined'} size={'small'}>
          small
        </Button>
        <span style={{ marginLeft: 16 }} />
        <Button color={'primary'} variant={'outlined'} disabled>
          disabled
        </Button>
        <span style={{ marginLeft: 16 }} />
        <Button color={'primary'} variant={'outlined'} disabled size={'small'}>
          small
        </Button>
      </div>

      <div style={{ marginBottom: 16 }}>
        <div>simple</div>
        <Button color={'primary'}>test</Button>
        <span style={{ marginLeft: 16 }} />
        <Button color={'primary'} size={'small'}>
          small
        </Button>
        <span style={{ marginLeft: 16 }} />
        <Button color={'primary'} disabled>
          disabled
        </Button>
        <span style={{ marginLeft: 16 }} />
        <Button color={'primary'} disabled size={'small'}>
          small
        </Button>
      </div>

      <div style={{ marginBottom: 16 }}>
        <Button color={'secondary'}>secondary</Button>
        <span style={{ marginLeft: 16 }} />
        <Button color={'secondary'} size={'small'}>
          small
        </Button>
        <span style={{ marginLeft: 16 }} />
        <Button color={'secondary'} disabled>
          disabled
        </Button>
        <span style={{ marginLeft: 16 }} />
        <Button color={'secondary'} disabled size={'small'}>
          small
        </Button>
      </div>
    </>
  );

  const demoTypography = (
    <>
      <TitleH1>Текст:</TitleH1>

      <TitleH1>H1 text</TitleH1>
      <TitleH2>H2 text</TitleH2>
      <TextFounds>TextFounds text</TextFounds>
      <TextSubTitle>TextSubTitle text</TextSubTitle>
      <div style={{ marginBottom: 16 }}>
        <TextBody1>TextBody text</TextBody1>
      </div>
      <div style={{ marginBottom: 16 }}>
        <TextBody2>TextBody2 text</TextBody2>
      </div>
      <div style={{ marginBottom: 16 }}>
        <TextCoins>TextCoins text</TextCoins>
      </div>
      <div style={{ marginBottom: 16 }}>
        <TextCaption>TextCaption text</TextCaption>
      </div>
      <div style={{ marginBottom: 16 }}>
        <TextHelper>TextHelper text</TextHelper>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Link1 href={'#'}>Test Link</Link1>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Link2 href={'#'}>Test Link2</Link2>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Link3 href={'#'}>Test Link3</Link3>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Link1 color={'secondary'} href={'#'}>
          Test Link
        </Link1>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Link2 color={'secondary'} href={'#'}>
          Test Link2
        </Link2>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Link3 color={'secondary'} href={'#'}>
          Test Link3
        </Link3>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Link1 color={'error'} href={'#'}>
          Test Link
        </Link1>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Link2 color={'error'} href={'#'}>
          Test Link2
        </Link2>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Link3 color={'error'} href={'#'}>
          Test Link3
        </Link3>
      </div>
      <br />
    </>
  );
  const [addOutletModal, setAddOutletModal] = React.useState(false);
  const toggleAddOutletModal = () => {
    setAddOutletModal(state => !state);
  };
  return (
    <Box p={2}>
      <button onClick={toggleAddOutletModal}>Open edit</button>
      <AddForm toggle={toggleAddOutletModal} open={addOutletModal} id={'5ef85995115b5002fbb67bfd'} />

      <div style={{ marginBottom: 24 }}>{demoTooltip}</div>
      <div style={{ marginBottom: 24 }}>{demoNotistack}</div>
      <div style={{ marginBottom: 24 }}>{demoAlerts}</div>
      <div style={{ marginBottom: 24 }}>{demoInputs}</div>
      <div style={{ marginBottom: 24 }}>{demoRadio}</div>
      <div style={{ marginBottom: 24 }}>{demoButtons}</div>
      <div style={{ marginBottom: 24 }}>{demoTypography}</div>
      <div style={{ marginBottom: 24 }}>
        <DatePickerDemo />
      </div>
    </Box>
  );
};

const mapStateToProps: MapStateToProps<{}, {}, IApplicationState> = (state: IApplicationState) => ({
  account: getUserAccount(state),
  accountLoadingState: getAccountLoadingState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(AccountRefund));
