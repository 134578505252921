import { IApplicationState } from "store/reducers";

export const getAccountState = (state: IApplicationState) => state.account;
export const getAccountLoadingState = (state: IApplicationState) => getAccountState(state).loading;
export const getAccountLoaded = (state: IApplicationState) => getAccountState(state).loaded;
export const getAccountData = (state: IApplicationState) => getAccountState(state).data;
export const getUserAccount = (state: IApplicationState) => getAccountData(state).user;
export const getUserProfile = (state: IApplicationState) => {
  const user = getUserAccount(state);
  if (user) {
    return user.profile;
  }
};
export const getUserPreferences = (state: IApplicationState) => {
  const user = getUserAccount(state);
  if (user) {
    return user.preferences;
  }
};
export const getLayoutNavOpened = (state: IApplicationState) => getAccountState(state).data.layout.leftOpened;
export const getLayoutDetailsOpened = (state: IApplicationState) => getAccountState(state).data.layout.rightOpened;
