import { defineMessages } from "react-intl";

export default defineMessages({
  checkoutTitle: {
    id: "checkout.title",
    description: "Cart title",
    defaultMessage: "Оформлення доставки"
  },
  checkoutBreadcrumbTitle: {
    id: "checkout.breadcrumb.title",
    description: "Cart title",
    defaultMessage: "Оформлення замовлення"
  },
  totalSum: {
    id: "checkout.order.totalSum",
    description: "checkout.order.totalSum",
    defaultMessage: "Загальна сума"
  },
  sum: {
    id: "checkout.order.totalSum",
    description: "checkout.order.totalSum",
    defaultMessage: "Сума"
  },
  delivery: {
    id: "checkout.delivery",
    description: "checkout.delivery",
    defaultMessage: "Доставка"
  },
  deliveryMethod: {
    id: "checkout.delivery.method",
    description: "checkout.delivery.method",
    defaultMessage: "Спосіб доставки"
  },
  deliveryAddress: {
    id: "checkout.delivery.deliveryAddress",
    description: "checkout.delivery.deliveryAddress",
    defaultMessage: "Адреса призначення"
  },
  noDeliveryAddress: {
    id: "checkout.delivery.noDeliveryAddress",
    description: "checkout.delivery.noDeliveryAddress",
    defaultMessage: "Це поле обов'язкове"
  },
  date: {
    id: "checkout.delivery.date",
    description: "checkout.delivery.date",
    defaultMessage: "Дата"
  },
  time: {
    id: "checkout.delivery.time",
    description: "checkout.delivery.time",
    defaultMessage: "Час"
  },
  package: {
    id: "checkout.packageId",
    description: "checkout.packageId",
    defaultMessage: "Тара"
  },
  packageReturn: {
    id: "checkout.packageId.return",
    description: "checkout.packageId.return",
    defaultMessage: "Повернути тару"
  },
  packageReturnSelected: {
    id: "checkout.order.packageReturnSelected",
    description: "checkout.order.packageReturnSelected",
    defaultMessage: "Повернення тари"
  },
  packageReturned: {
    id: "checkout.packageId.returned",
    description: "checkout.packageId.returned",
    defaultMessage: "Тару повернуто"
  },
  packageNotReturned: {
    id: "checkout.packageId.notReturned",
    description: "checkout.packageId.notReturned",
    defaultMessage: "Тару не повернуто"
  },
  mergeBackBtnLabel: {
    id: "checkout.mergeBackBtnLabel",
    description: "checkout.mergeBackBtnLabel",
    defaultMessage: "Об'єднати назад"
  },
  cancelBtnLabel: {
    id: "checkout.cancelBtnLabel",
    description: "checkout.cancelBtnLabel",
    defaultMessage: "Скасувати"
  },
  submitBtnLabel: {
    id: "checkout.submitBtnLabel",
    description: "checkout.submitBtnLabel",
    defaultMessage: "Замовити та оплатити"
  },
  submitPreOrderBtnLabel: {
    id: "checkout.submitPreOrderBtnLabel",
    description: "checkout.submitPreOrderBtnLabel",
    defaultMessage: "Замовити"
  },
  submitAllBtnLabel: {
    id: "checkout.submitAllBtnLabel",
    description: "checkout.submitAllBtnLabel",
    defaultMessage: "Замовити та оплатити всі"
  },
  submitPreOrderAllBtnLabel: {
    id: "checkout.submitPreOrderAllBtnLabel",
    description: "checkout.submitPreOrderAllBtnLabel",
    defaultMessage: "Замовити всі"
  },
  alertSplitOrder: {
    id: "checkout.alert.splitOrder",
    description: "checkout.alert.splitOrder",
    defaultMessage: "Розділення замовлення"
  },
  alertSplitOrderText: {
    id: "checkout.alert.splitOrderText",
    description: "checkout.alert.splitOrderText",
    defaultMessage: "Вам потрібно розділити доставку на кілька адрес? Зменшіть кількість товару в таблиці."
  },
  alertPayedOrder: {
    id: "checkout.alert.payedOrder",
    description: "checkout.alert.payedOrder",
    defaultMessage: "Оплачено"
  },
  alertPayedOrderText: {
    id: "checkout.alert.payedOrderText",
    description: "checkout.alert.payedOrderText",
    defaultMessage: "Замовлення сформовано та оплачено!"
  },
  finalizeAllOrdersSuccess: {
    id: "checkout.notification.finalizeAllOrdersSuccess",
    description: "checkout.notification.finalizeAllOrdersSuccess",
    defaultMessage: "Всі замовлення сформовані і оплачені!"
  },
  finalizeOrdersSuccess: {
    id: "checkout.notification.finalizeAllOrdersSuccess",
    description: "checkout.notification.finalizeAllOrdersSuccess",
    defaultMessage: "Замовлення сформовано та оплачено!"
  },
  finalizeOrdersError: {
    id: "checkout.notification.finalizeOrdersError",
    description: "checkout.notification.finalizeOrdersError",
    defaultMessage: "Не вистачає коштів на балансі, будь ласка поповніть рахунок."
  },
  mobileTitle: {
    id: "checkout.mobileTitle",
    description: "checkout.mobileTitle",
    defaultMessage: "Оформлення доставки"
  },
  mobileTotal: {
    id: "checkout.mobileTotal",
    description: "checkout.mobileTotal",
    defaultMessage: "Загалом:"
  },
  summary: {
    id: 'catalog.details.summary',
    description: 'catalog.details.summary',
    defaultMessage: 'Всього'
  },
  qty: {
    id: 'catalog.details.qty',
    description: 'catalog.details.qty',
    defaultMessage: 'Кількість'
  }
});
