import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import FlrSelect2 from 'components/shared/form-elements/select2/FlrSelect2';
import { IFilterOptionsRequest } from 'models';
import { baseUrl, catalogTypeSlugByCode } from 'shared/constants';
import { getCatalogFilters } from 'store/catalog/selectors';
import { fetchFilterOptionsAsync } from 'store/filter-options/actions';
import { getFilterOptionsData, getFilterOptionsLoadingState } from 'store/filter-options/selectors';

import SearchControl from './SearchControl';

import classes from './HeaderSearchDesktop.module.scss';

const HeaderSearch = () => {
  const dispatch = useDispatch();
  const filters = useSelector(getCatalogFilters);
  const filterOptionsData = useSelector(getFilterOptionsData);
  const filterOptionsLoading = useSelector(getFilterOptionsLoadingState);
  const isCatalog = useRouteMatch(`${baseUrl}/catalog/:type?/:filter?`);
  const { productType } = filters;

  const types = filterOptionsData.type;
  const typesOptions =
    types && types.map((item) => ({ label: item.name, value: item.code, slug: catalogTypeSlugByCode[item.code] }));

  const productTypeCode = typesOptions && typesOptions.find((typeItem: any) => typeItem.slug === productType);

  const [typeData, setTypeData] = useState({});
  const [type, setType] = useState((productTypeCode && productTypeCode.value) || '');

  const newProductType = (productTypeCode && productTypeCode.value) || '';

  useEffect(() => {
    if (typesOptions?.length || filterOptionsLoading || Boolean(isCatalog)) {
      return;
    }
    // * initial fetch filter options on every page accept catalog
    dispatch(
      fetchFilterOptionsAsync.request({
        productType,
        inStock: undefined,
        preOrder: false,
        discount: false,
        specialOffers: false,
        transitOffers: false,
        specialTransitOffers: false,
        deliveryRegion: undefined
      } as IFilterOptionsRequest)
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setType(newProductType);
  }, [filterOptionsData, newProductType]);

  useEffect(() => {
    if (!type && typesOptions && typesOptions[0]) {
      setType(typesOptions[0].value);
      setTypeData(typesOptions[0]);
    }
  }, [type, typesOptions]);

  const handleChangeProductType = (ev: any) => {
    setTypeData(ev.target.data);
    setType(ev.target.value);
  };

  return (
    <div className={classes.searchBlock}>
      <div className={classes.container}>
        <FlrSelect2
          isLoading={filterOptionsLoading} // for change to Select3
          fullWidth
          className={classes.searchSelect}
          disableWrapper
          value={type}
          options={typesOptions || []}
          onChange={handleChangeProductType}
          isCatalog
        />
        <SearchControl types={types} categoryType={type} categoryTypeData={typeData} />
      </div>
    </div>
  );
};

export default HeaderSearch;
