import { mergeDeep } from "./helpers";

export interface IHttpResponse<T> extends Response {
  parsedBody?: T;
  parsedErrors?: T;
}

export const http = <T>(request: RequestInfo): Promise<IHttpResponse<T>> => {
  return new Promise((resolve, reject) => {
    let response: IHttpResponse<T>;
    fetch(request)
      .then(res => {
        response = res;
        return res.json();
      })
      .then(body => {
        if (response.ok) {
          response.parsedBody = body;
          resolve(response);
        } else {
          response.parsedErrors = body;
          reject(response);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const get = async <T>(path: string, args: RequestInit = {}): Promise<IHttpResponse<T>> => {
  const resultArgs = mergeDeep({}, { method: "get", headers: { "Content-Type": "application/json" } }, args);
  return await http<T>(new Request(path, resultArgs));
};

export const post = async <T>(path: string, body: any, args: RequestInit = {}): Promise<IHttpResponse<T>> => {
  const defaultHeaders = { "Content-Type": "application/json" };
  const resultArgs = mergeDeep({}, { method: "post", body: JSON.stringify(body), headers: defaultHeaders }, args);
  return await http<T>(new Request(path, resultArgs));
};

export const put = async <T>(path: string, body: any, args: RequestInit = {}): Promise<IHttpResponse<T>> => {
  const defaultHeaders = { "Content-Type": "application/json" };
  const resultArgs = mergeDeep({}, { method: "put", body: JSON.stringify(body), headers: defaultHeaders }, args);
  return await http<T>(new Request(path, resultArgs));
};

export async function callApi(method: string, url: string, path: string, data?: any) {
  const res = await fetch(url + "/api" + path, {
    method,
    headers: {
      Accept: "application/json"
    },
    body: JSON.stringify(data)
  });
  return await res.json();
}

export const delay = (time: number, data: any) => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(data);
    }, time)
  );
};
