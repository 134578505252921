import { defineMessages } from 'react-intl';

export default defineMessages({
  productsInCart: {
    id: 'catalog.mini-cart.productsInCart',
    description: 'catalog.mini-cart.productsInCart',
    defaultMessage: 'Товари у кошику'
  },
  offersNumber: {
    id: 'catalog.mini-cart.offersNumber',
    description: 'catalog.mini-cart.offersNumber',
    defaultMessage: 'Пропозиції'
  },
  loginToSeeOffers: {
    id: 'catalog.mini-cart.loginToSeeOffers',
    description: 'catalog.mini-cart.loginToSeeOffers',
    defaultMessage: 'Щоб побачити пропозиції цін та купити цей товар Вам потрібно увійти або зареєструватись'
  },
  total: {
    id: 'catalog.mini-cart.total',
    description: 'catalog.mini-cart.total',
    defaultMessage: 'Сума'
  },
  totalWithDiscount: {
    id: 'catalog.mini-cart.totalWithDiscount',
    description: 'catalog.mini-cart.totalWithDiscount',
    defaultMessage: 'Сума зі знижкою'
  },
  cancelBtnLabel: {
    id: 'checkout.cancelBtnLabel',
    description: 'checkout.cancelBtnLabel',
    defaultMessage: 'Скасувати'
  },
  submitBtnLabel: {
    id: 'checkout.submitBtnLabel',
    description: 'checkout.submitBtnLabel',
    defaultMessage: 'Застосувати'
  },
  orderAndBuy: {
    id: 'checkout.orderAndBuy',
    description: 'checkout.orderAndBuy',
    defaultMessage: 'Замовити та оплатити'
  },
  addToCart: {
    id: 'checkout.addToCart',
    description: 'checkout.addToCart',
    defaultMessage: 'Додати у кошик'
  }
});
