import { devicePanelHeight, drawerWidthDevice } from 'shared/constants';
import { ITheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  backButton: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,

    '& .flr-icon': {
      minWidth: theme.spacing(3)
    }
  },
  titleCheckout: {
    marginLeft: theme.spacing(3),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '240px'
  },
  title: {
    '@media (max-width: 720px)': {
      lineClamp: 2,
      paddingLeft: 6
    }
  },
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
    backgroundColor: theme.palette.common.white,
    width: drawerWidthDevice,
    height: devicePanelHeight,
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    zIndex: (theme as any).zIndex.drawer + 1,
    top: 0,
    '&.bottom': {
      top: 'unset',
      bottom: 0
    },
    '&.fullWidth': {
      width: '100%'
    },
    [(theme as any).breakpoints.down('md')]: {
      width: drawerWidthDevice
    },
    [(theme as any).breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  alignLeft: {
    textAlign: 'left',
    maxWidth: 'fit-content'
  },
  alignRight: {
    textAlign: 'right'
  },
  linkGrid: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .text': {
      display: 'none'
    },
    '&.active': {
      color: theme.palette.primary.main,

      '& .flr-icon': {
        opacity: 1
      },

      '& .text': {
        display: 'inline-block'
      }
    }
  },
  chatBtn: {
    position: 'fixed',
    zIndex: 2147483639,
    width: 'calc((100% - 48px) / 5)',
    height: devicePanelHeight,
    bottom: 0,
    right: theme.spacing(3),
    borderRadius: 0
  },
  catalogHeader: {
    width: 375,
    '@media (max-width: 960px)': {
      width: '464px',
    },
    '@media (max-width: 740px)': {
      width: '100%'
    }
  },
  hideAccountHeader: {
    display: 'none'
  },
  fullWidth: {
    width: drawerWidthDevice,
    '@media (max-width: 720px)': {
      width: '100%'
    }
  },
  minWidth: {
    maxWidth: 'inherit'
  },
  iconClose: {
    cursor: 'pointer',
  }
});

export default styles;
