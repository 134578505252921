export interface ISlide {
  _id: string | number;
  header: string;
  description: string | string[];
  href?: string;
  image: string;
  mobileImage: string;
  order: number;
  color: string;
}

export class MainTopSlide {
  public id: string;
  public header: string;
  public description: string | string[];
  public href: string;
  public image: string;
  public mobileImage: string;
  public order: number;
  public color: string;
  public btnLabel: string;

  constructor(slide: any) {
    this.id = slide.id;
    this.header = slide.header;
    this.description = slide.description;
    this.href = slide.href;
    this.image = slide.image;
    this.mobileImage = slide.mobileImage;
    this.order = slide.order;
    this.color = slide.color;
    this.btnLabel = slide.btnLabel;
  }
}
