import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { PickersDay, PickersDayProps, pickersDayClasses } from '@mui/x-date-pickers/PickersDay';
import { palette } from 'styles/theme/default';
import Icon from '../Icon';

interface IProps extends DatePickerProps<any, any> {
  classes?: any;
  className?: any;
  isLoadingExternal?: boolean;
  [key: string]: any;
}

const styles = withStyles(() => ({
  paper: {
    '& .MuiPaper-root': {
      padding: 0
    },

    '& .MuiDialogActions-root': {
      padding: '10px 20px'
    }
  },
  input: {
    '& .MuiInputBase-root': {
      borderRadius: 8
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      padding: 10
    },
    '& .MuiInputLabel-root.MuiInputLabel-outlined': {
      marginTop: -5
    }
  }
}));

const OpenPickerIcon = () => <Icon type={'calendar'} size={24} />;

const renderWeekPickerDay = (date: Date, selectedDates: Array<Date | null>, pickersDayProps: PickersDayProps<Date>) => {
  return (
    <PickersDay
      {...pickersDayProps}
      sx={{
        [`&&.${pickersDayClasses.selected}`]: {
          backgroundColor: palette.primary.main,
          fontWeight: 500
        }
      }}
    />
  );
};

const FlrDatePicker: React.FC<IProps> = ({ classes, className, ...otherProps }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (!otherProps.disabled) {
      setOpen(true);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <DatePicker
      DialogProps={{
        className: classes.paper
      }}
      closeOnSelect={true}
      showToolbar={false}
      views={['day']}
      inputFormat="DD.MM.YYYY"
      components={{ OpenPickerIcon }}
      onClose={onClose}
      open={open}
      renderDay={renderWeekPickerDay}
      {...otherProps}
      renderInput={params => (
        <TextField
          classes={{ root: classes.input }}
          color="secondary"
          {...params}
          onClick={handleClick}
          autoComplete="off"
        />
      )}
    />
  );
};

export default styles(FlrDatePicker);
