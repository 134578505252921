import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import FlrCard from 'components/shared/card/FlrCard';
import Icon from 'components/shared/Icon';
import { TextSubTitle, TitleH1 } from 'components/shared/text';
import { User } from 'models';
import { baseUrl } from 'shared/constants';
import messages from 'translations/account/dashboard';

// todo
import { orderByStatusList } from '../orders/AccountOrdersTable';
import DashboardDeliverySkeleton from './DashboardDeliverySkeleton';

import classes from './DashboardDelivery.module.scss';

interface IProps {
  // component own props
  account: User | null;
}

interface IStateProps {
  // Props passed to the component by `connect`
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

const ordersMeta = {};
orderByStatusList.forEach((item) => {
  ordersMeta[item.statuses[0]] = item;
});

const DashboardDelivery: React.FC<IComponentProps> = ({ account }) => {
  const ordersSummaryKeys = account && account.profile.ordersSummary ? Object.keys(account.profile.ordersSummary) : [];
  const ordersSummary = ordersSummaryKeys.length && account ? account.profile.ordersSummary : {};

  return (
    <div className={classes.deliveryContainer}>
      <div className={clsx(classes.title, classes.titleDelivery)}>
        <Icon className={classes.titleIcon} type={'delivery'} opacity={1} />
        <TitleH1 className={classes.titleCommon}>{messages.titleDelivery.defaultMessage}</TitleH1>
      </div>

      {Boolean(ordersSummaryKeys.length) ? (
        ordersSummaryKeys.map((item) => {
          const orderMeta = ordersMeta[item];
          const orderCount = ordersSummary[item];

          // hotfix maybe only for localhost server
          if (item === 'null' || !orderMeta) {
            return null;
          }

          const itemComponent = (
            <FlrCard hoverShadow={Boolean(orderCount)} zeroPadding className={classes.cardLoyalty}>
              <div className={classes.cardLoyaltyContent}>
                <TextSubTitle className={classes.cardDeliveryLabel}>
                  <Icon type={orderMeta.icon} size={24} className={classes.cardDeliveryIcon} offset={8} />
                  <span>{orderMeta.label}</span>
                </TextSubTitle>

                <TextSubTitle className={classes.orderCount}>{orderCount}</TextSubTitle>
              </div>
            </FlrCard>
          );

          return (
            <div className={classes.wrapper} key={item}>
              {Boolean(orderCount) ? (
                <NavLink className={classes.CardLink} to={`${baseUrl}${orderMeta.path}`}>
                  {itemComponent}
                </NavLink>
              ) : (
                itemComponent
              )}
            </div>
          );
        })
      ) : (
        <DashboardDeliverySkeleton />
      )}
    </div>
  );
};

export default DashboardDelivery;
