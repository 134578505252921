import { createReducer, PayloadAction, createAsyncAction, createAction } from 'typesafe-actions';
import {
  loadedDataWrapper,
  REQUEST_ACTIONS,
  IAsyncDataWrapper,
  loadingDataWrapper,
  errorDataWrapper
} from 'store/actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ISeoSettings } from 'models/seoSettings';
import { SeoSettingsRepository } from './request';
import { getSeoLoadingState } from './selectors';

const httpClient = new SeoSettingsRepository();

export const prefix = '@@seoSettings/';

export const FETCH_SEO_SETTINGS = `${prefix}FETCH_SEO_SETTINGS`;
export const SEO_SETTINGS_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const SEO_SETTINGS_REQUEST_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const SEO_SETTINGS_REQUEST_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

interface ISeoSettingsStateSync {
  settings: ISeoSettings[];
}

export type ISeoSettingsState = IAsyncDataWrapper<ISeoSettingsStateSync>;

export const seoSettingsInitialState: ISeoSettingsState = {
  loading: false,
  loaded: false,
  data: {
    settings: []
  },
  error: null
};

type SeoSettingsActionTypes =
  | typeof SEO_SETTINGS_REQUEST
  | typeof SEO_SETTINGS_REQUEST_SUCCESS
  | typeof SEO_SETTINGS_REQUEST_FAILURE;

export const fetchSeoSettings = createAction(FETCH_SEO_SETTINGS)<void>();

export const fetchSeoSettingsAsync = createAsyncAction(
  SEO_SETTINGS_REQUEST,
  SEO_SETTINGS_REQUEST_SUCCESS,
  SEO_SETTINGS_REQUEST_FAILURE
)<void, ISeoSettings, Error>();

function* seoSettingsSaga(): Generator {
  const loading = yield select(getSeoLoadingState);
  if (loading) { return; }

  yield put(fetchSeoSettingsAsync.request());

  try {
    const response: any = yield call(() => httpClient.fetch());
    if (response.data) {
      yield put(fetchSeoSettingsAsync.success(response.data));
    }
  } catch (err) {
    yield put(fetchSeoSettingsAsync.failure(err as Error));
  }
}

export function* seoSettingsRequestSaga() {
  yield takeEvery(fetchSeoSettings, seoSettingsSaga);
}

export default createReducer(seoSettingsInitialState)
  .handleAction(fetchSeoSettingsAsync.request, (state: ISeoSettingsState) => {
    return loadingDataWrapper(state.data);
  })
  .handleAction(
    fetchSeoSettingsAsync.success,
    (state: ISeoSettingsState, action: PayloadAction<SeoSettingsActionTypes, ISeoSettings>) =>
      loadedDataWrapper({
        ...state.data,
        settings: action.payload
      })
  )
  .handleAction(fetchSeoSettingsAsync.failure, (state: ISeoSettingsState) =>
    errorDataWrapper({ ...state.data, settings: [] }, new Error('Failed to load seoSettings'))
  );
