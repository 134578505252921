import styled from '@emotion/styled/macro';

import { scrollBar } from 'styles/mixins';
import { defaultMaterialTheme, ITheme, IThemed } from 'utils/styled';

export const FiltersWrapper = styled<any>('div')(({ theme }: IThemed) => ({
  paddingBottom: 40,
  flexDirection: 'column',
  fontFamily: theme.fonts.headings,
  fontSize: theme.fontSizes.large,
  fontWeight: 500,
  lineHeight: '24px',
  width: '100%',
  flex: 1,
  ...scrollBar
}));

export const FilterSectionWrapper = styled<any>('aside')(({ theme }: IThemed) => ({
  flex: 0.2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  position: 'absolute',

  backgroundColor: theme.palette.common.cardBlackBackgroundHex,

  [`> ${FiltersWrapper}`]: {
    display: 'flex'
  }
}));

export const Wrapper = styled<any>('div')(({ theme }: IThemed) => ({
  fontFamily: theme.fonts.headings,
  letterSpacing: theme.letterSpacing,
  color: theme.colors.gray
}));

export const Title = styled<any>('div')(({ theme }: IThemed) => ({
  fontFamily: theme.fonts.headings,
  fontSize: theme.fontSizes.medium,
  lineHeight: '21px',
  fontWeight: 'bold'
}));

const styles = (theme: ITheme) => ({
  devicePanel: {
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paddedExpandPanel: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    whiteSpace: 'nowrap',

    '& > div': {
      paddingTop: theme.spacing(1.5),
      borderTop: `1px solid ${theme.palette.common.lines}`
    }
  },
  deliveryPanel: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(3)
  },
  selectLabel: {
    top: 0,
    '&.MuiInputLabel-outlined': {
      top: 0
    }
  },
  paddedPanel: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3)
  },
  paddedPanelWrapper: {
    paddingTop: theme.spacing(1.5),
    borderTop: `1px solid ${theme.palette.common.lines}`
  },
  expandedList: {
    display: 'flex',
    flexDirection: 'column'
  },
  collapseAllList: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  TreeItem: {
    color: theme.palette.text.secondary
  },
  TreeItemActive: {
    color: theme.palette.common.black
  },
  TreeSubItemActive: {
    color: theme.palette.text.primary
  },
  TreeItemHideIcon: {
    display: 'none'
  },
  FilterWrapper: {
    '& .MuiFormControlLabel-root': {
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(1)
    }
  },
  TopToolbar: {
    marginBottom: theme.spacing(3)
  },
  expansionSummary: {
    alignItems: 'flex-start',
    height: 'auto',

    '& > div': {
      display: 'block',
      margin: '0 !important',
      marginBottom: '10px !important'
    },

    '& .flr-icon': {
      width: 16,
      height: 16
    }
  },
  expansionHeader: {
    marginBottom: 5
  },
  expansionDesc: {
    marginTop: 5
  },
  expansionReset: {
    visibility: 'hidden',
    opacity: 0,
    transition: defaultMaterialTheme.transitions.create(['opacity'], {
      easing: defaultMaterialTheme.transitions.easing.easeInOut,
      duration: defaultMaterialTheme.transitions.duration.shortest
    })
  },
  expansionResetActive: {
    visibility: 'visible',
    opacity: 1
  },
  expansionButton: {
    '&&': {
      marginTop: '-8px !important',
      marginRight: '-8px !important'
    }
  },
  deliveryExpansionButton: {
    '&&': {
      marginTop: '-8px !important'
    }
  },
  expansionBottom: {
    width: 'calc(100% + 20px)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'default',
    '@media (max-width: 720px)': {
      width: 'calc(100% + 10px)'
    }
  },
  simpleBottom: {
    width: '100%',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'default'
  },
  ResetButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    zIndex: 1,
    left: 70,
    width: 152,
    backgroundColor: theme.palette.common.white,
    borderColor: 'transparent !important',
    borderRadius: '100px',
    ...theme.shadow.cardHover
  },
  colorLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  colorIco: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 16,
    height: 16,
    border: `1px solid ${theme.palette.common.lines}`,
    borderRadius: '50%'
  },
  statusFilterRadio: {
    padding: '2px',
    margin: '0 4px 0 4px'
  }
});

export default styles;
