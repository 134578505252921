import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth, { authInitialState, IAuthState } from 'store/auth/actions';
import cart, { cartInitialState, ICartState } from 'store/cart/actions';
import catalog, { catalogInitialState, ICatalog } from 'store/catalog/actions';
import filterOptions, { filterOptionsInitialState, IFilterOptionsState } from 'store/filter-options/actions';
import { ILayoutState, layoutReducer as layout, layoutInitialState } from 'store/layout';
import main, { IMainState, mainInitialState } from 'store/main/actions';
import orders, { IOrdersState, ordersInitialState } from 'store/order/actions';

import accountDiscounts, { accountDiscountsInitialState, IAccountDiscountState } from './account-discounts/actions';
import account, { accountInitialState, IAccountState } from './account/actions';
import { IAsyncCatalogDataWrapper } from './actions';
import balanceHistory, { balanceHistoryInitialState, IBalanceHistoryState } from './balance-history/actions';
import checkout, { checkoutInitialState, ICheckoutState } from './checkout/actions';
import expiredCart, { expiredCartInitialState, IExpiredCartState } from './expired-cart/actions';
import outlets, { IOutletsState, outletsInitialState } from './outlets/actions';
import seo, { ISeoSettingsState, seoSettingsInitialState } from './seo/actions';
import staticPages, { IStaticPagesState, staticPagesInitialState } from './static-page/actions';
import transactions, { ITransactionsState, transactionsInitialState } from './transactions/actions';

// The top-level state object
export interface IApplicationState {
  catalog: IAsyncCatalogDataWrapper<ICatalog>;
  auth: IAuthState;
  account: IAccountState;
  router: any;
  layout: ILayoutState;
  cart: ICartState;
  checkout: ICheckoutState;
  filterOptions: IFilterOptionsState;
  outlets: IOutletsState;
  orders: IOrdersState;
  transactions: ITransactionsState;
  locale: any;
  expiredCart: IExpiredCartState;
  balanceHistory: IBalanceHistoryState;
  main: IMainState;
  seo: ISeoSettingsState;
  staticPages: IStaticPagesState;
  accountDiscounts: IAccountDiscountState;
}

export const initialState: any = {
  catalog: catalogInitialState,
  auth: authInitialState,
  account: accountInitialState,
  cart: cartInitialState,
  checkout: checkoutInitialState,
  layout: layoutInitialState,
  filterOptions: filterOptionsInitialState,
  outlets: outletsInitialState,
  orders: ordersInitialState,
  expiredCart: expiredCartInitialState,
  transactions: transactionsInitialState,
  balanceHistory: balanceHistoryInitialState,
  main: mainInitialState,
  seo: seoSettingsInitialState,
  staticPages: staticPagesInitialState,
  accountDiscounts: accountDiscountsInitialState
};

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding IApplicationState property productType.
const reducers = (history: any) =>
  combineReducers<IApplicationState>({
    locale: () => 'ua',
    router: connectRouter(history),
    account,
    auth,
    layout,
    filterOptions,
    catalog,
    cart,
    checkout,
    orders,
    outlets,
    expiredCart,
    transactions,
    balanceHistory,
    main,
    seo,
    staticPages,
    accountDiscounts
  });

export default reducers;
