const styles = (theme: any) => ({
  fullWidth: {
    width: '100%'
  },
  orderCard: {
    width: '100%',
    paddingBottom: theme.spacing(4)
  },
  orderCardActual: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',

      '&:first-of-type': {
        display: 'block',
        marginBottom: theme.spacing(4)
      },
      '&:nth-of-type(1)': {
        display: 'block'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:last-of-type': {
        marginBottom: 0
      }
    }
  },
  orderCardFinalized: {
    paddingTop: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      '&&': {
        display: 'none'
      }
    }
  },
  checkoutOrderFooterBlock: {
    marginLeft: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  outlineMargin: {
    transform: `translateY(-${theme.spacing(3)})`,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(-4.5),
    marginRight: theme.spacing(-4.5)
  },
  tableHeaders: {
    // marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  tableItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  orderItemSubtotal: {
    width: 150
  },
  paramValue: {
    fontSize: theme.fontSizes.medium
  },
  orderItemRemove: {
    // textDecoration: "underline"
  },
  noMarginBottom: {
    marginBottom: 0
  },
  noMarginTop: {
    marginTop: 0
  },
  orderCardActions: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  cartAction: {
    justifyContent: 'flex-end',
    padding: 0,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  fundsPositive: {
    color: theme.palette.common.primaryAccentText
  },
  gray: {
    color: theme.palette.text.secondary,
    '&:hover': {
      cursor: 'default',
      color: theme.palette.text.secondary
    }
  },
  disabled: {
    '& > *': {
      opacity: 0.5,
      color: theme.palette.text.secondary,
      textDecoration: 'none',
      '&:hover': {
        cursor: 'default',
        color: theme.palette.text.secondary,
        textDecoration: 'none'
      }
    }
  },
  black: {
    color: theme.palette.common.black
  },
  textRight: {
    textAlign: 'right'
  },
  delivery: {
    '&>:nth-child(1)': {
      [(theme as any).breakpoints.between('sm', 'lg')]: {
        order: 1
      }
    },
    '&>:nth-child(2)': {
      [(theme as any).breakpoints.between('sm', 'lg')]: {
        order: 3
      }
    },
    '&>:nth-child(3)': {
      [(theme as any).breakpoints.between('sm', 'lg')]: {
        order: 2
      }
    },
    '&>:nth-child(4)': {
      [(theme as any).breakpoints.between('sm', 'lg')]: {
        order: 4
      }
    }
  },
  deliveryMethodValue: {
    marginTop: 10,
    display: 'block'
  }
});

export default styles;
