import * as React from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import { withTheme } from '@mui/styles';
import styled from '@emotion/styled/macro';
import { transparentize } from 'polished';
import FlrInput from './FlrInput';
import { IThemed } from '../../../../utils/styled';

export interface IFlrInputProps {
  validators?: string[];
  errorMessages?: string[];
}

class FlrInputWhite extends React.Component<TextFieldProps & IFlrInputProps> {
  public render(): React.ReactNode {
    return <ComponentWrapper {...this.props} />;
  }
}

const ComponentWrapper = styled<any>(FlrInput)(({ theme }: IThemed) => ({
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    color: theme.colors.background,

    '&.Mui-disabled': {
      color: transparentize(0.5, theme.colors.background)
    },

    '&.MuiInputLabel-shrink, &.Mui-focused': {
      color: transparentize(0.2, theme.colors.background),

      '&.Mui-error': {
        color: theme.colors.red
      }
    }
  },
  '& .MuiInputBase-root.MuiInput-root': {
    '&.MuiInput-underline': {
      '&.Mui-focused:after': {
        color: transparentize(0.2, theme.colors.background),
        borderBottomColor: theme.colors.background
      },
      '&:after, &:before': {
        borderBottomColor: transparentize(0.75, theme.colors.background)
      }
    },
    '& .MuiInputBase-input.MuiInput-input': {
      color: theme.colors.background
    }
  },
  '& .MuiFormHelperText-root': {
    color: transparentize(0.2, theme.colors.background)
  }
}));

export default withTheme(FlrInputWhite);
