import { isMobileDevice } from 'environment';
import { Theme, useMediaQuery, useTheme } from '@mui/material';

import HeaderSearchDesktop from './HeaderSearchDesktop';
import HeaderSearchDevice from './HeaderSearchDevice';

const isMobile = isMobileDevice(window.navigator);

const HeaderSearch: React.FC<{}> = () => {
  const theme: Theme = useTheme();
  const screenDevice = useMediaQuery(theme.breakpoints.down('md'));

  if (screenDevice || isMobile) {
    return <HeaderSearchDevice />;
  }

  return <HeaderSearchDesktop />;
};

export default HeaderSearch;
