import { ChangeEvent, FC, MouseEvent, ReactNode, SyntheticEvent, useState } from 'react';
import clsx from 'clsx';
import { filter as _filter, remove as _remove, includes as _includes, now as _now } from 'lodash';
import styled from '@emotion/styled/macro';
import { TreeView, TreeItem } from '@mui/lab';
import { Collapse } from '@mui/material';

import { IFilterProp, IFilterProps, IFiltersData } from 'store/catalog/actions';
import { IFilterOptions, IFilterProductCatalogCategory } from 'models';
import { IThemed } from 'utils/styled';

import FlrCheckboxWithLabel from 'components/shared/form-elements/FlrCheckbox';
import Icon from 'components/shared/Icon';
import { ExpandPanel } from 'components/shared';
import { Link2 } from 'components/shared/text';
import FilterLabel from './FilterLabel';
import { filtersMessages } from 'translations/catalog/filters';
import {
  getGroupState,
  prepareFilterCategoriesStateChildren,
  sortByIsTopLevel,
  sortByLocaleName,
  sortByProductCountAndChecked
} from './options-tools';
import { FILTER_CATEGORY_PATH } from 'shared/filters';

interface IProps {
  classes: any;
  filters: IFiltersData;
  filterOptionsData: IFilterOptions;
  setFilters: (newFilters: IFilterProps) => void;
  label: string;
  locale: string;
}

const CatalogCategory: FC<IProps> = ({
  locale,
  label: labelSection,
  filters,
  setFilters,
  filterOptionsData,
  classes
}) => {
  const [allList, setAllList] = useState(false);
  const [expanded, setExpanded] = useState<string[]>([]);
  const filtersFast = filters.fast || [];

  if (
    !filterOptionsData.catalogCategory.length ||
    (filterOptionsData.catalogCategory.length === 1 && !filterOptionsData.catalogCategory[0].items.length)
  ) {
    return null;
  }

  const handleChange = (_event: ChangeEvent<{}>, nodes: string[]) => {
    setExpanded(nodes);
  };

  const handleStopPropagation = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
  };

  // map filters on filterOptions
  let attributeOptionNodes: ReactNode[] = [];
  const attributeOptions = filterOptionsData.catalogCategory;
  const attributeFilterValues: string[] =
    _filter(filters.fast, { path: FILTER_CATEGORY_PATH } as IFilterProp).map(filter => filter.value) || [];

  const arraySelected = filtersFast.filter(item => item.path === FILTER_CATEGORY_PATH);
  const arraySelectedLength = (arraySelected && arraySelected.length) || 0;
  const arraySelectedLast = arraySelectedLength ? arraySelected[0] : null;

  const handleResetCategoryFilters = (ev: SyntheticEvent | MouseEvent) => {
    if (arraySelectedLast) {
      ev.stopPropagation();
      setFilters(filtersFast.filter(item => item.path !== FILTER_CATEGORY_PATH));
    }
  };

  const handleChangeCheckbox = (item: IFilterProductCatalogCategory) => (
    _event: SyntheticEvent,
    isChecked: boolean
  ) => {
    const isHasChildren = item.items && item.items.length;

    if (isHasChildren) {
      prepareFilterCategoriesStateChildren(item, isChecked, filtersFast);
      return setFilters(filtersFast);
    }

    const newFilterPropValue: IFilterProp = {
      rule: 'include',
      path: 'category',
      value: item.code,
      label: item.name
    };

    if (!isChecked) {
      _remove(filtersFast, newFilterPropValue);
    } else if (!_includes(filtersFast, newFilterPropValue)) {
      newFilterPropValue.time = _now();
      filtersFast.push(newFilterPropValue);
    }

    setFilters(filtersFast);
  };

  const handleGetTreeChildren = (items: IFilterProductCatalogCategory[], isTopLevel: boolean): ReactNode[] => {
    const arr: ReactNode[] = [];

    const isAnyItemHasChildren = items.some(item => !!item.items.length);

    items.sort(sortByLocaleName(locale)).forEach((value: IFilterProductCatalogCategory) => {
      if (!value.id || value.hidden) {
        return;
      }

      const isHasChildren = value && value.items;
      const groupState = getGroupState(attributeFilterValues, value);
      const isIndeterminate = isHasChildren ? groupState.isUnchecked && groupState.isChecked : false;

      const isSelected = attributeFilterValues.length > 0 && attributeFilterValues.includes(value.code);
      const isChecked = isHasChildren ? groupState.isChecked || isSelected : isSelected;

      const treeLabel = (
        <StyledCheckbox
          className={clsx({
            active: isChecked && isTopLevel,
            'active--subitem': isChecked && !isTopLevel
          })}
          checked={isChecked}
          disabled={!Boolean(value.productsCount) && !isChecked}
          indeterminate={isIndeterminate}
          label={`${value.name !== '' ? value.name : 'Інша'} (${value.productsCount})`}
          onChange={handleChangeCheckbox(value)}
          onClick={handleStopPropagation}
        />
      );

      if (isHasChildren) {
        let treeItemCustomIconProps = {};
        if (isChecked || isIndeterminate) {
          treeItemCustomIconProps = {
            expandIcon: <Icon size={24} offset={5} opacity={1} type="chevronRight" />,
            collapseIcon: <StyledIcon size={24} offset={5} opacity={1} type="chevronDown" />
          };
        }
        return arr.push(
          <TreeItem
            classes={{ iconContainer: clsx({ [classes.TreeItemHideIcon]: !isAnyItemHasChildren && isTopLevel }) }}
            nodeId={value.id}
            key={value.id}
            label={treeLabel}
            {...treeItemCustomIconProps}
          >
            {handleGetTreeChildren(value.items, false)}
          </TreeItem>
        );
      }

      arr.push(<TreeItem nodeId={value.id} key={value.id} label={treeLabel} />);
    });

    return arr;
  };

  const items =
    filterOptionsData.catalogCategory.length === 1 && filterOptionsData.catalogCategory[0].items.length
      ? filterOptionsData.catalogCategory[0].items
      : filterOptionsData.catalogCategory;

  if (attributeOptions) {
    attributeOptionNodes = handleGetTreeChildren(items, true);
  }
  const labelComp = (
    <FilterLabel
      classes={classes}
      label={labelSection}
      filterOptionsData={filterOptionsData}
      arraySelectedLast={arraySelectedLast}
      arraySelectedLength={arraySelectedLength}
      defaultCaption={filtersMessages.categoryFilterAll.defaultMessage}
      handleReset={handleResetCategoryFilters}
    />
  );

  const filteredItems = items
    .filter((c: IFilterProductCatalogCategory) => c.productsCount || attributeFilterValues.includes(c.code))
    .sort(sortByProductCountAndChecked(attributeFilterValues, locale))
    .sort(sortByIsTopLevel)
    .slice(0, 15);

  const firstAttributeOptionNodes = handleGetTreeChildren(filteredItems, true);

  const toggleAllList = () => setAllList(state => !state);

  return (
    <ExpandPanel
      defaultExpanded={true}
      label={labelComp}
      className={classes.paddedExpandPanel}
      classNameSummary={classes.expansionSummary}
      classNameButton={classes.expansionButton}
    >
      <TreeView
        expanded={expanded}
        onNodeToggle={handleChange}
        defaultExpandIcon={<Icon size={24} offset={5} opacity={1} type="chevronRight" />}
        defaultCollapseIcon={<Icon size={24} offset={5} opacity={1} type="chevronDown" />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        {!allList ? firstAttributeOptionNodes : null}

        <Collapse in={allList}>
          <div className={classes.expandedList}>
            {attributeOptionNodes.map(item => {
              return item;
            })}
          </div>
        </Collapse>
      </TreeView>

      <Link2 className={classes.collapseAllList} onClick={toggleAllList}>
        {allList ? filtersMessages.collapseIn.defaultMessage : filtersMessages.collapseOut.defaultMessage} (
        {attributeOptionNodes.length - firstAttributeOptionNodes.length})
      </Link2>
    </ExpandPanel>
  );
};

const StyledIcon = styled<any>(Icon)(({ theme }: IThemed) => ({
  svg: {
    path: {
      fill: theme.palette.primary.main,
      fillOpacity: 1
    }
  }
}));

const StyledCheckbox = styled<any>(FlrCheckboxWithLabel)(({ theme }: IThemed) => ({
  '& + .MuiTypography-root': {
    color: theme.palette.text.secondary
  },
  '&.active--subitem + .MuiTypography-root': {
    color: theme.palette.text.primary
  },
  '&.active + .MuiTypography-root': {
    color: theme.palette.common.black
  }
}));

export default CatalogCategory;
