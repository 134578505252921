import Skeleton from '@mui/material/Skeleton';

const HeaderTopSkeleton = () => (
  <>
    <Skeleton variant="rectangular" width={228} height={22} style={{ padding: 4, marginLeft: 16 }} />

    {[...Array(2)].map((_, i) => (
      <Skeleton key={i} variant="rectangular" width={148} height={22} style={{ padding: 4, marginLeft: 16 }} />
    ))}
  </>
);

export default HeaderTopSkeleton;
