import { defineMessages } from "react-intl";

export default defineMessages({
  success: {
    id: "notifications.success",
    description: "",
    defaultMessage: "Успіх"
  },
  error: {
    id: "notifications.error",
    description: "",
    defaultMessage: "Помилка"
  },
  warning: {
    id: "notifications.warning",
    description: "",
    defaultMessage: "Увага"
  },
  errorOccurred: {
    id: "notifications.outletAdded",
    description: "",
    defaultMessage: "Сталася помилка, будь ласка спробуйте пізніше."
  }
});
