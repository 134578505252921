import { defineMessages } from "react-intl";

export default defineMessages({
  register: {
    id: "auth.common.register",
    description: "Registration btn label",
    defaultMessage: "Реєстрація"
  },
  login: {
    id: "auth.common.login",
    description: "Login btn label",
    defaultMessage: "Вхід"
  },
  logoCaption: {
    id: "auth.common.logoCaption",
    description: "auth.common.logoCaption",
    defaultMessage: "Платформа закупівлі найсвіжіших квітів.<br />Для професіоналів своєї справи."
  },
  bottomCaptionRegistration: {
    id: "auth.common.bottomCaptionRegistration",
    description: "auth.common.bottomCaptionRegistration",
    defaultMessage: "Новий користувач?"
  },
  bottomCaptionLogin: {
    id: "auth.common.bottomCaptionLogin",
    description: "auth.common.bottomCaptionLogin",
    defaultMessage: "Вже зареєстровані?"
  },
  bottomLinkRegistration: {
    id: "auth.common.bottomLinkRegistration",
    description: "auth.common.bottomLinkRegistration",
    defaultMessage: "Зареєструйтесь"
  },
  bottomLinkLogin: {
    id: "auth.common.bottomLinkLogin",
    description: "auth.common.bottomLinkLogin",
    defaultMessage: "Увійдіть"
  }
});
