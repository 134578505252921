import { FC } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import 'moment/locale/uk';

import { useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';

import { ExpandPanel } from 'components/shared';
import FlrButtonOutlinedBrown from 'components/shared/buttons/FlrButtonOutlinedBrown';
import FlrCardNarrow from 'components/shared/card/FlrCardNarrow';
import { baseUrl, genitiveMonths } from 'shared/constants';
import { IAccountDiscountsTemp } from 'store/account-discounts/actions';
import { getAccountDiscountsLoadingState } from 'store/account-discounts/selectors';

import { ProductGroupDiscountProgressCard } from '../cards';

import classes from './GroupProducts.module.scss';

interface IGroupProductsProps {
  discounts: IAccountDiscountsTemp | null;
}

const GroupProducts: FC<IGroupProductsProps> = ({ discounts }) => {
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const loading = useSelector(getAccountDiscountsLoadingState);

  if (!discounts?.['purchased-qty']?.length) {
    return null;
  }

  const getMonthPeriod = (date?: string): string => {
    const prevDay = moment(date).add(1, 'day');
    const startOfMonth = prevDay.startOf('month').format('D.MM.YY');
    const endOfMonth = prevDay.endOf('month').format('D.MM.YY');
    const monthName = prevDay.format('MMMM');

    return `${monthName}, ${startOfMonth}-${endOfMonth}`;
  };

  const getMonthNameWithYear = (date?: string, isGenitive?: boolean): string => {
    const momentDate = moment(date).utc().locale('uk').add(1, 'month');
    const monthName = momentDate.format('MMMM');
    const year = momentDate.format('YYYY');

    const formattedMonth = isGenitive ? genitiveMonths[monthName] : monthName;

    return isGenitive ? formattedMonth : `${formattedMonth}, ${year}`;
  };

  const getFormatDate = (date?: string): string => {
    const prevDay = moment(date).add(1, 'day');
    const startOfMonth = prevDay.startOf('month').format('D.MM.YY');
    const endOfMonth = prevDay.endOf('month').format('D.MM.YY');

    return `${startOfMonth}-${endOfMonth}`;
  };

  return (
    <div className={classes.container}>
      {discounts?.['purchased-qty'].map((item, i) => {
        const maxConditionValue = Math.max(...Object.keys(item.conditions).map(Number));
        const nextDiscountPercent = item.nextDiscountProgress.percent + 1
        const nextDiscountValue = Number(item.conditions[nextDiscountPercent]) - item.nextDiscountProgress.progressValue;
        return (
          <FlrCardNarrow hoverShadow className={classes.card} key={i}>
            <div className={classes.titleContainer}>
              <h3 className={classes.title}>{item.name}</h3>
              <Link to={`${baseUrl}/catalog/${item.productType[0] || ''}`}>
                <FlrButtonOutlinedBrown classes={{ root: classes.link }}>До каталогу</FlrButtonOutlinedBrown>
              </Link>
            </div>
            <div className={classes.content}>
              <div className={classes.contentItem}>{item.categories.join(', ')}</div>
              <div className={classes.contentItem}>
                <div className={classes.historyContainer}>
                  <div className={classes.historyContent}>
                    {screenMobile ? (
                      <p className={classes.historyDate}>{getMonthPeriod(item.nextDiscountProgress.startDate)}</p>
                    ) : (
                      <>
                        <p className={classes.historyPeriod}>
                          {getMonthNameWithYear(item.nextDiscountProgress.startDate)}
                        </p>
                        <p className={classes.historyDate}>{getFormatDate(item.nextDiscountProgress.startDate)}</p>
                      </>
                    )}
                  </div>
                  <div className={classes.discountContainer}>
                    <span className={classes.discountText}>Діюча знижка</span>
                    <p className={classes.discountPercent}>{item.currentDiscount?.percent}%</p>
                  </div>
                </div>
              </div>
              <div className={classes.contentItem}>
                <div className={classes.historyContainer}>
                  {screenMobile ? (
                    <ExpandPanel
                      leftIcon
                      classNameSummary={classes.summary}
                      label={
                        <>
                          <div className={classes.guaranteedDiscountContainer}>
                            <p className={classes.guaranteedDiscountLabel}>Гарантована знижка</p>
                            <p className={classes.guaranteedDiscountValue}>
                              {item.nextDiscountProgress.percent}%
                            </p>
                          </div>
                          <div className={classes.historyContent}>
                            <p className={classes.historyDate}>{getMonthPeriod(item.nextDiscountProgress.endDate)}</p>
                          </div>
                        </>
                      }
                    >
                      <div className={classes.buyProductsContainer}>
                        <p className={classes.buyProductsLabel}>
                          {' '}
                          Куплено товарів у {getMonthNameWithYear(item.nextDiscountProgress.startDate, true)}
                        </p>
                        <p className={classes.buyProductsValue}>{item.nextDiscountProgress?.progressValue} шт</p>
                      </div>
                      <div className={classes.needToBuyContainer}>
                        <p className={classes.needToBuyLabel}>Для знижки у {nextDiscountPercent}% викупіть ще</p>
                        <p className={classes.needToBuyValue}>{nextDiscountValue} шт</p>
                      </div>
                      <ProductGroupDiscountProgressCard
                        discount={item}
                        loading={loading}
                        progressValue={Number(item.progressValue) || 0}
                      />
                    </ExpandPanel>
                  ) : (
                    <>
                      <div className={classes.historyContent}>
                        <p className={classes.historyPeriod}>
                          {getMonthNameWithYear(item.nextDiscountProgress.endDate)}
                        </p>
                        <p className={classes.historyDate}>{getFormatDate(item.nextDiscountProgress.endDate)}</p>
                      </div>
                      <div className={classes.guaranteedDiscountContainer}>
                        <p className={classes.guaranteedDiscountLabel}>Гарантована знижка</p>
                        <p className={classes.guaranteedDiscountValue}>{item.nextDiscountProgress.percent}%</p>
                      </div>
                      <div className={classes.buyProductsContainer}>
                        <p className={classes.buyProductsLabel}>
                          Куплено товарів у {getMonthNameWithYear(item.nextDiscountProgress.startDate, true)}
                        </p>
                        <p className={classes.buyProductsValue}>{item.nextDiscountProgress?.progressValue} шт</p>
                      </div>
                      {maxConditionValue > item.nextDiscountProgress.percent ? (
                        <div className={classes.needToBuyContainer}>
                          <p className={classes.needToBuyLabel}>
                            Для знижки у {nextDiscountPercent}% викупіть ще
                          </p>
                          <p className={classes.needToBuyValue}>{nextDiscountValue} шт</p>
                        </div>
                      ) : null}
                      <ProductGroupDiscountProgressCard
                        discount={item}
                        loading={loading}
                        progressValue={Number(item.progressValue) || 0}
                      />
                    </>
                  )}
                </div>
              </div>
              <Link className={classes.mobileLink} to={`${baseUrl}/catalog/${item.productType[0] || ''}`}>
                <FlrButtonOutlinedBrown>До каталогу</FlrButtonOutlinedBrown>
              </Link>
            </div>
          </FlrCardNarrow>
        );
      })}
    </div>
  );
};

export default GroupProducts;
