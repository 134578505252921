import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
// import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { SplitedPrice } from 'components/prices';
import Icon from 'components/shared/Icon';
import { TextBody1, TextHelper } from 'components/shared/text';
import { UAH } from 'shared/constants';
import messages from 'translations/layout/controls';

function NoOptionsMessage(props: any) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {messages.selectNoOptions.defaultMessage}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

// @ts-ignore
function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

function Control(props: any) {
  const {
    children,
    innerProps,
    innerRef,
    menuIsOpen,
    getStyles,
    selectProps: { classes, TextFieldProps, dropdownMenu, dropdownPlacement }
  } = props;

  return (
    <TextField
      fullWidth
      className={clsx(classes.inputBase, {
        [classes.inputActive]: menuIsOpen && dropdownPlacement === 'top',
        [classes.inputActiveTop]: menuIsOpen && dropdownPlacement !== 'top',
        [classes.inputDropdownMenu]: dropdownMenu && !menuIsOpen,
        [classes.inputDropdownMenuOpen]: dropdownMenu && menuIsOpen
      })}
      InputProps={{
        inputComponent,
        inputProps: {
          className: clsx(classes.input, classes.inputClasses),
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      styles={getStyles('input', props)}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired
};

function Option(props: any) {
  const {
    isDisabled,
    isSelected,
    selectProps: { classes },
    data
  } = props;

  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={clsx(classes.menuItem, {
        menuItemDisabled: isDisabled,
        [classes.menuItemDisabled]: isDisabled,
        [classes.menuItemSelected]: isSelected,
        [classes.menuItemAction]: !!data.onClick
      })}
      {...props.innerProps}
    >
      {data.render || (
        <Grid container>
          <Grid item className={classes.menuItemMain}>
            <TextBody1 component={'span'} className={clsx('main-label', { 'main-label--action': !!data.onClick })}>
              {data.icon && <Icon type={data.icon} width={16} height={16} opacity={1} offset={8} />}
              {props.children}
            </TextBody1>
            {data.desc && (
              <TextHelper component={'div'} className={'description'}>
                {data.desc}
              </TextHelper>
            )}
          </Grid>
          {data.amount && (
            <Grid className={classes.menuItemAmount} item>
              <SplitedPrice fontSize={'body1'} value={data.amount} postfix={UAH} hideDecimal />
            </Grid>
          )}
        </Grid>
      )}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool
};

function Placeholder() {
  return null;
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function SingleValue(props: any) {
  return (
    <TextBody1 component={'span'} className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </TextBody1>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function ValueContainer(props: any) {
  const {
    selectProps: { labelIcon, labelIconRight, classes }
  } = props;

  return (
    <div className={classes.valueContainer}>
      {!!labelIcon && labelIcon}
      {props.children}
      {!!labelIconRight && labelIconRight}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
};

// function MultiValue(props: any) {
//   return (
//     <Chip
//       tabIndex={-1}
//       label={props.children}
//       className={clsx(props.selectProps.classes.chip, {
//         [props.selectProps.classes.chipFocused]: props.isFocused
//       })}
//       onDelete={props.removeProps.onClick}
//       deleteIcon={<span>X</span>}
//     />
//   );
// }
//
// MultiValue.propTypes = {
//   children: PropTypes.node,
//   isFocused: PropTypes.bool,
//   removeProps: PropTypes.object.isRequired,
//   selectProps: PropTypes.object.isRequired
// };

// function Menu(props: any) {
//   return (
//     <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
//       {props.children}
//     </Paper>
//   );
// }

// Menu.propTypes = {
//   children: PropTypes.node,
//   innerProps: PropTypes.object,
//   selectProps: PropTypes.object
// };

function LoadingMessage(props: any) {
  const {
    selectProps: { classes }
  } = props;

  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={classes.menuItem}
      {...props.innerProps}
    >
      <Grid container>
        <Grid item className={classes.menuItemMain}>
          <TextBody1 component={'span'} className={'main-label'}>
            {messages.selectLoading.defaultMessage}
          </TextBody1>
        </Grid>
      </Grid>
    </MenuItem>
  );
}

const DropdownIndicator = (props: any) => {
  const {
    selectProps: { classes, menuIsOpen, dropdownMenu }
  } = props;

  if (dropdownMenu) {
    return null;
  }

  return (
    <div
      className={clsx(classes.DropdownIndicator, {
        [classes.DropdownIndicatorActive]: menuIsOpen
      })}
    >
      <Icon size={24} type={'chevronDown'} />
    </div>
  );
};

const components = {
  IndicatorSeparator: () => null,
  DropdownIndicator,
  Control,
  // Menu,
  // MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  LoadingMessage
};

export default components;
