import { FC } from 'react';

import Icon from './Icon';

import { IPhoneContact, phoneContacts } from 'shared/constants';
import classes from './PhonesList.module.scss';
interface IProps {
  phones?: string[] | undefined;
}

export const PhonesListComponent: FC<IProps> = ({ phones }) => (
  <>
    {phones
      ? phones.map((phone, i) => (
          <div key={`phone${i}`}>
            <a href={`tel:${phone}`} className={classes.phone}>{phone}</a>
          </div>
        ))
      : phoneContacts.map((item: IPhoneContact, index: number) =>
          item ? (
            <div key={`phone${index}`}>
              <div className={classes.wrapper}>
                <Icon type={item.icon || 'phoneCall'} size={18} opacity={1}/>
                <div>
                  <a href={`tel:${item.phone}`} className={classes.phone}>{item.phone}</a>
                  {item.additionalText ? <p className={classes.additionalText}>{item.additionalText}</p> : null}
                </div>
              </div>
            </div>
          ) : null
        )}
  </>
);
