import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';

import { baseUrl, defaultCatalogProductType } from 'shared/constants';

import { TitleH2, TextCaption } from 'components/shared/text';
import styles from './styles';
import messages from 'translations/main/categoryCutFlowers';

interface IProps {
  title: any;
  link?: string;
  count?: number;
  // component own props
  classes: any;
}

const MainCatalogPromo: React.FC<IProps> = ({ classes, link, count = 0, title, children }) => {
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <TitleH2 className={classes.title} component={'div'}>
          {title}
        </TitleH2>
        <TextCaption className={classes.count}>
          <FormattedMessage
            {...messages.catalogCount}
            values={{
              count: count.toLocaleString('en')
            }}
          />
        </TextCaption>

        <Button
          className={classes.linkButton}
          color={'primary'}
          variant={'contained'}
          size={'small'}
          component={NavLink}
          to={link || `${baseUrl}/catalog/${defaultCatalogProductType}`}
        >
          {messages.toCatalog.defaultMessage}
        </Button>
        {children}
      </div>
    </div>
  );
};

export default withStyles<any>(styles)(MainCatalogPromo);
