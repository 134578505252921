import React, { ReactNode } from 'react';

import { IFilterOptions } from 'models';
import { IFiltersData } from 'store/catalog/actions';
import FlrCheckboxWithLabel from 'components/shared/form-elements/FlrCheckbox';
import { ExpandPanel } from 'components/shared';
import FilterLabel from './FilterLabel';

import { filtersMessages } from 'translations/catalog/filters';

interface IProps {
  filters: IFiltersData;
  handleFilterChange: (type: string) => void;
  label: string;
  classes: any;
  loggedIn: boolean;
}
interface IStateProps {
  // Props passed to the component by `connect`
}

interface IDispatchProps {
  filterOptionsData: IFilterOptions;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

const ExtraFilters: React.FC<IComponentProps> = ({
  loggedIn,
  label: labelSection,
  classes,
  filters,
  handleFilterChange
}) => {
  if (!loggedIn) {
    return null;
  }

  const attributeOptionNodes: ReactNode[] = [
    <FlrCheckboxWithLabel
      key="extra-filter-0"
      checked={filters.watchOnly}
      label={filtersMessages.extraFiltersFavourite.defaultMessage}
      onChange={handleFilterChange('watchOnly')}
    />
    // * Uncomment this if you want to add discount filter
    // <FlrCheckboxWithLabel
    //   key="extra-filter-1"
    //   checked={filters.discount}
    //   label={filtersMessages.extraFiltersDiscount.defaultMessage}
    //   onChange={handleFilterChange('discount')}
    // />
  ];

  const labelComp = <FilterLabel classes={classes} label={labelSection} />;

  return (
    <ExpandPanel
      defaultExpanded={true}
      label={labelComp}
      className={classes.paddedExpandPanel}
      classNameSummary={classes.expansionSummary}
      classNameButton={classes.expansionButton}
    >
      {attributeOptionNodes}
    </ExpandPanel>
  );
};

export default ExtraFilters;
