import React, { useEffect, useRef, useState } from 'react';
import { connect, MapDispatchToProps, MapStateToProps, useSelector } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import { bindActionCreators, Dispatch } from 'redux';
import { throttle } from 'lodash';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import { useTheme, withStyles } from '@mui/styles';

import EmptyCart from 'components/EmptyCart';
import Breadcrumbs from 'components/layout/breadcrumbs/Breadcrumbs';
import SidePanelsLayout from 'components/layout/side-panels-layout';
import FullHeightSkeleton from 'components/loading/FullHeightSkeleton';
import ProductDetails from 'components/shared/product/product-details/ProductDetails';
import { useTrackInitialPageLoad } from 'controllers';
import { Cart } from 'models';
import {
  addCartItemAsync,
  fetchCartAsync,
  redirectToCatalog,
  removeCartItemAsync,
  selectProductAsync,
  setActiveItem,
  unselectProduct
} from 'store/cart/actions';
import {
  getCart,
  getCartActiveItem,
  getCartLoaded,
  getCartLoadingState,
  getExpiredCartLayoutExpanded,
  getSelectedProduct,
  getSelectedProductLoaded,
  getSelectedProductLoadingState
} from 'store/cart/selectors';
import { ISelectedProduct } from 'store/catalog/actions';
import { renewCheckoutAsync } from 'store/checkout/actions';
import {
  fetchExpiredCartAsync,
  removeExpiredCartItemAsync,
  restoreExpiredCartAsync,
  updateExpiredCartItemAsync
} from 'store/expired-cart/actions';
import { getExpiredCart, getExpiredCartLoaded, getExpiredCartLoadingState } from 'store/expired-cart/selectors';
import { IApplicationState } from 'store/reducers';

import CartMain from './components/CartMain';
import CartMainDevice from './components/CartMainDevice';
import CartLeftSide from './components/СartLeftSide';
import styles from './styles';

interface IProps {
  classes?: any;
}

interface IStateProps {
  cart: Cart;
  cartLoaded: boolean;
  cartLoadingState: boolean;
  expiredCart: Cart;
  expiredCartLoaded: boolean;
  expiredCartLoadingState: boolean;
  expiredCartExpanded: boolean;
  selectedProduct: ISelectedProduct;
  selectedProductLoaded: boolean;
  selectedProductLoadingState: boolean;
  activeCartItem: number;
}

interface IDispatchProps {
  unselectProduct: typeof unselectProduct;
  selectProduct: typeof selectProductAsync.request;
  checkoutCart: typeof renewCheckoutAsync.request;
  restoreExpiredCart: typeof restoreExpiredCartAsync.request;
  addCartItem: typeof addCartItemAsync.request;
  removeCartItem: typeof removeCartItemAsync.request;
  loadCart: typeof fetchCartAsync.request;
  loadExpiredCart: typeof fetchExpiredCartAsync.request;
  updateExpiredCartItem: typeof updateExpiredCartItemAsync.request;
  removeExpiredCartItem: typeof removeExpiredCartItemAsync.request;
  catalogRedirect: typeof redirectToCatalog;
  setActiveCartItem: typeof setActiveItem;
}

type CartProps = IProps & IStateProps & IDispatchProps;

const UnconnectedCart: React.FC<CartProps> = ({
  cart,
  cartLoaded,
  cartLoadingState,
  expiredCart,
  expiredCartLoaded,
  expiredCartLoadingState,
  selectedProduct,
  selectedProductLoaded,
  selectedProductLoadingState,
  activeCartItem,
  expiredCartExpanded,
  setActiveCartItem,
  loadExpiredCart,
  loadCart,
  classes
}) => {
  useTrackInitialPageLoad();
  const isExpiredCartExpanded = useSelector(getExpiredCartLayoutExpanded);

  const anchor = 'cart-page';

  const offset = useRef(0);
  const [displayedGroup, setDisplayedGroup] = useState<string | undefined>('');
  const [isCart, setIsCart] = useState(false);
  const [isRightPanel, setIsRightPanel] = useState(false);
  useEffect(() => {
    const containerEl = document.getElementById(`${anchor}-container`);
    if (!cartLoaded || cartLoadingState || expiredCartLoadingState || !expiredCartLoaded || !containerEl) {
      return;
    }

    const firstAnchor = containerEl.querySelector(`.${anchor}-item`);
    const allAnchors = containerEl.querySelectorAll(`.${anchor}-item`);
    const lastAnchor = allAnchors.length > 1 && (allAnchors.item(allAnchors.length - 1) as HTMLElement);

    if (firstAnchor) {
      const el = firstAnchor as HTMLElement;
      offset.current = -(el.offsetTop + 100);

      if (lastAnchor) {
        lastAnchor.style.marginBottom = `${Math.abs(
          offset.current + Math.max(0, containerEl.offsetHeight - lastAnchor.offsetHeight)
        )}px`;
      }

      const firstValidAnchor = Array.from(allAnchors).find((item) => {
        const firstElementId = item.children[0]?.id;

        if (isExpiredCartExpanded) {
          return firstElementId && firstElementId.includes('expired');
        }

        return firstElementId && !firstElementId.includes('expired');
      });

      if (firstValidAnchor) {
        const firstElementId = firstValidAnchor.children[0]?.id;
        if (firstElementId) {
          setDisplayedGroup(`${anchor}-${firstElementId}`);
        }
      }
    }
  }, [offset, cartLoaded, cartLoadingState, expiredCartLoaded, expiredCartLoadingState, isExpiredCartExpanded]);

  const handleScroll = () => {
    if (window.scrollY > 177) {
      setIsCart(true);
    } else {
      setIsCart(false);
    }

    if (window.scrollY > 70) {
      setIsRightPanel(true);
    } else {
      setIsRightPanel(false);
    }

    const containerEl = document.getElementById(`${anchor}-container`);
    if (!containerEl) {
      return;
    }

    const allAnchors = containerEl.querySelectorAll(`.${anchor}-item`);
    let currentActiveGroup = displayedGroup;

    Array.from(allAnchors).forEach((item, index) => {
      const rect = item.getBoundingClientRect();
      const heightPage = window.innerHeight;
      const nextItem = allAnchors[index + 1];

      if (rect.height <= heightPage && rect.top >= 0 && rect.bottom <= heightPage) {
        const firstElementId = item.children[0]?.id;
        if (firstElementId && !firstElementId.includes('expired')) {
          currentActiveGroup = `${anchor}-${firstElementId}`;
        }
      }

      else if (rect.bottom - 100 <= 0 && nextItem) {
        const nextElementId = nextItem.children[0]?.id;
        if (nextElementId && !nextElementId.includes('expired')) {
          currentActiveGroup = `${anchor}-${nextElementId}`;
        }
      }

      else if (rect.top <= 0 && rect.bottom > 0) {
        const firstElementId = item.children[0]?.id;
        if (firstElementId && !firstElementId.includes('expired')) {
          currentActiveGroup = `${anchor}-${firstElementId}`;
        }
      }
    });

    if (currentActiveGroup !== displayedGroup) {
      setDisplayedGroup(currentActiveGroup);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', throttle(handleScroll, 50));
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const containerEl = document.getElementById(`${anchor}-container`);
    setTimeout(() => {
      if (containerEl) {
        scroll.scrollTo(containerEl.scrollTop + 1, { containerId: `${anchor}-container` });
      }
    }, 500);
  }, [expiredCartExpanded]);

  useEffect(() => {
    if (!expiredCartLoadingState && !expiredCartLoaded) {
      loadExpiredCart();
    }
  }, [loadExpiredCart, expiredCartLoadingState, expiredCartLoaded]);

  useEffect(() => {
    loadCart();
    // eslint-disable-next-line
  }, []);

  const theme: Theme = useTheme();
  const screenFullHD = useMediaQuery(theme.breakpoints.up('xl'));
  const screenHD = useMediaQuery(theme.breakpoints.up('lg'));
  // const screenDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  // const screenTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const screenMobile = useMediaQuery(theme.breakpoints.down('md'));

  const initialLoading = (expiredCartLoadingState || cartLoadingState) && !(expiredCartLoaded || cartLoaded);
  const hasAnyItems = cart.items.length > 0 || expiredCart.items.length > 0;

  return (
    <Grid>
      <SidePanelsLayout
        fullScreen={false}
        smallWidth
        isFixed={isCart}
        isFixedRightPanel={isRightPanel}
        topPanel={
          !screenMobile
            ? () => (
                <Breadcrumbs
                  links={[
                    {
                      path: '/catalog/shear-flowers',
                      label: 'Каталог'
                    },
                    {
                      label: 'Кошик'
                    }
                  ]}
                />
              )
            : undefined
        }
        leftPanelToggleable={hasAnyItems && screenHD}
        leftPanelOpened={hasAnyItems && screenHD}
        leftPanel={
          !screenHD || !hasAnyItems
            ? () => null
            : () =>
                initialLoading ? (
                  <Grid item className={classes.cartLeftSide} style={{ paddingRight: 24 }}>
                    <FullHeightSkeleton skeletonHeight={100} withTitle />
                  </Grid>
                ) : (
                  <CartLeftSide
                    anchor={anchor}
                    anchorOffset={offset.current}
                    cart={cart}
                    expiredCart={expiredCart}
                    displayedGroup={displayedGroup}
                  />
                )
        }
        rightPanelToggleable={hasAnyItems && screenFullHD}
        rightPanelOpened={hasAnyItems && screenFullHD}
        rightPanel={
          screenFullHD || hasAnyItems
            ? () =>
                selectedProduct ? (
                  <ProductDetails
                    item={selectedProduct}
                    favouriteWrapper={classes.favouriteWrapper}
                    countImageStyle={{ top: 218 }}
                    maximizeImageStyle={{ top: 213 }}
                  />
                ) : null
            : () => null
        }
        anchorIndex={activeCartItem}
        trackAnchor={anchor}
        setAnchorIndex={(index: number) => setActiveCartItem({ index })}
      >
        {screenMobile ? (
          <Grid item xs={12} className={classes.mobileWrapper}>
            {initialLoading ? (
              <FullHeightSkeleton skeletonHeight={200} withPadding withTitle />
            ) : !hasAnyItems ? (
              <EmptyCart withButton />
            ) : (
              <CartMainDevice
                anchor={anchor}
                selectedProduct={selectedProduct}
                cart={cart}
                cartLoaded={cartLoaded}
                cartLoadingState={cartLoadingState}
                expiredCart={expiredCart}
                expiredCartLoaded={expiredCartLoaded}
                expiredCartLoadingState={expiredCartLoadingState}
              />
            )}
          </Grid>
        ) : (
          <Grid item xs={12} className={classes.wrapper} id="cart-page-container-inner">
            {initialLoading ? (
              <FullHeightSkeleton skeletonHeight={200} withPadding withTitle />
            ) : !hasAnyItems ? (
              <EmptyCart withButton />
            ) : (
              <CartMain anchor={anchor} cart={cart} expiredCart={expiredCart} />
            )}
          </Grid>
        )}
      </SidePanelsLayout>
    </Grid>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  cart: getCart(state),
  cartLoaded: getCartLoaded(state),
  cartLoadingState: getCartLoadingState(state),
  expiredCart: getExpiredCart(state),
  expiredCartLoaded: getExpiredCartLoaded(state),
  expiredCartLoadingState: getExpiredCartLoadingState(state),
  expiredCartExpanded: getExpiredCartLayoutExpanded(state),
  selectedProduct: getSelectedProduct(state),
  selectedProductLoaded: getSelectedProductLoaded(state),
  selectedProductLoadingState: getSelectedProductLoadingState(state),
  activeCartItem: getCartActiveItem(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      unselectProduct,
      selectProduct: selectProductAsync.request,
      addCartItem: addCartItemAsync.request,
      removeCartItem: removeCartItemAsync.request,
      loadCart: fetchCartAsync.request,
      loadExpiredCart: fetchExpiredCartAsync.request,
      checkoutCart: renewCheckoutAsync.request,
      restoreExpiredCart: restoreExpiredCartAsync.request,
      updateExpiredCartItem: updateExpiredCartItemAsync.request,
      removeExpiredCartItem: removeExpiredCartItemAsync.request,
      catalogRedirect: redirectToCatalog,
      setActiveCartItem: setActiveItem
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(UnconnectedCart as any));
