import { ITheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  common: {
    marginRight: theme.spacing(2),
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    minWidth: 32,
    color: theme.palette.common.white,
    borderRadius: '50%'
  },
  success: {
    backgroundColor: theme.palette.success.main
  },
  info: {
    backgroundColor: theme.palette.info.main
  },
  warning: {
    backgroundColor: theme.palette.warning.main
  },
  error: {
    backgroundColor: theme.palette.error.main
  }
});

export default styles;
