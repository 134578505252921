import { transparentize, rgba } from 'polished';

const brandSecondary = '#4A3230';
const brandPrimary = '#798C49';

const brandColors = {
  brandPrimary, // khaki
  brandPrimaryHover: '#6F823F', // darkerKhaki
  brandPrimaryDark: '#6A7D3A', // darkerThanDarkerKhaki
  brandPrimaryLightedOut: rgba(brandPrimary, 0.2),
  brandPrimaryLightedOutMax: rgba(brandPrimary, 0.1),
  brandSecondary, // brown
  brandSecondaryHover: brandSecondary,

  background: '#fff',
  backgroundSecondary: '#f2f2f2',
  backgroundGray: '#E5E5E5',
  backgroundDarkGray: '#f5f5f5',
  selectedBgLightGreen: '#F2F4ED',

  buttonBorder: transparentize(0.65, brandSecondary),
  buttonBorderHover: transparentize(0.35, brandSecondary),
  buttonLightOut: 'rgba(255, 255, 255, 0.1);',

  red: '#EB5757',
  pink: '#D770AD',
  black: '#333',
  gray: '#555',
  green: '#88A145',
  orange: '#F27C4A',
  lighterGray: '#8C8C8C',
  lightGray: '#DEDEDE',
  placeholder: '#FCCF00',
  borderGray: '#DCDCDC',
  /* Alerts/Info */
  alertInfo: '#009BDD',
  /* Alerts/Attention Amber */
  warning: '#FFB200',
  /* Secondary Black */
  blackSecondary: rgba(0, 0, 0, 0.6),
  helperBlack: 'rgba(0, 0, 0, 0.44)',
  grayedText: transparentize(0.3, brandSecondary)
};

export default brandColors;
