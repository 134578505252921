import React from "react";
import clsx from "clsx";
import { withStyles } from "@mui/styles";
import { Grid } from "@mui/material";

import { User } from "models/user";
import { TextBody1, TextBody2, TextSubTitle } from "components/shared/text";
import Icon from "components/shared/Icon";

import messages from "translations/account/settings";
import messagesReg from "translations/auth/registration";
import styles from "./styles";

interface IProps {
  // component own props
  account: User;
  classes: any;
}

const BusinessData: React.FC<IProps> = ({ classes, account }) => {
  return (
    <Grid xs={12} item>
      <div className={clsx(classes.dataSection, classes.dataSectionDocs)}>
        <Icon
          type={account.profile.filesVerified ? "verificationSuccess" : "verificationProgress"}
          size={24}
          opacity={1}
          offset={8}
        />
        <TextBody2>
          {account.profile.filesVerified
            ? messages.verificationDocsSuccess.defaultMessage
            : messages.verificationInProgress.defaultMessage}
        </TextBody2>
      </div>

      <div className={classes.dataSection}>
        <TextBody1 align={"left"} className={classes.dataSectionLabel}>
          {messages.companyName.defaultMessage}
        </TextBody1>
        <TextSubTitle align={"left"} className={classes.dataSectionValue}>
          {account.profile.businessName}
        </TextSubTitle>
      </div>

      <div className={classes.dataSection}>
        <TextBody1 align={"left"} className={classes.dataSectionLabel}>
          {messagesReg.formFieldEDPROU.defaultMessage}
        </TextBody1>
        <TextSubTitle align={"left"} className={classes.dataSectionValue}>
          {account.profile.EDRPOU}
        </TextSubTitle>
      </div>

      <div className={classes.dataSelection}>
        {account.profile &&
          account.profile.files &&
          account.profile.files.map((file, index) => {
            const url = `${process.env.REACT_APP_API_ENDPOINT}/static/client-uploads/${file}`;
            return (
              <a key={file} href={url} target={"_blank"} rel="noopener noreferrer">
                <div style={{ display: "flex", alignItems: "center", marginTop: index !== 0 ? 10 : 0 }}>
                  <Icon type={"attach"} size={12} color={"#000"} style={{ marginRight: 10 }} />
                  <TextBody1 style={{ color: "#000", fontSize: 14, textDecoration: "underline" }}>
                    Файл {index + 1}
                  </TextBody1>
                </div>
              </a>
            );
          })}
      </div>
    </Grid>
  );
};

export default withStyles<any>(styles)(BusinessData);
