import he from 'he';

import { IStaticPageData, IStaticPageTransformedKeys } from '../models/static-page';

export function decodeHtmlFields<T>(
  record: IStaticPageData | undefined,
  fields?: Array<keyof IStaticPageTransformedKeys>
): T {
  if (typeof record === 'string') {
    return he.decode(record) as unknown as T;
  } else {
    const decodedFields: Partial<IStaticPageData> = {};

    fields?.forEach((field) => {
      if (!record || record[field]) {
        if (record) {
          decodedFields[field] = he.decode(record[field]);
        }
      }
    });

    return { ...record, ...decodedFields } as unknown as T;
  }
}
