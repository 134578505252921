import { ITheme } from "../../utils/styled";
import defaultTheme from "./default";

const darkTheme: ITheme = Object.assign({}, defaultTheme, {
  colors: {
    backgroundSecondary: "#f2f2f2",
    brandSecondary: "#4A3230",
    brandPrimary: "#798C49",
    brandPrimaryHover: "#6F823F",
    background: "#fff",
    buttonBorder: "rgba(74, 50, 48, 0.25)",
    buttonLightOut: "rgba(255, 255, 255, 0.1);",
    red: "#EB5757",
    black: "#333",
    gray: "#555"
  },
  opacities: {
    iconDefault: "1",
    iconHover: "0.85"
  },
  breakpoints: {
    xs: "0px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px"
  },
  widths: {
    md: "720px",
    lg: "960px",
    xl: "1140px"
  },
  heights: {
    header: "60px"
  }
});

export default darkTheme;
