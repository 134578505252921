import { delay } from 'redux-saga';
import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { createAction, createAsyncAction, createReducer, PayloadAction } from 'typesafe-actions';

import { IPanelsLayout } from 'components/layout/side-panels-layout';
import {
  Cart,
  CartGroup,
  CartItem,
  DetailedProduct,
  IMiniCart,
  IRequestCartItem,
  IRequestTransitOffer,
  ITransitOrder,
  Product
} from 'models';
import { baseUrl, cartGroupCheckoutStorageKey } from 'shared/constants';
import {
  ADD_REQUEST_ACTIONS,
  catalogDataWrapper as dataWrapper,
  defaultDataWrapper,
  errorDataWrapper,
  IAsyncDataWrapper,
  loadedDataWrapper,
  loadingDataWrapper,
  refreshDataWrapper,
  REMOVE_REQUEST_ACTIONS,
  REQUEST_ACTIONS
} from 'store/actions';
import { expiredCartSaga, fetchExpiredCartAsync } from 'store/expired-cart/actions';
import messages from 'translations/cart/common';

import { ISelectedProduct, unselectProduct as unselectCatalogProduct } from '../catalog/actions';
import { finalizeTransitAsync } from '../checkout/actions';
import { enqueueSnackbarError } from '../layout';
import { CartRepository } from './request';
import { getTransitModalState } from './selectors';

export const prefix = '@@cart/';

export const CART_RESET = `${prefix}RESET`;

export const CART_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const CART_REQUEST_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const CART_REQUEST_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

export const CART_ADD_ITEM = `${prefix}item/${ADD_REQUEST_ACTIONS.REQUEST}`;
export const CART_ADD_ITEM_SUCCESS = `${prefix}item/${ADD_REQUEST_ACTIONS.SUCCESS}`;
export const CART_ADD_ITEM_FAILURE = `${prefix}item/${ADD_REQUEST_ACTIONS.FAILURE}`;

export const DEBOUNCED_CART_ADD_ITEM = `${prefix}item/DEBOUNCED_${ADD_REQUEST_ACTIONS.REQUEST}`;
export const DEBOUNCED_CART_ADD_ITEM_SUCCESS = `${prefix}item/DEBOUNCED_${ADD_REQUEST_ACTIONS.SUCCESS}`;
export const DEBOUNCED_CART_ADD_ITEM_FAILURE = `${prefix}item/DEBOUNCED_${ADD_REQUEST_ACTIONS.FAILURE}`;

export const CART_ADD_MANY_ITEMS = `${prefix}many-items/${ADD_REQUEST_ACTIONS.REQUEST}`;
export const CART_ADD_MANY_ITEMS_SUCCESS = `${prefix}many-items/${ADD_REQUEST_ACTIONS.SUCCESS}`;
export const CART_ADD_MANY_ITEMS_FAILURE = `${prefix}many-items/${ADD_REQUEST_ACTIONS.FAILURE}`;

export const CART_REMOVE_ITEM = `${prefix}item/${REMOVE_REQUEST_ACTIONS.REQUEST}`;
export const CART_REMOVE_ITEM_SUCCESS = `${prefix}item/${REMOVE_REQUEST_ACTIONS.SUCCESS}`;
export const CART_REMOVE_ITEM_FAILURE = `${prefix}item/${REMOVE_REQUEST_ACTIONS.FAILURE}`;

export const CART_HOLD_TIMER = `${prefix}hold/${REQUEST_ACTIONS.REQUEST}`;
export const CART_HOLD_TIMER_SUCCESS = `${prefix}hold/${REQUEST_ACTIONS.SUCCESS}`;
export const CART_HOLD_TIMER_FAILURE = `${prefix}hold/${REQUEST_ACTIONS.FAILURE}`;

export const CART_UNSELECT_PRODUCT = `${prefix}UNSELECT_PRODUCT`;
export const CART_SELECT_PRODUCT_REQUEST = `${prefix}SELECT_PRODUCT_${REQUEST_ACTIONS.REQUEST}`;
export const CART_SELECT_PRODUCT_SUCCESS = `${prefix}SELECT_PRODUCT_${REQUEST_ACTIONS.SUCCESS}`;
export const CART_SELECT_PRODUCT_FAILURE = `${prefix}SELECT_PRODUCT_${REQUEST_ACTIONS.FAILURE}`;

export const CART_CHECK_TRANSIT_REQUEST = `${prefix}CHECK_TRANSIT_${REQUEST_ACTIONS.REQUEST}`;
export const CART_CHECK_TRANSIT_SUCCESS = `${prefix}CHECK_TRANSIT_${REQUEST_ACTIONS.SUCCESS}`;
export const CART_CHECK_TRANSIT_FAILURE = `${prefix}CHECK_TRANSIT_${REQUEST_ACTIONS.FAILURE}`;
export const CART_CHECK_TRANSIT_SET_CHECKING = `${prefix}CHECK_TRANSIT_SET_CHECKING`;

export const CART_OPEN_MINI = `${prefix}OPEN_MINI`;
export const CART_CLOSE_MINI = `${prefix}CLOSE_MINI`;
export const CART_TOGGLE_MINI = `${prefix}TOGGLE_MINI`;
export const CART_MINI_EDIT_PRODUCT = `${prefix}MINI_EDIT_PRODUCT`;
export const SET_CART_ITEM_QTY = `${prefix}SET_CART_ITEM_QTY`;
export const CART_SET_ACTIVE_ITEM = `${prefix}SET_ACTIVE_ITEM`;
export const CART_LAYOUT_OPEN_EXPIRED = `${prefix}LAYOUT_OPEN_EXPIRED`;
export const CART_LAYOUT_CLOSE_EXPIRED = `${prefix}LAYOUT_CLOSE_EXPIRED`;
export const CART_LAYOUT_TOGGLE_EXPIRED = `${prefix}LAYOUT_TOGGLE_EXPIRED`;
export const CART_LAYOUT_WARNING_SHOWED = `${prefix}LAYOUT_CART_WARNING_SHOWED`;
export const CART_LAYOUT_SET_TAB = `${prefix}LAYOUT_SET_TAB`;
export const CART_LAYOUT_TOGGLE_PRODUCT_INFO = `${prefix}LAYOUT_TOGGLE_PRODUCT_INFO`;

export const CART_EXPIRE = `${prefix}CART_EXPIRE`;
export const CART_SET_TIMER = `${prefix}SET_TIMER`;
export const CART_SET_TRANSIT_TIMER = `${prefix}SET_TRANSIT_TIMER`;
export const CART_REDIRECT_TO_CATALOG = `${prefix}CART_REDIRECT_TO_CATALOG`;
export const CART_SET_TRANSIT_MODAL = `${prefix}SET_TRANSIT_MODAL`;

export interface ICartTimer {
  time: IPlainTime | null;
  shouldResetTimer?: boolean;
}

interface IPlainTime {
  minutes: string;
  seconds: string;
}

export type ICartTime = IPlainTime | Date;

interface IExpiredCartLayout {
  expanded: boolean;
  activeItem: number;
  warningShowed: boolean;
}

interface IOwnLayout {
  mobileTab: number;
}

interface ITransitModal {
  isOpen?: boolean;
  orders?: ITransitOrder[] | null;
  disableActions?: boolean;
  checking: boolean;
}

interface ICart {
  cart: Cart;
  miniCart: IMiniCart;
  timer: ICartTimer;
  transitTimer: ICartTimer;
  layout: IOwnLayout & IPanelsLayout & IExpiredCartLayout;
  selectedProduct: IAsyncDataWrapper<ISelectedProduct>;
  transitModal: ITransitModal;
}

interface IHoldResult {
  expiresOn: string;
  serverTime: string;
}

export type ICartState = IAsyncDataWrapper<ICart>;

export const selectedProductInitialState: IAsyncDataWrapper<ISelectedProduct> = {
  loaded: false,
  loading: false,
  data: null,
  error: null
};

export const cartInitialState: ICartState = {
  loading: false,
  loaded: false,
  data: {
    cart: new Cart({
      items: []
    }),
    timer: {
      time: null,
      shouldResetTimer: false
    },
    transitTimer: {
      time: null,
      shouldResetTimer: false
    },
    miniCart: {
      opened: false,
      editingProduct: '',
      saving: defaultDataWrapper([])
    },
    layout: {
      expanded: false,
      leftOpened: true,
      rightOpened: true,
      activeItem: 0,
      warningShowed: false,
      mobileTab: -1
    },
    selectedProduct: selectedProductInitialState,
    transitModal: {
      orders: null,
      isOpen: false,
      disableActions: false,
      checking: false
    }
  },
  error: null
};

export const setCartTimer = createAction(CART_SET_TIMER)<ICartTime>();
export const setCartTransitTimer = createAction(CART_SET_TRANSIT_TIMER)<ICartTime>();
export const openMiniCart = createAction(CART_OPEN_MINI)<void>();
export const closeMiniCart = createAction(CART_CLOSE_MINI)<void>();
export const toggleMiniCart = createAction(CART_TOGGLE_MINI)<void>();
export const editMiniCartProduct = createAction(CART_MINI_EDIT_PRODUCT)<string>();
export const setCartItemQtyAction = createAction(SET_CART_ITEM_QTY)<IRequestCartItem>();

export const openExpiredCart = createAction(CART_LAYOUT_OPEN_EXPIRED)<void>();
export const closeExpiredCart = createAction(CART_LAYOUT_CLOSE_EXPIRED)<void>();
export const toggleExpiredCart = createAction(CART_LAYOUT_TOGGLE_EXPIRED)<void>();
export const setCartWarningShowed = createAction(CART_LAYOUT_WARNING_SHOWED)<boolean>();
export const setCartMobileTab = createAction(CART_LAYOUT_SET_TAB)<number>();

export const resetCart = createAction(CART_RESET)<void>();
export const redirectToCatalog = createAction(CART_REDIRECT_TO_CATALOG)<void>();
export const setTransitModal = createAction(CART_SET_TRANSIT_MODAL)<Partial<ITransitModal>>();
export const setCheckingForTransit = createAction(CART_CHECK_TRANSIT_SET_CHECKING)<boolean>();

export const unselectProduct = createAction(CART_UNSELECT_PRODUCT)<void>();
export const selectProductAsync = createAsyncAction(
  CART_SELECT_PRODUCT_REQUEST,
  CART_SELECT_PRODUCT_SUCCESS,
  CART_SELECT_PRODUCT_FAILURE
)<Product, DetailedProduct, Error>();

export const toggleProductInfo = createAction(CART_LAYOUT_TOGGLE_PRODUCT_INFO)<boolean>();

export interface ISetActiveItem {
  index: number;
  shouldScroll?: boolean;
}
export const setActiveItem = createAction(CART_SET_ACTIVE_ITEM)<ISetActiveItem>();
export const expireCart = createAction(CART_EXPIRE)<void>();

export const holdCartAsync = createAsyncAction(CART_HOLD_TIMER, CART_HOLD_TIMER_SUCCESS, CART_HOLD_TIMER_FAILURE)<
  { groupType: string; cartGroupId: string },
  void,
  Error
>();

export const fetchCartAsync = createAsyncAction(CART_REQUEST, CART_REQUEST_SUCCESS, CART_REQUEST_FAILURE)<
  void,
  Cart,
  Error
>();

export const addCartItemAsync = createAsyncAction(CART_ADD_ITEM, CART_ADD_ITEM_SUCCESS, CART_ADD_ITEM_FAILURE)<
  IRequestCartItem,
  CartItem,
  Error
>();

export const debouncedAddCartItemAsync = createAsyncAction(
  DEBOUNCED_CART_ADD_ITEM,
  DEBOUNCED_CART_ADD_ITEM_SUCCESS,
  DEBOUNCED_CART_ADD_ITEM_FAILURE
)<IRequestCartItem, CartItem, Error>();

export const checkTransitOfferAsync = createAsyncAction(
  CART_CHECK_TRANSIT_REQUEST,
  CART_CHECK_TRANSIT_SUCCESS,
  CART_CHECK_TRANSIT_FAILURE
)<IRequestTransitOffer, ITransitOrder[] | null, Error>();

export const addManyCartItemsAsync = createAsyncAction(
  CART_ADD_MANY_ITEMS,
  CART_ADD_MANY_ITEMS_SUCCESS,
  CART_ADD_MANY_ITEMS_FAILURE
)<IRequestCartItem[], CartItem[], Error>();

export const removeCartItemAsync = createAsyncAction(
  CART_REMOVE_ITEM,
  CART_REMOVE_ITEM_SUCCESS,
  CART_REMOVE_ITEM_FAILURE
)<IRequestCartItem, void, Error>();

const httpClient = new CartRepository();

// function* timerSaga(action: ReturnType<typeof fetchCartAsync.success>): Generator {
//   let time: any = action.payload ? action.payload.expiresOn : '';
//   const expireDate = new Date(+time);
//   if (!isNaN(+expireDate)) {
//     const nowDate = +new Date();
//     const diff = new Date(+expireDate - nowDate);
//     let minutes = diff.getMinutes().toFixed(0);
//     let seconds = diff.getSeconds().toFixed(0);

//     if (minutes.length === 1) {
//       minutes = `0${minutes}`;
//     }
//     if (seconds.length === 1) {
//       seconds = `0${seconds}`;
//     }

//     time = { minutes, seconds };
//   }
//   yield put(setCartTimer(time));
// }

// function* transitTimerSaga(action: ReturnType<typeof fetchCartAsync.success>): Generator {
//   let time: any = action.payload ? action.payload.expiresOn : '';
//   const expireDate = new Date(+time);
//   if (!isNaN(+expireDate)) {
//     const nowDate = +new Date();
//     const diff = new Date(+expireDate - nowDate);
//     let minutes = diff.getMinutes().toFixed(0);
//     let seconds = diff.getSeconds().toFixed(0);

//     if (minutes.length === 1) {
//       minutes = `0${minutes}`;
//     }
//     if (seconds.length === 1) {
//       seconds = `0${seconds}`;
//     }

//     time = { minutes, seconds };
//   }
//   yield put(setCartTransitTimer(time));
// }

function* cartSelectProductSaga(action: ReturnType<typeof selectProductAsync.request>): Generator {
  yield put(selectProductAsync.success(action.payload));
}

function* cartResetSaga(): Generator {
  yield put(
    enqueueSnackbarError({
      title: messages.notificationCartIsExpiredTitle.defaultMessage,
      message: messages.notificationCartIsExpired.defaultMessage
    })
  );
  // yield put(resetCart());
  yield delay(1000);
  yield call(expiredCartSaga, fetchExpiredCartAsync.request());
  yield call(cartSaga, fetchCartAsync.request());
}

function* unselectCatalogProductSaga(): Generator {
  yield put(unselectCatalogProduct());
}

function* cartCatalogRedirectSaga(): Generator {
  yield put(push(`${baseUrl}/catalog`));
}

export function* successAddedSaga(): Generator {
  yield put(fetchCartAsync.request());
}

export function* cartSaga(action: ReturnType<typeof fetchCartAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.fetch());
    if (response && (response.status === 401 || response.status === 403 || response.status === 503)) {
      window.localStorage.setItem('redirect_after_login', window.location.pathname);
      yield put({ type: '@@auth/LOGOUT' });
      window.location.href = '/login';
    } else {
      yield put(fetchCartAsync.success(response));
    }
  } catch (err) {
    yield put(fetchCartAsync.failure(err as Error));
  }
}

function* cartHoldSaga(action: ReturnType<typeof holdCartAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.hold(action.payload));

    yield put(holdCartAsync.success(response));
  } catch (err) {
    yield put(holdCartAsync.failure(err as Error));
  }
}

function* cartItemAddSaga(action: ReturnType<typeof addCartItemAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.addItem(action.payload));
    const transitModalState: any = yield select(getTransitModalState);
    if (transitModalState) {
      yield put(setTransitModal({ disableActions: false, isOpen: false, orders: null, checking: false }));
    }
    yield put(unselectCatalogProduct());
    yield put(addCartItemAsync.success(response.data));
  } catch (err) {
    yield put(addCartItemAsync.failure(err as Error));
  }
}

function* debouncedCartItemAddSaga(action: ReturnType<typeof debouncedAddCartItemAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.addItem(action.payload));
    yield put(
      debouncedAddCartItemAsync.success({
        ...response.data,
        qty: response.data.reserved,
        offer: action.payload.offer
      })
    );
  } catch (err) {
    yield put(debouncedAddCartItemAsync.failure(err as Error));
  }
}

function* cartManyItemsAddSaga(action: ReturnType<typeof addManyCartItemsAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.addManyItems(action.payload));

    yield put(addManyCartItemsAsync.success(response));
  } catch (err) {
    yield put(addManyCartItemsAsync.failure(err as Error));
  }
}

function* cartItemRemoveSaga(action: ReturnType<typeof removeCartItemAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.removeItem(action.payload));

    yield put(addCartItemAsync.success(response.data));
  } catch (err) {
    yield put(addCartItemAsync.failure(err as Error));
  }
}

function* cartCheckTransitSaga(action: ReturnType<typeof checkTransitOfferAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.checkTransit(action.payload.offerId));

    if (response.handler === 'cart') {
      yield put(addCartItemAsync.request({ offer: action.payload.offerId, qty: action.payload.qty }));
    } else if (response.handler === 'transit') {
      yield put(checkTransitOfferAsync.success(response.orders || []));
    }
    yield put(setCheckingForTransit(false));
  } catch (err) {
    yield put(checkTransitOfferAsync.failure(err as Error));
  }
}

function* finalizeTransitModalSaga(action: ReturnType<typeof finalizeTransitAsync.request>): Generator {
  yield put(setTransitModal({ disableActions: true }));
}

function* successFinalizeTransitModalSaga(
  action: ReturnType<typeof finalizeTransitAsync.success | typeof finalizeTransitAsync.failure>
): Generator {
  yield put(setTransitModal({ disableActions: false, isOpen: false, orders: null }));
}

function* watchAddItem(action: ReturnType<typeof debouncedAddCartItemAsync.request>): Generator {
  try {
    yield delay(500);
    yield fork(debouncedCartItemAddSaga, action);
  } catch (error) {
    console.log('Error on debouncedCartItemAdd', error);
  }
}

export function* cartRequestSaga() {
  yield takeEvery(fetchCartAsync.request, cartSaga);
  // yield takeEvery(fetchCartAsync.success, timerSaga);
  // yield takeEvery(fetchCartAsync.success, transitTimerSaga);

  yield takeEvery(holdCartAsync.request, cartHoldSaga);
  yield takeEvery(addCartItemAsync.request, cartItemAddSaga);
  yield takeLatest(debouncedAddCartItemAsync.request, watchAddItem);
  yield takeEvery(addManyCartItemsAsync.request, cartManyItemsAddSaga);
  yield takeEvery(removeCartItemAsync.request, cartItemRemoveSaga);
  yield takeEvery(checkTransitOfferAsync.request, cartCheckTransitSaga);
  yield takeEvery(finalizeTransitAsync.request, finalizeTransitModalSaga);
  yield takeEvery([finalizeTransitAsync.success, finalizeTransitAsync.failure], successFinalizeTransitModalSaga);
  yield takeEvery(selectProductAsync.request, cartSelectProductSaga);

  // reset cart and fetch expire cart after timer ends
  yield takeEvery(expireCart, cartResetSaga);

  yield takeEvery(addCartItemAsync.success, successAddedSaga);
  yield takeEvery([addCartItemAsync.success, finalizeTransitAsync.success], unselectCatalogProductSaga);
  yield takeEvery(addManyCartItemsAsync.success, successAddedSaga);

  yield takeEvery(redirectToCatalog, cartCatalogRedirectSaga);
}

export default createReducer(cartInitialState)
  // cart layout actions
  .handleAction(CART_SET_TIMER, (state: ICartState, action: PayloadAction<typeof CART_SET_TIMER, ICartTime>) => {
    const newData = {
      ...state.data,
      timer: {
        ...state.data.timer,
        time: action.payload,
        shouldResetTimer: false
      } as ICartTimer
    };
    return loadedDataWrapper(newData, state.error, state.loading, state.loaded);
  })
  .handleAction(
    CART_SET_TRANSIT_TIMER,
    (state: ICartState, action: PayloadAction<typeof CART_SET_TRANSIT_TIMER, ICartTime>) => {
      const newData = {
        ...state.data,
        transitTimer: {
          ...state.data.transitTimer,
          time: action.payload,
          shouldResetTimer: false
        } as ICartTimer
      };
      return loadedDataWrapper(newData, state.error, state.loading, state.loaded);
    }
  )
  .handleAction(CART_OPEN_MINI, (state: ICartState) =>
    loadedDataWrapper({
      ...state.data,
      miniCart: {
        ...state.data.miniCart,
        opened: true
      }
    })
  )
  .handleAction(CART_CLOSE_MINI, (state: ICartState) =>
    loadedDataWrapper({
      ...state.data,
      miniCart: {
        ...state.data.miniCart,
        opened: false
      }
    })
  )
  .handleAction(CART_TOGGLE_MINI, (state: ICartState) => {
    return loadedDataWrapper({
      ...state.data,
      miniCart: {
        ...state.data.miniCart,
        opened: !state.data.miniCart.opened
      }
    });
  })
  .handleAction(
    CART_MINI_EDIT_PRODUCT,
    (state: ICartState, action: PayloadAction<typeof CART_MINI_EDIT_PRODUCT, string>) => {
      return loadedDataWrapper({
        ...state.data,
        miniCart: {
          ...state.data.miniCart,
          editingProduct: action.payload
        }
      });
    }
  )
  .handleAction(CART_LAYOUT_OPEN_EXPIRED, (state: ICartState) =>
    loadedDataWrapper({
      ...state.data,
      layout: {
        ...state.data.layout,
        expanded: true
      }
    })
  )
  .handleAction(CART_LAYOUT_CLOSE_EXPIRED, (state: ICartState) =>
    loadedDataWrapper({
      ...state.data,
      layout: {
        ...state.data.layout,
        expanded: true
      }
    })
  )
  .handleAction(CART_LAYOUT_SET_TAB, (state: ICartState, action: PayloadAction<typeof CART_LAYOUT_SET_TAB, number>) =>
    loadedDataWrapper({
      ...state.data,
      layout: {
        ...state.data.layout,
        mobileTab: action.payload
      }
    })
  )
  .handleAction(
    CART_LAYOUT_WARNING_SHOWED,
    (state: ICartState, action: PayloadAction<typeof CART_LAYOUT_WARNING_SHOWED, boolean | undefined>) =>
      loadedDataWrapper({
        ...state.data,
        layout: {
          ...state.data.layout,
          warningShowed: !!action.payload
        }
      })
  )
  .handleAction(CART_LAYOUT_TOGGLE_EXPIRED, (state: ICartState) => {
    return loadedDataWrapper({
      ...state.data,
      layout: {
        ...state.data.layout,
        expanded: !state.data.layout.expanded
      }
    });
  })

  // cart
  .handleAction(resetCart, () => cartInitialState)
  .handleAction(fetchCartAsync.success, (state: ICartState, action: PayloadAction<typeof CART_REQUEST_SUCCESS, Cart>) =>
    loadedDataWrapper({
      ...state.data,
      layout: {
        ...state.data.layout,
        warningShowed: +state.data.cart.expiresOn > +action.payload.expiresOn
      },
      cart: new Cart(action.payload),
      timer: {
        ...state.data.timer,
        shouldResetTimer: true
      },
      transitTimer: {
        ...state.data.transitTimer,
        shouldResetTimer: true
      }
    })
  )
  .handleAction(fetchCartAsync.request, (state: ICartState) => loadingDataWrapper(state.data))
  // .handleAction(expireCart, (state: ICartState, action: PayloadAction<typeof CART_EXPIRE, Cart>) =>
  //   loadedDataWrapper({
  //     ...state.data,
  //     miniCart: cartInitialState.data.miniCart
  //   })
  // )
  .handleAction(fetchCartAsync.failure, (state: ICartState) =>
    errorDataWrapper(state.data, new Error('Failed to load cart'))
  )

  // add cart item
  .handleAction(
    addCartItemAsync.request,
    (state: ICartState, action: PayloadAction<typeof CART_ADD_ITEM, IRequestCartItem>) =>
      loadedDataWrapper({
        ...state.data,
        cart: state.data.cart.update(action.payload),
        miniCart: {
          ...state.data.miniCart,
          saving: loadingDataWrapper(action.payload)
        }
      })
  )
  .handleAction(
    debouncedAddCartItemAsync.request,
    (state: ICartState, action: PayloadAction<typeof DEBOUNCED_CART_ADD_ITEM, IRequestCartItem>) =>
      loadedDataWrapper({
        ...state.data,
        cart: state.data.cart.update(action.payload),
        miniCart: {
          ...state.data.miniCart,
          saving: loadingDataWrapper(action.payload)
        }
      })
  )
  .handleAction(
    addCartItemAsync.success,
    (state: ICartState, action: PayloadAction<typeof CART_ADD_ITEM_SUCCESS, Cart>) =>
      loadedDataWrapper({
        ...state.data,
        miniCart: {
          ...state.data.miniCart,
          saving: loadedDataWrapper(action.payload)
        }
      })
  )
  .handleAction(
    debouncedAddCartItemAsync.success,
    (state: ICartState, action: PayloadAction<typeof DEBOUNCED_CART_ADD_ITEM_SUCCESS, Cart>) =>
      loadedDataWrapper({
        ...state.data,
        cart: state.data.cart.update(action.payload as any),
        miniCart: {
          ...state.data.miniCart,
          saving: loadedDataWrapper(action.payload)
        }
      })
  )
  .handleAction(addCartItemAsync.failure, (state: ICartState) =>
    errorDataWrapper(state.data, new Error('Failed to add cart item'))
  )
  .handleAction(debouncedAddCartItemAsync.failure, (state: ICartState) =>
    errorDataWrapper(state.data, new Error('Failed to debounced add cart item'))
  )

  // add many cart items
  .handleAction(
    addManyCartItemsAsync.request,
    (state: ICartState, action: PayloadAction<typeof CART_ADD_MANY_ITEMS, CartItem[]>) =>
      loadedDataWrapper({
        ...state.data,
        cart: state.data.cart.updateMany(action.payload),
        miniCart: {
          ...state.data.miniCart,
          saving: loadingDataWrapper(action.payload)
        }
      })
  )
  .handleAction(
    addManyCartItemsAsync.success,
    (state: ICartState, action: PayloadAction<typeof CART_ADD_MANY_ITEMS_SUCCESS, Cart>) =>
      loadedDataWrapper({
        ...state.data,
        miniCart: {
          ...state.data.miniCart,
          editingProduct: '',
          saving: loadedDataWrapper(action.payload)
        }
      })
  )
  .handleAction(
    addManyCartItemsAsync.failure,
    (state: ICartState, action: PayloadAction<typeof CART_ADD_MANY_ITEMS_SUCCESS, Cart>) =>
      loadedDataWrapper({
        ...state.data,
        miniCart: {
          ...state.data.miniCart,
          saving: errorDataWrapper(state.data, new Error('Failed to add cart items'))
        }
      })
  )

  // remove cart item
  .handleAction(
    removeCartItemAsync.request,
    (state: ICartState, action: PayloadAction<typeof CART_REMOVE_ITEM, CartItem>) => {
      const selectedProduct = state.data.selectedProduct && state.data.selectedProduct.data;
      const resetSelectedProduct = selectedProduct && selectedProduct.id === action.payload.product.id;
      return refreshDataWrapper({
        ...state.data,
        cart: state.data.cart.remove(action.payload),
        selectedProduct: resetSelectedProduct ? selectedProductInitialState : state.data.selectedProduct
      });
    }
  )
  .handleAction(
    removeCartItemAsync.success,
    (state: ICartState, action: PayloadAction<typeof CART_REMOVE_ITEM_SUCCESS, Cart>) =>
      loadedDataWrapper({
        ...state.data
      })
  )
  .handleAction(removeCartItemAsync.failure, (state: ICartState) =>
    errorDataWrapper(state.data, new Error('Failed to remove cart item'), false, true)
  )
  .handleAction(
    holdCartAsync.success,
    (state: ICartState, action: PayloadAction<typeof CART_HOLD_TIMER_SUCCESS, IHoldResult>) => {
      const newState = { ...state.data };
      const checkoutGroup = window.localStorage.getItem(cartGroupCheckoutStorageKey);
      const serverTimeDiff = Math.abs(new Date().getTime() - new Date(action.payload.serverTime).getTime());
      const updatedGroups: { [key: string]: CartGroup } = {};
      for (const groupKey in newState.cart.groups) {
        if (checkoutGroup === groupKey) {
          updatedGroups[groupKey] = new CartGroup(
            { ...newState.cart.groups[groupKey], expiresOn: action.payload.expiresOn },
            serverTimeDiff
          );
        } else {
          updatedGroups[groupKey] = newState.cart.groups[groupKey];
        }
      }
      return loadedDataWrapper({
        ...newState,
        cart: new Cart({
          ...newState.cart,
          serverTime: action.payload.serverTime,
          groups: updatedGroups
        })
      });
    }
  )
  .handleAction(
    CART_CHECK_TRANSIT_SET_CHECKING,
    (state: ICartState, action: PayloadAction<typeof CART_CHECK_TRANSIT_SET_CHECKING, boolean>) =>
      loadedDataWrapper({
        ...state.data,
        transitModal: {
          ...state.data.transitModal,
          checking: action.payload
        }
      })
  )
  .handleAction(
    checkTransitOfferAsync.request,
    (state: ICartState, action: PayloadAction<typeof CART_CHECK_TRANSIT_REQUEST, ITransitOrder[]>) =>
      loadedDataWrapper({
        ...state.data,
        transitModal: {
          ...state.data.transitModal,
          checking: true
        }
      })
  )
  .handleAction(
    checkTransitOfferAsync.success,
    (state: ICartState, action: PayloadAction<typeof CART_CHECK_TRANSIT_SUCCESS, ITransitOrder[]>) =>
      loadedDataWrapper({
        ...state.data,
        transitModal: {
          ...state.data.transitModal,
          isOpen: true,
          orders: action.payload
        }
      })
  )
  .handleAction(
    CART_SET_TRANSIT_MODAL,
    (state: ICartState, action: PayloadAction<typeof CART_SET_TRANSIT_MODAL, ITransitModal>) =>
      loadedDataWrapper({
        ...state.data,
        transitModal: {
          ...state.data.transitModal,
          ...action.payload
        }
      })
  )
  .handleAction(
    selectProductAsync.request,
    (state: ICartState, action: PayloadAction<typeof CART_SELECT_PRODUCT_REQUEST, Product>) =>
      loadedDataWrapper({
        ...state.data,
        selectedProduct: loadedDataWrapper(new DetailedProduct(action.payload))
      })
  )
  .handleAction(
    selectProductAsync.success,
    (state: ICartState, action: PayloadAction<typeof CART_SELECT_PRODUCT_SUCCESS, DetailedProduct>) => {
      const stateProduct = state.data.selectedProduct.data;
      const responseProduct = action.payload;
      if (stateProduct && stateProduct.id !== responseProduct.id) {
        return loadedDataWrapper({
          ...state.data,
          selectedProduct: loadedDataWrapper(stateProduct, state.data.selectedProduct.error)
        });
      }
      return loadedDataWrapper({
        ...state.data,
        selectedProduct: loadedDataWrapper(responseProduct)
      });
    }
  )
  .handleAction(CART_UNSELECT_PRODUCT, (state: ICartState) =>
    loadedDataWrapper({
      ...state.data,
      selectedProduct: selectedProductInitialState
    })
  )
  .handleAction(
    CART_LAYOUT_TOGGLE_PRODUCT_INFO,
    (state: ICartState, action: PayloadAction<typeof CART_LAYOUT_TOGGLE_PRODUCT_INFO, boolean>) =>
      dataWrapper({
        ...state.data,
        layout: {
          ...state.data.layout,
          rightOpened: action.payload
        }
      })
  );
