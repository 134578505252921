import { call, put, takeEvery } from 'redux-saga/effects';
import { createAction, createAsyncAction, createReducer, PayloadAction } from 'typesafe-actions';

import { ITransaction, Transaction } from 'models/transaction';
import {
  errorDataWrapper,
  IAsyncDataWrapper,
  loadedDataWrapper,
  loadingDataWrapper,
  REQUEST_ACTIONS
} from 'store/actions';

import { Currency } from '../../models';
import { logout } from '../auth/actions';
import { TransactionsRepository } from './request';

const httpClient = new TransactionsRepository();

export const prefix = '@@transactions/';

export const TRANSACTIONS_RESET = `${prefix}RESET`;
export const TRANSACTIONS_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const TRANSACTIONS_REQUEST_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const TRANSACTIONS_REQUEST_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

interface ITransactionsStateSync {
  transactions: Transaction[];
  currencies: Currency[];
  totalDocs: number;
}

export type ITransactionsState = IAsyncDataWrapper<ITransactionsStateSync>;

export const transactionsInitialState: ITransactionsState = {
  loading: false,
  loaded: false,
  data: {
    transactions: [],
    currencies: [],
    totalDocs: 0
  },
  error: null
};

type TransactionsActionTypes =
  | typeof TRANSACTIONS_REQUEST
  | typeof TRANSACTIONS_REQUEST_SUCCESS
  | typeof TRANSACTIONS_REQUEST_FAILURE;

export const fetchTransactionsAsync = createAsyncAction(
  TRANSACTIONS_REQUEST,
  TRANSACTIONS_REQUEST_SUCCESS,
  TRANSACTIONS_REQUEST_FAILURE
)<string, ITransactionsStateSync, Error>();

export const resetTransactions = createAction(TRANSACTIONS_RESET)();

function* transactionsSaga(action: ReturnType<typeof fetchTransactionsAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.fetch(action.payload));
    const transactions = response.data.docs.map((item: ITransaction) => new Transaction(item));
    const currencies = response.data.currencies.map((item: Currency) => new Currency(item));
    const totalDocs = response.data.totalDocs;

    yield put(fetchTransactionsAsync.success({ transactions, currencies, totalDocs }));
  } catch (err) {
    yield put(fetchTransactionsAsync.failure(err as Error));
  }
}

export function* transactionsRequestSaga() {
  yield takeEvery(fetchTransactionsAsync.request, transactionsSaga);
  yield takeEvery(logout, resetTransactions);
}

export default createReducer(transactionsInitialState)
  .handleAction(fetchTransactionsAsync.request, (state: ITransactionsState) => loadingDataWrapper(state.data))
  .handleAction(
    fetchTransactionsAsync.success,
    (state: ITransactionsState, action: PayloadAction<TransactionsActionTypes, ITransactionsStateSync>) =>
      loadedDataWrapper(
        {
          transactions: action.payload.transactions,
          currencies: action.payload.currencies,
          totalDocs: action.payload.totalDocs
        },
        null,
        false
      )
  )
  .handleAction(fetchTransactionsAsync.failure, (state: ITransactionsState) =>
    errorDataWrapper({ ...state.data }, new Error('Failed to load transactions'))
  );
