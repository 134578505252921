import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "main.cutFlowers.delivery",
    description: "",
    defaultMessage: "Зрізані квіти "
  },
  catalogCount: {
    id: "main.cutFlowers.delivery",
    description: "main.cutFlowers.delivery",
    defaultMessage: `У категорії {count} {count, plural,
        =0 {немає}
        one {# товар}
        =2 {# товарів}
        =3 {# товарів}
        =4 {# товарів}
        other {товарів}
    }`
  },
  toCatalog: {
    id: "main.cutFlowers.toCatalog",
    description: "",
    defaultMessage: "До Каталогу"
  }
});
