import React from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import Icon from "./Icon";

export interface IDropMenuItem {
  icon?: React.ReactNode;
  text?: string;
  onClick?: () => void;
}

interface IOwnProps {
  options: IDropMenuItem[];
  classes?: any;
}

const DropMenu: React.FC<IOwnProps> = ({ options }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Icon type={"send"} size={24} onClick={handleClick} />
      <Menu
        elevation={1}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        PaperProps={{
          style: {
            width: 250
          }
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => option.onClick && option.onClick()} disabled={!option.onClick}>
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
            <ListItemText>{option.text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default DropMenu;
