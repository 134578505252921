import { defineMessages } from "react-intl";

export default defineMessages({
  "8:00 - 20:00": {
    id: "checkout.deliveryTime.allday",
    description: "checkout.deliveryTime.allday",
    defaultMessage: "Весь день"
  },
  "8:00 - 11:00": {
    id: "checkout.deliveryTime.eightEleven",
    description: "checkout.deliveryTime.eightEleven",
    defaultMessage: "8:00 - 11:00"
  },
  "11:00 - 14:00": {
    id: "checkout.deliveryTime.elevenFourteen",
    description: "checkout.deliveryTime.elevenFourteen",
    defaultMessage: "11:00 - 14:00"
  },
  "14:00 - 17:00": {
    id: "checkout.deliveryTime.fourteenSeventeen",
    description: "checkout.deliveryTime.fourteenSeventeen",
    defaultMessage: "14:00 - 17:00"
  },
  "17:00 - 20:00": {
    id: "checkout.deliveryTime.seventeenTwenty",
    description: "checkout.deliveryTime.seventeenTwenty",
    defaultMessage: "17:00 - 20:00"
  },
  other: {
    id: "checkout.deliveryTime.other",
    description: "checkout.deliveryTime.other",
    defaultMessage: "Індивідуальний час"
  }
});
