import { homeMaxWidth } from 'shared/constants';

import ImageHotDeal from '../assets/hot-offer.webp';
import ImageHotDealTablet from '../assets/hot-offer-tablet.webp';
import ImageHotDealMobile from '../assets/hot-offer-mobile.webp';
import { slickDots } from 'styles/mixins';

const styles = (theme: any) => ({
  root: {
    maxWidth: homeMaxWidth,
    margin: '0 auto',
    marginBottom: theme.spacing(21),
    ...slickDots,

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(15)
    }
  },
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)})`,
    height: 370,

    borderRadius: 24,
    background: 'linear-gradient(85.13deg, #A7A7A7 4.05%, #E2E6E9 73.74%, #E6EAED 95.77%)',
    backgroundImage: `url(${ImageHotDealMobile}), linear-gradient(85.13deg, #A7A7A7 4.05%, #E2E6E9 33.74%, #E6EAED 95.77%)`,
    backgroundPosition: 'top right, center',
    backgroundRepeat: 'no-repeat, no-repeat',

    [theme.breakpoints.up('md')]: {
      height: 816,
      backgroundImage: `url(${ImageHotDealTablet}),  linear-gradient(85.13deg, #A7A7A7 4.05%, #E2E6E9 33.74%, #E6EAED 95.77%)`
    },
    [theme.breakpoints.up('lg')]: {
      height: 688,
      backgroundImage: `url(${ImageHotDeal}),  linear-gradient(85.13deg, #A7A7A7 4.05%, #E2E6E9 33.74%, #E6EAED 95.77%)`
    }
  },

  content: {
    maxWidth: '100%'
  },

  title: {
    color: theme.palette.common.black,

    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(5),
      marginRight: theme.spacing(20),
      marginBottom: theme.spacing(5),
      marginLeft: theme.spacing(6)
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
      marginTop: 254
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 32,
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(10),
      textAlign: 'center'
    }
  },

  product: {
    position: 'relative',
    height: 296,
    padding: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),

    background: theme.palette.common.cardBlackBackgroundHex,
    borderRadius: 8,

    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(-2),
      marginRight: 0,
      width: 442,
      height: 385
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
      width: 552
    }
  },
  mainContainer: {
    '&&': {
      paddingLeft: 0
    }
  },
  manufacturerContainer: {
    '&&': {
      paddingTop: 0,
      paddingLeft: 0,

      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
      }
    }
  },
  manufacturerLabel: {
    '&&': {
      color: theme.palette.text.secondary,
      fontSize: 14
    }
  },
  attributesContainer: {
    '&&': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 0
      }
    }
  },
  mainType: {
    '&&': {
      paddingRight: theme.spacing(3),
      marginBottom: theme.spacing(1),

      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
        fontSize: 20
      }
    }
  },
  mainTitle: {
    '&&': {
      marginBottom: 0,
      color: theme.palette.common.black,

      [theme.breakpoints.up('md')]: {
        fontSize: 48,
        marginBottom: theme.spacing(2)
      }
    }
  },
  mainColor: {
    '&&': {
      marginBottom: theme.spacing(1),

      [theme.breakpoints.up('md')]: {
        lineHeight: 1
      }
    }
  },

  params: {
    '&&': {
      marginRight: 0,
      marginLeft: 0,
      paddingLeft: theme.spacing(1)
    }
  },

  discount: {
    position: 'absolute',
    top: theme.spacing(-1),
    right: theme.spacing(-2),

    width: theme.spacing(10),
    height: theme.spacing(10),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: theme.palette.secondary.main,

    '& > span': {
      marginTop: theme.spacing(-1),
      marginBottom: 0
    },

    [theme.breakpoints.up('md')]: {
      top: -17,
      right: -34,

      width: 115,
      height: 115,

      '& > span': {
        fontSize: 32
      }
    }
  },
  paramHeight: {
    '&&&': {
      display: 'none',

      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        marginLeft: theme.spacing(-1),

        color: theme.palette.text.primary
      }
    }
  },

  productBottom: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    borderTop: `1px solid ${theme.palette.common.disabledBlack}`,

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  },
  productBottomPropositions: {
    color: theme.palette.common.black
  },

  timer: {
    position: 'absolute',
    bottom: -40,
    right: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  timerItem: {
    width: 80,
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),

    background: theme.palette.common.white,
    boxShadow: theme.shadow.cardActive.boxShadow,
    borderRadius: 8,

    '&:last-child': {
      marginRight: 0
    },

    [theme.breakpoints.up('md')]: {
      width: 104,
      height: 101
    },
    [theme.breakpoints.up('lg')]: {
      width: 129
    }
  },
  timerValue: {
    width: 64,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      fontSize: 32
    }
  },
  timerLabel: {
    textAlign: 'center'
  }
});

export default styles;
