import { FC, HTMLAttributes, useState } from 'react';
import Chart from 'react-apexcharts';
import { formatMessage } from 'react-intl/lib/formatters/message';
import { connect, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import clsx from 'clsx';
import moment from 'moment';
import { Theme, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import { SplitedPrice } from 'components/prices';
import FlrCard from 'components/shared/card/FlrCard';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import Icon from 'components/shared/Icon';
import { Link2, TextSubTitle } from 'components/shared/text';
import { User } from 'models';
import DataCardSkeleton from 'pages/account/finance/CardSkeleton';
import { getDonutChartOptions } from 'pages/account/loyalty/cards/chart-configs';
import { fetchAccountAsync } from 'store/account/actions';
import { getAccountLoadingState, getUserAccount } from 'store/account/selectors';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/account/finance';
import { formattedStringPrice } from 'utils/helpers';

import { ColorCircle } from '../../shared/product/ColorCircle';
import ChangeCreditLineModal from './ChangeCreditLineModal';

import classes from './CardCreditLine.module.scss';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  classNameWrapper?: string;
  withCircle?: boolean;
  withoutChart?: boolean;
  isFinance?: boolean;
}

interface IStateProps {
  account: User | null;
  accountLoadingState: boolean;
}

interface IDispatchProps {
  loadUser: typeof fetchAccountAsync.request;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

const CardCreditLine: FC<IComponentProps> = ({
  account,
  classNameWrapper,
  accountLoadingState,
  loadUser,
  withCircle,
  withoutChart,
  isFinance
}) => {
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalOpen, setModalOpen] = useState(false);

  const orderCredit = (account && account.profile.orderCredit) || 0;
  const availableFunds = (account && account.profile.availableFunds) || 0;
  const { credit = 0, tmpCredit = 0, tmpCreditExpireOn = null } = (account && account.profile) || {};
  const creditCurrent = credit + tmpCredit;

  const creditUsed = credit + tmpCredit + orderCredit - availableFunds;

  const simplifiedMessages = {
    creditLineUsed: messages.creditLineUsed.defaultMessage,
    creditLineAvailable: messages.creditLineAvailable.defaultMessage
  };

  const intlConfig = {
    locale: 'uk-UA',
    formats: {},
    messages: simplifiedMessages,
    defaultLocale: 'uk-UA',
    defaultFormats: {},
    onError: (err: any) => console.error(err)
  };

  const state: any = {};

  const chartOptions = {
    ...getDonutChartOptions('', formattedStringPrice(creditCurrent), classes.chartContainer),
    labels: [
      `${formatMessage(intlConfig, state, messages.creditLineUsed, { value: formattedStringPrice(creditUsed) })}`,
      `${formatMessage(intlConfig, state, messages.creditLineAvailable, {
        value: formattedStringPrice(availableFunds)
      })}`
    ]
  };

  chartOptions.colors[0] = '#c82361';

  const handleCLose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <FlrCard className={classNameWrapper} hoverShadow>
        <FlrCardTitle isUnderline className={classes.cardTitle}>
          <TextSubTitle align={'left'} className={classes.title}>
            <span className={classes.creditLineLabel}>{messages.creditLine.defaultMessage}</span>
            <Icon type={'history'} size={24} leftOffset={14} />
          </TextSubTitle>
        </FlrCardTitle>
        {!accountLoadingState && account ? (
          <div className={classes.container}>
            <Icon type={'creditCard'} size={32} />
            <div className={classes.wrapper}>
              <div className={classes.content}>
                <div className={classes.creditContainer}>
                  <SplitedPrice
                    fontSize={isMobile ? 'h2' : 'h1'}
                    fontSizeDecimal={isMobile ? 'h4' : 'h3'}
                    fontColor="black"
                    value={credit}
                    priceDisplayStyle={{ fontWeight: 700, letterSpacing: '1px' }}
                  />
                  {tmpCredit ? (
                    <div className={classes.tmpCreditWrapper}>
                      <SplitedPrice
                        value={tmpCredit + orderCredit}
                        prefix="+"
                        negative
                        fontSize={'subtitle1'}
                        fontSizeDecimal={'caption'}
                      />
                      {tmpCreditExpireOn ? <Link2>Погасити до {moment(tmpCreditExpireOn).calendar()}</Link2> : null}
                    </div>
                  ) : null}
                </div>
                <div className={clsx(classes.creditLineContainer, { [classes.creditLineFinance]: isFinance })}>
                  <div className={clsx(classes.creditLineWrapper, { [classes.creditLineWrapperFinance]: isFinance })}>
                    {withCircle ? <ColorCircle color="Темно сірий" /> : null}
                    <div className={classes.creditLineContent}>
                      <span className={classes.label}>{messages.creditLineAvailable.defaultMessage}</span>
                      <SplitedPrice
                        fontSize={'h3'}
                        fontSizeDecimal={isMobile ? 'subtitle2' : 'h3'}
                        value={availableFunds}
                        className={classes.creditLineValue}
                      />
                    </div>
                  </div>
                  <div className={classes.creditLineWrapper}>
                    {withCircle ? <ColorCircle color="Малина" /> : null}
                    <div className={classes.creditLineContent}>
                      <span className={classes.label}>{messages.creditLineUsed.defaultMessage}</span>
                      <SplitedPrice
                        fontSize={'h3'}
                        fontSizeDecimal={isMobile ? 'subtitle2' : 'h3'}
                        value={creditUsed}
                        className={classes.creditLineValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {!withoutChart ? (
                <div className={classes.chartContainer}>
                  <Chart
                    width={120}
                    height={120}
                    options={chartOptions}
                    series={[creditUsed, availableFunds]}
                    type={'donut'}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <DataCardSkeleton />
        )}
      </FlrCard>

      <ChangeCreditLineModal
        creditCurrent={creditCurrent}
        loadUser={loadUser}
        isOpen={modalOpen}
        onClose={handleCLose}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  account: getUserAccount(state),
  accountLoadingState: getAccountLoadingState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      loadUser: fetchAccountAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(CardCreditLine);
