import { FC, useState } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import CartGroupCard from 'components/cards/cart/CartGroupCard';
import CartGroupExpiredCardLabel from 'components/cards/cart/CartGroupExpiredCardLabel';
import { ExpandPanel } from 'components/shared';
import PaymentModal from 'components/shared/modals/payment/PaymentModal';
import { GTM } from 'controllers';
import { Cart, CartItem, UserProfile } from 'models';
import { fetchAccountAsync } from 'store/account/actions';
import { getUserProfile } from 'store/account/selectors';
import {
  debouncedAddCartItemAsync,
  removeCartItemAsync,
  toggleExpiredCart
} from 'store/cart/actions';
import { getExpiredCartLayoutExpanded } from 'store/cart/selectors';
import { renewCheckoutAsync } from 'store/checkout/actions';
import {
  removeExpiredCartAsync,
  removeExpiredCartGroupAsync,
  removeExpiredCartItemAsync,
  restoreExpiredCartAsync,
  updateExpiredCartItemAsync
} from 'store/expired-cart/actions';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/cart/common';
import messagesExpired from 'translations/cart/expired';
import CartBlockHeader from './CartBlockHeader';
import classes from './CartMain.module.scss';

interface IProps {
  anchor: string;
  cart: Cart;
  expiredCart: Cart;
}

interface IStateProps {
  userProfile: UserProfile | undefined;
  expiredCartExpanded: boolean;
}

interface IDispatchProps {
  checkoutCart: typeof renewCheckoutAsync.request;
  restoreExpiredCart: typeof restoreExpiredCartAsync.request;
  addCartItem: typeof debouncedAddCartItemAsync.request;
  removeCartItem: typeof removeCartItemAsync.request;
  updateExpiredCartItem: typeof updateExpiredCartItemAsync.request;
  removeExpiredCart: typeof removeExpiredCartAsync.request;
  removeExpiredCartItem: typeof removeExpiredCartItemAsync.request;
  removeExpiredCartGroup: typeof removeExpiredCartGroupAsync.request;
  toggleExpiredExpanded: typeof toggleExpiredCart;
  loadAccountData: typeof fetchAccountAsync.request;
}

type CartProps = IProps & IStateProps & IDispatchProps;

const CartMain: FC<CartProps> = ({
  anchor,
  userProfile,
  cart,
  expiredCart,
  expiredCartExpanded,
  checkoutCart,
  restoreExpiredCart,
  removeExpiredCart,
  removeExpiredCartGroup,
  removeExpiredCartItem,
  toggleExpiredExpanded,
  addCartItem,
  removeCartItem,
  updateExpiredCartItem,
  loadAccountData
}) => {
  const [modalPayOpen, setModalPayOpen] = useState(false);

  const handlePayCancel = () => {
    setModalPayOpen(false);
  };
  const handleOpenPayModal = () => {
    setModalPayOpen(true);
  };

  const handleExpiredCartRemove = () => {
    GTM.trackRemoveExpiredItemsFromCard(expiredCart);
    removeExpiredCart();
  };

  const onSuccess = () => {
    if (loadAccountData) {
      loadAccountData();
    }
  };

  const updateCartItemQty = (item: CartItem) => {
    addCartItem(item.toRequestItem());
  };

  const updateExpiredCartItemQty = (item: CartItem) => {
    updateExpiredCartItem(item.toRequestItem());
  };

  const availableFunds = (userProfile && userProfile.availableFunds) || 0;
  const totalCartPrice = Object.keys(cart.groups).reduce((acc, key) => acc + cart.groups[key].total, 0);

  return (
    <>
      {expiredCart && expiredCart.items.length > 0 && (
        <>
          <CartBlockHeader itemsNumber={expiredCart.items.length} title={messagesExpired.expiredCart} expired />
          <ExpandPanel
            leftIcon
            expanded={expiredCartExpanded}
            onChange={() => toggleExpiredExpanded()}
            label={
              <CartGroupExpiredCardLabel
                itemsNumber={expiredCart.items.length}
                onRemoveItems={handleExpiredCartRemove}
                isExpanded={expiredCartExpanded}
              />
            }
            className={classes.sectionSpacing}
          >
            {Object.keys(expiredCart.groups).map((groupName: string) => (
              <CartGroupCard
                expired
                anchor={anchor}
                key={`expired-cart-group-card-${groupName}`}
                cartGroupName={groupName}
                cartGroup={expiredCart.groups[groupName]}
                onRemove={() => removeExpiredCartGroup(groupName)}
                onSubmit={() => restoreExpiredCart(groupName)}
                onRemoveItem={(item: CartItem) => removeExpiredCartItem(item.toRequestItem())}
                onQtyUpdate={updateExpiredCartItemQty}
              />
            ))}
          </ExpandPanel>
        </>
      )}
      {cart && cart.regularGroups.length > 0 && (
        <>
          <CartBlockHeader
            timerType="inStock"
            itemsNumber={cart.regularGroups.length}
            title={messages.ordersWithAmount}
          />
          {cart.regularGroups.map((groupName: string) => (
            <CartGroupCard
              addBalance={handleOpenPayModal}
              balance={availableFunds}
              anchor={anchor}
              key={`cart-group-card-${groupName}`}
              cartGroupName={groupName}
              cartGroup={cart.groups[groupName]}
              onSubmit={() => checkoutCart(groupName)}
              onRemoveItem={(item: CartItem) => removeCartItem(item.toRequestItem())}
              onQtyUpdate={updateCartItemQty}
            />
          ))}
        </>
      )}
      {cart && cart.inTransitGroups.length > 0 && (
        <>
          <CartBlockHeader
            timerType="transit"
            itemsNumber={cart.inTransitGroups.length}
            title={messages.inTransitWithAmount}
          />
          {cart.inTransitGroups.map((groupName: string) => (
            <CartGroupCard
              addBalance={handleOpenPayModal}
              balance={availableFunds}
              anchor={anchor}
              key={`cart-group-card-${groupName}`}
              cartGroupName={groupName}
              cartGroup={cart.groups[groupName]}
              onSubmit={() => checkoutCart(groupName)}
              onRemoveItem={(item: CartItem) => removeCartItem(item.toRequestItem())}
              onQtyUpdate={(item: CartItem) => addCartItem(item.toRequestItem())}
            />
          ))}
        </>
      )}
      {cart && cart.preOrderGroups.length > 0 && (
        <>
          <CartBlockHeader preOrder itemsNumber={cart.preOrderGroups.length} title={messages.preOrdersWithAmount} />
          {cart.preOrderGroups.map((groupName: string) => (
            <CartGroupCard
              preOrder
              addBalance={handleOpenPayModal}
              balance={availableFunds}
              anchor={anchor}
              key={`cart-group-card-${groupName}`}
              cartGroupName={groupName}
              cartGroup={cart.groups[groupName]}
              onSubmit={() => checkoutCart(groupName)}
              onRemoveItem={(item: CartItem) => removeCartItem(item.toRequestItem())}
              onQtyUpdate={(item: CartItem) => addCartItem(item.toRequestItem())}
            />
          ))}
        </>
      )}
      {modalPayOpen && (
        <PaymentModal open={modalPayOpen} handleClose={handlePayCancel} amount={totalCartPrice} onSuccess={onSuccess} />
      )}
    </>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  userProfile: getUserProfile(state),
  expiredCartExpanded: getExpiredCartLayoutExpanded(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      addCartItem: debouncedAddCartItemAsync.request,
      removeCartItem: removeCartItemAsync.request,
      checkoutCart: renewCheckoutAsync.request,
      restoreExpiredCart: restoreExpiredCartAsync.request,
      updateExpiredCartItem: updateExpiredCartItemAsync.request,
      removeExpiredCartItem: removeExpiredCartItemAsync.request,
      removeExpiredCartGroup: removeExpiredCartGroupAsync.request,
      removeExpiredCart: removeExpiredCartAsync.request,
      toggleExpiredExpanded: toggleExpiredCart,
      loadAccountData: fetchAccountAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(CartMain);
