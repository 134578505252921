import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Paper } from '@mui/material';

import OrderComment from 'components/cards/order/OrderComment';
import { SplitedPrice } from 'components/prices';
import { GridDivider } from 'components/shared/table/dividers';
import { TextBody1, TextBody2, TitleH1 } from 'components/shared/text';
import { Order } from 'models';
import messages from 'translations/checkout/common';

import { getCheckoutActiveOrder } from '../../../store/checkout/selectors';
import CheckoutRightSideItem from './CheckoutRightSideItem';

import classes from './CheckoutRightSide.module.scss';

interface IProps {
  orders: Order[];
  finalizedOrders: Order[];
  onCancel: () => void;
  onSubmitAll: (payload: string) => void;
  anchor: string;
  submitting: boolean;
  anchorOffset?: number;
  preOrder?: boolean;
  displayedGroup?: string;
}

const CheckoutRightSide: FC<IProps> = ({
  orders,
  preOrder,
  finalizedOrders,
  onCancel,
  onSubmitAll,
  submitting,
  anchor,
  anchorOffset,
  displayedGroup
}) => {
  const [activeGroup, setActiveGroup] = useState('');
  if (displayedGroup && activeGroup !== displayedGroup) {
    setActiveGroup(displayedGroup);
  }
  const selectedOrder = useSelector(getCheckoutActiveOrder);
  const order = orders[selectedOrder.index - 1];
  const totalQty = orders.reduce((t, o: Order) => t + o.totalQty, 0);
  const totalSum = orders.reduce((t, o: Order) => t + o.totalSum, 0);
  const indexShift = finalizedOrders.length + 1;

  const handleSubmitAll = () => {
    if (orders && orders.length) {
      onSubmitAll(orders[0].orderId);
    }
  };
  return (
    <div className={classes.container}>
      <TitleH1 align={'center'} className={classes.title}>
        Розділені доставки
      </TitleH1>
      <Paper elevation={0} className={classes.checkoutOrdersSummaryCard}>
        <div className={classes.wrapper}>
          <div className={classes.labels}>
            <TextBody2 className={classes.label}>Доставка</TextBody2>
            <TextBody2 className={classes.label}>Сума</TextBody2>
          </div>
          <div className={classes.content}>
            {finalizedOrders.map((o: Order, index: number) => (
              <CheckoutRightSideItem
                key={`rb-${o.orderId}`}
                allOrdersCount={orders.length + finalizedOrders.length}
                order={o}
                anchor={anchor}
                anchorOffset={anchorOffset}
                index={index + 1}
                groupKey={o.id}
                activeGroup={activeGroup}
                setActiveGroup={setActiveGroup}
              />
            ))}
            {orders.map((o: Order, index: number) => (
              <CheckoutRightSideItem
                key={`rb-${o.orderId}`}
                allOrdersCount={orders.length + finalizedOrders.length}
                order={o}
                anchor={anchor}
                anchorOffset={anchorOffset}
                index={index + indexShift}
                groupKey={o.id}
                activeGroup={activeGroup}
                setActiveGroup={setActiveGroup}
              />
            ))}
            <GridDivider />
            <div className={classes.footer}>
              <TextBody2>Сума до сплати</TextBody2>
              <div className={classes.totalInfo}>
                <SplitedPrice value={totalQty} bold={false} fontSize={'body2'} hideDecimal={true} postfix={'шт'} />
                <SplitedPrice value={totalSum} fontSize={'h3'} fontSizeDecimal={'h5'} />
              </div>
            </div>
            <GridDivider />
          </div>
          {order && (
            <div className={classes.commentWrapper}>
              <TextBody1 className={classes.commentTitle}>Коментар до замовлення</TextBody1>
              <OrderComment index={selectedOrder.index - 1} />
            </div>
          )}
          <div className={classes.actions}>
            <Button color={'primary'} onClick={onCancel} className={classes.cancelBtn}>
              {messages.cancelBtnLabel.defaultMessage}
            </Button>
            <Button
              disabled={submitting}
              color={'primary'}
              variant={'contained'}
              onClick={handleSubmitAll}
              className={classes.submitBtn}
            >
              {preOrder ? messages.submitPreOrderAllBtnLabel.defaultMessage : messages.submitAllBtnLabel.defaultMessage}
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default CheckoutRightSide;
