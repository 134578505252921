import { FC } from 'react';
import clsx from 'clsx';

import { colorsMap } from 'shared/colorsMap';

import classes from './ColorCircle.module.scss';

interface IColorCircleProps {
  color: string;
  className?: string;
}

const biColor = (
  <>
    <defs>
      <linearGradient id="bicolor" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" style={{ stopColor: '#52ACFF', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: '#FFE32C', stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <circle cx="12" cy="12" r="11.9" fill="url(#bicolor)" stroke="black" stroke-width="0.2" />
  </>
);

const mixColor = (
  <>
    <defs>
      <linearGradient id="mixcolor" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="29%" style={{ stopColor: 'rgb(250, 139, 255)', stopOpacity: 1 }} />
        <stop offset="79%" style={{ stopColor: 'rgb(43, 210, 255)', stopOpacity: 1 }} />
        <stop offset="105%" style={{ stopColor: 'rgb(43, 255, 136)', stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <circle cx="12" cy="12" r="11.9" fill="url(#mixcolor)" stroke="black" stroke-width="0.2" />
  </>
);

const paintedColor = (
  <>
    <defs>
      <linearGradient id="paintedcolor" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: '#00C9FF', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: '#92FE9D', stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <circle cx="12" cy="12" r="11.9" fill="url(#paintedcolor)" stroke="black" stroke-width="0.2" />
  </>
);

export const ColorCircle: FC<IColorCircleProps> = ({ color, className }) => {
  function getCircle() {
    switch (color) {
      case 'Біколор':
        return biColor;
      case 'Мікс':
        return mixColor;
      case 'Фарбований':
        return paintedColor;
      case undefined:
        return (
          <>
            <circle cx="12" cy="12" r="11.9" fill="white" stroke="black" strokeWidth="0.2" />
            <line x1="2.83" y1="21.17" x2="21.17" y2="2.83" stroke="black" stroke-width="0.2" />
          </>
        );
      default:
        return <circle cx="12" cy="12" r="11.9" fill={colorsMap[color]} stroke="black" strokeWidth="0.2" />;
    }
  }

  return (
    <div className={clsx(classes.container, className)}>
      <svg viewBox="0 0 24 24">{getCircle()}</svg>
    </div>
  );
};
