import { FC } from 'react';
import clsx from 'clsx';
import { isMobileDevice } from 'environment';

import { SplitedPrice } from 'components/prices';
import { GridDivider } from 'components/shared/table/dividers';
import { TextBody1, TextBody2, TextSubTitle } from 'components/shared/text';
import { Order, ORDER_TYPE_PREORDER } from 'models';
import messages from 'translations/checkout/common';
import { methodLabel } from 'utils/helpers';

import OrderComment from './OrderComment';

import classes from './OrderCardSubtotal.module.scss';

interface IOrderCardSubtotalProps {
  order: Order;
  withDiscount?: boolean;
  withComment?: boolean;
  index?: number;
  withCommentEdit?: boolean;
  isAccount?: boolean;
}

const isMobile = isMobileDevice(window.navigator);

const Comment: FC<{
  withComment: boolean;
  withCommentEdit?: boolean;
  index?: number;
  orderNotes?: string;
}> = ({ withComment, withCommentEdit, index, orderNotes }) => {
  if (!withComment) { return null; }

  if (withCommentEdit && index !== undefined) {
    return <OrderComment index={index - 1} className={classes.comment} />;
  }

  if (orderNotes) {
    return (
      <div className={classes.commentContainer}>
        <TextBody1 className={classes.commentTitle}>Коментар до замовлення</TextBody1>
        <TextSubTitle>{orderNotes}</TextSubTitle>
      </div>
    );
  }

  return null;
};

const OrderCardSubtotal: FC<IOrderCardSubtotalProps> = ({
  order,
  withDiscount,
  withComment = true,
  withCommentEdit,
  index,
  isAccount
}) => {
  const isPreOrder = order.orderType === ORDER_TYPE_PREORDER;

  return (
    <>
      {!isMobile ? <GridDivider /> : null}
      <div className={clsx([classes.tableItem, classes.wrapper])}>
        <span className={clsx(classes.sumItemsLabel, {[classes.accountSumItemsLabel]: isAccount})}>Сума товарів</span>
        <span className={clsx(classes.quantityCountLabel, {[classes.accountQuantityCountLabel]: isAccount})}>{order.totalQty}</span>
        <span className={clsx(classes.removeItem, {[classes.accountRemoveItem]: isAccount})} />
        <span className={clsx(classes.inPackageCountLabel, {[classes.accountInPackageCountLabel]: isAccount})}>{order.totalPackQty}</span>
        <div className={classes.discountPrice}>
          {withDiscount ? (
            <SplitedPrice
              value={order.totalDiscount}
              fontSize={'h5'}
              fontSizeDecimal={'h6'}
              className={classes.discountValue}
            />
          ) : null}
        </div>
        <SplitedPrice
          value={order.totalItemsSumWithDiscount()}
          fontSize={'h5'}
          fontSizeDecimal={'h6'}
          bold={false}
          className={classes.orderItemSubtotal}
        />
      </div>
      <div className={classes.mobContainer}>
        <div className={classes.mobileWrapper}>
          <span className={classes.summaryLabel}>{messages.summary.defaultMessage}</span>
          <div className={classes.qtyContainer}>
            <span className={classes.label}>{messages.qty.defaultMessage}</span>
            <span className={classes.totalQtyValue}>{order.totalQty} шт</span>
          </div>
          <div className={classes.discountContent}>
            <span className={classes.label}>{messages.sum.defaultMessage}</span>
            <SplitedPrice
              value={order.totalItemsSumWithDiscount()}
              prefix={isPreOrder ? '~' : undefined}
              fontSize="body2"
              className={classes.discount}
            />
          </div>
        </div>
      </div>
      <GridDivider />
      {/* TODO hide for now */}
      {/* <Grid item container spacing={1} className={clsx(classes.tableItem, classes.disabled)} alignItems={"center"}>
        <Grid item container alignItems={"center"}>
          <Grid item xs>
            <TextSubTitle component={"span"} color={"textPrimary"}>
              {packageIdLabel(order.packageId)}
            </TextSubTitle>
          </Grid>
          <Grid item xs={"auto"}>
            <SplitedPrice value={order.packingPrice()} {...mobilePricesCommonProps} />
          </Grid>
        </Grid>
        {order.packageReturn && (
          <Grid item container>
            <Grid item xs>
              <TextBody2 color={"textSecondary"}>{messages.packageReturnSelected.defaultMessage}</TextBody2>
            </Grid>
            <Grid item xs={"auto"} className={classes.fundsPositive}>
              <SplitedPrice value={-order.packingReturnPrice()} {...mobilePricesCommonProps} />
            </Grid>
          </Grid>
        )}
      </Grid>
      <GridDivider /> */}

      <div className={classes.deliveryContainer}>
        <TextSubTitle style={{ margin: 0 }} className={classes.deliveryLabel}>
          Доставка {methodLabel(order.deliveryMethod && order.deliveryMethod)}
        </TextSubTitle>
        <SplitedPrice value={order.deliveryPrice()} fontSize="h3" className={classes.deliveryPrice} />
      </div>
      {!isMobile ? <GridDivider /> : null}
      {isMobile ? (
        <Comment withComment={withComment} withCommentEdit={withCommentEdit} index={index} orderNotes={order.notes} />
      ) : null}
      <div className={classes.totalSumWrapper}>
        <TextBody2 color={'textSecondary'} className={classes.totalSumLabel}>
          {messages.totalSum.defaultMessage}
        </TextBody2>
        <SplitedPrice
          value={order.totalSumAll()}
          prefix={isPreOrder ? '~' : undefined}
          fontSize={isMobile ? 'h2' : 'h1'}
          fontSizeDecimal={isMobile ? 'subtitle2' : 'h2'}
          className={classes.sum}
        />
      </div>
      {!isMobile ? (
        <Comment withComment={withComment} withCommentEdit={withCommentEdit} index={index} orderNotes={order.notes} />
      ) : null}
    </>
  );
};

export default OrderCardSubtotal;
