import * as React from 'react';
import styled from '@emotion/styled/macro';
import { CatalogHeaderTop, ICatalogHeaderTop } from '../header/HeaderTop';

const Header: React.FC<ICatalogHeaderTop> = props => {
  const { className, ...rest } = props;
  return (
    <ComponentWrapper className={className}>
      <CatalogHeaderTop {...rest} />
    </ComponentWrapper>
  );
};

const ComponentWrapper = styled<any>('header')(() => ({
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
  '& > div': {
    display: 'flex'
  }
}));

export default Header;
