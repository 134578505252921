import * as React from 'react';
import styled from '@emotion/styled/macro';
import { NavLink } from 'react-router-dom';
import { Button, Grid } from '@mui/material';

import { IThemed } from 'utils/styled';
import Icon from './shared/Icon';
import { TitleH1, TextBody1 } from './shared/text';
import cartMessages from 'translations/cart/common';
import { baseUrl } from 'shared/constants';

export const EmptyCartWrapper = styled<any>(Grid)(({ theme }: IThemed) => ({
  fontFamily: theme.fonts.headings,
  fontSize: 22,
  lineHeight: '33px',
  fontWeight: 500,
  padding: '68px 24px 0 24px',
  maxWidth: 600,
  margin: '0 auto'
}));

const CenteredIcon = styled<any>(Icon)(() => ({
  display: 'block',
  margin: '0 auto'
}));

interface IProps {
  withButton?: boolean;
}

const EmptyCart: React.FC<IProps> = ({ withButton }) => {
  return (
    <EmptyCartWrapper>
      <CenteredIcon type="emptyCart" size={160} />

      <TitleH1 align={'center'}>{cartMessages.emptyCart.defaultMessage}</TitleH1>
      <TextBody1 align={'center'}>
        {cartMessages.emptyCartCaption.defaultMessage}
        {withButton && (
          <NavLink to={`${baseUrl}/catalog`} style={{ display: 'block' }}>
            <Button variant={'contained'} color={'primary'} style={{ marginTop: 20 }}>
              {cartMessages.backToCatalog.defaultMessage}
            </Button>
          </NavLink>
        )}
      </TextBody1>
    </EmptyCartWrapper>
  );
};

export default EmptyCart;
