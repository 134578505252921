import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { defaultMaterialTheme, ITheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    minHeight: 64,
    '&.closable': {
      paddingRight: defaultMaterialTheme.spacing(9)
    },
    paddingRight: defaultMaterialTheme.spacing(3),
    marginTop: defaultMaterialTheme.spacing(1),
    marginBottom: defaultMaterialTheme.spacing(1),

    flex: 1,

    background: theme.colors.background,
    border: '1px solid rgba(0, 0, 0, 0.02)',

    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.16)',
    borderRadius: 4,

    '&::before': {
      content: "''",
      position: 'absolute',
      top: -1,
      left: -1,
      width: 4,
      height: 'calc(100% + 2px)',
      borderRadius: '4px 0px 0px 4px'
    }
  },
  leftShift: {
    width: 'calc(100% + 4px)',
    position: 'relative',
    left: -4,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  alertInfo: {
    '&::before': {
      background: theme.palette.info.main
    }
  },
  alertError: {
    '&::before': {
      background: theme.palette.error.main
    }
  },
  alertWarning: {
    '&::before': {
      background: theme.palette.warning.main
    }
  },
  alertSuccess: {
    '&::before': {
      background: theme.palette.success.main
    }
  },
  iconRoot: {
    position: 'absolute',
    left: defaultMaterialTheme.spacing(3),
    top: `calc(50% - ${defaultMaterialTheme.spacing(2)})`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    borderRadius: '50%',
    color: theme.palette.common.white
  },
  iconRootInfo: {
    background: theme.palette.info.main
  },
  iconRootError: {
    background: theme.palette.error.main,
    color: theme.palette.common.white
  },
  iconRootWarning: {
    background: theme.palette.warning.main
  },
  iconRootSuccess: {
    background: theme.palette.success.main
  },
  alertIcon: {
    position: 'absolute',
    left: defaultMaterialTheme.spacing(3),
    top: 0,
    bottom: 0
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1
  },
  content: {
    paddingTop: defaultMaterialTheme.spacing(2),
    paddingBottom: defaultMaterialTheme.spacing(2),
    paddingLeft: defaultMaterialTheme.spacing(9)
  },
  contentTitle: {
    marginBottom: 5,
    fontWeight: 700
  },
  contentText: {
    marginBottom: 5,
    color: theme.colors.blackSecondary,

    '& a': {
      letterSpacing: '0.03em',
      textDecoration: 'underline'
    }
  },
  alertAction: {
    minWidth: 56,
    marginLeft: defaultMaterialTheme.spacing(1),
    right: defaultMaterialTheme.spacing(9)
  },
  closeButton: {
    position: 'absolute',
    right: defaultMaterialTheme.spacing(3),
    top: 'calc(50% - 18px)'
  }
});

export const useBalanceStyles = makeStyles((theme: ITheme) =>
  createStyles({
    outlineMargin: {
      position: 'relative',
      paddingTop: '14px !important',
      paddingBottom: '14px !important',
      '&:last-child': {
        marginBottom: 40
      },
      marginLeft: -7,
      '&>.selectedItemCard': {
        width: '100%',
        padding: 8
      },
      '&.alert': {
        marginTop: theme.spacing(-2.5),
        marginBottom: theme.spacing(2.5)
      },
      '&.withoutMarginTop': {
        marginTop: 0
      }
    }
  })
);

export default styles;
