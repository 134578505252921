import { defineMessages } from "react-intl";

export default defineMessages({
  delivery: {
    id: "main.features.delivery",
    description: "",
    defaultMessage: "Доставка"
  },
  deliveryDesc: {
    id: "main.features.deliveryDesc",
    description: "",
    defaultMessage: "день в день"
  },
  discounts: {
    id: "main.features.discounts",
    description: "",
    defaultMessage: "Знижки"
  },
  discountsDesc: {
    id: "main.features.discountsDesc",
    description: "",
    defaultMessage: "за участь в програмі лояльності"
  },
  support: {
    id: "main.features.delivery",
    description: "",
    defaultMessage: "Онлайн супровід"
  },
  supportDesc: {
    id: "main.features.supportDesc",
    description: "",
    defaultMessage: "на всіх етапах"
  },
  priceOffers: {
    id: "main.features.priceOffers",
    description: "",
    defaultMessage: "Цінові пропозиції"
  },
  priceOffersDesc: {
    id: "main.features.priceOffersDesc",
    description: "",
    defaultMessage: "фіксовані та нефіксовані"
  },
  onlineBidding: {
    id: "main.features.onlineBidding",
    description: "",
    defaultMessage: "Онлайн торги"
  },
  onlineBiddingDesc: {
    id: "main.features.onlineBiddingDesc",
    description: "",
    defaultMessage: " щодня"
  }
});
