import React, { CSSProperties } from "react";
import clsx from "clsx";
import { Paper } from "@mui/material";
import { withStyles } from "@mui/styles";

import { ITheme } from "utils/styled";

interface IProps {
  id?: string;
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  classes: any;
  color?: "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  hoverShadow?: boolean;
  zeroPadding?: boolean;
  elevation?: number;
}

const FlrCardNarrow: React.FC<IProps> = ({ children, hoverShadow, zeroPadding, classes, className, ...rest }) => {
  return (
    <Paper
      classes={{
        root: clsx(classes.root, className, {
          [classes.rootShadow]: hoverShadow,
          [classes.rootZeroPadding]: zeroPadding
        })
      }}
      {...rest}
    >
      {children}
    </Paper>
  );
};

const styles = (theme: ITheme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRadius: theme.spacing(1),
    ...theme.shadow.cardActive,
    [(theme as any).breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  rootShadow: {
    "&:hover": {
      ...theme.shadow.cardHover
    }
  },
  rootZeroPadding: {
    padding: 0
  }
});

export default withStyles<any>(styles)(FlrCardNarrow);
