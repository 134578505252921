import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTrackInitialPageLoad } from 'controllers';
import { IStaticPageData } from 'models/static-page';
import { fetchStaticPagesAsync } from 'store/static-page/actions';
import { getDeliveryData } from 'store/static-page/selectors';

import { decodeHtmlFields } from '../../../utils/transforms';

const StaticDeliveryHtml = () => {
  useTrackInitialPageLoad();
  const dispatch = useDispatch();
  const staticPageData = useSelector(getDeliveryData);

  useEffect(() => {
    dispatch(fetchStaticPagesAsync.request('delivery'));
    // eslint-disable-next-line
  }, []);

  const decodedRecord = decodeHtmlFields<IStaticPageData>(staticPageData ? staticPageData : undefined, ['content']);
  let deliveryData = '';
  try {
    const jsonArray = JSON.parse(decodedRecord?.content || '[]');
    if (jsonArray && jsonArray[0]) {
      deliveryData = jsonArray[0].content;
    }
  } catch (error) {
    console.log('Error parsing JSON:', error);
  }
  return (
    <div className="c10" style={{ maxWidth: 1024 }}>
      <h3 className="c11" style={{ marginBottom: 20 }}>
        {staticPageData?.title}
      </h3>
      <div dangerouslySetInnerHTML={{ __html: deliveryData }} />
    </div>
  );
};

export default StaticDeliveryHtml;
