import { FC } from 'react';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { TextBody2 } from 'components/shared/text';
import { Icon } from 'components/shared';
import styles from './styles';
import messages from 'translations/cart/common';

interface IProps {
  itemsNumber: number;
  classes: any;
}

const CartGroupCardItemsHeaders: FC<IProps> = ({ itemsNumber, classes }) => {
  return (
    <div className={classes.tableHeaders}>
      <div className={classes.productNameHeader}>
        <TextBody2 className={classes.label}>Товар ({itemsNumber})</TextBody2>
      </div>
      <div className={classes.priceHeader}>
        <TextBody2 align={'right'} className={classes.label}>
          {messages.pricePerItem.defaultMessage}
        </TextBody2>
      </div>
      <div className={classes.quantityHeader}>
        <TextBody2 align={'center'} className={classes.label}>
          {messages.qtyInItems.defaultMessage}
        </TextBody2>
      </div>
      <div className={classes.inPackageCountHeader}>
        <TextBody2 align={'right'} className={classes.label}>
          {messages.inPackageCount.defaultMessage}
        </TextBody2>
      </div>
      <div className={classes.discountHeader}>
        <TextBody2 align={'right'} className={classes.label}>
          {messages.totalDiscount.defaultMessage}
        </TextBody2>
      </div>
      <div className={classes.orderItemSubtotalHeader}>
        <TextBody2 align={'center'} className={classes.label} >
          {messages.totalSum.defaultMessage}
        </TextBody2>
      </div>
      <div>
        <IconButton style={{ visibility: 'hidden', height: 0 }} size="large">
          <Icon type={'trash'} size={20} />
        </IconButton>
      </div>
    </div>
  );
};

export default withStyles<any>(styles)(CartGroupCardItemsHeaders);
