import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, TextField, FormHelperText } from '@mui/material';
import { fetchForgotPasswordStep2Async, IForgotPasswordObject } from 'store/auth/actions';
import { TitleH1 } from 'components/shared/text';
import FlrLoader from 'components/loading/LoadingSpinner';
import AuthPageWrapper from '../shared/AuthPageWrapper';
import messagesControls from 'translations/layout/controls';
import messages from 'translations/auth/forgotPassword';

interface IFormData {
  resetKey: string;
}

interface IProps {
  // component own props
  data: IForgotPasswordObject | null;
  classes?: any;
  token: string | null | undefined;
  isError: boolean;
  isLoading: boolean;
  fetchForgotPassword2: typeof fetchForgotPasswordStep2Async.request;
}

const CodeScreen: React.FC<IProps> = ({ classes, token, data, isError, isLoading, fetchForgotPassword2 }) => {
  const { handleSubmit, errors, control } = useForm<IFormData>();

  const onSubmit = handleSubmit(({ resetKey }): any => {
    if (isLoading) {
      return;
    }

    fetchForgotPassword2({ resetKey, token, authId: data && data.authId });
  });

  return (
    <AuthPageWrapper classes={classes}>
      <TitleH1 style={{ fontSize: 28 }}>{messages.headerCode.defaultMessage}</TitleH1>

      <form onSubmit={onSubmit}>
        <Controller
          disabled={isLoading}
          error={Boolean(errors.resetKey || isError)}
          helperText={
            (errors.resetKey && errors.resetKey.message) || isError ? messages.formFieldCodeError.defaultMessage : ''
          }
          rules={{
            required: messagesControls.requiredFiled.defaultMessage
          }}
          as={TextField}
          name="resetKey"
          control={control}
          defaultValue=""
          className={classes.loginControl}
          fullWidth
          variant={'outlined'}
          label={messages.formFieldCode.defaultMessage}
        />
        <FormHelperText>{data!._sentData.resetKey}</FormHelperText>

        <Button color={'primary'} variant={'contained'} type={'submit'}>
          {messages.formRestorePasswordButton.defaultMessage}
        </Button>
      </form>

      {isLoading && <FlrLoader withOverlay={true} />}
    </AuthPageWrapper>
  );
};

export default CodeScreen;
