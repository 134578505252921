import React, { ChangeEvent, ReactElement, ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { generatePath, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Hidden } from '@mui/material';
import { withStyles } from '@mui/styles';

import { IToggleablePanel } from 'components/layout/side-panels-layout';
import { IPreferencesData } from 'models';
import { IFilterOptions, IFilterOptionsRequest } from 'models/filter-options';
import { baseUrl, catalogProductTypeCeramic, catalogProductTypeCodes } from 'shared/constants';
import { updatePreferencesAccountAsync } from 'store/account/actions';
import { getUserPreferences } from 'store/account/selectors';
import {
  fetchCatalogAsync,
  filterAndSortCatalog,
  ICatalogRequest,
  IFilterProps,
  IFiltersData,
  resetFilter,
  selectProductAsync
} from 'store/catalog/actions';
import {
  getCatalogLoadingState,
  getCatalogPostFilteredFilters,
  getCatalogSort,
  getCatalogSortedState,
  getSelectedProductData
} from 'store/catalog/selectors';
import { fetchFilterOptionsAsync } from 'store/filter-options/actions';
import {
  getFilterOptionsData,
  getFilterOptionsLoadedState,
  getFilterOptionsLoadingState
} from 'store/filter-options/selectors';
import { IApplicationState } from 'store/reducers';
import { filtersMessages } from 'translations/catalog/filters';
import { filterJsonToString, filtersToString } from 'utils/filters';

import CatalogCategory from './options/CatalogCategory';
import ColorOptions from './options/ColorOptions';
import CountryOptions from './options/CountryOptions';
import { DeliveryFilters } from './options/DeliveryFilters';
import DiameterOptions from './options/DiameterOptions';
import ExtraFilters from './options/ExtraFilters';
import HeightRangeOptions from './options/HeightRangeOptions';
import { ManufacturerOptions } from './options/ManufacturerOptions';
import PriceRangeOptions from './options/PriceRangeOptions';
import { ProductTypeOptions } from './options/ProductTypeOptions';
import ShapeOptions from './options/ShapeOptions';
import { StatusFilters } from './options/StatusFilters';
import Skeleton from './Skeleton';
import styles, { FilterSectionWrapper, FiltersWrapper } from './styles';

export interface ISetFiltersParam {
  inStock?: undefined | boolean;
  preOrder?: boolean;
  discount?: boolean;
  specialOffers?: boolean;
  transitOffers?: boolean;
  specialTransitOffers?: boolean;
  supplier?: string;
  productType?: string;
  deliveryRegion?: string;
}
export type FilterChangeHandler<T> = (path: any, value: T) => void;

interface IFilterElement {
  label: MessageDescriptor;
  content: ReactNode | ReactNode[];
  condHide?: boolean;
}

interface IProps extends IToggleablePanel, RouteComponentProps {
  classes: any;
  productType: string;
  filters: IFiltersData;
  totalProducts: any;
  loadFilterOptions: typeof fetchFilterOptionsAsync.request;
  onClose: () => null;
  loggedIn: boolean;
  disableFilters: boolean;
}

interface IStateProps {
  sort: string;
  locale: string;
  catalogLoadingState: boolean;
  catalogSorted: boolean;
  filterOptionsLoading: boolean;
  filterOptionsData: IFilterOptions;
  postFilteredFilterOptions: Map<any, any>;
  loaded: boolean;
  selectedProduct: any;
  accountPreferences: IPreferencesData | undefined;
}

interface IDispatchProps {
  filterAndSortCatalog: typeof filterAndSortCatalog;
  loadCatalog: typeof fetchCatalogAsync.request;
  resetFilterAction: typeof resetFilter;
  selectProduct: typeof selectProductAsync.request;
  updateAccount: typeof updatePreferencesAccountAsync.request;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

export const FILTERS_LIST_MAX = 10; // items showed in collapsed mode

export class SideFilterPanel extends React.Component<IComponentProps> {
  constructor(props: IComponentProps) {
    super(props);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  public updateCatalog = (updatedParams: Partial<ICatalogRequest>) => {
    const { loadCatalog: update, filters, catalogLoadingState } = this.props;
    if (catalogLoadingState) {
      return;
    }
    const params = Object.assign({}, filters, updatedParams);
    const inStock = typeof updatedParams.inStock !== 'undefined' ? updatedParams.inStock : params.inStock;
    const supplier = typeof updatedParams.supplier !== 'undefined' ? updatedParams.supplier : params.supplier;
    const deliveryRegion =
      typeof updatedParams.deliveryRegion !== 'undefined' ? updatedParams.deliveryRegion : params.deliveryRegion;
    const preOrder = typeof updatedParams.preOrder !== 'undefined' ? updatedParams.preOrder : params.preOrder;
    const discount = typeof updatedParams.discount !== 'undefined' ? updatedParams.discount : params.discount;
    const specialOffers =
      typeof updatedParams.specialOffers !== 'undefined' ? updatedParams.specialOffers : params.specialOffers;
    const transitOffers =
      typeof updatedParams.transitOffers !== 'undefined' ? updatedParams.transitOffers : params.transitOffers;
    const specialTransitOffers =
      typeof updatedParams.specialTransitOffers !== 'undefined'
        ? updatedParams.specialTransitOffers
        : params.specialTransitOffers;

    update({ ...params, inStock });

    this.setFilters(params.fast, {
      productType: params.productType,
      inStock,
      supplier,
      preOrder,
      discount,
      specialOffers,
      transitOffers,
      specialTransitOffers,
      deliveryRegion
    });
  };

  public handleFilterChange = (type: string) => (event: any, checked?: boolean) => {
    const { filters, loadFilterOptions, selectProduct, selectedProduct, accountPreferences, updateAccount } =
      this.props;
    switch (type) {
      case 'supplier':
        if (filters.supplier === event.target.value) {
          return;
        }
        if (accountPreferences && event.target.data && event.target.data.id) {
          updateAccount({
            ...accountPreferences,
            // * to prevent race condition of accountPreferences update we should add deliveryRegion
            // * only if it's not set in accountPreferences yet
            // * supplier change goes after deliveryRegion change
            ...(filters.deliveryRegion && !accountPreferences.deliveryRegion
              ? { deliveryRegion: filters.deliveryRegion }
              : {}),
            supplier: event.target.data.id
          });
        }
        loadFilterOptions({ ...filters, [type]: event.target.value });
        this.updateCatalog({ ...filters, [type]: event.target.value });

        if (selectedProduct && selectedProduct.id) {
          selectProduct({ ...selectedProduct, supplier: event.target.value });
        }
        break;
      case 'inStock':
      case 'preOrder':
      case 'specialOffers':
      case 'transitOffers':
      case 'specialTransitOffers':
        const updatedFilters = {
          ...filters,
          inStock: false,
          preOrder: false,
          specialOffers: false,
          transitOffers: false,
          specialTransitOffers: false,
          [type]: checked
        };
        this.updateCatalog(updatedFilters);
        loadFilterOptions(updatedFilters);
        break;
      default:
        if ((filters as any)[type] !== checked) {
          this.updateCatalog({ ...filters, [type]: checked });
          loadFilterOptions({ ...filters, [type]: checked });
        }
        break;
    }
  };

  public handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { filterOptionsData, loadFilterOptions, filters } = this.props;
    const { inStock, preOrder, specialOffers, transitOffers, specialTransitOffers, deliveryRegion, supplier } = filters;
    const newCode = event.target.value;
    const newProductType = filterOptionsData.type.find((type) => type.code === newCode);

    const selectedFilters = {
      inStock,
      preOrder,
      specialOffers,
      transitOffers,
      specialTransitOffers,
      deliveryRegion,
      supplier
    };

    if (newProductType && newProductType.alias) {
      this.updateCatalog({
        productType: newProductType.alias,
        ...selectedFilters
      });
      this.setFilters([], { productType: newProductType.alias, ...selectedFilters });
      loadFilterOptions({ productType: newProductType.alias, ...selectedFilters, fast: [] } as IFilterOptionsRequest);
    }
  };

  public handleRegionChange = (newRegionCode: string) => {
    if (newRegionCode) {
      this.setFilters([], {
        ...this.props.filters,
        deliveryRegion: newRegionCode
      });
      // * to prevent race condition of accountPreferences update we should update the deliveryRegion
      // * only if supplier is already set
      // * deliveryRegion change goes before supplier change
      if (this.props.accountPreferences?.supplier) {
        this.props.updateAccount({
          ...this.props.accountPreferences,
          deliveryRegion: newRegionCode
        });
      }
    }
  };

  public resetFilters = () => {
    const { filters } = this.props;

    const { fast, ...restFilters } = filters;
    this.props.resetFilterAction();
    this.setFilters([], restFilters);
  };

  public render() {
    const {
      filterOptionsLoading,
      filterOptionsData,
      filters,
      classes,
      locale,
      loggedIn,
      catalogSorted,
      postFilteredFilterOptions
    } = this.props;

    const { productType } = filters;

    const types = filterOptionsData.type;
    const productTypeCode = types.find((type) => type.alias === productType);

    const optionsProps: {
      filters: IFiltersData;
      setFilters: (newFilters: IFilterProps) => void;
      filterOptionsData: IFilterOptions;
      classes: any;
      postFilteredFilterOptions: Map<any, any>;
    } = {
      filters,
      setFilters: this.setFilters,
      filterOptionsData,
      postFilteredFilterOptions,
      classes
    };

    const filtersExists = filters.fast && !!filters.fast.length;

    const deliveryFilter = {
      label: filtersMessages.deliveryFilter,
      content: (
        <DeliveryFilters
          filters={filters}
          regions={filterOptionsData.regions}
          suppliers={filterOptionsData.suppliers}
          // TODO remove accountPref after refactor catalog (filters should be set right after account request finished)
          selectedSupplier={filters.supplier || this.props.accountPreferences?.supplier}
          selectedDeliveryRegion={filters.deliveryRegion || this.props.accountPreferences?.deliveryRegion}
          showDeliveryModal={
            (!this.props.accountPreferences?.supplier || !this.props.accountPreferences?.deliveryRegion) &&
            !this.props.accountPreferences?.doNotShowDeliveryModal
          }
          isLoading={(!productTypeCode && !this.props.loaded) || !catalogSorted}
          isDisabled={!catalogSorted}
          changeRegion={this.handleRegionChange}
          changeSupplier={this.handleFilterChange('supplier')}
          label={filtersMessages.deliveryFilter.defaultMessage}
          classes={classes}
        />
      )
    };

    const statusFilter = {
      label: filtersMessages.productsStatus,
      content: (
        <StatusFilters
          {...optionsProps}
          handleFilterChange={this.handleFilterChange}
          label={filtersMessages.productsStatus.defaultMessage}
          classes={classes}
        />
      )
    };

    const productTypeFilter = {
      label: filtersMessages.productType,
      content: (
        <ProductTypeOptions
          options={types.map((typeOption) => ({
            label: `${typeOption.name} (${typeOption.qty || 0})`,
            value: typeOption.code,
            isDisabled: !typeOption.qty
          }))}
          classes={classes}
          productType={productTypeCode}
          handleTypeChange={this.handleTypeChange}
          label={filtersMessages.productType.defaultMessage}
          disabled={!catalogSorted}
        />
      )
    };

    const categoryFilter = {
      label: filtersMessages.subCategory,
      content: <CatalogCategory {...optionsProps} locale={locale} label={filtersMessages.subCategory.defaultMessage} />
    };

    const countryFilter = {
      label: filtersMessages.countryFilter,
      content: (
        <CountryOptions {...optionsProps} label={filtersMessages.countryFilter.defaultMessage} classes={classes} />
      )
    };

    const manufacturerFilter = {
      label: filtersMessages.countryFilter,
      content: (
        <ManufacturerOptions
          {...optionsProps}
          label={filtersMessages.manufacturerFilter.defaultMessage}
          classes={classes}
        />
      )
    };

    const colorFilter = {
      label: filtersMessages.colorFilter,
      content: <ColorOptions {...optionsProps} label={filtersMessages.colorFilter.defaultMessage} classes={classes} />
    };

    const diameterFilter = {
      label: filtersMessages.colorFilter,
      content: (
        <DiameterOptions {...optionsProps} label={filtersMessages.diameterFilter.defaultMessage} classes={classes} />
      )
    };

    const shapeFilter = {
      label: filtersMessages.shapeFilter,
      content: <ShapeOptions {...optionsProps} label={filtersMessages.shapeFilter.defaultMessage} classes={classes} />
    };

    const priceFilter = {
      label: filtersMessages.priceRange,
      content: (
        <PriceRangeOptions {...optionsProps} label={filtersMessages.priceRange.defaultMessage} classes={classes} />
      )
    };

    const heightFilter = {
      label: filtersMessages.heightFilter,
      content: (
        <HeightRangeOptions {...optionsProps} label={filtersMessages.heightFilter.defaultMessage} classes={classes} />
      )
    };

    const extra = {
      label: filtersMessages.extraFilters,
      content: (
        <ExtraFilters
          {...optionsProps}
          handleFilterChange={this.handleFilterChange}
          label={filtersMessages.extraFilters.defaultMessage}
          classes={classes}
          loggedIn={loggedIn}
        />
      )
    };

    const sortedFiltersByProductType = (code: string) => {
      switch (code) {
        case catalogProductTypeCodes[catalogProductTypeCeramic]:
          return [
            statusFilter,
            productTypeFilter,
            categoryFilter,
            countryFilter,
            diameterFilter,
            heightFilter,
            colorFilter,
            manufacturerFilter,
            shapeFilter,
            priceFilter,
            extra
          ];
        default:
          return [
            statusFilter,
            productTypeFilter,
            categoryFilter,
            countryFilter,
            colorFilter,
            manufacturerFilter,
            priceFilter,
            heightFilter,
            extra
          ];
      }
    };

    const filterElements: IFilterElement[] = sortedFiltersByProductType(
      (productTypeCode && productTypeCode.code) || ''
    );

    const isLoading = filterOptionsLoading || !filters;

    return (
      <FilterSectionWrapper className="asideInner">
        {isLoading ? (
          <Skeleton />
        ) : (
          <React.Fragment>
            <FiltersWrapper className={classes.FilterWrapper}>
              {deliveryFilter.content}
              <ValidatorForm onSubmit={() => null}>
                {filterElements.map((element, key) =>
                  !element.condHide ? (
                    <React.Fragment key={key}>{element.content as ReactElement}</React.Fragment>
                  ) : null
                )}
              </ValidatorForm>
            </FiltersWrapper>
            {filtersExists && (
              <Hidden lgDown>
                <Button
                  className={classes.ResetButton}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={this.resetFilters}
                >
                  {filtersMessages.resetFilters.defaultMessage}
                </Button>
              </Hidden>
            )}
          </React.Fragment>
        )}
      </FilterSectionWrapper>
    );
  }

  private setFilters = (newFilters: IFilterProps, preselectedFilters?: ISetFiltersParam) => {
    const { sort, locale, filters, history } = this.props;
    const {
      inStock,
      productType,
      preOrder,
      supplier,
      discount,
      specialOffers,
      transitOffers,
      specialTransitOffers,
      deliveryRegion
    } = preselectedFilters || filters;

    const filterMap = filtersToString(newFilters);
    let str = filterJsonToString(filterMap);

    if (inStock) {
      str = `inStock=${+inStock};${str}`;
    }

    str = `${preOrder ? 'preOrder=1;' : ''}${discount ? 'discount=1;' : ''}${
      supplier ? 'supplier=' + supplier + ';' : ''
    }${deliveryRegion ? 'deliveryRegion=' + deliveryRegion + ';' : ''}${specialOffers ? 'specialOffers=1;' : ''}${
      transitOffers ? 'transitOffers=1;' : ''
    }${specialTransitOffers ? 'specialTransitOffers=1;' : ''}${str}`;

    const url = generatePath(`${baseUrl}/catalog/:productType/:filterUrl?`, {
      productType: productType || filters.productType,
      filterUrl: str || undefined
    });
    history.push(url);

    this.props.filterAndSortCatalog({
      sort,
      locale,
      fast: newFilters,
      inStock: !!inStock,
      preOrder,
      supplier,
      deliveryRegion,
      discount
    });
  };
}

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  locale: state.locale,
  sort: getCatalogSort(state),
  filterOptionsData: getFilterOptionsData(state),
  filterOptionsLoading: getFilterOptionsLoadingState(state),
  catalogLoadingState: getCatalogLoadingState(state),
  catalogSorted: getCatalogSortedState(state),
  loaded: getFilterOptionsLoadedState(state),
  selectedProduct: getSelectedProductData(state),
  accountPreferences: getUserPreferences(state),
  postFilteredFilterOptions: getCatalogPostFilteredFilters(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      filterAndSortCatalog,
      resetFilterAction: resetFilter,
      loadCatalog: fetchCatalogAsync.request,
      selectProduct: selectProductAsync.request,
      updateAccount: updatePreferencesAccountAsync.request
    },
    dispatch
  )
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(SideFilterPanel as any))
);
