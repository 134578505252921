import { createContext } from 'react';

import { IBreadCrumbLink } from 'components/layout/breadcrumbs/Breadcrumbs';

export interface IBreadCrumbsContext {
  breadCrumbs: IBreadCrumbLink[];
  setCurrentUrl: (array: IBreadCrumbLink[]) => void;
}

export const URL_DEFAULT_VALUE = {
  breadCrumbs: [],
  setCurrentUrl: () => []
};

export const breadCrumbsContext = createContext<IBreadCrumbsContext>(URL_DEFAULT_VALUE);
