import Breadcrumbs from '../../../components/layout/breadcrumbs/Breadcrumbs';
import classes from './CheckoutBreadcrumbs.module.scss';

const CheckoutBreadcrumbs = () => {
  return (
    <Breadcrumbs
      className={classes.checkoutBreadcrumbs}
      links={[
        {
          path: '/catalog/shear-flowers',
          label: 'Каталог'
        },
        {
          path: '/cart',
          label: 'Кошик'
        },
        {
          label: 'Оформлення замовлення'
        }
      ]}
    />
  );
};

export default CheckoutBreadcrumbs;