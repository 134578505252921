export class Currency {
  public code: string;
  public name: string;
  public id: string;
  constructor(currency: any) {
    this.code = currency.code;
    this.name = currency.name;
    this.id = currency.id;
  }
}
