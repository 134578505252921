import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconButton } from '@mui/material';

import { SplitedPrice } from 'components/prices';
import { Icon } from 'components/shared';
import { TextBody2 } from 'components/shared/text';
import { CartItem } from 'models';
import messages from 'translations/cart/expired';
import { getProductImage } from 'utils/helpers';

import { ColorCircle } from '../../../shared/product/ColorCircle';

import classes from './CartGroupCardItemLabel.module.scss';

interface IProps {
  selected?: boolean;
  removeDisabled?: boolean;
  expired?: boolean;
  item: CartItem;
  onRemove?: () => void;
  onSelect: () => void;
  onUnselect: () => void;
}

const CartGroupCardItemLabelDevice: React.FC<IProps> = ({
  selected,
  expired,
  removeDisabled,
  item,
  onRemove,
  onSelect,
  onUnselect
}) => {
  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onRemove) {
      onRemove();
    }
    e.stopPropagation();
  };

  const handleSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (selected && onUnselect) {
      return onUnselect();
    }
    if (onSelect) {
      onSelect();
    }
  };

  const isArchived = !item.offer || item.product.archived;
  const priceDiff = item.originalPrice ? Math.round((item.originalPrice - item.price) * 100) / 100 : 0;
  const stockDeficit = item.originalRequest.qty - item.offer.inStock;
  const isOutOfStock = item.offer && item.offer.inStock < 1;
  const isPreOrder = item.offer.preOrder;
  const hasAdditionalInfo =
    expired && (isArchived || priceDiff !== 0 || stockDeficit > 0 || isOutOfStock || isPreOrder);

  return (
    <div onClick={handleSelect} className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.photoContainer}>
          <img src={getProductImage(item.product)} alt={item.product.displayName} className={classes.productImage} />
        </div>
        <div className={classes.productContainer}>
          <span className={classes.productName}>{item.product.displayName}</span>
          <div className={classes.productInfo}>
            <SplitedPrice fontColor={'inherit'} value={item.qty} fontSize={'body2'} hideDecimal postfix={'шт'} />
            <div className={classes.attributesContainer}>
              <ColorCircle color={item.product.attributes.color?.value} className={classes.colorCircle} />
              {item.product.attributesList.slice(0, 3).map((attr) => (
                <div key={attr.code} className={classes.attributeContainer}>
                  <Icon size={12} type={attr.alias} leftOffset={4} offset={2} opacity={1} />
                  <p className={classes.attrValue}>{attr.value}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.trashIconContainer}>
          <IconButton onClick={(e) => !removeDisabled && handleRemove(e)} size="large" className={classes.trashIcon}>
            <Icon type={'trash'} size={20} opacity={1} />
          </IconButton>
        </div>
      </div>
      {hasAdditionalInfo && (
        <div className={classes.productInfoCell}>
          {priceDiff !== 0 && (
            <div className={classes.productInfo}>
              <TextBody2 className={priceDiff > 0 ? classes.green : classes.red}>
                <FormattedMessage
                  tagName={React.Fragment}
                  values={{
                    amount: Math.abs(priceDiff),
                    price: (...chunks: any[]) => {
                      const price = parseFloat(chunks.pop());
                      return (
                        <SplitedPrice
                          key={`cart-item-price-change-${item.offer.id}`}
                          value={price}
                          bold={false}
                          decimalSameSize={true}
                          fontSize={'body2'}
                        />
                      );
                    }
                  }}
                  {...messages[priceDiff > 0 ? 'priceDecreased' : 'priceIncreased']}
                />
              </TextBody2>
            </div>
          )}
          {stockDeficit > 0 && (
            <TextBody2 className={classes.red}>
              <FormattedMessage {...messages.qtyDecreased} values={{ amount: stockDeficit }} />
            </TextBody2>
          )}
          {isOutOfStock && <TextBody2 color="error">{messages.outOfStock.defaultMessage}</TextBody2>}
          {isOutOfStock && isPreOrder && (
            <div className={classes.isOutOfStockContainer}>
              <Icon type={'box'} size={24} className={classes.black} />
              <TextBody2>{messages.preOrderAvailable.defaultMessage}</TextBody2>
            </div>
          )}
          {isArchived && <TextBody2 color="error">{messages.notAvailable.defaultMessage}</TextBody2>}
        </div>
      )}
    </div>
  );
};

export default CartGroupCardItemLabelDevice;
