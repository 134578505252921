// import { ITheme } from "utils/styled";

import { homeMaxWidth } from 'shared/constants';

const styles = (theme: any) => ({
  root: {
    maxWidth: homeMaxWidth,
    margin: '0 auto'
  },

  featuresContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    marginBottom: theme.spacing(9),

    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(-2)
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(18)
    }
  },
  featuresItem: {
    width: 162,
    minWidth: 162,
    textAlign: 'center',

    [theme.breakpoints.up('lg')]: {
      width: 245,
      minWidth: 245
    },
    [theme.breakpoints.down('md')]: {
      '&:nth-child(3)': {
        width: '100%'
      }
    }
  },
  featuresItemMiddleMobile: {},
  featureIcon: {
    color: theme.palette.text.primary
  },
  featuresItemTitle: {
    color: theme.palette.common.black,

    [theme.breakpoints.up('lg')]: {
      fontSize: 20
    }
  },
  featuresItemDesc: {
    color: theme.palette.common.black,

    [theme.breakpoints.up('lg')]: {
      fontSize: 16
    }
  }
});

export default styles;
