import { defineMessages } from "react-intl";

export default defineMessages({
  titleShareFlowers: {
    id: "main.categories.titleShareFlowers",
    description: "main.categories.titleShareFlowers",
    defaultMessage: "Зрізані квіти "
  },
  titleIndoor: {
    id: "main.categories.titleIndoor",
    description: "main.categories.titleIndoor",
    defaultMessage: "Кімнатні рослини "
  },
  titleStabilized: {
    id: "main.categories.titleStabilized",
    description: "main.categories.titleStabilized",
    defaultMessage: "Стабілізовані квіти"
  },
  titleAccessories: {
    id: "main.categories.titleAccessories",
    description: "main.categories.titleAccessories",
    defaultMessage: "Фурнітура "
  },
  catalogCount: {
    id: "main.categories.catalogCount",
    description: "main.categories.catalogCount",
    defaultMessage: `У категорії {count} {count, plural,
        =0 {немає}
        one {# товар}
        =2 {# товарів}
        =3 {# товарів}
        =4 {# товарів}
        other {товарів}
    }`
  },
  toCatalog: {
    id: "main.categories.toCatalog",
    description: "main.categories.toCatalog",
    defaultMessage: "До Каталогу"
  }
});
