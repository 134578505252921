import React from "react";
import { withStyles } from "@mui/styles";
import { Grid } from "@mui/material";

import { User } from "models/user";

import { TextBody1, TextCaption, TextSubTitle } from "components/shared/text";
import Icon from "components/shared/Icon";
import FlrCheckboxWithLabel from "components/shared/form-elements/FlrCheckbox";
import messages from "translations/account/settings";
import messagesReg from "translations/auth/registration";
import styles from "./styles";

interface IProps {
  // component own props
  account: User;
  classes: any;
  onEditClick: () => void;
  toggleChangePasswordModal: () => void;
}

class PersonalData extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { classes, account } = this.props;

    return (
      <Grid xs={12} sm={12} item>
        <div className={classes.dataSection}>
          <TextBody1 align={"left"} className={classes.dataSectionLabel}>
            {messages.fullName.defaultMessage}
          </TextBody1>
          <TextSubTitle align={"left"} className={classes.dataSectionValue}>
            {account.profile.firstName}&nbsp;{account.profile.lastName}
          </TextSubTitle>
        </div>
        <div className={classes.dataSection}>
          <TextBody1 align={"left"} className={classes.dataSectionLabel}>
            {messagesReg.formFieldEmail.defaultMessage}
          </TextBody1>
          <TextSubTitle align={"left"} className={classes.dataSectionValue}>
            {account.email}
          </TextSubTitle>
        </div>
        <div className={classes.dataSection}>
          <TextBody1 align={"left"} className={classes.dataSectionLabel}>
            {messagesReg.formFieldPhone.defaultMessage}
          </TextBody1>
          <TextSubTitle align={"left"} className={classes.dataSectionValue}>
            {account.phone}
          </TextSubTitle>
        </div>
        {account.profile.phone2 && (
          <div className={classes.dataSection}>
            <TextBody1 align={"left"} className={classes.dataSectionLabel}>
              {messagesReg.formFieldPhone2.defaultMessage}
            </TextBody1>
            <TextSubTitle align={"left"} className={classes.dataSectionValue}>
              {account.profile.phone2}
            </TextSubTitle>
          </div>
        )}
        {/*<div className={classes.dataSection}>*/}
        {/*  <TextBody1 align={"left"} className={classes.dataSectionLabel}>*/}
        {/*    {messages.destinationCity.defaultMessage}*/}
        {/*  </TextBody1>*/}
        {/*  <TextSubTitle align={"left"} className={classes.dataSectionValue}>*/}
        {/*    м. Київ*/}
        {/*    /!*  TODO *!/*/}
        {/*  </TextSubTitle>*/}
        {/*</div>*/}
        <div className={classes.dataSection}>
          <TextBody1 align={"left"} className={classes.dataSectionLabel}>
            {messages.language.defaultMessage}
          </TextBody1>
          <TextSubTitle align={"left"} className={classes.dataSectionValue}>
            Українська
            {/*  TODO */}
          </TextSubTitle>
        </div>
        <div className={classes.dataSection}>
          <TextBody1 align={"left"} className={classes.dataSectionLabel}>
            {messages.currency.defaultMessage}
          </TextBody1>
          <TextSubTitle align={"left"} className={classes.dataSectionValue}>
            ₴ — гривня
            {/*  TODO */}
          </TextSubTitle>
        </div>

        <TextSubTitle align={"left"} className={classes.subTitleGroup}>
          {messages.personalDataSubtitleAdditional.defaultMessage}
        </TextSubTitle>

        <TextCaption className={classes.groupCaption}>{messages.socialTitle.defaultMessage}</TextCaption>
        <div className={classes.socialContainer}>
          <div className={classes.socialItem}>
            <Icon size={24} type={"google"} opacity={1} offset={8} />
            <span>{messages.socialNotConnected.defaultMessage}</span>
            <span className={classes.socialAction}>{messages.socialConnect.defaultMessage}</span>
          </div>
          <div className={classes.socialItem}>
            <Icon size={24} type={"facebook"} opacity={1} offset={8} />
            <span>{messages.socialNotConnected.defaultMessage}</span>
            <span className={classes.socialAction}>{messages.socialConnect.defaultMessage}</span>
            {/* TODO*/}
            {/*<span className={classes.socialAction}>*/}
            {/*  <IconButton color={"secondary"}>*/}
            {/*    <Icon size={20} type={"trash"} opacity={1} />*/}
            {/*  </IconButton>*/}
            {/*</span>*/}
          </div>
        </div>

        <TextCaption className={classes.groupCaption}>{messages.notificationTitle.defaultMessage}</TextCaption>
        <div>
          <FlrCheckboxWithLabel name={"telegram"} label={"Telegram"} className={classes.formInputCheckbox} disabled />
        </div>
        <div>
          <FlrCheckboxWithLabel name={"viber"} label={"Viber"} className={classes.formInputCheckbox} disabled />
        </div>
        <div>
          <FlrCheckboxWithLabel name={"whatsapp"} label={"Whatsapp"} className={classes.formInputCheckbox} disabled />
        </div>
      </Grid>
    );
  }
}

export default withStyles<any>(styles)(PersonalData);
