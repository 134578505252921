import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { changeForgotPasswordAsync, IForgotPasswordObject } from 'store/auth/actions';
import { validatePassword } from 'utils/helpers';
import FlrLoader from 'components/loading/LoadingSpinner';
import FlrInputPassword from 'components/shared/form-elements/textfield/FlrInputPassword';
import messagesControls from 'translations/layout/controls';
import AuthPageWrapper from '../shared/AuthPageWrapper';
import messages from 'translations/auth/forgotPassword';
import messagesCommon from 'translations/common';
import { TitleH1 } from 'components/shared/text';

interface IFormData {
  password: string;
  confirmPassword: string;
}

interface IProps {
  // component own props
  data: IForgotPasswordObject | null;
  classes?: any;
  token: string | null | undefined;
  isError: boolean;
  isLoading: boolean;
  changeForgotPassword: typeof changeForgotPasswordAsync.request;
}

const ChangePasswordScreen: React.FC<IProps> = ({ classes, token, data, isError, isLoading, changeForgotPassword }) => {
  const { handleSubmit, errors, control, watch } = useForm<IFormData>();

  const onSubmit = handleSubmit(({ password, confirmPassword }): any => {
    if (isLoading) {
      return;
    }

    changeForgotPassword({
      password,
      confirmPassword,
      token,
      authId: data && data.authId,
      resetKey: data!.resetKey
    });
  });
  const passwordWatcher = watch('password');

  return (
    <AuthPageWrapper classes={classes}>
      <TitleH1 style={{ fontSize: 28 }} className={classes.headerSingle}>
        {messages.headerChange.defaultMessage}
      </TitleH1>

      <form onSubmit={onSubmit}>
        <Controller
          disabled={isLoading}
          error={Boolean(errors.password)}
          helperText={errors.password && errors.password.message}
          rules={{
            required: messagesControls.requiredFiled.defaultMessage,
            validate: validatePassword
          }}
          as={FlrInputPassword}
          name="password"
          control={control}
          defaultValue=""
          className={classes.loginControl}
          fullWidth
          variant={'outlined'}
          label={messages.formFieldCodePassword.defaultMessage}
          type="password"
        />

        <Controller
          disabled={isLoading}
          error={Boolean(errors.confirmPassword || isError)}
          helperText={
            (errors.confirmPassword && errors.confirmPassword.message) ||
            (isError && messages.formFieldCodePasswordError.defaultMessage)
          }
          rules={{
            required: messagesControls.requiredFiled.defaultMessage,
            validate: (value: string) => value === passwordWatcher || messagesCommon.passwordMismatch.defaultMessage
          }}
          as={FlrInputPassword}
          name="confirmPassword"
          control={control}
          defaultValue=""
          className={classes.loginControl}
          fullWidth
          variant={'outlined'}
          label={messages.formFieldCodePasswordConfirm.defaultMessage}
          type="password"
        />

        <Button color={'primary'} variant={'contained'} type={'submit'}>
          {messages.formRestorePasswordButton.defaultMessage}
        </Button>
      </form>

      {isLoading && <FlrLoader withOverlay={true} />}
    </AuthPageWrapper>
  );
};

export default ChangePasswordScreen;
