import { useDispatch, useSelector } from 'react-redux';
import { getPriceType } from '../../../store/layout/selectors';
import { IPriceType, setPriceType } from '../../../store/layout';

const usePriceType = () => {
  const dispatch = useDispatch();
  const priceType = useSelector(getPriceType);

  const updatePriceType = (newPriceType: IPriceType) => {
    dispatch(setPriceType(newPriceType));
  };

  return { priceType, updatePriceType };
};

export { usePriceType };
