import * as React from 'react';
import { DropzoneRootProps, useDropzone } from 'react-dropzone';
import styled from '@emotion/styled/macro';
import { FormControl, IconButton } from '@mui/material';
import { MessageDescriptor } from 'react-intl';
import clsx from 'clsx';
import { IThemed } from '../../../utils/styled';
import Icon from '../../shared/Icon';
import { humanFileSize, isObject } from '../../../utils/helpers';
import FlrLoader from '../../loading/LoadingSpinner';
import { v4 as uuid } from 'uuid';

export interface IFlrFileInputProps {
  label: MessageDescriptor | string;
  name: string;
  value?: {} | (File & { index: number });
  fileProcessed?: boolean | object;
  onChange?: any;
  multiple?: boolean;
  setProcessed?: (arg0: any) => void;
  fullWidth?: boolean;
}

export type ThemedDropzone = DropzoneRootProps & IThemed;

const getColor = (props: ThemedDropzone) => {
  if (props.isDragAccept) {
    return props.theme.palette.primary.main.replace('#', '');
  }
  if (props.isDragReject) {
    return props.theme.colors.red.replace('#', '');
  }
  if (props.isDragActive) {
    return props.theme.palette.common.primaryHoverPress.replace('#', '');
  }
  return props.theme.palette.primary.main.replace('#', '');
};

function FlrFileInput(props: IFlrFileInputProps) {
  const { label, value, fileProcessed, onChange, multiple, setProcessed } = props;
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*,.pdf',
    multiple
  });

  const inputProps = getInputProps({ multiple });
  const dropInputOnChange = inputProps.onChange;

  inputProps.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (onChange) {
      // tslint:disable-next-line:forin
      for (const fileKey in files) {
        const file = files[fileKey];
        if (isObject(file)) {
          file.id = uuid();
        }
      }
      onChange(files);
    }
    if (dropInputOnChange) {
      dropInputOnChange(e);
    }
    return;
  };

  const clearInput = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    const newVal =
      multiple && value
        ? (state: any) => {
            const copy = { ...state };
            delete copy[id];
            return copy;
          }
        : '';

    if (onChange) {
      onChange(newVal);
      if (setProcessed) {
        setProcessed(newVal);
      }
    }
  };

  const anyFileLoaded = Object.keys(fileProcessed || {}).length;

  // TODO
  const color = undefined;

  return (
    <ComponentWrapper {...getRootProps()} color={color} className={clsx({ fileLoaded: anyFileLoaded })}>
      <input {...inputProps} />
      {/*{!multiple &&*/}
      {/*  (() => {*/}
      {/*    // @ts-ignore*/}
      {/*    const file: File & { index: number } = value;*/}
      {/*    const fileIcon =*/}
      {/*      value && fileProcessed ? <Icon type={"checkMark"} size={16} opacity={1} /> : <FlrLoader size={20} />;*/}
      {/*    return (*/}
      {/*      <div>*/}
      {/*        <CheckIconBlock style={{ gridArea: "check", alignSelf: "center" }}>{fileIcon}</CheckIconBlock>*/}
      {/*        <span style={{ gridArea: "fileName" }}>{file && fileProcessed && file.name}</span>*/}
      {/*        <small style={{ gridArea: "fileSize" }}>{file && fileProcessed && humanFileSize(file.size)}</small>*/}

      {/*        <IconButton color={"secondary"} onClick={e => clearInput(e, file.index)} style={{ gridArea: "close" }}>*/}
      {/*          <Icon type="trash" opacity="1" size={24} />*/}
      {/*        </IconButton>*/}

      {/*        /!*<CloseIconBlock style={{ gridArea: "close" }} onClick={clearInput}>*!/*/}
      {/*        /!*  <Icon type={"cross"} size={10} />*!/*/}
      {/*        /!*</CloseIconBlock>*!/*/}
      {/*      </div>*/}
      {/*    );*/}
      {/*  })()}*/}
      {multiple &&
        value &&
        fileProcessed &&
        Object.keys(fileProcessed).map(id => {
          const file = value[id];
          const isFileLoaded = !!file;
          const fileIcon =
            file && !fileProcessed[id] ? <Icon type={'checkMark'} size={16} opacity={1} /> : <FlrLoader size={20} />;

          return (
            <div key={id}>
              <CheckIconBlock style={{ gridArea: 'check', alignSelf: 'center' }}>{fileIcon}</CheckIconBlock>
              <span style={{ gridArea: 'fileName' }}>{isFileLoaded && file.name}</span>
              <small style={{ gridArea: 'fileSize' }}>{isFileLoaded && humanFileSize(file.size)}</small>

              <IconButton
                color={'secondary'}
                onClick={e => clearInput(e, id)}
                style={{ gridArea: 'close' }}
                size="large"
              >
                <Icon type="trash" opacity="1" size={24} />
              </IconButton>

              {/*<CloseIconBlock style={{ gridArea: "close" }} onClick={clearInput}>*/}
              {/*  <Icon type={"cross"} size={10} />*/}
              {/*</CloseIconBlock>*/}
            </div>
          );
        })}
      <div className={'empty'}>
        <Icon type={'attach'} size={24} offset={8} opacity={1} />
        <span>{label}</span>
      </div>
    </ComponentWrapper>
  );
}

const CheckIconBlock = styled<any>('div')(({ theme }: IThemed) => ({
  width: 38,
  height: 38,
  color: theme.palette.primary.main,
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: 19,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const ComponentWrapper = styled<any>(FormControl)(
  ({ theme, isDragAccept, isDragActive, isDragReject }: IThemed & ThemedDropzone) => ({
    '&.MuiFormControl-root': {
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontFamily: theme.fonts.body,
      lineHeight: 1,
      fontSize: theme.fontSizes.small,
      letterSpacing: theme.letterSpacing,
      height: '100%',
      minHeight: 54,
      width: '100%',
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke2='%238E3FBEFF' stroke='%23${getColor(
        { theme, isDragAccept, isDragActive, isDragReject }
      )}' stroke-width='3' stroke-dasharray='5%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      borderRadius: 8,
      marginTop: 20,
      '&:focus': {
        outlineColor: theme.palette.primary.main,
        outline: 'none'
      },
      '& > div': {
        display: 'none',
        padding: '15px 20px',
        '&.empty': {
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          fontSize: 12,
          lineHeight: '120%',
          '&:hover': {
            cursor: 'pointer'
          }
        }
      },
      '&.fileLoaded': {
        '& > div': {
          '&.empty': {
            display: 'none'
          },
          textAlign: 'left',
          width: '100%',
          display: 'grid',
          gridColumnGap: 10,
          gridTemplateColumns: '40px 1fr 30px',
          gridTemplateAreas: `check fileName close
          check fileSize close`,
          '& > *': {
            padding: 5
          },
          '& > span': {
            color: theme.palette.text.primary,
            fontSize: theme.fontSizes.small,

            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          },
          '& > small': {
            paddingTop: 0,
            paddingBottom: 0,
            color: theme.palette.text.secondary,
            fontSize: theme.fontSizes.tiny
          }
        }
      }
    }
  })
);

export default FlrFileInput;
