import React from "react";
import { Grid } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';

export const DashboardDeliverySkeleton: React.FC<any> = ({ columns, classes }) => {
  const cols = +(columns || 5);

  return <>
    {new Array(cols).fill("a").map((val, colKey) => (
      <Grid item sm={12} md={6} key={colKey}>
        <Skeleton variant="rectangular" width={"100%"} height={76} style={{ padding: 8, borderRadius: 8 }} />
      </Grid>
    ))}
  </>;
};

export default DashboardDeliverySkeleton;
