import React from 'react';
import { Column } from 'material-table';
import { Grid, Hidden } from '@mui/material';
import { getProductImage } from 'utils/helpers';

import { DiscountProduct } from 'models';
import { TextBody1, TextBody2, TextSubTitle } from 'components/shared/text';
import messages from 'translations/account/loyalty';

const ellipsisStyles: any = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: '100%',
  overflow: 'hidden'
};

const columns: Array<Column<DiscountProduct>> = [
  {
    title: messages.type.defaultMessage,
    field: 'orderId',
    render: ({ product }) => {
      const productAttrs: string[] = [];
      Object.keys(product.attributes).forEach((attr: string) => {
        const val = product.getAttribute(attr);
        if (val && val.value && val.value.toString() !== '') {
          productAttrs.push(`${val.displayValue || val.value} ${val.format || ''}`);
        }
      });
      return <>
        <Hidden mdUp>
          <Grid spacing={1} container wrap="nowrap" alignItems="center" style={{ maxWidth: 200 }}>
            <Grid item>
              <img
                src={getProductImage(product)}
                alt={''}
                height={40}
                width={40}
                style={{ borderRadius: '4px', maxWidth: 'none' }}
              />
            </Grid>
            <Grid container item>
              {/* TODO optionally add link to product */}
              <TextSubTitle style={ellipsisStyles}>{product.fullName}</TextSubTitle>
              <TextBody2 color="textSecondary" style={ellipsisStyles}>
                {productAttrs.join(' | ')}
              </TextBody2>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdDown>
          <Grid spacing={1} container wrap="nowrap" alignItems="center" style={{ maxWidth: 400 }}>
            <Grid item>
              <img
                src={getProductImage(product)}
                alt={''}
                height={40}
                width={40}
                style={{ borderRadius: '4px', maxWidth: 'none' }}
              />
            </Grid>
            <Grid container item>
              {/* TODO optionally add link to product */}
              <TextSubTitle style={ellipsisStyles}>{product.fullName}</TextSubTitle>
              <TextBody2 color="textSecondary" style={ellipsisStyles}>
                {productAttrs.join(' | ')}
              </TextBody2>
            </Grid>
          </Grid>
        </Hidden>
      </>;
    }
  },
  {
    title: messages.qty.defaultMessage,
    field: 'qty',
    type: 'numeric',
    render: rowData => <TextBody1 color={'textSecondary'}>{rowData.qty}</TextBody1>
  }
];

export default columns;
