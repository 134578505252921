import { Cart } from "models/cart";
import { CartItem } from "models/cart-item";

export class CartService {
  private storage: Storage;
  private localCopy: Cart;
  constructor(private storageName: string) {
    this.storage = window.localStorage;
    this.localCopy = this.storage[this.storageName] ? Cart.parse(this.storage[this.storageName]) : new Cart([]);
  }

  get cart() {
    return this.localCopy;
  }

  public update(cart: Cart) {
    return new Cart(cart);
  }

  public add(item: CartItem): Cart {
    const existingElementIndex = this.localCopy.items.findIndex(el => el.product.id === item.product.id);
    const updatedItem = new CartItem(item);
    let list: CartItem[];
    if (existingElementIndex > -1 && item.product.id) {
      updatedItem.qty += this.localCopy.items[existingElementIndex].qty;
      list = [
        ...this.localCopy.items.slice(0, existingElementIndex),
        updatedItem,
        ...this.localCopy.items.slice(existingElementIndex)
      ];
    } else {
      list = [...this.localCopy.items, updatedItem];
    }

    this.localCopy = new Cart(list);
    this.updateStorage();
    return this.localCopy;
  }

  private updateStorage() {
    this.storage[this.storageName] = this.localCopy.stringify();
  }
}
