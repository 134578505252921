import { Product } from 'models';

export class OrderItem {
  public id: string;
  public offerId: string;
  public product: Product;
  public qty: number;
  public packQty: number;
  public price: number;
  public discount: number;
  public discountAuto: number;
  public discountPrice: number;
  public discountAmount: number;
  public subTotal: number;
  public total: number;
  public special: boolean;

  constructor(orderItem: any) {
    this.id = orderItem.id;
    this.qty = orderItem.qty;
    this.packQty = orderItem.packQty;
    this.offerId = orderItem.offerId;
    this.product = new Product(orderItem.product);
    this.price = orderItem.price;
    this.discount = orderItem.discount;
    this.discountAuto = orderItem.discountAuto || 0;
    this.discountPrice = orderItem.discountPrice;
    this.discountAmount = orderItem.discountAmount;
    this.subTotal = orderItem.subTotal;
    this.total = orderItem.total;
    this.special = orderItem.special;
  }

  public toRequestItem(): IRequestOrderItem {
    return {
      id: this.id,
      offerId: this.offerId,
      price: this.price,
      qty: this.qty
    };
  }
}

export interface IRequestOrderItem {
  qty: number;
  price: number;
  id: string;
  offerId: string;
}
