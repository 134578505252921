import styled from '@emotion/styled/macro';
import { ITheme, IThemed } from '../../../utils/styled';

interface ITextProps extends IThemed {
  fontSize?: keyof ITheme['fontSizes'] | '';
  fontColor?: keyof ITheme['colors'] | '';
  error?: boolean;
  success?: boolean;
  bold?: boolean;
}

function getFontSize(fontSize: string | undefined, theme: any): number | string {
  if (fontSize) {
    if (theme.typography[fontSize] && theme.typography[fontSize].fontSize) {
      return theme.typography[fontSize].fontSize;
    }
    return theme.fontSizes[fontSize] || 'inherit';
  }
  return 'inherit';
}

export const Text = styled<any>('span')(
  ({ theme, fontSize, fontColor, error, success, bold }: IThemed & ITextProps) => ({
    fontSize: getFontSize(fontSize, theme),
    color: fontColor ? theme.colors[fontColor] : error ? theme.colors.red : success ? theme.colors.green : 'inherit',
    fontWeight: bold ? 500 : 'normal'
  })
);
