import styled from '@emotion/styled/macro';
import { IThemed } from '../../utils/styled';

const Container = styled<any>('div')(({ theme }: IThemed) => ({
  margin: '0 auto',
  width: '100%',
  maxWidth: theme.widths.md,

  [`@media (min-width: ${theme.breakpoints.lg})`]: {
    maxWidth: theme.widths.lg
  },

  [`@media (min-width: ${theme.breakpoints.xl})`]: {
    maxWidth: theme.widths.xl
  }
}));

export default Container;
