import React, { useState, useEffect, useRef } from 'react';
import MaterialTable, { MaterialTableProps } from 'material-table';
import { Box, TableContainer, TablePagination } from '@mui/material';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import messages from 'translations/layout/table';

import FlrLoader from 'components/loading/LoadingSpinner';
import { ReactComponent as TableSortDefault } from 'components/shared/icons/table-sort-default.svg';
import { ReactComponent as Trash } from 'components/shared/icons/trash.svg';
import { ReactComponent as Edit } from 'components/shared/icons/edit.svg';
import { ReactComponent as ArrowExpand } from 'components/shared/icons/arrow-expand.svg';
import { DetailPanelWrapper } from './DetailPanelWrapper';
import { pageSizeOptions } from './constants';
import styles from './styles';

interface IProps extends MaterialTableProps<any> {
  isLoadingExternal?: boolean;
  detailPanel?: any;
  classes?: any;
  data: any[];
}

const FlrTable: React.FC<IProps> = ({
  classes,
  data,
  columns,
  components = {},
  isLoadingExternal,
  detailPanel,
  ...other
}) => {
  const [page, setPage] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const tableRef: any = useRef();

  const handleScroll = () => {
    setIsOpen(false);
  };

  // * workaround for open table expand panel after changing page and return back
  useEffect(
    () => () => {
      if (tableRef.current && tableRef.current.props.data && tableRef.current.props.detailPanel) {
        tableRef.current.props.data.forEach((i: any, idx: number) =>
          i.tableData && i.tableData.showDetailPanel
            ? tableRef.current.onToggleDetailPanel([idx], tableRef.current.props.detailPanel)
            : null
        );
      }
    },
    []
  );

  // * close "select per row" dropdown while scrolling
  useEffect(() => {
    if (isOpen) {
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen]);

  const handleChangePage = (event: React.ChangeEvent<HTMLInputElement>, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setIsOpen(false);
  };

  const onRowClick = (event: any, rowData?: any, togglePanel?: (panelIndex?: number) => void) => {
    if (detailPanel && togglePanel) {
      // * dirty dirty workaround for "MaterialTable" lib for expanded row
      [...event.currentTarget.parentNode.children].forEach((el: any) => el.classList.remove(classes.selectedRow));
      if (event.currentTarget.nextSibling && event.currentTarget.nextSibling.classList.value === 'MuiTableRow-root') {
        event.currentTarget.classList.remove(classes.selectedRow);
      } else {
        event.currentTarget.classList.add(classes.selectedRow);
      }
      togglePanel();
    }
  };

  const pagedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const selectProps = {
    native: false,
    IconComponent: ArrowExpand,
    MenuProps: {
      classes: { paper: classes.selectListPaper },
      MenuListProps: {
        classes: { root: classes.selectList }
      },
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'left'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
      },
      onClose: () => setIsOpen(false),
      open: isOpen
    },
    onOpen: () => setIsOpen(true),
    classes: {
      icon: clsx(classes.dropdownIndicator, { [classes.dropdownIndicatorActive]: isOpen }),
      root: classes.selectRoot
    }
  };

  const expandProps = {
    ...(detailPanel ? { onRowClick } : {}),
    ...(detailPanel ? { detailPanel: (r: any) => <DetailPanelWrapper data={r} children={detailPanel} /> } : {})
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <MaterialTable
        tableRef={tableRef}
        icons={
          {
            SortArrow: TableSortDefault,
            Delete: Trash,
            Edit,
            DetailPanel: () => null
          } as any
        }
        isLoading={false}
        components={{
          Container: Box,
          Toolbar: () => null,
          Pagination: props => (
            <TablePagination
              {...props}
              classes={{
                root: classes.paginationRoot,
                selectRoot: classes.select,
                toolbar: classes.toolbar,
                caption: classes.paginationCaption
              }}
              rowsPerPageOptions={pageSizeOptions}
              rowsPerPage={rowsPerPage}
              page={page}
              count={data.length}
              SelectProps={selectProps}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
            />
          ),
          ...components
        }}
        columns={columns}
        data={pagedData}
        localization={{
          body: {
            emptyDataSourceMessage: messages.emptyTable.defaultMessage,
            editTooltip: messages.editTooltip.defaultMessage,
            deleteTooltip: messages.deleteTooltip.defaultMessage,
            editRow: {
              deleteText: messages.deleteText.defaultMessage,
              cancelTooltip: messages.cancelTooltip.defaultMessage,
              saveTooltip: messages.saveTooltip.defaultMessage
            }
          },
          pagination: { labelRowsPerPage: messages.rows.defaultMessage }
        }}
        {...other}
        {...expandProps}
        options={{
          paginationType: 'normal',
          actionsColumnIndex: 999,
          pageSize: data.length < rowsPerPage ? data.length : rowsPerPage,
          detailPanelType: 'single',
          rowStyle: {
            zIndex: 2,
            position: 'relative'
          },
          headerStyle: {
            position: 'initial'
          }
        }}
        key={pagedData.length + rowsPerPage}
      />

      {isLoadingExternal && <FlrLoader withOverlay={true} />}
    </TableContainer>
  );
};

export default withStyles<any>(styles)(FlrTable);
