import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'register.header',
    description: 'Registration page header',
    defaultMessage: 'Реєстрація'
  },
  headerCaption: {
    id: 'register.header',
    description: 'register.header',
    defaultMessage: 'Щоб купувати квіти гуртом за найкращими цінами, зареєструйтесь '
  },
  alreadyRegistered: {
    id: 'register.alreadyRegistered',
    description: 'Subheader for already registered customer',
    defaultMessage: 'Вже є обліковий запис?'
  },
  login: {
    id: 'register.login',
    description: 'Login link label',
    defaultMessage: 'Увійти'
  },
  formStep1: {
    id: 'register.form.Step1',
    description: 'Form step title (main info)',
    defaultMessage: 'Основна інформація'
  },
  formStep2: {
    id: 'register.form.step2',
    description: 'Form step title (legal info)',
    defaultMessage: 'Юридична інформація'
  },
  formStepFinal: {
    id: 'register.form.stepFinal',
    description: 'Form step final message',
    defaultMessage: "Дякуємо за реєстрацію, ми зв'яжемося з Вами після перевірки наданих даних"
  },
  formFieldFirstName: {
    id: 'register.form.firstName',
    description: 'Reg form field firstName',
    defaultMessage: 'Ім’я'
  },
  formFieldLastName: {
    id: 'register.form.lastName',
    description: 'Reg form field lastName',
    defaultMessage: 'Прізвище'
  },
  usedAsLogin: {
    id: 'register.form.usedAsLogin',
    description: 'register.form.usedAsLogin',
    defaultMessage: 'Використовується як логін'
  },
  formFieldPhone: {
    id: 'register.form.phone',
    description: 'Reg form field phone',
    defaultMessage: 'Основний номер телефону'
  },
  formFieldPhone2: {
    id: 'register.form.phone2',
    description: 'Reg form field phone2',
    defaultMessage: 'Додатковий номер телефону'
  },
  formFieldEmail: {
    id: 'register.form.email',
    description: 'Reg form field email',
    defaultMessage: 'Електронна пошта'
  },
  formFieldPassword: {
    id: 'register.form.password',
    description: 'Reg form field password',
    defaultMessage: 'Пароль'
  },
  formFieldSubscribe: {
    id: 'register.form.subscribe',
    description: 'Reg form field subscribe',
    defaultMessage: 'Підписатись на розсилку. Обіцяємо не спамити'
  },
  formContinueButton: {
    id: 'register.form.registerContinueButton',
    description: 'Reg form button label',
    defaultMessage: 'Продовжити реєстрацію'
  },
  formFieldEDPROU: {
    id: 'register.form.edrpou',
    description: 'Reg form field EDRPOU',
    defaultMessage: 'ЄДРПОУ'
  },
  formFieldFileEDPROU: {
    id: 'register.form.file.edrpou',
    description: 'Reg form attach EDRPOU file label',
    defaultMessage: 'Надайте файл ЄДРПОУ'
  },
  formFieldFileFOP: {
    id: 'register.form.file.fop',
    description: 'Reg form attach FOP file label',
    defaultMessage: 'Надайте файл ФОП'
  },
  formFinishButton: {
    id: 'register.form.registerFinishButton',
    description: 'Reg form final button label',
    defaultMessage: 'Зареєструватися'
  },
  formFinishAgreement: {
    id: 'register.form.agreement',
    description: 'Reg form terms and policies agreement text',
    defaultMessage:
      'Нажимаючи «Зареєструватися», я підтверджую, що ознайомився (-лась) з Умовами використання та Политикою конфіденціальності'
  },
  formFieldErrorEmail: {
    id: 'register.form.error.email',
    description: 'Reg form field error email',
    defaultMessage: 'Це адреса электронної пошти вже зайнята'
  },
  formFieldErrorPhone: {
    id: 'register.form.error.phone',
    description: 'Reg form field error phone',
    defaultMessage: 'Цей номер не є дійсним'
  },
  formFieldErrorPhoneNotValid: {
    id: 'register.form.error.phone.notValid',
    description: 'Reg form field error phone not valid',
    defaultMessage: 'Цей номер невірний'
  }
});
