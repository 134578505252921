import { call, put, takeEvery } from 'redux-saga/effects';
import { createAsyncAction, createReducer, PayloadAction } from 'typesafe-actions';

import { AccountDiscounts } from 'models/account-discounts';
import {
  errorDataWrapper,
  IAsyncDataWrapper,
  loadedDataWrapper,
  loadingDataWrapper,
  REQUEST_ACTIONS
} from 'store/actions';

import { AccountDiscountsRepository } from './request';

const httpClient = new AccountDiscountsRepository();
export const prefix = '@@accountDiscounts/';

export const ACCOUNT_DISCOUNTS_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const ACCOUNT_DISCOUNTS_REQUEST_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const ACCOUNT_DISCOUNTS_REQUEST_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

export interface IAccountDiscountsTemp extends AccountDiscounts {
  clientDiscounts: IClientDiscount[];
}

export type IAccountDiscountState = IAsyncDataWrapper<IAccountDiscountsTemp | null>;

export interface IConditions {
  [key: string]: string;
}

export interface IDiscountData {
  discountType: string;
  percent: number;
  progressValue: number;
  requiredValue: number;
  endDate?: string;
  startDate?: string;
}

export interface IClientDiscount {
  client: string;
  discountRule: string;
  id: string;
  onDate: string;
  updatedAt: string;
  discountData: IDiscountData;
  conditions?: IConditions;
}

export const accountDiscountsInitialState: IAccountDiscountState = {
  loading: false,
  loaded: false,
  data: null,
  error: null
};

type AccountDiscountsActionTypes =
  | typeof ACCOUNT_DISCOUNTS_REQUEST
  | typeof ACCOUNT_DISCOUNTS_REQUEST_SUCCESS
  | typeof ACCOUNT_DISCOUNTS_REQUEST_FAILURE;

export const fetchAccountDiscountsAsync = createAsyncAction(
  ACCOUNT_DISCOUNTS_REQUEST,
  ACCOUNT_DISCOUNTS_REQUEST_SUCCESS,
  ACCOUNT_DISCOUNTS_REQUEST_FAILURE
)<void, AccountDiscounts, Error>();

export const fetchClientDiscountsAsync = createAsyncAction(
  ACCOUNT_DISCOUNTS_REQUEST,
  ACCOUNT_DISCOUNTS_REQUEST_SUCCESS,
  ACCOUNT_DISCOUNTS_REQUEST_FAILURE
)<void, { clientDiscounts: IClientDiscount[] }, Error>();

function* accountDiscountSaga(): Generator {
  try {
    const response: any = yield call(() => httpClient.fetch());
    yield put(fetchAccountDiscountsAsync.success(new AccountDiscounts(response.data)));
  } catch (err) {
    yield put(fetchAccountDiscountsAsync.failure(err as Error));
  }
}

function* clientDiscountsSaga(): Generator {
  try {
    const response: any = yield call(() => httpClient.fetchClientDiscounts());
    yield put(fetchClientDiscountsAsync.success({ clientDiscounts: response.data.clientDiscounts }));
  } catch (err) {
    yield put(fetchClientDiscountsAsync.failure(err as Error));
  }
}

export function* accountDiscountsRequestSaga() {
  yield takeEvery(fetchAccountDiscountsAsync.request, accountDiscountSaga);
  yield takeEvery(fetchClientDiscountsAsync.request, clientDiscountsSaga);
}

export default createReducer(accountDiscountsInitialState)
  .handleAction(
    [fetchAccountDiscountsAsync.request, fetchClientDiscountsAsync.request],
    (state: IAccountDiscountState) => {
      return loadingDataWrapper(state.data);
    }
  )
  .handleAction(
    [fetchAccountDiscountsAsync.success, fetchClientDiscountsAsync.success],
    (state: IAccountDiscountState, action: PayloadAction<AccountDiscountsActionTypes, AccountDiscounts>) =>
      loadedDataWrapper({
        ...state.data,
        ...action.payload
      })
  )
  .handleAction(
    [fetchAccountDiscountsAsync.failure, fetchClientDiscountsAsync.failure],
    (state: IAccountDiscountState) =>
      errorDataWrapper({ ...state.data, accountDiscounts: null }, new Error('Failed to fetch accountDiscounts'))
  );
