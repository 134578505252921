import { ITheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  heading: {
    padding: '28px 0 0',
    marginBottom: '18px',
    '@media (max-width: 1500px)': {
      padding: '25px 0 0',
      marginBottom: 15
    }
  },
  orderCard: {
    maxWidth: '1136px',
    width: '100%',
    marginBottom: 64,
    '&:last-of-type': {
      marginBottom: '64px !important'
    },
    '& .': {
      marginBottom: theme.spacing(4)
    },
    '@media (max-width: 1500px)': {
      maxWidth: '1096px'
    }
  },
  tableItem: {
    marginLeft: 32,
    marginRight: 32,
    position: 'relative',
    '&:first-of-type': {
      borderTop: `1px solid ${theme.palette.divider}`
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .nowrap': {
      whiteSpace: 'nowrap',
      '& >div': {
        overflow: 'hidden'
      }
    },
    '@media (max-width: 1500px)': {
      borderBottom: 'none',
      '&::after': {
        content: '""',
        position: 'absolute',
        left: -20,
        right: -20,
        bottom: 0,
        height: '1px',
        backgroundColor: theme.palette.divider
      },
      '&:first-of-type::after': {
        content: '""',
        position: 'absolute',
        left: -20,
        right: -20,
        bottom: 0,
        height: '1px',
        backgroundColor: theme.palette.divider
      }
    }
  },
  tableItemInner: {
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 8,
    display: 'flex',
    width: '100%'
  },
  itemsDivider: {
    paddingLeft: 32,
    paddingRight: 32
  },
  outlineMargin: {
    position: 'relative',
    paddingTop: '14px !important',
    paddingBottom: '14px !important',
    '&:last-child': {
      marginBottom: 40
    },
    marginLeft: -7,
    '&>.selectedItemCard': {
      width: '100%',
      padding: 8
    },
    '&.alert': {
      marginTop: theme.spacing(-2.5),
      marginBottom: theme.spacing(2.5)
    }
  },
  productImageCell: {
    padding: 0,
    height: 40,
    width: 40,
    marginRight: 16,
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover'
    }
  },
  deliveryHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 32,
    marginLeft: 32,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '@media (max-width: 1500px)': {
      borderBottom: 'none',
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        left: -20,
        right: -20,
        bottom: 0,
        height: '1px',
        backgroundColor: theme.palette.divider
      }
    }
  },
  tableHeaders: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginLeft: 32,
    marginRight: 32,
    justifyContent: 'space-between',
    position: 'relative',
    '@media (max-width: 1500px)': {
      borderBottom: 'none',
      '&::after': {
        content: '""',
        position: 'absolute',
        left: -20,
        right: -20,
        bottom: 0,
        height: '1px',
        backgroundColor: theme.palette.divider
      }
    }
  },
  productImage: {
    borderRadius: theme.spacing(0.5)
  },
  productNameHeader: {
    minWidth: 364,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    marginRight: 16,
    '@media (max-width: 1500px)': {
      minWidth: 324
    },
    '@media (max-width: 1150px)': {
      minWidth: 284
    },
    '@media (max-width: 1050px)': {
      minWidth: 364
    }
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '19.2px'
  },
  productName: {
    minWidth: 308,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    marginRight: 16,
    gap: 4,
    '@media (max-width: 1500px)': {
      minWidth: 268
    },
    '@media (max-width: 1150px)': {
      maxWidth: 324
    }
  },
  quantityContainer: {
    minWidth: 208,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
    [(theme as any).breakpoints.down('md')]: {
      minWidth: 150
    }
  },
  quantityHeader: {
    minWidth: 208,
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    marginRight: 16
  },
  productNameAttr: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    whiteSpace: 'nowrap',
    width: '100%',
    '@media (max-width: 960px)': {
      width: '220px'
    }
  },
  orderItemSubtotal: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 116,
    marginRight: 20,
    justifyContent: 'flex-end',
    '&>span>*': {
      fontWeight: 700
    },
    '@media (max-width: 1150px)': {
      minWidth: 200
    },
    '@media (max-width: 1050px)': {
      minWidth: 242
    }
  },
  orderItemSubtotalHeader: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 116,
    flex: 1,
    justifyContent: 'flex-end',
    marginRight: 16
  },
  orderItemRemove: {
    textDecoration: 'none',
    lineHeight: 1,
    display: 'block'
  },
  noMarginBottom: {
    marginBottom: 0
  },
  noMarginTop: {
    marginTop: 0
  },
  cartAction: {
    justifyContent: 'flex-end',
    padding: 0,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  cardActions: {
    padding: '25px 32px 24px 32px'
  },
  disabled: {
    opacity: 0.5,
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'default',
      color: theme.palette.text.primary,
      textDecoration: 'none'
    }
  },
  gray: {
    color: theme.palette.text.secondary,
    '&:hover': {
      cursor: 'default',
      color: theme.palette.text.secondary
    }
  },
  black: {
    color: theme.palette.common.black
  },
  red: {
    color: theme.palette.error.main
  },
  green: {
    color: theme.palette.common.primaryAccentText
  },
  divider: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5) - 4
  },
  attributeItem: {
    position: 'relative' as 'relative', // https://github.com/microsoft/TypeScript/issues/11465
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    '&:not(:first-child)': {
      paddingLeft: theme.spacing(1),
      '&::before': {
        display: 'block',
        position: 'absolute' as 'absolute',
        top: 0,
        bottom: 0,
        width: 1,
        content: "''",
        left: -1,
        backgroundColor: theme.palette.divider
      }
    },
    [(theme as any).breakpoints.down('md')]: {
      '&:first-child': {
        width: '100%',
        clear: 'both'
      },
      '&:not(:first-child)': {
        '&:nth-child(2)': {
          paddingLeft: 0,
          '&::before': {
            display: 'none'
          }
        }
      }
    },
    [(theme as any).breakpoints.down('sm')]: {
      '&:first-child': {
        width: 'initial'
      },
      '&:not(:first-child)': {
        '&:nth-child(1)': {
          paddingLeft: 0,
          '&::before': {
            display: 'none'
          }
        }
      }
    }
  },
  expired: {
    background: 'transparent'
  },
  padded: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [(theme as any).breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  textRight: {
    textAlign: 'right',
    '&>span>*': {
      fontWeight: 700,
      fontSize: '18px'
    }
  },
  discountPrice: {
    '@media (max-width: 720px)': {
      color: '#000'
    },
    '& > *': {
      fontWeight: 700,
      lineHeight: '22px',
      '@media (max-width: 720px)': {
        fontWeight: 400,
        fontSize: '14px'
      }
    }
  },
  cartBlockHeader: {
    marginTop: theme.spacing(6),
    '&:first-of-type': {
      marginTop: 0
    }
  },
  noVerticalMargin: {
    marginBottom: 0,
    marginTop: 0
  },
  specialOrderSign: {
    // for fire icon with price in one line
    flexWrap: 'nowrap',
    maxWidth: 96
  },
  transitDeliveryTypeContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center'
  },
  transitDeliveryText: {
    marginRight: theme.spacing(4),
    color: '#8f8f8f'
  },
  transitDeliveryTime: {
    marginRight: theme.spacing(1),
    color: '#8f8f8f'
  },
  deliveryDatesContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center'
  },
  attributeContainer: {
    display: 'flex',
    marginRight: 8,
    '&:last-of-type': {
      marginRight: 0
    }
  },
  otherAttributeValue: {
    margin: 0,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '14px',
    color: '#333',
    userSelect: 'none'
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    [(theme as any).breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  inPackageCountContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 84,
    justifyContent: 'flex-end',
    marginRight: 16,
    '@media (max-width: 1150px)': {
      display: 'none'
    }
  },
  inPackageCountHeader: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 84,
    justifyContent: 'flex-end',
    marginRight: 16,
    '@media (max-width: 1150px)': {
      display: 'none'
    }
  },
  removeIcon: {
    padding: 0
  },
  discountContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 90,
    marginRight: 16,
    justifyContent: 'flex-end',
    '@media (max-width: 1150px)': {
      display: 'none'
    }
  },
  discountHeader: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 90,
    justifyContent: 'flex-end',
    marginRight: 16,
    '@media (max-width: 1150px)': {
      display: 'none'
    }
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 90,
    marginRight: 16,
    justifyContent: 'flex-end',
    '@media (max-width: 1050px)': {
      display: 'none'
    }
  },
  priceHeader: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 90,
    marginRight: 16,
    justifyContent: 'flex-end',
    flex: 1,
    '@media (max-width: 1050px)': {
      display: 'none'
    }
  },
  outOfStockContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    width: '100%',
    [(theme as any).breakpoints.down('lg')]: {
      justifyContent: 'space-between'
    }
  },
  productInfoCell: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  priceDiffContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  colorCircle: {
    marginRight: 8,
    userSelect: 'none'
  },
  discountItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    minWidth: 110,
    marginRight: 32,
    gap: 2,
    '@media (max-width: 720px)': {
      minWidth: 90,
      marginRight: 8
    }
  },
  totalQtyItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    minWidth: 98,
    marginRight: 32,
    gap: 2,
    '@media (max-width: 720px)': {
      minWidth: 90,
      marginRight: 8
    }
  },
  sumPriceItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    minWidth: 126,
    marginRight: 32,
    gap: 2,
    '@media (max-width: 720px)': {
      minWidth: 90,
      marginRight: 8
    }
  },
  subtotalWrapper: {
    display: 'flex',
    width: '100%'
  },
  checkoutOrderFooterBlock: {
    width: 'fit-content',
    padding: '11px 30px',
    '@media (max-width: 1500px)': {
      padding: '11px 13px'
    }
  },
  quantityValue: {
    '@media (max-width: 720px)': {
      color: '#000'
    },
    '& > *': {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '22px',
      '@media (max-width: 720px)': {
        fontWeight: 400,
        fontSize: '14px'
      }
    }
  },
  sumValue: {
    '@media (max-width: 720px)': {
      color: '#000'
    },
    '& > *': {
      fontWeight: 700,
      lineHeight: '22px',
      '@media (max-width: 720px)': {
        fontWeight: 500,
        fontSize: '14px'
      }
    }
  },
  fullWidth: {
    width: '100%',
  }
});

export default styles;
