import React from 'react';
import clsx from 'clsx';
import _find from 'lodash/find';

import FlrSlider from 'components/shared/form-elements/FlrSlider';
import { Link2, TextSubTitle } from 'components/shared/text';
import { IFilterOptions } from 'models';
import { ATTRIBUTE_PRICE_PATH } from 'shared/filters';
import { IFilterProp, IFilterProps, IFiltersData } from 'store/catalog/actions';
import { filtersMessages } from 'translations/catalog/filters';

interface IProps {
  filters: IFiltersData;
  setFilters: (newFilters: IFilterProps) => void;
  classes: any;
  label: string;
  filterOptionsData: IFilterOptions;
  postFilteredFilterOptions: Map<any, any>;
}

const PriceRangeOptions: React.FC<IProps> = ({
  classes,
  label: labelSection,
  filterOptionsData,
  postFilteredFilterOptions,
  setFilters,
  filters
}) => {
  const opts = filterOptionsData.price;

  const arrayOfOptions: any[] | null = postFilteredFilterOptions.get('prices')
    ? Array.from(postFilteredFilterOptions.get('prices')).filter(Boolean)
    : null;

  if (!arrayOfOptions || !arrayOfOptions.length) {
    return null;
  }

  if (opts.length !== 2) {
    return <></>;
  }

  opts[0] = Math.floor(Math.min(...arrayOfOptions));
  opts[1] = Math.ceil(Math.max(...arrayOfOptions));

  const filtersFast = filters.fast || [];

  const priceFilter = filtersFast.find((filter) => filter.path === ATTRIBUTE_PRICE_PATH);
  const filterValues =
    priceFilter && priceFilter.value ? priceFilter.value.split('-').map((val) => parseInt(val, 10)) : opts;

  const arraySelected = filtersFast.filter((item) => item.path === ATTRIBUTE_PRICE_PATH);
  const arraySelectedLast = arraySelected && arraySelected.length ? arraySelected[arraySelected.length - 1] : null;

  const handleResetPriceFilters = (ev: React.SyntheticEvent | React.MouseEvent) => {
    if (arraySelectedLast) {
      ev.stopPropagation();
      setFilters(filtersFast.filter((item) => item.path !== ATTRIBUTE_PRICE_PATH));
    }
  };

  const handlePriceRangeChange = (values: number[]) => {
    const newFilterPropValue: IFilterProp = {
      rule: 'range',
      path: ATTRIBUTE_PRICE_PATH,
      value: values.join('-')
    };

    const priceFilterObj = _find(filtersFast, { path: ATTRIBUTE_PRICE_PATH });
    if (priceFilterObj) {
      priceFilterObj.value = newFilterPropValue.value;
      priceFilterObj.time = newFilterPropValue.time;
    } else {
      filtersFast.push(newFilterPropValue);
    }

    setFilters(filtersFast);
  };

  return (
    <div className={classes.paddedPanel}>
      <div className={classes.paddedPanelWrapper}>
        <div className={classes.simpleBottom}>
          <TextSubTitle>{labelSection}</TextSubTitle>
          <Link2
            className={clsx(classes.expansionReset, {
              [classes.expansionResetActive]: !!arraySelectedLast
            })}
            onClick={handleResetPriceFilters}
          >
            {filtersMessages.reset.defaultMessage}
          </Link2>
        </div>
        <FlrSlider
          title={filtersMessages.priceRange.defaultMessage}
          min={opts[0]}
          max={opts[1]}
          value={filterValues}
          onChange={handlePriceRangeChange}
          withInputs={true}
        />
      </div>
    </div>
  );
};

export default PriceRangeOptions;
