import { FC, useMemo } from 'react';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment/moment';

import { RowSkeleton } from 'components/cards/product';
import { SplitedPrice } from 'components/prices';
import Icon from 'components/shared/Icon';
import Tooltip from 'components/shared/tooltip';
import { IAttribute, Product } from 'models/product';
import { getCatalogFilters } from 'store/catalog/selectors';
import cardMessages from 'translations/catalog/details';

import { createCartItemQtySelector } from '../../../store/cart/selectors';
import { usePriceType } from '../../layout/header/usePriceType';
import { ColorCircle } from '../../shared/product/ColorCircle';
import { CardDiscountIcon } from '../shared/CardDiscountIcon';
import { CatalogProductRowDevice } from './device/catalog-product-row-device';

import classes from './product-row.module.scss';

interface ICatalogProductRowMemoizedProps {
  key?: string;
  style?: object;
  index?: number;
  product?: Product | null;
  handleSelectProduct?: any;
  selectedProductId?: string | null;
  withPrice?: boolean;
  isMobile?: boolean;
}

interface IProps {
  item: Product;
  onClick?: (product: Product) => void;
  isSelected?: boolean;
  isPriceByPackage?: boolean;
  showStockColumn?: boolean;
}

const CatalogProductRowComp: FC<IProps> = ({ item, onClick, isSelected = false, isPriceByPackage, showStockColumn }) => {
  const cartItemQty = useSelector(createCartItemQtySelector(item.id));
  const filters = useSelector(getCatalogFilters);

  if (!item) {
    return null;
  }

  const productParams: IAttribute[] = [];
  Object.keys(item.attributes).forEach((attributeKey) => {
    const attribute = item.getAttribute(attributeKey);
    if (attribute.value) {
      productParams.push(attribute);
    }
  });

  const manufacturerName = item.getAttribute('manufacturer').value;
  const manufacturer = (
    <div className={classes.tooltipContainer}>
      {item.getAttribute('country') && item.getAttribute('country').value ? (
        <FlagIcon code={item.getAttribute('country').value as FlagIconCode} size={16} style={{ marginRight: 10 }} />
      ) : (
        <span style={{ width: 26 }} />
      )}
      <span className={clsx(classes.ellipsisText, classes.manufacturerLabel)}>{manufacturerName}</span>
    </div>
  );

  const preOrderTooltip = (
    <div className={classes.tooltipContainer}>
      <div className={classes.preOrderContainer}>
        <Icon type="infoNew" size={18} offset={2} className={classes.iconPreOrder} />
      </div>
      <div style={{ flexDirection: 'column', boxSizing: 'border-box', display: 'flex', gap: '8px' }}>
        <span className={clsx(classes.ellipsisText, classes.preOrderTooltip)}>Актуальність ціни</span>
        <span className={clsx(classes.ellipsisText, classes.preOrderTooltipDate)}>
          {moment(item.preOrderPriceDate).format('DD.MM.YY')}
        </span>
      </div>
    </div>
  );

  const handleClick = () => {
    if (onClick) {
      onClick(item);
    }
  };

  const inCartCount = () => {
    return cartItemQty > 0 ? (
      <div className={classes.cartCountContainer}>
        <span className={classes.inCartCountLabel}>{cartItemQty}</span>
        <Icon type="shoppingCartOne" size={18} offset={4} />
      </div>
    ) : null;
  };
  const stockBySupplier = item?.stockBySupplier[filters.supplier || ''];
  return (
    <div className={clsx(classes.row, { [classes.rowWrapperSelected]: isSelected })}>
      <div onClick={handleClick} className={classes.rowContainer}>
        <div className={classes.nameWrapper}>
          {item.fullName.length > 21 ? (
            <Tooltip
              placement="top"
              title={
                <p className="MuiTypography-root MuiTypography-subtitle2 MuiTypography-colorInherit">{item.fullName}</p>
              }
            >
              <span className={classes.overFlowText}>
                <span className={classes.name}>{item.fullName}</span>
              </span>
            </Tooltip>
          ) : (
            <span className={classes.name}>{item.fullName}</span>
          )}
        </div>
        <div className={classes.attributesWrapper}>
          <ColorCircle color={item.attributes?.color?.value} />
          {item.attributesList.map((attr) => (
            <div key={attr.code} className={classes.attributeContainer}>
              <Icon size={14} type={attr.alias} leftOffset={4} offset={2} className={classes.icon} opacity={1} />
              <p className={classes.otherAttributeValue}>{attr.value}</p>
            </div>
          ))}
        </div>
        <div className={classes.manufacturer}>
          {manufacturerName && manufacturerName.length > 8 ? (
            <Tooltip
              placement="top"
              title={
                <p className="MuiTypography-root MuiTypography-subtitle2 MuiTypography-colorInherit">
                  {manufacturerName}
                </p>
              }
            >
              {manufacturer}
            </Tooltip>
          ) : (
            manufacturer
          )}
        </div>
        <div className={classes.categoryWrapper}>
          {item.catalogCategory.length > 10 ? (
            <Tooltip
              placement="top"
              title={
                <p className="MuiTypography-root MuiTypography-subtitle2 MuiTypography-colorInherit">
                  {item.catalogCategory}
                </p>
              }
            >
              <span className={classes.overFlowText}>
                <span className={classes.ellipsisText}>{item.catalogCategory}</span>
              </span>
            </Tooltip>
          ) : (
            <span className={classes.ellipsisText}>{item.catalogCategory}</span>
          )}
        </div>
        <div className={classes.unitWrapper}>
          <span className={classes.value}>{item.inPackageCount}</span>
        </div>
        {showStockColumn ? (
          <div className={classes.avaliableWrapper}>
            <span className={classes.value}>{stockBySupplier}</span>
          </div>
        ) : null}
        <div className={classes.inCartWrapper}>
          <span className={classes.cartCountLabel}>{inCartCount()}</span>
        </div>
        <div className={classes.discountBadgeWrapper}>
          {item.discount ? (
            <CardDiscountIcon
              discount={item.discount}
              details={item.discountDetails}
              className={classes.discountBadge}
              specialPriceDiscount={item?.specialPriceDiscount}
            >
              <span className={classes.discountText}>
                -{item.specialPriceDiscount > item.discount ? item.specialPriceDiscount : item.discount}%
              </span>
            </CardDiscountIcon>
          ) : (
            <span className={classes.emptyDiscountBadge} />
          )}
        </div>
        <div className={classes.price}>
          {item.priceRange ? (
            <div className={classes.priceItem}>
              <SplitedPrice
                fontColor="inherit"
                fontSize={'h5'}
                value={item.minPrice * (isPriceByPackage ? item.inPackageCount : 1)}
                className={classes.priceBold}
              />
            </div>
          ) : item.hasPreOrder && item.preOrderPrice ? (
            <div className={clsx(classes.priceItem, classes.priceItemPreOrder)}>
              <span className={classes.priceBold}>~</span>
              <SplitedPrice
                fontColor="inherit"
                value={item.preOrderPriceWithDiscount * (isPriceByPackage ? item.inPackageCount : 1)}
                fontSize={'h5'}
                className={classes.priceBold}
              />
            </div>
          ) : (
            cardMessages.notInStock.defaultMessage
          )}
        </div>
        <div className={classes.preOrderTooltipLabel}>
          {item.hasPreOrder && item.preOrderPrice && !item.priceRange && item.preOrderPriceDate ? (
            <Tooltip placement="top" title={preOrderTooltip} open={true}>
              <span className={classes.preOrderTooltipLabel}>
                <Icon type="infoNew" size={18} offset={2} />
              </span>
            </Tooltip>
          ) : null}
        </div>
        <div className={classes.iconContainer}>
          {item.specialPrice ? <Icon type="fireBordered" size={18} opacity={1} className={classes.icon} /> : null}
          {item.hasTransit ? (
            <Icon type="goodsComingBordered" size={18} opacity={1} leftOffset={-6} className={classes.icon} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const CatalogProductRowContainer = (props: IProps & ICatalogProductRowMemoizedProps) => {
  const { priceType } = usePriceType();
  const isPriceByPackage = priceType === 'package';

  if (props.isMobile) {
    return (
      <CatalogProductRowDevice {...props} isPriceByPackage={isPriceByPackage} showStockColumn={props.showStockColumn} />
    );
  }

  return <CatalogProductRowComp {...props} isPriceByPackage={isPriceByPackage} showStockColumn={props.showStockColumn} />;
};

export const CatalogProductRowMemoized = ({
  key,
  style,
  index,
  product,
  handleSelectProduct,
  selectedProductId,
  withPrice,
  isMobile
}: ICatalogProductRowMemoizedProps) =>
  useMemo(
    () => (
      <div key={key} style={style}>
        {!product ? (
          <RowSkeleton key={key} />
        ) : (
          <CatalogProductRowContainer
            key={`product-card-${index}`}
            item={product}
            onClick={handleSelectProduct}
            isSelected={selectedProductId === product.id}
            withPrice={withPrice}
            isMobile={isMobile}
          />
        )}
      </div>
    ),
    // eslint-disable-next-line
    [product, selectedProductId]
  );
