import { defineMessages } from 'react-intl';

export const filtersMessages = defineMessages({
  filters: {
    id: 'pages.catalog.topActionPanel.filters',
    description: 'Фільтри',
    defaultMessage: 'Фільтри'
  },
  reset: {
    id: 'pages.catalog.filters.reset',
    description: 'Скинути',
    defaultMessage: 'Скинути'
  },
  collapseIn: {
    id: 'pages.catalog.filters.collapseIn',
    description: 'pages.catalog.filters.collapseIn',
    defaultMessage: 'Згорнути'
  },
  collapseOut: {
    id: 'pages.catalog.filters.collapseOut',
    description: 'pages.catalog.filters.collapseOut',
    defaultMessage: 'Розгорнути'
  },
  deliveryFilter: {
    id: 'pages.catalog.filters.deliveryFilter',
    description: 'status of products',
    defaultMessage: 'Доставка товарів'
  },
  productsStatus: {
    id: 'pages.catalog.filters.productsStatus',
    description: 'status of products',
    defaultMessage: 'Статус товарів'
  },
  inStock: {
    id: 'pages.catalog.filters.inStock',
    description: 'inStock availability check',
    defaultMessage: 'В наявності'
  },
  inStockMessage: {
    id: 'pages.catalog.filters.inStockMessage',
    description: 'inStockMessage availability check',
    defaultMessage: 'Усі в наявності, включаючи акційні'
  },
  inStockChipMessage: {
    id: 'pages.catalog.filters.inStockChipMessage',
    description: 'inStockChipMessage availability check',
    defaultMessage: 'Усі в наявності'
  },
  preOrder: {
    id: 'pages.catalog.filters.preOrder',
    description: 'preOrder availability check',
    defaultMessage: 'Передзамовлення'
  },
  preOrderMessage: {
    id: 'pages.catalog.filters.preOrderMessage',
    description: 'preOrderMessage availability check',
    defaultMessage: 'Усі з передзамовленням'
  },
  specialOffers: {
    id: 'pages.catalog.filters.specialOffers',
    description: 'specialOffers availability check',
    defaultMessage: 'Акційні в наявності'
  },
  specialOffersMessage: {
    id: 'pages.catalog.filters.specialOffersMessage',
    description: 'specialOffersMessage availability check',
    defaultMessage: 'Усі акційні в наявності'
  },
  transitOffers: {
    id: 'pages.catalog.filters.transitOffers',
    description: 'transitOffers availability check',
    defaultMessage: 'В дорозі'
  },
  transitOffersMessage: {
    id: 'pages.catalog.filters.transitOffersMessage',
    description: 'transitOffersMessage availability check',
    defaultMessage: 'Усі в дорозі, включаючи акційні'
  },
  specialTransitOffers: {
    id: 'pages.catalog.filters.specialTransitOffers',
    description: 'specialTransitOffers availability check',
    defaultMessage: 'Акційні в дорозі'
  },
  specialTransitOffersMessage: {
    id: 'pages.catalog.filters.specialTransitOffersMessage',
    description: 'specialTransitOffersMessage availability check',
    defaultMessage: 'Усі акційні в дорозі'
  },
  supplier: {
    id: 'pages.catalog.filters.supplier',
    description: 'pages.catalog.filters.supplier',
    defaultMessage: 'Звідки доставляємо'
  },
  deliveryFrom: {
    id: 'pages.catalog.filters.deliveryFrom',
    description: 'pages.catalog.filters.deliveryFrom',
    defaultMessage: 'Куди доставляємо'
  },
  productCategory: {
    id: 'pages.catalog.filters.productCategory',
    description: 'pages.catalog.filters.productCategory',
    defaultMessage: 'Категорія товарів'
  },
  resetFilters: {
    id: 'pages.catalog.filters.reset',
    description: 'reset filters link',
    defaultMessage: 'Скинути все'
  },
  applyFilters: {
    id: 'pages.catalog.filters.apply',
    description: 'apply filters button',
    defaultMessage: 'Застосувати'
  },
  deliveryPeriod: {
    id: 'pages.catalog.filters.deliveryPeriod',
    description: 'Период доставки',
    defaultMessage: 'Період доставки'
  },
  priceRange: {
    id: 'pages.catalog.filters.priceRange',
    description: 'Цена',
    defaultMessage: 'Ціна за шт, ₴'
  },
  category: {
    id: 'pages.catalog.filters.category',
    description: 'Категория',
    defaultMessage: 'Категорія'
  },
  categoryFilterAll: {
    id: 'pages.catalog.filters.categoryFilterAll',
    description: 'pages.catalog.filters.categoryFilterAll',
    defaultMessage: 'Всі категорії'
  },
  productType: {
    id: 'pages.catalog.filters.productType',
    description: 'pages.catalog.filters.productType',
    defaultMessage: 'Категорія товарів'
  },

  subCategory: {
    id: 'pages.catalog.filters.subCategory',
    description: 'Подкатегория',
    defaultMessage: 'Підкатегорія'
  },
  typeFilter: {
    id: 'pages.catalog.filters.typeFilter',
    description: 'Вид',
    defaultMessage: 'Вид'
  },
  heightFilter: {
    id: 'pages.catalog.filters.heightFilter',
    description: 'Высота',
    defaultMessage: 'Висота, см'
  },
  colorFilterAll: {
    id: 'pages.catalog.filters.colorFilterAll',
    description: 'pages.catalog.filters.colorFilterAll',
    defaultMessage: 'Всі кольори'
  },
  colorFilter: {
    id: 'pages.catalog.filters.colorFilter',
    description: 'Цвет',
    defaultMessage: 'Колір'
  },
  diameterFilter: {
    id: 'pages.catalog.filters.diameterFilter',
    description: 'Диаметр',
    defaultMessage: 'Діаметр'
  },
  shapeFilter: {
    id: 'pages.catalog.filters.shapeFilter',
    description: 'Форма',
    defaultMessage: 'Форма'
  },
  colorFilterOther: {
    id: 'pages.catalog.filters.colorFilterOther',
    description: 'Цвет другой',
    defaultMessage: 'Інший'
  },
  countryFilter: {
    id: 'pages.catalog.filters.countryFilter',
    description: 'Страна',
    defaultMessage: 'Краіна'
  },
  manufacturerFilter: {
    id: 'pages.catalog.filters.manufacturerFilter',
    description: 'Производитель',
    defaultMessage: 'Виробник'
  },
  countryFilterAll: {
    id: 'pages.catalog.filters.countryFilterAll',
    description: 'pages.catalog.filters.countryFilterAll',
    defaultMessage: 'Всі країни'
  },
  manufacturerFilterAll: {
    id: 'pages.catalog.filters.manufacturerFilterAll',
    description: 'pages.catalog.filters.manufacturerFilterAll',
    defaultMessage: 'Всі виробники'
  },
  extraFilters: {
    id: 'pages.catalog.filters.extraFilters',
    description: 'Доп. фильтры',
    defaultMessage: 'Додаткові фільтри'
  },
  extraFiltersNotChecked: {
    id: 'pages.catalog.filters.extraFiltersNotChecked',
    description: '(не обрано)',
    defaultMessage: '(не обрано)'
  },
  extraFiltersFavourite: {
    id: 'pages.catalog.filters.extraFilters',
    description: 'pages.catalog.filters.extraFilters',
    defaultMessage: 'Обрані'
  },
  extraFiltersDiscount: {
    id: 'pages.catalog.filters.extraFiltersDiscount',
    description: 'pages.catalog.filters.extraFiltersDiscount',
    defaultMessage: 'Зі знижкою'
  },
  chipsPricePerUnit: {
    id: 'pages.catalog.filters.chipsPricePerUnit',
    description: 'pages.catalog.filters.chipsPricePerUnit',
    defaultMessage: '/шт'
  },
  chipsHeight: {
    id: 'pages.catalog.filters.chipsHeight',
    description: 'pages.catalog.filters.chipsHeight',
    defaultMessage: 'см'
  },
  deliveryModalCancel: {
    id: 'pages.catalog.filters.deliveryModalCancel',
    description: 'pages.catalog.filters.deliveryModalCancel',
    defaultMessage: 'Скасувати'
  },
  deliveryModalApply: {
    id: 'pages.catalog.filters.deliveryModalApply',
    description: 'pages.catalog.filters.deliveryModalApply',
    defaultMessage: 'Застосувати'
  },
  deliveryModalTitle: {
    id: 'pages.catalog.filters.deliveryModalTitle',
    description: 'pages.catalog.filters.deliveryModalTitle',
    defaultMessage: 'Виберіть параметри доставки'
  }
});
