import { ITheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  wrapper: {
    // width: ${(props: IProps) => props.containerWidth};
    display: 'flex',
    justifyContent: 'space-between'
  },
  socialIconButton: {
    padding: 0,
    borderRadius: '50%',
    borderBottom: 'none',
    '&:not(:last-of-type)': {
      marginRight: 8
    },
    // "&:focus": {
    //   border: `1px solid ${theme.palette.secondary.main}`
    // },
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: 'transparent'
    },
    '&:active': {
      color: theme.palette.common.primaryHoverPress,
      borderColor: 'transparent'
    }
  },
  socialIconButtonDark: {
    color: theme.palette.common.white,

    '&:hover': {
      color: theme.palette.secondary.main
    },
    '&:active': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.common.primaryHoverPress
    }
  }
});

export default styles;
