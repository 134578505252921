import styled from '@emotion/styled/macro';
import { IThemed } from 'utils/styled';
import {
  productCardHeight,
  productCardHeightMobile,
  productCardHeightMobileLogged,
  productCardsMargin,
  tableViewRowHeight,
  tableViewRowMobileHeight
} from 'shared/constants';
import { contentFade } from 'styles/mixins';

export const ComponentWrapper = styled<any>('section')(({ theme }: IThemed) => ({
  flex: 2,
  background: theme.palette.common.cardBlackBackgroundHex,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const Wrapper = styled<any>('div')((props: IThemed) => {
  const { theme } = props;
  return {
    width: 'auto',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    padding: '6px 0 0 1px',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      padding: '0 0 0 1px',
    },
    ...contentFade,
    '& .stickyHeader': {
      position: 'sticky',
      zIndex: 1,
      height: 50,
      top: 0,
      width: '100%',
      backgroundColor: '#fff',
      maxWidth: 'calc(100% - 20px)',
      display: 'grid',
      borderRadius: '8px',
      marginLeft: 4,
    },
    '& .loader': {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center'
    },

    // inner scrolled container
    '& .ReactVirtualized__Grid': {
      [`@media (min-width: ${theme.breakpoints.sm})`]: {
        overflow: 'auto !important'
      },
      '&:focus': {
        outline: 'none'
      },
      '&.withPrice': {
        '& > div': {
          overflow: 'scroll',
          '& > div': {
            [`@media (max-width: ${theme.breakpoints.sm})`]: {
              gridAutoRows: productCardHeightMobile + productCardHeightMobileLogged - 4
            }
          }
        }
      },
      '& > div': {
        overflow: 'scroll',
        // product card row
        '& > div': {
          transition: 'top 0.5s ease',
          display: 'grid',
          gridColumnGap: productCardsMargin,
          gridTemplateColumns: `repeat(${props['data-items']}, minmax(0, 1fr))`,
          gridAutoRows: productCardHeight + 6,
          gridAutoFlow: 'row',
          overflow: 'visible',
          padding: '0 6px',
          [`@media (max-width: ${theme.breakpoints.sm})`]: {
            gridAutoRows: productCardHeightMobile + 6,
            padding: '12px 16px 2px',
            gridColumnGap: 15
          },
          '& > figure': {
            position: 'relative',
            boxSizing: 'border-box',
            '& .card-footer': {
              borderRadius: `0 0 ${theme.cardBorderRadius} ${theme.cardBorderRadius}`
            }
          }
        }
      },
      '&.table-layout-wrapper': {

        [`@media (max-width: ${theme.breakpoints.sm})`]: {
          marginTop: 0
        },

        '& > div': {
          background: 'white',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '0 0 8px 8px',
          margin: '0 6px',
          [`@media (max-width: ${theme.breakpoints.sm})`]: {
            margin: '12px 10px 0',
            borderRadius: 8,
          },
          '& > div': {
            gridAutoRows: tableViewRowHeight,
            padding: 0,

            [`@media (max-width: ${theme.breakpoints.sm})`]: {
              gridAutoRows: tableViewRowMobileHeight
            }
          }
        }
      }
    }
  };
});
