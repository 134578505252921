import { useEffect, useRef } from 'react';

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current as T;
}

// alias for useEffect(() => {}, [deps])
export function useDidUpdate(deps: any | any[], cb: () => void) {
  useEffect(cb, Array.isArray(deps) ? deps : [deps]); // eslint-disable-line
}

// alias for useEffect(() => {}, [])
export function useDidMount(cb: () => void) {
  useEffect(() => cb(), []); // eslint-disable-line
}
