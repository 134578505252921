import { FC } from 'react';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { isMobileDevice } from 'environment';
import { Column } from 'material-table';
import moment from 'moment';

import { SplitedPrice } from 'components/prices';
import { ColorCircle } from 'components/shared/product/ColorCircle';
import { Link1, TextBody1, TextBody2 } from 'components/shared/text';
import { Order, ORDER_TYPE_PREORDER } from 'models';
import { defaultLocale } from 'shared/constants';
import messages from 'translations/account/order';

import classes from './AccountOrdersTableColumns.module.scss';

// todo columns to actual model
const isMobile = isMobileDevice(window.navigator);
const prettyDate = (date: Date, isShort?: boolean) => {
  const mdate = moment(date);
  return mdate.locale(defaultLocale).format(isShort ? 'dd DD.MM' : 'DD.MM.YYYY');
};

const prettyDateWithDay = (date: Date) => {
  return moment(date).locale(defaultLocale).format('dd DD.MM.YYYY');
};

const formatTime = (timeRange: string): string => {
  return timeRange
    .split(' - ')
    .map((time) => {
      const [hours, minutes] = time.split(':').map(Number);
      const formattedHours = hours < 10 ? hours?.toString() : hours;
      return `${formattedHours}:${minutes?.toString().padStart(2, '0')}`;
    })
    .join(' - ');
};

interface IProps {
  // component own props
  rowData: Order;
}

export const indicatorOrderStatus = (status: string) => {
  switch (status) {
    case 'Підтверджене':
      return 'Небесний';
    case 'Доставляється':
      return 'Мандариновий';
    case 'Завершене':
      return 'Лаймовий';
    case 'Повернення':
      return 'Ягідний';
    case 'Скасоване':
      return 'Залізно-сірий';
    default:
      return '#b3b3b3';
  }
};

const LinkColumn: FC<IProps> = ({ rowData }) => {
  const { pathname } = useLocation();

  const orderId = rowData && rowData.orderId;

  return (
    <div className={clsx(classes.wrapper, classes.orderIdWrapper)}>
      <Link1 to={`${pathname}/${orderId}`} className={classes.orderId}>
        {!isMobile ? '№ ' : null}{rowData && rowData.orderId}
      </Link1>
      <TextBody2 color={'textSecondary'} className={classes.createdAt}>
        {isMobile
          ? `${rowData.orderType}`
          : `${messages.tableStatusFrom.defaultMessage} ${prettyDate(rowData && rowData.createdAt)}`}
      </TextBody2>
    </div>
  );
};

const columns: Array<Column<Order>> = [
  {
    title: messages.tableOrder.defaultMessage,
    field: 'createdAt',
    render: (rowData) => <LinkColumn rowData={rowData} />,
    sorting: true
  },
  {
    title: messages.typeOrder.defaultMessage,
    field: 'type',
    render: (rowData) => <span className={classes.typeWrapper}>{rowData.orderType}</span>,
    sorting: true
  },
  {
    render: (rowData) => (
      <div className={classes.statusContainer}>
        {rowData.orderStatus !== 'Нове' ? (
          <ColorCircle color={indicatorOrderStatus(rowData.orderStatus)} className={classes.colorCircle} />
        ) : null}
      </div>
    )
  },
  {
    title: messages.tableStatus.defaultMessage,
    field: 'status',
    defaultSort: 'desc',
    render: (rowData) => (
      <div className={classes.statusWrapper}>
        <span className={clsx(classes.orderStatus, { [classes.newOrderStatus]: rowData.orderStatus === 'Нове' })}>
          {rowData.orderStatus}
        </span>
      </div>
    ),
    sorting: true
  },
  {
    title: messages.tableDateShipment.defaultMessage,
    field: 'deliveryDate',
    render: (rowData) => (
      <div className={clsx(classes.wrapper, classes.deliveryDateWrapper)}>
        <TextBody1>{rowData.deliveryDate ? prettyDateWithDay(rowData.deliveryDate) : '-'}</TextBody1>
        <TextBody2 color={'textSecondary'}>
          {formatTime(rowData?.orderDetails?.deliveryTime?.name || rowData?.orderDetails?.selfPickUpTime?.name || '')}
        </TextBody2>
      </div>
    ),
    sorting: false
  },
  {
    title: messages.tableDeliveryType.defaultMessage,
    field: 'deliveryMethod',
    render: (rowData) => (
      <div className={clsx(classes.wrapper, classes.deliveryMethodWrapper)}>
        <TextBody1>{rowData?.orderDetails?.deliveryMethod?.name || '-'}</TextBody1>
        <TextBody2 color={'textSecondary'} className={classes.deliveryMethod}>
          {rowData.outlet!.name}
          {rowData.outlet!.address ? `: ${rowData.outlet!.address}` : ''}
        </TextBody2>
      </div>
    ),
    sorting: false
  },
  {
    title: messages.tableDeliveryCount.defaultMessage,
    field: 'totalQty',
    type: 'numeric',
    render: (rowData) => (
      <div className={clsx(classes.wrapper, classes.totalQtyWrapper)}>
        <TextBody1>{rowData.totalQty} шт</TextBody1>
        {rowData.totalPackQty > 0 && <TextBody2 color={'textSecondary'}>{rowData.totalPackQty} уп.</TextBody2>}
      </div>
    ),
    sorting: false
  },
  {
    title: messages.tableDeliveryAmount.defaultMessage,
    field: 'totalSum',
    type: 'numeric',
    render: (rowData) => (
      <div className={clsx(classes.totalSumWrapper)}>
        <SplitedPrice
          fontSize={'large'}
          value={rowData.totalSum}
          prefix={rowData.orderType === ORDER_TYPE_PREORDER ? '~' : undefined}
          className={classes.splicedPrice}
        />
      </div>
    ),
    sorting: false
  }
];

export const columnsTablet: Array<Column<Order>> = [
  {
    title: messages.tableOrder.defaultMessage,
    field: 'createdAt',
    render: (rowData) => <LinkColumn rowData={rowData} />,
    sorting: true
  },
  {
    title: messages.typeOrder.defaultMessage,
    field: 'type',
    render: (rowData) => <span className={classes.typeWrapper}>{rowData.orderType}</span>,
    sorting: true
  },
  {
    render: (rowData) => (
      <div className={classes.statusContainer}>
        {rowData.orderStatus !== 'Нове' ? (
          <ColorCircle color={indicatorOrderStatus(rowData.orderStatus)} className={classes.colorCircle} />
        ) : null}
      </div>
    )
  },
  {
    title: messages.tableStatus.defaultMessage,
    field: 'status',
    defaultSort: 'desc',
    render: (rowData) => (
      <div className={classes.statusWrapper}>
        <span className={clsx(classes.orderStatus, { [classes.newOrderStatus]: rowData.orderStatus === 'Нове' })}>
          {rowData.orderStatus}
        </span>
      </div>
    ),
    sorting: true
  },
  {
    title: messages.tableDateShipment.defaultMessage,
    field: 'deliveryDate',
    render: (rowData) => (
      <div className={clsx(classes.wrapper, classes.deliveryDateWrapper)}>
        <TextBody1>{rowData.deliveryDate ? prettyDateWithDay(rowData.deliveryDate) : '-'}</TextBody1>
        <TextBody2 color={'textSecondary'}>
          {formatTime(rowData?.orderDetails?.deliveryTime?.name || rowData?.orderDetails?.selfPickUpTime?.name || '')}
        </TextBody2>
      </div>
    ),
    sorting: false
  },
  {
    title: messages.tableDeliveryType.defaultMessage,
    field: 'deliveryMethod',
    render: (rowData) => (
      <div className={clsx(classes.wrapper, classes.deliveryMethodWrapper)}>
        <TextBody1>{rowData?.orderDetails?.deliveryMethod?.name || '-'}</TextBody1>
      </div>
    ),
    sorting: false
  },
  {
    title: messages.tableDeliveryAmount.defaultMessage,
    field: 'totalSum',
    type: 'numeric',
    render: (rowData) => (
      <div className={clsx(classes.totalSumWrapper)}>
        <SplitedPrice
          fontSize={'large'}
          value={rowData.totalSum}
          prefix={rowData.orderType === ORDER_TYPE_PREORDER ? '~' : undefined}
          className={classes.splicedPrice}
        />
      </div>
    ),
    sorting: false
  }
];

export const columnsMobile: Array<Column<any>> = [
  {
    render: (rowData) => (
      <div className={classes.statusContainer}>
        {rowData.orderStatus !== 'Нове' ? (
          <ColorCircle color={indicatorOrderStatus(rowData.orderStatus)} className={classes.colorCircle} />
        ) : (
          <span className={classes.newOrderStatus}>{rowData.orderStatus}</span>
        )}
      </div>
    )
  },
  {
    title: messages.tableOrder.defaultMessage,
    field: 'createdAt',
    render: (rowData) => <LinkColumn rowData={rowData} />,
    sorting: true
  },
  {
    title: messages.deliveryLabel.defaultMessage,
    field: 'deliveryDate',
    render: (rowData: Order) => (
      <div className={classes.container}>
        <TextBody2 className={classes.displayName}>{rowData?.orderDetails?.warehouse?.displayName || ''}</TextBody2>
        <TextBody2 color={'textSecondary'} className={classes.text}>
          <span style={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
            {rowData.deliveryDate ? prettyDate(rowData.deliveryDate, true) : '-'}
          </span>
        </TextBody2>
      </div>
    ),
    sorting: true
  },
  {
    title: messages.tableDeliveryAmount.defaultMessage,
    field: 'totalSum',
    type: 'numeric',
    render: (rowData) => (
      <div className={classes.container}>
        <SplitedPrice
          fontSize={'body2'}
          decimalSameSize
          value={rowData.totalSum}
          prefix={rowData.orderType === ORDER_TYPE_PREORDER ? '~' : undefined}
          className={classes.mobilePrice}
          hideDecimal
        />
        <TextBody2 color={'textSecondary'} className={classes.text}>
          <SplitedPrice fontColor={'inherit'} fontSize={'body2'} hideDecimal value={rowData.totalQty} postfix={'шт'} />
        </TextBody2>
      </div>
    ),
    sorting: true
  }
];

export default columns;
