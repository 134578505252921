// Example for using discriminated union types.
import { OptionsObject } from 'notistack';

export type ThemeColors = 'light' | 'dark';
export type IPriceType = 'package' | 'unit';

export interface INotificationContent {
  message?: any;
  title?: any;
  options?: OptionsObject;
}
export interface INotificationContentMessage {
  message: any;
  title?: any;
  options?: OptionsObject;
}
export interface INotification {
  key?: string | number;
  message: any;
  title?: any;
  options?: OptionsObject;
  dismissed?: boolean;
}

// Define however naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export const LAYOUT_ACTION_TYPES = {
  SET_THEME: '@@layout/SET_THEME',
  ENQUEUE_SNACKBAR: '@@notification/ENQUEUE_SNACKBAR',
  CLOSE_SNACKBAR: '@@notification/CLOSE_SNACKBAR',
  REMOVE_SNACKBAR: '@@notification/REMOVE_SNACKBAR',
  SET_PRICE_TYPE: '@@layout/SET_PRICE_TYPE'
};

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface ILayoutState {
  readonly theme: ThemeColors;
  readonly notifications: INotification[];
  readonly priceType: IPriceType;
}
