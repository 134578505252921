import { FC } from 'react';
import clsx from 'clsx';

import { CartItem } from 'models';
import { ExpandPanel } from 'components/shared';
import CartGroupCardItemLabel from './CartGroupCardItemLabel';

import CartGroupCardItemData from './CartGroupCardItemData';
import classes from './CartGroupCardItem.module.scss';

interface IProps {
  selected?: boolean;
  removeDisabled?: boolean;
  expired?: boolean;
  max?: number;
  item: CartItem;
  onChangeAmount?: (qty: number) => void;
  onSelect: () => void;
  onUnselect: () => void;
  onRemove?: () => void;
  withItemRemove?: boolean;
}

const CartGroupCardItemDevice: FC<IProps> = ({
  selected,
  expired,
  removeDisabled,
  max,
  item,
  onChangeAmount,
  onRemove,
  onSelect,
  onUnselect,
}) => {
  const handleChange = () => {
    if (selected) {
      onUnselect();
    } else {
      onSelect();
    }
  };

  return (
    <div className={clsx(classes.tableItem, { [classes.outlineMargin]: selected })}>
      <ExpandPanel
        label={
          <CartGroupCardItemLabel
            expired={expired}
            item={item}
            onRemove={onRemove}
            selected={selected}
            onSelect={onSelect}
            onUnselect={onUnselect}
            removeDisabled={removeDisabled}
          />
        }
        onChange={handleChange}
        leftIcon
        expanded={selected}
        classNameSummary={classes.summary}
      >
        <CartGroupCardItemData
          max={max}
          onChangeAmount={onChangeAmount}
          item={item}
          removeDisabled={removeDisabled}
        />
      </ExpandPanel>
    </div>
  );
};

export default CartGroupCardItemDevice;
