import { css } from 'styled-components';
import { devicePanelHeight } from 'shared/constants';

export default css`
  html {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
  }

  #root {
    overflow-x: hidden;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
    position: relative;
    vertical-align: middle;
  }

  figure {
    margin: 0;
  }

  figcaption {
    font-size: 80%;
  }

  table {
    width: 100%;
    font-size: 85%;
    border-collapse: collapse;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: none !important;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }

  /* Change ring button styles */
  .rngst_phone_button.rngst_phone_button {
    position: fixed;
    right: 8px;
    bottom: 16px;
    opacity: 1;
    width: 60px;
    height: 60px;
    margin: 0;
    transform-origin: unset;
    border-radius: 40px;
    z-index: 2147483640 !important;
    transition: none !important;
  }
  .rngst_phone_button.rngst_phone_button.dropdownMessengers .dropdown-content-rngst {
    left: 0;
    background: transparent;
    width: 100%;
    padding: 0;
    align-items: center;
  }
  .rngst_phone_button.rngst_phone_button.dropdownMessengers .rngst_phone_icon {
    left: unset;
    top: unset;
    transform-origin: unset;
    /*
    width: 100%;
    */
  }
  .rngst_phone_button .rngst_phone_body--tooltip .tooltiptext,
  .rngst_phone_button .dropdown-content-rngst .tooltiptext {
    display: none;
  }
  .rngst_phone_button .rngst_phone_body {
    left: unset;
    top: unset;
  }
  .rngst_phone_button .rngst_phone_circle,
  .rngst_phone_button .rngst_phone_circle2,
  .rngst_phone_button .rngst_phone_fill {
    display: none;
  }
  #wrapper_id #id #rngst_modalDialog {
    background: #f1e8f7;
  }
  @media screen and (min-width: 960px) {
    .rngst_phone_button.rngst_phone_button.dropdownMessengers.dropdownMessengers-show {
      box-shadow: none;
      z-index: 2147483638 !important;
    }
  }
  @media screen and (max-width: 959px) {
    .rngst_phone_button.rngst_phone_button {
      width: calc(((100% - 48px) / 5) + 24px);
      height: ${devicePanelHeight}px;
      bottom: 0;
      right: 0;
      border-radius: 8px 0 0 0;
      background-color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
      z-index: 1200 !important;
    }
    .rngst_phone_button.rngst_phone_button.dropdownMessengers.dropdownMessengers-show {
      /* bottom: 0; */
      right: 6px;
    }
    .rngst_phone_button.rngst_phone_button .dropbtn {
      opacity: 1;
    }
    .rngst_phone_button.rngst_phone_button.dropdownMessengers .rngst_phone_icon {
      filter: invert(1);
      width: calc(100% - 16px);
      height: 100%;
      opacity: 0.5;
    }
    .rngst_phone_button.rngst_phone_button .rngst_phone_body,
    .rngst_phone_button.rngst_phone_button:hover .rngst_phone_body {
      background-color: transparent;
      height: 100%;
    }
    .rngst_phone_button.rngst_phone_button.dropdownMessengers .dropdown-content-rngst {
      box-shadow: none;
      background: transparent;
      padding-bottom: 0px;
      flex-direction: column;
    }
    /*
    .rngst_phone_button.rngst_phone_button.dropdownMessengers .dropdown-content-rngst:after {
      display: block;
      content: "";
      width: 50px;
      height: 50px;
      position: absolute;
      bottom: 4px;
      border-radius: 25px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
      border: 1px solid grey;
    }
    */
    .rngst_phone_button.rngst_phone_button.dropdownMessengers .dropdown-content-rngst .messengers-icon-btn a {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
      background: transparent;
      border-radius: 25px;
    }
  }
  .dropdown-content-rngst a {
    margin: 5px 0 5px 20px;
  }
  .apexcharts-tooltip {
    transform: translateX(-50%) !important;
  }
  .creditLine .apexcharts-text.apexcharts-datalabel-value {
    transform: translateY(-10px) !important;
  }

  _::-webkit-full-page-media,
  _:future,
  :root .safari_iOS_flex {
    flex-basis: auto;
  }

  _::-webkit-full-page-media,
  _:future,
  :root .MuiGrid-grid-xs-true:not(.MuiGrid-direction-xs-column) {
    flex-basis: auto;
  }

  /* show message for mobile devices landscape mode */
  // @media screen and (orientation: landscape) and (max-width: 960px) {
  //   #root {
  //     display: none;
  //   }

  //   #landscape-fallback {
  //     display: block;
  //     text-align: center;
  //     margin-top: 150px;
  //   }
  // }
`;
