import { defaultMaterialTheme } from "utils/styled";

export const getDonutChartOptions = (displayValue: string, label: string, classes: any) => ({
  legend: {
    show: false
  },
  dataLabels: {
    enabled: false
  },
  tooltip: {
    enabled: false,
    custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
      return `<div class="${classes.chartTooltip}">${w.config.labels[seriesIndex]}</div>`;
    }
  },
  colors: ['#cddc39'],
  plotOptions: {
    pie: {
      donut: {
        size: "75%",
        labels: {
          show: true,
          name: {
            show: true,
            offsetY: 0
          },
          value: {
            show: true,
            fontSize: "12px",
            fontFamily: defaultMaterialTheme.typography.h6.fontFamily,
            fontWeight: defaultMaterialTheme.typography.h6.fontWeight,
            color: defaultMaterialTheme.palette.text.secondary,
            offsetY: 0
          },
          total: {
            show: true,
            showAlways: true,
            label: displayValue,
            fontSize: "22px",
            fontFamily: defaultMaterialTheme.typography.h1.fontFamily,
            fontWeight: defaultMaterialTheme.typography.h1.fontWeight,
            color: defaultMaterialTheme.palette.text.primary,
            formatter: () => label
          }
        }
      }
    }
  },
  states: {
    normal: {
        filter: {
            type: 'none',
            value: 0,
        }
    },
    hover: {
        filter: {
            type: 'none',
            value: 0,
        }
    },
    active: {
        filter: {
            type: 'none',
            value: 0,
        }
    },
}
});