import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { createAsyncAction, createReducer, PayloadAction } from 'typesafe-actions';

import { IStaticPageData } from 'models/static-page';
import { errorDataWrapper, IAsyncDataWrapper, loadingDataWrapper, REQUEST_ACTIONS } from 'store/actions';

import { StaticPagesRepository } from './request';
import { getStaticPagesData, getStaticPagesLoadingState } from './selectors';

const httpClient = new StaticPagesRepository();

export const prefix = '@@staticPages/';

export const STATIC_PAGES_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const STATIC_PAGES_REQUEST_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const STATIC_PAGES_REQUEST_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

interface IStaticPagesStateSync {
  oferta: IStaticPageData | null;
  replacement: IStaticPageData | null;
  delivery: IStaticPageData | null;
}

interface IStaticPagePayload {
  data: IStaticPageData;
  pageType: keyof IStaticPagesStateSync;
}

export type IStaticPagesState = IAsyncDataWrapper<IStaticPagesStateSync>;

export const staticPagesInitialState: IStaticPagesState = {
  loading: false,
  loaded: false,
  data: {
    oferta: null,
    replacement: null,
    delivery: null,
  },
  error: null
};

export const fetchStaticPagesAsync = createAsyncAction(
  STATIC_PAGES_REQUEST,
  STATIC_PAGES_REQUEST_SUCCESS,
  STATIC_PAGES_REQUEST_FAILURE
)<string, IStaticPagePayload, Error>();

function* staticPagesSaga(action: ReturnType<typeof fetchStaticPagesAsync.request>): SagaIterator {
  const loading = yield select(getStaticPagesLoadingState);
  if (!loading) {
    return;
  }

  const storedData: any = yield select(getStaticPagesData);
  if (storedData[action.payload as keyof IStaticPagesStateSync]) {
    return;
  }

  try {
    const response: any = yield call(() => httpClient.getStaticData(action.payload));
    if (response.data) {
      yield put(fetchStaticPagesAsync.success({ data: response.data, pageType: action.payload as keyof IStaticPagesStateSync }));
    }
  } catch (e) {
    yield put(fetchStaticPagesAsync.failure(e as Error));
  }
}

export function* staticPagesRequestSaga(): SagaIterator {
  yield takeEvery(fetchStaticPagesAsync.request, staticPagesSaga);
}

export default createReducer(staticPagesInitialState)
  .handleAction(fetchStaticPagesAsync.request, (state: IStaticPagesState) => loadingDataWrapper(state.data))
  .handleAction(
    fetchStaticPagesAsync.success,
    (state: IStaticPagesState, action: PayloadAction<string, IStaticPagePayload>) => ({
      ...state,
      loading: false,
      loaded: true,
      data: {
        ...state.data,
        [action.payload.pageType]: action.payload.data
      },
      error: null
    })
  )
  .handleAction(fetchStaticPagesAsync.failure, (state: IStaticPagesState, action: PayloadAction<string, Error>) =>
    errorDataWrapper(state.data, action.payload)
  );
