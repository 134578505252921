import { defineMessages } from "react-intl";

export default defineMessages({
  novaposhtaLabel: {
    id: "checkout.deliveryMethod.novaposhta.label",
    description: "checkout.deliveryMethod.novaposhta.label",
    defaultMessage: "Нова Пошта"
  },
  novaposhtaDescription: {
    id: "checkout.deliveryMethod.novaposhta.description",
    description: "checkout.deliveryMethod.novaposhta.description",
    defaultMessage: "Вкажіть відділення"
  },
  florotekaLabel: {
    id: "checkout.deliveryMethod.floroteka.label",
    description: "checkout.deliveryMethod.floroteka.label",
    defaultMessage: "Флоротека"
  },
  florotekaDescription: {
    id: "checkout.deliveryMethod.floroteka.description",
    description: "checkout.deliveryMethod.floroteka.description",
    defaultMessage: `Доставка машинами, що облаштовані умовами для найкращого зберігання квітів.
    З 8:00 до 20:00`
  },
  selfpickupLabel: {
    id: "checkout.deliveryMethod.self.label",
    description: "checkout.deliveryMethod.self.label",
    defaultMessage: "Самостійно"
  },
  selfpickupDescription: {
    id: "checkout.deliveryMethod.self.description",
    description: "checkout.deliveryMethod.self.description",
    defaultMessage: ""
  },
});
