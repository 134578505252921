import { IFilterOptions, IFilterOptionsRequest } from "models/filter-options";

import { HttpClient } from "utils/http";

export class FilterOptionsRepository {
  private client = new HttpClient(`${process.env.REACT_APP_API_ENDPOINT}/catalog/filters`);

  public async fetch(request: IFilterOptionsRequest) {
    const params: any = Object.keys(request).reduce(
      (acc, key) => (request[key] ? { ...acc, [key]: request[key] } : acc),
      {}
    );

    const { supplier, ...filters } = params;

    // ? TODO why we need to pass "filters" filed ?
    return await this.client
      .get<IFilterOptions>("/", { params: { ...params, filters } })
      .then(response => response.data);
  }
}
