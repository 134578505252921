import * as React from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import { withTheme } from '@mui/styles';
import { TextValidator } from 'react-material-ui-form-validator';
import { ITheme } from 'utils/styled';

class FlrInput extends React.Component<TextFieldProps> {
  public render(): React.ReactNode {
    const { helperText, ...other } = this.props;

    // prevent input jumps when no helper text provided
    return <TextValidator helperText={helperText || ' '} {...other} />;
  }
}

export default withTheme<ITheme, any>(FlrInput);
