import * as React from 'react';

interface IFlrTextLimiterType {
  value: string;
  maxLength: number;
}

const FlrTextLimiter = ({ value, maxLength }: IFlrTextLimiterType) => (
  <span style={{ display: 'inline-block', float: 'right' }}>
    {value.length}/{maxLength}
  </span>
);

export default FlrTextLimiter;
