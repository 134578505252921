import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import env from 'environment';
import moment from 'moment';

import { Icon } from 'components/shared';
import FlrButtonOutlinedBrown from 'components/shared/buttons/FlrButtonOutlinedBrown';
import FlrCard from 'components/shared/card/FlrCard';
import { TextBody2, TextSubTitle, TitleH1 } from 'components/shared/text';
import Tooltip from 'components/shared/tooltip';
import { IClientDiscount, IDiscountCondition } from 'models';
import { baseUrl, defaultCatalogProductType } from 'shared/constants';
import messages from 'translations/account/dashboard';

import classes from './DashboardLoyaltyCard.module.scss';

interface IPropsLoyaltyCard {
  discount: IDiscountCondition;
  isLoading?: boolean;
  clientDiscount?: IClientDiscount;
  smallCards?: boolean;
  isBalanceLoyaltyCard?: boolean;
}

const DashboardLoyaltyCard: FC<IPropsLoyaltyCard> = ({
  discount,
  clientDiscount,
  isLoading,
  smallCards,
  isBalanceLoyaltyCard
}) => {
  const { image, name, discountType, startDate, endDate } = discount;
  const imageUrl = image ? `${env.apiUrl}/static/product-discount-images/${image}` : '/images/discount-card-2.webp';

  const guaranteedDiscountPercent = discount?.discountData?.nextDiscountProgress?.percent || 0;

  const nextDiscountPercent: any = (() => {
    if (discount?.discountData) {
      return Object.keys(discount.conditions).find(
        (k) => Number(discount.conditions[k]) >= (discount?.discountData?.nextDiscountProgress?.progressValue || 0)
      );
    }
    return Object.keys(discount.conditions)[0];
  })();

  const getPercent = () => {
    if (discount.discountType === 'static' || discount.discountType === 'website') {
      return Object.keys(discount.conditions)[0];
    }
    if (clientDiscount?.discountData?.percent) {
      return clientDiscount.discountData.percent || 0;
    }
    return 0;
  };

  const getDescription = () => {
    if (discount.discountType === 'static' || discount.discountType === 'website') {
      return discount.description;
    }
    return '';
  };

  const nextMonth = moment().startOf('month').add(1, 'month').format('D MMMM');
  const lastDayOfMonth = moment().endOf('month').format('D MMMM');

  const nextDiscountAmount = clientDiscount
    ? Number(discount.conditions[nextDiscountPercent]) -
      (discount?.discountData?.nextDiscountProgress?.progressValue || 0)
    : Number(discount.conditions[nextDiscountPercent]);

  const Conditions = () => {
    return (
      <>
        <TextSubTitle color={'inherit'} style={{ marginTop: 0 }}>
          <FormattedMessage {...messages.tooltipTitle} values={{ nextMonth, guaranteedDiscountPercent }} />
        </TextSubTitle>
        <br />
        <TextBody2 className={classes.tooltipText}>
          <FormattedMessage
            {...messages.tooltipText}
            values={{
              nextDiscountPercent,
              nextDiscountAmount,
              lastDayOfMonth
            }}
          />
        </TextBody2>
      </>
    );
  };

  const percentLevel = getPercent();

  return (
    <div className={clsx(classes.cardPaper, { [classes.smallCards]: smallCards })}>
      <FlrCard hoverShadow zeroPadding className={classes.cardLoyalty}>
        {!isBalanceLoyaltyCard ? <img className={classes.cardLoyaltyImage} src={imageUrl} alt={''} /> : null}
        {discountType !== 'static' && discountType !== 'website' ? (
          <div className={classes.cardLoyaltyTooltip}>
            <Tooltip
              placement="top"
              icon={<Icon type={'info'} size={24} offset={8} opacity={1} />}
              title={<Conditions />}
              classes={{ tooltip: classes.tooltipClass }}
            >
              <span>
                <Icon type={'info'} size={32} opacity={1} className={classes.cardLoyaltyTooltipIcon} />
              </span>
            </Tooltip>
          </div>
        ) : null}
        <div
          className={clsx(classes.cardLoyaltyContent, { [classes.balanceCardLoyaltyContent]: isBalanceLoyaltyCard })}
        >
          <TextSubTitle
            className={clsx(classes.name, { [classes.balanceCardLoyaltyName]: isBalanceLoyaltyCard })}
            title={name}
          >
            {name}
          </TextSubTitle>
          {(discount.discountType === 'static' || discount.discountType === 'website') && isBalanceLoyaltyCard ? (
            <span className={classes.date}>{`З ${moment(startDate).format('DD.MM.YY')} до ${moment(endDate).format(
              'DD.MM.YY'
            )}`}</span>
          ) : null}

          {isBalanceLoyaltyCard ? (
            <TextSubTitle className={classes.description} title={name}>
              {getDescription()}
            </TextSubTitle>
          ) : null}
          {isBalanceLoyaltyCard ? (
            <TextSubTitle className={classes.currentDiscountContainer} title={name}>
              <span className={classes.currentDiscountMessage}>{messages.currentDiscount.defaultMessage}</span>
              <span className={classes.percent}>{percentLevel}%</span>
            </TextSubTitle>
          ) : null}
          {!isLoading && !isBalanceLoyaltyCard && (
            <TitleH1 component="span" className={classes.percentLevel}>
              {percentLevel}%
            </TitleH1>
          )}
          {isBalanceLoyaltyCard ? (
            <Link className={classes.link} to={`${baseUrl}/catalog/${defaultCatalogProductType}`}>
              <FlrButtonOutlinedBrown>До каталогу</FlrButtonOutlinedBrown>
            </Link>
          ) : null}
        </div>
      </FlrCard>
    </div>
  );
};

export default DashboardLoyaltyCard;
