import * as React from 'react';
import { withTheme } from '@mui/styles';
import styled from '@emotion/styled/macro';
import { IThemed } from '../../../../utils/styled';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';

export type IPlainInputProps = IThemed &
  InputBaseProps & {
    textColor: string;
    labelColor?: string;
    bg?: string;
    borderColor?: string;
    size?: 'small' | 'medium' | 'large' | 'default';
  } & React.PropsWithChildren<any>;

class FlrInputPlain extends React.Component<IPlainInputProps> {
  public static defaultProps = {
    size: 'medium',
    inputProps: { 'aria-label': '' }
  };

  public render(): React.ReactNode {
    return <ComponentContainer {...this.props} />;
  }
}

const ComponentContainer = styled<any>(InputBase)(
  ({ theme, borderColor, bg, size, textColor }: IThemed & IPlainInputProps) => ({
    '&.MuiInputBase-root': {
      borderColor: borderColor || theme.colors.buttonBorder,
      backgroundColor: bg || theme.colors.background,
      padding: theme.buttonPadding[size || 'medium'],
      color: textColor || theme.colors.brandSecondary,
      '&.Mui-disabled': {
        color: theme.colors.lightGray
      },
      '& .MuiInputBase-input': {
        fontSize: theme.fontSizes[size || 'medium'],
        '&.MuiInputBase-inputAdornedEnd': {
          marginRight: 8
        },
        '&.MuiInputBase-inputAdornedStart': {
          marginLeft: 8
        }
      }
    }
  })
);

export default withTheme(FlrInputPlain);
