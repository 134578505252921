export interface ITestimonial {
  id: string;
  avatar?: string;
  name: string;
  brand: string;
  title: string;
  date: string;
  text: string;
}

export class Testimonial {
  public id: string;
  public avatar: string;
  public name: string;
  public brand: string;
  public title: string;
  public date: string;
  public text: string;

  constructor(testimonial: any) {
    this.id = testimonial.id;
    this.avatar = testimonial.avatar;
    this.name = testimonial.name;
    this.brand = testimonial.brand;
    this.title = testimonial.title;
    this.date = testimonial.date;
    this.text = testimonial.text;
  }

  get dateString() {
    return new Date(this.date).toLocaleDateString();
  }
}
