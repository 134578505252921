import React from "react";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import { get } from "lodash/fp";

import { getLanguageCodeFromLocale } from "./helpers";
import messages from "./translations.json";

// TODO: create dedicated logger to avoid using console.log
const onError = (err: string) => null; // console.log("Error in Intl Provider::", err);

interface IProviderProps {
  children: React.ReactNode;
  locale: string;
}

// Default locale - is a language of user's browser, locale should be passed from user's profile
export const IntlProvider: React.FC<IProviderProps> = ({ children, locale }) => {
  const { language } = navigator;
  const defaultLocale = getLanguageCodeFromLocale(language);

  return (
    <ReactIntlProvider
      locale={locale}
      key={locale}
      defaultLocale={defaultLocale}
      messages={get(locale)(messages) || get(getLanguageCodeFromLocale(locale))(messages)}
      onError={onError}
    >
      {children}
    </ReactIntlProvider>
  );
};
