import { ITheme, defaultMaterialTheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  paper: {
    height: '100%',
    padding: defaultMaterialTheme.spacing(2),
    color: defaultMaterialTheme.palette.text.secondary
  },
  commonControl: {
    marginBottom: theme.spacing(3)
  },
  checkbox: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 5
  },
  dialogContainer: {
    padding: '64px 96px',
    [defaultMaterialTheme.breakpoints.down('md')]: {
      padding: defaultMaterialTheme.spacing(4)
    },
    [defaultMaterialTheme.breakpoints.down('sm')]: {
      padding: defaultMaterialTheme.spacing(2)
    },
    paddingBottom: theme.spacing(0)
  },
  dialogTitleContainer: {
    paddingBottom: theme.spacing(1)
  },
  dialogTitle: {
    margin: 0,
    fontSize: 24
  },
  dialogCaption: {
    marginBottom: theme.spacing(5)
  },
  dialogContent: {
    marginBottom: 0
  },
  dialogActions: {
    width: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  addFormContainer: {
    marginTop: theme.spacing(3),

    // form labels alining
    '& label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      top: -6
    }
  }
});

export default styles;
