import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "account.assist.title",
    description: "",
    defaultMessage: "Допомога"
  },
  titleFaq: {
    id: "account.assistFaq.title",
    description: "",
    defaultMessage: "FAQ: Популярні питання"
  },
  titleSupport: {
    id: "account.assistSupport.title",
    description: "",
    defaultMessage: "Мої звернення"
  }
});
