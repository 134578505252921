import ImageMapBg from '../../../pages/main/assets/map-bg.webp';
import ImageMapBgMobile from '../../../pages/main/assets/map-bg-mobile.webp';

import { homeMaxWidth } from 'shared/constants';

const styles = (theme: any) => ({
  root: {
    maxWidth: homeMaxWidth,
    margin: '0 auto',

    padding: theme.spacing(6),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(8)
  },
  container: {
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {}
  },
  title: {
    color: theme.palette.common.black,

    [theme.breakpoints.up('md')]: {
      fontSize: 48
    }
  },
  formTitle: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    color: theme.palette.common.black
  },
  formSubTitle: {
    marginBottom: theme.spacing(3),
    color: theme.palette.common.helperBlack
  },

  contactsList: {
    marginBottom: theme.spacing(5),

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(6)
    }
  },
  contactsItem: {
    position: 'relative',
    paddingLeft: 40,
    color: theme.palette.text.primary
  },
  contactsItemTitle: {
    marginBottom: theme.spacing(1)
  },
  contactsItemBody: {},
  contactsItemIcon: {
    position: 'absolute',
    left: 0,
    top: theme.spacing(1)
  },
  mapColumn: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(13)
    }
  },
  mapWrapper: {
    backgroundImage: `url(${ImageMapBgMobile})`,
    backgroundPosition: 'top left',
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${ImageMapBg})`
    },
    [theme.breakpoints.up('lg')]: {}
  },

  mapContainer: {
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(4),

    '& > div': {
      overflow: 'hidden',
      height: 180,
      borderRadius: 8,
      ...theme.shadow.cardActive
    },

    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(4.5),
      paddingTop: theme.spacing(9.5),

      '& > div': {
        height: 450
      }
    },
    [theme.breakpoints.up('lg')]: {}
  },

  mapIcon: {
    color: theme.colors.brandPrimary,
    transform: 'translateX(-50%) translateY(-100%)'
  },

  contactForm: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
      margin: '0 auto'
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      marginLeft: 'unset',
      marginRigth: 'unset'
    }
  },
  form: {
    '& label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      top: -5
    }
  },
  input: {
    marginBottom: theme.spacing(2)
  },
  buttonContainer: {
    textAlign: 'right'
  },
  buttonSubmit: {
    '&&': {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingTop: 11,
      paddingBottom: 11,
      borderColor: theme.palette.common.white,
      color: theme.palette.common.white
    }
  }
});

export default styles;
