import { defineMessages } from "react-intl";

export default defineMessages({
  originalLabel: {
    id: "checkout.packageId.original.label",
    description: "checkout.packageId.original.label",
    defaultMessage: "Поворотна тара"
  },
  originalDescription: {
    id: "checkout.packageId.original.description",
    description: "checkout.packageId.original.description",
    defaultMessage: "Ви нічого не сплачуєте, якщо повертаєте тару одразу"
  },
  coldLabel: {
    id: "checkout.packageId.cold.label",
    description: "checkout.packageId.cold.label",
    defaultMessage: "Холодна тара"
  },
  coldDescription: {
    id: "checkout.packageId.cold.description",
    description: "checkout.packageId.cold.description",
    defaultMessage: "За використання треба всеодно заплатити"
  }
});
