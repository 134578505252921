import { FC } from 'react';
import { useLocation } from 'react-router';
import moment from 'moment';

import { SplitedPrice } from 'components/prices';
import { GridDivider, GridDividerBold } from 'components/shared/table/dividers';
import { Link3, TextBody2 } from 'components/shared/text';
import { Order } from 'models';
import { defaultLocale } from 'shared/constants';
import messages from 'translations/account/order';

import { ColorCircle } from '../../../components/shared/product/ColorCircle';
import { indicatorOrderStatus } from './AccountOrdersTableColumns';

import classes from './AccountOrdersTableMobileRowExpanded.module.scss';

interface IProps {
  // component own props
  data: Order;
  classes: any;
}

const prettyDate = (date: Date, withDayOfWeek?: boolean) => {
  const mdate = moment(date);
  return mdate.locale(defaultLocale).format(withDayOfWeek ? 'dd DD.MM.YYYY' : 'DD.MM.YYYY');
};

const AccountOrdersTableMobileRowExpanded: FC<IProps> = ({ data }) => {
  const { pathname } = useLocation();

  return (
    <div className={classes.expandedMobileContainer}>
      <div className={classes.expandedMobileRow}>
        <TextBody2 color="textSecondary">{messages.title.defaultMessage}</TextBody2>
        <div className={classes.wrapper}>
          <TextBody2 align="right">
            <Link3 to={`${pathname}/${data.orderId}`}>№ {data.orderId}</Link3>
          </TextBody2>
          <TextBody2 color="textSecondary" align="right" className={classes.dateValue}>
            {messages.tableStatusFrom.defaultMessage} {prettyDate(data.createdAt)}
          </TextBody2>
        </div>
      </div>
      <GridDivider />
      <div className={classes.expandedMobileRow}>
        <TextBody2 color="textSecondary">{messages.typeOrder.defaultMessage}</TextBody2>
        <TextBody2 color="textPrimary" align="right">
          {data.orderType}
        </TextBody2>
      </div>
      <GridDivider />
      <div className={classes.expandedMobileRow}>
        <TextBody2 color="textSecondary">{messages.tableStatus.defaultMessage}</TextBody2>
        <TextBody2 color="textSecondary" align="right" className={classes.statusWrapper}>
          {data.orderStatus !== 'Нове' ? (
            <ColorCircle color={indicatorOrderStatus(data.orderStatus)} className={classes.colorCircle} />
          ) : null}
          {data.orderStatus}
        </TextBody2>
      </div>
      <GridDivider />
      <div className={classes.expandedMobileRow}>
        <TextBody2 color="textSecondary">{messages.deliveryLabel.defaultMessage}</TextBody2>
        <div className={classes.wrapper}>
          <TextBody2 align="right" className={classes.dateValue}>
            {prettyDate(data.deliveryDate || new Date(), true)}
          </TextBody2>
          <TextBody2 align="right" color="textSecondary">
            {data?.deliveryTime?.name || data?.orderDetails?.selfPickUpTime?.name || ''}
          </TextBody2>
        </div>
      </div>
      <GridDivider />
      <div className={classes.expandedMobileRow}>
        <TextBody2 color="textSecondary">{messages.tableDeliveryType.defaultMessage}</TextBody2>
        <TextBody2 align="right">{data?.orderDetails?.deliveryMethod?.name || '-'}</TextBody2>
      </div>
      <GridDivider />
      <div className={classes.expandedMobileRow}>
        <TextBody2 color="textSecondary">{messages.tableDeliveryAddress.defaultMessage}</TextBody2>
        <div className={classes.wrapper}>
          <TextBody2 align="right">{data?.outlet?.name}</TextBody2>
          <TextBody2 color="textSecondary" align="right">
            {data.outlet!.address}
          </TextBody2>
        </div>
      </div>
      <GridDividerBold />
      <div className={classes.expandedMobileRow}>
        <TextBody2 color="textSecondary">{messages.tableDeliveryCount.defaultMessage}</TextBody2>
        <div className={classes.wrapper}>
          <TextBody2 align="right">
            <SplitedPrice
              fontColor="inherit"
              fontSize="caption"
              bold={false}
              hideDecimal
              value={data.totalQty}
              postfix="шт"
            />
          </TextBody2>
          <TextBody2 color="textSecondary" align="right">
            <SplitedPrice
              fontColor="inherit"
              fontSize="caption"
              bold={false}
              hideDecimal
              value={data.totalPackings}
              postfix="уп."
            />
          </TextBody2>
        </div>
      </div>
      <GridDivider />
      <div className={classes.expandedMobileRow}>
        <TextBody2 color="textSecondary">{messages.tableDeliveryAmount.defaultMessage}</TextBody2>
        <TextBody2 align="right">
          <SplitedPrice fontColor="black" fontSize="caption" decimalSameSize value={data.totalSum} />
        </TextBody2>
      </div>
    </div>
  );
};

export { AccountOrdersTableMobileRowExpanded };
