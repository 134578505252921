import React, { CSSProperties } from 'react';
import { Typography } from '@mui/material';

interface IProps {
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  className?: string;
  style?: CSSProperties;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
}

const TitleH1: React.FC<IProps> = ({ children, component, color, ...rest }) => {
  return (
    <Typography color={color || 'textPrimary'} variant="h1" component={component || 'p'} {...rest}>
      {children}
    </Typography>
  );
};

export default TitleH1;
