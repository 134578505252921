import { devicePanelHeight } from '../../../../shared/constants';

const styles = (theme: any) => ({
  link: {
    padding: '6px 16px',
    width: '100%',

    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  headerDropDown: {
    zIndex: `${theme.zIndex.modal + 1} !important`
  },

  drawerPaper: {
    paddingTop: devicePanelHeight,
    paddingBottom: theme.spacing(2),

    width: '100vw'
  },
  mobileMenuTitle: {
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    color: theme.palette.common.black
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  mobileMenuTopLinks: {
    marginBottom: theme.spacing(2),
    flexWrap: 'nowrap'
  },
  mobileMenuSettings: {
    padding: theme.spacing(2, 0),

    '& .MuiButtonBase-root': {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '14px',
      textTransform: 'unset',
      color: theme.palette.text.primary
    }
  },
  mobileMenuSettingsButton: {},
  mainMenu: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  mainMenuItem: {
    padding: theme.spacing(0.5, 0),
    marginBottom: theme.spacing(1),
    minHeight: 32
  },
  mainMenuItemLogOut: {
    margin: theme.spacing(2, 0)
  },
  mainMenuLink: {
    padding: 0,
    width: '100%',

    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    },
    '&.active': {
      color: theme.palette.primary.main
    }
  },
  subDrawerPaper: {
    borderRadius: theme.spacing(1, 1, 0, 0)
  },
  subDrawerContent: {
    position: 'relative',
    padding: theme.spacing(2)
  },
  subDrawerTitle: {
    marginBottom: theme.spacing(2)
  },
  subDrawerListItem: {
    padding: 0,
    marginBottom: theme.spacing(2),

    '&:first-of-type': {
      marginTop: theme.spacing(2)
    },
    '&:last-of-type': {
      marginBottom: 0
    }
  },
  drawerCheckMark: {
    paddingLeft: 5,
    minWidth: theme.spacing(4),
    color: theme.palette.primary.main
  },
  drawerCheckMarkHidden: {
    opacity: 0
  },
  subDrawerButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2)
  },
  name: {
    '@media(max-width: 1400px)': {
      display: 'none',
    }
  }
});

export default styles;
