import React, { CSSProperties, FC, SyntheticEvent, HTMLAttributes, ElementType, MouseEvent } from 'react';
import { Typography, Link as LinkMUI } from '@mui/material';
import { withStyles } from '@mui/styles';
import { NavLink } from 'react-router-dom';

import { defaultMaterialTheme, ITheme } from 'utils/styled';

interface IProps {
  component?: ElementType<HTMLAttributes<HTMLElement>>;
  color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  classes: any;
  className?: string;
  onClick?: (() => void) | ((ev: SyntheticEvent | MouseEvent) => void);
  style?: CSSProperties;
  href?: string;
  to?: string;
  id?: string;
}

const Link2: FC<IProps> = ({
  children,
  classes,
  href,
  component,
  color,
  className,
  style,
  to = '',
  onClick,
  ...rest
}) => {
  return (
    <Typography
      className={className}
      color={color || 'textPrimary'}
      variant={'inherit'}
      component={component || 'span'}
      style={style}
    >
      {to && !href ? (
        <NavLink to={to} className={classes.link2}>
          {children}
        </NavLink>
      ) : onClick ? (
        <LinkMUI className={classes.link2} onClick={onClick} color={'inherit'} {...rest}>
          {children}
        </LinkMUI>
      ) : (
        <LinkMUI className={classes.link2} href={href} color={'inherit'}>
          {children}
        </LinkMUI>
      )}
    </Typography>
  );
};

const styles = (theme: ITheme) => ({
  link2: {
    ...theme.typography.link2,
    cursor: 'pointer',
    transition: defaultMaterialTheme.transitions.create(['color', 'backgroundColor'], {
      easing: defaultMaterialTheme.transitions.easing.sharp,
      duration: defaultMaterialTheme.transitions.duration.leavingScreen
    }),

    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    },
    '&:active': {
      color: theme.palette.common.primaryHoverPress,
      borderColor: theme.palette.common.primaryHoverPress
    }
  }
});

export default withStyles<any>(styles)(Link2);
