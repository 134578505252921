import * as React from "react";
import Skeleton from '@mui/material/Skeleton';

const HeaderTopSkeleton: React.FC<any> = () => (
  <>
    {[...Array(3)].map((_, i) => (
      <Skeleton
        key={i}
        variant="rectangular"
        width={127}
        height={48}
        style={{ padding: 0, marginLeft: 16, marginBottom: 5, marginTop: 5 }}
      />
    ))}
  </>
);

export default HeaderTopSkeleton;
