import Skeleton from '@mui/material/Skeleton';

import classes from './MiniDetailsSkeleton.module.scss';

const MiniDetailSkeleton = () => {
  return (
    <div className={classes.container}>
      <div className={classes.productDetails}>
        <Skeleton variant="rectangular" className={classes.img} height={190} />
        <div className={classes.infoProduct}>
          <Skeleton variant="rectangular" className={classes.productAttr} height={58} />
          <Skeleton variant="rectangular" className={classes.productAttr} height={58} />
          <Skeleton variant="rectangular" className={classes.productAttr} height={28} />
          <Skeleton variant="rectangular" className={classes.productAttr} height={28} />
        </div>
      </div>
      <div className={classes.content}>
        <Skeleton variant="rectangular" width="100%" height={28} />
        <div className={classes.actions}>
          <Skeleton variant="rectangular" width="30%" height={28} />
          <Skeleton variant="rectangular" width="30%" height={28} />
        </div>
        <div className={classes.card}>
          <Skeleton variant="rectangular" width="100%" height={190} />
        </div>
        <div className={classes.card}>
          <Skeleton variant="rectangular" width="100%" height={190} />
        </div>
        <div className={classes.card}>
          <Skeleton variant="rectangular" width="100%" height={190} />
        </div>
      </div>
    </div>
  );
};

export default MiniDetailSkeleton;
