import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { connect, MapStateToProps, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import clsx from 'clsx';
import moment from 'moment';
import { Button, Hidden } from '@mui/material';

import OrderCardItemDevice from 'components/cards/order/device/OrderCardItem';
import OrderCardDelivery from 'components/cards/order/OrderCardDelivery';
import OrderCardItem from 'components/cards/order/OrderCardItem';
import OrderCardItemHeaders from 'components/cards/order/OrderCardItemHeaders';
import OrderCardSubtotal from 'components/cards/order/OrderCardSubtotal';
import ProductDetailsMobile from 'components/cards/shared/ProductDetailsMobile';
import { IBreadCrumbLink } from 'components/layout/breadcrumbs/Breadcrumbs';
import FlrLoader from 'components/loading/LoadingSpinner';
import Alert from 'components/shared/Alert/Alert';
import FlrCard from 'components/shared/card/FlrCard';
import { ColorCircle } from 'components/shared/product/ColorCircle';
import { Link3, TitleH1, TitleH2 } from 'components/shared/text';
import { Order, ORDER_STATUS_NEW, OrderItem, Product } from 'models';
import { ACCOUNT_OPEN_DETAILS, restoreCartAndRedirect } from 'store/account/actions';
import { getOrderAsync, getOrderReactionAsync } from 'store/order/actions';
import { getOrderReaction, getOrdersList, getOrdersLoadingState, getSelectedOrder } from 'store/order/selectors';
import { fetchOutletsAsync } from 'store/outlets/actions';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/account/order';

import { breadCrumbsContext } from '../breadCrumbsState';
import AccountOrderHeaderSkeleton from './AccountOrderHeaderSkeleton';
import AccountOrderReviewPanel from './AccountOrderReviewPanel';
import { AccountOrderSkeleton } from './AccountOrderSkeleton';
import { indicatorOrderStatus } from './AccountOrdersTableColumns';

import classes from './AccountOrder.module.scss';

interface IParams {
  uuid: string;
  filter: string;
}

interface IProps {
  setRightPanel?: (arg: () => ReactNode) => void;
}

interface IStateProps {
  order: Order | null;
  orders: Order[];
  ordersLoadingState: boolean;
}

interface IDispatchProps {
  restoreCartAndRedirect: typeof restoreCartAndRedirect;
  loadOutlets: typeof fetchOutletsAsync.request;
  getOrder: typeof getOrderAsync.request;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;
const formattedDate = (date?: Date | null) => moment(date).format('HH:mm DD.MM.YYYY');

const AccountOrder: React.FC<IComponentProps> = ({
  setRightPanel,
  order,
  getOrder,
  ordersLoadingState,
  restoreCartAndRedirect
}) => {
  const { uuid, filter } = useParams<IParams>();
  const isOrderLoaded = order && order.orderId === uuid;

  const orderId = order && order.id;
  const feedbackReclamation = order && order.feedbackReclamation;

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const loadOrderTimeoutCallback = useCallback(() => {
    setTimeout(() => {
      if (uuid) {
        getOrder(uuid);
      }
    }, 50);
  }, [getOrder, uuid]);

  const isNeedLoadOrder = !isLoaded && !isOrderLoaded && uuid;
  useEffect(() => {
    if (isNeedLoadOrder) {
      loadOrderTimeoutCallback(); // todo dev
      setIsLoaded(true);
    }
  }, [uuid, isLoaded, isNeedLoadOrder, loadOrderTimeoutCallback]);

  useEffect(() => {
    if (setRightPanel && order?.requestReview) {
      setRightPanel(() => () => (
        <AccountOrderReviewPanel
          uuid={uuid}
          id={orderId}
          feedbackReclamation={feedbackReclamation}
          getOrder={getOrder}
        />
      ));
    }

    return () => {
      if (setRightPanel) {
        setRightPanel(() => null);
      }
    };
  }, [setRightPanel, getOrder, uuid, orderId, feedbackReclamation]);

  const { setCurrentUrl } = useContext(breadCrumbsContext);

  const updateBreadCrumbs = useCallback(() => {
    const breadcrumbsArray: IBreadCrumbLink[] = [{ label: messages.title.defaultMessage, path: '/account/orders' }];
    breadcrumbsArray.push({ label: `${messages.title.defaultMessage} № ${uuid}` });

    setCurrentUrl(breadcrumbsArray);
  }, [setCurrentUrl, filter, uuid]);

  useEffect(() => {
    updateBreadCrumbs();
  }, [updateBreadCrumbs]);

  useEffect(() => {
    updateBreadCrumbs();

    return () => {
      setCurrentUrl([]);
    };
  }, [setCurrentUrl, updateBreadCrumbs]);

  const handleOrderItemsToCart = () => {
    restoreCartAndRedirect(order?.orderId || '');
  };

  // const hasDiscount = order && order.totalDiscount > 0;
  const isNewOrder = order && order.orderStatus === ORDER_STATUS_NEW;

  const dispatch = useDispatch();

  const [selectedProduct, setSelectedProduct] = useState('');
  const [mobileProduct, setMobileProductInfo] = useState<Product | null>(null);

  if (ordersLoadingState) {
    return <FlrLoader size={16} />;
  }
  const orderStatusName = order?.orderDetails?.orderStatus?.name;
  return (
    <div className={classes.container}>
      <TitleH1 className={classes.orderId}>
        {messages.title.defaultMessage} № {uuid}
      </TitleH1>

      {isOrderLoaded && order ? (
        <>
          {order.requestReview ? (
            <Alert
              type={'info'}
              title={messages.reviewAlertTitle.defaultMessage}
              className={classes.outlineMargin}
              action={
                <Link3 onClick={() => dispatch({ type: ACCOUNT_OPEN_DETAILS })} className={classes.link}>
                  {messages.reviewAlertLink.defaultMessage}
                </Link3>
              }
            >
              {messages.reviewAlertBody.defaultMessage}
            </Alert>
          ) : null}
        </>
      ) : (
        <AccountOrderHeaderSkeleton />
      )}
      <FlrCard hoverShadow className={classes.containerTopCard}>
        <div className={classes.item}>
          <span className={classes.label}>{messages.tableStatus.defaultMessage}</span>
          <span className={clsx(classes.value, { [classes.newOrderStatus]: orderStatusName === 'Нове' })}>
            {orderStatusName !== 'Нове' ? <ColorCircle color={indicatorOrderStatus(orderStatusName)} /> : null}
            {orderStatusName}
          </span>
        </div>
        <div className={classes.item}>
          <span className={classes.label}>{messages.type.defaultMessage}</span>
          <span className={classes.value}>{order?.orderDetails?.orderType?.name}</span>
        </div>
        <div className={classes.item}>
          <span className={classes.label}>{messages.orderCreated.defaultMessage}</span>
          <span className={classes.value}>{formattedDate(order?.createdAt)}</span>
        </div>
        <div className={classes.item}>
          <span className={classes.label}>{messages.supplier.defaultMessage}</span>
          <span className={classes.value}>{order?.orderDetails?.supplierName || 'Флоротека'}</span>
        </div>
      </FlrCard>

      <FlrCard className={classes.orderCard} hoverShadow>
        <TitleH2 className={classes.title}>{messages.orderLabel.defaultMessage}</TitleH2>
        {isOrderLoaded && order ? (
          <>
            <OrderCardDelivery order={order} submitted={false} />
            <TitleH2 className={classes.orderLabelProduct}>{messages.orderProduct.defaultMessage}</TitleH2>
            <div className={classes.orderItemsGrid}>
              <Hidden smDown>
                <OrderCardItemHeaders
                  itemsNumber={order.items.length}
                  withItemRemove={Boolean(isNewOrder)}
                  withDiscount
                  isAccount
                />
              </Hidden>
              {order.items && Array.isArray(order.items)
                ? order.items.map((item: OrderItem, index: number) => (
                    <>
                      <Hidden smUp>
                        <OrderCardItemDevice
                          isFirst={index === 0}
                          item={item}
                          removeDisabled
                          withItemRemove={false}
                          isNotEditable
                          withDiscount
                          selected={item.id === selectedProduct}
                          onSelect={() => setSelectedProduct(item.id)}
                          onUnselect={() => setSelectedProduct('')}
                        />
                      </Hidden>
                      <Hidden smDown>
                        <OrderCardItem
                          isFirst={index === 0}
                          item={item}
                          removeDisabled
                          withItemRemove={false}
                          isNotEditable
                          withDiscount
                          isAccount
                        />
                      </Hidden>
                    </>
                  ))
                : null}
              <OrderCardSubtotal order={order} withDiscount isAccount />
            </div>
            <div className={classes.btnWrapper}>
              <Button
                color={'primary'}
                variant={'contained'}
                onClick={handleOrderItemsToCart}
                className={classes.desktopButton}
              >
                {messages.orderAllToCart.defaultMessage} ({order.items.length})
              </Button>
            </div>
          </>
        ) : (
          <AccountOrderSkeleton classes={classes} />
        )}
      </FlrCard>
      <Button
        color={'primary'}
        variant="outlined"
        onClick={handleOrderItemsToCart}
        fullWidth
        className={classes.mobileButton}
      >
        {messages.orderAllToCart.defaultMessage} ({order?.items.length})
      </Button>
      {order?.requestReview ? (
        <Button
          color={'primary'}
          variant="contained"
          onClick={() => dispatch({ type: ACCOUNT_OPEN_DETAILS })}
          fullWidth
          className={classes.mobileButton}
        >
          {messages.reviewAlertLink.defaultMessage}
        </Button>
      ) : null}
      <ProductDetailsMobile onClose={() => setMobileProductInfo(null)} product={mobileProduct} />
    </div>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  order: getSelectedOrder(state),
  orderReaction: getOrderReaction(state),
  orders: getOrdersList(state),
  ordersLoadingState: getOrdersLoadingState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      restoreCartAndRedirect,
      loadOutlets: fetchOutletsAsync.request,
      getOrder: getOrderAsync.request,
      getOrderReaction: getOrderReactionAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountOrder);
