import { IApplicationState } from "store/reducers";
import { IHotOffer } from "./actions";

export const getMain = (state: IApplicationState) => state.main;
export const getTestimonialsData = (state: IApplicationState) => getMain(state).testimonials.data;
export const getFeaturedProductsSectionsLoading = (state: IApplicationState) =>
  getMain(state).featuredProductsSections.loading;
export const getFeaturedProductsSectionsLoaded = (state: IApplicationState) =>
  getMain(state).featuredProductsSections.loaded;
export const getFeaturedProductsSectionsData = (state: IApplicationState) =>
  getMain(state).featuredProductsSections.data;
export const getTopSlidersData = (state: IApplicationState) => getMain(state).topSlider.data;
export const getMainPromoData = (state: IApplicationState) => getMain(state).mainPromo.data;
export const getContactsData = (state: IApplicationState) => getMain(state).contacts.data;

export const getHotOffers = (state: IApplicationState) => getMain(state).hotOfferProducts;
export const getHotOffersLoadingState = (state: IApplicationState) => getHotOffers(state).loading;
export const getHotOffersData = (state: IApplicationState): IHotOffer[] | null => getHotOffers(state).data;

export const getMainMenuData = (state: IApplicationState) => getMain(state).mainMenu.data;
