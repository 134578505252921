import { FC } from 'react';
import moment from 'moment';

import Icon from 'components/shared/Icon';
import { User } from 'models';
import messages from 'translations/account/finance';

import classes from './CardDepositDiscount.module.scss';

interface IProps {
  account: User;
}

const CardDepositDiscount: FC<IProps> = ({ account }) => {
  const personalDiscount =
    (account &&
      account.profile.discount &&
      account.profile.discount.personal &&
      account.profile.discount.personal.percent) ||
    0;

  const nextMonday = moment().day(1 + 7);

  return (
    <div className={classes.cardContent}>
      <Icon type="loyalty" offset={8} />
      <div className={classes.content}>
        <span className={classes.depositDiscountAmount}>{personalDiscount}%</span>
        <div>
          <span className={classes.dateValue}>
            {nextMonday.format('D MMMM')} {messages.depositDiscountRefresh.defaultMessage}
          </span>
          <br />
          <span className={classes.label}>{messages.depositDiscountReminder.defaultMessage}</span>
        </div>
      </div>
    </div>
  );
};

export default CardDepositDiscount;
