import { HttpClient } from 'utils/http';
import { AuthService } from './service';
export class AuthRepository {
  private client = new HttpClient(`${process.env.REACT_APP_API_ENDPOINT}`);
  private service = new AuthService();

  public async check() {
    // we have to be sure token is fresh
    this.client.setAuthHeader();
    return await this.client.get('/auth/check').catch(() => {
      this.service.authToken = null;
    });
  }

  public async login(data: any) {
    return await this.client.post('/auth/login', data);
  }

  public async forgotPassword(data: any) {
    return await this.client.post('/auth/forgot-password', data);
  }
  public async forgotPassword2(data: any) {
    return await this.client.post('/auth/validate-reset-key', {
      resetKey: data.resetKey,
      authId: data.authId,
      token: data.token
    });
  }
  public async changeForgotPassword(data: any) {
    return await this.client.post('/auth/reset-password', {
      resetKey: data.resetKey,
      authId: data.authId,
      token: data.token,
      password: data.password,
      confirmPassword: data.confirmPassword
    });
  }

  public async register(data: any) {
    return await this.client.post('/auth/register', data);
  }
}
