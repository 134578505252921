import { FC } from 'react';
import clsx from 'clsx';
import { isMobileDevice } from 'environment';

import { SplitedPrice } from 'components/prices';
import FlrQuantity from 'components/shared/form-elements/quantity/FlrQuantity';
import Icon from 'components/shared/Icon';
import { GridDivider, GridDividerBold } from 'components/shared/table/dividers';
import { Link3 } from 'components/shared/text';
import { GTM } from 'controllers';
import { OrderItem } from 'models';
import messages from 'translations/cart/common';

import classes from './OrderCardItemData.module.scss';

interface IProps {
  removeDisabled?: boolean;
  max?: number;
  item: OrderItem;
  onChangeAmount?: (qty: number) => void;
  onRemove?: () => void;
  withDiscount?: boolean;
  withItemRemove?: boolean;
  isNotEditable?: boolean;
  isPreOrder?: boolean;
}

const isMobile = Boolean(isMobileDevice(window.navigator));

const OrderItemDataDevice: FC<IProps> = ({
  max,
  item,
  onChangeAmount,
  onRemove,
  withDiscount,
  isNotEditable,
  isPreOrder,
  removeDisabled,
  withItemRemove
}) => {
  const removeHandle = () => {
    if (!removeDisabled && onRemove) {
      GTM.trackNullifyItemFromOrderList(item);
      onRemove();
    }
  };

  const isSpecialOrder = item.special;

  return (
    <div className={classes.container}>
      <GridDivider />
      <div className={classes.params}>
        <span className={classes.paramLabel}>{messages.quantity.defaultMessage}</span>
        <div className={classes.qtyContainer}>
          {isNotEditable ? (
            <FlrQuantity value={item.qty} disableMaxLimit={isNotEditable} disabled />
          ) : (
            <FlrQuantity
              value={item.qty}
              inPackageCount={item.product.inPackageCount}
              min={item.qty === item.product.inPackageCount ? 0 : item.product.inPackageCount}
              onDecrement={item.qty === item.product.inPackageCount ? onRemove : undefined}
              max={max || item.qty}
              onChange={onChangeAmount}
              disableMaxLimit
              hideKeyboard={isMobile}
              isSelect
            />
          )}
        </div>
        {!!withItemRemove && (
          <Link3
            className={clsx(classes.orderItemRemove, { [classes.disabled]: removeDisabled })}
            onClick={removeHandle}
          >
            {messages.reset.defaultMessage}
          </Link3>
        )}
      </div>
      <GridDivider />
      <div className={classes.params}>
        <span className={classes.paramLabel}>{messages.price.defaultMessage}</span>
        {isSpecialOrder ? <Icon opacity={1} type="fire" size={20} style={{ marginRight: 6 }} /> : null}
        <SplitedPrice value={item.price} className={classes.splitedPrice} prefix={isPreOrder ? '~' : ''} />
      </div>
      {withDiscount && (
        <>
          <GridDivider />
          <div className={classes.params}>
            <span className={classes.paramLabel}>{messages.totalDiscount.defaultMessage}</span>
            <SplitedPrice value={item.discountAmount} className={classes.splitedPrice} />
          </div>
        </>
      )}
      <GridDividerBold />
      <div className={classes.params}>
        <span className={classes.sumLabel}>{messages.total.defaultMessage}</span>
        <SplitedPrice value={item.total} className={classes.sumPrice} prefix={isPreOrder ? '~' : ''} />
      </div>
    </div>
  );
};

export default OrderItemDataDevice;
