import React from 'react';
import clsx from 'clsx';
import _find from 'lodash/find';

import FlrSlider from 'components/shared/form-elements/FlrSlider';
import { Link2, TextSubTitle } from 'components/shared/text';
import { IFilterOptions, ProductAttributesDictionary } from 'models';
import { ATTRIBUTE_HEIGHT_PATH } from 'shared/filters';
import { IFilterProp, IFilterProps, IFiltersData } from 'store/catalog/actions';
import { filtersMessages } from 'translations/catalog/filters';

interface IProps {
  filters: IFiltersData;
  setFilters: (newFilters: IFilterProps) => void;
  label: string;
  classes: any;
  filterOptionsData: IFilterOptions;
  postFilteredFilterOptions: Map<any, any>;
}

const HeightRangeOptions: React.FC<IProps> = ({
  label: labelSection,
  classes,
  filterOptionsData,
  postFilteredFilterOptions,
  setFilters,
  filters
}) => {
  // map filters on filterOptions
  const heightFilterOptionsData = filterOptionsData.attributes.find(
    (attrFilter) => attrFilter.code === ProductAttributesDictionary.height
  );
  const opts = ((heightFilterOptionsData && heightFilterOptionsData.options) || [])
    .filter((o) => postFilteredFilterOptions.get('height')?.has(o))
    .map((opt) => parseInt(opt, 10))
    .sort((a, b) => a - b);
  if (opts.length < 2) {
    return <></>;
  }
  const min = opts[0];
  const max = opts[opts.length - 1];

  const filtersFast = filters.fast || [];

  const heightFilter = filtersFast.find((filter) => filter.path === ATTRIBUTE_HEIGHT_PATH);
  const filterValues =
    heightFilter && heightFilter.value ? heightFilter.value.split('-').map((val) => parseInt(val, 10)) : [min, max];

  const arraySelected = filtersFast.filter((item) => item.path === ATTRIBUTE_HEIGHT_PATH);
  const arraySelectedLast = arraySelected && arraySelected.length ? arraySelected[arraySelected.length - 1] : null;

  const handleResetHeightFilters = (ev: React.SyntheticEvent | React.MouseEvent) => {
    if (arraySelectedLast) {
      ev.stopPropagation();
      setFilters(filtersFast.filter((item) => item.path !== ATTRIBUTE_HEIGHT_PATH));
    }
  };

  const marks: any[] = opts.map((opt) => {
    return {
      value: opt,
      label: opt
    };
  });

  const handleHeightRangeChange = (values: number[]) => {
    // reset height filter when min and max values are selected
    if (values[0] === min && values[values.length - 1] === max) {
      setFilters(filtersFast.filter((f) => f.path !== ATTRIBUTE_HEIGHT_PATH));
      return;
    }

    const newFilterPropValue: IFilterProp = {
      rule: 'range',
      path: ATTRIBUTE_HEIGHT_PATH,
      value: `${values[0]}-${values[values.length - 1]}`
    };

    const priceFilterObj = _find(filtersFast, { path: ATTRIBUTE_HEIGHT_PATH });
    if (priceFilterObj) {
      priceFilterObj.value = newFilterPropValue.value;
      priceFilterObj.time = newFilterPropValue.time;
    } else {
      filtersFast.push(newFilterPropValue);
    }

    setFilters(filtersFast);
  };

  return (
    <div className={classes.paddedPanel}>
      <div className={classes.paddedPanelWrapper}>
        <div className={classes.simpleBottom}>
          <TextSubTitle>{labelSection}</TextSubTitle>
          <Link2
            className={clsx(classes.expansionReset, {
              [classes.expansionResetActive]: !!arraySelectedLast
            })}
            onClick={handleResetHeightFilters}
          >
            {filtersMessages.reset.defaultMessage}
          </Link2>
        </div>
        <FlrSlider
          title={filtersMessages.heightFilter.defaultMessage}
          min={min}
          max={max}
          value={filterValues}
          onChange={handleHeightRangeChange}
          withInputs={true}
          marks={marks}
          step={null}
        />
      </div>
    </div>
  );
};

export default HeightRangeOptions;
