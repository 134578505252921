import React, { ReactNode } from 'react';
import clsx from 'clsx';
import MaterialExpansionPanel, { AccordionProps } from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Icon from 'components/shared/Icon';
import { ITheme } from 'utils/styled';

import { Link2 } from './text';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      width: '100%'
    },
    divider: {
      marginLeft: theme.spacing(3),
      marginRight: 10,
      borderTop: `1px solid ${theme.palette.common.lines}`
    },
    panel: {
      border: 'none',
      boxShadow: 'none',
      background: 'none',
      '&.withDivider': {
        marginTop: theme.spacing(1.5),

        '&:before': {
          height: 0
        }
      },
      '&.Mui-disabled': {
        background: 'none !important'
      },
      '&&&': {
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(-1),
        '& >div': {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1)
        }
      }
    },
    summary: {
      minHeight: '0 !important',
      paddingLeft: 0,
      paddingRight: 0,
      height: 40,
      '& .MuiIconButton-root': {
        transition: 'transform 0.5s ease'
      },
      '&.Mui-expanded': {
        margin: 'auto',
        minHeight: 'none !important',
        '& > .MuiIconButton-root': {
          transform: 'rotate(180deg)'
        }
      },
      '&.leftIcon': {
        flexDirection: 'row-reverse'
      }
    },
    leftIcon: {
      marginLeft: 0,
      marginRight: 0,
      padding: 8
    },
    smallIcon: {
      borderRadius: 100
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 0,
      paddingRight: 0
    },
    heading: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 30,
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    customLabel: {
      '&>*': {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '24px',
        borderBottom: 'none',
        letterSpacing: 'normal'
      }
    }
  })
);

interface IProps extends AccordionProps {
  label: ReactNode | undefined;
  actions?: any;
  leftIcon?: boolean;
  withDivider?: boolean;
  className?: string;
  classNameSummary?: string;
  classNameButton?: string;
  classNameDetails?: string;
  classNameLabel?: boolean;
  classNameHeading?: string;
}

export const ExpandPanel: React.FC<IProps> = ({
  label,
  children,
  leftIcon,
  withDivider,
  actions,
  defaultExpanded,
  className,
  classNameSummary,
  classNameButton,
  classNameDetails,
  classNameLabel,
  classNameHeading,
  ...other
}) => {
  const classes = useStyles();
  const hasChildren = React.Children.count(children) > 0;
  const defaultExpandedActual = defaultExpanded !== undefined && defaultExpanded;

  return (
    <div className={clsx(classes.root, className)}>
      {withDivider && <div className={classes.divider} />}
      <MaterialExpansionPanel
        className={clsx(classes.panel, { withDivider })}
        square
        defaultExpanded={defaultExpandedActual && hasChildren}
        disabled={!hasChildren}
        {...other}
      >
        <AccordionSummary
          className={clsx(classes.summary, { leftIcon, [classNameSummary || '']: !!classNameSummary })}
          expandIcon={<Icon type={'arrowExpand'} size={12} opacity={1} />}
          classes={{
            expandIconWrapper: clsx(classes.smallIcon, {
              [classes.leftIcon]: leftIcon,
              [classNameButton || '']: classNameButton
            })
          }}
        >
          {typeof label === 'string' ? (
            <Link2
              onClick={() => null}
              className={clsx(classes.heading, {
                [classNameHeading || '']: !!classNameHeading,
                [classes.customLabel || '']: !!classNameLabel
              })}
            >
              {label}
            </Link2>
          ) : (
            label
          )}
        </AccordionSummary>
        <AccordionDetails className={clsx(classes.details, { [classNameDetails || '']: !!classNameDetails })}>
          {children}
        </AccordionDetails>
        {actions && (
          <>
            <Divider />
            <AccordionActions>{actions}</AccordionActions>
          </>
        )}
      </MaterialExpansionPanel>
    </div>
  );
};
