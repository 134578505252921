import React, { CSSProperties } from "react";
import { Typography, Link as LinkMUI } from "@mui/material";
import { NavLink } from "react-router-dom";
import { withStyles } from "@mui/styles";

import { defaultMaterialTheme, ITheme } from "utils/styled";

interface IProps {
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  color?: "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
  classes: any;
  className?: string;
  onClick?: (() => void) | ((ev: React.SyntheticEvent | React.MouseEvent) => void);
  style?: CSSProperties;
  href?: string;
  to?: string;
}

const Link3: React.FC<IProps> = ({ children, classes, href, component, color, className, style, to = "", onClick }) => {
  return (
    <Typography
      className={className}
      color={color || "textPrimary"}
      variant={"inherit"}
      component={component || "span"}
      style={style}
    >
      {to && !href ? (
        <NavLink to={to} className={classes.link3}>
          {children}
        </NavLink>
      ) : onClick ? (
        <LinkMUI className={classes.link3} onClick={onClick} color={"inherit"}>
          {children}
        </LinkMUI>
      ) : (
        <LinkMUI className={classes.link3} href={href} color={"inherit"}>
          {children}
        </LinkMUI>
      )}
    </Typography>
  );
};

const styles = (theme: ITheme) => ({
  ///////////////
  link3: {
    ...theme.typography.link3,
    transition: defaultMaterialTheme.transitions.create(["color"], {
      easing: defaultMaterialTheme.transitions.easing.sharp,
      duration: defaultMaterialTheme.transitions.duration.leavingScreen
    }),

    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    },
    "&:active": {
      color: theme.palette.common.primaryHoverPress
    }
  }
});

export default withStyles<any>(styles)(Link3);
