import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "account.settings.title",
    description: "",
    defaultMessage: "Налаштування профіля"
  },
  personalData: {
    id: "account.settings.personalData",
    description: "",
    defaultMessage: "Особисті дані"
  },
  personalDataSubtitle: {
    id: "account.settings.personalDataSubtitle",
    description: "account.settings.personalDataSubtitle",
    defaultMessage: "Основна інформація"
  },
  personalDataSubtitleAdditional: {
    id: "account.settings.personalDataSubtitleAdditional",
    description: "account.settings.personalDataSubtitle",
    defaultMessage: "Додаткова інформація"
  },
  socialTitle: {
    id: "account.settings.socialTitle",
    description: "account.settings.socialTitle",
    defaultMessage: "Прив’язані профілі соціальних мереж"
  },
  socialNotConnected: {
    id: "account.settings.socialNotConnected",
    description: "account.settings.socialNotConnected",
    defaultMessage: "Не прив’язано"
  },
  socialConnect: {
    id: "account.settings.socialConnect",
    description: "account.settings.socialConnect",
    defaultMessage: "Прив’язати"
  },
  notificationTitle: {
    id: "account.settings.notificationTitle",
    description: "account.settings.notificationTitle",
    defaultMessage: "Отримувати повідомлення в наступних меседжерах"
  },
  personalDataSubtitleCaption: {
    id: "account.settings.personalDataSubtitleCaption",
    description: "account.settings.personalDataSubtitleCaption",
    defaultMessage: "Прив’язані профілі соціальних мереж"
  },
  businessData: {
    id: "account.settings.businessData",
    description: "",
    defaultMessage: "Юридичні дані"
  },
  addressTitle: {
    id: "account.settings.addressTitle",
    description: "",
    defaultMessage: "Адреси призначення"
  },
  addressSubtitle: {
    id: "account.settings.addressSubtitle",
    description: "",
    defaultMessage: "Додайте адреси своїх магазинів, щоб швидше оформлювати доставку"
  },
  addOutletTitle: {
    id: "account.settings.outlet.addOutletTitle",
    description: "",
    defaultMessage: "+ Додати пункт призначення"
  },
  name: {
    id: "account.settings.outlet.name",
    description: "account.settings.outlet.name",
    defaultMessage: "Назва"
  },
  region: {
    id: "account.settings.outlet.region",
    description: "account.settings.outlet.region",
    defaultMessage: "Область"
  },
  city: {
    id: "account.settings.outlet.city",
    description: "account.settings.outlet.city",
    defaultMessage: "Місто"
  },
  street: {
    id: "account.settings.outlet.street",
    description: "account.settings.outlet.street",
    defaultMessage: "Вулиця"
  },
  building: {
    id: "account.settings.outlet.building",
    description: "account.settings.outlet.building",
    defaultMessage: "Будинок"
  },
  unit: {
    id: "account.settings.outlet.unit",
    description: "account.settings.outlet.unit",
    defaultMessage: "Корпус"
  },
  room: {
    id: "account.settings.outlet.room",
    description: "account.settings.outlet.room",
    defaultMessage: "Квартира"
  },
  phone: {
    id: "account.settings.outlet.phone",
    description: "account.settings.outlet.phone",
    defaultMessage: "Телефон"
  },
  addPhone2: {
    id: "account.settings.addPhone2",
    description: "account.settings.addPhone2",
    defaultMessage: "+ Додати номер телефону"
  },
  fullName: {
    id: "account.settings.fullName",
    description: "account.settings.fullName",
    defaultMessage: "ПІБ"
  },
  companyName: {
    id: "account.settings.companyName",
    description: "account.settings.companyName",
    defaultMessage: "Назва Компанії"
  },
  address: {
    id: "account.settings.fullName",
    description: "account.settings.fullName",
    defaultMessage: "Адреса"
  },
  destinationCity: {
    id: "account.settings.city",
    description: "account.settings.city",
    defaultMessage: "Місто (для визначення терміну доставки)"
  },
  language: {
    id: "account.settings.language",
    description: "account.settings.language",
    defaultMessage: "Мова спілкування з “Флоротека”"
  },
  currency: {
    id: "account.settings.currency",
    description: "account.settings.currency",
    defaultMessage: "Валюта"
  },
  passwordChangeTitle: {
    id: "account.settings.passwordChangeTitle",
    description: "account.settings.passwordChangeTitle",
    defaultMessage: "Змінити пароль"
  },
  changePassword: {
    id: "account.settings.changePassword",
    description: "account.settings.changePassword",
    defaultMessage: "Змінити пароль"
  },
  oldPasswordLabel: {
    id: "account.settings.oldPasswordLabel",
    description: "account.settings.oldPasswordLabel",
    defaultMessage: "Старий пароль"
  },
  passwordLabel: {
    id: "account.settings.passwordLabel",
    description: "account.settings.passwordLabel",
    defaultMessage: "Новий пароль"
  },
  passwordConfirmationLabel: {
    id: "account.settings.passwordConfirmationLabel",
    description: "account.settings.passwordConfirmationLabel",
    defaultMessage: "Підтвердження пароля"
  },
  passwordErrorWrong: {
    id: "account.settings.passwordErrorWrong",
    description: "account.settings.passwordErrorWrong",
    defaultMessage: "Поточний пароль невірний"
  },
  passwordChanged: {
    id: "account.settings.passwordChanged",
    description: "account.settings.passwordChanged",
    defaultMessage: "Пароль успішно змінений"
  },
  passwordMismatch: {
    id: "account.settings.passwordMismatch",
    description: "account.settings.passwordMismatch",
    defaultMessage: "Паролі не співпадають"
  },
  passwordChangeSubmit: {
    id: "account.settings.passwordChangeSubmit",
    description: "account.settings.passwordChangeSubmit",
    defaultMessage: "Змінити пароль"
  },
  addOutletBtnLabel: {
    id: "account.settings.outlet.addOutletBtnLabel",
    description: "account.settings.outlet.addOutletBtnLabel",
    defaultMessage: "Додати"
  },
  saveOutletBtnLabel: {
    id: "account.settings.outlet.saveOutletBtnLabel",
    description: "account.settings.outlet.saveOutletBtnLabel",
    defaultMessage: "Зберегти"
  },
  cancelOutletBtnLabel: {
    id: "account.settings.outlet.cancelOutletBtnLabel",
    description: "account.settings.outlet.cancelOutletBtnLabel",
    defaultMessage: "Скасувати"
  },
  editBtnLabel: {
    id: "account.settings.outlet.editBtnLabel",
    description: "account.settings.outlet.editBtnLabel",
    defaultMessage: "Редагувати"
  },
  outletAdded: {
    id: "notifications.outletAdded",
    description: "",
    defaultMessage: "Пункт призначення доданий"
  },
  outletRemoved: {
    id: "notifications.outletAdded",
    description: "",
    defaultMessage: "Пункт призначення видалений"
  },
  outletSaved: {
    id: "notifications.outletAdded",
    description: "",
    defaultMessage: "Пункт призначення збережений"
  },
  saveBtnLabel: {
    id: "account.settings.outlet.saveBtnLabel",
    description: "account.settings.outlet.saveBtnLabel",
    defaultMessage: "Зберегти"
  },
  deleteBtnLabel: {
    id: "account.settings.outlet.deleteBtnLabel",
    description: "account.settings.outlet.deleteBtnLabel",
    defaultMessage: "Видалити"
  },
  cancelBtnLabel: {
    id: "account.settings.outlet.cancelBtnLabel",
    description: "account.settings.outlet.cancelBtnLabel",
    defaultMessage: "Скасувати"
  },
  saveChangesConfirmTitle: {
    id: "account.settings.outlet.saveChangesConfirmTitle",
    description: "account.settings.outlet.saveChangesConfirmTitle",
    defaultMessage: "Зберегти зміни?"
  },
  saveChangesConfirmText: {
    id: "account.settings.outlet.continueChanges",
    description: "account.settings.outlet.continueChanges",
    defaultMessage: `Ви маєте незбережені зміни в “{dataType}”.
    Продовжити без їх збереження?`
  },
  saveChangesConfirmContinue: {
    id: "account.settings.outlet.continueChanges",
    description: "account.settings.outlet.continueChanges",
    defaultMessage: "Продовжити редагування"
  },
  editButton: {
    id: "account.settings.editButton",
    description: "account.settings.editButton",
    defaultMessage: "Редагувати"
  },
  addAddressButton: {
    id: "account.settings.addAddressButton",
    description: "account.settings.addAddressButton",
    defaultMessage: "додати адресу"
  },
  addOutletModalTitle: {
    id: "account.settings.outlet.addOutletModalTitle",
    description: "account.settings.outlet.addOutletModalTitle",
    defaultMessage: "Новий пункт призначення"
  },
  editOutletModalTitle: {
    id: "account.settings.outlet.editOutletModalTitle",
    description: "account.settings.outlet.editOutletModalTitle",
    defaultMessage: "Редагувати пункт призначення"
  },
  deleteOutletModalTitle: {
    id: "account.settings.outlet.deleteOutletModalTitle",
    description: "account.settings.outlet.deleteOutletModalTitle",
    defaultMessage: "Видалити пункт призначення"
  },
  deleteOutletModalText: {
    id: "account.settings.outlet.deleteOutletModalTitle",
    description: "account.settings.outlet.deleteOutletModalTitle",
    defaultMessage: `Ви дійсно бажаєте видалити пункт призначення "{name}" ?`
  },
  addOutletCaption: {
    id: "account.settings.outlet.addOutletCaption",
    description: "account.settings.outlet.addOutletCaption",
    defaultMessage: "Додайте свій магазин або іншу адресу, щоб швидко обирати"
  },
  verificationInProgress: {
    id: "account.settings.verificationInProgress",
    description: "account.settings.verificationInProgress",
    defaultMessage: "Триває верифікація"
  },
  verificationDocsSuccess: {
    id: "account.settings.verificationSuccess",
    description: "account.settings.verificationSuccess",
    defaultMessage: "Документи верифіковані"
  },
  notificationSuccessUpdated: {
    id: "account.settings.notification.successUpdated",
    description: "account.settings.notification.successUpdated",
    defaultMessage: "Успішно оновлено"
  },
  deliveryRegion: {
    id: "account.settings.deliveryRegion",
    description: "account.settings.deliveryRegion",
    defaultMessage: "Регіон"
  }
});
