import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { INotification, removeSnackbar } from "store/layout";
import { getNotificationsState } from "store/layout/selectors";
import SnackbarWithTitle from "./SnackbarWithTitle";

let displayed: Array<string | number> = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotificationsState);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: string | number) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: string | number) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({ key, message, title, options = {}, dismissed = false }: INotification) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (key && displayed.includes(key)) {
        return;
      }

      // display snackbar using notistack
      enqueueSnackbar(title ? <SnackbarWithTitle title={title}>{message}</SnackbarWithTitle> : message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        }
      });

      // keep track of snackbars that we've displayed
      if (key) {
        storeDisplayed(key);
      }
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
