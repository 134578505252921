import styled from '@emotion/styled/macro';
import { IThemed } from 'utils/styled';
import { Box } from '@mui/material';

const FlrQuantityWrapper = styled<any>(Box)(({ theme }: IThemed) => ({
  '& .MuiAutocomplete-option:active': {
    backgroundColor: theme.palette.common.primaryClickablePressBackground
  },
  '& .MuiAutocomplete-popupIndicator': {
    transition: 'transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',

    '&:active': {
      backgroundColor: 'transparent'
    }
  },

  '& .MuiFormControl-root': {
    minWidth: 110,
    backgroundColor: '#fff',
    borderRadius: 8,

    '& .MuiAutocomplete-inputRoot': {
      marginBottom: 0
    }
  },
  '& .FlrQuantity-ItemText': {
    marginBottom: 0
  },
  '& .MuiOutlinedInput-input': {
    paddingTop: 11,
    paddingBottom: 11
  },
  '& .button-decrease': {
    marginRight: 12
  },
  '& .button-increase': {
    marginLeft: 12
  },
  '& .button-decrease, .button-increase': {
    position: 'relative',
    padding: 0,
    minWidth: 'initial',
    width: 24,
    height: 24
  },
  '& .button-decrease:disabled::before, .button-increase:disabled::before, .button-increase:disabled::after': {
    backgroundColor: theme.palette.common.disabledBlack
  },

  '& .button-decrease::before, .button-increase::before': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    content: "''",
    width: 12,
    height: 2,
    backgroundColor: theme.palette.text.secondary,
    transform: 'translate(-50%, -50%)'
  },
  '& .button-increase::after': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    content: "''",
    width: 2,
    height: 12,
    backgroundColor: theme.palette.text.secondary,
    transform: 'translate(-50%, -50%)'
  },

  'input.quantity-input': {
    paddingTop: 8,
    paddingBottom: 8,
    height: 19
  },

  'input.quantity-input::-webkit-outer-spin-button, input.quantity-input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  },

  /* Firefox */
  'input.quantity-input[type="number"]': {
    '-moz-appearance': 'textfield',
    textAlign: 'right'
  },
}));

export { FlrQuantityWrapper };
