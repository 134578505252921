import React from "react";
import Icon from "../Icon";
import { withStyles } from "@mui/styles";

import styles from "./AlertIcon.styles";
import clsx from "clsx";

export interface IProps {
  classes?: any;
  type?: string;
  children?: any;
}

const AlertIconError: React.FC<IProps> = ({ classes, type = "block" }) => {
  return (
    <span className={clsx(classes.common, classes.error)}>
      <Icon size={24} type={type} opacity={1} />
    </span>
  );
};

export default withStyles<any>(styles)(AlertIconError);
