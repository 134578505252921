import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { fetchSeoSettings } from 'store/seo/actions';
import { getSeoSettings } from 'store/seo/selectors';
import { ISeoSettings } from 'models/seoSettings';

export const CanonicalLinks = () => {
  const dispatch = useDispatch();
  const seoSettings = useSelector(getSeoSettings);
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchSeoSettings());
    // eslint-disable-next-line
  }, []);

  const seoSettingsMap: ISeoSettings | undefined =
    seoSettings && seoSettings.length ? seoSettings.find(s => s.url === location.pathname) : undefined;

  let seoMetaTitle: string | undefined = '';
  let seoMetaDesc: string | undefined = '';
  // exclude catalog page for now
  if (!location.pathname.includes('/catalog')) {
    seoMetaTitle = seoSettingsMap?.metaTitle;
    seoMetaDesc = seoSettingsMap?.metaDesc;
  }

  return (
    <Helmet>
      {seoMetaTitle ? <title>{seoMetaTitle}</title> : null}
      {seoMetaDesc ? <meta name="description" content={seoMetaDesc}></meta> : null}
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
};
