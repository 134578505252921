import React, { useState, useEffect, useRef, FC, ChangeEvent } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack';

import env from 'environment';
import { AuthService } from 'store/auth/service';
import messages from 'translations/account/finance';
import { getUserAccount } from 'store/account/selectors';

import useStyles from './styles';
import SnackbarContent from 'components/layout/notistack-provider/SnackbarWithTitle';
import FlrSelect2, { ISelectOption } from 'components/shared/form-elements/select2/FlrSelect2';
import FlrDialogTitle from '../components/DialogTitle';
import { CreditPaymentContent } from './CreditPaymentContent';
import { LiqpayPaymentContent } from './LiqpayPaymentContent';

interface IProps {
  open: boolean;
  handleClose: () => void;
  amount?: number;
  onSuccess?: () => void;
}

type IComponentProps = IProps;

export const AMOUNT_TYPES = {
  recommended: 'recommended',
  credit: 'credit',
  custom: 'custom'
};

enum PAYMENT_TYPES {
  cash = 'cash',
  liqpay = 'liqpay',
  credit = 'credit'
}

const PAYMENT_TYPES_LABELS = {
  [PAYMENT_TYPES.cash]: messages.balanceModalCashType.defaultMessage,
  [PAYMENT_TYPES.liqpay]: messages.balanceModalLiqpayType.defaultMessage,
  [PAYMENT_TYPES.credit]: messages.balanceModalCreditType.defaultMessage
};

const PaymentModal: FC<IComponentProps> = ({ open, handleClose, amount = 0, onSuccess }) => {
  const account = useSelector(getUserAccount);
  const classes = useStyles();

  const availablePaymentOptions: ISelectOption[] =
    account && account.preferences.paymentMethods
      ? Object.entries(account.preferences.paymentMethods)
          .map(([type, enabled]) => enabled && { label: PAYMENT_TYPES_LABELS[type], value: type })
          .filter(Boolean)
      : [];

  if (!availablePaymentOptions.length) {
    availablePaymentOptions.push({ label: PAYMENT_TYPES_LABELS.credit, value: PAYMENT_TYPES.credit });
  }

  const [paymentType, setPaymentType] = useState(availablePaymentOptions[0].value);
  const { enqueueSnackbar } = useSnackbar();
  const [amountType, setAmountType] = useState(amount ? AMOUNT_TYPES.recommended : AMOUNT_TYPES.custom);
  const [customAmount, setCustomAmount] = useState(`${amount}`);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCustomAmount(value);
  };

  const handlePaymentTypeChange = (event: any) => {
    setPaymentType(event.target.value);
  };

  const creditUsed = account && account.profile.balance < 0 ? -1 * account.profile.balance : 0;
  const recommendedAmount =
    account && (account.profile.balance || account.profile.balance === 0) ? amount - account.profile.balance : 0;
  const amountMinimal = account && account.profile.availableFunds ? amount - account.profile.availableFunds : 0;

  const [payData, setPayData] = useState('');
  const [paySignature, setPaySignature] = useState('');
  const [getLinkPending, setGetLinkPending] = useState<boolean>(false);
  const payForm = useRef<HTMLFormElement>(null);
  useEffect(() => {
    if (payData && paySignature && payForm.current && payForm.current.submit) {
      setGetLinkPending(false);
      payForm.current.submit();
    }
  }, [payData, paySignature]);
  const handleGetPayLink = () => {
    const amountResult =
      amountType === AMOUNT_TYPES.recommended
        ? recommendedAmount
        : amountType === AMOUNT_TYPES.credit
        ? amountMinimal
        : customAmount;

    const urlMock = `${env.apiUrl}/account/pay/request`;
    const service = new AuthService();

    setGetLinkPending(true);
    axios
      .post(
        `${urlMock}`,
        {
          amount: String(amountResult),
          redirectUrl: window.location.href,
          paymentType
        },
        {
          headers: {
            Authorization: service.authToken
          }
        }
      )
      .then(response => {
        setPaySignature(response.data.signature);
        setPayData(response.data.data);
        if (handleClose) {
          handleClose();
        }
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(e => {
        setGetLinkPending(false);
        enqueueSnackbar(
          <SnackbarContent title={messages.creditLineRequestErrorTitle.defaultMessage}>
            {messages.creditLineRequestError.defaultMessage}
          </SnackbarContent>,
          { variant: 'error' }
        );
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll={'body'}
      classes={{
        paperScrollBody: classes.dialogContainer
      }}
    >
      <FlrDialogTitle text={messages.balanceModalTitle} />

      <div className={classes.paymentSelectWrapper}>
        <FlrSelect2
          className={classes.paymentSelect}
          label={messages.balanceModalPaymentType.defaultMessage}
          disableWrapper
          value={paymentType}
          options={availablePaymentOptions}
          onChange={handlePaymentTypeChange}
        />
      </div>

      {paymentType === PAYMENT_TYPES.credit ? <CreditPaymentContent /> : null}

      {paymentType === PAYMENT_TYPES.liqpay ? (
        <LiqpayPaymentContent
          amountMinimal={amountMinimal}
          amountType={amountType}
          creditUsed={creditUsed}
          customAmount={customAmount}
          handleChange={handleChange}
          recommendedAmount={recommendedAmount}
          setAmountType={setAmountType}
        />
      ) : null}

      <DialogActions>
        <Button variant={'outlined'} color={'primary'} onClick={handleClose}>
          {messages.cancelBtnLabel.defaultMessage}
        </Button>
        <Button disabled={getLinkPending} variant={'contained'} color={'primary'} onClick={handleGetPayLink}>
          {paymentType === PAYMENT_TYPES.credit ? messages.topUpSave.defaultMessage : messages.topUp.defaultMessage}
        </Button>
      </DialogActions>

      <form ref={payForm} method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8">
        <input type="hidden" name="data" value={payData} />
        <input type="hidden" name="signature" value={paySignature} />
      </form>
    </Dialog>
  );
};

export default PaymentModal;
