import { FC } from 'react';
import { Element as ScrollBlock } from 'react-scroll';
import clsx from 'clsx';

import Alert from 'components/shared/Alert/Alert';
import FlrCard from 'components/shared/card/FlrCard';
import { TextCaption, TitleH2 } from 'components/shared/text';
import { Cart, CartItem, Order, ORDER_TYPE_PREORDER, OrderItem } from 'models';
import { ORDER_STATUS_NEW } from 'models/order';
import messages from 'translations/checkout/common';

import OrderCardDelivery from '../OrderCardDelivery';
import OrderCardSubtotal from '../OrderCardSubtotal';
import OrderCardItemDevice from './OrderCardItem';

import classes from './OrderCardDevice.module.scss';

interface IProps {
  checkoutGroup: string;
  cart: Cart;
  order: Order;
  orderIndex: number;
  orderIndexShift: number;
  anchor: string;
  selectedOrderItemId: string;
  onQtyUpdate: (item: OrderItem, newQty: number) => void;
  onDeliveryUpdate: (item: Order) => void;
  onRemoveItem: (item: OrderItem) => void;
  onSelectOrderItem: (itemId: string) => void;
  onUnselectOrderItem: () => void;
  submitted: boolean;
}

const OrderCardDevice: FC<IProps> = ({
  checkoutGroup,
  cart,
  order,
  orderIndex,
  orderIndexShift,
  anchor,
  selectedOrderItemId,
  onRemoveItem,
  onSelectOrderItem,
  onUnselectOrderItem,
  onQtyUpdate,
  onDeliveryUpdate,
  submitted
}) => {
  const isPreOrder = order.orderType === ORDER_TYPE_PREORDER;
  const isNewOrder = order.orderStatus === ORDER_STATUS_NEW;
  const disableRemove = order.items.length === 1; // order.totalQty > 1 && order.items.length > 1);
  const getGroupItemQty = (offerId: string) => {
    const group = cart.groups[checkoutGroup];
    if (group) {
      const item = group.items.find((cartItem: CartItem) => cartItem.offer.id === offerId);
      if (item) {
        return item.qty;
      }
    }
    return 0;
  };

  return (
    <ScrollBlock
      name={`${anchor}-${order.id}`}
      className={clsx(`${anchor}-item`, classes.orderCard, classes.orderCardActual)}
    >
      <FlrCard id={order.id}>
        <div className={classes.headerWrapper}>
          {orderIndex && orderIndex >= 0 && (
            <TextCaption className={classes.deliveryOrder}>
              {messages.delivery.defaultMessage} &ndash; {orderIndex + orderIndexShift}
            </TextCaption>
          )}
          <TitleH2 className={classes.deliveryOutlet}>{order.outlet && order.outlet.name}&nbsp;</TitleH2>
        </div>
        <OrderCardDelivery order={order} onDeliveryChange={onDeliveryUpdate} submitted={submitted} />
        <Alert type={'info'} title={messages.alertSplitOrder.defaultMessage} className={classes.outlineMargin}>
          {messages.alertSplitOrderText.defaultMessage}
        </Alert>
        <div className={classes.container}>
          {order.items && order.items.length
            ? order.items.map((item: OrderItem, index: number) => (
                <OrderCardItemDevice
                  isPreOrder={isPreOrder}
                  isFirst={index === 0}
                  max={getGroupItemQty(item.offerId)}
                  key={item.id}
                  item={item}
                  removeDisabled={disableRemove}
                  withItemRemove={isNewOrder}
                  selected={item.offerId === selectedOrderItemId}
                  onChangeAmount={(qty: number) => onQtyUpdate(item, qty)}
                  onSelect={() => onSelectOrderItem(item.offerId)}
                  onUnselect={() => onUnselectOrderItem()}
                  onRemove={() => onRemoveItem(item)}
                  isNotEditable={item.special}
                  withDiscount
                />
              ))
            : null}
          <OrderCardSubtotal
            order={order}
            index={orderIndex}
            withCommentEdit={true}
            withDiscount
          />
        </div>
      </FlrCard>
    </ScrollBlock>
  );
};

export default OrderCardDevice;
