export interface IOrderReaction {
  id: string;
  ratingQuality: number;
  ratingManager: number;
  ratingDelivery: number;
  comment: string;
  reclamation?: string;
  images?: string[];
}

export class OrderReaction {
  public id: string;
  public ratingQuality: number;
  public ratingManager: number;
  public ratingDelivery: number;
  public comment: string;
  public reclamation?: string;
  public images?: string[];

  constructor(orderReaction: any) {
    this.id = orderReaction.id;
    this.comment = orderReaction.comment;
    this.ratingQuality = orderReaction.ratingQuality || 0;
    this.ratingManager = orderReaction.ratingManager || 0;
    this.ratingDelivery = orderReaction.ratingDelivery || 0;

    this.reclamation = orderReaction.reclamation;
    this.images = orderReaction.images;
  }
}
