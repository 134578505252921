import { createAsyncAction, createReducer, PayloadAction } from 'typesafe-actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { loadedDataWrapper, REQUEST_ACTIONS, loadingDataWrapper } from 'store/actions';
import { MainRepository } from './request';

import { Product, DetailedProduct, Testimonial, MainPromo, MainTopSlide, FeaturedProductsSection } from 'models';
import { mainPromoMock } from '../../mocks/mainPage';

export const prefix = '@@main/';

export const FEATURED_PRODUCTS_SECTIONS_REQUEST = `${prefix}FEATURED_PRODUCTS_SECTIONS_${REQUEST_ACTIONS.REQUEST}`;
export const FEATURED_PRODUCTS_SECTIONS_SUCCESS = `${prefix}FEATURED_PRODUCTS_SECTIONS_${REQUEST_ACTIONS.SUCCESS}`;
export const FEATURED_PRODUCTS_SECTIONS_FAILURE = `${prefix}FEATURED_PRODUCTS_SECTIONS_${REQUEST_ACTIONS.FAILURE}`;

export const MAIN_MENU_REQUEST = `${prefix}MAIN_MENU_${REQUEST_ACTIONS.REQUEST}`;
export const MAIN_MENU_SUCCESS = `${prefix}MAIN_MENU_${REQUEST_ACTIONS.SUCCESS}`;
export const MAIN_MENU_FAILURE = `${prefix}MAIN_MENU_${REQUEST_ACTIONS.FAILURE}`;

export const SETTINGS_REQUEST = `${prefix}SETTINGS_${REQUEST_ACTIONS.REQUEST}`;
export const SETTINGS_SUCCESS = `${prefix}SETTINGS_${REQUEST_ACTIONS.SUCCESS}`;
export const SETTINGS_FAILURE = `${prefix}SETTINGS_${REQUEST_ACTIONS.FAILURE}`;

export const MAIN_TOP_SLIDER_REQUEST = `${prefix}MAIN_TOP_SLIDER_${REQUEST_ACTIONS.REQUEST}`;
export const MAIN_TOP_SLIDER_SUCCESS = `${prefix}MAIN_TOP_SLIDER_${REQUEST_ACTIONS.SUCCESS}`;
export const MAIN_TOP_SLIDER_FAILURE = `${prefix}MAIN_TOP_SLIDER_${REQUEST_ACTIONS.FAILURE}`;

export const PROMO_REQUEST = `${prefix}PROMO_${REQUEST_ACTIONS.REQUEST}`;
export const PROMO_SUCCESS = `${prefix}PROMO_${REQUEST_ACTIONS.SUCCESS}`;
export const PROMO_FAILURE = `${prefix}PROMO_${REQUEST_ACTIONS.FAILURE}`;

export const TESTIMONIALS_REQUEST = `${prefix}TESTIMONIALS_${REQUEST_ACTIONS.REQUEST}`;
export const TESTIMONIALS_SUCCESS = `${prefix}TESTIMONIALS_${REQUEST_ACTIONS.SUCCESS}`;
export const TESTIMONIALS_FAILURE = `${prefix}TESTIMONIALS_${REQUEST_ACTIONS.FAILURE}`;

export const MAIN_HOT_OFFER_REQUEST = `${prefix}HOT_OFFER_${REQUEST_ACTIONS.REQUEST}`;
export const MAIN_HOT_OFFER_SUCCESS = `${prefix}HOT_OFFER_${REQUEST_ACTIONS.SUCCESS}`;
export const MAIN_HOT_OFFER_FAILURE = `${prefix}HOT_OFFER_${REQUEST_ACTIONS.FAILURE}`;

export interface IHotOffer {
  product: Product;
  enabled: boolean;
  _id: string;
  header: string;
  color: string;
  expiredTime: string;
  image: string;
}

export interface IMain {
  featuredProductsSections: IFeaturedProductsSections;
  contacts: IMainContacts;
  topSlider: IMainTopSliderData;
  mainPromo: IPromoData;
  testimonials: ITestimonialData;
  hotOfferProducts: IHotOfferProducts;
  mainMenu: IMainMenu;
}

export interface IMainTopSliderData {
  data: MainTopSlide[];
  loaded: boolean;
  loading: boolean;
  error: boolean;
}
export interface IFeaturedProductsSections {
  data: FeaturedProductsSection[] | null;
  loaded: boolean;
  loading: boolean;
  error: boolean;
}
export interface IPromoData {
  data: MainPromo | null;
  loaded: boolean;
  loading: boolean;
  error: boolean;
}
export interface ITestimonialData {
  data: Testimonial[];
  loaded: boolean;
  loading: boolean;
  error: boolean;
}
export interface IHotOfferProducts {
  data: null | IHotOffer[];
  loaded: boolean;
  loading: boolean;
  error: boolean;
}
export interface IMainMenu {
  data: null | any;
  loaded: boolean;
  loading: boolean;
  error: boolean;
}

export interface IMainContactsData {
  phones?: any;
  address: any;
  workHours: any;
  advantages?: any;
}
export interface IMainContacts {
  data: IMainContactsData | null;
  loaded: boolean;
  loading: boolean;
  error: boolean;
}

export type IMainState = IMain;

const httpClient = new MainRepository();

export const mainInitialState: IMainState = {
  featuredProductsSections: { data: null, loaded: false, loading: false, error: false },
  contacts: { data: null, loaded: false, loading: false, error: false },
  topSlider: { data: [], loaded: false, loading: false, error: false },
  mainPromo: { data: null, loaded: false, loading: false, error: false },
  testimonials: { data: [], loaded: false, loading: false, error: false },
  hotOfferProducts: { data: null, loaded: false, loading: false, error: false },
  mainMenu: { data: null, loaded: false, loading: false, error: false }
};

export const mainMenuAsync = createAsyncAction(MAIN_MENU_REQUEST, MAIN_MENU_SUCCESS, MAIN_MENU_FAILURE)<
  void,
  any,
  Error
>();

export const mainSettingsAsync = createAsyncAction(SETTINGS_REQUEST, SETTINGS_SUCCESS, SETTINGS_FAILURE)<
  void,
  any,
  Error
>();

export const mainTopSlidersAsync = createAsyncAction(
  MAIN_TOP_SLIDER_REQUEST,
  MAIN_TOP_SLIDER_SUCCESS,
  MAIN_TOP_SLIDER_FAILURE
)<void, any, Error>();

export const mainPromoAsync = createAsyncAction(PROMO_REQUEST, PROMO_SUCCESS, PROMO_FAILURE)<void, any, Error>();

export const mainTestimonialsAsync = createAsyncAction(
  TESTIMONIALS_REQUEST,
  TESTIMONIALS_SUCCESS,
  TESTIMONIALS_FAILURE
)<void, any, Error>();

export const hotOfferProductsAsync = createAsyncAction(
  MAIN_HOT_OFFER_REQUEST,
  MAIN_HOT_OFFER_SUCCESS,
  MAIN_HOT_OFFER_FAILURE
)<void, IHotOfferProducts[], Error>();

export const fetchFeaturedProductsSectionsAsync = createAsyncAction(
  FEATURED_PRODUCTS_SECTIONS_REQUEST,
  FEATURED_PRODUCTS_SECTIONS_SUCCESS,
  FEATURED_PRODUCTS_SECTIONS_FAILURE
)<void, FeaturedProductsSection[], Error>();

function* mainMenuSaga(action: ReturnType<typeof mainMenuAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.getMainMenu());

    yield put(mainMenuAsync.success(response));
  } catch (err) {
    yield put(mainMenuAsync.failure(err as Error));
  }
}

function* featuredProductsSectionsSaga(): Generator {
  try {
    const response: any = yield call(() => httpClient.getFeaturedProductsSections());
    yield put(
      fetchFeaturedProductsSectionsAsync.success(response.map((item: any) => new FeaturedProductsSection(item)))
    );
  } catch (err) {
    yield put(fetchFeaturedProductsSectionsAsync.failure(err as Error));
  }
}

function* hotOfferProductsSaga(): Generator {
  try {
    const response: any = yield call(() => httpClient.getHotOffer());
    yield put(
      hotOfferProductsAsync.success(
        response.map((item: IHotOffer) => {
          return { ...item, product: new Product(item.product) };
        })
      )
    );
  } catch (err) {
    yield put(hotOfferProductsAsync.failure(err as Error));
  }
}

function* mainTopSliderSaga(): Generator {
  try {
    const response: any = yield call(() => httpClient.getTopSliders());

    yield put(mainTopSlidersAsync.success(response.map((item: any) => new MainTopSlide(item))));
  } catch (err) {
    yield put(mainTopSlidersAsync.failure(err as Error));
  }
}

function* mainSettingsSaga(): Generator {
  try {
    const responseMock = {
      phones: '+38 067 804 0974,+38 063 238 0696',
      address: `м. Київ,<br> вул. Старосільська, 1/2`,
      workHours: `з 8:00 до 22:00<br> без вихідних`
    };

    yield put(mainSettingsAsync.success(responseMock));
  } catch (err) {
    yield put(mainSettingsAsync.failure(err as Error));
  }
}

function* mainPromoSaga(): Generator {
  try {
    // const response: any = yield call(() => httpClient.getMainTopSlider());
    const response = mainPromoMock;
    yield put(mainPromoAsync.success(new MainPromo(response)));
  } catch (err) {
    yield put(mainPromoAsync.failure(err as Error));
  }
}

function* mainTestimonialsSaga(): Generator {
  try {
    // const response: any = yield call(() => httpClient.getHotOffer());
    const response = [
      {
        id: 1,
        name: 'Поліна Кирилко',
        brand: 'Мережа магазинів Квіточка',
        title: 'Найкращий постачальник на ринку',
        date: '2020-04-11T18:11:17.026Z',
        text:
          'Задоволена кожним замовленням у Флоротеці! Чудові менеджери, які завжди нададуть консультацію та влучно підберуть потрібний сорт! Свіжість квітів гарантована!'
      },
      {
        id: 2,
        name: 'Іван Остапчук',
        brand: 'Служба квіткового сервісу',
        title: 'Флоротека не розчаровує. Завжди свіжі квіти!',
        date: '2020-04-28T18:11:17.026Z',
        text: 'Постачальник найкращих квітів на ринку! Супер якість та великий асортимент.\n Дякую, що ви є!'
      },
      {
        id: 3,
        name: 'Тетяна Платонова',
        brand: 'Магазин Квітучий Сад',
        title: 'Заощаджую на програмі лояльності',
        date: '2020-03-18T18:11:17.026Z',
        text: 'Зручна доставка та дуже адекватні ціни. Рекомендую!'
      }
      // {
      //   id: 4,
      //   name: "Тетяна Остапчук",
      //   brand: "Магазин Сад",
      //   title: "Служба квіткового сервісу",
      //   date: "2020-03-21T18:11:17.026Z",
      //   text: "An absolutely fabulous company. I have both given and received flowers from Bloom & Wild."
      // },
      // {
      //   id: 5,
      //   avatar: "https://i.pravatar.cc/80?u=fake5@pravatar.com",
      //   name: "Поліна Платонова",
      //   brand: "Служба Сад",
      //   title: "Завжди свіжі квіти!",
      //   date: "2020-04-11T18:11:17.026Z",
      //   text: "An absolutely fabulous company. I have both given and received flowers from Bloom & Wild."
      // }
    ];
    yield put(mainTestimonialsAsync.success(response.map((item: any) => new Testimonial(item))));
  } catch (err) {
    yield put(mainTestimonialsAsync.failure(err as Error));
  }
}

export function* mainRequestSaga() {
  yield takeEvery(mainMenuAsync.request, mainMenuSaga);
  yield takeEvery(fetchFeaturedProductsSectionsAsync.request, featuredProductsSectionsSaga);
  yield takeEvery(mainTopSlidersAsync.request, mainTopSliderSaga);
  yield takeEvery(hotOfferProductsAsync.request, hotOfferProductsSaga);
  yield takeEvery(mainPromoAsync.request, mainPromoSaga);
  yield takeEvery(mainSettingsAsync.request, mainSettingsSaga);
  yield takeEvery(mainTestimonialsAsync.request, mainTestimonialsSaga);
}

export default createReducer(mainInitialState)
  .handleAction(mainMenuAsync.request, (state: IMainState, action: PayloadAction<typeof MAIN_MENU_REQUEST, void>) => {
    return {
      ...state,
      mainMenu: loadingDataWrapper(state.mainMenu.data)
    };
  })
  .handleAction(mainMenuAsync.success, (state: IMainState, action: PayloadAction<typeof MAIN_MENU_SUCCESS, void>) => {
    return {
      ...state,
      mainMenu: loadedDataWrapper(action.payload)
    };
  })
  .handleAction(
    hotOfferProductsAsync.request,
    (state: IMainState, action: PayloadAction<typeof MAIN_HOT_OFFER_REQUEST, Product>) => {
      return {
        ...state,
        hotOfferProducts: loadingDataWrapper(null)
      };
    }
  )
  .handleAction(
    hotOfferProductsAsync.success,
    (state: IMainState, action: PayloadAction<typeof MAIN_HOT_OFFER_SUCCESS, DetailedProduct>) => {
      const responseProduct = action.payload;
      return {
        ...state,
        hotOfferProducts: loadedDataWrapper(responseProduct)
      };
    }
  )
  .handleAction(
    mainTestimonialsAsync.request,
    (state: IMainState, action: PayloadAction<typeof TESTIMONIALS_REQUEST, void>) => {
      return {
        ...state,
        testimonials: loadingDataWrapper([])
      };
    }
  )
  .handleAction(
    mainTestimonialsAsync.success,
    (state: IMainState, action: PayloadAction<typeof TESTIMONIALS_SUCCESS, Testimonial[]>) => {
      return {
        ...state,
        testimonials: loadedDataWrapper(action.payload)
      };
    }
  )
  .handleAction(
    mainTopSlidersAsync.request,
    (state: IMainState, action: PayloadAction<typeof MAIN_TOP_SLIDER_REQUEST, void>) => {
      return {
        ...state,
        topSlider: loadingDataWrapper([])
      };
    }
  )
  .handleAction(
    mainTopSlidersAsync.success,
    (state: IMainState, action: PayloadAction<typeof MAIN_TOP_SLIDER_SUCCESS, void>) => {
      return {
        ...state,
        topSlider: loadedDataWrapper(action.payload)
      };
    }
  )
  .handleAction(mainPromoAsync.request, (state: IMainState, action: PayloadAction<typeof PROMO_REQUEST, void>) => {
    return {
      ...state,
      mainPromo: loadingDataWrapper(null)
    };
  })
  .handleAction(mainPromoAsync.success, (state: IMainState, action: PayloadAction<typeof PROMO_SUCCESS, void>) => {
    return {
      ...state,
      mainPromo: loadedDataWrapper(action.payload)
    };
  })
  .handleAction(
    mainSettingsAsync.request,
    (state: IMainState, action: PayloadAction<typeof SETTINGS_REQUEST, void>) => {
      return {
        ...state,
        contacts: loadingDataWrapper(null)
      };
    }
  )
  .handleAction(
    mainSettingsAsync.success,
    (state: IMainState, action: PayloadAction<typeof SETTINGS_SUCCESS, void>) => {
      return {
        ...state,
        contacts: loadedDataWrapper(action.payload)
      };
    }
  )
  .handleAction(
    fetchFeaturedProductsSectionsAsync.request,
    (state: IMainState, action: PayloadAction<typeof FEATURED_PRODUCTS_SECTIONS_REQUEST, void>) => {
      return {
        ...state,
        featuredProductsSections: loadingDataWrapper(null)
      };
    }
  )
  .handleAction(
    fetchFeaturedProductsSectionsAsync.success,
    (
      state: IMainState,
      action: PayloadAction<typeof FEATURED_PRODUCTS_SECTIONS_SUCCESS, FeaturedProductsSection[]>
    ) => {
      return {
        ...state,
        featuredProductsSections: loadedDataWrapper(action.payload)
      };
    }
  );
