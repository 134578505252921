import React, { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { FlrButtonKhaki } from './shared/buttons';
import { cookiesAllowedName } from 'shared/constants';
import Icon from './shared/Icon';
import { setCookie } from '../utils/cookies';
import { ITheme, IThemed } from '../utils/styled';

import commonMessages from 'translations/common';

const useClasses = makeStyles((theme: ITheme) =>
  createStyles({
    paperRoot: {
      padding: '64px 96px',

      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        padding: 16,
        margin: 16,
        maxWidth: 'calc(100% - 32px)'
      }
    },
    description: {
      display: 'flex'
    }
  })
);

const TransitionComponent = React.forwardRef(function Transition(
  props: TransitionProps & { children: any },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GDPR = () => {
  const classes = useClasses();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setCookie(cookiesAllowedName, '1');
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 300);
  }, []);

  return (
    <Dialog
      open={open}
      maxWidth={false}
      TransitionComponent={TransitionComponent}
      classes={{ paper: classes.paperRoot }}
    >
      <StyledContainer>
        <div className={classes.description}>
          <Icon type="cookies" size={36} />
          <p style={{ whiteSpace: 'pre-line', width: '100%' }}>
            <FormattedHTMLMessage {...commonMessages.gdprPopupText} />
          </p>
        </div>

        <div className="actions">
          <FlrButtonKhaki size="small" onClick={handleClose}>
            <FormattedMessage {...commonMessages.gdprPopupButton} />
          </FlrButtonKhaki>
        </div>
      </StyledContainer>
    </Dialog>
  );
};

const StyledContainer = styled<any>('div')(({ theme }: IThemed) => ({
  padding: 0,
  fontSize: theme.fontSizes.small,
  background: theme.colors.background,
  height: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',

  a: {
    color: theme.palette.primary.main
  },

  p: {
    marginLeft: 16,
    marginTop: 0
  },

  '& .actions': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default GDPR;
