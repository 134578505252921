export const iOS = (userAgent: string) => !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
export const webkit = (userAgent: string) => !!userAgent.match(/WebKit/i);
export const iOSSafari = ({ userAgent }: Navigator) =>
  iOS(userAgent) && webkit(userAgent) && !userAgent.match(/CriOS/i);

export const isMobileDevice = ({ userAgent }: Navigator) =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

const environment = {
  apiUrl: String(process.env.REACT_APP_API_ENDPOINT),
  iOSSafari
};

export default environment;
