import { FC, useContext } from 'react';

import Breadcrumbs, { IBreadCrumbLink } from 'components/layout/breadcrumbs/Breadcrumbs';
import messages from 'translations/account/menu';

import { breadCrumbsContext } from './breadCrumbsState';

const AccountBreadCrumbs: FC = ({ children }) => {
  const { breadCrumbs } = useContext(breadCrumbsContext);
  const accountLink: IBreadCrumbLink = {
    label: messages.title.defaultMessage,
    path: `/account/dashboard`
  };

  // ensure we have last item disabled
  const modifiedBreadcrumbs = [accountLink, ...breadCrumbs];
  if (modifiedBreadcrumbs.length > 1) {
    modifiedBreadcrumbs[modifiedBreadcrumbs.length - 1].path = undefined;
  }
  return <Breadcrumbs links={modifiedBreadcrumbs}>{children}</Breadcrumbs>;
};

export default AccountBreadCrumbs;
