import React from "react";
import { withStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';

import styles from "./styles";

export const AccountOrderHeaderSkeleton: React.FC<any> = ({ classes }) => {
  return (
    <Grid container justifyContent={"space-between"} alignItems={"center"}>
      <Grid item>
        <Skeleton
          variant="rectangular"
          width={150}
          height={20}
          className={classes.orderSubTitle}
          style={{ marginBottom: 16 }}
        />
      </Grid>
      <Grid item>
        <Skeleton variant="rectangular" width={150} height={16} style={{ marginBottom: 12 }} />
      </Grid>
    </Grid>
  );
};

export default withStyles<any>(styles)(AccountOrderHeaderSkeleton);
