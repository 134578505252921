import TagManager from "react-gtm-module";
import { Product, CartGroup, CartItem, OrderItem, Cart, Order } from "models";
import { store } from "../../index";
import { getProductsFields } from "./helpers";

interface ITrackData {
  dataLayer: {
    event: string;
    ecommerce: any;
    pageType?: string;
  };
}

interface IGoogleTagManager {
  init(id: string): void;
  trackPage(data: string): void;
  trackPurchasePage(data: Order): void;
  trackOpenProductDetail(data: Product): void;
  trackBuyButton(qty: number, price: number): void;
  trackAddQtyToBuyButton(qty: number, price: number): void;
  trackChangeQtyInCart(item: CartItem, quantity: number, price: number): void;
  trackRestoreExpiredButton(data: CartGroup): void;
  trackApplyQtyInCart(data: CartItem[]): void;
  trackRemoveItemFromCart(data: CartItem): void;
  trackRemoveQtyOfItemFromCart(data: CartItem, qty: number): void;
  trackNullifyItemFromOrderList(data: OrderItem): void;
  trackRemoveExpiredItemsFromCard(data: Cart): void;
  trackRemoveItemsFromCart(data: CartGroup): void;
  trackRemoveItemsFromCartWhenExpired(data: any): void;
  trackEnterToCheckoutPage(data: Cart): void;
  trackEnterDeliveryAddress(): void;
  trackSplitOrder(data: OrderItem): void;
  trackCheckoutOrder(data: Order): void;
  trackCheckoutAllOrders(data: Cart): void;
  trackOrderFeedback(): void;
}

class GoogleTagManager implements IGoogleTagManager {
  public init(gtmId: string) {
    TagManager.initialize({ gtmId });
  }

  public trackPage(UID: string) {
    const trackData = {
      dataLayer: {
        UID
      }
    };
    TagManager.dataLayer(trackData);
  }

  public trackPurchasePage(order: Order) {
    const trackData = {
      dataLayer: {
        pageType: "purchase",
        event: "purchase",
        ecommerce: {
          purchase: {
            actionField: {
              id: `№ ${order.orderId}`,
              revenue: order.totalSum
            },
            products: order.items.map((i: OrderItem) =>
              i
                ? {
                    ...getProductsFields(i.product),
                    price: i.price,
                    quantity: i.qty
                  }
                : null
            )
          }
        }
      }
    };
    TagManager.dataLayer(trackData);
  }

  public trackOpenProductDetail(data: Product) {
    const trackData: ITrackData = {
      dataLayer: {
        pageType: "product",
        event: "productDetail",
        ecommerce: {
          detail: {
            products: [getProductsFields(data)]
          }
        }
      }
    };
    TagManager.dataLayer(trackData);
  }

  // * -> addToCart event
  public trackBuyButton(quantity: number, price: number) {
    const state = store.getState();
    const productData = state.catalog.data.selectedProduct.data as Product;
    TagManager.dataLayer(this._getAddToCardEventData(productData, quantity, price));
  }

  // * unused, for now
  public trackAddQtyToBuyButton(quantity: number, price: number) {
    const state = store.getState();
    const productData = state.catalog.data.selectedProduct.data as Product;
    TagManager.dataLayer(this._getAddToCardEventData(productData, quantity, price));
  }

  public trackChangeQtyInCart(item: CartItem, quantity: number, price: number) {
    TagManager.dataLayer(this._getAddToCardEventData(item.product, quantity, price));
  }

  public trackRestoreExpiredButton(data: CartGroup) {
    // TODO change currencyCode according to the user currency
    const trackData: ITrackData = {
      dataLayer: {
        event: "addToCart",
        ecommerce: {
          currencyCode: "UAH",
          add: {
            products: data.items.map((i: CartItem) => ({
              ...getProductsFields(i.product),
              price: i.clientPrice,
              quantity: i.qty
            }))
          }
        }
      }
    };

    TagManager.dataLayer(trackData);
  }

  public trackApplyQtyInCart(data: CartItem[]) {
    TagManager.dataLayer(this._getAddToCardEventData(data[0].product, data[0].qty, data[0].clientPrice));
  }
  // * <- addToCart event

  // * -> removeFromCart event
  public trackRemoveItemFromCart(data: CartItem) {
    TagManager.dataLayer(this._getRemoveFromCardEventData(data.product, data.qty, data.clientPrice));
  }

  public trackRemoveQtyOfItemFromCart(data: CartItem, qty: number) {
    TagManager.dataLayer(this._getRemoveFromCardEventData(data.product, data.qty - qty, data.clientPrice));
  }

  public trackNullifyItemFromOrderList(data: OrderItem) {
    TagManager.dataLayer(this._getRemoveFromCardEventData(data.product, data.qty, data.price));
  }

  public trackRemoveExpiredItemsFromCard(data: Cart) {
    TagManager.dataLayer(this._getRemoveItemsFromCardEventData(data));
  }

  public trackRemoveItemsFromCart(data: CartGroup) {
    TagManager.dataLayer(this._getRemoveItemsFromCardEventData(data));
  }

  public trackRemoveItemsFromCartWhenExpired(data: Cart) {
    TagManager.dataLayer(this._getRemoveItemsFromCardEventData(data));
  }
  // * <- removeFromCart event

  // * -> checkout event
  public trackEnterToCheckoutPage(data: Cart) {
    TagManager.dataLayer(this._getCheckoutCartItemsEventData(data.items, 1));
  }

  public trackEnterDeliveryAddress() {
    const state = store.getState();
    const cartItems = state.cart.data.cart.items;
    TagManager.dataLayer(this._getCheckoutCartItemsEventData(cartItems, 2));
  }

  public trackSplitOrder(data: OrderItem) {
    TagManager.dataLayer(this._getCheckoutOrderItemsEventData([data], 3));
  }

  public trackCheckoutOrder(data: Order) {
    TagManager.dataLayer(this._getCheckoutOrderItemsEventData(data.items, 4));
  }

  public trackCheckoutAllOrders(data: Cart) {
    TagManager.dataLayer(this._getCheckoutOrderItemsEventData(data.items, 4));
  }
  // * <- checkout event

  // * -> refund event
  public trackOrderFeedback() {
    const state = store.getState();
    const selectedOrder = state.orders.data.selectedOrder;
    if (selectedOrder) {
      const trackData: ITrackData = {
        dataLayer: {
          event: "refund",
          ecommerce: {
            refund: {
              actionField: { id: selectedOrder.orderId },
              products: selectedOrder.items.map((i: OrderItem) => ({
                id: i.product.code,
                quantity: i.qty
              }))
            }
          }
        }
      };
      TagManager.dataLayer(trackData);
    }
  }
  // * <- refund event

  private _getAddToCardEventData(productData: Product, quantity: number, price: number): ITrackData {
    // TODO change currencyCode according to the user currency
    return {
      dataLayer: {
        event: "addToCart",
        ecommerce: {
          currencyCode: "UAH",
          add: {
            products: [
              {
                ...getProductsFields(productData),
                quantity,
                price
              }
            ]
          }
        }
      }
    } as ITrackData;
  }

  private _getRemoveFromCardEventData(productData: Product, quantity: number, price: number) {
    return {
      dataLayer: {
        event: "removeFromCart",
        ecommerce: {
          remove: {
            products: [
              {
                ...getProductsFields(productData),
                quantity,
                price
              }
            ]
          }
        }
      }
    } as ITrackData;
  }

  private _getRemoveItemsFromCardEventData(data: CartGroup | Cart) {
    return {
      dataLayer: {
        event: "removeFromCart",
        ecommerce: {
          remove: {
            products: data.items.map((i: CartItem) => ({
              ...getProductsFields(i.product),
              price: i.clientPrice,
              quantity: i.qty
            }))
          }
        }
      }
    } as ITrackData;
  }

  private _getCheckoutCartItemsEventData(data: CartItem[], step: number) {
    return {
      dataLayer: {
        event: "checkout",
        pageType: "checkout",
        ecommerce: {
          checkout: {
            actionField: { step },
            products: data.map((i: CartItem) => ({
              ...getProductsFields(i.product),
              price: i.clientPrice,
              quantity: i.qty
            }))
          }
        }
      }
    } as ITrackData;
  }

  private _getCheckoutOrderItemsEventData(data: any, step: number) {
    return {
      dataLayer: {
        event: "checkout",
        pageType: "checkout",
        ecommerce: {
          checkout: {
            actionField: { step },
            products: data.map((i: CartItem | OrderItem) => ({
              ...getProductsFields(i.product),
              quantity: i.qty,
              price: i.price
            }))
          }
        }
      }
    } as ITrackData;
  }
}

export const GTM = new GoogleTagManager() as IGoogleTagManager;
