import { FC } from 'react';
import clsx from 'clsx';

import ProductDetails from 'components/cards/shared/ProductDetails';
import { SplitedPrice } from 'components/prices';
import FlrQuantity from 'components/shared/form-elements/quantity/FlrQuantity';
import Icon from 'components/shared/Icon';
import { Link3, TextBody1 } from 'components/shared/text';
import { GTM } from 'controllers';
import { OrderItem } from 'models';
import { getProductImage } from 'utils/helpers';

import classes from './OrderCardItem.module.scss';

interface IProps {
  isFirst: boolean;
  removeDisabled: boolean;
  max?: number;
  item: OrderItem;
  onChangeAmount?: (qty: number) => void;
  onRemove?: () => void;
  withDiscount?: boolean;
  withItemRemove?: boolean;
  isNotEditable?: boolean;
  isPreOrder?: boolean;
  isAccount?: boolean;
}

const OrderCardItem: FC<IProps> = ({
  isFirst,
  removeDisabled,
  max,
  item,
  onChangeAmount,
  onRemove,
  withItemRemove,
  withDiscount,
  isNotEditable,
  isPreOrder,
  isAccount
}) => {
  const removeHandle = () => {
    if (!removeDisabled && onRemove) {
      GTM.trackNullifyItemFromOrderList(item);
      onRemove();
    }
  };

  const isSpecialOrder = item.special;

  return (
    <>
      <hr className={clsx(classes.divider, { [classes.dividerFirst]: isFirst })} />
      <div className={classes.container}>
        <div className={clsx(classes.productDetailsWrapper, {[classes.accountProductDetailsWrapper]: isAccount})}>
          <img src={getProductImage(item.product)} alt={''} className={classes.img} />
          <ProductDetails product={item.product} />
        </div>
        <div className={classes.price}>
          {isSpecialOrder ? <Icon opacity={1} type="fire" size={20} style={{ marginRight: 6 }} /> : null}
          <SplitedPrice value={item.price} fontSize='h3' className={classes.splicedValue}/>
        </div>
        <div className={clsx(classes.quantity, {[classes.accountQuantity]: isAccount})}>
          {isNotEditable ? (
            <TextBody1 align={'center'}>{item.qty}</TextBody1>
          ) : (
            <FlrQuantity
              value={item.qty}
              inPackageCount={item.product.inPackageCount}
              min={item.qty === item.product.inPackageCount ? 0 : item.product.inPackageCount}
              onDecrement={item.qty === item.product.inPackageCount ? () => onRemove && onRemove() : undefined}
              max={max || item.qty}
              onChange={onChangeAmount}
              disableMaxLimit={!isPreOrder}
            />
          )}
        </div>
        {!!withItemRemove && (
          <Link3
            className={clsx(classes.orderItemRemove, removeDisabled ? classes.disabled : null)}
            onClick={removeHandle}
          >
            Обнулити
          </Link3>
        )}
        <TextBody1 className={clsx(classes.inPackageCount, {[classes.accountInPackageCount]: isAccount})}>{item.packQty}</TextBody1>
        {!!withDiscount && (
          <div className={classes.paramValue}>
            {item.discount > 0 || item.discountAuto > 0 ? <SplitedPrice value={item.discountAmount} prefix='-' fontSize='subtitle1' className={classes.discountAmount}/> : null}
          </div>
        )}
        <div className={classes.orderItemSubtotal}>
          <SplitedPrice
            value={Math.round(item.total * 100) / 100}
            fontSize={'h3'}
            className={classes.totalPrice}
          />
        </div>
      </div>
    </>
  );
};

export default OrderCardItem;
