import React, { CSSProperties } from "react";
import clsx from "clsx";
import { withStyles } from "@mui/styles";

import { ITheme } from "utils/styled";

interface IProps {
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  classes: any;
  color?: "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
  className?: string;
  style?: CSSProperties;
  href?: string;
  isUnderline?: boolean;
}

const FlrCardTitle: React.FC<IProps> = ({ children, classes, isUnderline, className }) => {
  return (
    <div
      className={clsx(classes.root, className, {
        [classes.rootUnderline]: isUnderline
      })}
    >
      {children}
    </div>
  );
};

const styles = (theme: ITheme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingBottom: theme.spacing(1.5),
    marginBottom: theme.spacing(2)
  },
  rootUnderline: {
    borderBottom: `1px solid ${theme.palette.common.disabledBlackBackground}`
  }
});

export default withStyles<any>(styles)(FlrCardTitle);
