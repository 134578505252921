import { IApplicationState } from "store/reducers";

export const getExpiredCartState = (state: IApplicationState) => state.expiredCart;
export const getExpiredCartLoadingState = (state: IApplicationState) => state.expiredCart.loading;
export const getExpiredCartLoaded = (state: IApplicationState) => state.expiredCart.loaded;
export const getExpiredCart = (state: IApplicationState) => getExpiredCartState(state).data.expiredCart;
export const getExpiredCartItems = (state: IApplicationState) => getExpiredCartState(state).data.expiredCart.items;
export const getExpiredCartGroups = (state: IApplicationState) => getExpiredCartState(state).data.expiredCart.groups;
export const getExpiredCartTimer = (state: IApplicationState) => getExpiredCartState(state).data.timer;
export const getExpiredCartTimerTime = (state: IApplicationState) => getExpiredCartTimer(state).time;
