import React from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';

import FlrCardNarrow from 'components/shared/card/FlrCardNarrow';
import FlrProgress from 'components/shared/progress-bar/FlrProgressBar';
import { getAccountDiscountsData, getAccountDiscountsLoadingState } from 'store/account-discounts/selectors';
import messagesCommon from 'translations/common';
import { formatNumber } from 'utils/helpers';

import styles from '../styles';

interface IComponentProps {
  progressValue: number;
  classes?: any;
}

const PersonalDiscountProgressCard: React.FC<IComponentProps> = ({ progressValue, classes }) => {
  const discountConditions = useSelector(getAccountDiscountsData);
  const discountConditionsLoadingState = useSelector(getAccountDiscountsLoadingState);

  if (discountConditionsLoadingState) {
    return (
      <FlrCardNarrow>
        <br />
        <br />
        <br />
        loading....
        <br />
        <br />
        <br />
        <br />
      </FlrCardNarrow>
    );
  }

  if (!discountConditions) {
    return null;
  }

  const balanceConditions = discountConditions.balance.find(
    // check and change discountType to id
    (d) => discountConditions.accountBalanceDiscount?.discountRule === d.discountType
  );

  const conditions =
    balanceConditions && balanceConditions.conditions
      ? balanceConditions.conditions
      : discountConditions.accountBalanceDiscount?.conditions || {};
  const conditionsArray = Object.keys(conditions);

  const steps = conditionsArray.map((key) => ({
    value: `${key}%`,
    label: Number(conditions[key]) > 0 ? formatNumber(Number(conditions[key])) : messagesCommon.start.defaultMessage
  }));
  const activeIndex = conditionsArray.reduce((acc, k, idx) => (Number(conditions[k]) > progressValue ? acc : idx), -1);

  // const progressValue = (account && account?.profile.balance) || 1;
  let progress = 0;
  if (conditionsArray.length === activeIndex) {
    progress = 100;
  } else if (conditions[activeIndex]) {
    progress =
      (((progressValue || 0) - Number(conditions[activeIndex])) * 100) /
        (Number(conditions[activeIndex + 1]) - Number(conditions[activeIndex])) /
        conditionsArray.length +
      (100 / conditionsArray.length) * activeIndex;
  } else {
    progress =
      ((progressValue || 0) * 100) / Number(conditions[activeIndex + 1]) / conditionsArray.length +
      (100 / conditionsArray.length) * activeIndex +
      1;
  }

  return <FlrProgress steps={steps} progress={progress} activeIndex={activeIndex} />;
};

export default withStyles<any>(styles)(PersonalDiscountProgressCard);
