import { devicePanelHeight } from 'shared/constants';
import { ITheme } from 'utils/styled';

const styles: any = (theme: ITheme) => ({
  loader: {
    marginRight: 12
  },
  searchBlock: {
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up!('md')]: {
      minWidth: 150
    }
  },
  buttonSearch: {
    '& .flr-icon': {
      minWidth: 24,
      marginRight: theme.spacing(1)
    },
    flexShrink: 0,
    width: 'auto',
    borderTopLeftRadius: '0 !important',
    borderBottomLeftRadius: '0 !important',
    padding: '10px 20px',
    [theme.breakpoints.down!('lg')]: {
      minWidth: 24,
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      '& .flr-icon': {
        marginRight: 0
      },
      '& >span >span': {
        display: 'none'
      }
    },
    [theme.breakpoints.only!('sm')]: {
      '& .flr-icon': {
        marginRight: theme.spacing(1)
      },
      '& >span >span': {
        display: 'block'
      }
    }
  },
  controlContainer: {
    width: '100%',

    '& .MuiInputBase-root': {
      '& .MuiInputBase-input[aria-expanded=true]': {
        '& + .MuiOutlinedInput-notchedOutline': {
          // '& + &': {
          borderWidth: 2,
          borderLeft: `2px solid ${theme.palette.primary.main}`,
          borderRight: `2px solid ${theme.palette.primary.main}`,
          borderBottom: 'none',
          borderColor: theme.palette.primary.main
        }
      }
    },

    '&&& .MuiInputBase-root': {
      height: 40,
      padding: 0,
      borderRadius: 0
    },

    [theme.breakpoints.up!('md')]: {
      '&&& .MuiInputBase-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderLeft: 'none',
          borderRight: 'none',
          borderColor: theme.palette.common.disabledBlack,
          borderTopWidth: '1px'
        }
      },

      '&&& .MuiInputBase-input': {
        padding: '0 19px',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '14px',
      }
    },

    [theme.breakpoints.down!('md')]: {
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.common.disabledBlack}`,

      '&&& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.common.helperBlack}`,
        borderLeft: `1px solid ${theme.palette.common.helperBlack}`,
        borderRight: `1px solid ${theme.palette.common.helperBlack}`,
        borderRadius: theme.spacing(1)
      },
      '&.Mui-focused': {
        '&&& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main
        }
      },

      '&&& .MuiInputBase-input': {
        paddingLeft: theme.spacing(1.5),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingRight: theme.spacing(5)
      }
    }
  },

  ListPopper: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: `2px solid ${theme.palette.primary.main}`,
    borderTop: 'none'
  },
  ListPaper: {
    marginTop: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: `2px solid ${theme.palette.primary.main}`,
    borderTop: 'none',

    '& > ul': {
      padding: 0,
      ...theme.scrollBar
    },

    '& .MuiAutocomplete-option': {
      color: theme.palette.text.secondary,
      padding: '6px 22px',

      '&.Mui-focused': {
        backgroundColor: 'transparent'
      },

      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main
      }
    }
  },
  groupItemContainer: {
    [(theme as any).breakpoints.down('md')]: {
      borderBottom: `1px solid ${theme.palette.common.disabledBlack}`,
      paddingBottom: theme.spacing(1),

      '&:last-of-type': {
        borderBottom: `none`
      }
    }
  },
  groupItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),

    color: theme.palette.common.black,
    textTransform: 'capitalize',

    [(theme as any).breakpoints.down('md')]: {
      marginLeft: 0,
      paddingBottom: 10,
      paddingTop: theme.spacing(2)
    },
    [(theme as any).breakpoints.up('sm')]: {
      paddingTop: 21,
      paddingBottom: theme.spacing(2)
    }
  },
  clearHistory: {
    cursor: 'pointer',
    color: theme.palette.text.primary,
    fontSize: 14,

    '& a': {
      color: theme.palette.text.primary
    }
  },
  optionItem: {
    marginLeft: theme.spacing(1),
    marginTop: 6,
    marginBottom: 6,
    width: 'auto',

    [theme.breakpoints.down!('md')]: {
      marginTop: 0,
      marginLeft: 0,
      marginBottom: theme.spacing(1),
      minHeight: 32
    }
  },
  optionItemHighlight: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  itemIcon: {
    height: 24
  },

  searchCatalogButton: {
    paddingTop: 9,
    paddingBottom: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.common.buttonOutlined}`,
    textTransform: 'unset',

    '&.MuiButton-outlinedPrimary': {
      padding: '7px 10px',
      borderWidth: 1,
      borderColor: theme.palette.common.buttonOutlined,
      color: theme.palette.text.primary
    }
  },
  searchCatalogButtonText: {
    color: theme.palette.common.helperBlack,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  mobileDrawer: {
    width: '100vw',
    height: '100vh'
  },
  mobileContent: {
    marginTop: devicePanelHeight,
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  mobileSubTitle: {
    marginBottom: theme.spacing(1)
  },
  mobileCategory: {
    marginBottom: theme.spacing(2)
  },
  searchItemMobile: {
    fontSize: 16,
    lineHeight: 1.4
  }
});

export default styles;
