import React from 'react';
import styled from '@emotion/styled/macro';

import { WorkingHours } from 'components/shared/WorkingHours';
import { PhonesListComponent } from 'components/shared/PhonesList';
import { IThemed } from 'utils/styled';
import SocialLinks from './SocialLinks';

interface IProps {
  phones: string[] | undefined;
  workHours: string | undefined;
}

export const PhonesCard: React.FC<IProps> = ({ phones, workHours }) => (
  <Wrapper>
    <PhonesListComponent phones={phones} />
    <SocialLinks />
    <Divider />
    <WorkingHours workHours={workHours} />
  </Wrapper>
);

const Wrapper = styled<any>('section')(({ theme }: IThemed) => ({
  padding: '8px 16px',
  fontFamily: theme.fonts.headings,
  letterSpacing: theme.letterSpacing
}));

const Divider = styled<any>('div')(({ theme }: IThemed) => ({
  border: `1px solid ${theme.colors.lightGray}`,
  margin: '8px 0'
}));
