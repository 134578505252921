import { FC } from 'react';

import FooterBottom from './footer-bottom/FooterBottom';
import FooterCopyright from './footer-copyright/FooterCopyright';
import FooterDevice from './footer-device/FooterDevice';
import FooterNav from './footer-nav';

import classes from './Footer.module.scss';

export interface IFooterProps {
  showOnlyDevice?: boolean;
}

const Footer: FC<IFooterProps> = ({ showOnlyDevice }) => (
  <>
    <FooterDevice />
    {!showOnlyDevice ? (
      <footer className={classes.footer}>
        <FooterNav />
        <FooterBottom />
        <FooterCopyright />
      </footer>
    ) : null}
  </>
);

export default Footer;
