import React from "react";
import { withStyles } from "@mui/styles";
import { Breadcrumbs as MaterialBreadcrumbs } from "@mui/material";

import { baseUrl } from "shared/constants";
import { Link3, TextHelper } from "components/shared/text";
import styles from "./styles";
import clsx from "clsx";

export interface IBreadCrumbLink {
  path?: string;
  label: string;
}

export interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  links: IBreadCrumbLink[];
  classes?: any;
}

const Breadcrumbs: React.FC<IProps> = ({ links, classes, children, ...rest }) => (
  <MaterialBreadcrumbs aria-label="breadcrumb" className={clsx(classes.root)} {...rest}>
    {children}
    <Link3 to={`/`}>Головна</Link3>
    {links.map((link, key) =>
      link.path ? (
        <Link3 to={`${baseUrl}${link.path}`} key={key}>
          {link.label}
        </Link3>
      ) : (
        <TextHelper key={key} style={{ opacity: 0.5 }}>
          {link.label}
        </TextHelper>
      )
    )}
  </MaterialBreadcrumbs>
);

export default withStyles(styles)(Breadcrumbs);
