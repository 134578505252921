import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import styled from '@emotion/styled/macro';
import { useRouteMatch } from 'react-router';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Cart } from 'models';
import { GTM } from 'controllers';
import { IThemed } from 'utils/styled';
import { Text } from 'components/layout/content/Text';
import { TextBody2, TextSubTitle } from 'components/shared/text';
import Tooltip from 'components/shared/tooltip';
import Icon from 'components/shared/Icon';
import messages from 'translations/cart/timer';
import { CART_LAYOUT_WARNING_SHOWED, expireCart } from 'store/cart/actions';
import { IApplicationState } from 'store/reducers';
import {
  getCart,
  getCartTimerShouldReset,
  getCartWarningShowed,
  getCartTransitTimerShouldReset
} from 'store/cart/selectors';
import { baseUrl, CART_WARNING_NOTIFICATION_MINUTES_LEFT, UAH } from 'shared/constants';
import { SplitedPrice } from 'components/prices';
import { HeaderBottomLink } from './HeaderBottomLink';
import SnackbarContent from 'components/layout/notistack-provider/SnackbarWithTitle';

import cartMessages from 'translations/cart/common';
import notificationsMessages from 'translations/layout/notifications';
import { stylesTopLinks } from './Balance';
import { useTimer } from '../../timer/useTimer';
import HoldCart from '../../timer/HoldCart';

interface IOwnProps {
  // put them here
  mobile?: boolean;
  classes?: any;
}

interface IStateProps {
  cart: Cart;
  shouldResetCartTimer?: boolean;
  shouldResetCartTransitTimer?: boolean;
  cartWarningShowed?: boolean;
}

interface IDispatchProps {
  resetCart: typeof expireCart;
}

export type IProps = IOwnProps & IStateProps & IDispatchProps;

const cartUrl = `${baseUrl}/cart`;

const InCart: React.FC<IProps> = ({
  cart,
  resetCart,
  shouldResetCartTimer,
  shouldResetCartTransitTimer,
  cartWarningShowed,
  mobile,
  classes
}) => {
  const isLinkActive = useRouteMatch(cartUrl);
  const isCheckoutPage = !!useRouteMatch(`${baseUrl}/checkout`);

  const inStockExpiresOn =
    cart.groups && cart.regularGroups && cart.regularGroups.length ? cart.groups[cart.regularGroups[0]].expiresOn : '';
  const transitExpiresOn =
    cart.groups && cart.inTransitGroups && cart.inTransitGroups.length
      ? cart.groups[cart.inTransitGroups[0]].expiresOn
      : '';

  const inStockExpiresOnTime = new Date(inStockExpiresOn).getTime();
  const transitExpiresOnTime = new Date(transitExpiresOn).getTime();

  /** Notification */
  const { enqueueSnackbar } = useSnackbar();

  // decide what to do with this
  const dispatch = useDispatch();
  const handleChange = (end: number) => {
    const secondsLeft = (end - Date.now()) / 1000;
    const minutesLeft = Math.floor(secondsLeft / 60);
    const isNotExpired = minutesLeft > 0 || secondsLeft > 0;
    const isCartNotEmpty = cart && cart.items && cart.items.length > 0;

    if (
      isNotExpired &&
      isCartNotEmpty &&
      minutesLeft &&
      minutesLeft < CART_WARNING_NOTIFICATION_MINUTES_LEFT &&
      !cartWarningShowed
    ) {
      dispatch({ type: CART_LAYOUT_WARNING_SHOWED, payload: true });

      enqueueSnackbar(
        <SnackbarContent title={notificationsMessages.warning.defaultMessage}>
          {cartMessages.notificationExpiredCartIn15Minutes.defaultMessage}
        </SnackbarContent>,
        {
          variant: 'warning'
        }
      );
    }
  };
  /** Notification end */

  const handleResetCart = () => {
    GTM.trackRemoveItemsFromCartWhenExpired(cart);
    resetCart();
  };

  const inStockTimerProps = useTimer({
    type: cart && cart.items.length > 0 ? 'inStock' : '',
    end: inStockExpiresOnTime,
    shouldRestart: !!shouldResetCartTimer,
    shouldPause: !cart.regularGroups.length,
    isCheckoutPage,
    onExpire: handleResetCart,
    onChange: inStockExpiresOnTime <= transitExpiresOnTime ? handleChange : undefined
  });

  const transitTimerProps = useTimer({
    type: cart && cart.inTransitGroups.length > 0 ? 'transit' : '',
    end: transitExpiresOnTime,
    shouldRestart: !!shouldResetCartTransitTimer,
    shouldPause: !cart.inTransitGroups.length,
    isCheckoutPage,
    onExpire: handleResetCart,
    onChange: inStockExpiresOnTime > transitExpiresOnTime ? handleChange : undefined
  });

  const nearestTimer = (() => {
    if (!inStockTimerProps.minutes && !inStockTimerProps.seconds) {
      return transitTimerProps;
    }
    if (!transitTimerProps.minutes && !transitTimerProps.seconds) {
      return inStockTimerProps;
    }
    if (inStockTimerProps.minutes < transitTimerProps.minutes) {
      return inStockTimerProps;
    } else if (inStockTimerProps.minutes === transitTimerProps.minutes) {
      if (inStockTimerProps.seconds < transitTimerProps.seconds) {
        return inStockTimerProps;
      } else {
        return transitTimerProps;
      }
    }
    return transitTimerProps;
  })();

  const { shouldPause, minutes, seconds }: any = nearestTimer;

  const fontColor = isCheckoutPage
    ? 'lighterGray'
    : +minutes >= CART_WARNING_NOTIFICATION_MINUTES_LEFT
    ? 'alertInfo'
    : +minutes > 0
    ? 'warning'
    : 'red';

  const timer =
    minutes >= 0 && seconds >= 0 ? (
      <TimerWrapper>
        <Grid
          container
          alignItems={'center'}
          alignContent={'center'}
          style={{ display: shouldPause ? 'none' : 'block' }}
        >
          <Grid item xs style={{ whiteSpace: 'nowrap', display: 'flex' }}>
            <Tooltip
              icon={<Icon type={isCheckoutPage ? 'pause' : 'timer'} size={24} offset={8} opacity={1} />}
              title={
                <div>
                  <TextSubTitle color={'inherit'} style={{ marginTop: 0, whiteSpace: 'normal', color: 'white' }}>
                    {messages.title.defaultMessage}
                  </TextSubTitle>
                  <br />
                  <TextBody2 color={'inherit'} style={{ color: 'white', whiteSpace: 'normal' }}>
                    {isCheckoutPage ? messages.bodyCheckout.defaultMessage : messages.body.defaultMessage}
                  </TextBody2>
                  <br />
                </div>
              }
            >
              <Text fontColor={fontColor} style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <span style={{ display: 'inline-block', minWidth: 20, textAlign: 'left' }}>
                    {minutes <= 9 ? '0' : ''}
                    {minutes}
                  </span>
                  <span style={{ display: 'inline-block', minWidth: 5, textAlign: 'center' }}>:</span>
                  <span style={{ display: 'inline-block', minWidth: 20 }}>
                    {seconds <= 9 ? '0' : ''}
                    {seconds}
                  </span>
                </div>
                <Icon type={isCheckoutPage ? 'pause' : 'timer'} size={16} />
              </Text>
            </Tooltip>
          </Grid>
          {isCheckoutPage && <HoldCart cart={cart} />}
        </Grid>
      </TimerWrapper>
    ) : null;

  if (mobile) {
    if (!timer) {
      return null;
    }
    return timer;
  }

  const result = (
    <HeaderBottomLink
      to={cartUrl}
      className={clsx({
        active: isLinkActive
      })}
    >
      <Icon type={'cart'} className={'menu-cart-icon'} size={32} offset={8} withBackground opacity={1} />
      <Grid container className={classes.mainContent}>
        <Grid item sm={12}>
          {cart.regularGroups.length > 0 || cart.inTransitGroups.length > 0 ? (
            timer
          ) : (
            <TextBody2 color={'textSecondary'}>{cartMessages.inCart.defaultMessage}</TextBody2>
          )}
        </Grid>
        <Grid item>
          <SplitedPrice fontSize={'caption'} value={cart.total || 0} hideDecimal postfix={UAH} />
        </Grid>
      </Grid>
    </HeaderBottomLink>
  );

  if (mobile) {
    return (
      <Grid item xs>
        {result}
      </Grid>
    );
  }

  return result;
};

const TimerWrapper = styled<any>('div')(({ theme }: IThemed) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& svg': {
    marginLeft: 4
  },
  '& span:last-of-type': {
    minWidth: 20
  }
}));

const mapStateToProps = (state: IApplicationState) => ({
  cart: getCart(state),
  cartWarningShowed: getCartWarningShowed(state),
  shouldResetCartTimer: getCartTimerShouldReset(state),
  shouldResetCartTransitTimer: getCartTransitTimerShouldReset(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      resetCart: expireCart
    },
    dispatch
  )
});

export default withStyles<any>(stylesTopLinks)(connect(mapStateToProps, mapDispatchToProps)(InCart));
