import * as React from 'react';
import { IFlrSteps } from 'components/shared/stepper';
import messages from 'translations/auth/registration';
import Step1 from './step1';
import Step2 from './step2';

const RegFormSteps: IFlrSteps = {
  main: [
    {
      title: messages.formStep1,
      isRequired: true,
      content: Step1
    },
    {
      title: messages.formStep2,
      isRequired: true,
      content: Step2
    }
  ],
  final: () => <p className="final-h4">{messages.formStepFinal.defaultMessage}</p>
};

export default RegFormSteps;
