import { useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';

import { getCatalogFilters } from 'store/catalog/selectors';
import { getFilterOptionsData, getFilterOptionsLoadedState } from 'store/filter-options/selectors';

import classes from './TopBar.module.scss';

const CatalogDelivery = () => {
  const filterOptionsData = useSelector(getFilterOptionsData);
  const filtersState = useSelector(getCatalogFilters);
  const isOptionsLoaded = useSelector(getFilterOptionsLoadedState);

  const region = filterOptionsData.regions.find((item) => item.alias === filtersState.deliveryRegion);
  const supplierData = filterOptionsData.suppliers.find((item) => item.value === filtersState.supplier);

  const deliveryTo = region ? region.name : null;
  const deliveryFrom = supplierData ? supplierData.label : null;

  return (
    <div className={classes.deliveryContainer}>
      <span className={classes.deliveryLabel}>Доставка:&nbsp;</span>
      {isOptionsLoaded ? (
        <>
          <span className={classes.label}>в {deliveryTo}</span>
          &nbsp;
          <span className={classes.label}>з {deliveryFrom}</span>
        </>
      ) : (
        <Skeleton variant="rectangular" width={120} />
      )}
    </div>
  );
};

export { CatalogDelivery };
