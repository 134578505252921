import { FC } from 'react';
import { useRouteMatch } from 'react-router';
import { Theme, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import { CatalogHeaderTop } from './HeaderTop';
import { CatalogHeaderBottom } from './HeaderBottom';

import { baseUrl } from 'shared/constants';
import classes from './Header.module.scss'

const PageHeader: FC<any> = ({ children, wholeRef, optionalRef }) => {
  const theme: Theme = useTheme();
  const screenDevice = useMediaQuery(theme.breakpoints.down('md'));

  const isCatalog = useRouteMatch(`${baseUrl}/catalog/:type?/:filter?`);
  const isCart = useRouteMatch(`${baseUrl}/cart`);
  const isHeaderBottomExists = isCatalog || isCart;

  return (
    <header ref={wholeRef} className={classes.container}>
      <CatalogHeaderTop />
      {(isHeaderBottomExists || !screenDevice) && <CatalogHeaderBottom useDeviceStyle={screenDevice} />}
      <div ref={optionalRef}>{children}</div>
    </header>
  );
};

export default PageHeader;
