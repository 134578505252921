import { useCallback, useState } from 'react';
import { IBreadCrumbsContext } from "./breadCrumbsState";
import { IBreadCrumbLink } from "components/layout/breadcrumbs/Breadcrumbs";

export const useBreadCrumbs = (): IBreadCrumbsContext => {
  const [breadCrumbs, setBreadCrumbs] = useState<IBreadCrumbLink[]>([]);

  const setCurrentUrl = useCallback((array: IBreadCrumbLink[]): void => {
    setBreadCrumbs(array);
  }, []);

  return {
    breadCrumbs,
    setCurrentUrl
  };
};
