import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ITheme } from 'utils/styled';

export default makeStyles((theme: ITheme) =>
  createStyles({
    searchBlock: {
      position: 'relative'
    },
    sortButton: {
      marginLeft: 8,
      minWidth: 40,
      '&.MuiButton-outlinedPrimary': {
        padding: '7px 7px',
        display: 'flex',
        borderWidth: 1,
        borderColor: theme.palette.common.buttonOutlined,
        color: theme.palette.text.primary
      }
    },
    mobileDrawer: {
      width: '100vw',
      height: '100vh'
    },
    active: {
      color: theme.palette.common.black
    },
    sortListWrapper: {
      marginTop: 54,
      color: theme.palette.text.primary
    },
    iconWrapper: {
      minWidth: 30
    }
  })
);
