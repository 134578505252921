import React from 'react';
import { find, filter, remove, includes, now } from 'lodash';
import { IFilterOptions, ProductAttributesDictionary } from 'models';
import { IFilterProp, IFilterProps, IFiltersData } from 'store/catalog/actions';
import FlrCheckboxWithLabel from 'components/shared/form-elements/FlrCheckbox';
import { FilterChangeHandler } from '../index';
import { ExpandPanel } from 'components/shared';
import FilterLabel from './FilterLabel';
import { ATTRIBUTE_SHAPE_PATH } from 'shared/filters';
import { sortAlphabeticallyByValue } from './options-tools';

interface IProps {
  filters: IFiltersData;
  setFilters: (newFilters: IFilterProps) => void;
  label: string;
  filterOptionsData: IFilterOptions;
  postFilteredFilterOptions: Map<any, any>;
  classes: any;
}

const ShapeOptions: React.FC<IProps> = ({
  classes,
  label: labelSection,
  filterOptionsData,
  postFilteredFilterOptions,
  setFilters,
  filters
}) => {
  // map filters on filterOptions
  const attributeOptions = find(filterOptionsData.attributes, { code: ProductAttributesDictionary.shape });
  const attributeFilterValues: string[] =
    filter(filters.fast, { path: ATTRIBUTE_SHAPE_PATH } as IFilterProp).map(filter => filter.value) || [];

  if (!attributeOptions) {
    return null;
  }

  const filtersFast = filters.fast || [];

  const handleDiameterFilterChange: FilterChangeHandler<boolean> = (key: string, isChecked: boolean) => {
    const newFilterPropValue: IFilterProp = {
      rule: 'include',
      path: ATTRIBUTE_SHAPE_PATH,
      value: key
    };
    if (!isChecked) {
      remove(filtersFast, newFilterPropValue);
    } else if (!includes(filtersFast, newFilterPropValue)) {
      newFilterPropValue.time = now();
      filtersFast.push(newFilterPropValue);
    }
    setFilters(filtersFast);
  };

  const arraySelected = filtersFast.filter(item => item.path === ATTRIBUTE_SHAPE_PATH);
  const arraySelectedLength = (arraySelected && arraySelected.length) || 0;
  const arraySelectedLast = arraySelectedLength ? arraySelected[0] : null;

  const handleResetDiameterFilters = (ev: React.SyntheticEvent | React.MouseEvent) => {
    if (arraySelectedLast) {
      ev.stopPropagation();
      setFilters(filtersFast.filter(item => item.path !== ATTRIBUTE_SHAPE_PATH));
    }
  };

  const allAttributeOptions = attributeOptions.options.map(o => ({
    value: o,
    disabled: !postFilteredFilterOptions.get('shape')?.has(o),
    label: o,
    isChecked: attributeFilterValues.length > 0 && attributeFilterValues.includes(o)
  }));

  const allAttributeOptionsNodes = allAttributeOptions
    .sort(sortAlphabeticallyByValue)
    .map(({ isChecked, label, disabled, value }) => (
      <FlrCheckboxWithLabel
        checked={isChecked}
        label={label}
        onChange={(event: any, checked: boolean) => handleDiameterFilterChange(value, checked)}
        disabled={disabled && !isChecked}
      />
    ));

  const labelComp = (
    <FilterLabel
      classes={classes}
      label={labelSection}
      arraySelectedLast={arraySelectedLast}
      arraySelectedLength={arraySelectedLength}
      handleReset={handleResetDiameterFilters}
    />
  );

  return (
    <ExpandPanel
      defaultExpanded={true}
      label={labelComp}
      className={classes.paddedExpandPanel}
      classNameSummary={classes.expansionSummary}
      classNameButton={classes.expansionButton}
    >
      <>{allAttributeOptionsNodes}</>
    </ExpandPanel>
  );
};

export default ShapeOptions;
