import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "main.contacts.title",
    description: "main.contacts.title",
    defaultMessage: "Контактні дані"
  },
  phoneLabel: {
    id: "main.contacts.phoneLabel",
    description: "main.contacts.phoneLabel",
    defaultMessage: "Телефони"
  },
  addressLabel: {
    id: "main.contacts.addressLabel",
    description: "main.contacts.addressLabel",
    defaultMessage: "Адреса"
  },
  workHoursLabel: {
    id: "main.contacts.workHoursLabel",
    description: "main.contacts.workHoursLabel",
    defaultMessage: "Години роботи"
  },

  formTitle: {
    id: "main.contacts.formTitle",
    description: "main.contacts.formTitle",
    defaultMessage: "Виникли питання або є пропозиції?"
  },
  formCaption: {
    id: "main.contacts.formCaption",
    description: "main.contacts.formCaption",
    defaultMessage: "Напишіть нам"
  },
  namePlaceholder: {
    id: "main.contacts.namePlaceholder",
    description: "main.contacts.namePlaceholder",
    defaultMessage: "Ім’я"
  },
  phonePlaceholder: {
    id: "main.contacts.phonePlaceholder",
    description: "main.contacts.phonePlaceholder",
    defaultMessage: "Номер телефону"
  },
  emailPlaceholder: {
    id: "main.contacts.emailPlaceholder",
    description: "main.contacts.emailPlaceholder",
    defaultMessage: "E-mail"
  },
  textPlaceholder: {
    id: "main.contacts.textPlaceholder",
    description: "main.contacts.textPlaceholder",
    defaultMessage: "Текст вашого повідомлення"
  },
  submit: {
    id: "main.contacts.submit",
    description: "main.contacts.submit",
    defaultMessage: "Надіслати"
  },

  notificationSuccess: {
    id: "main.contacts.notificationSuccess",
    description: "main.contacts.notificationSuccess",
    defaultMessage: "Дякуємо за звернення"
  },
  notificationError: {
    id: "main.contacts.notificationError",
    description: "main.contacts.notificationError",
    defaultMessage: "Виникла помилка, будь ласка спробуйте пізніше"
  }
});
