import { transparentize } from 'polished';
import styled from '@emotion/styled/macro';
import { IThemed } from '../../utils/styled';

const LoadingOverlay = styled<any>('div')(({ theme }: IThemed) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 10,
  background: transparentize(0.25, theme.colors.background),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export default LoadingOverlay;
