import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import { Hidden, Radio } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { ValidatorForm } from 'react-material-ui-form-validator';
import messages from 'translations/account/finance';
import { Text } from 'components/layout/content/Text';
import { SplitedPrice } from 'components/prices';
import { FlrInput } from 'components/shared/form-elements/textfield';

import { TextBody2 } from 'components/shared/text';
import { AMOUNT_TYPES } from './PaymentModal';

import useStyles from './styles';

interface ILiqpayPaymentContentProps {
  amountType: string;
  setAmountType: React.Dispatch<React.SetStateAction<string>>;
  recommendedAmount: number;
  creditUsed: number;
  amountMinimal: number;
  customAmount: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

type ILiqpayPaymentContent = (props: ILiqpayPaymentContentProps) => JSX.Element;

export const LiqpayPaymentContent: ILiqpayPaymentContent = ({
  amountType,
  setAmountType,
  recommendedAmount,
  creditUsed,
  amountMinimal,
  customAmount,
  handleChange
}) => {
  const classes = useStyles();

  return (
    <DialogContent>
      <TextBody2 className={classes.dialogText}>{messages.balanceModalText.defaultMessage}</TextBody2>
      <div
        className={clsx(classes.balanceModalItem, { active: amountType === AMOUNT_TYPES.recommended })}
        onClick={() => setAmountType(AMOUNT_TYPES.recommended)}
      >
        <Hidden smDown>
          <Radio checked={amountType === AMOUNT_TYPES.recommended} />
        </Hidden>
        <div className={classes.radioWrapper}>
          <Hidden smUp>
            <Radio checked={amountType === AMOUNT_TYPES.recommended} />
          </Hidden>
          <div className={classes.balanceModalText}>
            <div className={classes.balanceModalTextMain}>
              <Text fontSize={'medium'} bold>
                {messages.balanceModalRecommendedAmount.defaultMessage}
              </Text>
            </div>
            <div>
              <Text fontSize={'medium'}>{messages.balanceModalRecommendedAmountCapt.defaultMessage}</Text>
            </div>
          </div>
        </div>
        <div className={clsx(classes.balanceModalAmount, classes.balanceModalAmountText)}>
          <SplitedPrice value={recommendedAmount} /> ₴
        </div>
      </div>

      {creditUsed > 0 && (
        <div
          className={clsx(classes.balanceModalItem, { active: amountType === AMOUNT_TYPES.credit })}
          onClick={() => setAmountType(AMOUNT_TYPES.credit)}
        >
          <Hidden smDown>
            <Radio checked={amountType === AMOUNT_TYPES.credit} />
          </Hidden>
          <div className={classes.radioWrapper}>
            <Hidden smUp>
              <Radio checked={amountType === AMOUNT_TYPES.credit} />
            </Hidden>
            <div className={classes.balanceModalText}>
              <div className={classes.balanceModalTextMain}>
                <Text fontSize={'medium'} bold>
                  {messages.balanceModalCreditAmount.defaultMessage}
                </Text>
              </div>
              <div>
                <Text fontSize={'medium'}>{messages.balanceModalCreditAmountCapt.defaultMessage}</Text>
              </div>
            </div>
          </div>
          <div className={clsx(classes.balanceModalAmount, classes.balanceModalAmountText)}>
            <SplitedPrice value={amountMinimal} /> ₴
          </div>
        </div>
      )}

      <div
        className={clsx(classes.balanceModalItem, { active: amountType === AMOUNT_TYPES.custom })}
        onClick={() => setAmountType(AMOUNT_TYPES.custom)}
      >
        <Hidden smDown>
          <Radio checked={amountType === AMOUNT_TYPES.custom} />
        </Hidden>
        <div className={clsx(classes.balanceModalText, classes.radioWrapper)}>
          <Hidden smUp>
            <Radio checked={amountType === AMOUNT_TYPES.custom} />
          </Hidden>
          <div className={classes.balanceModalTextMain}>
            <Text fontSize={'medium'} bold>
              {messages.balanceModalOther.defaultMessage}
            </Text>
            <TextBody2 color={'textSecondary'}>{messages.balanceModalOtherLabel.defaultMessage}</TextBody2>
          </div>
          <Hidden smDown>
            <ValidatorForm noValidate instantValidate={true} autoComplete="off" onSubmit={() => ({})}>
              <FlrInput
                fullWidth
                min={1}
                variant="outlined"
                name="custom"
                placeholder={messages.balanceModalOtherPlaceholder.defaultMessage}
                value={customAmount}
                onChange={handleChange}
              />
            </ValidatorForm>
          </Hidden>
        </div>
        <Hidden smUp>
          <div className={classes.balanceModalAmount}>
            <ValidatorForm noValidate instantValidate={true} autoComplete="off" onSubmit={() => ({})}>
              <FlrInput
                fullWidth
                min={1}
                variant="outlined"
                name="custom"
                placeholder={messages.balanceModalOtherPlaceholder.defaultMessage}
                value={customAmount}
                onChange={handleChange}
              />
            </ValidatorForm>
          </div>
        </Hidden>
      </div>
    </DialogContent>
  );
};
