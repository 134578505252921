import { ClientProfile, Product, Warehouse, OfferPrices, Offer } from 'models';

export interface IOriginalRequest {
  warehouse: Warehouse;
  supplier: string;
  price: OfferPrices;
  qty: number;
}

export class CartItem {
  public product: Product;
  public offer: Offer;
  public warehouse: Warehouse;
  public customer?: ClientProfile;
  public qty: number;
  public clientPrice: number;
  public discountPerItem: number;
  public discountPercent: number;
  public subtotal: number;
  public total: number;
  public discount: number;
  public offerPrices: OfferPrices;
  public originalRequest: IOriginalRequest;
  public special: boolean;
  public transit: boolean;

  constructor(item: any) {
    this.product = new Product(item.product);
    this.offer = item.offer;
    this.warehouse = item.warehouse;
    this.customer = item.customer;
    this.qty = item.qty;
    this.discount = item.discount;
    this.clientPrice = item.clientPrice;
    this.discountPerItem = item.discountPerItem;
    this.discountPercent = item.discountPercent;
    this.subtotal = item.subtotal;
    this.total = item.total;
    this.offerPrices = item.offer.price;
    this.originalRequest = item.originalRequest || {};
    this.special = item.special;
    this.transit = item.transit;
  }

  public toRequestItem(): IRequestCartItem {
    return {
      qty: this.qty,
      offer: this.offer.id,
      product: this.product
    };
  }

  public get price(): number {
    return this.priceByQty(this.offerPrices, this.qty);
  }

  public get getMinQty(): number {
    if (this.offer.special) {
      return Object.keys(this.offerPrices)
        .map(Number)
        .sort(Number)[0];
    } else if (this.qty === this.product.inPackageCount) {
      return 0;
    } else {
      return this.product.inPackageCount;
    }
  }

  public get originalPrice(): number {
    return this.priceByQty(this.originalRequest.price, this.originalRequest.qty);
  }

  private priceByQty(prices: OfferPrices, qty: number): number {
    if (!prices) {
      return 0;
    }
    const qtyPoints = Object.keys(prices).map(point => parseInt(point, 10));
    // .sort();
    if (!qtyPoints) {
      return 0;
    }
    let price = this.offer.special ? prices[qtyPoints[0]] : prices['0'];
    if (qtyPoints.length) {
      qtyPoints.forEach(point => {
        if (point <= qty) {
          price = prices[point];
        }
      });
    }
    return parseFloat(price);
  }
}

export interface IRequestCartItem {
  qty: number;
  offer: string | undefined;
  product?: Product;
  orderId?: string;
}

export interface IRequestTransitOffer {
  offerId: string;
  qty: number;
}

export interface ICheckTransitResponse {
  handler: 'cart' | 'transit';
  orders?: any[];
}
