import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { palette, typography, shadow } from 'styles/theme/default';

type FlrWithMUI<T, K> =
  | ({
      [P in keyof T]?: T[P];
    } &
      {
        [P in keyof K]?: K[P];
      })
  | any;

interface ICatalogDimensions {
  header: string;
  sortBar: string;
}

export interface ITheme {
  spacing?: any;
  palette: FlrWithMUI<typeof palette, typeof defaultMaterialTheme.palette>;
  typography: FlrWithMUI<typeof typography, typeof defaultMaterialTheme.typography>;
  overrides?: any;
  components?: any;
  props?: any;
  colors: any;
  shadow: typeof shadow;
  scrollBar: any;
  opacities: {
    iconDefault: string;
    iconHover: string;
  };
  letterSpacing: string;
  line: {
    height: string;
  };
  fonts: {
    body: string;
    headings: string;
    monospace: string;
  };
  fontSizes: {
    tiny: string;
    small: string;
    default: string;
    medium: string;
    large: string;
    h1: string;
    h2: string;
    h3: string;
    h4: string;
    h5: string; // coins
    h6: string; // helper text
    caption: string;
    body1: string;
    body2: string;
    subtitle1: string;
    subtitle2: string;
  };
  padding: {
    container: string;
    cards: string;
    card: string;
    cardInner: string;
  };
  containerPadding: string;
  cardContainerPadding: string;
  cardBorderRadius: string;
  buttonPadding: {
    tiny: string;
    small: string;
    default: string;
    medium: string;
    large: string;
  };
  breakpoints: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
    values: {
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
    };
    only?: (key: string) => string;
    down?: (key: string) => string;
    up?: (key: string) => string;
  };
  widths: {
    md: string;
    lg: string;
    xl: string;
  };
  heights: {
    header: string;
    catalog: ICatalogDimensions;
  };
  zIndex?: {
    drawer: number;
    modal: number;
  };
}

export interface IThemed extends React.HTMLAttributes<HTMLElement> {
  theme: ITheme;
  [key: string]: any;
}

export const defaultMaterialTheme = createTheme();
