import { HttpClient } from 'utils/http';

export class MainRepository {
  private client = new HttpClient(`${process.env.REACT_APP_API_ENDPOINT}`);

  public async get(productId: string) {
    return (await this.client.get(`/main/product/${productId}`)).data;
  }

  public async getTopSliders() {
    return (await this.client.get(`/home-page/sliders`)).data;
  }

  public async getHotOffer() {
    return (await this.client.get(`/home-page/offers`)).data;
  }

  public async getFeaturedProductsSections() {
    return (await this.client.get(`/home-page/products`)).data;
  }

  public async getTopSlider() {
    return (await this.client.get(`/main/top-slider`)).data;
  }

  public async getTestimonials() {
    return (await this.client.get(`/main/testimonials`)).data;
  }

  public async getMainMenu() {
    return (await this.client.get(`/catalog/main-menu`)).data;
  }
}
