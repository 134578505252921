export interface IMainPromo {
  title?: string;
  subTitle: string | string[];
  text: string;
  img: any;
}

export class MainPromo {
  public title: string;
  public subTitle?: string | string[];
  public text: string;
  public img: any;

  constructor(promo: any) {
    this.title = promo.title;
    this.subTitle = promo.subTitle;
    this.text = promo.text;
    this.img = promo.img;
  }
}
