import { createReducer, PayloadAction, createAsyncAction } from "typesafe-actions";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  loadedDataWrapper,
  REQUEST_ACTIONS,
  IAsyncDataWrapper,
  loadingDataWrapper,
  errorDataWrapper
} from "store/actions";
import { BalanceHistoryRepository } from "./request";

const httpClient = new BalanceHistoryRepository();

export const prefix = "@@balanceHistory/";

export const BALANCE_HISTORY_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const BALANCE_HISTORY_REQUEST_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const BALANCE_HISTORY_REQUEST_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

export type BalanceHistory = Record<string, number> | null;

interface IBalanceHistoryStateSync {
  balanceHistory: BalanceHistory;
}

export type IBalanceHistoryState = IAsyncDataWrapper<IBalanceHistoryStateSync>;

export const balanceHistoryInitialState: IBalanceHistoryState = {
  loading: false,
  loaded: false,
  data: {
    balanceHistory: null
  },
  error: null
};

type BalanceHistoryActionTypes =
  | typeof BALANCE_HISTORY_REQUEST
  | typeof BALANCE_HISTORY_REQUEST_SUCCESS
  | typeof BALANCE_HISTORY_REQUEST_FAILURE;

export const fetchBalanceHistoryAsync = createAsyncAction(
  BALANCE_HISTORY_REQUEST,
  BALANCE_HISTORY_REQUEST_SUCCESS,
  BALANCE_HISTORY_REQUEST_FAILURE
)<void, BalanceHistory, Error>();

function* balanceHistorySaga(): Generator {
  try {
    const response: any = yield call(() => httpClient.fetch());
    const fakeResponse = {};

    // if (process.env.NODE_ENV === "development") {
    //   let someMonday = moment()
    //     .locale(defaultLocale)
    //     .startOf("isoWeek");
    //   const yearAgo = moment(someMonday).subtract(6, "months");
    //   while (someMonday.isAfter(yearAgo)) {
    //     const value = someMonday.format("MM-DD-Y");
    //     fakeResponse[value] = Math.round(Math.random() * 80000 - Math.random() * 40000);
    //     someMonday = moment(someMonday).subtract(1, "weeks");
    //   }
    // }
    const resultData = { ...response.data, ...fakeResponse };
    yield put(fetchBalanceHistoryAsync.success(resultData));
  } catch (err) {
    yield put(fetchBalanceHistoryAsync.failure(err as Error));
  }
}

export function* balanceHistoryRequestSaga() {
  yield takeEvery(fetchBalanceHistoryAsync.request, balanceHistorySaga);
}

export default createReducer(balanceHistoryInitialState)
  .handleAction(fetchBalanceHistoryAsync.request, (state: IBalanceHistoryState) => {
    return loadingDataWrapper(state.data);
  })
  .handleAction(
    fetchBalanceHistoryAsync.success,
    (state: IBalanceHistoryState, action: PayloadAction<BalanceHistoryActionTypes, BalanceHistory>) =>
      loadedDataWrapper({
        ...state.data,
        balanceHistory: action.payload
      })
  )
  .handleAction(fetchBalanceHistoryAsync.failure, (state: IBalanceHistoryState) =>
    errorDataWrapper({ ...state.data, balanceHistory: null }, new Error("Failed to load balanceHistory"))
  );
