import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "main.subscribe.title",
    description: "main.subscribe.title",
    defaultMessage: "Дізнавайтеся першими про знижки та оновлення"
  },
  subTitle: {
    id: "main.subscribe.subTitle",
    description: "main.subscribe.subTitle",
    defaultMessage: "Підпишіться на розсилку Флоротеки, щоб отримувати корисні новини"
  },
  controlPlaceholder: {
    id: "main.subscribe.controlPlaceholder",
    description: "main.subscribe.controlPlaceholder",
    defaultMessage: "Ваша електронна пошта"
  },
  submit: {
    id: "main.subscribe.submit",
    description: "main.subscribe.submit",
    defaultMessage: "підписатися"
  },
  notificationSuccess: {
    id: "main.subscribe.notificationSuccess",
    description: "main.subscribe.notificationSuccess",
    defaultMessage: "Ви успішно підписалися"
  },
  notificationError: {
    id: "main.subscribe.notificationError",
    description: "main.subscribe.notificationError",
    defaultMessage: "Виникла помилка, будь ласка спробуйте пізніше"
  }
});
