import React from "react";
import Skeleton from '@mui/material/Skeleton';
import { getViewportHeight } from "utils/helpers";

interface IProps {
  headerHeight?: number;
  skeletonHeight?: number;
  withPadding?: boolean;
  withTitle?: boolean;
}

export const FullHeightSkeleton: React.FC<IProps> = ({
  headerHeight = 200,
  skeletonHeight = 200,
  withPadding,
  withTitle = false
}) => {
  const viewportHeight = getViewportHeight() - headerHeight; // Header
  const skeletonsCount = Math.max(Math.floor(viewportHeight / skeletonHeight), 1); // height skeleton
  const padding = 24;
  return <>
    {withTitle && (
      <Skeleton
        variant="rectangular"
        width={"75%"}
        height={40}
        style={{ margin: withPadding ? padding : 0, marginTop: 0, marginBottom: padding }}
      />
    )}
    {[...Array(skeletonsCount)].map((_, i) => (
      <Skeleton
        key={`skeleton-${i}`}
        variant="rectangular"
        width={`calc(100% - ${withPadding ? padding * 2 : 0}px)`}
        height={skeletonHeight}
        style={{ margin: withPadding ? padding : 0, marginTop: 0, marginBottom: padding }}
      />
    ))}
  </>;
};

export default FullHeightSkeleton;
