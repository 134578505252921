const styles = (theme: any) => ({
  common: {
    position: 'relative',
    maxWidth: 'calc(100vw - 32px)',
    width: 560,
    display: 'flex',

    // override material default styles
    flexWrap: 'nowrap !important',
    alignItems: 'flex-start !important',

    borderRadius: theme.spacing(0.5, 0, 0, 0.5),
    border: '1px solid #F9F9F9',
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.text.secondary} !important`,

    '&::before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: 0,
      left: theme.spacing(-0.5),
      width: theme.spacing(0.5),
      height: '100%',
      backgroundColor: theme.palette.info.main
    }
  },
  anchorOriginTopRight: {
    [theme.breakpoints.up('md')]: {
      top: 80
    }
  },
  closeButton: {
    marginTop: 8
  },
  success: {
    '&::before': {
      backgroundColor: theme.palette.success.main
    }
  },
  info: {
    '&::before': {
      backgroundColor: theme.palette.info.main
    }
  },
  warning: {
    '&::before': {
      backgroundColor: theme.palette.warning.main
    }
  },
  error: {
    '&::before': {
      backgroundColor: theme.palette.error.main
    }
  },

  title: {
    margin: 0,
    padding: 0,
    marginBottom: 5,

    color: theme.palette.text.primary,
    fontWeight: 500
  },
  text: {
    margin: 0,
    padding: 0,

    color: theme.palette.text.secondary,
    fontWeight: 400
  }
});

export default styles;
