import * as React from 'react';
import { withTheme } from '@mui/styles';
import { IThemed } from 'utils/styled';
import Button, { ButtonProps } from '@mui/material/Button';
import { PropsWithChildren } from 'react';

export interface IFlrButtonProps {
  borderwidth: number;
  textcolor: string;
  textcolorhover: string;
  bg?: string;
  bghover: string;
  size?: 'small' | 'medium' | 'big';
}

export type IButtonProps = IThemed & ButtonProps & IFlrButtonProps & PropsWithChildren<any>;

class FlrButton extends React.Component<IButtonProps> {
  public static defaultProps = {
    variant: 'text',
    size: 'medium',
    color: 'default'
  };

  public render(): React.ReactNode {
    return <Button {...this.props}>{this.props.children}</Button>;
  }
}

export default withTheme(FlrButton);
