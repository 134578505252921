import styled from '@emotion/styled/macro';
import { IThemed } from '../../../utils/styled';
import { Grid } from '@mui/material';
import { GridProps } from '@mui/material/Grid';

const ContentWrapper = styled<any>('div')(({ theme }: IThemed) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto'
}));

type ISides = 'left' | 'top' | 'bottom' | 'right' | 'auto' | 'x' | 'y';

type PropBoolean = boolean | 'true' | 'false';
interface IPaddedContainer {
  side: ISides[] | ISides;
  padding?: string;
  important?: PropBoolean;
  [key: string]: any;
}

interface ISpacedContainer extends GridProps {
  pSide: ISides[] | ISides;
  mSide: ISides[] | ISides;
  pDistance?: string;
  mDistance?: string;
  [key: string]: any;
}

const checkSide = (sides: ISides[] | ISides) => (side: string) =>
  Array.isArray(sides) ? sides.findIndex(s => s === side) > -1 : sides === side;

const detectDistance = (directions: ISides[] | ISides) => (distance: string | number) => {
  const sides = checkSide(directions);
  const top = sides('top') || sides('y') ? distance : 0;
  const right = sides('right') || sides('x') ? distance : 0;
  const bottom = sides('bottom') || sides('y') ? distance : 0;
  const left = sides('left') || sides('x') ? distance : 0;

  return `${top} ${right} ${bottom} ${left}`;
};

export const PaddedContainer = styled<any>(Grid)(({ theme, padding, side, important }: IThemed & IPaddedContainer) => {
  const distance = padding ? theme.padding[padding] : 0;
  return {
    padding: `${detectDistance(side)(distance)} ${String(important) === 'true' ? ' !important' : ''};`
  };
});

export const SpacedContainer = styled<any>(Grid)(
  ({ theme, pDistance, pSide, mDistance, important, mSide }: IThemed & ISpacedContainer) => {
    const distancePadding = pDistance ? theme.padding[pDistance] : 0;
    const distanceMargin = mDistance ? theme.padding[mDistance] : 0;
    return {
      padding: detectDistance(pSide)(distancePadding),
      margin: detectDistance(mSide)(distanceMargin) + (String(important) === 'true' ? ' !important;' : ';')
    };
  }
);

export const MarginedContainer = styled<any>(Grid)(
  ({ theme, padding, side, important }: IThemed & IPaddedContainer) => {
    const distanceMargin = padding ? theme.padding[padding] : 0;
    return {
      margin: detectDistance(side)(distanceMargin) + (String(important) === 'true' ? ' !important;' : ';')
    };
  }
);

export default ContentWrapper;
