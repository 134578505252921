import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    id: "restorePassword.header",
    description: "restorePassword.header",
    defaultMessage: "Відновити пароль"
  },
  headerCode: {
    id: "restorePassword.headerCode",
    description: "restorePassword.headerCode",
    defaultMessage: "Введіть код"
  },

  headerChange: {
    id: "account.settings.headerChange",
    description: "account.settings.headerChange",
    defaultMessage: "Змінити пароль"
  },

  alreadyRegistered: {
    id: "restorePassword.alreadyRegistered",
    description: "restorePassword.alreadyRegistered",
    defaultMessage: "Згадали пароль?"
  },
  register: {
    id: "restorePassword.register",
    description: "Registration link label",
    defaultMessage: "Реєстрація"
  },
  login: {
    id: "restorePassword.login",
    description: "restorePassword.login",
    defaultMessage: "Увійти"
  },
  formFieldLogin: {
    id: "restorePassword.form.login",
    description: "restorePassword.form.login",
    defaultMessage: "Логін"
  },
  formFieldLoginError: {
    id: "restorePassword.formFieldLogin",
    description: "restorePassword.formFieldLogin",
    defaultMessage: "Такий користувач не знайден"
  },

  formFieldCode: {
    id: "restorePassword.form.formFieldCode",
    description: "restorePassword.form.formFieldCode",
    defaultMessage: "Код"
  },
  formFieldCodeError: {
    id: "restorePassword.form.formFieldCodeError",
    description: "restorePassword.form.formFieldCodeError",
    defaultMessage: "Невірний код"
  },

  formFieldCodePassword: {
    id: "restorePassword.form.formFieldCodePassword",
    description: "restorePassword.form.formFieldCodePassword",
    defaultMessage: "Новий пароль"
  },
  formFieldCodePasswordConfirm: {
    id: "restorePassword.form.formFieldCodePasswordConfirm",
    description: "restorePassword.form.formFieldCodePasswordConfirm",
    defaultMessage: "Підтвердження пароля"
  },
  formFieldCodePasswordError: {
    id: "restorePassword.form.formFieldCodePasswordConfirm",
    description: "restorePassword.form.formFieldCodePasswordConfirm",
    defaultMessage: "Виникла помилка, спробуйте пізнише"
  },

  formRestorePasswordButton: {
    id: "restorePassword.form.loginButton",
    description: "restorePassword.form.loginButton",
    defaultMessage: "Відновити"
  },
  formCheckCodeButton: {
    id: "restorePassword.form.formCheckCodeButton",
    description: "restorePassword.form.formCheckCodeButton",
    defaultMessage: "Перевірити"
  },
  formLoginError: {
    id: "restorePassword.form.loginError",
    description: "restorePassword.form.loginError",
    defaultMessage: "Невірні дані"
  }
});
