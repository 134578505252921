import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import clsx from 'clsx';
import { Button, Drawer, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';

import AsideDeviceTopPanel from 'components/layout/aside-device/header';
import FlrSelect2 from 'components/shared/form-elements/select2/FlrSelect2';
import Icon from 'components/shared/Icon';
import { TextBody2, TextSubTitle } from 'components/shared/text';
import { baseUrl, catalogTypeSlugByCode } from 'shared/constants';
import { getCatalogFilters } from 'store/catalog/selectors';
import { getFilterOptionsData, getFilterOptionsLoadingState } from 'store/filter-options/selectors';
import messages from 'translations/catalog/search';

import SearchControl from './SearchControl';
import styles from './styles';

interface IProps {
  classes?: any;
}

const HeaderSearch: React.FC<IProps> = ({ classes }) => {
  const filters = useSelector(getCatalogFilters);
  const filterOptionsData = useSelector(getFilterOptionsData);
  const filterOptionsLoading = useSelector(getFilterOptionsLoadingState);
  const { productType } = filters;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => setDrawerOpen((state) => !state);
  const isCatalog = useRouteMatch(`${baseUrl}/catalog/:type?/:filter?`);

  const types = filterOptionsData.type;
  const typesOptions =
    types && types.map((item) => ({ label: item.name, value: item.code, slug: catalogTypeSlugByCode[item.code] }));

  const productTypeCode = typesOptions && typesOptions.find((typeItem: any) => typeItem.slug === productType);

  const [typeData, setTypeData] = useState({});
  const [type, setType] = useState((productTypeCode && productTypeCode.value) || '');

  const newProductType = (productTypeCode && productTypeCode.value) || '';

  useEffect(() => {
    setType(newProductType);
  }, [filterOptionsData, newProductType]);

  useEffect(() => {
    if (!type && typesOptions && typesOptions[0]) {
      setTimeout(() => {
        setType(typesOptions[0].value);
        setTypeData(typesOptions[0]);
      }, 0);
    }
  }, [type, typesOptions]);

  const handleChangeProductType = (ev: any) => {
    setTypeData(ev.target.data);
    setType(ev.target.value);
  };

  return (
    <div className={classes.searchBlock}>
      {isCatalog ? (
        <div>
          <Button className={classes.searchCatalogButton} fullWidth variant={'outlined'} onClick={toggleDrawer}>
            <TextBody2 className={classes.searchCatalogButtonText}>
              {messages.mobilePlaceholder.defaultMessage}
            </TextBody2>
            <Icon type={'search'} size={24} />
          </Button>
        </div>
      ) : (
        <IconButton onClick={toggleDrawer} size="large">
          <Icon type={'search'} size={24} opacity={1} />
        </IconButton>
      )}

      <Drawer
        open={drawerOpen}
        anchor={'top'}
        classes={{
          paper: classes.mobileDrawer
        }}
      >
        <AsideDeviceTopPanel title={messages.mobileTitle.defaultMessage} onClose={toggleDrawer} />

        <div className={classes.mobileContent}>
          <TextSubTitle className={classes.mobileSubTitle}>{messages.mobileSubTitle.defaultMessage}</TextSubTitle>

          <FlrSelect2
            isLoading={filterOptionsLoading}
            fullWidth
            className={clsx(classes.mobileCategory, 'search-select')}
            disableWrapper
            value={type}
            options={typesOptions || []}
            onChange={handleChangeProductType}
          />

          <SearchControl
            types={types}
            categoryType={type}
            categoryTypeData={typeData}
            toggleDrawer={toggleDrawer}
            mobileDevice
          />
        </div>
      </Drawer>
    </div>
  );
};

export default withStyles<any>(styles)(HeaderSearch);
