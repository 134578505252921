import { ReactNode } from "react";
import { MessageDescriptor } from "react-intl";
import React from "react";
import FlrStepperHeader from "./FlrStepperHeader";

import LoadingSpinner from "components/loading/LoadingSpinner";

export interface IFlrStepperState {
  steps: IFlrSteps;
  skipped: Set<number>;
  activeStep: number;
}

export interface IFlrStepButtonActions {
  next: () => void;
  skip?: () => void;
  prev?: () => void;
  classes?: any;
}

export interface IFlrStepperProps {
  steps: IFlrSteps;
  goToStep?: number;
  storeLastCompletedStepByKey?: string;
}

export interface IFlrSteps {
  main: IFlrStep[];
  final?: ReactNode;
}

export interface IFlrStep {
  title: MessageDescriptor;
  isRequired?: boolean;
  content: any;
  labels?: IFlrStepButtonLabels;
  actions?: IFlrStepButtonActions;
}

export interface IFlrStepButtonLabels {
  next: MessageDescriptor;
  skip?: MessageDescriptor;
  prev?: MessageDescriptor;
}

export type TypeStepContent = React.ComponentClass<any> | React.FunctionComponent<any> | string;

function renderStep(elem: TypeStepContent, stepProps: any = {}) {
  return React.createElement(elem, { ...stepProps });
}

class FlrStepper extends React.Component<IFlrStepperProps, IFlrStepperState> {
  /*private handleReset = () => {
    this.setActiveStep(0);
  };*/

  get isFinalStep(): boolean {
    return this.state.activeStep === this.state.steps.main.length;
  }

  get isFirstStep(): boolean {
    return this.state.activeStep === 0;
  }
  constructor(props: IFlrStepperProps) {
    super(props);

    const switchKey = this.props.storeLastCompletedStepByKey;
    const lastCompletedStep = switchKey && switchKey.length ? window.localStorage.getItem(switchKey) : 0;
    const activeStep = switchKey && switchKey.length && lastCompletedStep ? parseInt(lastCompletedStep, 10) : 0;

    this.state = {
      steps: props.steps || { main: [] },
      activeStep: props.goToStep || activeStep,
      skipped: new Set<number>()
    };
  }

  public handleNext = () => {
    const activeStep = this.state.activeStep;
    const nextStep = activeStep + 1;

    if (this.isStepSkipped(activeStep)) {
      this.unsetSkipped(activeStep);
    }
    if (!this.isFinalStep) {
      this.setActiveStep(nextStep);
    }

    const switchKey = this.props.storeLastCompletedStepByKey;
    if (switchKey && switchKey.length) {
      if (!this.isFinalStep) {
        window.localStorage.setItem(switchKey, nextStep.toString());
      } else {
        window.localStorage.removeItem(switchKey);
      }
    }
  };

  public handlePrev = () => {
    if (!this.isFirstStep) {
      this.setActiveStep(this.state.activeStep - 1);
    }
  };

  public handleSkip = () => {
    if (!this.isStepOptional(this.state.activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    this.setSkipped(this.state.activeStep);
    this.setActiveStep(this.state.activeStep + 1);
  };

  public render(): React.ReactNode {
    const { steps, activeStep } = this.state;
    const { main, final } = steps;

    const StepContent: TypeStepContent = (!this.isFinalStep
      ? main[activeStep].content
      : final
      ? (final as TypeStepContent)
      : LoadingSpinner) as TypeStepContent;

    return (
      <div>
        <FlrStepperHeader main={main} activeStep={activeStep} />
        <div>{renderStep(StepContent, { next: this.handleNext, prev: this.handlePrev, skip: this.handleSkip })}</div>
      </div>
    );
  }

  private isStepOptional = (step: number) => {
    return step === 0 || !this.state.steps.main[step].isRequired;
  };

  private isStepSkipped = (step: number) => {
    return this.state.skipped.has(step);
  };

  private setActiveStep = (step: number) => {
    this.setState({ activeStep: step });
  };

  private setSkipped = (step: number) => {
    this.state.skipped.add(step);
  };

  private unsetSkipped = (step: number) => {
    this.state.skipped.delete(step);
  };
}

export default FlrStepper;
