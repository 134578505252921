import { IClientDiscount, IConditions, IDiscountData, IDiscountHistory } from './discount-conditions';

export interface ICurrentDiscount {
  discountType: string;
  endDate: string;
  startDate: string;
  percent: number;
  progressValue: number;
  requiredValue: number;
}

export interface IBalance {
  allCatalog: boolean;
  categories: string[];
  conditions: IConditions;
  currentDiscount: ICurrentDiscount;
  discountType: string;
  endDate: string;
  history: IDiscountHistory[];
  isActive: boolean;
  name: string;
  nextDiscountProgress: IDiscountData;
  offersType: string[];
  productType: string[];
  progressValue: number;
  startDate: string;
}

export interface IPurchasedQty {
  allCatalog: boolean;
  categories: string[];
  conditions: IConditions;
  currentDiscount: ICurrentDiscount;
  description: string;
  discountType: string;
  endDate: string;
  history: IDiscountHistory[];
  image: string;
  name: string;
  isActive: boolean;
  nextDiscountProgress: IDiscountData;
  offersType: string[];
  productType: string[];
  progressValue: number;
  startDate: string;
}

export interface IDiscountCondition {
  id: string;
  allCatalog: boolean;
  categories: string[];
  conditions: IConditions;
  description: string;
  discountType: string;
  discountData: {
    nextDiscountProgress: IDiscountData;
    progressValue: number;
  };
  endDate: string;
  name: string;
  offersType: string[];
  productType: string[];
  products: string[];
  startDate: string;
  image?: string;
  requireAmount?: number;
}

export interface IAccountDiscounts {
  balance: IBalance[];
  'purchased-qty': IPurchasedQty[];
  static: any[];
  website: any[];
}

export class AccountDiscounts {
  public balance: IBalance[];
  public 'purchased-qty': IPurchasedQty[];
  public static?: any[];
  public website: any[];
  public permanentDiscounts: IDiscountCondition[];
  public accountBalanceDiscount: IClientDiscount | undefined;
  constructor(discounts: IAccountDiscounts) {
    this.balance = discounts.balance;
    this['purchased-qty'] = discounts['purchased-qty'];
    this.static = discounts.static;
    this.website = discounts.website;
    this.permanentDiscounts = [
      ...(Array.isArray(discounts.static) ? discounts.static : []),
      ...(Array.isArray(discounts.website) ? discounts.website : [])
    ];
    this.accountBalanceDiscount = (() => {
      let discount: any = null;
      discount = {
        ...this.balance[0],
        discountData: this.balance[0] && this.balance[0] ? this.balance[0].nextDiscountProgress : {}
      };
      return discount;
    })();
  }
}
