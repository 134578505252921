import React from 'react';
import clsx from 'clsx';
import { Tooltip as MuiTooltip, ClickAwayListener } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import { withStyles } from '@mui/styles';
import { iOS, isMobileDevice } from 'environment';
import { ITheme } from 'utils/styled';

const isMobile = isMobileDevice(window.navigator);

interface IProps extends TooltipProps {
  classes: any;
  icon?: any;
  isControlled?: boolean;
}

const isIOS = iOS(window.navigator.userAgent)

const Tooltip: React.FC<IProps> = ({ children, classes, title, icon, isControlled = isMobile }) => {
  const [open, setOpen] = React.useState(false);

  const toggleTooltipOpen = (e: React.SyntheticEvent<HTMLButtonElement, Event>) => {
    e.stopPropagation();
    e.preventDefault();
    if (isMobile) {
      setOpen(!open);
    } else {
      setOpen(true);
    }
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const controlledProps = {
    onClose: handleTooltipClose,
    open,
    disableHoverListener: true,
    disableTouchListener: true
  };

  const appliedProps = isControlled ? { ...controlledProps } : {};
  const titleContent = icon ? (
    <div className={classes.rootIcon}>
      <div className={classes.icon}>{icon}</div>
      <div className={classes.rootTitle}>{title}</div>
    </div>
  ) : (
    title
  );

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <MuiTooltip
        className={classes.root}
        disableFocusListener
        PopperProps={{
          disablePortal: true
        }}
        classes={{
          tooltip: classes.tooltip || '',
          popper: classes.popper || ''
        }}
        placement={isIOS ? 'top' : 'bottom'}
        title={titleContent}
        {...appliedProps}
      >
        {isControlled ? (
          <button className={clsx(classes.button, classes.controlledWrapper)} onClick={toggleTooltipOpen}>
            {children}
          </button>
        ) : (
          children
        )}
      </MuiTooltip>
    </ClickAwayListener>
  );
};

const styles = (theme: ITheme) => ({
  tooltip: {
    padding: 16
  },
  rootIcon: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  icon: {
    color: theme.palette.secondary.main,
    lineHeight: 1
  },
  button: {
    border: 'none',
    margin: 0,
    padding: 0,
    width: 'auto',
    overflow: 'visible',
    background: 'transparent',
    color: 'inherit',
    font: 'inherit',
    lineHeight: 'normal',

    '&:focus': {
      outline: 'none'
    }
  },
  rootTitle: {},
  controlledWrapper: {},
  popper: {}
});

export default withStyles<any>(styles)(Tooltip);
