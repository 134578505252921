import { FC } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import Icon from 'components/shared/Icon';
import { TextHelper } from 'components/shared/text';
import { getCatalogFilters } from 'store/catalog/selectors';
import messages from 'translations/account/menu';

import classes from './FooterDevice.module.scss';

export interface IUrlData {
  slug: string;
  icon: string;
  text: string;
}

const urls: IUrlData[] = [
  {
    slug: 'catalog',
    icon: 'catalog',
    text: messages.catalog.defaultMessage
  },
  {
    slug: '/account/finance',
    icon: 'finance',
    text: messages.finance.defaultMessage
  },
  {
    slug: '/cart',
    icon: 'cart',
    text: messages.cart.defaultMessage
  },
  {
    slug: '/account/orders',
    icon: 'delivery',
    text: messages.orders.defaultMessage
  }
];

interface IFooterDeviceProps {
  classNameWrapper?: string;
}

const FooterDevice: FC<IFooterDeviceProps> = ({ classNameWrapper }) => {
  const filters = useSelector(getCatalogFilters);

  const beautifyUrl = () => {
    const {
      inStock,
      productType,
      preOrder,
      discount,
      supplier: filterSupplier,
      specialOffers,
      transitOffers,
      specialTransitOffers,
      deliveryRegion
    } = filters;

    const queryStr = `${inStock ? 'inStock=1;' : ''}${preOrder ? 'preOrder=1;' : ''}${discount ? 'discount=1;' : ''}${
      filterSupplier ? 'supplier=' + filterSupplier + ';' : ''
    }${deliveryRegion ? 'deliveryRegion=' + deliveryRegion + ';' : ''}${specialOffers ? 'specialOffers=1;' : ''}${
      transitOffers ? 'transitOffers=1;' : ''
    }${specialTransitOffers ? 'specialTransitOffers=1;' : ''}`;

    return `/catalog/${productType || 'shear-flowers'}/${queryStr}?`;
  };

  const getUrl = (slug: string): string => {
    if (slug === 'catalog') {
      return beautifyUrl();
    }
    return slug;
  };

  const openChat = () => {
    const rngstBtn = document.querySelector('.rngst_phone_button');
    if (rngstBtn) {
      rngstBtn.classList.toggle('dropdownMessengers-show');
    }
  };

  return (
    <footer className={clsx(classes.footerMobile, { classNameWrapper })}>
      <div className={classes.wrapper}>
        {urls.map((urlData, index) => {
          return (
            <div key={`footer-nav-${index}`} className={classes.item}>
              <NavLink to={getUrl(urlData.slug)} className={classes.link} activeClassName={classes.active}>
                <Icon type={urlData.icon} />
                <TextHelper className={classes.label} color="inherit">
                  {urlData.text}
                </TextHelper>
              </NavLink>
            </div>
          );
        })}
        <div className={classes.link} onClick={openChat}>
          <Icon type="feedback" size={32} />
        </div>
      </div>
    </footer>
  );
};

export default FooterDevice;
