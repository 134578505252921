import { FC } from 'react';
import { defineMessages } from 'react-intl';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { useHistory } from 'react-router';
import { generatePath, NavLink } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import Icon from 'components/shared/Icon';
import { TextBody2 } from 'components/shared/text';
import { baseUrl, defaultCatalogProductType } from 'shared/constants';
import { fetchCatalogAsync, filterAndSortCatalog, ICatalogRequest, IFiltersData } from 'store/catalog/actions';
import { getCatalogFilters, getCatalogSort } from 'store/catalog/selectors';
import { IApplicationState } from 'store/reducers';

import classes from './HeaderTopFavorites.module.scss';

interface IOwnProps {}

interface IStateProps {
  // Props passed to the component by `connect`
  sort: string;
  locale: string;
  filters: IFiltersData;
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
  filterAndSortCatalog: typeof filterAndSortCatalog;
  loadCatalog: typeof fetchCatalogAsync.request;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

export const favoritesMessages = defineMessages({
  label: {
    id: 'catalog.header.favoritesLabel',
    description: '',
    defaultMessage: 'Обрані'
  }
});

const HeaderTopFavorites: FC<IProps> = ({
  sort,
  locale,
  filters,
  loadCatalog,
  filterAndSortCatalog: loadFilterOptions
}) => {
  const history = useHistory();
  const updateCatalog = (updatedParams: Partial<ICatalogRequest>) => {
    const params = {
      ...filters,
      ...updatedParams
    };
    loadCatalog(params);
    const url = generatePath(`${baseUrl}/catalog/:productType/:inStock?`, {
      productType: defaultCatalogProductType,
      inStock: undefined
    });
    history.push(url);
    window.history.pushState(params, document.title, url);
  };

  const handleWatchOnlyChange = () => {
    const defaultFilters = {
      inStock: true,
      discount: false,
      preOrder: false,
      supplier: ''
    };

    updateCatalog({ ...defaultFilters, watchOnly: true });
    loadFilterOptions({ ...defaultFilters, sort, locale, watchOnly: true, fast: [] });
  };

  return (
    <div onClick={handleWatchOnlyChange}>
      <NavLink className={classes.favoritesLink} to={`${baseUrl}/catalog/${defaultCatalogProductType}`}>
        <>
          <Icon type="heart" size={24} className={classes.icon} />
          <TextBody2 className={classes.label}>{favoritesMessages.label.defaultMessage}</TextBody2>
        </>
      </NavLink>
    </div>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state: IApplicationState) => ({
  locale: state.locale,
  sort: getCatalogSort(state),
  filters: getCatalogFilters(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      filterAndSortCatalog,
      loadCatalog: fetchCatalogAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTopFavorites);
