import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { Button, Grid, Hidden } from '@mui/material';

import { attributesToFilter, IAttribute, Product } from 'models';
import { selectProductAsync } from 'store/catalog/actions';
import { IHotOffer } from 'store/main/actions';
import { TitleH1, TitleH2, TextBody1, TextBody2, TextCaption, TextHelper, TextSubTitle } from 'components/shared/text';
import Icon from 'components/shared/Icon';
import { ProductCardContainer } from 'components/cards/product/styles';

import messages from 'translations/main/hotDeal';
import env from '../../../environment';
import { baseUrl, catalogTypeSlugByCode } from 'shared/constants';

interface IProps {
  hotOffer: IHotOffer;
  selectProduct: typeof selectProductAsync.request;
  classes: any;
}
const HotOfferItem: React.FC<IProps> = ({ hotOffer, classes, selectProduct }) => {
  const hotOfferProduct = hotOffer && hotOffer.product;
  const totalPropositions =
    hotOfferProduct && hotOfferProduct.offers && hotOfferProduct.offers.reduce((acc, item) => +acc + +item.inStock, 0);

  const onExpire = () => {
    // console.log("-hot deal--expired;-");
  };

  const expiredDate = new Date(hotOffer.expiredTime);

  const { seconds: secondsLeft, minutes: minutesLeft, hours: hoursLeft, days: daysLeft } = useTimer({
    expiryTimestamp: +expiredDate as any,
    onExpire
  });

  if (+expiredDate < Date.now()) {
    return null;
  }

  let hours: number | string = +hoursLeft.toFixed(0) + daysLeft * 24;
  const showDays = hours > 99;
  let minutes = minutesLeft.toFixed(0);
  let seconds = secondsLeft.toFixed(0);

  if (hours < 10) {
    hours = `0${hours}`;
  } else if (showDays) {
    hours = hoursLeft.toFixed(0);
    if (hours.length === 1) {
      hours = `0${hours}`;
    }
  }

  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }
  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }

  if (!hotOfferProduct) {
    return null;
  }

  const productParams: IAttribute[] = [];
  Object.keys(hotOffer.product.attributes).forEach(attributeKey => {
    const attribute = hotOfferProduct.getAttribute(attributeKey);
    if (attribute.value) {
      productParams.push(attribute);
    }
  });

  const [firstAttr, ...otherAttrs] = productParams
    .filter(({ code }) => !attributesToFilter.includes(code))
    .splice(0, 5);

  const catalogSlug = catalogTypeSlugByCode[hotOfferProduct.productTypeCode];
  const handleSelectProduct = (product: Product) => {
    selectProduct(product);
  };

  return (
    <Grid
      container
      className={classes.container}
      style={{
        backgroundColor: hotOffer.color,
        backgroundImage: `url(${env.apiUrl}/static/offer-images/${hotOffer.image}.png)`
      }}
    >
      <Hidden mdDown>
        <Grid item sm={1} />
      </Hidden>
      <Grid className={classes.content} item lg={4}>
        <TitleH2 className={classes.title}>{messages.title.defaultMessage}</TitleH2>

        <div className={classes.product}>
          <div className={classes.discount}>
            <TitleH1 component={'span'}>{hotOfferProduct.discount * -1 || 0}%</TitleH1>
          </div>

          <ProductCardContainer>
            <div>
              <div className={clsx('manufacturer-container', classes.manufacturerContainer)}>
                {hotOfferProduct.getAttribute('country') && hotOfferProduct.getAttribute('country').value && (
                  <FlagIcon
                    className="manufacturer-flag"
                    code={hotOfferProduct.getAttribute('country').value as FlagIconCode}
                    size={16}
                  />
                )}
                <TextCaption className={clsx('manufacturer-label', classes.manufacturerLabel)}>
                  {hotOfferProduct.getAttribute('manufacturer').value}
                </TextCaption>
              </div>

              <div className={clsx('main-container', classes.mainContainer)}>
                <TextSubTitle className={clsx('main-type', classes.mainType)}>
                  {hotOfferProduct.catalogCategory}
                </TextSubTitle>

                <TitleH1 className={clsx('main-title', classes.mainTitle)}>{hotOfferProduct.fullName}</TitleH1>

                <TextBody1 className={clsx('main-color', classes.mainColor)}>
                  {hotOfferProduct.getAttribute('color').value}
                </TextBody1>
              </div>

              <div className={clsx('attributes-container', classes.attributesContainer)}>
                {firstAttr && (
                  <div className={clsx('params', classes.params)}>
                    <div className={clsx('param height', classes.paramHeight)}>
                      <Icon type={'height'} size={24} offset={8} />
                      <div>
                        {firstAttr.value} {firstAttr.format}
                      </div>
                    </div>

                    {otherAttrs.map((param, key) => (
                      <div className="param" key={key}>
                        <TextHelper className="param-label">{param.name}</TextHelper>
                        <TextBody2 className="param-value">
                          {param.value} {param.format}
                        </TextBody2>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </ProductCardContainer>

          <div className={classes.productBottom}>
            <TextCaption className={classes.productBottomPropositions}>
              {messages.totalPropositions.defaultMessage}: {totalPropositions}
            </TextCaption>

            <Button
              color={'primary'}
              variant={'contained'}
              component={NavLink}
              to={`${baseUrl}/catalog/${catalogSlug}`}
            >
              <div onClick={() => handleSelectProduct(hotOfferProduct)}>{messages.buy.defaultMessage}</div>
            </Button>
          </div>

          <div className={classes.timer}>
            {showDays && (
              <div className={classes.timerItem}>
                <TitleH1 className={classes.timerValue} component={'div'}>
                  {daysLeft}
                </TitleH1>
                <TextBody1 className={classes.timerLabel} component={'div'}>
                  {messages.timerDays.defaultMessage}
                </TextBody1>
              </div>
            )}
            <div className={classes.timerItem}>
              <TitleH1 className={classes.timerValue} component={'div'}>
                {hours}
              </TitleH1>
              <TextBody1 className={classes.timerLabel} component={'div'}>
                {messages.timerHours.defaultMessage}
              </TextBody1>
            </div>
            <div className={classes.timerItem}>
              <TitleH1 className={classes.timerValue} component={'div'}>
                {minutes}
              </TitleH1>
              <TextBody1 className={classes.timerLabel} component={'div'}>
                {messages.timerMinutes.defaultMessage}
              </TextBody1>
            </div>
            {!showDays && (
              <div className={classes.timerItem}>
                <TitleH1 className={classes.timerValue} component={'div'}>
                  {seconds}
                </TitleH1>
                <TextBody1 className={classes.timerLabel} component={'div'}>
                  {messages.timerSeconds.defaultMessage}
                </TextBody1>
              </div>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default HotOfferItem;
