import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { withTheme } from '@mui/styles';
import Icon from 'components/shared/Icon';

export interface IFlrCheckboxProps extends CheckboxProps {
  label?: React.ReactNode | string;
  onChange?: any;
  classNameLabel?: string;
  classes?: object;
}

function getCheckboxType(checked: boolean | undefined, indeterminate: boolean | undefined) {
  if (indeterminate) {
    return 'checkboxInter';
  } else if (checked) {
    return 'checkboxChecked';
  } else {
    return 'checkbox';
  }
}

function FlrCheckboxWithLabel(props: React.PropsWithChildren<IFlrCheckboxProps>) {
  const { label, onChange, classNameLabel, checked, classes, ...other } = props;

  const onChangeBoolean = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    return onChange && onChange(event, checked);
  };

  const checkbox = (
    <Checkbox
      {...other}
      checked={checked}
      onChange={onChangeBoolean}
      icon={<Icon opacity={1} size={24} type={getCheckboxType(checked, other.indeterminate)} />}
    />
  );

  if (!label) {
    return checkbox;
  }

  const labelVal = (typeof label === 'string' || React.isValidElement(label)) && label;

  // prevent input jumps when no helper text provided
  return <FormControlLabel className={classNameLabel} control={checkbox} label={labelVal} classes={classes} />;
}

export default withTheme(FlrCheckboxWithLabel);
