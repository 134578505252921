import { defineMessages } from "react-intl";

export default defineMessages({
  loginToBuy: {
    id: "catalog.details.loginToBuy",
    description: "Щоб купити увійдіть або зареєструйтесь",
    defaultMessage: "Щоб купити увійдіть або зареєструйтесь"
  },
  // stockIsNotEnough: {
  //   id: "catalog.details.stockIsNotEnough",
  //   description: "Недостатня кількість товару в наявності",
  //   defaultMessage: "Недостатня кількість товару в наявності"
  // },
  notInStock: {
    id: "catalog.details.notInStock",
    description: "catalog.details.notInStock",
    defaultMessage: "Немає в наявності"
  }
});
