import React, { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Grid, Button, Dialog, DialogContent, DialogActions, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form';

import { Outlet } from 'models/outlet';
import { GTM } from 'controllers';
import { addOutletAsync, fetchOutletsAsync, updateOutletAsync } from 'store/outlets/actions';
import { getOutletsData, getOutletsLoadingState } from 'store/outlets/selectors';
import { IApplicationState } from 'store/reducers';
import FlrLoader from 'components/loading/LoadingSpinner';
import { TextBody2 } from 'components/shared/text';
import FlrDialogTitle from 'components/shared/modals/components/DialogTitle';
import messages from 'translations/account/settings';
import messagesControls from 'translations/layout/controls';
import styles from './styles';
import { clearPopovers, validatePhoneMask } from 'utils/helpers';
import FlrInputPhone from 'components/shared/form-elements/textfield/FlrInputPhone';
import AddressAutocomplete, { parseGeoData } from '../../form-elements/address/AddressAutocomplete';
import { omit, isFunction } from 'lodash';
import FlrCheckboxWithLabel from '../../form-elements/FlrCheckbox';

interface IProps {
  // component own props
  classes: any;
  open: boolean;
  closePopOvers?: boolean;
  toggle: () => void;
  id?: string;
  onSave?: () => void;
  onUpdate?: () => void;
  onSuccess?: (arg1: Outlet) => void;
}

interface IStateProps {
  // Props passed to the component by `connect`
  outlets: Outlet[];
  outletsLoadingState: boolean;
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
  loadOutlets: typeof fetchOutletsAsync.request;
  addOutlet: typeof addOutletAsync.request;
  updateOutlet: typeof updateOutletAsync.request;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

const initialStage = {
  name: '',
  region: '',
  city: '',
  street: '',
  building: '',
  unit: '',
  room: '',
  latitude: '',
  longitude: '',
  isDefault: false,
  customAddress: false,
  map_address: null,
  phone: ''
};
interface IFormData {
  name: string;
  region: string;
  city: string;
  street: string;
  building: string;
  unit: string;
  room: string;
  latitude: string;
  longitude: string;
  isDefault: boolean;
  customAddress: boolean;
  map_address: object | null;
  phone: string;
}

const OutletModal: React.FC<IComponentProps> = ({
  id,
  classes,
  addOutlet: saveOutlet,
  updateOutlet,
  outlets,
  outletsLoadingState,
  toggle,
  loadOutlets,
  closePopOvers,
  open,
  onUpdate,
  onSuccess
}) => {
  const outletToEdit = id && outlets && outlets.length && outlets.find(item => item.id === id);

  React.useEffect(() => {
    if (id && !outlets && outletsLoadingState) {
      loadOutlets();
    }
  }, [outlets, outletsLoadingState, loadOutlets, id]);

  const defaultValues = outletToEdit || initialStage;
  const { handleSubmit, errors, control, reset, setValue, getValues } = useForm<IFormData>({
    defaultValues: defaultValues || {}
  });

  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const defaultValuesMemo = React.useMemo(() => defaultValues, [defaultValues]);

  useEffect(() => {
    if (defaultValuesMemo) {
      reset(defaultValuesMemo);
    }
  }, [id, reset, defaultValuesMemo]);

  const onCancel = () => {
    reset(initialStage);
    toggle();
    clearPopovers(closePopOvers);
  };

  const onSubmit = handleSubmit((fields): any => {
    if (disableSubmit) {
      return;
    }
    setDisableSubmit(true);

    const newOutlet = id
      ? new Outlet(omit({ ...fields, id }, ['map_address']))
      : new Outlet(omit({ ...fields }, ['map_address']));

    // @ts-ignore
    id ? updateOutlet(newOutlet) : saveOutlet({ ...newOutlet, _then: onSuccess });
    setDisableSubmit(false);
    if (isFunction(onUpdate)) {
      onUpdate();
    }

    GTM.trackEnterDeliveryAddress();
    onCancel();
  });

  return (
    <Dialog
      open={open}
      // onClose={onCancel}
      aria-labelledby="scroll-dialog-title"
      scroll={'body'}
      classes={{
        paperScrollBody: classes.dialogContainer
      }}
    >
      <FlrDialogTitle text={id ? messages.editOutletModalTitle : messages.addOutletModalTitle} />
      <DialogContent>
        {!id && (
          <TextBody2 className={classes.dialogCaption} align={'center'}>
            {messages.addOutletCaption.defaultMessage}
          </TextBody2>
        )}

        <form onSubmit={onSubmit} noValidate>
          <Grid container spacing={2} className={classes.addFormContainer} style={{ overflow: 'hidden' }}>
            <Grid item xs={12}>
              <Controller
                error={Boolean(errors.name)}
                helperText={errors.name && errors.name.message}
                rules={{
                  required: messagesControls.requiredFiled.defaultMessage
                }}
                required
                as={TextField}
                name="name"
                control={control}
                className={classes.commonControl}
                fullWidth
                variant={'outlined'}
                label={messages.name.defaultMessage}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                error={Boolean(errors.phone)}
                helperText={errors.phone && errors.phone.message}
                rules={{
                  required: messagesControls.requiredFiled.defaultMessage,
                  validate: validatePhoneMask
                }}
                required
                as={FlrInputPhone}
                name="phone"
                control={control}
                className={classes.commonControl}
                fullWidth
                variant={'outlined'}
                label={messages.phone.defaultMessage}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                error={Boolean(errors.map_address)}
                helperText={errors.map_address && errors.map_address.message}
                as={AddressAutocomplete}
                name="map_address"
                control={control}
                rules={{
                  validate: () => {
                    const values = getValues();
                    const fieldsToValidate = [
                      {
                        name: 'city',
                        label: 'місто'
                      },
                      {
                        name: 'region',
                        label: 'регіон'
                      },
                      {
                        name: 'street',
                        label: 'вулицю'
                      },
                      {
                        name: 'building',
                        label: 'будинок'
                      }
                    ];

                    // check address full value
                    for (let i = 0; i < fieldsToValidate.length; i++) {
                      const field = fieldsToValidate[i];
                      const value = values[field.name];
                      if (!value) {
                        return `Це поле повинно містити ${field.label}`;
                      }
                    }

                    // check address duplication
                    if (
                      outlets.find(
                        outletItem =>
                          outletItem !== outletToEdit && // skip current outlet
                          // check on all-fields duplicate
                          outletItem.city === values.city &&
                          outletItem.building === values.building &&
                          outletItem.region === values.region &&
                          outletItem.street === values.street
                      )
                    ) {
                      return 'Ця адреса вже iснує';
                    }

                    return true;
                  }
                }}
                setValue={setValue}
                fullWidth
                outlet={outletToEdit}
                variant={'outlined'}
                label={messages.address.defaultMessage}
                geo={null} // not storing for now
                setGeo={(geo: any, requestedValue: string) => parseGeoData(geo, requestedValue, setValue)}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={FlrCheckboxWithLabel}
                name="isDefault"
                control={control}
                className={classes.checkbox}
                label={'Основний'}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'none' }}>
              <Controller
                as={TextField}
                name="street"
                control={control}
                className={classes.commonControl}
                fullWidth
                variant={'outlined'}
                label={messages.street.defaultMessage}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'none' }}>
              <Controller
                as={TextField}
                name="region"
                control={control}
                className={classes.commonControl}
                fullWidth
                variant={'outlined'}
                label={messages.region.defaultMessage}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'none' }}>
              <Controller as={TextField} name="latitude" control={control} />
            </Grid>
            <Grid item xs={12} style={{ display: 'none' }}>
              <Controller as={TextField} name="longitude" control={control} />
            </Grid>
            <Grid item xs={12} style={{ display: 'none' }}>
              <Controller
                as={TextField}
                name="city"
                control={control}
                className={classes.commonControl}
                fullWidth
                variant={'outlined'}
                label={messages.city.defaultMessage}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'none' }}>
              <Controller
                as={TextField}
                name="building"
                control={control}
                className={classes.commonControl}
                fullWidth
                variant={'outlined'}
                label={messages.building.defaultMessage}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'none' }}>
              <Controller as={FlrCheckboxWithLabel} name="customAddress" control={control} />
            </Grid>
            <DialogActions className={classes.dialogActions}>
              <Grid container justifyContent={'space-between'}>
                <Button variant={'outlined'} color={'primary'} onClick={onCancel} type={'button'}>
                  {messages.cancelOutletBtnLabel.defaultMessage}
                </Button>

                <Button variant={'contained'} color={'primary'} size={'large'} disabled={disableSubmit} type={'submit'}>
                  {id ? messages.saveOutletBtnLabel.defaultMessage : messages.addOutletBtnLabel.defaultMessage}
                </Button>
              </Grid>
            </DialogActions>
          </Grid>

          {disableSubmit && <FlrLoader withOverlay={true} />}
        </form>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  outlets: getOutletsData(state),
  outletsLoadingState: getOutletsLoadingState(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      addOutlet: addOutletAsync.request,
      loadOutlets: fetchOutletsAsync.request,
      updateOutlet: updateOutletAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(OutletModal as any));
