import React from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { FormattedHTMLMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Grid, Button, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { push } from 'connected-react-router';

import env from 'environment';
import { useTrackInitialPageLoad } from 'controllers';
import { IUserLoginResponse } from 'models/user';
import { TitleH1, Link1, TextBody2 } from 'components/shared/text';
import Icon from 'components/shared/Icon';
import FlrLoader from 'components/loading/LoadingSpinner';
import FlrInputPassword from 'components/shared/form-elements/textfield/FlrInputPassword';
import FlrCheckboxWithLabel from 'components/shared/form-elements/FlrCheckbox';
import AuthPageWrapper from './shared/AuthPageWrapper';
import LoginFromAdminPanel from './login-from-admin-panel';

import {
  baseUrl,
  cartGroupCheckoutStorageKey,
  registrationFormStepStorageKey,
  registrationTokenStorageKey,
  registrationShouldClearLocalstorageKeys
} from 'shared/constants';
import messages from 'translations/auth/login';
import messagesControls from 'translations/layout/controls';
import styles, { SocialLoginSectionHeading, SocialButtons, SocialLoginSection } from './styles';
import PhoneLink from '../../components/shared/PhoneLink';

interface IFormData {
  authId: string;
  password: string;
  remember: boolean;
}

interface IProps {
  // component own props
  classes: any;
}

// have no idea why it need... (nikita)
const TIMEOUT_PROMISE = 500;

const httpClient = axios.create({ baseURL: env.apiUrl });

const LoginPage: React.FC<IProps> = ({ classes }) => {
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [authError, setAuthError] = React.useState('');
  const [pendingUserError, setPendingUserError] = React.useState(false);
  const dispatch = useDispatch();

  useTrackInitialPageLoad();

  const { handleSubmit, errors, control } = useForm<IFormData>();
  const onBack = () => {
    setAuthError('');
    setPendingUserError(false);
  };
  const onSubmit = handleSubmit(({ authId, password, remember }) => {
    setDisableSubmit(true);
    setAuthError('');
    const response = httpClient.post<any, AxiosResponse<IUserLoginResponse>>(
      `${process.env.REACT_APP_API_ENDPOINT}/auth/login`,
      {
        authId: authId.trim(),
        password: password.trim(),
        remember: remember ? 1 : 0
      }
    );

    response
      .then(res => {
        if (res.data.success) {
          window.localStorage.setItem('token', res.data.token);
          window.localStorage.removeItem(cartGroupCheckoutStorageKey);
          window.localStorage.removeItem(registrationFormStepStorageKey);
          window.localStorage.removeItem(registrationTokenStorageKey);
          // replace with proper logic of bus/state emit
          setTimeout(() => {
            window.location.reload();
          }, TIMEOUT_PROMISE);
        } else {
          setAuthError('');
        }
      })
      .catch((e: AxiosError) => {
        if (e.response) {
          let responseErrors = [];
          if (e.response.status === 403) {
            responseErrors =
              (e.response.data && typeof e.response.data === 'object' && Object.values(e.response.data)) || [];
            setPendingUserError(true);
            if (responseErrors.length) {
              setAuthError(String(responseErrors[0]) || '');
            }
            // force to finish 2nd reg step
            if (e.response.data.status === 'registration-not-finished') {
              window.localStorage.setItem(registrationTokenStorageKey, e.response.data.auth.token);
              window.localStorage.setItem(registrationFormStepStorageKey, '1');
              window.localStorage.setItem(registrationShouldClearLocalstorageKeys, '1');
              dispatch(push('/registration'));
            }
          } else if (e.response.status === 404) {
            setAuthError('authId');
          } else if (e.response.data && typeof e.response.data === 'object') {
            responseErrors = Object.keys(e.response.data);
            if (responseErrors.length) {
              setAuthError(String(responseErrors[0]) || '');
            }
          }
        }
      })
      .finally(() => {
        setTimeout(() => {
          setDisableSubmit(false);
        }, TIMEOUT_PROMISE + 100);
      });
  });

  const handleSocial = () => {
    // alert("we'll try login with " + name);
  };
  const authErrorMessage =
    (!!authError && messages[`${authError}Error`] && messages[`${authError}Error`] && (
      <FormattedHTMLMessage {...messages[`${authError}Error`]} />
    )) ||
    authError;
  const submitBtnCommonProps = {
    color: 'primary' as 'primary',
    variant: 'contained' as 'contained',
    fullWidth: true,
    style: { marginTop: 24 },
    disabled: disableSubmit
  };
  return (
    <main>
      <LoginFromAdminPanel>
        <AuthPageWrapper classes={classes}>
          <TitleH1 className={classes.header} align={'center'}>
            {messages.header.defaultMessage}
          </TitleH1>
          {!pendingUserError ? (
            <React.Fragment>
              <TextBody2 className={classes.headerCaption} align={'center'}>
                {messages.headerCaption.defaultMessage}
              </TextBody2>
              <form onSubmit={onSubmit}>
                <Controller
                  error={Boolean(errors.authId || !!authErrorMessage)}
                  helperText={errors.authId && errors.authId.message}
                  rules={{
                    required: messagesControls.requiredFiled.defaultMessage
                  }}
                  as={TextField}
                  name="authId"
                  control={control}
                  defaultValue=""
                  fullWidth
                  variant={'outlined'}
                  label={messages.formFieldLogin.defaultMessage}
                  className={classes.formInputAuthId}
                />

                <Controller
                  error={Boolean(errors.password || !!authErrorMessage)}
                  helperText={(errors.password && errors.password.message) || authErrorMessage}
                  rules={{
                    required: messagesControls.requiredFiled.defaultMessage
                  }}
                  as={FlrInputPassword}
                  name="password"
                  control={control}
                  defaultValue=""
                  // className={classes.loginControl}
                  fullWidth
                  variant={'outlined'}
                  label={messages.formFieldPassword.defaultMessage}
                  className={classes.formInputPassword}
                />
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                  <Controller
                    defaultValue={0}
                    rules={{
                      required: true
                    }}
                    as={FlrCheckboxWithLabel}
                    name={'remember'}
                    control={control}
                    label={messages.formFieldRememberMe.defaultMessage}
                    className={classes.formInputCheckbox}
                  />

                  <Link1 to={`${baseUrl}/forgot-password`}>{messages.forgotPassword.defaultMessage}</Link1>
                </Grid>
                <Button type={'submit'} {...submitBtnCommonProps}>
                  {messages.formLoginButton.defaultMessage}
                </Button>
              </form>
              <SocialLoginSection>
                <SocialLoginSectionHeading>
                  <span>{messages.alternativeLogin.defaultMessage}</span>
                </SocialLoginSectionHeading>
                <SocialButtons>
                  {['facebook', 'google'].map((name, key) => (
                    <Button
                      variant={'outlined'}
                      color={'secondary'}
                      key={key}
                      fullWidth
                      onClick={handleSocial}
                      startIcon={<Icon size={20} type={name} />}
                      sx={{ padding: '5px 15px' }}
                    >
                      <span style={{ opacity: 0.8 }}>{name}</span>
                    </Button>
                  ))}
                </SocialButtons>
              </SocialLoginSection>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TextBody2 className={classes.headerCaption} align={'center'}>
                {authErrorMessage}
              </TextBody2>
              <div className={classes.headerCaption} style={{ textAlign: 'center' }}>
                <TextBody2 align={'center'}>{messages.authErrorDetails.defaultMessage}</TextBody2>
                <Grid container alignItems={'center'} justifyContent={'center'}>
                  <Grid item>
                    <PhoneLink withPopup />
                  </Grid>
                </Grid>
              </div>
              <Button onClick={onBack} {...submitBtnCommonProps}>
                {messages.formBackButton.defaultMessage}
              </Button>
            </React.Fragment>
          )}
          {disableSubmit && <FlrLoader withOverlay={true} />}
        </AuthPageWrapper>
      </LoginFromAdminPanel>
    </main>
  );
};

export default withStyles<any>(styles)(LoginPage);
