import { homeMaxWidth } from 'shared/constants';

const styles = (theme: any) => ({
  wrapper: {
    background: 'linear-gradient(90deg, rgba(245,245,245,1) 50%, rgba(0,0,0,0) 50%)'
  },
  containerOffset: {
    background: theme.palette.gray[100]
  },
  root: {
    maxWidth: homeMaxWidth,
    margin: '0 auto',

    position: 'relative',
    marginTop: 10,
    marginBottom: 233,

    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
      marginBottom: 411
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: 370
    }
  },
  container: {
    '&&': {
      marginRight: theme.spacing(5),
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(6),
      paddingRight: theme.spacing(4.25),
      paddingBottom: 244,

      background: theme.palette.gray[100],
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,

      [theme.breakpoints.up('md')]: {
        paddingLeft: 0,
        marginRight: theme.spacing(12),
        paddingBottom: 416
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 2000,
        position: 'relative',
        paddingBottom: theme.spacing(10)
      }
    }
  },

  image: {
    position: 'absolute',
    bottom: -169,
    right: 32,
    height: 381,
    objectFit: 'cover',

    [theme.breakpoints.up('md')]: {
      bottom: -320,
      right: 32,
      height: 669
    },

    [theme.breakpoints.up('lg')]: {
      bottom: 'unset',
      top: 250,
      // right: 24,
      right: -85,
      height: 846
    },
    // eslint-disable-next-line
    ['@media (min-width:1480px)']: {
      right: 84
    },
    // eslint-disable-next-line
    ['@media (min-width:1600px)']: {
      right: 144
    }
  },

  contentWrapper: {
    [theme.breakpoints.up('lg')]: {
      width: 561
    },

    '& h2, & h3': {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),

      color: theme.palette.common.black,

      [theme.breakpoints.up('md')]: {
        fontSize: 32
      }
    },
    '& p': {
      fontSize: 16,
      color: theme.palette.text.primary,
      lineHeight: 1.4
    },
    '& h2 + p,& h3 + p,': {
      marginBottom: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),

    color: theme.palette.common.black,

    [theme.breakpoints.up('md')]: {
      fontSize: 48
    },
    [theme.breakpoints.up('lg')]: {
      width: 690
    }
  },
  subTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),

    color: theme.palette.common.black,

    [theme.breakpoints.up('md')]: {
      fontSize: 32
    }
  },

  text: {
    marginBottom: theme.spacing(2)
  }
});

export default styles;
