import { homeMaxWidth } from 'shared/constants';

import ImageSubscribe from '../assets/subscribe.webp';
import ImageSubscribeTablet from '../assets/subscribe-tablet.webp';
import ImageSubscribeMobile from '../assets/subscribe-mobile.webp';

const styles = (theme: any) => ({
  wrapper: {
    backgroundColor: '#111111'
  },

  root: {
    maxWidth: homeMaxWidth,
    margin: '0 auto',

    height: 369,
    padding: theme.spacing(6),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(8),

    backgroundColor: '#111111',
    backgroundImage: `url(${ImageSubscribeMobile})`,
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10),
      paddingLeft: 0,
      paddingRight: theme.spacing(12),
      marginBottom: theme.spacing(12),

      backgroundImage: `url(${ImageSubscribeTablet})`
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(15),
      backgroundImage: `url(${ImageSubscribe})`
    }
  },
  container: {
    width: '100%',
    borderRadius: 24,

    color: theme.palette.common.white,

    [theme.breakpoints.up('md')]: {
      width: 540
    },
    [theme.breakpoints.up('lg')]: {}
  },

  title: {
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      fontSize: 36
    }
  },

  subTitle: {
    marginBottom: theme.spacing(4),

    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
      textAlign: 'left'
    }
  },
  formContainer: {
    margin: '0 auto',
    maxWidth: 280,

    [theme.breakpoints.up('md')]: {
      maxWidth: 'unset'
    }
  },
  input: {
    marginBottom: theme.spacing(2),

    borderRadius: 8,

    '&.MuiOutlinedInput-root': {
      backgroundColor: theme.palette.common.white
    },

    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.helperBlack
    },

    [theme.breakpoints.up('md')]: {
      width: 345,
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(1)
    }
  },
  buttonSubmit: {
    '&&': {
      paddingTop: 11,
      paddingBottom: 11,
      borderColor: theme.palette.common.white,
      color: theme.palette.common.white
    }
  }
});

export default styles;
