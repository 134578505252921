import { FC } from 'react';
import clsx from 'clsx';

import { TextBody2 } from 'components/shared/text';

import classes from './OrderCardItemHeaders.module.scss';

interface IProps {
  itemsNumber: number;
  withDiscount?: boolean;
  withItemRemove?: boolean;
  isAccount?: boolean;
}

const OrderCardItemHeaders: FC<IProps> = ({ itemsNumber, isAccount }) => {
  return (
    <div className={classes.orderCardItemHeaders}>
      <TextBody2 className={clsx(classes.name, {[classes.accountName]: isAccount})}>Товар ({itemsNumber})</TextBody2>
      <TextBody2 className={classes.price}>Ціна</TextBody2>
      <TextBody2 className={clsx(classes.quantity, {[classes.accountQuantity]: isAccount})}>Кількість, шт</TextBody2>
      <TextBody2 className={clsx(classes.removeItem, {[classes.withoutRemoveItem]: isAccount})} />
      <TextBody2 className={clsx(classes.inPackageCount, {[classes.accountInPackageCount]: isAccount})}>Кількість, уп</TextBody2>
      <TextBody2 className={classes.discount}>Знижка</TextBody2>
      <TextBody2 className={classes.sum}>Сума</TextBody2>
    </div>
  );
};

export default OrderCardItemHeaders;
