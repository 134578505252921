import React, { CSSProperties } from "react";
import { Typography, Link as LinkMUI } from "@mui/material";
import { NavLink } from "react-router-dom";

import { withStyles } from "@mui/styles";

interface IProps {
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  color?: "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
  classes: any;
  className?: string;
  style?: CSSProperties;
  href?: string;
  to?: string;
}

const Link1: React.FC<IProps> = ({ children, classes, href, component, color, className, style, to = "" }) => {
  return (
    <Typography
      className={className}
      color={color || "textPrimary"}
      variant={"inherit"}
      component={component || "span"}
      style={style}
    >
      {to && !href ? (
        <NavLink to={to} className={classes.link}>
          <LinkMUI component={"span"} underline={"always"} variant="inherit">
            {children}
          </LinkMUI>
        </NavLink>
      ) : (
        <LinkMUI
          underline={"always"}
          variant="inherit"
          color={color || "textPrimary"}
          className={classes.link}
          href={href}
        >
          {children}
        </LinkMUI>
      )}
    </Typography>
  );
};

const styles = () => ({
  root: {},
  link: {
    textDecoration: "none !important"
  }
});

export default withStyles<any>(styles)(Link1);
