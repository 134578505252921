import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "main.testimonials.title",
    description: "",
    defaultMessage: "Про нас говорять"
  },
  subTitle: {
    id: "main.testimonials.subTitle",
    description: "",
    defaultMessage: "Підпишіться на розсилку Флоротеки, щоб отримувати корисні новини"
  },
  controlPlaceholder: {
    id: "main.testimonials.controlPlaceholder",
    description: "",
    defaultMessage: "Ваша електронна пошта"
  },
  submit: {
    id: "main.testimonials.submit",
    description: "",
    defaultMessage: "підписатися"
  }
});
