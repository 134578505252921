import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import clsx from 'clsx';
import { Collapse, Grid, Hidden, Theme, useMediaQuery } from '@mui/material';
import { Breakpoint } from '@mui/material/styles';
import { useTheme, withStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

import { CatalogProductCardHome } from 'components/cards/product';
import { ProductCardSkeleton } from 'components/cards/product/skeleton';
import FlrLoader from 'components/loading/LoadingSpinner';
import { Link1, TitleH1 } from 'components/shared/text';
import { FeaturedProductsSection, Product } from 'models';
import { baseUrl, defaultCatalogProductType, ProductTypeAlias } from 'shared/constants';
import { selectProductAsync } from 'store/catalog/actions';
import {
  getFeaturedProductsSectionsData,
  getFeaturedProductsSectionsLoaded,
  getFeaturedProductsSectionsLoading
} from 'store/main/selectors';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/main/categoryCommon';

import { getUserAccount } from '../../../store/account/selectors';
import MainCatalogPromo from '../category-promo';
import { GRID_TYPES, IGridType, IGridTypeColumns } from './config';
import getStyles from './styles';

type BreakpointOrNull = Breakpoint | null;

interface IOwnProps {
  // put own props here
  ImagePromoMobile?: any;
  ImagePromo?: any;
  promoImageStyle?: string;
  productType: ProductTypeAlias;
  catalogPath?: string;
}

interface IStateProps {
  sectionsData: FeaturedProductsSection[] | null;
  sectionsDataLoading: boolean;
  sectionsDataLoaded: boolean;
}

interface IDispatchProps {
  selectProduct: typeof selectProductAsync.request;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const productsCountToShowByWidthDefault: IGridTypeColumns = {
  xs: 6,
  sm: 6,
  md: 8,
  lg: 14,
  xl: 14
};

const CategoryCommon: React.FC<IProps> = ({
  sectionsData,
  sectionsDataLoaded,
  sectionsDataLoading,
  selectProduct,
  // width,
  promoImageStyle,
  productType,
  catalogPath = defaultCatalogProductType
}) => {
  const user = useSelector(getUserAccount);
  const rowLg = user ? 276 : 234;
  const rowMd = user ? 259 : 216;
  const rowSm = 153;

  // Generate the styles with the dynamic values
  const useStyles = makeStyles((theme) => getStyles(theme, rowLg, rowMd, rowSm));
  const classes = useStyles();

  const theme: Theme = useTheme();
  const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse();
  const width =
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xl';

  const [slideShown, setSlideShown] = React.useState(false);
  const showLoader = sectionsDataLoading && !sectionsDataLoaded;
  if (!showLoader) {
    setTimeout(() => {
      setSlideShown(true);
    }, 1000);
  }

  const section =
    sectionsData &&
    sectionsData
      .filter((sample) => sample.productType && sample.productType.alias === productType)
      .sort((a: FeaturedProductsSection, b: FeaturedProductsSection) => (a.order > b.order ? 1 : -1))[0];

  const handleSelectProduct = (product: Product) => {
    // selectProduct(product);
  };
  const products = [];
  const gridConfig: IGridType = GRID_TYPES[productType];
  const productsCountToShowByWidth = gridConfig.columns || productsCountToShowByWidthDefault;
  const countToShow = productsCountToShowByWidth[width || 'xl'];
  let displayCategoryName = '';
  if (section) {
    const { catalogCategory, productType: sectionProductType } = section;
    displayCategoryName = catalogCategory.name;
    products.push(
      <MainCatalogPromo
        key={'promo'}
        title={catalogCategory.name}
        link={`${baseUrl}/catalog/${sectionProductType.alias}`}
        count={section.products.length}
      >
        <img
          className={clsx(classes.imagePromoCard, productType)}
          src={gridConfig.imageMobile}
          srcSet={`${gridConfig.imageMobile} 300w, ${gridConfig.image} 960w`}
          alt={''}
        />
      </MainCatalogPromo>
    );

    for (let i = 0; i < countToShow; i++) {
      if (!(section.products && section.products[i])) {
        products.push(
          <ProductCardSkeleton key={i} className={classes.productCardSkeleton} style={{ gridArea: `product${i}` }} />
        );

        continue;
      }

      products.push(
        <NavLink
          key={i}
          to={`${baseUrl}/catalog/${sectionProductType.alias}/product=${section.products[i].code};`}
          className={classes.productCardWrapper}
          style={{ gridArea: `product${i}` }}
        >
          <CatalogProductCardHome
            className={clsx(classes.productCard, classes.productCardMargin)}
            item={section.products[i]}
            onClick={handleSelectProduct}
          />
        </NavLink>
      );
    }
  }

  return (
    <div className={classes.wrapper}>
      {(!slideShown || showLoader) && (
        <Grid container alignItems={'center'} justifyContent={'center'} style={{ marginBottom: 80 }}>
          <FlrLoader size={40} />
        </Grid>
      )}
      <Collapse in={slideShown} timeout={1000}>
        <div className={classes.categoryRoot}>
          <Grid className={classes.categoryHeader} container alignItems={'flex-end'} spacing={2}>
            <Grid item xs={1} implementation="css" smDown component={Hidden} />
            <div className={classes.categoryTitleContainer}>
              <TitleH1 className={classes.categoryTitle}>{displayCategoryName}</TitleH1>
            </div>
            <Grid item className={classes.titleLink}>
              <Link1 to={`${baseUrl}/catalog/${catalogPath}`}>{messages.toCatalog.defaultMessage}</Link1>
            </Grid>
          </Grid>
          <Grid className={clsx(classes.categoriesProducts, productType)} container spacing={2}>
            {products}
          </Grid>
        </div>
      </Collapse>
    </div>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state: IApplicationState) => ({
  sectionsData: getFeaturedProductsSectionsData(state),
  sectionsDataLoaded: getFeaturedProductsSectionsLoaded(state),
  sectionsDataLoading: getFeaturedProductsSectionsLoading(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      selectProduct: (product: Product) => selectProductAsync.request(product)
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(getStyles)(CategoryCommon as any));
