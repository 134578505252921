import { IAsyncDataWrapper } from 'store/actions';

import { CartGroup, CartGroupsList } from './cart-group';
import { CartItem, IRequestCartItem } from './cart-item';

export interface IMiniCart {
  opened: boolean;
  editingProduct: string;
  saving: IAsyncDataWrapper<CartItem[]>;
}

export class Cart {
  public static parse(storageSavedString: string) {
    const objCart = JSON.parse(storageSavedString);
    return new Cart(objCart);
  }

  public items: CartItem[] = [];
  public groups: CartGroupsList = {};
  public total: number;
  public discount: number;
  public expiresOn: Date;
  public serverTime: Date | string;

  constructor(cart: any) {
    const serverTimeDiff = Math.abs(new Date().getTime() - new Date(cart.serverTime).getTime());
    this.items = (cart.items || []).map((item: any) => new CartItem(item));
    this.total = cart.total;
    this.discount = cart.discount;
    this.serverTime = cart.serverTime;
    this.expiresOn = new Date(new Date(cart.expiresOn).getTime() + serverTimeDiff);

    const groups = cart.groups || {};
    this.groups = {};
    Object.keys(groups).forEach(
      (groupKey: string) => (this.groups[groupKey] = new CartGroup(groups[groupKey], serverTimeDiff))
    );
  }

  public stringify() {
    return JSON.stringify(this);
  }

  get totalQty() {
    return this.items.reduce((acc, item) => acc + item.qty, 0);
  }

  public update(item: IRequestCartItem | CartItem) {
    const cart = new Cart(this);
    const itemToUpdate = cart.items.find((cartItem) => cartItem.offer.id === item.offer);
    if (itemToUpdate) {
      itemToUpdate.qty = item.qty;
    }
    const offerGroup = Object.keys(cart.groups).find((groupKey) =>
      groupKey.includes(itemToUpdate?.offer.offerType || '')
    );
    if (offerGroup) {
      cart.groups[offerGroup].items = cart.groups[offerGroup].items.map((i) => {
        if (i.offer.id === item.offer) {
          i.qty = item.qty;
          i.total = item.qty * i.clientPrice;
          i.discount = item.qty * i.discountPerItem;
        }
        return i;
      });
      cart.groups[offerGroup].total = cart.groups[offerGroup].items.reduce((acc, i) => acc + i.clientPrice * i.qty, 0);
      cart.groups[offerGroup].discount = cart.groups[offerGroup].items.reduce(
        (acc, i) => acc + i.discountPerItem * i.qty,
        0
      );
      cart.groups[offerGroup].subtotal =
        cart.groups[offerGroup].items.reduce((acc, i) => acc + i.clientPrice * i.qty, 0) +
        cart.groups[offerGroup].discount;
    }

    return cart;
  }

  public updateMany(items: CartItem[]) {
    const cart = new Cart(this);
    /*const indexOfItemToUpdate = cart.items.findIndex(itemSearch => itemSearch.product["_id"] === item.product["_id"]);
    cart.items[indexOfItemToUpdate] = item;*/
    // TODO put proper logic here!
    return cart;
  }

  public remove(item: CartItem) {
    const cart = new Cart(this);
    /*const indexOfItemToUpdate = cart.items.findIndex(itemSearch => itemSearch.product["_id"] === item.product["_id"]);
    cart.items[indexOfItemToUpdate] = item;*/
    // TODO put proper logic here!
    return cart;
  }

  public get regularGroups(): string[] {
    return Object.keys(this.groups || {}).filter(
      (groupKey: string) => this.groups[groupKey] && !this.groups[groupKey].preOrder && !this.groups[groupKey].transit
    );
  }

  public get preOrderGroups(): string[] {
    return Object.keys(this.groups || {}).filter(
      (groupKey: string) => this.groups[groupKey] && this.groups[groupKey].preOrder
    );
  }

  public get inTransitGroups(): string[] {
    return Object.keys(this.groups || {}).filter(
      (groupKey: string) => this.groups[groupKey] && this.groups[groupKey].transit
    );
  }
}
