import * as React from 'react';
import clsx from 'clsx';
import { useRouteMatch } from 'react-router';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import Icon from 'components/shared/Icon';
import Tooltip from 'components/shared/tooltip';
import { TextBody2, TextSubTitle } from 'components/shared/text';
import { SplitedPrice } from 'components/prices';
import { HeaderBottomLink } from './HeaderBottomLink';

import { baseUrl, UAH } from 'shared/constants';
import menuMessages from 'translations/account/menu';
import messages from 'translations/account/finance';

interface ICartProps {
  balance: number;
  availableCredit: number;
  classes?: any;
}

const balanceUrl = `${baseUrl}/account/finance`;

const BalanceComponent: React.FC<ICartProps> = ({ balance, availableCredit, classes }) => {
  const isLinkActive = useRouteMatch(balanceUrl);

  return (
    <div className={classes.wrapper}>
      <HeaderBottomLink
        to={balanceUrl}
        className={clsx({
          active: isLinkActive
        })}
      >
        <Icon type={'wallet'} className={'menu-cart-icon'} size={32} offset={8} withBackground opacity={1} />
        <Grid container className={classes.mainContent}>
          <Grid item xs={12}>
            <TextBody2 color={'textSecondary'} align={'inherit'}>
              {menuMessages.balance.defaultMessage}
            </TextBody2>
          </Grid>
          <Grid item>
            {balance > 0 ? (
              <SplitedPrice fontSize={'caption'} value={balance} postfix={UAH} hideDecimal />
            ) : (
              <Tooltip
                icon={<Icon type={'wallet'} size={24} offset={8} opacity={1} />}
                title={
                  <div>
                    <TextSubTitle style={{ color: 'white', marginTop: 0, whiteSpace: 'normal' }}>
                      Власні кошти скінчилися, використовуються кредитні
                    </TextSubTitle>
                    <br />
                    <TextBody2 style={{ color: 'white', whiteSpace: 'normal' }}>
                      {messages.creditLineUsed.defaultMessage}
                      &nbsp;
                      <SplitedPrice fontSize={'body2'} value={Math.max(0, -balance)} postfix={UAH} />
                    </TextBody2>
                    <br />
                    <TextBody2 style={{ color: 'white', whiteSpace: 'normal' }}>
                      {messages.creditLineAvailable.defaultMessage}
                      &nbsp;
                      <SplitedPrice fontSize={'body2'} value={availableCredit} postfix={UAH} />
                    </TextBody2>
                  </div>
                }
              >
                <div>
                  <SplitedPrice fontSize={'caption'} negative={balance < 0} value={balance} postfix={UAH} hideDecimal />
                </div>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </HeaderBottomLink>
    </div>
  );
};

export const stylesTopLinks = (theme: any) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start'
    }
  },
  mainContent: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      textAlign: 'left'
    }
  }
});

export const Balance = withStyles<any>(stylesTopLinks)(BalanceComponent);
