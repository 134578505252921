import clsx from 'clsx';
import { Collapse } from '@mui/material';

import { IFilterOptions } from 'models';
import { IFilterProp } from 'store/catalog/actions';
import { Link2, TextBody2, TextSubTitle } from 'components/shared/text';
import { getFilterLabel } from 'utils/filters';
import { filtersMessages } from 'translations/catalog/filters';

interface IProps {
  classes: any;
  label: string;
  filterOptionsData?: IFilterOptions;
  defaultCaption?: string;
  arraySelectedLast?: IFilterProp | null;
  arraySelectedLength?: number;
  handleReset?: (ev: React.SyntheticEvent | React.MouseEvent) => void;
}

const FilterLabel: React.FC<IProps> = ({
  classes,
  label,
  arraySelectedLast,
  arraySelectedLength,
  defaultCaption,
  filterOptionsData,
  handleReset
}) => {
  const labelComputed = arraySelectedLast
    ? filterOptionsData
      ? getFilterLabel(arraySelectedLast, filterOptionsData)
      : arraySelectedLast.label || arraySelectedLast.value
    : `${defaultCaption}`;

  return (
    <>
      <TextSubTitle className={classes.expansionHeader}>{label}</TextSubTitle>
      {defaultCaption && (
        <Collapse in={true}>
          <div className={classes.expansionBottom} onClick={ev => ev.stopPropagation()}>
            <TextBody2 className={classes.expansionDesc}>
              {labelComputed}
              {arraySelectedLength && arraySelectedLength > 1 ? ` + ${arraySelectedLength - 1}` : null}
            </TextBody2>

            {handleReset ? (
              <Link2
                className={clsx(classes.expansionReset, {
                  [classes.expansionResetActive]: !!arraySelectedLast
                })}
                onClick={handleReset}
              >
                {filtersMessages.reset.defaultMessage}
              </Link2>
            ) : null}
          </div>
        </Collapse>
      )}
    </>
  );
};

export default FilterLabel;
