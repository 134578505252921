import { ITheme } from "utils/styled";
import makeStyles from '@mui/styles/makeStyles';

export const tabStyles = makeStyles(
  (theme: ITheme) => ({
    root: {
      ...theme.typography.body1,
      color: theme.palette.text.secondary,

      "&:hover": {
        textDecoration: "none",
        color: theme.palette.primary.main
      },
      "&:active": {
        textDecoration: "none",
        color: theme.palette.common.primaryHoverPress
      },
      "&:focus": {
        color: theme.palette.primary.main,
        textDecoration: "none"
      }
    },

    wrapper: {
      justifyContent: "start",
      alignItems: "flex-start"
    }
  }),
  { name: "MuiTab" }
);

export const tabsStyles = makeStyles(
  (theme: ITheme) => ({
    root: {
      "&.account-tabs": {
        marginLeft: theme.spacing(3),
      }
    },
    indicator: {
      left: 0,
      width: theme.spacing(0.5),
      height: theme.spacing(0.5),
      borderRadius: 4
    }
  }),
  { name: "MuiTabs" }
);
