import { defineMessages } from 'react-intl';

export default defineMessages({
  inStockFilter: {
    id: 'catalog.inStockFilter',
    description: 'catalog.inStockFilter',
    defaultMessage: 'В наявності'
  },
  inCart: {
    id: 'catalog.inCart',
    description: 'catalog.inCart',
    defaultMessage: 'У кошику:'
  },
  availableInStock: {
    id: 'catalog.availableInStock',
    description: 'catalog.availableInStock',
    defaultMessage: 'Доступно'
  },
  specialFilter: {
    id: 'catalog.specialFilter',
    description: 'catalog.specialFilter',
    defaultMessage: 'Акційні товари'
  },
  transitFilter: {
    id: 'catalog.transitFilter',
    description: 'catalog.transitFilter',
    defaultMessage: 'В дорозі на склад'
  },
  preOrderFilter: {
    id: 'catalog.preOrderFilter',
    description: 'catalog.preOrderFilter',
    defaultMessage: 'Передзамовлення'
  },
  loginToBuy: {
    id: 'catalog.details.loginToBuy',
    description: 'Щоб купити увійдіть або зареєструйтесь',
    defaultMessage: 'Щоб купити увійдіть або зареєструйтесь'
  },
  // stockIsNotEnough: {
  //   id: "catalog.details.stockIsNotEnough",
  //   description: "Недостатня кількість товару в наявності",
  //   defaultMessage: "Недостатня кількість товару в наявності"
  // },
  fastestDelivery: {
    id: 'catalog.details.fastestDelivery',
    description: 'catalog.details.fastestDelivery',
    defaultMessage: 'Швидка доставка'
  },
  priceFirst: {
    id: 'catalog.details.fastestDelivery',
    description: 'catalog.details.fastestDelivery',
    defaultMessage: 'Найнижча ціна'
  },
  preOrderFirst: {
    id: 'catalog.details.preOrderFirst',
    description: 'catalog.details.preOrderFirst',
    defaultMessage: 'Спочатку передзамовлення'
  },
  offersEmpty: {
    id: 'catalog.details.offersEmpty',
    description: 'catalog.details.offersEmpty',
    defaultMessage: 'Пропозиції відсутні'
  },
  offersInProgress: {
    id: 'catalog.details.offersInProgress',
    description: 'catalog.details.offersInProgress',
    defaultMessage: 'Пропозиції формуються...'
  },
  offersPreOrderEmpty: {
    id: 'catalog.details.offersPreOrderEmpty',
    description: 'catalog.details.offersPreOrderEmpty',
    defaultMessage: 'Пропозиції відсутні'
  },
  additionalInfo: {
    id: 'catalog.details.additionalInfo',
    description: 'catalog.details.additionalInfo',
    defaultMessage: 'Дополнительная информация'
  },
  quality: {
    id: 'catalog.details.quality',
    description: 'Якість',
    defaultMessage: 'Якість'
  },
  quantityUnit: {
    id: 'catalog.details.quantityUnit',
    description: 'catalog.details.quantityUnit',
    defaultMessage: 'шт'
  },
  quantityUnits: {
    id: 'catalog.details.quantityUnit',
    description: 'catalog.details.quantityUnit',
    defaultMessage: 'Штуки'
  },
  transitDeliveryText: {
    id: 'catalog.details.transitDeliveryText',
    description: 'catalog.details.transitDeliveryText',
    defaultMessage: 'Прибуття на склад'
  },
  transitDeliveryTextShort: {
    id: 'catalog.details.transitDeliveryTextShort',
    description: 'catalog.details.transitDeliveryTextShort',
    defaultMessage: 'На складі'
  },
  transitModalTitle: {
    id: 'catalog.details.transitModalTitle',
    description: 'catalog.details.transitModalTitle',
    defaultMessage: 'Додати в замовлення'
  },
  transitOfferModalTitle: {
    id: 'catalog.details.transitOfferModalTitle',
    description: 'catalog.details.transitOfferModalTitle',
    defaultMessage: 'Товар вже в дорозі на наш склад'
  },
  transitModalTitleSecondary: {
    id: 'catalog.details.transitModalTitleSecondary',
    description: 'catalog.details.transitModalTitleSecondary',
    defaultMessage:
      'Ви за крок до передзамовлення, доставка за яким немає визначених термінів. Проте цей товар вже прямує на наш склад.'
  },
  transitModalBottomText: {
    id: 'catalog.details.transitModalBottomText',
    description: 'catalog.details.transitModalBottomText',
    defaultMessage:
      'Бажаєте купити товар із найближчої поставки чи оформити передзамовлення з невизначеним терміном доставки?'
  },
  transitToOrderBottomText1: {
    id: 'catalog.details.transitToOrderBottomText1',
    description: 'catalog.details.transitToOrderBottomText1',
    defaultMessage: 'Раніше Ви додавали до замовлення цей товар за ціною '
  },
  transitToOrderBottomText2: {
    id: 'catalog.details.transitToOrderBottomText2',
    description: 'catalog.details.transitToOrderBottomText2',
    defaultMessage: '. Ціна змінюється щодня, тому встигніть придбати товар наперед за вигідною ціною.'
  },
  transitToOrderTitle1: {
    id: 'catalog.details.transitToOrderTitle1',
    description: 'catalog.details.transitToOrderTitle1',
    defaultMessage: 'У Вас вже є оформлені доставки з товарами з цієї поставки, яка вже прямує на наш склад.'
  },
  transitToOrderTitle2: {
    id: 'catalog.details.transitToOrderTitle2',
    description: 'catalog.details.transitToOrderTitle2',
    defaultMessage:
      'Ви можете додавати товари до вже оформлених доставок з цієї поставки або ж оформлювати нові, якщо товар потрібен на іншій адресі призначення.'
  },
  transitProductColumn1: {
    id: 'catalog.details.transitProductColumn1',
    description: 'catalog.details.transitProductColumn1',
    defaultMessage: 'Прибуття на склад'
  },
  transitProductColumn2: {
    id: 'catalog.details.transitProductColumn2',
    description: 'catalog.details.transitProductColumn2',
    defaultMessage: 'Можлива доставка'
  },
  transitProductColumn3: {
    id: 'catalog.details.transitProductColumn3',
    description: 'catalog.details.transitProductColumn3',
    defaultMessage: 'Доступно з поставки, шт'
  },
  transitProductColumn4: {
    id: 'catalog.details.transitProductColumn4',
    description: 'catalog.details.transitProductColumn4',
    defaultMessage: 'Ціна'
  },
  transitOrderColumn1: {
    id: 'catalog.details.transitOrderColumn1',
    description: 'catalog.details.transitOrderColumn1',
    defaultMessage: 'Вже у замовленні'
  },
  transitOrderColumn2: {
    id: 'catalog.details.transitOrderColumn2',
    description: 'catalog.details.transitOrderColumn2',
    defaultMessage: 'Ціна'
  },
  transitOrderColumn3: {
    id: 'catalog.details.transitOrderColumn3',
    description: 'catalog.details.transitOrderColumn3',
    defaultMessage: 'Кількість товару, шт'
  },
  transitOrderColumn4: {
    id: 'catalog.details.transitOrderColumn4',
    description: 'catalog.details.transitOrderColumn4',
    defaultMessage: 'Сума'
  },
  transitChooseOrderLabel: {
    id: 'catalog.details.transitChooseOrderLabel',
    description: 'catalog.details.transitChooseOrderLabel',
    defaultMessage: 'Замовлення, в яке буде додано товар'
  },
  transitChooseOrderHint: {
    id: 'catalog.details.transitChooseOrderHint',
    description: 'catalog.details.transitChooseOrderHint',
    defaultMessage: 'Товар буде доданий у Кошик для подальшого оформлення нового передзамовлення'
  },
  transitChooseOrderSum: {
    id: 'catalog.details.transitChooseOrderSum',
    description: 'catalog.details.transitChooseOrderSum',
    defaultMessage: 'Сума замовлення: '
  },
  pricePerQuantity: {
    id: 'catalog.details.pricePerQuantity',
    description: 'catalog.details.pricePerQuantity',
    defaultMessage: 'Ціна'
  },
  discountPricePerQuantity: {
    id: 'catalog.details.discountPricePerQuantity',
    description: 'catalog.details.discountPricePerQuantity',
    defaultMessage: 'Ціна зі знижкою'
  },
  ripeness: {
    id: 'catalog.details.ripeness',
    description: 'Стиглість',
    defaultMessage: 'Стиглість'
  },
  weight: {
    id: 'catalog.details.weight',
    description: 'Вага',
    defaultMessage: 'Вага'
  },
  attrType: {
    id: 'catalog.cart.attrType',
    description: 'catalog.cart.attrType',
    defaultMessage: 'Вид'
  },
  attrCategory: {
    id: 'catalog.cart.attrCategory',
    description: 'catalog.cart.attrCategory',
    defaultMessage: 'Категорія'
  },
  attrCode: {
    id: 'catalog.cart.attrCode',
    description: 'catalog.cart.attrCode',
    defaultMessage: 'Артикул'
  },
  inPack: {
    id: 'catalog.details.inPack',
    description: 'В упаковці',
    defaultMessage: 'В упаковці'
  },
  special: {
    id: 'catalog.details.special',
    description: 'catalog.details.special',
    defaultMessage: 'Акція'
  },
  transit: {
    id: 'catalog.details.transit',
    description: 'catalog.details.transit',
    defaultMessage: 'В дорозі'
  },
  preOrder: {
    id: 'catalog.details.preOrder',
    description: 'catalog.details.preOrder',
    defaultMessage: 'Передзамовлення'
  },
  fastest: {
    id: 'catalog.details.fastest',
    description: 'catalog.details.fastest',
    defaultMessage: 'Найшвидша'
  },
  quantityTitle: {
    id: 'catalog.details.quantityTitle',
    description: 'catalog.details.quantityTitle',
    defaultMessage: 'Додати товару до замовлення, шт.'
  },
  deliveryRegion: {
    id: 'catalog.details.deliveryRegion',
    description: 'catalog.details.deliveryRegion',
    defaultMessage: 'Регіон доставки'
  },
  deliveryRegionInfo: {
    id: 'catalog.details.deliveryRegionInfo',
    description: 'catalog.details.deliveryRegionInfo',
    defaultMessage: 'Змінити регіон доставки Ви можете у фільтрах'
  },
  deliveryLabel: {
    id: 'catalog.details.deliveryLabel',
    description: 'catalog.details.deliveryLabel',
    defaultMessage: 'Доставка'
  },
  transitLabel: {
    id: 'catalog.details.transitLabel',
    description: 'catalog.details.transitLabel',
    defaultMessage: 'Надходить'
  },
  inPackageCountLabel: {
    id: 'catalog.details.inPackageCountLabel',
    description: 'catalog.details.inPackageCountLabel',
    defaultMessage: 'Упаковок'
  },
  alreadyInOrderLabel: {
    id: 'catalog.details.alreadyInOrderLabel',
    description: 'catalog.details.alreadyInOrderLabel',
    defaultMessage: 'Вже у замовленні'
  }
});
