import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ITheme } from 'utils/styled';

export default makeStyles((theme: ITheme) =>
  createStyles({
    rowWrapperSelected: {
      border: '2px solid #8E3FBE',
      boxShadow: '4px 4px 10px rgba(115, 52, 133, 0.16)',
      borderRadius: 8
    },
    rowContainer: {
      margin: '0 26px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      height: '100%',
      width: 'auto',

      [(theme as any).breakpoints.down('sm')]: {
        margin: '0 16px',
        flexWrap: 'nowrap'
      }
    },
    nameWrapper: {
      minWidth: '20%',
      whiteSpace: 'nowrap',
      marginRight: 10,
      position: 'relative',
      flexBasis: '25%',
      [(theme as any).breakpoints.down('sm')]: {
        flexBasis: '100%',
        height: 28,
        display: 'flex',
        alignItems: 'flex-end'
      }
    },
    name: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.common.black
    },
    nameMobile: {
      fontWeight: 500,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.common.black,
      whiteSpace: 'nowrap',
      // prevent cutting letter on the bottom
      paddingBottom: 3,
      textAlign: 'left'
    },
    firstAttrWrapper: {
      minWidth: 73,
      marginRight: 12,
      whiteSpace: 'nowrap',
      flexBasis: '73px',
      [`@media (max-width: 1940px)`]: {
        maxWidth: '95px'
      },
    },
    containerAttributes: {
      marginBottom: 8
    },
    firstAttrIcon: {
      minWidth: 12,
      opacity: 1
    },
    manufacturer: {
      minWidth: 60,
      marginRight: 10,
      position: 'relative',
      flexBasis: '15%',
      textDecoration: 'underline',
      [`@media (max-width: 1940px)`]: {
        maxWidth: '200px'
      },
    },
    manufacturerMobile: {
      marginRight: 8,
      fontSize: theme.fontSizes.tiny
    },
    attributesWrapper: {
      flexGrow: 1,
      minWidth: 70,
      flexBasis: '20%',
      maxWidth: '95%',
      [`@media (max-width: 1940px)`]: {
        flexBasis: '19%',
        maxWidth: '225px',
      },
      [`@media (max-width: 1740px)`]: {
        flexBasis: '19%',
        maxWidth: '145px',
      },
      [`@media (max-width: 1540px)`]: {
        flexBasis: '18%',
        maxWidth: '135px',
        marginRight: 5
      }
    },
    attributes: {
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',

      [(theme as any).breakpoints.down('sm')]: {
        marginLeft: 8
      }
    },
    categoryWrapper: {
      minWidth: '10%',
      marginRight: 10,
      whiteSpace: 'nowrap',
      flexBasis: '15%',
      flexGrow: 3,
      [`@media (max-width: 1940px)`]: {
        maxWidth: 120,
        flexBasis: '10%',
        flexGrow: 'auto',
      },
      [`@media (max-width: 1540px)`]: {
        marginRight: 0
      }
    },
    priceTitle: {
      display: 'flex',
      justifyContent: 'center',
      [(theme as any).breakpoints.down('sm')]: {
        height: 28,
        display: 'flex',
        justifyContent: 'flex-end'
      }
    },
    price: {
      whiteSpace: 'nowrap',
      marginRight: 20,
      flexBasis: '72px',

      [(theme as any).breakpoints.down('sm')]: {
        marginRight: 0,
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      },
      [`@media (max-width: 1540px)`]: {
        marginRight: 8
      },
      [`@media (max-width: 720px)`]: {
        marginRight: 0
      }
    },
    priceItem: {
      width: 70,
      textAlign: 'right',
      fontWeight: 700,
      [(theme as any).breakpoints.down('sm')]: {
        fontWeight: 400
      }
    },
    priceItemPreOrder: {
      color: theme.palette.common.helperBlack,
      [(theme as any).breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      }
    },
    priceBold: {
      '& > *': {
        fontWeight: 700
      }
    },
    discountBadgeWrapper: {
      minWidth: 44,
      marginRight: 6
    },
    discountBadge: {
      padding: '2px 8px',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.black,
      borderRadius: 20,

      [(theme as any).breakpoints.down('sm')]: {
        padding: '2px 9px',
        width: 'fit-content',
        marginLeft: 10,
      }
    },
    preOrderText: {
      [(theme as any).breakpoints.down('sm')]: {
        fontSize: 10,
        fontWeight: 400
      }
    },
    discountText: {
      fontWeight: 700,

      [(theme as any).breakpoints.down('sm')]: {
        fontSize: theme.fontSizes.tiny
      }
    },
    discountBadgeHeader: {
      width: 54
    },
    ellipsisText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%'
    },
    overFlowText: {
      overflow: 'hidden'
    },
    priceLabel: {
      '& > *': {
        fontWeight: 400,
        lineHeight: 1
      }
    },
    mainContainer: {
      flex: 1,
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'space-between',
      padding: '8px 0 4px 0',

      [(theme as any).breakpoints.down('sm')]: {
        overflowX: 'auto'
      }
    },
    tooltipMobileDiscountPopper: {
      zIndex: 1500
    },
    tooltipMobileDiscountTooltip: {
      [(theme as any).breakpoints.down('sm')]: {
        margin: '7px 0',
        marginTop: '12px !important'
      }
    },
    tooltipMobileDiscountRoot: {
      [(theme as any).breakpoints.down('sm')]: {
        marginLeft: 10,
        marginTop: 3
      }
    },
    iconPreOrder: {
      color: '#CDDC39'
    },
    preOrderTooltip: {
      fontSize: 16,
      fontWeight: 500,
      marginBottom: 8
    },
    preOrderTooltipDate: {
      fontWeight: 400
    },
    preOrderContainer: {
      marginBottom: 18,
      marginRight: 4
    },
    preOrderTooltipLabel: {
      display: 'flex',
      minWidth: 18
    }
  })
);
