import { useEffect } from 'react';
import { useTrackInitialPageLoad } from 'controllers';
import { useDispatch, useSelector } from 'react-redux';
import { getReplacementData } from 'store/static-page/selectors';
import { fetchStaticPagesAsync } from 'store/static-page/actions';
import { decodeHtmlFields } from '../../../utils/transforms';
import { IStaticPageData } from '../../../models/static-page';

const StaticReplacementHtml = () => {
  useTrackInitialPageLoad();
  const dispatch = useDispatch();
  const staticPageData = useSelector(getReplacementData);

  useEffect(() => {
    dispatch(fetchStaticPagesAsync.request('replacement'));
    // eslint-disable-next-line
  }, []);

  const decodedRecord = decodeHtmlFields<IStaticPageData>(staticPageData ? staticPageData : undefined, ['content']);
  let replacementData = '';
  try {
    const jsonArray = JSON.parse(decodedRecord?.content || '[]');
    if (jsonArray && jsonArray[0]) {
      replacementData = jsonArray[0].content;
    }
  } catch (error) {
    console.log('Error parsing JSON:', error);
  }

  return (
    <div className="c10" style={{ maxWidth: 1024 }}>
      <h3 className="c11" style={{ marginBottom: 50 }}>
        {staticPageData?.title}
      </h3>
      <div dangerouslySetInnerHTML={{ __html: replacementData }} />
    </div>
  );
};

export default StaticReplacementHtml;
