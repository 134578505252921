import React from "react";
import Lottie from "react-lottie";
import animationData from "./flr-preloader.json";
import Overlay from "./LoadingOverlay";

export interface IFlrPreloaderProps {
  size?: number;
  withOverlay?: boolean;
}

const FlrLoader = (props: IFlrPreloaderProps) => {
  const useSize = props.size || 100;
  const withOverlay = props.withOverlay || false;

  const defaultOptions = {
    renderer: "svg",
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid"
    }
  };

  const spinner = <Lottie options={defaultOptions} height={useSize} width={useSize} />;

  return (
    <>{withOverlay ? <Overlay>{spinner}</Overlay> : <div style={{ width: useSize, height: useSize }}>{spinner}</div>}</>
  );
};

export default FlrLoader;
