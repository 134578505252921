import { FC, useState } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import clsx from 'clsx';
import { isMobileDevice } from 'environment';
import _ from 'lodash';
import { Button, IconButton } from '@mui/material';

import ProductDetails from 'components/cards/shared/ProductDetails';
import FlrLoader from 'components/loading/LoadingSpinner';
import { SplitedPrice } from 'components/prices';
import Icon from 'components/shared/Icon';
import { GridDivider } from 'components/shared/table/dividers';
import { TextSubTitle } from 'components/shared/text';
import { GTM } from 'controllers';
import { CartItem, Product } from 'models';
import { editMiniCartProduct } from 'store/cart/actions';
import {
  getMiniCartMultiUpdateDone,
  getMiniCartMultiUpdateLoading,
  getMiniCartUpdatingProduct
} from 'store/cart/selectors';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/cart/mini-cart';

import { useDidUpdate } from '../../../utils/hooks';
import MiniCartDetailsCardItemOrder from './MiniCartDetailsCardItemOrder';

import classes from './MiniCartDetailsCardItem.module.scss';

interface IOwnProps {
  items: CartItem[];
  allItemsCount: number;
  product: Product;
  prefix: string;
  index: number;
  onApplyEdit: (items: CartItem[]) => void;
}

interface IStateProps {
  isApplyingUpdate: boolean;
  editingProduct: string;
}

interface IDispatchProps {
  editProduct: typeof editMiniCartProduct;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const isMobile = isMobileDevice(window.navigator);

const MiniCartDetailsCardItem: FC<IProps> = ({
  allItemsCount,
  items,
  product,
  prefix,
  index,
  editProduct,
  editingProduct,
  onApplyEdit,
  isApplyingUpdate
}) => {
  const isLast = allItemsCount === index;
  const isFirst = index === 1;
  const isEdit = `${prefix}-${product.id}` === editingProduct;
  const [tmpItems, setTmpItems] = useState(_.cloneDeep(items));

  const handleApply = () => {
    if (isApplyingUpdate) {
      return;
    }
    GTM.trackApplyQtyInCart(tmpItems);
    onApplyEdit(tmpItems);
  };

  useDidUpdate([items], () => {
    setTmpItems(_.cloneDeep(items));
  });

  const handleUpdate = (newQty: number, itemIndex: number) => {
    const newItems = _.cloneDeep(tmpItems);
    if (newQty < newItems[itemIndex].qty) {
      GTM.trackRemoveQtyOfItemFromCart(newItems[itemIndex], newQty);
    }
    newItems[itemIndex].qty = newQty;
    setTmpItems(newItems);
  };

  const handleRemove = (itemIndex: number) => {
    const newItems = _.cloneDeep(tmpItems);
    newItems[itemIndex].qty = 0;
    GTM.trackRemoveQtyOfItemFromCart(newItems[itemIndex], 0);
    setTmpItems(newItems);
  };

  const handleCancel = () => {
    setTmpItems(items);
    editProduct('');
  };

  const onOpenEdit = () => {
    if (isEdit) {
      handleCancel();
    } else {
      editProduct(`${prefix}-${product.id}`);
    }
  };

  const tmpItemsSum = tmpItems.reduce(
    (acc, item) => acc + (item.offer.special ? item.qty * item.price : item.qty * item.clientPrice),
    0
  );
  const tmpItemsQty = tmpItems.reduce((acc, item) => acc + item.qty, 0);

  return (
    <div className={clsx(classes.miniCartCardItemAnchor, { [classes.active || '']: isEdit })}>
      <div
        className={clsx(classes.container, {
          last: isLast,
          first: isFirst,
          [classes.editContainer || '']: isEdit
        })}
      >
        <div className={clsx(classes.itemRow, classes.rightSideItem)} onClick={onOpenEdit}>
          <Icon
            type={isEdit ? 'chevronUp' : 'chevronDown'}
            size={24}
            offset={3}
            onClick={handleCancel}
            className={classes.collapseItem}
          />
          <div className={classes.wrapper}>
            <div className={clsx(classes.miniCartCardItemTexts, 'right-side-item-texts')}>
              <TextSubTitle className={classes.fullName}>{product.fullName}</TextSubTitle>

              {isMobile ? (
                <SplitedPrice
                  value={tmpItemsSum}
                  fontColor={'inherit'}
                  fontSize={'h3'}
                  fontSizeDecimal={'h5'}
                  className={clsx(classes.sumPrice, {[classes.sumPriceActive]: isEdit})}
                />
              ) : (
                <SplitedPrice
                  fontColor={'textSecondary'}
                  value={tmpItemsQty}
                  fontSize={'body2'}
                  hideDecimal
                  postfix={'шт'}
                  className={classes.quantityItems}
                />
              )}
            </div>
            <div className={clsx(classes.itemInfo, 'right-side-item-texts')}>
              {isMobile ? (
                <div className={classes.inPackageCountContainer}>
                  <Icon type="package" size={14} />
                  <span className={classes.inPackageCount}>
                    {product.inPackageCount}&nbsp;{product.unit}
                  </span>
                </div>
              ) : null}
              <ProductDetails product={product} attrsOnly />
              {isMobile ? (
                <SplitedPrice
                  fontColor={'textSecondary'}
                  value={tmpItemsQty}
                  fontSize={'body2'}
                  hideDecimal
                  postfix={'шт'}
                  className={classes.quantityItems}
                />
              ) : (
                <SplitedPrice
                  value={tmpItemsSum}
                  fontColor={'inherit'}
                  fontSize={'h3'}
                  fontSizeDecimal={'h5'}
                  className={classes.sumPrice}
                />
              )}
            </div>
          </div>
          <div className={clsx(classes.editBtn, classes.miniCartCardItemEditBtn)}>
            <IconButton aria-label="edit" size="large">
              <Icon type={'edit'} size={20} />
            </IconButton>
          </div>
        </div>
        {isEdit && (
          <div className={clsx(classes.miniCartCardItemEdit, classes.rightSideItem)}>
            <GridDivider />
            {tmpItems.map((item: CartItem, indexInner) =>
              item.qty > 0 ? (
                <MiniCartDetailsCardItemOrder
                  key={`rb-inner-${item.product.id}-${indexInner}`}
                  item={item}
                  onChange={(newQty: number) => handleUpdate(newQty, indexInner)}
                  onRemove={() => handleRemove(indexInner)}
                  isEdit={isEdit}
                />
              ) : null
            )}
            <div className={classes.buttons}>
              <Button color={'primary'} onClick={handleCancel} className={classes.cancelBtn}>
                {messages.cancelBtnLabel.defaultMessage}
              </Button>
              <Button
                color={'primary'}
                variant={'outlined'}
                onClick={handleApply}
                disabled={isApplyingUpdate}
                startIcon={isApplyingUpdate ? <FlrLoader size={20} /> : undefined}
                className={classes.applyBtn}
              >
                {messages.submitBtnLabel.defaultMessage}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state: IApplicationState) => ({
  isApplyingUpdate: getMiniCartMultiUpdateLoading(state),
  isApplyAvailable: getMiniCartMultiUpdateDone(state),
  editingProduct: getMiniCartUpdatingProduct(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      editProduct: editMiniCartProduct
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(MiniCartDetailsCardItem as any);
