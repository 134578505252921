import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import GoogleMapReact from 'google-map-react';
import { useTrackInitialPageLoad } from 'controllers';
import { TitleH1, TitleH2, TextSubTitle, TextBody1, TextBody2 } from 'components/shared/text';
import Icon from 'components/shared/Icon';
import ContactsForm from 'components/shared/contacts/ContactsForm';
import mapColorOptions from 'styles/map/default';
import Container from 'components/layout/Container';
import SocialLinks from 'components/shared/SocialLinks';
import messages from 'translations/main/contacts';
import { WarehouseCard, IWarehouse } from './WarehouseCard';

import styles from './styles';

interface IProps {
  classes?: any;
}

const defaultMapProps = {
  center: {
    lat: 50.474261,
    lng: 30.59002
  },
  zoom: 14
};

const socialIcons = [
  { icon: 'telegram', title: 'Telegram', link: 'https://t.me/@FlorotekaBot' },
  { icon: 'facebook', title: 'Facebook', link: 'https://www.facebook.com/floroteka/' },
  { icon: 'instagram', title: 'Instagram', link: 'https://www.instagram.com/floroteka/' }
];

const warehouses: IWarehouse[] = [
  {
    name: 'Флоротека Київ',
    city: 'Київ',
    type: 'warehouse',
    address: 'вул. Старосільська 1',
    coordinates: {
      latitude: 50.474245364117465,
      longitude: 30.590174043004847
    },
    phones: ['38 096 911 10 10', '38 095 911 10 10', '38 073 911 10 10']
  },
  {
    name: 'Флоротека Цитадель',
    city: 'Львів',
    type: 'warehouse',
    address: 'вул. Грабовського 11',
    coordinates: {
      latitude: 49.83175360568778,
      longitude: 24.0231253575375
    },
    phones: ['38 068 911 95 95', '38 066 911 95 95', '38 073 911 95 95']
  },
  {
    name: 'Флоротека Шувар',
    city: 'Львів',
    type: 'warehouse',
    address: 'вул. Хуторівка 4Б',
    coordinates: {
      latitude: 49.796569,
      longitude: 24.0336351
    },
    phones: ['38 097 911 93 93', '38 066 911 93 93', '38 073 911 93 93']
  }
];

const AboutUs: React.FC<IProps> = ({ classes }) => {
  useTrackInitialPageLoad();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const iconCommonProps = {
    className: classes.mapIcon,
    type: 'markerGoogle',
    width: 21,
    height: 38,
    opacity: 1
  };

  const iconStyle = { paddingBottom: 0, paddingLeft: 8, boxSizing: 'content-box' };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={classes.root}>
        <Grid container>
          <Grid item>
            <TitleH1 style={{ fontSize: 48, paddingTop: 30, marginBottom: 60 }}>Головний офіс</TitleH1>
          </Grid>
          <Grid item container className={classes.aboutContainer}>
            <Grid item xs={12} md={5}>
              <TitleH2 style={{ marginTop: 0, marginBottom: 20, fontSize: 32 }}>Про нас</TitleH2>
              <TextBody1 component={'p'} style={{ marginBottom: 16 }}>
                Вас вітає Флоротека
                <span role="img" aria-labelledby="flower">
                  🌹
                </span>
              </TextBody1>
              <TextBody1 component={'p'} style={{ marginBottom: 16 }}>
                Один із лідерів ринку з понад десятирічним досвідом в реалізації квіткової продукції по всій Україні! У
                нас найбільший в країні асортимент продукції. Наша клієнтська база налічує більше 10000 клієнтів. Ми
                ростемо, розвиваємось і продаємо тільки якісну квіткову продукцію!
              </TextBody1>
              <TextBody1 component={'p'} style={{ marginBottom: 16 }}>
                Завітайте до нас за хорошим настроєм та гарними квітами. Якщо ви не знайшли те, що хотіли - не біда,
                привеземо під замовлення все, що забажаєте
                <span role="img" aria-labelledby="ok">
                  👌
                </span>
              </TextBody1>
              <TextBody1 component={'p'} style={{ marginBottom: 16 }}>
                Приєднуйтесь
                <span role="img" aria-labelledby="wink">
                  😉
                </span>
              </TextBody1>
            </Grid>
            <Grid item xs={12} md={4} container className={classes.contactsContainer}>
              <Grid item container justifyContent="center" direction="column" wrap="wrap" alignContent="center" gap={4}>
                <Grid item container xs={4}>
                  <Grid item xs>
                    <Grid item container xs style={{ marginBottom: 10 }}>
                      <Icon type="phone" size={16} offset={12} opacity={1} style={{ marginLeft: 6 }} />
                      <a href={`tel:0 800 202 862`}>
                        <TextSubTitle>0 800 202 862</TextSubTitle>
                      </a>
                    </Grid>
                    <SocialLinks icons={socialIcons} />
                  </Grid>
                </Grid>
                <Grid item container xs={4}>
                  <Grid item xs={'auto'}>
                    <Icon type={'clock'} withBackground={true} style={iconStyle} />
                  </Grid>
                  <Grid item xs>
                    <TextSubTitle style={{ color: '#000', marginBottom: 10 }}>Години роботи</TextSubTitle>
                    <TextBody2 style={{ marginBottom: 4 }}>з 8:00 до 21:00</TextBody2>
                    <TextBody2>без вихідних</TextBody2>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TitleH1 style={{ fontSize: 48, paddingTop: 30, marginTop: 30, marginBottom: 30 }}>
              Квіткові бази
            </TitleH1>
          </Grid>
          <Grid item container justifyContent="space-between" className={classes.warehousesContainer}>
            <Grid item container alignContent="flex-start" spacing={1}>
              {warehouses.map((wh, index) => (
                <WarehouseCard
                  onSelect={() => setSelectedIndex(index)}
                  {...wh}
                  key={index}
                  selected={selectedIndex === index}
                />
              ))}
            </Grid>
            <Grid item sm={12} xs={12}>
              <div style={{ width: '100%', height: 450, gridArea: 'map' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyAniQiNgrAbCIDsBqcBSY0Iqyw0-qNBWRM' }}
                  defaultCenter={{
                    lat: warehouses[selectedIndex].coordinates.latitude,
                    lng: warehouses[selectedIndex].coordinates.longitude
                  }}
                  center={{
                    lat: warehouses[selectedIndex].coordinates.latitude,
                    lng: warehouses[selectedIndex].coordinates.longitude
                  }}
                  defaultZoom={defaultMapProps.zoom}
                  options={{ styles: mapColorOptions }}
                >
                  {warehouses.map(wh => (
                    <Icon {...iconCommonProps} lat={wh.coordinates.latitude} lng={wh.coordinates.longitude} />
                  ))}
                </GoogleMapReact>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Container style={{ margin: '50px auto', maxWidth: 900, padding: '0 25px' }}>
        <TitleH2 className={classes.formTitle} style={{ fontSize: 48 }}>
          {messages.formTitle.defaultMessage}
        </TitleH2>
        <TextSubTitle
          className={classes.formSubTitle}
          component={'p'}
          style={{ marginBottom: 32, color: 'rgba(0, 0, 0, 0.44)' }}
        >
          {messages.formCaption.defaultMessage}
        </TextSubTitle>
        <ContactsForm />
      </Container>
    </div>
  );
};

export default withStyles<any>(styles)(AboutUs);
