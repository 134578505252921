import React from 'react';
import { withStyles } from '@mui/styles';
import styles from './styles';

export interface IPropsSnackbarContent {
  title?: string | React.ReactNode;
  classes?: any;
}

const SnackbarContentWithTitle: React.FC<IPropsSnackbarContent> = ({ children, title, classes }) => {
  return (
    <div>
      {title && <p className={classes.title}>{title}</p>}
      <div className={classes.text}>{children}</div>
    </div>
  );
};

export default withStyles<any>(styles)(SnackbarContentWithTitle);
