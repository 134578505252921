import { FC, SyntheticEvent } from 'react';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import { Theme, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import { SplitedPrice } from 'components/prices';
import FlrCard from 'components/shared/card/FlrCard';
import Icon from 'components/shared/Icon';
import { TextCaption, TextHelper, TitleH2 } from 'components/shared/text';
import { Product } from 'models/product';
import cardMessages from 'translations/catalog/details';
import { getProductImage } from 'utils/helpers';

import { getUserAccount, getUserProfile } from '../../../store/account/selectors';
import { watchProductAsync } from '../../../store/catalog/actions';
import { Text } from '../../layout/content/Text';
import { ColorCircle } from '../../shared/product/ColorCircle';
import { CardDiscountIcon } from '../shared/CardDiscountIcon';
import MobileCard from './MobileCard';
import { ProductCardContainer } from './styles';

interface IProps {
  className?: string;
  item: Product;
  onClick?: (product: Product) => void;
  isSelected?: boolean;
}

export const CatalogProductCardHome: FC<IProps> = ({ className, item, onClick = () => null, isSelected = false }) => {
  const user = useSelector(getUserAccount);
  const userProfile = useSelector(getUserProfile);
  const theme: Theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!item) {
    return null;
  }

  const isWatch = userProfile && userProfile.watchList.includes(item.id);
  const onFavoriteClick = (ev: SyntheticEvent | MouseEvent) => {
    ev.stopPropagation();
    ev.preventDefault();
    dispatch(watchProductAsync.request({ productId: item.id, watch: !isWatch }));
  };
  return (
    <>
      {isMobile ? (
        <MobileCard
          item={item}
          user={user}
          onClick={() => onClick(item)}
          isWatch={isWatch}
          isSelected={isSelected}
          onFavoriteClick={() => onFavoriteClick}
          withPrice={true}
        />
      ) : (
        <ProductCardContainer>
          <FlrCard
            hoverShadow
            zeroPadding
            onClick={() => onClick(item)}
            className={clsx('home-page-cards', { selected: isSelected, [className || '']: Boolean(className) })}
          >
            <div className={clsx('grid-container home-page-grid-container', { unauthorized: !user && !userProfile })}>
              <div className="photo-container">
                <div
                  className="photo"
                  style={{
                    backgroundImage: getProductImage(item, true)
                  }}
                />
              </div>
              <div className="manufacturer-container">
                {item.getAttribute('country') && item.getAttribute('country').value && (
                  <FlagIcon
                    code={item.getAttribute('country').value as FlagIconCode}
                    style={{ marginRight: 6 }}
                    size={12}
                    className="flag-icon"
                  />
                )}
                &nbsp;
                <TextHelper className="manufacturer-label">{item.getAttribute('manufacturer').value}</TextHelper>
              </div>
              <div className="main-container">
                <TitleH2 className="main-title home-page-main-title">{item.fullName}</TitleH2>
                <TextCaption className="main-type">{item.catalogCategory}</TextCaption>
              </div>
              <div className="packing-container">
                <div className="packing-wrapper">
                  <Icon type="package" size={19} offset={10} />
                  <span>{item.inPackageCount}</span>
                  &nbsp;
                  <span>{item.unit}</span>
                </div>
              </div>
              <div className="attributes-container">
                {item.attributesList?.length ? (
                  <>
                    <div className="attr-container">
                      <div className="attr-content">
                        <ColorCircle color={item?.attributes?.color?.value} className="color-circle" />
                        <span className="color">Колір</span>
                      </div>
                      {item.attributesList[0] ? (
                        <div className="attr-content">
                          <Icon type={item.attributesList[0].alias} size={19} offset={8} />
                          <p className="attr-value">
                            {item.attributesList[0].value} {item.attributesList[0].format}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="other-attributes-wrapper">
                      {item.attributesList.slice(1, 3).map((attr) => (
                        <div className="attr-content" key={attr.code}>
                          <Icon type={attr.alias} size={19} offset={8} />
                          <p className="attr-value">{attr.value}</p>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
              {user && userProfile ? (
                <div className="card-footer">
                  <div className="price">
                    <CardDiscountIcon discount={item.discount} details={item.discountDetails} />
                    {item.priceRange ? (
                      <div className="card-footer-double-price-container">
                        {!item.priceRangeEquals ? <span className="min-price-label">Від</span> : null}
                        <SplitedPrice
                          fontColor={'#000000'}
                          fontSize={'h3'}
                          value={item.minPrice}
                          className="maxPrice"
                        />
                      </div>
                    ) : item.hasPreOrder && item.preOrderPrice ? (
                      <div className={clsx('card-footer-price', { 'pre-order': item.hasPreOrder })}>
                        {moment(item?.preOrderPriceDate).isValid() ? (
                          <Text className="pre-order-text">На {moment(item.preOrderPriceDate).format('DD.MM.YY')}</Text>
                        ) : null}
                        <SplitedPrice
                          value={item.preOrderPriceWithDiscount}
                          className="pre-order-price"
                          fontColor={'inherit'}
                          fontSize={'h3'}
                          fontSizeDecimal={'medium'}
                          priceDisplayStyle={{ fontWeight: 700 }}
                          prefix="~"
                        />
                      </div>
                    ) : (
                      cardMessages.notInStock.defaultMessage
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </FlrCard>
        </ProductCardContainer>
      )}
    </>
  );
};
