import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import env from "environment";
import { get } from "utils/api";
import {
  cartGroupCheckoutStorageKey,
  registrationFormStepStorageKey,
  registrationTokenStorageKey
} from "shared/constants";

interface ILoginFromAdminPanel {
  children: JSX.Element;
}

const LoginFromAdminPanel = (props: ILoginFromAdminPanel) => {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const [queryParam, queryValue] = location.search.split("=");
    if (queryParam.includes("admin_token") && queryValue) {
      setLoading(true);
      window.localStorage.removeItem("token");
      get<{ token: string }>(`${env.apiUrl}/auth/token/${queryValue}`)
        .then(response => {
          if (response.ok && response.parsedBody) {
            window.localStorage.setItem("token", response.parsedBody.token);
            window.localStorage.removeItem(cartGroupCheckoutStorageKey);
            window.localStorage.removeItem(registrationFormStepStorageKey);
            window.localStorage.removeItem(registrationTokenStorageKey);
            window.location.replace("/");
          } else {
            throw new Error("Error while login. Auth token is invalid.");
          }
        })
        .catch(() => setError(Error("Error while login. Auth token is invalid.")))
        .finally(() => setLoading(false));
    } else if (queryParam.includes("admin_token") && !queryValue) {
      setError(new Error("Error while login from admin panel. Auth token are missed."));
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <div>...loading...</div>;
  }

  if (error) {
    return (
      <div>
        <p>Ooops!</p>
        <p>{error.message || "Error while login."}</p>
      </div>
    );
  }

  return !loading && !error ? props.children : null;
};

export default LoginFromAdminPanel;
