import { emphasize } from '@mui/material/styles';

import { defaultMaterialTheme, ITheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  root: {
    flexGrow: 1,
    minWidth: 50,
    height: 250
  },
  label: {
    maxWidth: 'calc(100% - 50px)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginTop: '-5px !important',

    '&.MuiInputLabel-shrink': {
      maxWidth: '100%',
      marginTop: '0 !important'
    }
  },
  DropdownIndicator: {
    height: 24,
    transformOrigin: 'center',
    transition: defaultMaterialTheme.transitions.create(['transform', 'margin-bottom'], {
      easing: defaultMaterialTheme.transitions.easing.sharp,
      duration: defaultMaterialTheme.transitions.duration.leavingScreen
    })
  },
  DropdownIndicatorActive: {
    transform: 'rotate(180deg)'
  },
  input: {
    display: 'flex',
    padding: 0,
    // height: 22,
    lineHeight: '22px'
  },
  inputBase: {
    '& .MuiInputBase-root': {
      marginBottom: 0,
      cursor: 'pointer'
    },
    '& > .MuiInputLabel-shrink + .MuiInputBase-formControl >  fieldset legend': {
      maxWidth: 'calc(100% - 10px)'
    }
  },
  catalogSelect: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRight: '2px solid',
      '&:after': {
        display: 'none'
      }
    }
  },
  inputDropdownMenu: {
    '&& .MuiInputBase-input': {
      padding: '12px 8px'
    },
    '& fieldset': {
      border: 'none',
      marginBottom: 3
    }
  },
  inputDropdownMenuOpen: {
    '&& .MuiInputBase-input': {
      padding: '12px 8px'
    }
  },
  inputActive: {
    '& fieldset': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTop: 'none'
    }
  },
  inputActiveTop: {
    '& fieldset': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: 'none'
    }
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '.icon': {
      display: 'none'
    }
  },
  chip: {
    margin: theme.spacing(0.5, 0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.mode === 'light' ? theme.palette.gray[300] : theme.palette.gray[700], 0.08)
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  singleValue: {
    marginBottom: 0,
    fontSize: 16,
    whiteSpace: 'nowrap',
    color: theme.palette.text.secondary
  },
  placeholder: {
    position: 'absolute' as 'absolute',
    left: 2,
    bottom: 12,
    fontSize: 16
  },
  menuItem: {
    padding: '9px 12px',
    marginBottom: 0,
    position: 'relative',

    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 12,
      right: 12,
      width: 'calc(100% - 24px)',
      height: 1,
      backgroundColor: theme.palette.common.disabledBlackBackground
    },

    '&.Mui-selected': {
      backgroundColor: 'inherit'
    },

    '& .amount': {
      fontWeight: '500'
    },
    '& .main-label, & .description, & .amount': {
      color: theme.palette.text.secondary
    },
    '& .main-label.main-label--action': {
      borderBottom: `1px solid ${theme.palette.text.primary}`
    },
    /*"&&.menuItemDisabled:hover": {
      "& .main-label, & .description, & .amount": {
        color: `${theme.palette.common.gray} !important`
      }
    },*/
    '&&:hover': {
      backgroundColor: theme.palette.common.white,
      '& .main-label, & .description, & .amount': {
        color: theme.palette.common.black
      }
    },
    '&&&:active': {
      backgroundColor: theme.palette.common.primaryClickablePressBackground,

      '&:before': {
        width: '100%',
        left: 0,
        backgroundColor: 'transparent'
      }
    },

    '&&:active + &:before, &&.FlrSelect2-menuItemSelected + &:before': {
      content: 'none'
    }
  },

  menuItemDisabled: {
    '&&, &&:hover, &&:hover.Mui-disabled': {
      cursor: 'not-allowed',
      fontWeight: 'normal',
      '& .main-label, & .description, & .amount': {
        color: theme.palette.text.disabled,
        fontWeight: 'normal'
      }
    }
  },

  menuItemSelected: {
    '&&, &&.Mui-selected': {
      backgroundColor: theme.palette.common.cardBlackBackground,

      '& .main-label, & .description, & .amount': {
        color: theme.palette.common.black
      },

      '&:before': {
        width: '100%',
        left: 0,
        backgroundColor: theme.palette.common.cardBlackBackground
      }
    }
  },

  menuItemAction: {
    '&&': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),

      // flex for icon
      display: 'flex',
      alignItems: 'center'
    }
  },
  menuItemMain: {
    flex: 1,
    marginBottom: 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  menuItemAmount: {
    maxWidth: 60,
    textAlign: 'right'
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing(0),
    left: 0,
    right: 0,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 'none',
    overflow: 'hidden',
    boxShadow: 'none',

    '& > div': {
      padding: 0,
      ...theme.scrollBar
    }
  },
  divider: {
    height: theme.spacing(2)
  }
});

export default styles;
