import { ITheme } from 'utils/styled';
import { withStyles } from '@mui/styles';

export default withStyles((theme: ITheme) => ({
  deliveryItem: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.disabledBlack}`,
    borderRadius: theme.spacing(1),
    width: '100%'
  },
  deliveryItemPreOrder: {
    backgroundColor: theme.palette.common.cardBlackBackgroundHex,
    width: '100%'
  },
  specialOrderType: {
    fontSize: 14,
    paddingLeft: 10,
    color: theme.colors.pink
  },
  transitOrderType: {
    fontSize: 14,
    paddingLeft: 10,
    color: theme.colors.alertInfo
  },
  cardFooter: {
    paddingTop: theme.spacing(2),
    paddingBottom: 0,
    paddingRight: 0,
    borderTop: `1px solid ${theme.palette.common.disabledBlack}`,

    [(theme as any).breakpoints.down('sm')]: {
      paddingLeft: '0 !important',
      paddingTop: 8
    }
  },
  cardFooterInner: {
    flexWrap: 'nowrap',
    gap: 24,
    '&>button': {
      fontWeight: 700
    }
  },
  cardFooterTotalNotEnough: {
    marginRight: theme.spacing(1.5),
    maxWidth: 140
  },
  totalPriceFontWeight: {
    fontWeight: 700
  },
  cardHeaderTitle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box'
  },
  deliveryTypeContainer: {
    display: 'flex',
    width: '100%',
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.common.disabledBlackBackground}`
  },
  deliveryTime: {
    whiteSpace: 'nowrap'
  },
  cardOuter: {
    marginBottom: theme.padding.container
  },
  card: {
    borderRadius: '2px'
  },
  cardInner: {
    borderBottom: `1px solid ${theme.colors.backgroundGray}`
  },
  cardPrices: {
    padding: 16,
    [(theme as any).breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  quantityOrdered: {
    fontWeight: 500,
    color: theme.palette.text.secondary
  },
  quantityOrderedError: {
    color: theme.palette.error.main
  },
  quantityExists: {
    color: theme.palette.text.secondary
  },
  cardOffer: {
    lineHeight: '22px',
    color: theme.palette.common.helperBlack,
    '& .active': {
      color: theme.colors.black
    }
  },
  cardAdviceSelected: {
    borderColor: theme.colors.brandPrimary
  },
  cardFooterTotalLabel: {
    lineHeight: 1,
    color: '#646464'
  },
  preOrderLabel: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1
  },
}));
