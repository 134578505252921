import * as React from "react";
import clsx from "clsx";
import { defineMessages } from "react-intl";
import { withStyles } from "@mui/styles";

import { ITheme } from "utils/styled";
import Icon from "components/shared/Icon";
import ButtonCommon from "../header-main/top/StyledButtonCommon";

interface IProps {
  dark?: boolean;
  classes?: any;
}

export const help = defineMessages({
  label: {
    id: "catalog.header.helpLabel",
    description: "help label",
    defaultMessage: "Допомога"
  }
});

export const HelpWrapper: React.FC<IProps> = ({ classes, dark }) => (
  <div
    className={clsx(classes.wrapper, {
      [classes.wrapperDark]: dark
    })}
  >

    <a
      target={'_blank'}
      rel="noopener noreferrer"
      href="https://docs.google.com/document/u/1/d/e/2PACX-1vT9kg48w35ZxEW3z_1SDA36we8WaMfCy0YHAVNvRtvODFrejDbeQVjZpj3t2Qhbm26spB6s-IpKVzA7/pub"
    >
      <ButtonCommon dark={dark}>
        <Icon type={"support"} size={24} offset={8} />
        <span>{help.label.defaultMessage}</span>
      </ButtonCommon>
    </a>
  </div>
);

const styles = (theme: ITheme) => ({
  wrapper: {
    width: "fit-content",
    fontFamily: theme.fonts.headings,
    letterSpacing: theme.letterSpacing,
    paddingLeft: 14,
    marginLeft: 7,
    color: theme.palette.text.secondary,
    borderLeft: `1px solid ${theme.palette.common.disabledBlack}`,
    gap: 11,
  },
  wrapperDark: {
    marginRight: "auto",
    borderLeft: `1px solid ${theme.palette.common.white}`
  }
});

export const Help = withStyles<any>(styles)(HelpWrapper);
