import { FC } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import Icon from 'components/shared/Icon';
import { TextSubTitle, TitleH1 } from 'components/shared/text';
import { getClientDiscounts } from 'store/account-discounts/selectors';
import messages from 'translations/account/dashboard';

import DashboardLoyaltyCard from './DashboardLoyaltyCard';

import classes from './DashboardLoyalty.module.scss';

// TODO !!! change any [] to IAccountDiscounts after rework DashboardLoyalty
interface IDashboardLoyaltyProps {
  discountConditions?: any[];
  hideTitle?: boolean;
  hideProfileDiscount?: boolean;
  smallCards?: boolean;
  classNameWrapper?: string;
  isBalanceLoyaltyCard?: boolean;
}

const DashboardLoyalty: FC<IDashboardLoyaltyProps> = ({
  discountConditions,
  hideTitle,
  smallCards,
  classNameWrapper,
  isBalanceLoyaltyCard
}) => {
  const clientDiscounts = useSelector(getClientDiscounts);

  return (
    <div className={clsx(classNameWrapper, classes.container)}>
      {!hideTitle && (
        <div className={classes.title}>
          <Icon className={classes.titleIcon} type={'loyalty'} opacity={1} />
          <TitleH1 className={classes.titleLoyalty}>{messages.titleLoyalty.defaultMessage}</TitleH1>
          <TextSubTitle className={classes.subTitleLoyalty}>{messages.subTitleLoyalty.defaultMessage}</TextSubTitle>
        </div>
      )}
      <div className={classes.wrapper}>
        {discountConditions && discountConditions.length
          ? discountConditions.map((item) => (
              <DashboardLoyaltyCard
                key={item.name}
                discount={item}
                clientDiscount={
                  clientDiscounts && clientDiscounts.length
                    ? clientDiscounts.find(({ discountRule }) => item.id === discountRule)
                    : undefined
                }
                smallCards={smallCards}
                isBalanceLoyaltyCard={isBalanceLoyaltyCard}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default DashboardLoyalty;
