import { HttpClient } from 'utils/http';
export class AccountDiscountsRepository {
  private client = new HttpClient(`${process.env.REACT_APP_API_ENDPOINT}/account/discount/`);

  public async fetch() {
    return await this.client.get('');
  }

  public async fetchClientDiscounts() {
    return await this.client.get('conditions');
  }
}
