import { IApplicationState } from 'store/reducers';

export const getFilterOptionsState = (state: IApplicationState) => state.filterOptions;
export const getFilterOptionsLoadingState = (state: IApplicationState) => getFilterOptionsState(state).loading;
export const getFilterOptionsLoadedState = (state: IApplicationState) => getFilterOptionsState(state).loaded;
export const getFilterOptionsLoadError = (state: IApplicationState) => getFilterOptionsState(state).error;
export const getFilterOptionsData = (state: IApplicationState) => getFilterOptionsState(state).data;
export const getFilterOptionsAttributes = (state: IApplicationState) => getFilterOptionsData(state).attributes;
export const getFilterOptionsType = (state: IApplicationState) => getFilterOptionsData(state).type;
export const getFilterOptionsPrice = (state: IApplicationState) => getFilterOptionsData(state).price;
export const getFilterOptionSupplier = (state: IApplicationState) => {
  if (state.filterOptions.data.suppliers) {
    const filterSupplier = state.filterOptions.data.suppliers.find(s =>
      state.account.data.user
        ? s.id === state.account.data.user.preferences.supplier ||
          s.value === state.account.data.user.preferences.supplier
        : false
    );
    if (filterSupplier) {
      return filterSupplier;
    }
  }

  return null;
};
