import { FC, SyntheticEvent } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { IconButton } from '@mui/material';
import { Product, User } from '../../../models';
import FlrCard from '../../shared/card/FlrCard';
import { ProductCardImage } from './product-card-image';
import Icon from '../../shared/Icon';
import Tooltip from '../../shared/tooltip';
import { ColorCircle } from '../../shared/product/ColorCircle';
import { SplitedPrice } from '../../prices';
import { Text } from '../../layout/content/Text';
import cardMessages from '../../../translations/catalog/details';
import { CardDiscountIcon } from '../shared/CardDiscountIcon';
import classes from './MobileCard.module.scss';

interface IMobileCardProps {
  item: Product;
  withPrice?: boolean;
  onClick?: (product: Product) => void;
  onFavoriteClick?: (ev: SyntheticEvent | MouseEvent) => void;
  isWatch?: boolean;
  isSelected?: boolean;
  user?: User | null;
  isPriceByPackage?: boolean;
}

const MobileCard: FC<IMobileCardProps> = ({
  item,
  withPrice = false,
  onClick = () => null,
  onFavoriteClick,
  isSelected = false,
  isWatch,
  user,
  isPriceByPackage
}) => {
  const productManufacturer = item.attributes.manufacturer || {};
  const productColor = item.attributes.color || {};
  return (
    <FlrCard
      hoverShadow
      zeroPadding
      onClick={() => onClick(item)}
      className={clsx({ selected: isSelected }, classes.catalogCard)}
    >
      <div className={clsx(classes.container, { [classes.unauthorized]: !user })}>
        <ProductCardImage
          isSelected={isSelected}
          productImages={item.images}
          productId={item.id}
          productUnit={item.unit}
          isMobile
          classNameWrapper={classes.photoContainer}
          className={classes.photo}
        />
        <div className={classes.mainContainer}>
          <div className={classes.specialPriceIcon}>
            {item.specialPrice && !isSelected ? (
              <Icon type="fireBordered" size={32} className={classes.icon} opacity={1} />
            ) : null}
          </div>
          <div className={clsx(classes.inTransitIcon, { [classes.transitIcon]: !item.specialPrice && !isSelected })}>
            {item.hasTransit && !isSelected ? (
              <Icon type="goodsComingBordered" size={32} className={classes.icon} opacity={1} />
            ) : null}
          </div>
          <div className={classes.name}>
            {item.fullName.length > 36 && !isSelected ? (
              <Tooltip title={<span>{item.displayName}</span>}>
                <span className={classes.mainTitle}>{item.fullName}</span>
              </Tooltip>
            ) : (
              <span className={classes.mainTitle}>{item.fullName}</span>
            )}
          </div>
        </div>
        <div className={classes.manufacturerContainer}>
          <span className={classes.manufacturerLabel}>{productManufacturer.value}</span>
          <div className={classes.packageInfo}>
            <Icon type="package" size={14} offset={4} leftOffset={4} />
            <span>{item.inPackageCount}</span>
            &nbsp;
            <span>{item.unit}</span>
          </div>
        </div>
        <div className={classes.attributesContainer}>
          <div className={classes.attributes}>
            <ColorCircle color={productColor.value} className={classes.colorCircle} />
            {item.attributesList?.length
              ? item.attributesList.map((attr) => (
                  <div key={attr.code} className={classes.otherAttributesItem}>
                    <Icon size={14} type={attr.alias} leftOffset={8} offset={2} opacity={1} />
                    <p className={classes.otherAttributeValue}>{attr.value}</p>
                  </div>
                ))
              : null}
          </div>
        </div>
        {withPrice && user ? (
          <div className={clsx(classes.cardFooter, { [classes.noPrice]: !withPrice })}>
            {item.discount ? (
              <CardDiscountIcon
                discount={item.specialPriceDiscount > item.discount ? item.specialPriceDiscount : item.discount}
                details={item.discountDetails}
                className={classes.discountBadge}
                specialPriceDiscount={item?.specialPriceDiscount}
              />
            ) : null}
            <div
              className={clsx(classes.price, {
                [classes.preOrder]: !item.priceRange,
                [classes.discout]: item.discount
              })}
            >
              {item.priceRange ? (
                <div className={classes.doublePriceContainer}>
                  {!item.priceRangeEquals ? <span className={classes.label}>Від</span> : null}
                  <SplitedPrice
                    fontColor={'#000000'}
                    fontSize="large"
                    value={item.minPrice * (isPriceByPackage ? item.inPackageCount : 1)}
                  />
                </div>
              ) : item.hasPreOrder && item.preOrderPrice ? (
                <div className={classes.cardFooterPrice}>
                  {moment(item?.preOrderPriceDate).isValid() ? (
                    <Text className={classes.preOrderText}>На {moment(item.preOrderPriceDate).format('DD.MM.YY')}</Text>
                  ) : null}
                  <div>
                    <span className={classes.boldText}>~</span>
                    <SplitedPrice
                      value={item.preOrderPriceWithDiscount * (isPriceByPackage ? item.inPackageCount : 1)}
                      className={classes.preOrderPrice}
                      fontColor={'inherit'}
                      fontSize="large"
                    />
                  </div>
                </div>
              ) : (
                cardMessages.notInStock.defaultMessage
              )}
            </div>
          </div>
        ) : null}
        {user && withPrice && (
          <IconButton
            size={'small'}
            onClick={onFavoriteClick}
            className={clsx(classes.favourite, { [classes.productSelected]: isSelected, [classes.selected]: isWatch })}
          >
            <Icon type={isWatch ? 'heartFull' : 'heart'} size={26} />
          </IconButton>
        )}
      </div>
    </FlrCard>
  );
};

export default MobileCard;
