import React from "react";
import { withStyles } from "@mui/styles";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { validateEmail, validatePhoneMask } from "utils/helpers";
import { User } from "models/user";
import FlrLoader from "components/loading/LoadingSpinner";
import Icon from "components/shared/Icon";
import { Link2, TextCaption, TextSubTitle } from "components/shared/text";
import FlrSelect2 from "components/shared/form-elements/select2/FlrSelect2";
import { currencies, languages } from "components/layout/header-main/top/HeaderTopSettings";
import FlrCheckboxWithLabel from "components/shared/form-elements/FlrCheckbox";

import messagesControls from "translations/layout/controls";
import profileMessages from "translations/auth/registration";
import messages from "translations/account/settings";
import message from "translations/account/settings";
import styles from "./styles";
import { defaultCurrency, defaultLocale } from "shared/constants";
import FlrInputPhone from "components/shared/form-elements/textfield/FlrInputPhone";

interface IProps {
  // component own props
  account: User;
  classes: any;
  onChange: (account: any) => void;
  onSave: (account: User) => void;
  onCancel: () => void;
}

interface IStateProps {
  // Props passed to the component by `connect`
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phone2: string;
  location: string;
  currency: string;
  language: string;
  telegram: boolean;
  viber: boolean;
  whatsapp: boolean;
}

const PersonalDataForm: React.FC<IComponentProps> = ({ account, classes, onSave, onCancel }) => {
  const defaultValues = {
    firstName: account.profile.firstName,
    lastName: account.profile.lastName,
    email: account.email,
    phone: account.phone,
    phone2: account.profile.phone2,
    location: "Киев",
    language: defaultLocale,
    currency: defaultCurrency
  };
  const [language, setLanguage] = React.useState(defaultLocale);
  const [currency, setCurrency] = React.useState(defaultCurrency);

  const { handleSubmit, errors, control, setValue } = useForm<IFormData>({ defaultValues });

  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [addPhone2, setAddPhone2] = React.useState(false);
  const toggleAddPhone2 = () => setAddPhone2(state => !state);

  const onSubmit = handleSubmit((fields): any => {
    if (disableSubmit) {
      return;
    }

    setDisableSubmit(true);

    const accountToSave = { ...account };
    const accountProfile = { ...account.profile };
    accountProfile.firstName = fields.firstName;
    accountProfile.lastName = fields.lastName;
    accountToSave.email = fields.email;
    accountToSave.phone = fields.phone;
    accountProfile.phone2 = fields.phone2;
    accountToSave.profile = accountProfile;
    // accountToSave.location = fields.location;
    // accountToSave.language = language;
    // accountToSave.currency = currency;

    onSave(accountToSave);
  });

  return (
    <Grid item sm={12}>
      <form onSubmit={onSubmit}>
        <Grid item xs={12}>
          <Controller
            defaultValue={account.profile.firstName}
            error={Boolean(errors.firstName)}
            helperText={(errors.firstName && errors.firstName.message) || " "}
            rules={{
              required: messagesControls.requiredFiled.defaultMessage
            }}
            as={TextField}
            name="firstName"
            control={control}
            className={classes.commonControl}
            fullWidth
            variant={"outlined"}
            label={profileMessages.formFieldFirstName.defaultMessage}
          />

          <Controller
            defaultValue={account.profile.lastName}
            error={Boolean(errors.lastName)}
            helperText={(errors.lastName && errors.lastName.message) || " "}
            rules={{
              required: messagesControls.requiredFiled.defaultMessage
            }}
            as={TextField}
            name="lastName"
            control={control}
            className={classes.commonControl}
            fullWidth
            variant={"outlined"}
            label={profileMessages.formFieldLastName.defaultMessage}
          />

          <Controller
            defaultValue={account.email}
            error={Boolean(errors.email)}
            helperText={(errors.email && errors.email.message) || profileMessages.usedAsLogin.defaultMessage}
            rules={{
              required: messagesControls.requiredFiled.defaultMessage,
              validate: validateEmail
            }}
            as={TextField}
            name="email"
            control={control}
            className={classes.commonControl}
            fullWidth
            variant={"outlined"}
            label={profileMessages.formFieldEmail.defaultMessage}
            type="email"
          />

          <Controller
            defaultValue={account.phone}
            error={Boolean(errors.phone)}
            helperText={(errors.phone && errors.phone.message) || profileMessages.usedAsLogin.defaultMessage}
            rules={{
              required: messagesControls.requiredFiled.defaultMessage,
              validate: validatePhoneMask
            }}
            as={FlrInputPhone}
            name="phone"
            control={control}
            className={classes.commonControl}
            fullWidth
            variant={"outlined"}
            label={profileMessages.formFieldPhone.defaultMessage}
            type="tel"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    type={account.phoneVerified ? "verificationSuccess" : "verificationProgress"}
                    size={24}
                    opacity={1}
                  />
                </InputAdornment>
              )
            }}
          />

          {!!(addPhone2 || account.profile.phone2) ? (
            <Controller
              defaultValue={account.profile.phone2}
              error={Boolean(errors.phone2)}
              helperText={(errors.phone2 && errors.phone2.message) || " "}
              as={FlrInputPhone}
              name="phone2"
              control={control}
              className={classes.commonControl}
              fullWidth
              variant={"outlined"}
              label={profileMessages.formFieldPhone2.defaultMessage}
              type="tel"
            />
          ) : (
            <Link2 component={"div"} className={classes.linkButton} onClick={toggleAddPhone2}>
              {messages.addPhone2.defaultMessage}
            </Link2>
          )}

          {/* todo backend*/}
          {/*<Controller*/}
          {/*  disabled*/}
          {/*  defaultValue={defaultValues.location}*/}
          {/*  error={Boolean(errors.location)}*/}
          {/*  helperText={(errors.location && errors.location.message) || " "}*/}
          {/*  rules={{*/}
          {/*    required: messagesControls.requiredFiled.defaultMessage*/}
          {/*  }}*/}
          {/*  as={TextField}*/}
          {/*  name="location"*/}
          {/*  control={control}*/}
          {/*  className={classes.commonControl}*/}
          {/*  fullWidth*/}
          {/*  variant={"outlined"}*/}
          {/*  label={messages.destinationCity.defaultMessage}*/}
          {/*/>*/}

          <FlrSelect2
            className={classes.commonSelectControl}
            fullWidth
            label={message.language.defaultMessage}
            value={language}
            options={languages}
            onChange={(ev: any) => {
              setValue("currency", ev.target.value);
              setLanguage(ev.target.value);
            }}
          />
          {/*<Controller*/}
          {/*  // defaultValue={defaultValues.language}*/}
          {/*  error={Boolean(errors.language)}*/}
          {/*  helperText={(errors.language && errors.language.message) || " "}*/}
          {/*  rules={{*/}
          {/*    required: messagesControls.requiredFiled.defaultMessage*/}
          {/*  }}*/}
          {/*  as={FlrSelect2}*/}
          {/*  name="language"*/}
          {/*  control={control}*/}
          {/*  className={classes.commonControl}*/}
          {/*  fullWidth*/}
          {/*  variant={"outlined"}*/}
          {/*  label={messages.language.defaultMessage}*/}
          {/*  options={languages}*/}
          {/*  value={language}*/}
          {/*  onChange={([selected]: any) => {*/}
          {/*    const {*/}
          {/*      target: { value, data }*/}
          {/*    } = selected;*/}
          {/*    console.log("--onchange--", { selected, value, data });*/}

          {/*    setValue("language", value);*/}
          {/*    setLanguage(data);*/}
          {/*  }}*/}
          {/*/>*/}

          <FlrSelect2
            className={classes.commonSelectControl}
            fullWidth
            label={message.currency.defaultMessage}
            value={currency}
            options={currencies}
            onChange={(ev: any) => {
              setValue("currency", ev.target.value);
              setCurrency(ev.target.value);
            }}
          />

          <TextSubTitle align={"left"} className={classes.subTitleGroup}>
            {messages.personalDataSubtitleAdditional.defaultMessage}
          </TextSubTitle>

          <TextCaption className={classes.groupCaption}>{messages.socialTitle.defaultMessage}</TextCaption>
          <div className={classes.socialContainer}>
            <div className={classes.socialItem}>
              <Icon size={24} type={"google"} opacity={1} offset={8} />
              <span>{messages.socialNotConnected.defaultMessage}</span>
              <span className={classes.socialAction}>{messages.socialConnect.defaultMessage}</span>
            </div>
            <div className={classes.socialItem}>
              <Icon size={24} type={"facebook"} opacity={1} offset={8} />
              <span>{messages.socialNotConnected.defaultMessage}</span>
              <span className={classes.socialAction}>{messages.socialConnect.defaultMessage}</span>
              {/* TODO */}
              {/*<span className={classes.socialAction}>*/}
              {/*  <IconButton color={"secondary"}>*/}
              {/*    <Icon size={20} type={"trash"} opacity={1} />*/}
              {/*  </IconButton>*/}
              {/*</span>*/}
            </div>
          </div>

          <TextCaption className={classes.groupCaption}>{messages.notificationTitle.defaultMessage}</TextCaption>
          <div>
            <Controller
              defaultValue={false}
              as={FlrCheckboxWithLabel}
              name={"telegram"}
              control={control}
              label={"Telegram"}
              className={classes.formInputCheckbox}
            />
          </div>
          <div>
            <Controller
              defaultValue={false}
              as={FlrCheckboxWithLabel}
              name={"viber"}
              control={control}
              label={"Viber"}
              className={classes.formInputCheckbox}
            />
          </div>
          <div>
            <Controller
              defaultValue={false}
              as={FlrCheckboxWithLabel}
              name={"whatsapp"}
              control={control}
              label={"Whatsapp"}
              className={classes.formInputCheckbox}
            />
          </div>
        </Grid>

        <Button
          className={classes.saveButton}
          color="primary"
          variant="contained"
          fullWidth
          disabled={disableSubmit}
          type={"submit"}
        >
          {messages.saveBtnLabel.defaultMessage}
        </Button>

        <Button className={classes.cancelButton} color="primary" fullWidth onClick={onCancel}>
          {messages.cancelBtnLabel.defaultMessage}
        </Button>
        {disableSubmit && <FlrLoader withOverlay={true} />}
      </form>
    </Grid>
  );
};

export default withStyles<any>(styles)(PersonalDataForm);
