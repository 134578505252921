import { homeMaxWidth } from 'shared/constants';

const styles = (theme: any) => ({
  wrapper: {
    background: 'linear-gradient(90deg, rgba(0,0,0,0) 50%, rgba(245,245,245,1) 50%)'
  },
  root: {
    // maxWidth: 1920,
    // margin: "108px auto",
    maxWidth: homeMaxWidth,
    margin: '0 auto',

    // height: 369,
    paddingTop: theme.spacing(4.5),
    paddingBottom: theme.spacing(8),

    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    background: theme.palette.gray[100],

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10)
    },
    [theme.breakpoints.up('lg')]: {},

    '& .slick-dots li.slick-active button:before': {
      color: theme.palette.primary.main,
      fontSize: 16,
      opacity: 1
    },
    '& .slick-dots li button:before': {
      top: 8,
      fontSize: 8,
      opacity: 1,
      color: theme.palette.common.helperBlack
    }
  },
  container: {
    width: '100%',
    borderRadius: 24,

    [theme.breakpoints.up('md')]: {
      width: 530
    },
    [theme.breakpoints.up('lg')]: {}
  },

  title: {
    textAlign: 'center',
    color: theme.palette.common.black,

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(5),

      textAlign: 'left',
      fontSize: 36
    }
  },

  subTitle: {
    marginBottom: theme.spacing(4),

    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
      textAlign: 'left'
    }
  },

  testimonialsItem: {
    position: 'relative',

    marginLeft: theme.spacing(2.5),
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2.25),
    display: 'flex',
    maxWidth: 'calc(100% - 32px)',

    '&:focus': {
      outline: 'none'
    }
  },
  testimonialsAvatar: {
    position: 'absolute',
    left: 0,
    top: 0,

    width: 72,
    height: 72,

    borderRadius: '50%',

    backgroundColor: theme.palette.primary.main,
    fontSize: 36,

    [theme.breakpoints.up('md')]: {
      width: 80,
      height: 80
    }
  },
  testimonialsMain: {
    marginLeft: 21,
    marginTop: theme.spacing(-2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(3),

    borderRadius: 8,
    backgroundColor: theme.palette.common.white,

    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(7),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(5)
    },

    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(7),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(5)
    },

    // eslint-disable-next-line
    ['@media (min-width:1600px)']: {
      marginRight: theme.spacing(5)
    }
  },
  testimonialsName: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0.5)
  },
  testimonialsFirm: {
    marginBottom: theme.spacing(3)
  },
  testimonialsTitle: {
    marginTop: 0,
    marginBottom: theme.spacing(2)
  },
  testimonialsDate: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',

    color: theme.palette.text.secondary
  },
  testimonialsText: {
    marginTop: 0,
    marginBottom: theme.spacing(2)
  }
});

export default styles;
