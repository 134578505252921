import { FC, ReactNode } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { withTheme } from '@mui/styles';

export interface IFlrSwitchProps extends SwitchProps {
  labelClassName?: any;
  label: string;
  labelRenderer?: (arg: any) => ReactNode;
}

const FlrSwitchWithLabel: FC<IFlrSwitchProps> = ({
  labelRenderer,
  checked,
  onChange,
  label,
  labelClassName,
  ...other
}) => {
  const onChangeBoolean = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    return onChange && onChange(event, checked);
  };

  // prevent input jumps when no helper text provided
  return (
    <FormControlLabel
      className={labelClassName}
      control={<Switch {...other} checked={checked} onChange={onChangeBoolean} />}
      label={label}
    />
  );
};

export default withTheme(FlrSwitchWithLabel);
