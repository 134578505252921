import React from 'react';
import { Grid, Hidden } from '@mui/material';
import { withStyles } from '@mui/styles';
// import { Breakpoint } from '@mui/material/styles';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';

import { IApplicationState } from 'store/reducers';
import { IMainContactsData } from 'store/main/actions';
import { getContactsData } from 'store/main/selectors';

import { TitleH1, TitleH2, TextBody1, TextSubTitle } from 'components/shared/text';
import Icon from 'components/shared/Icon';
import OfficesMap from 'components/shared/contacts/OfficesMap';

import ContactsForm from './ContactsForm';
import styles from './styles';
import messages from 'translations/main/contacts';
import { phoneDefault } from 'shared/constants';

interface IOwnProps {
  // component own props
  classes?: any;
}

interface IStateProps {
  contacts: IMainContactsData | null;
}

interface IDispatchProps {}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const MainContacts: React.FC<IProps> = ({ classes, contacts }) => {
  let phones;
  try {
    if (contacts && contacts.phones && contacts.phones.value) {
      phones = contacts.phones.value.split(',');
    }
  } catch (e) {
    // handle?
  }

  let address;
  if (contacts && contacts.address && contacts.address.value) {
    address = contacts.address.value;
  }
  let workHours;
  if (contacts && contacts.workHours && contacts.workHours.value) {
    workHours = contacts.workHours.value;
  }
  const iconStyle = { paddingBottom: 10, paddingLeft: 5, boxSizing: 'content-box' };

  return (
    <section className={classes.wrapper}>
      <Grid container className={classes.root} alignItems={'flex-end'}>
        <Grid className={classes.mapColumn} item lg={7} md={12}>
          <Grid container item>
            <Grid item xs={1} implementation="css" mdDown component={Hidden} />

            <Grid item xs={11}>
              <TitleH1 className={classes.title} color={'inherit'} style={{ marginBottom: 35 }}>
                {messages.title.defaultMessage}
              </TitleH1>

              <div className={classes.contactsList}>
                <div className={classes.contactsItem}>
                  <Icon
                    className={classes.contactsItemIcon}
                    type={'phoneContact'}
                    width={20}
                    height={20}
                    withBackground
                    opacity={1}
                    style={iconStyle}
                  />
                  <TitleH2 className={classes.contactsItemTitle} style={{ marginTop: 6 }}>
                    {messages.phoneLabel.defaultMessage}
                  </TitleH2>

                  {phones ? (
                    phones.map(
                      (phone: any, index: number) =>
                        phone && (
                          <a key={index} href={`tel:${phone}`}>
                            <TextBody1 key={index}>{phone}</TextBody1>
                          </a>
                        )
                    )
                  ) : (
                    <a href={`tel:${phoneDefault}`}>
                      <TextBody1>{phoneDefault}</TextBody1>
                    </a>
                  )}
                </div>

                <div className={classes.contactsItem}>
                  <Icon
                    className={classes.contactsItemIcon}
                    type={'marker'}
                    width={14}
                    height={20}
                    withBackground
                    opacity={1}
                    style={iconStyle}
                  />
                  <TitleH2 className={classes.contactsItemTitle} style={{ marginTop: 6 }}>
                    {messages.addressLabel.defaultMessage}
                  </TitleH2>
                  {address ? (
                    <TextBody1>{address}</TextBody1>
                  ) : (
                    <>
                      <TextBody1>м. Київ</TextBody1>
                      <TextBody1 style={{ marginBottom: 10 }}>вул. Старосільська 1</TextBody1>
                      <TextBody1>м. Львів</TextBody1>
                      <TextBody1>вул. Грабовського, 11</TextBody1>
                    </>
                  )}
                </div>

                <div className={classes.contactsItem}>
                  <Icon
                    className={classes.contactsItemIcon}
                    type={'clock'}
                    width={21}
                    height={21}
                    withBackground
                    opacity={1}
                    style={iconStyle}
                  />
                  <TitleH2 className={classes.contactsItemTitle} style={{ marginTop: 6 }}>
                    {messages.workHoursLabel.defaultMessage}
                  </TitleH2>
                  {workHours ? (
                    <TextBody1>{workHours}</TextBody1>
                  ) : (
                    <>
                      <TextBody1>08:00 - 21:00</TextBody1>
                      <TextBody1>без вихідних</TextBody1>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <OfficesMap />
        </Grid>

        <Grid item md={8} lg={4} className={classes.contactForm}>
          <TitleH2 className={classes.formTitle} style={{ fontSize: 40 }}>
            {messages.formTitle.defaultMessage}
          </TitleH2>
          <TextSubTitle color={'inherit'} className={classes.formSubTitle} component={'p'}>
            {messages.formCaption.defaultMessage}
          </TextSubTitle>

          <ContactsForm />
        </Grid>
      </Grid>
    </section>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state: IApplicationState) => ({
  contacts: getContactsData(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch: Dispatch) => ({
  ...bindActionCreators({}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(MainContacts as any));
