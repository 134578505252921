import React from "react";
import Rating from '@mui/material/Rating';

import Icon from "components/shared/Icon";
import { RatingProps } from '@mui/material/Rating';

export interface IFlrRatingProps extends RatingProps {}

const FlrRating: React.FC<IFlrRatingProps> = props => {
  return <Rating {...props} emptyIcon={<Icon type={"star"} size={24} />} icon={<Icon type={"starFull"} size={24} />} />;
};

export default FlrRating;
