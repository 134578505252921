import { Cart } from "models/cart";
import { IRequestCartItem } from "models/cart-item";
import { HttpClient } from "utils/http";

export class ExpiredCartRepository {
  private client = new HttpClient(`${process.env.REACT_APP_API_ENDPOINT}/sales/cart/expired`);

  public async fetch() {
    return await this.client.get<Cart>("").then(cart => cart.data);
  }

  public async restore(cartGroupName: string) {
    return await this.client.post("", {
      groupId: cartGroupName
    });
  }

  public async removeGroup(cartGroupName: string) {
    return await this.client.delete(`?groupId=${cartGroupName}`);
  }

  public async remove() {
    return await this.client.delete("");
  }

  public async updateItem(item: IRequestCartItem) {
    return await this.client.post(`${item.offer}`, { qty: item.qty });
  }

  public async removeItem(item: IRequestCartItem) {
    return await this.client.delete(`${item.offer}`);
  }
}
