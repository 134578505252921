import * as React from 'react';
import styled from '@emotion/styled/macro';
import { ValidatorForm } from 'react-material-ui-form-validator';
import env from 'environment';

import { FlrInput } from 'components/shared/form-elements/textfield';
import { FlrButtonKhaki } from 'components/shared/buttons';
import { IFlrStepButtonActions } from 'components/shared/stepper';
import FlrFileInput from 'components/shared/form-elements/FlrFileInput';
import FlrLoader from 'components/loading/LoadingSpinner';
import { HttpClient } from 'utils/http';
import { IThemed } from 'utils/styled';

import {
  registrationFormStepStorageKey,
  registrationShouldClearLocalstorageKeys,
  registrationTokenStorageKey
} from 'shared/constants';
import messagesControls from 'translations/layout/controls';
import messages from 'translations/auth/registration';
import { useDidMount } from 'utils/hooks';
import { isFunction } from 'lodash';

export interface IFormChangeEvent {
  name: string;
  value: string;
}

export interface IFormFields {
  edrpou: string;
}

export interface IFormFiles {
  edrpou: FileList;
  edrpouProcessed: {};
  fop: FileList;
  fopProcessed: {};
}

const RegistrationFormStep2 = (props: IFlrStepButtonActions) => {
  // if register 2nd step was forced by login effect -
  // turn off forcing after living of page in order to enable registration from scratch
  const didUnmount = () => {
    if (window.localStorage.getItem(registrationShouldClearLocalstorageKeys)) {
      window.localStorage.removeItem(registrationFormStepStorageKey);
      window.localStorage.removeItem(registrationTokenStorageKey);
      window.localStorage.removeItem(registrationShouldClearLocalstorageKeys);
    }
  };
  useDidMount(() => didUnmount);
  const [formData, setFormData] = React.useState({
    edrpou: ''
  } as IFormFields);

  const [edrpou, setEdrpou] = React.useState({});
  const [edrpouProcessed, setEdrpouProcessed] = React.useState({});
  const [fop, setFop] = React.useState({});
  const [fopProcessed, setFopProcessed] = React.useState({});

  const entities = {
    edrpou: {
      setter: setEdrpou,
      setterProcessed: setEdrpouProcessed,
      processed: edrpouProcessed,
      value: edrpou
    },
    fop: {
      setter: setFop,
      setterProcessed: setFopProcessed,
      processed: fopProcessed,
      value: fop
    }
  };

  const [disableSubmit, setDisableSubmit] = React.useState(false);

  const handleFileChange = ({ name, value }: IFormChangeEvent): any => {
    const setter = entities[name].setter;
    const setterProcessed = entities[name].setterProcessed;

    setterProcessed((prevState: any) => ({
      ...prevState,
      ...Object.values(value).reduce(
        (acc, cur) => ({
          ...acc,
          // @ts-ignore
          [cur.id]: false
        }),
        {}
      )
    }));

    setter(
      isFunction(value)
        ? value
        : (prevState: any) => ({
            ...prevState,
            ...Object.values(value).reduce((acc, cur) => {
              return {
                ...acc,
                // @ts-ignore
                [cur.id]: cur
              };
            }, {})
          })
    );

    setTimeout(() => {
      setterProcessed((prevState: any) => ({
        ...prevState,
        ...Object.values(value).reduce(
          // @ts-ignore
          (acc, cur: any) =>
            !acc[cur.id]
              ? acc
              : {
                  ...acc,
                  // @ts-ignore
                  [cur.id]: false
                },
          {}
        )
      }));
    }, Math.random() * 3500);
  };

  const handleFieldChange = (event: React.FormEvent<HTMLInputElement>) => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    if (disableSubmit) {
      return;
    }
    setDisableSubmit(true);

    const regToken = window.localStorage.getItem(registrationTokenStorageKey) || '';
    const headers = { Authorization: regToken };

    await registerRequest();
    await sendFiles();

    setDisableSubmit(false);
    props.next();

    function registerRequest() {
      const data = new FormData();
      Object.keys(formData).forEach(key => {
        data.append(key, formData[key]);
      });
      const httpClient = new HttpClient(`${env.apiUrl}/account/business-data`);
      return httpClient.post('', data, { headers }).then(() => {
        window.localStorage.removeItem(registrationTokenStorageKey);
      });
    }

    function sendFiles() {
      const data = new FormData();
      data.append('edrpou', formData.edrpou);

      ['fop', 'edrpou'].forEach(fileType => {
        (Object.values(entities[fileType].value) || []).forEach(file => {
          // @ts-ignore
          data.append(`scan_${fileType}`, file, file.name);
        });
      });

      const httpClient = new HttpClient(`${env.apiUrl}/account/business-data`);

      return httpClient.post('', data, { headers }).catch(res => {
        if (!res.ok) {
          if (res.parsedErrors) {
            // setErrors({...errors, ...res.parsedErrors});
          }
        }
      });
      // });

      // Object.values(entities[fileType].value).forEach(file => {
      //   // @ts-ignore
      //   data.append("files", file, `scan_${fileType}`);
      //
      //   promisesToPush.push(() =>
      //
      //   );
      // });
    }
    // const data = new FormData();
    // Object.keys(formData).forEach(key => {
    //   data.append(key, formData[key]);
    // });
    // Object.keys(formFiles).forEach(key => {
    //   const value = formFiles[key];
    //   if (typeof value === "boolean") {
    //     return;
    //   }
    //   if (value[0]) {
    //     data.append("files", value[0], `scan_${key}`);
    //   }
    // });
    //
    // const httpClient = new HttpClient(`${env.apiUrl}/account/business-data`);
    // httpClient
    //   .post("", data, { headers })
    //   .then(() => {
    //     window.localStorage.removeItem(registrationTokenStorageKey);
    //     props.next();
    //   })
    //   .catch(res => {
    //     if (!res.ok) {
    //       if (res.parsedErrors) {
    //         // setErrors({...errors, ...res.parsedErrors});
    //       }
    //     }
    //   })
    //   .finally(() => {
    //     setDisableSubmit(false);
    //   });
  };

  return (
    <ValidatorForm noValidate autoComplete="off" onSubmit={handleSubmit}>
      <FormInput
        variant={'outlined'}
        fullWidth
        required
        name="edrpou"
        label={messages.formFieldEDPROU.defaultMessage}
        value={formData.edrpou}
        onChange={handleFieldChange}
        validators={['required']}
        errorMessages={[messagesControls.requiredFiled.defaultMessage]}
      />
      <FormFileInput
        fullWidth
        name="fileEDRPOU"
        multiple
        label={messages.formFieldFileEDPROU.defaultMessage as any}
        value={edrpou}
        // tslint:disable-next-line:jsx-no-lambda
        onChange={(value: string) => handleFileChange({ name: 'edrpou', value })}
        fileProcessed={edrpouProcessed}
      />
      <FormFileInput
        fullWidth
        name="fileFOP"
        label={messages.formFieldFileFOP.defaultMessage}
        value={fop}
        multiple
        // tslint:disable-next-line:jsx-no-lambda
        onChange={(value: string) => handleFileChange({ name: 'fop', value })}
        fileProcessed={fopProcessed}
      />
      <FlrButtonKhaki size={'large'} fullWidth disabled={disableSubmit} style={{ marginTop: 24 }} type={'submit'}>
        {messages.formFinishButton.defaultMessage}
      </FlrButtonKhaki>
      <FormAgreementText>{messages.formFinishAgreement.defaultMessage}</FormAgreementText>
      {disableSubmit && <FlrLoader withOverlay={true} />}
    </ValidatorForm>
  );
};

const FormInput = styled<any>(FlrInput)(() => ({
  '&.MuiFormControl-root': {
    marginTop: '1vh'
  }
}));

const FormFileInput = styled<any>(FlrFileInput)(() => ({
  '& .MuiFormControl-root': {
    marginTop: '1vh !important'
  }
}));

const FormAgreementText = styled<any>('p')(({ theme }: IThemed) => ({
  fontSize: theme.fontSizes.small,
  letterSpacing: theme.letterSpacing,
  color: theme.colors.gray
}));

export default RegistrationFormStep2;
