import { useCallback, useEffect } from 'react';
import { useTimer as useTimerHook } from 'react-timer-hook';
import { ICartTime } from 'store/cart/actions';

interface IArgs {
  type: string;
  end: any;
  shouldRestart: boolean;
  shouldPause?: boolean;
  isCheckoutPage?: boolean;
  onExpire?: () => void;
  onChange?: (end: number) => void;
  setCartTimer?: (time: ICartTime) => void;
}

type IUseTimer = (args: IArgs) => { minutes: any; seconds: any; shouldPause?: boolean; isPaused?: boolean };

const useTimer: IUseTimer = ({ type, end, shouldRestart, shouldPause, onExpire, onChange, isCheckoutPage }) => {
  const {
    seconds: secondsLeft,
    minutes: minutesLeft,
    hours: hoursLeft,
    isRunning,
    restart,
    pause,
    resume
  } = useTimerHook({
    expiryTimestamp: end,
    onExpire
  });

  let minutes = minutesLeft.toFixed(0);
  let seconds = secondsLeft.toFixed(0);

  if (onChange) {
    onChange(end);
  }

  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }
  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }

  useCallback(() => {
    if (hoursLeft === 0 && minutesLeft === 0 && secondsLeft === 0) {
      return onExpire && onExpire();
    }
  }, [hoursLeft, minutesLeft, secondsLeft, onExpire]);

  // shouldRestart timer with new expire date
  useEffect(() => {
    if (type) {
      window.dispatchEvent(new CustomEvent('timersStore', { detail: { [type]: { minutes, seconds } } }));
    }
    if (shouldRestart && !isCheckoutPage) {
      restart(end);
    }
    // eslint-disable-next-line
  }, [end, shouldRestart]);

  useEffect(() => {
    if (type && isRunning) {
      window.dispatchEvent(new CustomEvent('timersStore', { detail: { [type]: { minutes, seconds } } }));
    }
    // eslint-disable-next-line
  }, [minutes, seconds]);

  useEffect(() => {
    if (isRunning && isCheckoutPage) {
      pause();
    } else if (!isRunning && !shouldPause && !isCheckoutPage) {
      resume();
    }
    // eslint-disable-next-line
  }, [isCheckoutPage]);

  return {
    minutes: Number(minutes),
    seconds: Number(seconds),
    shouldPause,
    isPaused: isCheckoutPage
  };
};

export { useTimer };
