import * as React from 'react';
import { withStyles } from '@mui/styles';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, MapStateToProps } from 'react-redux';
import { Redirect } from 'react-router';

import { IApplicationState } from 'store/reducers';
import { getForgotPasswordState, getUserProfileLoaded, getUserProfileLoadingState } from 'store/auth/selectors';
import {
  changeForgotPasswordAsync,
  fetchForgotPasswordStep2Async,
  fetchForgotPasswordAsync,
  IForgotPasswordObject
} from 'store/auth/actions';
import { useTrackInitialPageLoad } from 'controllers';

import AuthIdScreen from './AuthIdScreen';

import styles from '../styles';
import CodeScreen from './CodeScreen';
import ChangePasswordScreen from './ChangePasswordScreen';
import { baseUrl } from 'shared/constants';

export interface IForgotPasswordFormState {
  authId: string;
  disableSubmit: boolean;
  authError: boolean;
}

interface IOwnProps {
  // component own props
  classes: any;
}

interface IStateProps {
  // Props passed to the component by `connect`
  data: IForgotPasswordObject | null;
  loadingState: boolean;
  loadedState: boolean;
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
  fetchForgotPassword: typeof fetchForgotPasswordAsync.request;
  fetchForgotPassword2: typeof fetchForgotPasswordStep2Async.request;
  changeForgotPassword: typeof changeForgotPasswordAsync.request;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const ForgotPasswordPage: React.FC<IProps> = ({
  classes,
  data,
  loadingState,
  changeForgotPassword,
  fetchForgotPassword,
  fetchForgotPassword2
}) => {
  useTrackInitialPageLoad();

  const codeExpiration = data && data._sentData && data._sentData.expiresOn;
  const codeExpirationDate = codeExpiration && new Date(codeExpiration);
  const codeToken = (data && data._sentData && data._sentData.token) || null;
  const isCodeValidated = data && data.isValid;
  const isChanged = data && data.isSuccess;
  const isError = data && data.error;

  if (isChanged) {
    return <Redirect to={{ pathname: `${baseUrl}/login` }} />;
  }

  const isShowCodeForm = codeExpirationDate && +codeExpirationDate > Date.now();

  return (
    <main>
      {!isCodeValidated && !isShowCodeForm && (
        <AuthIdScreen
          classes={classes}
          isError={isError}
          isLoading={loadingState}
          fetchForgotPassword={fetchForgotPassword}
        />
      )}
      {!isCodeValidated && isShowCodeForm && (
        <CodeScreen
          data={data}
          classes={classes}
          isError={isError}
          isLoading={loadingState}
          fetchForgotPassword2={fetchForgotPassword2}
          token={codeToken}
        />
      )}

      {isCodeValidated && (
        <ChangePasswordScreen
          data={data}
          classes={classes}
          isError={isError}
          isLoading={loadingState}
          changeForgotPassword={changeForgotPassword}
          token={codeToken}
        />
      )}
    </main>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  data: getForgotPasswordState(state),
  loadingState: getUserProfileLoadingState(state),
  loadedState: getUserProfileLoaded(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      fetchForgotPassword: fetchForgotPasswordAsync.request,
      fetchForgotPassword2: fetchForgotPasswordStep2Async.request,
      changeForgotPassword: changeForgotPasswordAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(ForgotPasswordPage as any));
