import { FC } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { createCartItemQtySelector } from 'store/cart/selectors';
import messages from 'translations/catalog/mini-details';

import Icon from '../../Icon';

import classes from './CartItemQty.module.scss';

interface ICartItemQtyProps {
  id: string;
  className?: string;
}

const CartItemQty: FC<ICartItemQtyProps> = ({ id, className }) => {
  const cartItemQty = useSelector(createCartItemQtySelector(id));
  return (
    <div className={clsx(classes.wrapper, { [className || '']: !!className })}>
      {cartItemQty > 0 ? (
        <>
          <Icon type="shoppingCartOne" className={classes.icon} size={18} offset={4} />
          <span className={classes.inCartLabel}>{messages.inCart.defaultMessage}</span>
          <span className={classes.inCartCount}>
            {cartItemQty}&nbsp;{messages.quantityUnit.defaultMessage}
          </span>
        </>
      ) : null}
    </div>
  );
};

export default CartItemQty;
