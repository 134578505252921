import Map, { IMapProps } from './Map';
import { Button, Card, useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import styled from '@emotion/styled/macro';
import { IThemed } from 'utils/styled';
import Icon from '../../Icon';

interface IOwnProps {
  setMapOpened: (state: boolean) => void;
}

const MapWithWrapper = ({ setMapOpened, ...props }: IOwnProps & IMapProps) => {
  const theme: Theme = useTheme();
  const screenDevice = useMediaQuery(theme.breakpoints.down('md'));
  const revert = () => setMapOpened(false); /* just close for now */

  return (
    <div
      onClick={(evt: any) => {
        evt.stopPropagation();
      }}
      style={{
        position: 'fixed',
        zIndex: 120000,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        ...(screenDevice
          ? {
              height: 'calc(100vh - 0px)',
              width: 'calc(100vw - 0px)'
            }
          : {
              maxHeight: '800px',
              maxWidth: '800px',
              width: '100%',
              height: '100%'
            })
      }}
    >
      <Map
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        {...props}
      />
      {/* todo move to material styles */}
      <div style={{ position: 'absolute', right: 60, top: 10 }} onClick={revert}>
        <Card
          style={{
            padding: 10,
            borderRadius: 2,
            boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
            width: 40,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
        >
          <Icon type={'cross'} size={15} />
        </Card>
      </div>

      {props.place && (
        <ButtonWrapper>
          <Card>
            <Button
              size={'large'}
              color={'primary'}
              style={{ margin: '0 auto' }}
              onClick={() => {
                setMapOpened(false);
              }}
            >
              Зберегти
            </Button>
          </Card>
        </ButtonWrapper>
      )}
    </div>
  );
};

const ButtonWrapper = styled<any>(Card)(({ theme }: IThemed) => ({
  position: 'absolute',
  bottom: 100,
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  justifyContent: 'center',
  marginLeft: 'auto'
}));

export default MapWithWrapper;
