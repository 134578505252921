import React from "react";
import { Grid } from "@mui/material";

import { withStyles } from "@mui/styles";
import styles from "./styles";
import Icon from "components/shared/Icon";
import { TextBody2, TextSubTitle } from "components/shared/text";
import messages from "translations/main/features";

interface IProps {
  // component own props
  classes: any;
}

interface IFeatureItem {
  icon: string;
  title: string;
  desc: string;
}

const featuresList: IFeatureItem[] = [
  {
    icon: "delivery",
    title: messages.delivery.defaultMessage,
    desc: messages.deliveryDesc.defaultMessage
  },
  {
    icon: "support",
    title: messages.support.defaultMessage,
    desc: messages.supportDesc.defaultMessage
  },
  {
    icon: "bids",
    title: messages.onlineBidding.defaultMessage,
    desc: messages.onlineBiddingDesc.defaultMessage
  },
  {
    icon: "discount",
    title: messages.discounts.defaultMessage,
    desc: messages.discountsDesc.defaultMessage
  },
  {
    icon: "loyalty",
    title: messages.priceOffers.defaultMessage,
    desc: messages.priceOffersDesc.defaultMessage
  }
];

const MainFeatures: React.FC<IProps> = ({ classes }) => {
  return (
    <Grid container className={classes.root} component={"section"}>
      <Grid container item className={classes.featuresContainer} spacing={3}>
        {featuresList.map((item, index) => (
          <Grid key={index} item className={classes.featuresItem}>
            <Icon className={classes.featureIcon} size={40} opacity={1} type={item.icon} withBackground />
            <TextSubTitle className={classes.featuresItemTitle}>{item.title}</TextSubTitle>
            <TextBody2 className={classes.featuresItemDesc}>{item.desc}</TextBody2>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default withStyles<any>(styles)(MainFeatures);
