import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "main.hotDeal.title",
    description: "main.hotDeal.title",
    defaultMessage: "Гаряча пропозиція"
  },
  propositions: {
    id: "main.hotDeal.propositions",
    description: "main.hotDeal.propositions",
    defaultMessage: "Пропозицій"
  },
  buy: {
    id: "main.hotDeal.buy",
    description: "main.hotDeal.buy",
    defaultMessage: "купити"
  },
  totalPropositions: {
    id: "main.hotDeal.totalPropositions",
    description: "main.hotDeal.totalPropositions",
    defaultMessage: "Пропозицій"
  },
  timerDays: {
    id: "main.hotDeal.timerDays",
    description: "main.hotDeal.timerDays",
    defaultMessage: "Днів"
  },
  timerHours: {
    id: "main.hotDeal.timerHours",
    description: "main.hotDeal.timerHours",
    defaultMessage: "Годин"
  },
  timerMinutes: {
    id: "main.hotDeal.timerMinutes",
    description: "main.hotDeal.timerMinutes",
    defaultMessage: "Хвилин"
  },
  timerSeconds: {
    id: "main.hotDeal.timerSeconds",
    description: "main.hotDeal.timerSeconds",
    defaultMessage: "Секунд"
  }
});
