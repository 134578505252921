import React from 'react';
import { find, filter, remove, includes, now } from 'lodash';
import { Collapse } from '@mui/material';

import { IFilterOptions, ProductAttributesDictionary } from 'models';
import { IFilterProp, IFilterProps, IFiltersData } from 'store/catalog/actions';
import FlrCheckboxWithLabel from 'components/shared/form-elements/FlrCheckbox';
import { FilterChangeHandler } from '../index';
import { ExpandPanel } from 'components/shared';
import { Link2 } from 'components/shared/text';
import FilterLabel from './FilterLabel';

import { filtersMessages } from 'translations/catalog/filters';
import { ATTRIBUTE_DIAMETER_PATH } from 'shared/filters';
import { MAX_OPTIONS_SHOWN, sortByCheckedRank, sortNumericByValue } from './options-tools';

interface IProps {
  filters: IFiltersData;
  setFilters: (newFilters: IFilterProps) => void;
  label: string;
  filterOptionsData: IFilterOptions;
  postFilteredFilterOptions: Map<any, any>;
  classes: any;
}

const DiameterOptions: React.FC<IProps> = ({
  classes,
  label: labelSection,
  filterOptionsData,
  postFilteredFilterOptions,
  setFilters,
  filters
}) => {
  const [allList, setAllList] = React.useState(false);
  // map filters on filterOptions
  const attributeOptions = find(filterOptionsData.attributes, { code: ProductAttributesDictionary.diameter });
  const attributeFilterValues: string[] =
    filter(filters.fast, { path: ATTRIBUTE_DIAMETER_PATH } as IFilterProp).map(filter => filter.value) || [];

  if (!attributeOptions) {
    return null;
  }

  const filtersFast = filters.fast || [];

  const handleDiameterFilterChange: FilterChangeHandler<boolean> = (key: any, isChecked: boolean) => {
    const newFilterPropValue: IFilterProp = {
      rule: 'include',
      path: ATTRIBUTE_DIAMETER_PATH,
      value: key
    };
    if (!isChecked) {
      remove(filtersFast, newFilterPropValue);
    } else if (!includes(filtersFast, newFilterPropValue)) {
      newFilterPropValue.time = now();
      filtersFast.push(newFilterPropValue);
    }
    setFilters(filtersFast);
  };

  const arraySelected = filtersFast.filter(item => item.path === ATTRIBUTE_DIAMETER_PATH);
  const arraySelectedLength = (arraySelected && arraySelected.length) || 0;
  const arraySelectedLast = arraySelectedLength ? arraySelected[0] : null;

  const handleResetDiameterFilters = (ev: React.SyntheticEvent | React.MouseEvent) => {
    if (arraySelectedLast) {
      ev.stopPropagation();
      setFilters(filtersFast.filter(item => item.path !== ATTRIBUTE_DIAMETER_PATH));
    }
  };

  const allAttributeOptions = attributeOptions.options.map(o => ({
    value: Number(o),
    disabled: !postFilteredFilterOptions.get('diameter')?.has(o),
    rank: postFilteredFilterOptions.get('diameter')?.get(o),
    label: `${o} см`,
    isChecked: attributeFilterValues.length > 0 && attributeFilterValues.includes(o)
  }));

  const uncollapsedOptionsNodes = allAttributeOptions
    .filter(o => !o.disabled || o.isChecked)
    .sort(sortByCheckedRank)
    .filter((o, idx) => o.isChecked || idx < MAX_OPTIONS_SHOWN)
    .sort(sortNumericByValue)
    .map(({ isChecked, label, disabled, value }) => (
      <FlrCheckboxWithLabel
        checked={isChecked}
        label={label}
        onChange={(event: any, checked: boolean) => handleDiameterFilterChange(value, checked)}
        disabled={disabled && !isChecked}
      />
    ));

  const allAttributeOptionsNodes = allAttributeOptions
    .sort(sortNumericByValue)
    .map(({ isChecked, label, disabled, value }) => (
      <FlrCheckboxWithLabel
        checked={isChecked}
        label={label}
        onChange={(event: any, checked: boolean) => handleDiameterFilterChange(value, checked)}
        disabled={disabled && !isChecked}
      />
    ));

  const toggleAllList = () => setAllList(state => !state);

  const labelComp = (
    <FilterLabel
      classes={classes}
      label={labelSection}
      arraySelectedLast={arraySelectedLast}
      arraySelectedLength={arraySelectedLength}
      handleReset={handleResetDiameterFilters}
    />
  );

  return (
    <ExpandPanel
      defaultExpanded={true}
      label={labelComp}
      className={classes.paddedExpandPanel}
      classNameSummary={classes.expansionSummary}
      classNameButton={classes.expansionButton}
    >
      <>{!allList ? uncollapsedOptionsNodes : null}</>

      <Collapse in={allList}>
        <div className={classes.expandedList}>
          <>{allAttributeOptionsNodes}</>
        </div>
      </Collapse>

      {allAttributeOptionsNodes && !!allAttributeOptionsNodes.length && (
        <Link2 className={classes.collapseAllList} onClick={toggleAllList}>
          {allList ? filtersMessages.collapseIn.defaultMessage : filtersMessages.collapseOut.defaultMessage} (
          {allAttributeOptionsNodes.length - uncollapsedOptionsNodes.length})
        </Link2>
      )}
    </ExpandPanel>
  );
};

export default DiameterOptions;
