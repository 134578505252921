import styled from '@emotion/styled/macro';
import { Box } from '@mui/material';

import { defaultMaterialTheme, IThemed } from 'utils/styled';

export const OrderDetailsWrapper = styled<any>(Box)(({ theme }: IThemed) => ({
  fontSize: 12,
  lineHeight: '18px',
  '& .package-count': {
    border: ' 1px solid #009BDD',
    marginTop: 8,
    display: 'flex',
    justifyContent: 'center',
    padding: '9px 0',
    borderRadius: 8,
    alignItems: 'center',
    '.in-package-label': {
      fontSize: 16,
      fontWeight: 400,
      color: '#333'
    },
    '.in-package-count': {
      fontSize: 16,
      fontWeight: 500,
      color: '#333'
    }
  },
  '@keyframes slideIn': {
    from: {
      marginLeft: '0'
    },
    to: { marginLeft: '-158px' }
  },
  '@keyframes slideOut': {
    from: {
      marginLeft: '-158px'
    },
    to: { marginLeft: '0' }
  },
  '& .maximize-images-img': {
    backgroundColor: theme.palette.text.secondary,
    borderRadius: '50%',
    padding: 3
  },
  '& .more-info-label': {
    fontSize: 14
  },
  '& .color-name': {
    fontSize: 16,
    lineHeight: '22px'
  },
  '& .details-favourite': {
    position: 'absolute',
    zIndex: 2,
    left: 120,

    '& .favourite': {
      cursor: 'pointer',
      paddingLeft: 0,
      color: '#fff',
      backgroundColor: theme.palette.text.secondary,
      height: 32,
      width: 32,
      padding: '3px',
      borderRadius: '50%',
      '&:hover, &.selected': {
        color: theme.palette.error.main,
        padding: 0
      }
    }
  },
  '& .country-container': {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    marginTop: 8,
    padding: '0 4px 8px',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      marginTop: 4,
      padding: '5px 0 6px 4px'
    },
    '& .manufacturer-label': {
      fontSize: 14,
      lineHeight: '14px',
      color: '#666'
    }
  },
  '& .attributes-wrapper': {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    padding: '4px 4px 12px',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginTop: 4,
      padding: '0 4px 5px',
      '& :nth-child(2)': {
        marginBottom: 3
      }
    }
  },
  '& .attributes-container': {
    display: 'block',
    // width: '258px',
    width: '100%',
    maxWidth: '100%',
    flexBasis: '100%',
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      width: '100%'
    }
  },

  '& .summary': {
    gap: 8,
    '&.summaryOpen': {
      '@media (max-width: 720px)': {
        marginLeft: 158
      }
    },
    '& > div': {
      margin: 0,
      justifyContent: 'flex-end',
      alignItems: 'center',
      [`@media (max-width: ${theme.breakpoints.md})`]: {
        alignItems: 'center'
      }
    },
    '& > div:last-of-type': {
      padding: '8px 6px'
    },
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      height: 'fit-content',
      padding: '0'
    }
  },

  '& .color-attr': {
    display: 'flex',
    alignItems: 'center',
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      width: '50%',
      marginBottom: 3,
      height: '24px'
    }
  },

  '& .color-circle': {
    height: '16px',
    width: '16px',
    marginRight: 8,
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      marginRight: 12,
      height: '12px',
      width: '12px'
    }
  },

  '& .in-package-count-label': {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '19px',
    color: '#333'
  },

  '& .more-info-item': {
    marginTop: 4,
    paddingBottom: 4,
    borderBottom: `1px solid ${theme.palette.common.disabledBlackBackground}`,

    '&:last-child': {
      borderBottom: 'none'
    }
  },

  '& .more-label': {
    color: theme.palette.text.secondary
  },

  '& .label': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '14px'
  },

  '& .more-value': {
    textAlign: 'right'
  },

  '& .details-manufacturer': {
    color: theme.palette.text.secondary
  },

  '& .details-title': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    '& h2': {
      margin: '0 0 8px 0',
      color: theme.palette.text.primary,
      height: '58px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '28.8px',
      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        margin: '0 0 5px 0'
      }
    }
  },

  '& .details-color': {
    '& > p': {
      textAlign: 'center',
      color: theme.palette.text.secondary
    }
  },

  '& .content-container': {
    flexWrap: 'nowrap',
    display: 'flex',
    gap: 16
  },
  '& .image-wrapper': {
    maxWidth: 'fit-content',
    cursor: 'pointer'
  },
  '& .image-container': {
    position: 'relative',
    height: 190,
    width: 142,

    '& > img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      borderRadius: 8
    },

    '& > .image-icon': {
      borderRadius: '50%',
      backgroundColor: `${theme.palette.common.disabledBlack} !important`,
      color: `${theme.palette.common.white} !important`,
      position: 'absolute',
      bottom: 8,
      right: 8
    }

    // '& .image-gallery-trigger': {
    //   position: 'absolute',
    //   top: 0,
    //   width: '100%',
    //   height: '100%'
    // }
  },

  '& .attribute-container': {
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: '50%',
      maxWidth: '50%',
      height: '24px',
      marginRight: 0
    }
  },
  '& .detailsContainer': {
    display: 'flex',
    alignItems: 'center',
    '.MuiCollapse-root': {
      '@media (max-width: 720px)': {
        animation: 'slideIn 0.3s forwards'
      }
    },
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      paddingBottom: 4
    },
    '&.detailsOpen': {
      '.MuiCollapse-root': {
        '@media (max-width: 720px)': {
          marginTop: 8,
          animation: 'slideOut 0.3s forwards'
        }
      },
      '@media (max-width: 720px)': {
        marginLeft: '-158px'
      }
    }
  },
  '& .in-package-count': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    paddingLeft: 4,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      paddingLeft: 2
    }
  },
  '& .count-images': {
    position: 'absolute',
    zIndex: 1,
    top: 178,
    left: 40,
    cursor: 'pointer',
    '@media(max-width: 1367px)': {
      left: 32,
      top: 228
    }
  },
  '& .count-images-label': {
    color: '#fff',
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: theme.palette.text.secondary,
    borderRadius: 8,
    padding: '2px 4px'
  },
  '& .maximize-images': {
    position: 'absolute',
    zIndex: 1,
    top: 177,
    left: 133,
    cursor: 'pointer',
    '@media (max-width: 1367px)': {
      top: 228,
      left: 125
    }
  },
  '& .attributes-title': {
    display: 'inline-block',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '22px',

    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      marginRight: 6
    }
  },
  '& .attributes-value': {
    color: theme.palette.common.black
  },

  '& .details-link': {
    marginTop: 20
  },

  '& .expand-panel-details': {
    padding: 0
  },

  '& .details-container': {
    flexDirection: 'column',
    padding: 24,
    color: theme.colors.black,
    borderRadius: 2,
    backgroundColor: '#fafafa',

    '& .MuiGrid-item': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 12,

      '&::after': {
        position: 'absolute',
        bottom: 6,
        width: '100%',
        borderBottom: '1px dotted rgba(85, 85, 85, 0.5)',
        content: "''"
      }
    }
  },
  '& .details-label': {
    position: 'relative',
    paddingRight: 2,
    fontSize: 14,
    lineHeight: '21px',
    letterSpacing: '0.03em',
    backgroundColor: '#fafafa',
    zIndex: 2
  },
  '& .details-value': {
    position: 'relative',
    paddingLeft: 2,
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 500,
    backgroundColor: '#fafafa',
    zIndex: 2,

    '&__discount': {
      color: theme.colors.brandPrimaryDark,
      fontWeight: 400
    }
  },

  [defaultMaterialTheme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export const OrderInCartDetailsWrapper = styled<any>('div')(({ theme }: IThemed) => ({
  '& .cart-overall': {
    width: '100%',
    display: 'flex',
    marginTop: 32,
    background: theme.palette.text.primary,
    borderRadius: 4,

    '& > .cart-icon': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 4,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      color: theme.palette.common.black,
      backgroundColor: theme.palette.secondary.main
    },
    '& > .cart-data': {
      flex: 1,
      padding: '8px 16px',
      display: 'flex',
      justifyContent: 'space-between',
      '& > .MuiTypography-root': {
        color: theme.palette.common.white
      }
    }
  }
}));
