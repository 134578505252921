import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    id: "login.header",
    description: "Login page header",
    defaultMessage: "Вхід"
  },
  headerCaption: {
    id: "login.header",
    description: "login.header",
    defaultMessage: "Щоб купувати квіти гуртом за найкращими цінами, введіть ваш логін та пароль"
  },
  alreadyRegistered: {
    id: "login.alreadyRegistered",
    description: "Subheader for new customer",
    defaultMessage: "Ви новий користувач?"
  },
  register: {
    id: "login.register",
    description: "Registration link label",
    defaultMessage: "Реєстрація"
  },
  formFieldLogin: {
    id: "login.form.login",
    description: "Login form field login",
    defaultMessage: "Електронна адреса або номер телефону"
    // defaultMessage: "Логін"
  },
  formFieldPassword: {
    id: "login.form.password",
    description: "Login form field password",
    defaultMessage: "Пароль"
  },
  formFieldRememberMe: {
    id: "login.form.rememberMe",
    description: "Login form field remember me",
    defaultMessage: "Запам'ятати мене"
  },
  forgotPassword: {
    id: "login.forgotPassword",
    description: "Registration link label",
    defaultMessage: "Нагадати пароль"
  },
  alternativeLogin: {
    id: "login.alternativeLogin",
    description: "title for social login options",
    defaultMessage: "Вхід через соцмережі"
  },
  formLoginButton: {
    id: "login.form.loginButton",
    description: "Login form button label",
    defaultMessage: "Увійти"
  },
  formBackButton: {
    id: "login.form.formBackButton",
    description: "formBackButton",
    defaultMessage: "Назад"
  },
  password_incorrectError: {
    id: "login.form.password_incorrectError",
    description: "",
    defaultMessage: "Невірні дані для входу"
  },
  authIdError: {
    id: "login.form.authIdError",
    description: "",
    defaultMessage: "Невірні дані для входу"
  },
  pendingError: {
    id: "login.form.pendingError",
    description: "login.form.pendingError",
    defaultMessage: `Ваш аккаунт ще не верифіковано.<br/>Як тільки статус вашого аккаунту змінется - з Вами обв' язково зв'яжется наш менеджер та Вам надійде смс повідомлення.`
  },
  authErrorDetails: {
    id: "login.form.pendingError",
    description: "login.form.pendingError",
    defaultMessage: `Якщо виникли запитання, можете зв'язатись з нами за номером: `
  }
});
