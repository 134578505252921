import * as React from 'react';
import clsx from 'clsx';
import { TextField, IconButton } from '@mui/material';
import { styled, withTheme } from '@mui/styles';
import { IThemed } from 'utils/styled';
import Icon from 'components/shared/Icon';

const FlrInputPassword: React.FC<any> = props => {
  const { helperText, ...other } = props;

  const [showPassword, setShowPassword] = React.useState(false);
  const handleToggle = () => setShowPassword(state => !state);

  return (
    <ComponentContainer>
      <TextField helperText={helperText || ' '} {...other} type={showPassword ? 'text' : 'password'} />
      <TogglePassword
        className={clsx({
          'show-password-icon--active': showPassword
        })}
        tabIndex={-1}
        onClick={handleToggle}
      >
        <Icon type={showPassword ? 'eyeOff' : 'eye'} size={24} opacity={1} />
      </TogglePassword>
    </ComponentContainer>
  );
};

const ComponentContainer = styled<any>('div')(({ theme }: IThemed) => ({
  position: 'relative',

  '& .show-password-icon--active': {
    color: theme.palette.primary.main
  }
}));

const TogglePassword = styled<any>(IconButton)(() => ({
  '&&': {
    position: 'absolute',
    top: 1,
    right: 12
  }
}));

export default withTheme(FlrInputPassword);
