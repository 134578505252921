import * as React from 'react';
import { ProductCardContainer } from './styles';
import Skeleton from '@mui/material/Skeleton';
import FlrCard from '../../shared/card/FlrCard';

interface IProps {
  className?: string;
  style?: object;
}

export const ProductCardSkeleton: React.FC<IProps> = ({ className, style = {} }) => {
  return (
    <ProductCardContainer style={style}>
      <FlrCard hoverShadow zeroPadding className={className}>
        <div className="grid-container">
          <div className="photo-container">
            <div className="photo">
              <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
            </div>
          </div>
          <div className="manufacturer-container">
            <div className="flag">
              <Skeleton variant="rectangular" width={24} height={16} />
              &nbsp;
            </div>
            <div className="manufacturer-label">
              <Skeleton variant="rectangular" width={100} height={16} />
            </div>
          </div>
          <div className="main-container info">
            <Skeleton variant="rectangular" width={'100%'} height={14} style={{ marginBottom: 2 }} />
            <div className="name">
              <Skeleton variant="rectangular" width={'100%'} height={16} />
              <Skeleton variant="rectangular" width={'60%'} height={16} />
            </div>
            <Skeleton variant="rectangular" width={'100%'} height={14} />
          </div>
          <div className="attributes-container">
            <div className="params params--active">
              {Array(5)
                .fill(1)
                .map((v, index) => (
                  <div className={`param ${index === 0 && 'mobile'}`} key={index}>
                    <Skeleton variant="rectangular" width={40} height={14} style={{ marginBottom: 2 }} />
                    <Skeleton variant="rectangular" width={30} height={18} />
                  </div>
                ))}
            </div>
          </div>
          <div className="card-footer">
            <Skeleton variant="rectangular" width={'25%'} />
            <Skeleton variant="rectangular" width={'50%'} />
          </div>
        </div>
      </FlrCard>
    </ProductCardContainer>
  );
};
