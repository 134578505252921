import { Reducer } from 'redux';
import { ILayoutState, IPriceType, LAYOUT_ACTION_TYPES } from './types';

const defaultPriceType = (window.localStorage.getItem('priceType') as IPriceType) || 'unit';

// Type-safe initialState!
export const layoutInitialState: ILayoutState = {
  theme: 'light',
  notifications: [],
  priceType: defaultPriceType
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain productType-safe.
const reducer: Reducer<ILayoutState> = (state = layoutInitialState, action) => {
  switch (action.type) {
    case LAYOUT_ACTION_TYPES.SET_THEME: {
      return { ...state, theme: action.payload };
    }
    case LAYOUT_ACTION_TYPES.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification
          }
        ]
      };

    case LAYOUT_ACTION_TYPES.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      };

    case LAYOUT_ACTION_TYPES.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== action.key)
      };
    case LAYOUT_ACTION_TYPES.SET_PRICE_TYPE:
      return { ...state, priceType: action.payload };
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as layoutReducer };
