import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { ListRowProps } from 'react-virtualized';
import { getCatalogLayout } from 'store/catalog/selectors';
import { Skeleton, RowSkeleton } from 'components/cards/product';

interface IProps {
  withPrice: boolean;
  productCardHeight: number;
  productCardsMargin: number;
}

const Skeletons: React.FC<IProps> = ({ withPrice, productCardHeight, productCardsMargin }) => {
  const layout = useSelector(getCatalogLayout);
  const isTableLayout: boolean = window.localStorage.getItem('layoutType') === 'table';

  const itemHeight = productCardHeight + (isTableLayout ? 0 : productCardsMargin * 1.5);

  const rowRendererSkeletons = ({ key, style }: Pick<ListRowProps, 'key' | 'style'>) => {
    const skeletons = [];
    for (let i = 0; i < layout.columns; i++) {
      if (isTableLayout) {
        skeletons.push(<RowSkeleton key={i} />);
      } else {
        skeletons.push(<Skeleton key={i} className={'catalog-card'} />);
      }
    }
    return (
      <div key={key} style={style}>
        {skeletons}
      </div>
    );
  };

  return (
    <div
      style={{
        position: 'absolute',
        width: `calc(100% - ${isTableLayout ? 12 : 16}px)`,
        height: '100%',
        overflow: 'hidden',
        zIndex: 999
      }}
      className={clsx('ReactVirtualized__Grid', { withPrice, 'table-layout-wrapper': isTableLayout })}
    >
      <div className={'ReactVirtualized__Grid__innerScrollContainer'} style={{ overflow: 'hidden' }}>
        {Array(Math.ceil(document.body.offsetHeight / itemHeight))
          .fill(1)
          .map((item, i) =>
            rowRendererSkeletons({
              key: String(i),
              style: { height: itemHeight }
            })
          )}
      </div>
    </div>
  );
};

export default Skeletons;
