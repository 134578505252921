import { lighten, transparentize } from 'polished';
import styled from '@emotion/styled/macro';
import { Grid } from '@mui/material';

import Icon from 'components/shared/Icon';
import { productCardHeightMobileLogged } from 'shared/constants';
import { textOverflow, textOverflow2Lines } from 'styles/mixins';
import { IThemed } from 'utils/styled';

const zIndexSelected = 20;
const zIndexFavourite = 5;

export const Placeholder = styled<any>('div')(({ theme }: IThemed) => ({
  height: '100%',
  backgroundColor: transparentize(0, theme.colors.background),
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.cardBorderRadius,
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.16)',

  '&:hover': {
    boxShadow: '2px 4px 20px rgba(0, 0, 0, 0.16)'
  },
  '&.selected': {
    position: 'relative',
    zIndex: zIndexSelected,
    top: -8,
    left: -8,
    width: 'calc(100% + 16px)',
    height: 'calc(100% + 16px)',
    paddingBottom: 1,
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: '0px 0px 18px rgba(142, 63, 190, 0.4)',
    background: 'linear-gradient(57.47deg, rgb(142, 63, 190) 0%, rgba(142, 63, 190, 0) 100%)',

    '& .card-footer': {
      borderTop: `2px solid ${theme.palette.primary.main}`
    },

    '& .card-body': {
      position: 'relative',

      '& .info': {
        position: 'static',
        '& .data': {
          position: 'absolute',

          '& .origin': {
            display: 'none'
          },

          '& .main-type': {
            marginTop: 55
          },

          '& .name': {
            marginBottom: 16,

            [`@media (min-width: 1705px) and (max-width: 1960px)`]: {
              maxWidth: 202
            }
          },

          '& .name, .main-type, .color': {
            maxWidth: 240,
            color: theme.palette.common.white
          }
        },
        '& .photo': {
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          '& .discount': {
            display: 'none',
            top: 4,
            left: 0
          }
        }
      }
    }
  },

  '& .card-body': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderTopLeftRadius: theme.cardBorderRadius,
    borderTopRightRadius: theme.cardBorderRadius,

    '&:hover .info .favourite': {
      color: theme.palette.text.secondary
    },

    '& .info': {
      margin: 16,
      display: 'flex',
      alignItems: 'flex-start',
      position: 'relative',

      '& .photo': {
        flex: '0 0 80px',
        height: 88,
        background: `${theme.colors.backgroundSecondary} center bottom no-repea`,
        backgroundSize: 'cover',
        borderRadius: 4
      },

      '& .data': {
        margin: '0 0 0 8px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
        '& .origin, .color': {
          fontSize: theme.fontSizes.tiny,
          color: lighten(0.15, theme.colors.gray)
        },
        '& .origin': {
          display: 'flex',
          alignItems: 'center',
          letterSpacing: '0.02em',
          paddingRight: 40,
          marginBottom: 8,

          '& .flag': {
            flex: '0 0 auto',
            display: 'flex',
            width: 12,
            height: 12,
            marginRight: 8
          },
          '& .manufacturer': {
            ...textOverflow
          }
        },
        '& .main-type': {
          lineHeight: '1.3',
          paddingRight: 40,
          marginTop: 0,
          marginBottom: 0,
          ...textOverflow
        },
        '& .name': {
          marginBottom: 8,
          height: 40,
          overflow: 'hidden',
          fontSize: `${parseInt(theme.fontSizes.large, 10) + 2}px`,
          fontWeight: 500,
          color: theme.colors.black
        }
      },
      '& .favourite': {
        position: 'absolute',
        top: -4,
        right: 0,
        color: 'transparent',
        zIndex: zIndexFavourite,
        cursor: 'pointer',
        transition: 'none',

        '&.selected': {
          color: theme.palette.error.main
        },

        '&.product-selected': {
          right: 8,
          top: 8,
          borderRadius: '50%',
          backgroundColor: `${theme.palette.common.disabledBlack} !important`,
          color: theme.palette.common.white,

          '&.selected': {
            color: theme.palette.error.main
          }
        }
      }
    },
    '& .params': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridColumnGap: theme.cardContainerPadding,
      margin: `0 ${theme.cardContainerPadding}`,
      paddingTop: 6,
      paddingBottom: 6,
      height: 48,
      maxHeight: 48,
      borderBottom: `1px solid ${theme.palette.common.disabledBlack}`,

      '&--active': {
        borderTop: `1px solid ${theme.palette.common.disabledBlackBackground}`
      },

      '& .param': {
        borderRight: `1px solid ${theme.colors.borderGray}`,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',

        '& .param-label': {
          color: theme.palette.text.secondary
        },
        '&:last-of-type': {
          paddingRight: 0,
          borderRight: 'none'
        }
      }
    }
  },
  '& .card-footer': {
    padding: theme.cardContainerPadding,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
    background: theme.palette.common.white,
    '& .height': {
      minWidth: '30%',
      fontSize: theme.fontSizes.large,
      color: theme.colors.black,
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      [`> ${Icon}`]: {
        color: theme.palette.text.primary,
        marginRight: 8
      }
    },
    '& .no-price-message': {
      fontSize: theme.fontSizes.small,
      color: theme.palette.common.helperBlack,
      textAlign: 'right',
      marginLeft: 10
    },
    '& .price': {
      color: theme.palette.common.black
    }
  }
}));

export const ProductCardContainer = styled<any>('figure')(({ theme }: IThemed) => ({
  '& .catalog-card': {
    height: '100%',
    position: 'relative',
    '& .grid-container': {
      gridTemplateColumns: '88px 1fr 1fr 1fr',
      gridTemplateRows: 'auto 88px 86px 40px 40px',
      gridTemplateAreas: `"manufacturer-container manufacturer-container manufacturer-container manufacturer-container"
        "photo-container photo-container photo-container photo-container"
        "main-container main-container main-container main-container"
        "attributes-container attributes-container attributes-container attributes-container"
        "card-footer card-footer card-footer card-footer"`,
      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        gridTemplateRows: '32px 127px 44px 28px 40px'
      }
    },
    '& .main-container': {
      paddingTop: 0,
      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        '& > :first-child': {
          display: 'none'
        }
      },
      '& .main-type': {
        lineHeight: 1.3,
        paddingRight: 40,
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 400,
        color: theme.palette.text.secondary
      },
      '& .name': {
        height: 40,
        marginBottom: 8,
        overflow: 'hidden',
        fontSize: `${parseInt(theme.fontSizes.large, 10) + 2}px`,
        fontWeight: 500,
        color: theme.colors.black
      }
    },
    '& .attributes-container': {
      '& .params': {
        display: 'flex',
        overflow: 'hidden'
      }
    },
    '& .favourite': {
      position: 'absolute',
      top: 36,
      right: 4,
      color: 'white',
      borderRadius: '50%',
      zIndex: zIndexFavourite,
      cursor: 'pointer',
      transition: 'none',

      '&.selected': {
        color: theme.palette.error.main
      },

      '&.product-selected': {
        color: theme.palette.common.white,

        '&.selected': {
          color: theme.palette.error.main
        }
      }
    },

    '& .special-offer-icon': {
      position: 'absolute',
      top: 68,
      right: 7,
      borderRadius: '50%',
      zIndex: zIndexFavourite,
      background: '#FFFFFF',

      [`@media (min-width: ${theme.breakpoints.sm})`]: {
        display: 'none'
      }
    },

    '& .card-footer': {
      paddingTop: 10,
      background: 'transparent',
      borderTop: '1px solid rgba(0, 0, 0, 0.2)',
      boxSizing: 'border-box',
      height: productCardHeightMobileLogged,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gridArea: 'card-footer',
      margin: '0 8px',
      '&.noPrice': {
        display: 'none'
      },
      '& .height': {
        display: 'none'
      },
      '& .preOrder': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        color: 'rgba(0, 0, 0, 0.44)',
        '& > .label': {
          color: 'inherit'
        }
      },
      '& .price': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',

        '& > div:not(:first-child)': {
          marginLeft: 10
        },

        '& > *:not([role=tooltip])': {
          textAlign: 'right',
          '&:last-of-type': {
            opacity: 1
          }
        },

        [`@media (max-width: ${theme.breakpoints.sm})`]: {
          justifyContent: 'flex-end'
        }
      },
      '& .card-footer-price': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      '& .card-footer-double-price-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& > *:not(:last-child)': {
          marginRight: 5
        }
      },
      '& .card-footer-double-price': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      },

      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        justifyContent: 'initial',
        paddingTop: 0,

        '& .price': {
          width: '100%',

          '&.justifyBetween': {
            justifyContent: 'space-between'
          }
        },

        '& .card-footer-price': {
          width: '100%',
          justifyContent: 'flex-end'
        }
      }
    },
    '& .special-signs': {
      border: `1px solid ${theme.colors.pink}`,
      borderRadius: 6,
      marginBottom: 8,
      display: 'inline-block',

      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        display: 'none'
      },

      '& .offer-sign': {
        display: 'inline-block',
        fontSize: 12,
        color: theme.colors.pink,
        padding: '4px 5px'
      }
    }
  },

  '& .manufacturer-container': {
    marginBottom: 0,
    gridArea: 'manufacturer-container',
    fontSize: 12,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 8,
    '.flag-icon': {
      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        width: ' 14px!important',
        marginTop: 2,
        marginLeft: 2
      }
    },
    '& .manufacturer-flag': {
      marginRight: 10
    },
    '& .flag': {
      display: 'flex'
    },
    '& .manufacturer-label': {
      color: theme.palette.text.secondary,
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 400,
      ...textOverflow
    }
  },

  '& .photo-container': {
    gridArea: 'photo-container'
  },

  '& .discount': {
    padding: '4px 6px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.black,
    borderRadius: 14,
    marginRight: 10,
    h4: {
      fontSize: 14
    },
    p: {
      fontSize: 12,
      fontWeight: 700
    }
  },

  '& .main-container': {
    gridArea: 'main-container',
    maxWidth: '100%',
    padding: 8,
    '.container-color': {
      display: 'flex',
      alignItems: 'center',
      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        display: 'none'
      },
      '.main-color': {
        fontSize: 12
      }
    },
    '& .main-type': {
      marginTop: 0,
      marginBottom: 4,
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.text.secondary,
      ...textOverflow
    },
    '& .main-title': {
      marginTop: 0,
      marginBottom: 4,
      textAlign: 'left',
      display: 'block',
      fontSize: 16,
      fontWeight: 500,
      minHeight: '38px',
      ...textOverflow2Lines
    },
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      '& .home-page-main-title': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'block'
      }
    },
    '& .main-color': {
      ...textOverflow
    }
  },
  '& .attributes-container': {
    gridArea: 'attributes-container',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    alignItems: 'center',
    borderTop: '1px solid #ebebeb',
    maxHeight: 54,
    margin: '0 16px',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      '&.attributes-container': {
        display: 'flex'
      }
    },

    '.mobile-attributes': {
      borderTop: '1px solid rgba(0, 0, 0, 0.2)',
      margin: '0 8px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      width: '100%',

      '.other-attributes-container': {
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'flex',
        alignItems: 'center',
        gap: 8,

        '.other-attributes-item': {
          display: 'flex',
          alignItems: 'center',
          width: 'auto',
          '.other-attributes': {
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '12px',
            fontWeight: '400'
          }
        }
      }
    },

    '& .params': {
      display: 'grid',

      '&.param-columns-1': {
        gridTemplateColumns: '2fr'
      },
      '&.param-columns-2': {
        gridTemplateColumns: '2fr 2fr'
      },
      '&.param-columns-3': {
        gridTemplateColumns: '2fr 2fr 2fr'
      },
      '&.param-columns-4': {
        gridTemplateColumns: '2fr 2fr 2fr 2fr'
      },
      gridColumnGap: theme.cardContainerPadding,
      margin: '0 8px',
      paddingTop: 4,
      borderTop: '1px solid rgba(0, 0, 0, 0.08)',
      overflow: 'scroll',
      '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
      scrollbarWidth: 'none' /* Firefox */,
      '&::-webkit-scrollbar': {
        display: 'none' /* Safari and Chrome */
      },

      '& .param': {
        display: 'none',
        borderRight: `1px solid ${theme.colors.borderGray}`,
        flex: 1,
        flexDirection: 'column',
        paddingRight: 8,
        '&.mobile': {
          order: -1,
          display: 'block',
          fontSize: 12
        },
        '&:nth-child(1)': {
          display: 'block',
          [`@media (max-width: ${theme.breakpoints.sm})`]: {
            '& > :first-child': {
              display: 'none'
            }
          }
        },
        '&:nth-child(2)': {
          borderRight: 'none',
          display: 'block',
          [`@media (max-width: ${theme.breakpoints.sm})`]: {
            '& > :first-child': {
              display: 'none'
            }
          }
        },
        '&:last-of-type': {
          paddingRight: 0,
          borderRight: 'none'
        },
        '& .param-label': {
          color: theme.palette.text.secondary,
          lineHeight: 1.2,
          fontSize: 12
        },
        '& .param-value': {
          color: theme.palette.text.primary,
          lineHeight: 1,
          fontSize: 14
        },
        '& >*': {
          font: `normal ${theme.fontSizes.medium}/18px ${theme.fonts.body}`,
          whiteSpace: 'nowrap',
          letterSpacing: theme.letterSpacing,
          margin: 0
        }
      }
    }
  },
  '& .photo': {
    position: 'relative',
    width: '100%',
    height: '100%',
    background: `${theme.palette.common.cardBlackBackgroundHex} center center no-repeat`,
    backgroundSize: 'cover'
  },
  '& .card-footer': {
    gridArea: 'card-footer',
    margin: '0 8px',
    paddingTop: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '.height': {
      display: 'none'
    },
    '& .price': {
      display: 'flex',
      gap: 8,
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      '.discount': {
        padding: '2px 7px'
      },
      '& .card-footer-double-price-container': {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        lineHeight: '22px',
        '.card-footer-double-price': {
          display: 'flex',
          flexDirection: 'column',
          '.minPrice, .maxPrice': {
            '>span': {
              fontSize: 16,
              fontWeight: 700
            },
            '>span:last-of-type': {
              fontSize: 12,
              fontWeight: 700
            }
          }
        }
      },
      '& .card-footer-price': {
        '&.pre-order': {
          display: 'flex',
          alignItems: 'center',
          gap: 4
        },
        opacity: 0.5,
        '& .pre-order-text': {
          fontSize: 14,
          lineHeight: 1,
          fontWeight: 400
        },
        '& .pre-order-price': {
          fontSize: 16,
          fontWeight: 700
        }
      },
      '& >*': {
        letterSpacing: '0.01em',
        marginRight: 0,
        fontSize: '14px',
        fontWeight: 500,
        height: 'fit-content'
      }
    }
  },
  '& .home-page-cards': {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      '& .home-page-grid-container': {
        gridTemplateRows: '32px 127px 42px 26px 40px',
        gridTemplateColumns: '88px 1fr 1fr 1fr',
        gridTemplateAreas: `"manufacturer-container manufacturer-container manufacturer-container manufacturer-container"
        "photo-container photo-container photo-container photo-container"
        "main-container main-container main-container main-container"
        "attributes-container attributes-container attributes-container attributes-container"
        "card-footer card-footer card-footer card-footer"`,
        position: 'relative',

        '.favourite': {
          color: 'white',
          position: 'absolute',
          top: 40,
          right: 2,
          cursor: 'pointer',
          transition: 'none',
          '&.selected': {
            color: '#e91e63'
          },
          '&.product-selected': {
            color: '#ffffff',
            '&.selected': {
              color: '#e91e63'
            }
          }
        }
      }
    }
  },
  [`@media (min-width: ${theme.breakpoints.sm})`]: {
    '& .catalog-card': {
      '& .grid-container': {
        padding: 16,
        gridTemplateRows: '16px 124px 44px 32px',
        gridTemplateColumns: '88px auto 1fr 1fr',
        gridTemplateAreas: `"manufacturer-container manufacturer-container manufacturer-container manufacturer-container"
          "photo-container main-container main-container main-container"
          "attributes-container attributes-container attributes-container attributes-container"
          "card-footer card-footer card-footer card-footer"`
      },
      '& .photo-container': {
        paddingRight: 8,
        paddingBottom: 8
      },
      '& .main-container, .manufacturer-container, .attributes-container': {
        paddingLeft: 0,
        paddingRight: 0
      },
      '& .manufacturer-container': {
        paddingRight: 40,
        marginBottom: 8
      },
      '& .favourite': {
        top: 12,
        color: 'transparent',
        background: 'transparent'
      },
      '& .card-footer': {
        margin: 0,
        height: 'auto',
        justifyContent: 'space-between',
        '&.noPrice': {
          display: 'flex'
        },
        '& .height': {
          display: 'flex'
        },
        '& .price': {
          '& >*': {
            margin: 0
          }
        }
      },
      '&.selected': {
        position: 'relative',
        zIndex: zIndexSelected,
        top: -8,
        left: -8,
        width: 'calc(100% + 16px)',
        height: 'calc(100% + 16px)',
        paddingBottom: 1,
        border: `2px solid ${theme.palette.primary.main}`,
        boxShadow: '0px 0px 18px rgba(142, 63, 190, 0.4)',
        background: 'linear-gradient(57.47deg, rgb(142, 63, 190) 0%, rgba(142, 63, 190, 0) 100%)',

        '& .grid-container': {
          gridTemplateRows: '16px auto 44px 44px',
          gridTemplateAreas: `"photo-container photo-container photo-container photo-container"
            "photo-container photo-container photo-container photo-container"
            "photo-container photo-container photo-container photo-container"
            "card-footer card-footer card-footer card-footer"`,
          gridGap: 0,
          padding: 0,
          height: '100%'
        },

        '& .photo-container': {
          position: 'relative',
          '& .photo': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }
        },

        '& .manufacturer-container, .attributes-container': {
          display: 'none'
        },

        '& .main-container': {
          position: 'absolute',
          padding: theme.cardContainerPadding,
          '& .main-type': {
            marginTop: 55
          },

          '& .name': {
            marginBottom: 16,

            [`@media (min-width: 1705px) and (max-width: 1960px)`]: {
              maxWidth: 202
            }
          },

          '& .name, .main-type, .color': {
            maxWidth: 240,
            color: theme.palette.common.white
          }
        },

        '& .card-footer': {
          padding: theme.cardContainerPadding,
          background: theme.palette.common.white,
          borderTop: `2px solid ${theme.palette.primary.main}`,
          borderRadius: '0 0 6px 6px'
        }
      }
    },

    '& .params': {
      margin: 0,
      marginRight: 16
    },

    '& .grid-container': {
      display: 'grid',
      gridTemplateColumns: '128px 1fr 1fr 1fr',
      gridTemplateRows: '39px 68px 36px 64px auto',
      gap: '1px 1px',
      gridTemplateAreas: `"photo-container manufacturer-container manufacturer-container manufacturer-container"
        "photo-container main-container main-container main-container"
        "photo-container packing packing packing"
        "photo-container attributes-container attributes-container attributes-container"
        "photo-container card-footer card-footer card-footer"`,
      '&.unauthorized': {
        gridTemplateRows: '39px 68px 36px 70px',
        gridTemplateAreas: `"photo-container manufacturer-container manufacturer-container manufacturer-container"
        "photo-container main-container main-container main-container"
        "photo-container packing packing packing"
        "photo-container attributes-container attributes-container attributes-container"
        `
      }
    },

    '& .photo-container': {
      gridArea: 'photo-container',
      '& .photo': {
        width: '100%',
        height: '100%',
        borderRadius: 8,
        background: `${theme.colors.backgroundSecondary} center center no-repeat`,
        backgroundSize: 'cover'
      }
    },

    '& .manufacturer-container': {
      gridArea: 'manufacturer-container',
      '& .manufacturer-flag': {
        marginRight: 10
      },

      '& .main-container': {
        gridArea: 'main-container'
      }
    },

    '& .main-container, .manufacturer-container': {
      padding: '0 16px',
      '.favourite': {
        marginLeft: 'auto',
        '&.selected': {
          color: '#e91e63'
        },
        '&.product-selected': {
          color: '#ffffff',
          '&.selected': {
            color: '#e91e63'
          }
        }
      }
    },

    '& .attributes-container': {
      gridArea: 'attributes-container',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      '& .params': {
        '& .param': {
          display: 'block',
          '&:nth-child(2):not(:last-of-type)': {
            borderRight: `1px solid ${theme.colors.borderGray}`
          },
          '&.mobile': {
            order: 999,
            display: 'none'
          }
        }
      }
    },

    '& .card-footer': {
      gridArea: 'card-footer',
      margin: '0 16px',
      borderTop: `1px solid ${theme.colors.borderGray}`,
      padding: '8px 0 16px',
      display: 'flex',
      justifyContent: 'space-between',
      flexSirection: 'row',
      alignItems: 'center',
      '& .height': {
        minWidth: '30%',
        fontSize: theme.fontSizes.small,
        color: theme.palette.text.primary,
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        [`> ${Icon}`]: {
          color: theme.palette.text.primary,
          marginRight: 8
        },
        '& > div': {
          fontSize: '14px',
          fontWeight: 400
        }
      },
      '& .no-price-message': {
        fontSize: theme.fontSizes.small,
        textAlign: 'right',
        marginLeft: 10
      },
      '& .price': {
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        minHeight: 23,
        '.discount': {
          padding: '4px 6px',
          p: {
            fontWeight: 500,
            lineHeight: '14px',
            fontSize: 14,
            color: '#000'
          }
        },
        '& .card-footer-double-price-container': {
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          '.card-footer-double-price': {
            display: 'flex',
            flexDirection: 'column'
          }
        },
        '& .card-footer-price': {
          '& .pre-order-price': {
            fontSize: 16,
            fontWeight: 700
          }
        },
        '& >*': {
          letterSpacing: '0.01em',
          marginRight: 0,
          fontSize: '14px',
          fontWeight: 500,
          height: 'fit-content'
        }
      }
    },
    '& .min-price-label': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '14px',
      color: '#000',
      marginRight: 4
    },
    '& .packing-container': {
      padding: '0 16px',
      gridTemplateAreas: 'packing',
      '.packing-wrapper': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '14px'
      }
    },
    '& .attr-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '58%',
      marginLeft: 3,
      marginTop: 0,
      maxHeight: 24,
      '@media(max-width: 1720px)': {
        width: '100%'
      }
    },
    '& .attr-content': {
      display: 'flex',
      alignItems: 'center',
      width: '50%',
      maxHeight: 24
    },
    '& .color': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '14px',
      color: '#333'
    },
    '& .other-attributes-wrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '58%',
      maxHeight: 24,
      '@media(max-width: 1720px)': {
        width: '100%'
      }
    },
    '& .color-circle': {
      marginRight: 12
    }
  }
}));

export const InlineBlock = styled<any>('div')(() => ({
  display: 'inline-block'
}));

export const FooterWrapper = styled<any>(Grid)(({ theme }: IThemed) => ({
  fontSize: theme.fontSizes.large
}));

export const BorderedBlocksWrapper = styled<any>(Grid)(({ theme }: IThemed) => ({
  '& > div': {
    borderRight: `1px solid ${theme.colors.gray}`,

    '&:last-child': {
      borderRight: 'none'
    }
  }
}));
