import { IDeliveryLocation } from "./order";
import { Outlet } from "./outlet";

export class Warehouse implements IDeliveryLocation {
  public alias: string;
  public name: string;
  public code: string;
  public id: string;
  public address: string;
  public supplier: string;
  public infiniteStock: boolean;
  public displayName: string;
  public selfPickUp: boolean;
  public outlets: Outlet[];
  public selfPickUpTimeSlots: any[];

  constructor(warehouse: any) {
    this.id = warehouse.id;
    this.alias = warehouse.alias;
    this.code = warehouse.code;
    this.name = warehouse.name;
    this.selfPickUp = warehouse.selfPickUp;
    this.displayName = warehouse.displayName || warehouse.name;
    this.infiniteStock = warehouse.infiniteStock;
    this.address = warehouse.address || "";
    this.supplier = warehouse.supplier;
    this.outlets = warehouse.outlets;
    this.selfPickUpTimeSlots = warehouse.selfPickUpTimeSlots;
  }
}
