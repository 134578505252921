import React from 'react';
import clsx from 'clsx';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { TextBody1, TitleH1 } from 'components/shared/text';
import IconCartTimer from 'components/timer/IconCartTimer';
import styles from '../styles';

interface IProps {
  classes: any;
  title: MessageDescriptor;
  itemsNumber?: number;
  expired?: boolean;
  preOrder?: boolean;
  mobile?: boolean;
  className?: string;
  timerType?: 'inStock' | 'transit' | undefined;
}

const CartBlockHeader: React.FC<IProps> = ({
  mobile,
  classes,
  title,
  itemsNumber,
  expired,
  preOrder,
  className,
  timerType
}) => {
  const Wrapper = mobile ? TextBody1 : TitleH1;
  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent={'space-between'}
      alignContent={'center'}
      style={{ marginBottom: mobile ? 0 : 16 }}
      className={clsx(classes.cartBlockHeader, className)}
    >
      <Grid item xs>
        <Wrapper className={classes.noVerticalMargin}>
          <FormattedMessage {...title} values={{ items: itemsNumber }} />
        </Wrapper>
      </Grid>
      {!mobile && <IconCartTimer type={timerType} expired={expired} preOrder={preOrder} />}
    </Grid>
  );
};

export default withStyles<any>(styles)(CartBlockHeader);
