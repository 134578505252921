import { ChangeEvent, FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { ExpandPanel } from 'components/shared';
import FlrSelect2 from 'components/shared/form-elements/select2/FlrSelect2';
import { IRegionData, ISupplierData } from 'models';
import { catalogSetFilter, IFiltersData } from 'store/catalog/actions';
import { filtersMessages } from 'translations/catalog/filters';

import FilterLabel from './FilterLabel';

interface IProps {
  regions: IRegionData[];
  suppliers: ISupplierData[];
  selectedSupplier?: string;
  selectedDeliveryRegion?: string;
  changeSupplier: (event: any, checked?: boolean) => void;
  changeRegion: (region: string) => void;
  isLoading: boolean;
  isDisabled: boolean;
  label: string;
  classes: any;
  filters: IFiltersData;
  showDeliveryModal?: boolean;
}

export const DeliveryFilters: FC<IProps> = ({
  label: labelSection,
  classes,
  isLoading,
  isDisabled,
  changeSupplier,
  changeRegion,
  suppliers,
  selectedSupplier,
  selectedDeliveryRegion,
  regions,
  filters
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      selectedDeliveryRegion &&
      selectedRegion?.suppliers[0] &&
      !selectedRegion?.suppliers.includes(selectedSupplier || '')
    ) {
      changeSupplier({
        target: { value: selectedRegion?.suppliers[0] || '', data: { id: selectedRegion?.suppliers[0] } }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeliveryRegion]);

  const selectedRegion = regions.find((r) => r.alias === selectedDeliveryRegion);

  const regionOptions = regions.map((region) => ({
    label: region.name,
    value: region.alias
  }));
  const supplierOptions = suppliers
    .filter((s) => selectedRegion?.suppliers.includes(s.value))
    .map((s) => ({
      label: s.label,
      value: s.value
    }));

  const handleRegionChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target?.value) {
      processChangeRegion(event.target?.value || '');
    }
  };

  const processChangeRegion = (region: string) => {
    changeRegion(region);
    dispatch(
      catalogSetFilter({
        ...filters,
        deliveryRegion: region
      })
    );
  };

  return (
    <ExpandPanel
      defaultExpanded={true}
      label={<FilterLabel classes={classes} label={labelSection} />}
      className={clsx(classes.deliveryPanel)}
      classNameSummary={classes.expansionSummary}
      classNameButton={classes.deliveryExpansionButton}
    >
      <div className={classes.TopToolbar}>
        <FlrSelect2
          isLoading={isLoading}
          isDisabled={isDisabled}
          classes={{ label: classes.selectLabel }}
          label={filtersMessages.deliveryFrom.defaultMessage}
          value={selectedDeliveryRegion || ''}
          onChange={handleRegionChange}
          options={regionOptions}
        />
      </div>
      <div>
        <FlrSelect2
          isLoading={isLoading}
          label={filtersMessages.supplier.defaultMessage}
          value={selectedSupplier || ''}
          onChange={changeSupplier}
          options={supplierOptions}
          isDisabled={isDisabled}
        />
      </div>
    </ExpandPanel>
  );
};
