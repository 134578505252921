import { IApplicationState } from "store/reducers";

export const getOrdersLoadingState = (state: IApplicationState) => state.orders.loading;
export const getOrdersLoaded = (state: IApplicationState) => state.orders.loaded;
export const getOrdersData = (state: IApplicationState) => state.orders.data;
export const getOrdersList = (state: IApplicationState) => getOrdersData(state).docs;
export const getOrdersTotalDocs = (state: IApplicationState) => getOrdersData(state).totalDocs;
export const getOrdersFilters = (state: IApplicationState) => getOrdersData(state).filters;
export const getSelectedOrder = (state: IApplicationState) => getOrdersData(state).selectedOrder;
export const getOrderReaction = (state: IApplicationState) => getOrdersData(state).orderReaction;
