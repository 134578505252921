import * as React from "react";
import { RouteProps, RouteComponentProps, Redirect, Route } from "react-router";
import { baseUrl } from "shared/constants";
// TODO rewrite to proper auth check
const checkAuth = window.localStorage.getItem("token");

export const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  if (!component) {
    throw Error("component is undefined");
  }

  const Component = component; // JSX Elements have to be uppercase.
  const render = (props: RouteComponentProps<any>): React.ReactNode => {
    if (checkAuth) {
      return <Component {...props} />;
    }
    return <Redirect to={{ pathname: `${baseUrl}/login` }} />;
  };

  return <Route {...rest} render={render} />;
};

export const GuestRoute = ({ component, ...rest }: RouteProps) => {
  if (!component) {
    throw Error("component is undefined");
  }

  const Component = component; // JSX Elements have to be uppercase.
  const render = (props: RouteComponentProps<any>): React.ReactNode => {
    if (checkAuth) {
      return <Redirect to={{ pathname: `${baseUrl}/` }} />;
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={render} />;
};
