import React from 'react';
import styled from '@emotion/styled/macro';
import { defineMessages } from 'react-intl';

import { IThemed } from '../../utils/styled';
import Icon from './Icon';
import classes from './WorkingHours.module.scss';

export const whMessages = defineMessages({
  hours: {
    id: 'header.workingHours.hours',
    description: 'working hours',
    defaultMessage: 'з 8:00 по 18:00'
  },
  weekends: {
    id: 'header.workingHours.weekends',
    description: 'weekends',
    defaultMessage: 'без вихідних'
  }
});

interface IProps {
  from?: string;
  to?: string;
  labelColor?: string;
  workHours?: string;
}

// TODO: "from & to" props should be passed into "hours" message definition as parameters
export const WorkingHours: React.FC<IProps> = ({
  workHours,
  from = '8:00',
  to = '18:00',
  labelColor = 'brandSecondary'
}) => (
  <>
    <Schedule>
      {workHours ? (
        workHours
      ) : (
        <div className={classes.container}>
          <Icon type="clock" opacity={1} size={18} />
          <div className={classes.wrapper}>
            <p className={classes.hours}>{whMessages.hours.defaultMessage}</p>
            <p className={classes.weekends}>{whMessages.weekends.defaultMessage}</p>
          </div>
        </div>
      )}
    </Schedule>
  </>
);

const Schedule = styled<any>('p')(({ theme }: IThemed) => ({
  fontSize: theme.fontSizes.default,
  lineHeight: '21px',
  margin: 0
}));
