import React from "react";
import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Link2 } from "components/shared/text";
import { Icon } from "components/shared";

import styles from "./styles";
import messages from "translations/cart/common";

interface IProps {
  isExpanded?: boolean;
  itemsNumber?: number;
  onRemoveItems?: () => void;
  classes: any;
}

const CartGroupExpiredCardLabel: React.FC<IProps> = ({ isExpanded, itemsNumber = 0, classes, onRemoveItems }) => {
  return (
    <Grid container alignItems={"center"} justifyContent={"space-between"} alignContent={"center"}>
      <Grid item sm>
        <Link2>{isExpanded ? messages.hideItems.defaultMessage : messages.showAllItems.defaultMessage}</Link2>
      </Grid>
      {onRemoveItems && (
        <Grid item container spacing={1} sm alignItems={"center"} alignContent={"center"} justifyContent={"flex-end"}>
          <Grid item style={{ display: "flex" }}>
            <Icon type={"trash"} size={16} />
          </Grid>
          <Grid item>
            <Link2 onClick={onRemoveItems}>Прибрати неоформлені товари з Кошика ({itemsNumber}) </Link2>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles<any>(styles)(CartGroupExpiredCardLabel);
