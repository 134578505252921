import React from "react";
import { Hidden, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import styles from "./styles";
import clsx from "clsx";

interface IProps {
  classes?: any;
  action?: React.ReactNode;
  info?: React.ReactNode;
  fullWidth?: boolean;
  classNameWrapper?: string;
}

const AsideDeviceBottomPanel: React.FC<IProps> = props => {
  const { classes, info, action, fullWidth, classNameWrapper } = props;
  return (
    <Hidden lgUp>
      <div className={clsx(classes.root, "devicePanel", "bottom", classNameWrapper, {[classes.fullWidth || '']: fullWidth,})}>
        <Grid container alignItems={"center"}>
          {info && (
            <Grid item xs={"auto"} className={classes.alignLeft}>
              {info}
            </Grid>
          )}
          {action && (
            <Grid item xs className={classes.alignRight}>
              {action}
            </Grid>
          )}
        </Grid>
      </div>
    </Hidden>
  );
};

export default withStyles<any>(styles)(AsideDeviceBottomPanel);
