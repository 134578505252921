import { homeMaxWidth } from 'shared/constants';

// import ImageHead from "../assets/mock/head.webp";
// import ImageHeadMobile from "../assets/mock/head-mobile.webp";
// import ImageHeadTablet from "../assets/mock/head-tablet.webp";
// import ImageHeadMobile from "../assets/mock/home_slider_test.webp";

const styles = (theme: any) => ({
  wrapper: {
    marginBottom: theme.spacing(8),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(15)
    },

    '& .slick-dots': {
      bottom: 25
    },

    '& .slick-dots li.slick-active button:before': {
      color: theme.palette.primary.main,
      fontSize: 16,
      opacity: 1
    },
    '& .slick-dots li button:before': {
      top: 8,
      fontSize: 8,
      opacity: 1,
      color: theme.palette.common.helperBlack
    }
  },
  root: {
    position: 'relative',
    display: 'inline-flex !important',
    // TODO comment for test on mobile devices
    // paddingTop: theme.spacing(5),
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(10),

    // backgroundImage: `url(${ImageHeadMobile})`,
    // backgroundPosition: "center right",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",

    minHeight: 470,

    // eslint-disable-next-line
    ['@media (min-width:1480px)']: {
      minHeight: 420
    },

    [theme.breakpoints.up('md')]: {
      // paddingTop: theme.spacing(9),
      // paddingRight: theme.spacing(20),
      minHeight: 650
      // backgroundImage: `url(${ImageHeadTablet})`
    },
    [theme.breakpoints.up('lg')]: {
      // paddingTop: theme.spacing(0),
      // paddingLeft: theme.spacing(0),
      // paddingRight: theme.spacing(0),
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column'
      // backgroundPosition: "center",
      // backgroundImage: `url(${ImageHead})`
    }
  },
  container: {
    maxWidth: homeMaxWidth,
    margin: '0 auto',
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(10),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(9),
      paddingRight: theme.spacing(20)
      // backgroundImage: `url(${ImageHeadTablet})`
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
      // backgroundPosition: "center",
      // backgroundImage: `url(${ImageHead})`
    }
  },
  imageContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    zIndex: -1
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  brandName: {
    color: theme.palette.common.black,
    height: 26.5,
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      height: 64,
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(5),
      height: 78
    }
  },
  slideContainer: {
    '&:focus': {
      outline: 'none'
    }
  },
  content: {
    alignItems: 'center',

    [theme.breakpoints.up('lg')]: {
      maxWidth: '50%'
    }
  },
  title: {
    marginTop: 0,
    marginBottom: theme.spacing(5),

    [theme.breakpoints.up('md')]: {
      fontSize: 48
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 64
    }
  },

  subTitle: {
    marginTop: 0,
    marginBottom: theme.spacing(5),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7),
      fontSize: 20
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(5),
      fontSize: 24
    }
  },
  catalogButton: {
    '&:focus': {
      textDecoration: 'none'
    },

    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  }
});

export default styles;
