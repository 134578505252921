import React from 'react';
import { withStyles } from '@mui/styles';
import { TextField, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { User } from 'models/user';
import FlrLoader from 'components/loading/LoadingSpinner';
import { FlrButtonKhaki, FlrButtonOutlinedBrown } from 'components/shared/buttons';

import messagesControls from 'translations/layout/controls';
import messages from 'translations/account/settings';
import messagesReg from 'translations/auth/registration';
import styles from './styles';
import { HttpClient } from '../../../utils/http';
import env from '../../../environment';
import { IFormChangeEvent } from '../../auth/registration/step2';
import styled from '@emotion/styled/macro';
import FlrFileInput from 'components/shared/form-elements/FlrFileInput';
import { isFunction } from 'lodash';

interface IProps {
  // component own props
  account: User;
  classes: any;
  onChange: (account: User) => void;
  onSave: (account: User) => void;
  onCancel: () => void;
}

interface IStateProps {
  // Props passed to the component by `connect`
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
  // updateProfile: typeof addOutletAsync.request;
}

interface IFormData {
  fullName: string;
  businessName: string;
  EDRPOU: string;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

const PersonalDataForm: React.FC<IComponentProps> = ({ account, classes, onSave, onCancel }) => {
  const { handleSubmit, errors, control } = useForm<IFormData>();
  const [disableSubmit, setDisableSubmit] = React.useState(false);

  const [edrpou, setEdrpou] = React.useState({});
  const [edrpouProcessed, setEdrpouProcessed] = React.useState({});
  const [fop, setFop] = React.useState({});
  const [fopProcessed, setFopProcessed] = React.useState({});

  const entities = {
    edrpou: {
      setter: setEdrpou,
      setterProcessed: setEdrpouProcessed,
      processed: edrpouProcessed,
      value: edrpou
    },
    fop: {
      setter: setFop,
      setterProcessed: setFopProcessed,
      processed: fopProcessed,
      value: fop
    }
  };

  const handleFileChange = ({ name, value }: IFormChangeEvent): any => {
    const setter = entities[name].setter;
    const setterProcessed = entities[name].setterProcessed;

    setterProcessed((prevState: any) => ({
      ...prevState,
      ...Object.values(value).reduce(
        (acc, cur) => ({
          ...acc,
          // @ts-ignore
          [cur.id]: false
        }),
        {}
      )
    }));

    setter(
      isFunction(value)
        ? value
        : (prevState: any) => ({
            ...prevState,
            ...Object.values(value).reduce((acc, cur) => {
              return {
                ...acc,
                // @ts-ignore
                [cur.id]: cur
              };
            }, {})
          })
    );

    setTimeout(() => {
      setterProcessed((prevState: any) => ({
        ...prevState,
        ...Object.values(value).reduce(
          (acc, cur) => ({
            ...acc,
            // @ts-ignore
            [cur.id]: false
          }),
          {}
        )
      }));
    }, Math.random() * 3500);
  };

  const handleFilesSubmit = async () => {
    if (disableSubmit) {
      return;
    }
    setDisableSubmit(true);

    const data = new FormData();
    data.append('edrpou', account.profile.EDRPOU);

    ['fop', 'edrpou'].forEach(fileType => {
      (Object.values(entities[fileType].value) || []).forEach(file => {
        // @ts-ignore
        data.append(`scan_${fileType}`, file, file.name);
      });
    });

    await new HttpClient(`${env.apiUrl}/account/business-data`).post('', data).catch(res => {
      if (!res.ok) {
        if (res.parsedErrors) {
          // setErrors({...errors, ...res.parsedErrors});
        }
      }
    });

    setDisableSubmit(false);
  };

  // ----- files uploading end
  const onSubmit = handleSubmit(
    async (fields): Promise<any> => {
      if (disableSubmit) {
        return;
      }

      setDisableSubmit(true);

      const accountToSave = { ...account };
      const accountProfile = { ...account.profile };
      accountProfile.EDRPOU = fields.EDRPOU;
      accountProfile.businessName = fields.businessName;
      accountToSave.profile = accountProfile;

      await handleFilesSubmit();
      onSave(accountToSave);
    }
  );

  return (
    <Grid item sm={12}>
      <form onSubmit={onSubmit}>
        <Grid item xs={12}>
          <Controller
            defaultValue={account.profile.businessName}
            error={Boolean(errors.businessName)}
            helperText={(errors.businessName && errors.businessName.message) || ' '}
            rules={{
              required: messagesControls.requiredFiled.defaultMessage
            }}
            required
            as={TextField}
            name="businessName"
            control={control}
            className={classes.commonControl}
            fullWidth
            variant={'outlined'}
            label={messages.companyName.defaultMessage}
          />

          <Controller
            defaultValue={account.profile.EDRPOU}
            error={Boolean(errors.EDRPOU)}
            helperText={(errors.EDRPOU && errors.EDRPOU.message) || ' '}
            rules={{
              required: messagesControls.requiredFiled.defaultMessage
            }}
            required
            as={TextField}
            name="EDRPOU"
            control={control}
            className={classes.commonControl}
            fullWidth
            variant={'outlined'}
            label={messagesReg.formFieldEDPROU.defaultMessage}
          />
          <FilesInputWrapper>
            <FormFileInput
              fullWidth
              name="fileEDRPOU"
              label={messagesReg.formFieldFileEDPROU.defaultMessage as any}
              value={edrpou}
              // tslint:disable-next-line:jsx-no-lambda
              onChange={(value: string) => handleFileChange({ name: 'edrpou', value })}
              setProcessed={setEdrpouProcessed}
              fileProcessed={edrpouProcessed}
              multiple={true}
            />
            <FormFileInput
              fullWidth
              setProcessed={setFopProcessed}
              name="fileFOP"
              label={messagesReg.formFieldFileFOP.defaultMessage}
              value={fop}
              // tslint:disable-next-line:jsx-no-lambda
              onChange={(value: string) => handleFileChange({ name: 'fop', value })}
              fileProcessed={fopProcessed}
              multiple={true}
            />
          </FilesInputWrapper>
        </Grid>
        <Grid item container justifyContent={'flex-end'} spacing={2}>
          <Grid item>
            <FlrButtonOutlinedBrown onClick={onCancel}>{messages.cancelBtnLabel.defaultMessage}</FlrButtonOutlinedBrown>
          </Grid>
          <Grid item>
            <FlrButtonKhaki disabled={disableSubmit} type={'submit'}>
              {messages.saveBtnLabel.defaultMessage}
            </FlrButtonKhaki>
          </Grid>
        </Grid>
        {disableSubmit && <FlrLoader withOverlay={true} />}
      </form>
    </Grid>
  );
};

const FormFileInput = styled<any>(FlrFileInput)(() => ({
  '& .MuiFormControl-root': {
    marginTop: '1vh !important'
  }
}));

const FilesInputWrapper = styled<any>('div')(() => ({
  marginBottom: 35,
  marginTop: -30
}));

export default withStyles<any>(styles)(PersonalDataForm);
