import * as React from "react";
import GoogleMapReact from "google-map-react";
import Icon from "components/shared/Icon";
import mapColorOptions from "styles/map/default";
import { withStyles } from "@mui/styles";
import styles from "./styles";
import { Grid } from "@mui/material";

export interface ICoordinates {
  lat: number;
  lng: number;
}

export interface IFooterMapProps {
  center?: ICoordinates;
  zoom?: number;
  classes?: any;
}

const OfficesMap: React.FC<IFooterMapProps> = ({ center, zoom, classes }) => {
  const defaultProps = {
    center: {
      lat: 50.474261,
      lng: 30.590020
    },
    zoom: 14
  };

  const iconCommonProps = {
    className: classes.mapIcon,
    type: "markerGoogle",
    width: 21,
    height: 38,
    opacity: 1
  };

  return (
    <Grid item sm={12}>
      <div>
        <div className={classes.mapContainer}>
          <div style={{ width: "100%", gridArea: "map" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyAniQiNgrAbCIDsBqcBSY0Iqyw0-qNBWRM" }}
              defaultCenter={center || defaultProps.center}
              defaultZoom={zoom || defaultProps.zoom}
              options={{ styles: mapColorOptions }}
            >
              <Icon {...iconCommonProps} {...(center || defaultProps.center)} />
              <Icon {...iconCommonProps} lat={49.831836} lng={24.023147} />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default withStyles<any>(styles)(OfficesMap);
