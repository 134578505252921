import { defineMessages } from "react-intl";

export default defineMessages({
  or: {
    id: "common.or",
    description: "common.or",
    defaultMessage: "або"
  },
  start: {
    id: "common.start",
    description: "common.start",
    defaultMessage: "Старт"
  },

  gdprPopupText: {
    id: "gdprPopup.text",
    description: "GDPR popup main text",
    defaultMessage: `Ми використовуємо файли cookie для кращої роботи сайту.
Продовжуючи використовувати сайт, ви даєте свою згоду на роботу з цими файлами.`
  },
  gdprPopupButton: {
    id: "gdprPopup.button",
    description: "GDPR button label",
    defaultMessage: "Добре"
  },

  emailNotValid: {
    id: "common.emailNotValid",
    description: "common.emailNotValid",
    defaultMessage: "Невірний формат електронної пошти"
  },
  passwordMismatch: {
    id: "common.passwordMismatch",
    description: "common.passwordMismatch",
    defaultMessage: "Паролі не співпадають"
  },
  passwordValidationAz: {
    id: "common.passwordValidationAz",
    description: "common.passwordValidationAz",
    defaultMessage: "Містить як малі (a–z), так і великі літери (A–Z)"
  },
  passwordValidationNumber: {
    id: "common.passwordValidationNumber",
    description: "common.passwordValidationNumber",
    defaultMessage: "Містить щонайменш одну цифру (0–9) або символ"
  },
  passwordValidationLength: {
    id: "common.passwordValidationLength",
    description: "common.passwordValidationLength",
    defaultMessage: "Містить щонайменш 8 симв."
  }
});
