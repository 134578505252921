import { FC } from 'react';

import { SplitedPrice } from 'components/prices';

import { OfferPrices } from '../../../models';
import messages from '../../../translations/catalog/mini-details';
import { Icon } from '../../shared';

import classes from './SpecialOffer.module.scss';

interface ISpecialOfferProps {
  price: OfferPrices;
}

const SpecialOffer: FC<ISpecialOfferProps> = ({ price }) => {
  return (
    <div className={classes.container}>
      <div>
        <span className={classes.label}>{messages.quantityUnits.defaultMessage}</span>
        {Object.keys(price).map((item, index) => (
          <div className={classes.quantityLabelContainer} key={index}>
            <Icon type="moreThan" size={14}/>
            <span className={classes.value}>{item}</span>
          </div>
        ))}
      </div>
      <div>
        <span className={classes.label}>{messages.pricePerQuantity.defaultMessage}</span>
        {Object.values(price).map((item, index) => (
          <div key={index} className={classes.priceLabelContainer}>
            <SplitedPrice className={classes.splicedPrice} value={Number(item)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecialOffer;
