import messages from '../translations/main/top';
import { baseUrl, defaultCatalogProductType } from '../shared/constants';
import ImageHead from '../pages/main/assets/mock/head.webp';
import ImageHead2 from '../pages/main/assets/mock/home_slider_test.webp';
import { ISlide } from '../models/mainTopSlide';
import ImagePromo from '../pages/main/assets/promo.webp';

export const mainTopSliders: ISlide[] = [
  {
    _id: 0,
    description: [messages.subTitle1.defaultMessage, messages.subTitle2.defaultMessage],
    href: `${baseUrl}/catalog/${defaultCatalogProductType}`,
    image: ImageHead,
    color: '#333',
    mobileImage: ImageHead,
    order: 1,
    header: 'header1'
  },
  {
    _id: 1,
    header: 'Поступление тюльпанов',
    description: 'Поступление тюльпанов. \nСпешите купить.',
    href: `${baseUrl}/catalog/${defaultCatalogProductType}`,
    image: ImageHead2,
    color: '#888',
    mobileImage: ImageHead2,
    order: 2
  }
];

export const mainPromoMock = {
  title: 'Переваги гуртової закупівлі у Флоротека',
  img: ImagePromo,
  text:
    '<h3>Закупівлі без посредників</h3>\n' +
    '<p>Ви не переплачуєте, та маєте змогу обирати найсвіжіші квіти безпосередньо у виробника, якому довіряєте. Ми в свою чергу гарантуємо якість, зручність оформлення доставок та їх швидкість.</p>\n' +
    '<p>Ми цінуємо Ваш час.</p>\n' +
    '<h3>Квіти кращих плантацій</h3>\n' +
    '<p>Cпівпрацюємо лише з надійними постачальниками.</p>\n' +
    '<p>За 3 роки роботи сформували коло з 120 перевірених партнерів. А одже ви зможете знайти квіти, які зможуть задовільнити для будь-які потреби Ваших клієнтів!</p>\n' +
    '<h3>Швидка доставка до ваших складів\n</h3>' +
    '<p>Ми використовуємо спеціалізований транспорт та можемо доставити ваше замовлення на різні склади по всій Україні.</p>'
};
