import { FC } from 'react';
import { Element as ScrollBlock } from 'react-scroll';
import clsx from 'clsx';
import moment from 'moment';

import { SplitedPrice } from 'components/prices';
import Alert from 'components/shared/Alert/Alert';
import FlrCard from 'components/shared/card/FlrCard';
import { Link1, TextBody1, TextBody2, TextCaption, TitleH2 } from 'components/shared/text';
import { Order, Outlet } from 'models';
import { baseUrl, defaultLocale } from 'shared/constants';
import messages from 'translations/checkout/common';
import orderStatusMessages from 'translations/checkout/delivery-status';

import classes from './OrderCardFinalized.module.scss';

interface IProps {
  order: Order;
  outlets: Outlet[];
  orderIndex: number;
  anchor: string;
}

const OrderCardFinalized: FC<IProps> = ({ order, orderIndex, outlets, anchor }) => {
  const date = moment(order.deliveryDate || new Date());
  const deliveryDate = `${date.locale(defaultLocale).format('ddd')} ${date.format('L')}`;
  return (
    <ScrollBlock
      name={`${anchor}-${order.id}`}
      className={clsx(`${anchor}-item`, classes.orderCard, classes.orderCardFinalized)}
    >
      <FlrCard id={order.id} className={classes.card}>
        <Alert type='success' title={messages.alertPayedOrder.defaultMessage} className={classes.outlineMargin}>
          {messages.alertPayedOrderText.defaultMessage}
        </Alert>
        <div className={classes.container}>
          <div className={classes.deliveryWrapper}>
            <TextCaption className={classes.deliveryOrder}>
              {messages.delivery.defaultMessage} &ndash; {orderIndex}
            </TextCaption>
            <TitleH2 className={classes.outletName}>{order.outlet && order.outlet.name}&nbsp;</TitleH2>
          </div>
          <div className={classes.tableHeaders}>
            <TextBody2 className={classes.orderHeader}>Замовлення</TextBody2>
            <TextBody2 className={classes.statusHeader}>Статус</TextBody2>
            <TextBody2 className={classes.dateHeader}>Дата доставки</TextBody2>
            <TextBody2 className={classes.deliveryMethodHeader}>Спосіб доставки</TextBody2>
            <TextBody2 className={classes.suplierHeader}>Постачальник</TextBody2>
            <TextBody2 className={classes.qtyHeader}>Кількість</TextBody2>
            <TextBody2 className={classes.sumHeader}>Сума</TextBody2>
          </div>
          <div className={classes.tableHeaders}>
            <div className={classes.orderCode}>
              <Link1 to={`${baseUrl}/account/orders/${order.id}`}>№ {order.orderId}</Link1>
              <TextBody2 color={'textSecondary'}>від {moment(order.createdAt).format('L')}</TextBody2>
            </div>
            <TextBody1 className={classes.orderStatus}>
              {orderStatusMessages[order.orderStatus] && orderStatusMessages[order.orderStatus].defaultMessage}
            </TextBody1>
            <div className={classes.deliveryDateContainer}>
              <TextBody1>{deliveryDate}</TextBody1>
              <TextBody2 color={'textSecondary'}>
                {order.orderDetails.deliveryTime.name ? order.orderDetails.deliveryTime.name : ''}
              </TextBody2>
            </div>
            <div className={classes.deliveryContainer}>
              <TextBody1 className={classes.noMarginBottom}>{order.orderDetails.deliveryMethod.name}</TextBody1>
              <TextBody2 color={'textSecondary'} className={classes.noMarginBottom}>
                {order.outlet && order.outlet.address}
              </TextBody2>
            </div>
            <div className={classes.warehouseContainer}>
              <TextBody1>{order.warehouse.displayName}</TextBody1>
              <TextBody2 color={'textSecondary'}>{order.warehouse.address}</TextBody2>
            </div>
            <div className={classes.qtyContainer}>
              <TextBody1 align={'right'}>
                <SplitedPrice
                  value={order.totalQty}
                  bold={false}
                  hideDecimal={true}
                  postfix={'шт.'}
                  fontSize={'body1'}
                />
              </TextBody1>
              <TextBody2 align={'right'} color={'textSecondary'}>
                <SplitedPrice
                  value={order.totalPackings}
                  bold={false}
                  hideDecimal={true}
                  postfix={'уп.'}
                  fontSize={'body2'}
                />
              </TextBody2>
            </div>
            <SplitedPrice value={order.totalSum} fontSize={'subtitle1'} className={classes.sumContainer} />
          </div>
        </div>
      </FlrCard>
    </ScrollBlock>
  );
};

export default OrderCardFinalized;
