import React from 'react';
import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { TitleH2 } from 'components/shared/text';
import { GridDividerBold } from 'components/shared/table/dividers';
import messages from 'translations/account/order';

export const AccountOrderSkeleton: React.FC<any> = ({ columns, classes }) => {
  const cols = +(columns || 5);

  return (
    <Grid item sm>
      <Skeleton variant="rectangular" width={162} height={24} style={{ marginBottom: 16 }} />

      <Grid item container sm={12} spacing={2}>
        {new Array(cols).fill('a').map((val, colKey) => (
          <Grid sm item key={colKey}>
            <Skeleton variant="rectangular" width={'80%'} height={76} style={{ marginTop: 4 }} />
          </Grid>
        ))}
      </Grid>

      <TitleH2 className={classes.orderLabelProduct}>{messages && messages.orderProduct.defaultMessage}</TitleH2>
      <GridDividerBold className={classes.orderLabelProductDivider} />
      <Skeleton variant="rectangular" width={'100%'} height={322} style={{ marginBottom: 16 }} />
    </Grid>
  );
};
