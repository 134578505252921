import React from 'react';
import clsx from 'clsx';
import { IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import { TitleH1 } from 'components/shared/text';
import Icon from 'components/shared/Icon';
import { GridDivider } from 'components/shared/table/dividers';

import { ISettingsItem } from '../top/HeaderTopSettings';

export interface IProps {
  title: string;
  classes?: any;
  items?: ISettingsItem[];
  closeSubDrawer: () => void;
}

const MobileMenuSubDrawer: React.FC<IProps> = ({ title, closeSubDrawer, items, classes }) => {
  return (
    <section className={classes.subDrawerContent}>
      <TitleH1 className={classes.subDrawerTitle}>{title}</TitleH1>
      <IconButton className={classes.subDrawerButton} onClick={closeSubDrawer} size="large">
        <Icon type={'cancel'} size={12} />
      </IconButton>

      <GridDivider />

      <List>
        {items &&
          items.map(({ label, value, isDisabled }) => (
            <ListItem
              key={value}
              onClick={isDisabled ? () => ({}) : closeSubDrawer}
              className={clsx(classes.subDrawerListItem)}
              style={{
                opacity: isDisabled ? 0.5 : 1
              }}
            >
              <ListItemAvatar
                className={clsx(classes.drawerCheckMark, {
                  [classes.drawerCheckMarkHidden]: isDisabled
                })}
              >
                <Icon type={'checkMark'} width={14} heigth={10} offset={8} opacity={isDisabled ? 0 : 1} />
              </ListItemAvatar>

              <ListItemText primary={label} />
            </ListItem>
          ))}
      </List>
    </section>
  );
};

export default MobileMenuSubDrawer;
