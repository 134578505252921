import React from 'react';
import styled from '@emotion/styled/macro';
import { Grid } from '@mui/material';
import { TextSubTitle, TextBody2 } from 'components/shared/text';
import Icon from 'components/shared/Icon';
import { IThemed } from '../../../../utils/styled';

type WarehouseType = 'partner' | 'warehouse';

export interface IWarehouse {
  name: string;
  city: string;
  type: WarehouseType;
  address: string;
  coordinates: any;
  phones: string[];
  openTime?: string;
  is24on7?: boolean;
}

type IWarehouseCard = (props: IWarehouse & { selected: boolean; onSelect: () => void }) => JSX.Element;

const WarehouseCardContainer = styled<any>('div')(({ theme }: IThemed) => ({
  width: '100%',
  padding: theme.spacing(2),
  borderRadius: 10,
  cursor: 'pointer',
  background: 'white',
  border: `1.5px solid ${theme.palette.common.disabledGrayBackground}`,

  '&.selected': {
    border: `1.5px solid ${theme.palette.primary.main}`,
    boxShadow: '4px 4px 10px rgba(115, 52, 133, 0.16);'
  }
}));

const WarehouseCard: IWarehouseCard = ({ name, address, phones, selected, onSelect }) => {
  return (
    <Grid item sm={10} xs={12} onClick={onSelect}>
      <WarehouseCardContainer className={selected ? 'selected' : ''}>
        <Grid container>
          <Grid item flex={1}>
            <TextSubTitle color={selected ? 'primary' : 'textPrimary'} style={{ marginBottom: 10 }}>
              {name}
            </TextSubTitle>
          </Grid>
          {/* <Grid item>time</Grid> */}
          <Grid item>
            <Icon type="warehouse" size={16} />
          </Grid>
        </Grid>
        <TextBody2 style={{ marginBottom: 10, textDecoration: 'underline' }}>{address}</TextBody2>
        {phones.map(p => (
          <TextBody2 style={{ marginBottom: 4 }}>
            <a href={`tel:${p}`}>+{p}</a>
          </TextBody2>
        ))}
      </WarehouseCardContainer>
    </Grid>
  );
};

export { WarehouseCard };
