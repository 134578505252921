import { createReducer, PayloadAction, createAsyncAction } from 'typesafe-actions';
import {
  loadedDataWrapper,
  REQUEST_ACTIONS,
  IAsyncDataWrapper,
  loadingDataWrapper,
  errorDataWrapper,
  refreshDataWrapper
} from 'store/actions';
import { OutletsRepository } from './request';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Outlet } from 'models/outlet';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from '../layout';

import messages from 'translations/account/settings';
import { extractDigitsFromString } from '../../utils/helpers';

const httpClient = new OutletsRepository();

export const prefix = '@@outlets/';

export const OUTLETS_GET_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const OUTLETS_GET_REQUEST_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const OUTLETS_GET_REQUEST_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

export const OUTLET_ADD_REQUEST = `${prefix}add/${REQUEST_ACTIONS.REQUEST}`;
export const OUTLET_ADD_REQUEST_SUCCESS = `${prefix}add/${REQUEST_ACTIONS.SUCCESS}`;
export const OUTLET_ADD_REQUEST_FAILURE = `${prefix}add/${REQUEST_ACTIONS.FAILURE}`;

export const OUTLET_REMOVE_REQUEST = `${prefix}remove/${REQUEST_ACTIONS.REQUEST}`;
export const OUTLET_REMOVE_REQUEST_SUCCESS = `${prefix}remove/${REQUEST_ACTIONS.SUCCESS}`;
export const OUTLET_REMOVE_REQUEST_FAILURE = `${prefix}remove/${REQUEST_ACTIONS.FAILURE}`;

export const OUTLET_UPDATE_REQUEST = `${prefix}update/${REQUEST_ACTIONS.REQUEST}`;
export const OUTLET_UPDATE_REQUEST_SUCCESS = `${prefix}update/${REQUEST_ACTIONS.SUCCESS}`;
export const OUTLET_UPDATE_REQUEST_FAILURE = `${prefix}update/${REQUEST_ACTIONS.FAILURE}`;

export type IOutletsState = IAsyncDataWrapper<Outlet[]>;

export const outletsInitialState: IOutletsState = {
  loading: false,
  loaded: false,
  data: [],
  error: null
};

type OutletsListActionTypes =
  | typeof OUTLETS_GET_REQUEST
  | typeof OUTLETS_GET_REQUEST_SUCCESS
  | typeof OUTLETS_GET_REQUEST_FAILURE;

type OutletAddActionTypes =
  | typeof OUTLET_ADD_REQUEST
  | typeof OUTLET_ADD_REQUEST_SUCCESS
  | typeof OUTLET_ADD_REQUEST_FAILURE;

type OutletRemoveActionTypes =
  | typeof OUTLET_REMOVE_REQUEST
  | typeof OUTLET_REMOVE_REQUEST_SUCCESS
  | typeof OUTLET_REMOVE_REQUEST_FAILURE;

type OutletUpdateActionTypes =
  | typeof OUTLET_UPDATE_REQUEST
  | typeof OUTLET_UPDATE_REQUEST_SUCCESS
  | typeof OUTLET_UPDATE_REQUEST_FAILURE;

export const fetchOutletsAsync = createAsyncAction(
  OUTLETS_GET_REQUEST,
  OUTLETS_GET_REQUEST_SUCCESS,
  OUTLETS_GET_REQUEST_FAILURE
)<void, any, Error>();

export const addOutletAsync = createAsyncAction(
  OUTLET_ADD_REQUEST,
  OUTLET_ADD_REQUEST_SUCCESS,
  OUTLET_ADD_REQUEST_FAILURE
)<Outlet & { _then: (a: any) => void }, null, Error>();

export const updateOutletAsync = createAsyncAction(
  OUTLET_UPDATE_REQUEST,
  OUTLET_UPDATE_REQUEST_SUCCESS,
  OUTLET_UPDATE_REQUEST_FAILURE
)<Outlet, Outlet, Error>();

export const removeOutletAsync = createAsyncAction(
  OUTLET_REMOVE_REQUEST,
  OUTLET_REMOVE_REQUEST_SUCCESS,
  OUTLET_REMOVE_REQUEST_FAILURE
)<string, string, Error>();

function* outletsListSaga(): Generator {
  try {
    const response: any = yield call(() => httpClient.all());
    if (response && (response.status === 401 || response.status === 403 || response.status === 503)) {
      window.localStorage.setItem('redirect_after_login', window.location.pathname);
      yield put({ type: '@@auth/LOGOUT' });
      window.location.href = '/login';
    } else {
      response.data = (response as IAsyncDataWrapper<Outlet[]>).data.map(outlet => new Outlet(outlet));
      yield put(fetchOutletsAsync.success(response.data));
      return response.data;
    }
  } catch (err) {
    yield put(fetchOutletsAsync.failure(err as Error));
  }
}

function* outletUpdateSaga(action: ReturnType<typeof updateOutletAsync.request>): Generator {
  try {
    if (action.payload) {
      const outlet = {
        ...action.payload,
        phone: extractDigitsFromString(action.payload.phone)
      };
      const response: any = yield call(() => httpClient.update(outlet.id, outlet));
      yield put(updateOutletAsync.success(new Outlet(response)));
      // fix this shitty spike later
      yield outletsListSaga();
      yield put(enqueueSnackbarSuccess({ message: messages.outletSaved.defaultMessage }));
    }
  } catch (err) {
    yield put(updateOutletAsync.failure(err as Error));
    yield put(enqueueSnackbarError());
  }
}

function* outletRemoveSaga(action: ReturnType<typeof removeOutletAsync.request>): Generator {
  try {
    if (action.payload) {
      yield call(() => httpClient.delete(action.payload));
      yield put(removeOutletAsync.success(action.payload));
      // fix this shitty spike later
      yield outletsListSaga();
      yield put(enqueueSnackbarSuccess({ message: messages.outletRemoved.defaultMessage }));
    }
  } catch (err) {
    yield put(removeOutletAsync.failure(err as Error));
    yield put(enqueueSnackbarError());
  }
}

function* outletAddSaga(action: ReturnType<typeof addOutletAsync.request> & { _then: (a: any) => void }): Generator {
  try {
    // dirty hack to call cb from component
    const then = action.payload._then;
    delete (action as any).payload._then;

    if (action.payload) {
      const data = {
        ...action.payload,
        phone: extractDigitsFromString(action.payload.phone)
      };
      const response: any = yield call(() => httpClient.add(data));
      yield put(addOutletAsync.success(response));
      // fix this shitty spike later
      yield outletsListSaga();
      if (then) {
        const newOutlet = response.data;
        then(newOutlet);
      }
      yield put(enqueueSnackbarSuccess({ message: messages.outletAdded.defaultMessage }));
    }
  } catch (err) {
    yield put(addOutletAsync.failure(err as Error));
    yield put(enqueueSnackbarError());
  }
}

export function* outletsRequestSaga() {
  yield takeEvery(fetchOutletsAsync.request, outletsListSaga);
  yield takeEvery(addOutletAsync.request, outletAddSaga);
  yield takeEvery(updateOutletAsync.request, outletUpdateSaga);
  yield takeEvery(removeOutletAsync.request, outletRemoveSaga);
}

export default createReducer(outletsInitialState)
  // get outlets list actions
  .handleAction(fetchOutletsAsync.request, (state: IOutletsState) => loadingDataWrapper(state.data))
  .handleAction(
    fetchOutletsAsync.success,
    (state: IOutletsState, action: PayloadAction<OutletsListActionTypes, Outlet[]>) => loadedDataWrapper(action.payload)
  )
  .handleAction(fetchOutletsAsync.failure, (state: IOutletsState) =>
    errorDataWrapper(state.data, new Error('Failed to load outlets list'))
  )

  // add outlet actions
  .handleAction(addOutletAsync.request, (state: IOutletsState) => {
    return loadingDataWrapper(state.data);
  })
  .handleAction(addOutletAsync.success, (state: IOutletsState, action: PayloadAction<OutletAddActionTypes, Outlet>) => {
    state.data.push(new Outlet(action.payload));
    return loadedDataWrapper({ ...state.data });
  })
  .handleAction(addOutletAsync.failure, (state: IOutletsState) =>
    errorDataWrapper(state.data, new Error('Failed to add an outlet'), false, true)
  )

  // update outlet actions
  .handleAction(
    updateOutletAsync.request,
    (state: IOutletsState, action: PayloadAction<OutletUpdateActionTypes, Outlet>) => refreshDataWrapper(state.data)
  )
  .handleAction(
    updateOutletAsync.success,
    (state: IOutletsState, action: PayloadAction<OutletUpdateActionTypes, Outlet>) => {
      const updateIndex = state.data.findIndex(outlet => outlet.id === action.payload.id);
      state.data[updateIndex] = action.payload;
      return loadedDataWrapper([...state.data]);
    }
  )
  .handleAction(updateOutletAsync.failure, (state: IOutletsState) =>
    errorDataWrapper({ ...state.data }, new Error('Failed to load address list'))
  )

  // remove outlet actions
  .handleAction(
    removeOutletAsync.request,
    (state: IOutletsState, action: PayloadAction<OutletRemoveActionTypes, string>) => refreshDataWrapper(state.data)
  )
  .handleAction(
    removeOutletAsync.success,
    (state: IOutletsState, action: PayloadAction<OutletRemoveActionTypes, Outlet>) => {
      const removeIndex = state.data.findIndex(outlet => outlet.id === action.payload.id);
      delete state.data[removeIndex];
      return loadedDataWrapper(state.data);
    }
  )
  .handleAction(removeOutletAsync.failure, (state: IOutletsState) =>
    errorDataWrapper({ ...state.data }, new Error('Failed to load address list'))
  );
