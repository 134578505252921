import ImageCategoryAccessoriesMobile from '../assets/furniture-mobile.webp';
import ImageCategoryAccessories from '../assets/furniture.webp';
import ImageCategoryIndoorMobile from '../assets/indoor-plants-mobile.webp';
import ImageCategoryIndoor from '../assets/indoor-plants.webp';
import ImageCategoryShearFlowersMobile from '../assets/cut-flowers-mobile.webp';
import ImageCategoryShearFlowers from '../assets/cut-flowers.webp';
import ImageCategoryStabilizedMobile from '../assets/stabilized-flowers-mobile.webp';
import ImageCategoryStabilized from '../assets/stabilized-flowers.webp';
import {
  catalogProductTypeAccessories,
  catalogProductTypePotted,
  catalogProductTypeShear,
  catalogProductTypeStable
} from 'shared/constants';

export interface IGridType {
  columns: IGridTypeColumns;
  image: string;
  imageMobile: string;
}

export interface IGridTypeColumns {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export const GRID_TYPES: Record<string, IGridType> = {
  [catalogProductTypeShear]: {
    columns: {
      xs: 6,
      sm: 6,
      md: 10,
      lg: 14,
      xl: 14
    },
    image: ImageCategoryShearFlowers,
    imageMobile: ImageCategoryShearFlowersMobile
  },
  [catalogProductTypePotted]: {
    columns: {
      xs: 6,
      sm: 6,
      md: 10,
      lg: 14,
      xl: 14
    },
    image: ImageCategoryIndoor,
    imageMobile: ImageCategoryIndoorMobile
  },
  [catalogProductTypeStable]: {
    columns: {
      xs: 6,
      sm: 6,
      md: 10,
      lg: 10,
      xl: 10
    },
    image: ImageCategoryStabilized,
    imageMobile: ImageCategoryStabilizedMobile
  },
  [catalogProductTypeAccessories]: {
    columns: {
      xs: 6,
      sm: 6,
      md: 10,
      lg: 10,
      xl: 10
    },
    image: ImageCategoryAccessories,
    imageMobile: ImageCategoryAccessoriesMobile
  }
};
