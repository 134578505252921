import { remove as _remove, includes as _includes, now as _now } from 'lodash';
import { IFilterProductCatalogCategory } from 'models';
import { IFilterProp, IFilterProps } from 'store/catalog/actions';

export const MAX_OPTIONS_SHOWN = 10;

export const getGroupState = (
  attributeFilterValues: Array<string>,
  item: IFilterProductCatalogCategory
): { isChecked: boolean; isUnchecked: boolean } => {
  let isChecked = false;
  let isUnchecked = false;
  (item.items || []).forEach((subItem: IFilterProductCatalogCategory) => {
    isChecked = isChecked || attributeFilterValues.includes(subItem.code);
    isUnchecked = isUnchecked || !attributeFilterValues.includes(subItem.code);
  });

  return { isChecked, isUnchecked };
};

export function filterByCheckedDisabledQty(item: any): boolean {
  if (!item.disabled || item.isChecked) {
  }
  return false;
}

export function sortByCheckedRank(a: any, b: any) {
  if (a.isChecked) {
    return -1;
  }

  if (a.isChecked || b.isChecked) return 0;

  if (a.rank && b.rank) {
    return b.rank - a.rank;
  }
  return 0;
}

export function sortByChecked(a: any, b: any) {
  if (a.isChecked) {
    return -1;
  }
  return 0;
}

export const sortByProductCountAndChecked = (attributeFilterValues: Array<string>, locale: string) => (
  a: IFilterProductCatalogCategory,
  b: IFilterProductCatalogCategory
) => {
  const isCheckedA = attributeFilterValues.length > 0 && attributeFilterValues.includes(a.code);
  const isCheckedB = attributeFilterValues.length > 0 && attributeFilterValues.includes(b.code);
  if (isCheckedA) {
    return -1;
  }

  if (isCheckedA || isCheckedB) return 0;

  if (a.productsCount !== b.productsCount) {
    return b.productsCount - a.productsCount;
  } else {
    return a.name.localeCompare(b.name, locale);
  }
};

export function sortByIsTopLevel(a: IFilterProductCatalogCategory, _b: IFilterProductCatalogCategory) {
  if (a.firstLevel) {
    return -1;
  }
  return 0;
}

export const sortByLocaleName = (locale: string) => (
  a: IFilterProductCatalogCategory,
  b: IFilterProductCatalogCategory
) => a.name.localeCompare(b.name, locale);

export function sortAlphabeticallyByLabel(a: any, b: any) {
  if (a.label && b.label) {
    return a.label.localeCompare(b.label);
  }
  return 0;
}

export function sortAlphabeticallyByValue(a: any, b: any) {
  if (a.value && b.value) {
    return a.value.localeCompare(b.value);
  }
  return 0;
}

export function sortNumericByValue(a: any, b: any) {
  if (a.value && b.value) {
    return a.value - b.value;
  }
  return 0;
}

export const prepareFilterCategoriesStateChildren = (
  item: IFilterProductCatalogCategory,
  isChecked: boolean,
  filtersFast: IFilterProps
): IFilterProps => {
  const newFilterPropValue: IFilterProp = {
    rule: 'include',
    path: 'category',
    value: item.code,
    label: item.name || item.code
  };

  /** change only if has products */
  if (Boolean(item.productsCount)) {
    if (!isChecked) {
      _remove(filtersFast, newFilterPropValue);
    } else if (!_includes(filtersFast, newFilterPropValue)) {
      newFilterPropValue.time = _now();
      filtersFast.push(newFilterPropValue);
    }
  }

  item.items.forEach((subItem: IFilterProductCatalogCategory) => {
    if (subItem.items && subItem.items.length) {
      prepareFilterCategoriesStateChildren(subItem, isChecked, filtersFast);
    } else {
      const newFilterPropValue: IFilterProp = {
        rule: 'include',
        path: 'category',
        value: subItem.code,
        label: subItem.name || subItem.code
      };

      /** change only if has products */
      if (Boolean(subItem.productsCount)) {
        if (!isChecked) {
          _remove(filtersFast, newFilterPropValue);
        } else if (!_includes(filtersFast, newFilterPropValue)) {
          newFilterPropValue.time = _now();
          filtersFast.push(newFilterPropValue);
        }
      }
    }
  });

  return filtersFast;
};
