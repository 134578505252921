import { Product } from "models/product";
import { CatalogCategory } from "models/catalog-category";
import { ProductType } from "models/product-type";

export class FeaturedProductsSection {
  public productType: ProductType;
  public catalogCategory: CatalogCategory;
  public order: number;
  public products: Product[];

  constructor(section: any) {
    this.productType = section.productType;
    this.catalogCategory = section.catalogCategory;
    this.products = (section.products || []).map((product: any) => new Product(product));
    this.order = +section.order;
  }
}
