import React from 'react';
import { Button, Grid, Hidden, TextField, useMediaQuery, Theme } from '@mui/material';
import { withStyles, useTheme } from '@mui/styles';
// import { Breakpoint } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form';

import { TitleH1, TextSubTitle } from 'components/shared/text';
import styles from './styles';
import messages from 'translations/main/subscribe';
import messagesControls from 'translations/layout/controls';
import { HttpClient } from 'utils/http';
import env from '../../../environment';
import SnackbarContent from '../../../components/layout/notistack-provider/SnackbarWithTitle';
import { useSnackbar } from 'notistack';

interface IFormData {
  email: string;
}

interface IProps {
  // component own props
  classes: any;
}

const MainSubscribe: React.FC<IProps> = ({ classes }) => {
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { handleSubmit, errors, control, reset } = useForm<IFormData>();
  const [isLoadingDev, setIsLoadingDev] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit(({ email }): any => {
    /** TODO submit subscribe form */
    setIsLoadingDev(true);

    const httpClient = new HttpClient(`${env.apiUrl}/form/news-subscribe`);
    const token = window.localStorage.getItem('token') || '';
    const headers = token ? { Authorization: token } : {};
    httpClient
      .post('', { email }, { headers })
      .then(() => {
        reset({});
        enqueueSnackbar(<SnackbarContent>{messages.notificationSuccess.defaultMessage}</SnackbarContent>, {
          variant: 'success'
        });
      })
      .catch(res => {
        if (!res.ok) {
          enqueueSnackbar(<SnackbarContent>{messages.notificationError.defaultMessage}</SnackbarContent>, {
            variant: 'error'
          });
          if (res.parsedErrors) {
            // todo handle error
          }
        }
      })
      .finally(() => {
        setIsLoadingDev(false);
      });
  });

  return (
    <div className={classes.wrapper}>
      <Grid container className={classes.root} component={'section'} spacing={2}>
        <Hidden mdDown>
          <Grid item sm={1} />
        </Hidden>

        <Grid item className={classes.container}>
          <TitleH1 className={classes.title} color={'inherit'}>
            {messages.title.defaultMessage}
          </TitleH1>
          <TextSubTitle className={classes.subTitle} color={'inherit'}>
            {messages.subTitle.defaultMessage}
          </TextSubTitle>

          <form className={classes.formContainer} onSubmit={onSubmit} noValidate>
            <Controller
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email.message}
              as={TextField}
              rules={{
                required: messagesControls.requiredFiled.defaultMessage,
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: messagesControls.validEmail.defaultMessage
                }
              }}
              name="email"
              type="email"
              control={control}
              defaultValue=""
              className={classes.input}
              InputLabelProps={{ shrink: true }}
              placeholder={messages.controlPlaceholder.defaultMessage}
              // ! TODO check after migrating to material v5
              // fullWidth={isWidthDown('sm', width)}
              fullWidth={isMobile}
              variant={'outlined'}
            />

            <Button
              size={'large'}
              // ! TODO check after migrating to material v5
              // fullWidth={isWidthDown('sm', width)}
              fullWidth={isMobile}
              className={classes.buttonSubmit}
              disabled={isLoadingDev}
              color={'primary'}
              variant={'outlined'}
              type={'submit'}
            >
              {messages.submit.defaultMessage}
            </Button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles<any>(styles)(MainSubscribe);
