import React from 'react';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import Chart from 'react-apexcharts';
import { IClientDiscount, User } from 'models';
import { baseUrl } from 'shared/constants';
import { SplitedPrice } from 'components/prices';
import { Link2, TextSubTitle } from 'components/shared/text';
import Icon from 'components/shared/Icon';
import FlrCardNarrow from 'components/shared/card/FlrCardNarrow';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import { UAH } from 'shared/constants';
import messages from 'translations/account/loyalty';
import { getDonutChartOptions } from './chart-configs';
import styles from '../styles';

interface IProps {
  discount: IClientDiscount;
  account: User | null;
  classes: any;
}

const PersonalDiscountCard: React.FC<IProps> = ({ discount, classes, account }) => {
  if (!discount) {
    return null;
  }

  const chartOptions = {
    ...getDonutChartOptions(`${discount.discountData.percent}%`, 'Знижка', classes),
    labels: [messages.discountLabel.defaultMessage, messages.discountLabel.defaultMessage]
  };
  return (
    <FlrCardNarrow className={classes.paperCard} hoverShadow>
      <FlrCardTitle isUnderline>
        <TextSubTitle align={'left'}>{messages.currentDiscountLevel.defaultMessage}</TextSubTitle>
      </FlrCardTitle>
      <Grid item xs={12} container>
        <Grid item xs={'auto'} className={classes.cardIcon}>
          <Icon type={'finance'} offset={8} />
        </Grid>
        <Grid item xs container justifyContent={'space-between'} direction={'column'}>
          <Grid item xs>
            <SplitedPrice
              value={discount.discountData.requiredValue}
              fontSize={'h1'}
              fontSizeDecimal={'h2'}
              negative={Boolean(account && account.profile?.balance < 0)}
              prefix={
                discount.discountData.progressValue > discount.discountData.requiredValue ? (
                  <>&ge;&nbsp;</>
                ) : (
                  <>&le;&nbsp;</>
                )
              }
              postfix={UAH}
            />
            {/* <TextSubTitle className={classes.cardBalanceBody}>
              {intl.formatMessage(messages.discountForWeeks, getFormatParams(discount.countOfWeeks))}
            </TextSubTitle> */}
          </Grid>
          <Grid item>
            <Link2 href={`${baseUrl}/account/loyalty#discount-rules-anchor`}>{messages.readRules.defaultMessage}</Link2>
          </Grid>
        </Grid>
        <Grid item xs={'auto'}>
          <Chart width={120} height={140} options={chartOptions} series={[0, 1]} type={'donut'} />
        </Grid>
      </Grid>
    </FlrCardNarrow>
  );
};

export default withStyles<any>(styles)(PersonalDiscountCard);
