import { SvgIcon } from '@mui/material';
import Radio from '@mui/material/Radio';

interface IProps {
  [key: string]: any;
}

const CustomRadioButtonUnchecked = (props: IProps) => (
  <SvgIcon {...props}>
    <circle cx="12" cy="12" r="9" stroke={props.disabled ? '#b0b0b0' : 'currentColor'} fill="none" strokeWidth="1" />
  </SvgIcon>
);

const CustomRadioButtonChecked = (props: IProps) => (
  <SvgIcon {...props}>
    <circle cx="12" cy="12" r="9" stroke={props.disabled ? '#b0b0b0' : 'currentColor'} fill="none" strokeWidth="1" />
    <circle cx="12" cy="12" r="5" fill={props.disabled ? '#b0b0b0' : 'currentColor'} />
  </SvgIcon>
);

const FlrRadio = (props: IProps) => {
  return <Radio icon={<CustomRadioButtonUnchecked />} checkedIcon={<CustomRadioButtonChecked />} {...props} />;
};

export { FlrRadio };
