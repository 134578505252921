import { action } from 'typesafe-actions';

import {
  LAYOUT_ACTION_TYPES,
  ThemeColors,
  INotification,
  INotificationContent,
  INotificationContentMessage,
  IPriceType
} from './types';
import messages from 'translations/layout/notifications';

export const setTheme = (theme: ThemeColors) => action(LAYOUT_ACTION_TYPES.SET_THEME, theme);

export const enqueueSnackbar = (notification: INotification) => {
  const key = notification.options && notification.options.key;

  return {
    type: LAYOUT_ACTION_TYPES.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random()
    }
  };
};

export const enqueueSnackbarError = ({ message, title, options = {} }: INotificationContent = {}) => {
  return {
    type: LAYOUT_ACTION_TYPES.ENQUEUE_SNACKBAR,
    notification: {
      message: message || messages.errorOccurred.defaultMessage,
      title: title || messages.error.defaultMessage,
      options: {
        ...options,
        variant: 'error'
      },
      key: new Date().getTime() + Math.random()
    }
  };
};
export const enqueueSnackbarSuccess = ({ message, title, options = {} }: INotificationContentMessage) => {
  return {
    type: LAYOUT_ACTION_TYPES.ENQUEUE_SNACKBAR,
    notification: {
      message,
      title: title || messages.success.defaultMessage,
      options: {
        ...options,
        variant: 'success'
      },
      key: new Date().getTime() + Math.random()
    }
  };
};
export const enqueueSnackbarWarning = ({ message, title, options = {} }: INotificationContentMessage) => {
  return {
    type: LAYOUT_ACTION_TYPES.ENQUEUE_SNACKBAR,
    notification: {
      message,
      title: title || messages.warning.defaultMessage,
      options: {
        ...options,
        variant: 'warning'
      },
      key: new Date().getTime() + Math.random()
    }
  };
};
export const enqueueSnackbarInfo = ({ message, title, options = {} }: INotificationContentMessage) => {
  return {
    type: LAYOUT_ACTION_TYPES.ENQUEUE_SNACKBAR,
    notification: {
      message,
      title: title || messages.error.defaultMessage,
      options: {
        ...options,
        variant: 'info'
      },
      key: new Date().getTime() + Math.random()
    }
  };
};

export const closeSnackbar = (key: number | string) => ({
  type: LAYOUT_ACTION_TYPES.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key
});

export const removeSnackbar = (key: number | string) => ({
  type: LAYOUT_ACTION_TYPES.REMOVE_SNACKBAR,
  key
});

export const setPriceType = (priceType: IPriceType) => action(LAYOUT_ACTION_TYPES.SET_PRICE_TYPE, priceType);
