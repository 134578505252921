import { CartService } from './service';
import { IRequestCartItem, Cart, ICheckTransitResponse } from 'models';
import { prefix } from './actions';
import { HttpClient } from 'utils/http';

export class CartRepository {
  private client = new HttpClient(`${process.env.REACT_APP_API_ENDPOINT}/sales/cart`);
  private service = new CartService(`${prefix}_DATA`);

  public async fetch() {
    this.client.setAuthHeader();
    return await this.client
      .get<Cart>('/')
      .then(cart => cart.data)
      .then(this.service.update);
  }

  public async addItem(item: IRequestCartItem) {
    return await this.client.post(`/${item.offer}`, { qty: item.qty });
  }

  public async addManyItems(items: IRequestCartItem[]) {
    return await this.client.post(`/update-all`, items);
  }

  public async removeItem(item: IRequestCartItem) {
    return await this.client.delete(`/${item.offer}`);
  }

  public async hold({ groupType, cartGroupId }: any) {
    return await this.client.get<any>(`/hold/${groupType}/${cartGroupId}`).then(holdResult => holdResult.data);
  }

  public async checkTransit(offerId: string) {
    return await this.client.get<ICheckTransitResponse>(`/check-transit/${offerId}`).then(response => response.data);
  }
}
