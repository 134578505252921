import { ITheme } from 'utils/styled';
import { mobileHeaderHeight } from 'shared/constants';

const styles = (theme: ITheme) => ({
  root: {
    background: theme.palette.common.cardBlackBackgroundHex,
    [(theme as any).breakpoints.down('sm')]: {
      position: 'fixed',
      top: mobileHeaderHeight,
      zIndex: 2
    }
  }
});

export default styles;
