import { defineMessages } from "react-intl";

export default defineMessages({
  subTitle1: {
    id: "main.features.deliveryDesc",
    description: "",
    defaultMessage: "Платформа закупівлі найсвіжіших квітів."
  },
  subTitle2: {
    id: "main.features.deliveryDesc",
    description: "",
    defaultMessage: "Для професіоналів своєї справи."
  },
  buttonMobile: {
    id: "main.features.deliveryDesc",
    description: "",
    defaultMessage: "у каталог"
  },
  button: {
    id: "main.features.deliveryDesc",
    description: "",
    defaultMessage: "перейти до каталогу"
  }
});
