import React from "react";
import { Divider, DividerProps, Grid } from "@mui/material";

type IProps = {
  dividerProps: DividerProps;
} & any;
export const GridDividerBold = ({ dividerProps, ...rest }: IProps) => (
  <Grid item sm {...rest}>
    <Divider {...dividerProps} />
    <Divider {...dividerProps} style={{ opacity: 0.5 }} />
  </Grid>
);

export const GridDivider = ({ dividerProps, ...rest }: IProps) => (
  <Grid item sm {...rest}>
    <Divider {...dividerProps} style={{ opacity: 0.5 }} />
  </Grid>
);
