import { HttpClient } from 'utils/http';

export class OutletsRepository<T> {
  private client = new HttpClient(`${process.env.REACT_APP_API_ENDPOINT}/account/outlets`);

  public async all() {
    this.client.setAuthHeader();
    return await this.client.get('');
  }

  public async add(data: T) {
    return await this.client.post('', data);
  }

  public async delete(id: string) {
    return await this.client.delete(`/${id}`);
  }

  public async update(id: string, data: T) {
    return await this.client.put(`/${id}`, data);
  }
}
