import { FC, ReactNode, useEffect, useState } from 'react';
import Select from 'react-select';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';

import components from './components';
import styles from './styles';

export interface ISelectOption {
  label: string;
  value: string;
  isDisabled?: boolean;
  render?: any;
  data?: object;
  desc?: string;
  amount?: string | number;
  onClick?: () => void;
  icon?: string;
}

interface IProps {
  options: ISelectOption[];
  classes: any;
  className?: string;
  value: string;
  name?: string;
  label?: string;
  isLoading?: boolean;
  fullWidth?: boolean;
  disableWrapper?: boolean;
  dropdownMenu?: boolean;
  labelIcon?: ReactNode;
  labelIconRight?: ReactNode;
  placeholder?: string;
  helperText?: string;
  style?: any;
  invalid?: boolean;
  onChange?: (ev: any) => void;
  isDisabled?: boolean;
  selectClasses?: object;
  controlClasses?: object;
  menuItemClasses?: object;
  inputDropdownMenuOpenClasses?: object;
  inputClasses?: object;
  isCatalog?: boolean;
  qtySelect?: boolean;
}

const topStyles = {
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderBottom: 'none'
};

const bottomStyles = {
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderTop: 'none'
};

const FlrSelect2: FC<IProps> = ({
  onChange,
  value,
  options,
  label,
  name = '',
  classes,
  isLoading,
  className,
  dropdownMenu,
  labelIcon,
  labelIconRight,
  invalid,
  isDisabled,
  selectClasses,
  controlClasses,
  menuItemClasses,
  inputDropdownMenuOpenClasses,
  inputClasses,
  isCatalog,
  qtySelect
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [compValue, setCompValue] = useState<ISelectOption | null>(null);
  const [dropdownPlacement, setDropdownPlacement] = useState('bottom');

  useEffect(() => {
    if (options) {
      const foundOption =
        options.find((option) => {
          return option.value === value;
        }) || null;

      setCompValue(foundOption);
    }
  }, [options, value]);

  // TODO defaultEvent ?
  function handleChangeSingle(newValue: any) {
    if (newValue.onClick) {
      return newValue.onClick();
    }

    setCompValue(newValue);

    if (onChange) {
      onChange({
        target: {
          value: newValue.value,
          name,
          data: newValue
        }
      });
    }
  }

  const selectStyles = {
    input: (base: any) => ({
      ...base,
      color: invalid ? theme.palette.error.main : theme.palette.text.primary,
      '& input': {
        cursor: 'pointer !important',
        font: 'inherit'
      }
    }),
    singleValue: (base: any) => ({
      ...base,
      color: 'red'
    }),
    menu: (base: any, rest: any) => {
      const openedToTop = base.bottom === '100%';
      if (openedToTop) {
        rest.selectProps.setDropdownPlacement('top');
      }
      return {
        ...base,
        margin: 0,
        boxShadow: 'none',
        borderRadius: 8,
        border: `2px solid ${theme.palette.primary.main}`,
        zIndex: 2,
        ...(openedToTop ? topStyles : bottomStyles)
      };
    },
    menuList: (base: any) => ({
      ...base,
      padding: 0
    })
  };

  const onMenuClose = () => {
    setIsOpen(false);
  };
  const onMenuOpen = () => {
    setIsOpen(true);
  };

  // @ts-ignore
  return (
    <Select
      dropdownPlacement={dropdownPlacement}
      setDropdownPlacement={setDropdownPlacement}
      dropdownMenu={dropdownMenu}
      labelIcon={labelIcon}
      labelIconRight={labelIconRight}
      // menuIsOpen // dev
      maxMenuHeight={qtySelect ? 140 : undefined}
      menuPlacement="auto"
      className={clsx(className, { [classes.catalogSelect]: isCatalog && isOpen })}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      isSearchable={false}
      classes={{
        ...classes,
        ...selectClasses,
        ...controlClasses,
        ...menuItemClasses,
        ...inputDropdownMenuOpenClasses,
        inputClasses
      }}
      name={name}
      styles={selectStyles}
      isLoading={isLoading}
      TextFieldProps={{
        variant: 'outlined',
        label,
        InputLabelProps: {
          shrink: isOpen || Boolean(compValue),
          className: classes.label
        },
        disabled: isDisabled
      }}
      options={options}
      // @ts-ignore
      components={components}
      value={compValue}
      onChange={handleChangeSingle}
      isDisabled={isDisabled}
    />
  );
};

export default withStyles<any>(styles)(FlrSelect2);
