import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { ThemeProvider, Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import 'moment/locale/uk';
import { ConfirmProvider } from 'material-ui-confirm';

import { IApplicationState } from 'store/reducers';
import { fetchCartAsync } from 'store/cart/actions';
import * as layoutActions from 'store/layout/actions';
import { ThemeColors } from 'store/layout';
import { getTheme } from 'store/layout/selectors';
import { fetchAccountAsync } from 'store/account/actions';
import { fetchOutletsAsync } from 'store/outlets/actions';
import { fetchFeaturedProductsSectionsAsync, mainSettingsAsync } from './store/main/actions';

import { IntlProvider } from 'intl/IntlProvider';
import Routes from './routes';

import GDPRBar from './components/gdprBar';
import { CanonicalLinks } from './components/CanonicalLinks';
import NotistackProvider from './components/layout/notistack-provider';

import { getCookie } from 'utils/cookies';
import { cookiesAllowedName } from 'shared/constants';
import * as themes from './styles/theme';
import { confirmDefaultProps } from './styles/theme/default';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface IDefaultTheme extends Theme {}
}

// Any additional component props go here.
interface IMainProps {
  history: History;
  theme: ThemeColors;
}

interface IMappedActions {
  setTheme: typeof layoutActions.setTheme;
  loadCart: typeof fetchCartAsync.request;
  loadUser: typeof fetchAccountAsync.request;
  loadOutlets: typeof fetchOutletsAsync.request;
  loadContacts: typeof mainSettingsAsync.request;
  loadMainPageProducts: typeof fetchFeaturedProductsSectionsAsync.request;
}

type AppProps = IMainProps & IMappedActions;

moment.locale('uk');

const HARDCODED_LOCALE = 'en-US';
// en-US / ru-RU / uk-UA
// Create an intersection productType of the component props and our Redux props.
// TODO: pass real locale into IntlProvider from user selection via appropriate component
class App extends React.Component<AppProps> {
  public cookiesAllowed = false;
  public componentDidMount() {
    const { loadCart, loadUser, loadOutlets, loadContacts, loadMainPageProducts } = this.props;
    loadUser();
    loadCart();
    loadOutlets();
    loadContacts();
    loadMainPageProducts();
    this.cookiesAllowed = getCookie(cookiesAllowedName) !== '1';
  }
  public render() {
    const { history, theme } = this.props;
    const coloredTheme = themes[theme];
    const muiTheme = createTheme(coloredTheme as any);
    return (
      <IntlProvider locale={HARDCODED_LOCALE}>
        <ConnectedRouter history={history}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
              <LocalizationProvider dateAdapter={AdapterMoment as any}>
                <NotistackProvider>
                  <ConfirmProvider defaultOptions={confirmDefaultProps}>
                    {/* add spinner for whole app if user is loading */}
                    <Routes />
                    {this.cookiesAllowed && <GDPRBar />}
                    <CanonicalLinks />
                  </ConfirmProvider>
                </NotistackProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </ConnectedRouter>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  theme: getTheme(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      loadUser: fetchAccountAsync.request,
      loadCart: fetchCartAsync.request,
      loadOutlets: fetchOutletsAsync.request,
      loadContacts: mainSettingsAsync.request,
      loadMainPageProducts: fetchFeaturedProductsSectionsAsync.request,
      setTheme: layoutActions.setTheme
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
