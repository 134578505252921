import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";

import { post } from "utils/api";
import { extractDigitsFromString, validateEmail, validatePassword, validatePhoneMask } from "utils/helpers";
import env from "environment";

import { IUserRegisterResponse } from "models/user";

import { FlrButtonKhaki } from "components/shared/buttons";
import { IFlrStepButtonActions } from "components/shared/stepper";
import FlrLoader from "components/loading/LoadingSpinner";
import FlrCheckbox from "components/shared/form-elements/FlrCheckbox";
import FlrInputPassword from "components/shared/form-elements/textfield/FlrInputPassword";

import messages from "translations/auth/registration";
import messagesControls from "translations/layout/controls";
import styles from "../styles";
import { registrationTokenStorageKey } from "shared/constants";
import FlrInputPhone from "components/shared/form-elements/textfield/FlrInputPhone";

export interface IUniqueFieldErrorState {
  phone: string;
  email: string;
}

interface IFormData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  subscribe: boolean;
}

const RegistrationFormStep1 = (props: IFlrStepButtonActions) => {
  const { classes } = props;

  const { handleSubmit, errors: errorsHook, control, watch } = useForm<IFormData>();
  const watchPhone: string = watch('phone');

  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [errors, setErrors] = React.useState({
    email: "",
    phone: ""
  } as IUniqueFieldErrorState);

  const onSubmit = handleSubmit((fields): any => {
    if (disableSubmit) {
      return;
    }
    setDisableSubmit(true);

    const response = post<IUserRegisterResponse>(`${env.apiUrl}/auth/register`, {
      ...fields,
      confirmPassword: fields.password,
      phone: extractDigitsFromString(fields.phone)
    });

    response
      .then(res => {
        if (res.ok) {
          if (res.parsedBody) {
            window.localStorage.setItem(registrationTokenStorageKey, res.parsedBody.authData.token);
            props.next();
          }
        }
      })
      .catch(res => {
        if (!res.ok) {
          if (res.parsedErrors) {
            setErrors({ ...errors, ...res.parsedErrors });
          }
        }
      })
      .finally(() => {
        setDisableSubmit(false);
      });
  });

  useEffect(() => {
    if (errors.phone) {
      setErrors({ ...errors, phone: ''})
    }
    // eslint-disable-next-line
  }, [watchPhone]);

  return (
    <form onSubmit={onSubmit}>
      <Controller
        disabled={disableSubmit}
        error={Boolean(errorsHook.firstName)}
        helperText={errorsHook.firstName && errorsHook.firstName.message}
        rules={{
          required: messagesControls.requiredFiled.defaultMessage
        }}
        as={TextField}
        name="firstName"
        control={control}
        defaultValue=""
        className={classes.commonControl}
        fullWidth
        variant={"outlined"}
        label={messages.formFieldFirstName.defaultMessage}
      />
      <Controller
        disabled={disableSubmit}
        error={Boolean(errorsHook.lastName)}
        helperText={errorsHook.lastName && errorsHook.lastName.message}
        rules={{
          required: messagesControls.requiredFiled.defaultMessage
        }}
        as={TextField}
        name="lastName"
        control={control}
        defaultValue=""
        className={classes.commonControl}
        fullWidth
        variant={"outlined"}
        label={messages.formFieldLastName.defaultMessage}
      />
      <Controller
        disabled={disableSubmit}
        error={Boolean(errorsHook.phone || !!errors.phone.length)}
        helperText={
          (errorsHook.phone && errorsHook.phone.message) ||
          (!!errors.phone.length && messages.formFieldErrorPhone.defaultMessage)
        }
        rules={{
          required: messagesControls.requiredFiled.defaultMessage,
          validate: validatePhoneMask
        }}
        as={FlrInputPhone}
        name="phone"
        control={control}
        defaultValue=""
        className={classes.commonControl}
        fullWidth
        variant={"outlined"}
        label={messages.formFieldPhone.defaultMessage}
      />
      <Controller
        disabled={disableSubmit}
        error={Boolean(errorsHook.email || !!errors.email.length)}
        helperText={
          (errorsHook.email && errorsHook.email.message) ||
          (!!errors.email.length && messages.formFieldErrorEmail.defaultMessage)
        }
        rules={{
          required: messagesControls.requiredFiled.defaultMessage,
          validate: validateEmail
        }}
        as={TextField}
        name="email"
        control={control}
        defaultValue=""
        className={classes.commonControl}
        fullWidth
        variant={"outlined"}
        label={messages.formFieldEmail.defaultMessage}
      />
      <Controller
        disabled={disableSubmit}
        error={Boolean(errorsHook.password)}
        helperText={errorsHook.password && errorsHook.password.message}
        rules={{
          required: messagesControls.requiredFiled.defaultMessage,
          validate: validatePassword
        }}
        as={FlrInputPassword}
        name="password"
        control={control}
        defaultValue=""
        className={classes.commonControl}
        fullWidth
        variant={"outlined"}
        label={messages.formFieldPassword.defaultMessage}
        type="password"
      />

      <FlrButtonKhaki disabled={disableSubmit} size={"large"} fullWidth type={"submit"} onClick={onSubmit}>
        {messages.formContinueButton.defaultMessage}
      </FlrButtonKhaki>

      <Controller
        as={FlrCheckbox}
        name="subscribe"
        control={control}
        className={classes.checkboxControl}
        classNameLabel={classes.checkboxLabelControl}
        label={messages.formFieldSubscribe.defaultMessage}
      />

      {disableSubmit && <FlrLoader withOverlay={true} />}
    </form>
  );
};

export default withStyles<any>(styles)(RegistrationFormStep1);
