import * as React from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import styled from '@emotion/styled/macro';
import { defineMessages } from 'react-intl';
import { useRouteMatch } from 'react-router';
import withStyles from '@mui/styles/withStyles';

import { IThemed } from 'utils/styled';
import Icon from 'components/shared/Icon';
import { HeaderBottomLink } from './HeaderBottomLink';
import { TextBody2 } from 'components/shared/text';
import { baseUrl } from 'shared/constants';
import {
  ORDER_STATUS_PREORDER,
  ORDER_STATUS_PENDING_APPROVAL,
  ORDER_STATUS_SHIPPED,
  OrderStatus,
  groupOrdersByStatuses,
  ORDER_STATUS_PREORDER_PENDING_APPROVAL,
  // ORDER_STATUS_APPROVED,
  ORDER_STATUS_RESERVED,
  ORDER_STATUS_PACKED
} from 'models';
import { stylesTopLinks } from './Balance';

export const orders = defineMessages({
  label: {
    id: 'catalog.header.orders',
    description: 'orders label',
    defaultMessage: 'Замовлення'
  }
});

interface IHeaderOrdersProps {
  ordersSummary: Record<OrderStatus, number> | null;
  classes: any;
}

const ordersUrl = `${baseUrl}/account/orders`;

const OrdersComponent: React.FC<IHeaderOrdersProps> = ({ ordersSummary, classes }) => {
  const isLinkActive = useRouteMatch(ordersUrl);

  const ordersPreorderCount =
    (ordersSummary &&
      groupOrdersByStatuses(ordersSummary, [ORDER_STATUS_PREORDER_PENDING_APPROVAL, ORDER_STATUS_PREORDER])) ||
    0;
  const ordersPendingCount =
    (ordersSummary &&
      groupOrdersByStatuses(ordersSummary, [
        ORDER_STATUS_PENDING_APPROVAL,
        ORDER_STATUS_RESERVED,
        ORDER_STATUS_PACKED
      ])) ||
    0;
  const ordersShippedCount = (ordersSummary && ordersSummary[ORDER_STATUS_SHIPPED]) || 0;

  return (
    <div className={classes.wrapper}>
      <HeaderBottomLink
        to={ordersUrl}
        className={clsx({
          active: isLinkActive
        })}
      >
        <Icon type={'delivery'} className={'menu-cart-icon'} size={32} offset={8} withBackground opacity={1} />
        <Grid container className={classes.mainContent}>
          <Grid item sm={12}>
            <TextBody2 color={'textSecondary'}>{orders.label.defaultMessage}</TextBody2>
          </Grid>
          <Grid item>
            <Value>
              {ordersPreorderCount} | {ordersPendingCount} | {ordersShippedCount}
            </Value>
          </Grid>
        </Grid>
      </HeaderBottomLink>
    </div>
  );
};

const Value = styled<any>('span')(({ theme }: IThemed & any) => ({
  lineHeight: '18px',
  fontSize: theme.fontSizes.default
}));

export const Orders = withStyles<any>(stylesTopLinks)(OrdersComponent);
