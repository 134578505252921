import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import Alert from 'components/shared/Alert/Alert';
import { Link2, TextBody2 } from 'components/shared/text';
import { UAH } from 'shared/constants';
import messages from 'translations/cart/common';

import { SplitedPrice } from '../../prices';
import { useBalanceStyles } from './style';

interface INotEnoughBalanceAlertProps {
  className?: string;
  amount?: number;
  addBalance?: (amount?: number) => void;
  withoutMarginTop?: boolean;
}

const NotEnoughBalanceAlert: FC<INotEnoughBalanceAlertProps> = ({
  amount,
  addBalance,
  className,
  withoutMarginTop
}) => {
  const classes = useBalanceStyles();
  return (
    <Alert
      type={'error'}
      icon={'wallet'}
      title={
        <FormattedMessage
          tagName={React.Fragment}
          values={{
            amount,
            price: (...chunks: any[]) => {
              const price = parseFloat(chunks.pop());
              return <SplitedPrice value={price} postfix={UAH} decimalSameSize={true} fontSize={'caption'} />;
            }
          }}
          {...messages.notEnoughFundsTitle}
        />
      }
      className={clsx(classes.outlineMargin, 'alert', className, { 'withoutMarginTop': withoutMarginTop })}
    >
      <TextBody2>
        <FormattedMessage
          tagName={React.Fragment}
          values={{
            url: (...chunks: any[]) => <Link2 onClick={() => addBalance && addBalance(amount)}>{chunks.pop()}</Link2>
          }}
          {...messages.notEnoughFundsText}
        />
      </TextBody2>
    </Alert>
  );
};

export { NotEnoughBalanceAlert };
