import React from "react";
import { withStyles } from "@mui/styles";
import styles from "./styles";
import { Grid } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';

export const DataCardSkeleton: React.FC<any> = ({ columns, classes }) => {
  const cols = +(columns || 1);
  return (
    <Grid item sm>
      <Grid item container sm={12} spacing={2}>
        {new Array(cols).fill("a").map((val, colKey) => (
          <Grid sm item key={colKey}>
            <div className={classes.dataSection}>
              <Skeleton variant="rectangular" width={80} height={14} className={classes.dataSectionLabel} />
              <Skeleton
                variant="rectangular"
                width={"80%"}
                height={12}
                className={classes.dataSectionValue}
                style={{ marginTop: 4 }}
              />
            </div>
          </Grid>
        ))}
      </Grid>
      <Grid item sm={12} container justifyContent={"flex-end"} className={classes.formAction}>
        <Skeleton variant="rectangular" width={162} height={44} />
      </Grid>
    </Grid>
  );
};

export default withStyles<any>(styles)(DataCardSkeleton);
