import React from 'react';
import Popup, { Position } from 'reactjs-popup';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { IApplicationState } from 'store/reducers';
import { getContactsData } from 'store/main/selectors';
import { IMainContactsData } from 'store/main/actions';
import { defaultMaterialTheme } from 'utils/styled';
import { PhonesCard } from 'components/layout/header-main/top/PhonesCard';
import ButtonCommon from 'components/layout/header-main/top/StyledButtonCommon';
import { TextBody2 } from './text';
import { phoneDefault } from 'shared/constants';

interface IOwnProps {
  // component own props
  withPopup?: boolean;
  dark?: boolean;
  triggerContent?: JSX.Element;
  containerClassName?: string;
  position?: Position | Position[];
  onOpen?: () => void;
  onClose?: () => void;
}

interface IStateProps {
  contacts: IMainContactsData | null;
}

interface IDispatchProps {}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const PhoneLink: React.FC<IProps> = ({
  contacts,
  withPopup = false,
  dark,
  triggerContent,
  containerClassName,
  position = 'bottom left',
  onOpen,
  onClose
}) => {
  let phones;
  try {
    if (contacts && contacts.phones && contacts.phones.value) {
      phones = contacts.phones.value.split(',');
    }
  } catch (e) {
    // do something
  }

  let workHours;
  if (contacts && contacts.workHours && contacts.workHours.value) {
    workHours = contacts.workHours.value;
  }

  const mainPhone = phones ? phones[0] : phoneDefault;
  const iconLink = (
    <ButtonCommon dark={dark}>
      {withPopup ? (
        <TextBody2 color={'inherit'}>{mainPhone}</TextBody2>
      ) : (
        <a href={`tel:${mainPhone}`}>
          <TextBody2 color={'inherit'}>{mainPhone}</TextBody2>
        </a>
      )}
    </ButtonCommon>
  );

  return (
    <div className={containerClassName}>
      {withPopup ? (
        <Popup
          on={['click']}
          closeOnDocumentClick
          contentStyle={{
            width: 'fit-content',
            padding: 0,
            borderRadius: 3,
            zIndex: defaultMaterialTheme.zIndex.modal
          }}
          onOpen={onOpen}
          onClose={onClose}
          trigger={() => (
            <ButtonCommon dark={dark}>
              <TextBody2 color={'inherit'}>{triggerContent || mainPhone}</TextBody2>
            </ButtonCommon>
          )}
          position={position as any}
        >
          <PhonesCard phones={phones} workHours={workHours} />
        </Popup>
      ) : (
        iconLink
      )}
    </div>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state: IApplicationState) => ({
  contacts: getContactsData(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch: Dispatch) => ({
  ...bindActionCreators({}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneLink);
