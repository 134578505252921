export class AuthService {
  private storage: Storage;
  constructor() {
    this.storage = window.localStorage;
  }

  get authToken(): string | null {
    return this.storage!.getItem("token")!.toString();
  }

  set authToken(newToken: string | null) {
    if (newToken === null) {
      this.storage.removeItem("token");
      return;
    }
    this.storage.setItem("token", newToken);
  }
}
