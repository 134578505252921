import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { baseUrl } from 'shared/constants';

function getTimeout(url: string) {
  if (process.env.NODE_ENV === 'development') {
    return 60000;
  }
  if (url.includes('catalog')) {
    return 30000;
  }
  return 15000;
}

export class HttpClient {
  private client: AxiosInstance;

  constructor(url: string) {
    this.client = axios.create({
      baseURL: url,
      timeout: getTimeout(url)
    });
    this.setAuthHeader();
  }

  public setAuthHeader() {
    const token = window.localStorage.getItem('token');
    const headers = this.client.defaults.headers;
    if (token) {
      headers.Authorization = token;
    } else {
      delete headers.Authorization;
    }
    Object.assign(this.client.defaults, { headers });
  }

  public get<T>(slug: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.client.get(slug, config).catch(this.handleError);
  }

  public delete<T>(slug: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.client.delete(slug, config).catch(this.handleError);
  }

  public post<T, R>(slug: string, data: T, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.client.post(slug, data, config);
  }

  public put<T, R>(slug: string, data: T, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.client.put(slug, data, config);
  }

  public patch<T, R>(slug: string, data: T, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.client.patch(slug, data, config);
  }

  private handleError(err: any) {
    const errorData = err.response;
    if (errorData && errorData.status === 401) {
      window.localStorage.removeItem('token');
      const path = window.location.pathname.replace(`${baseUrl}`, '');
      if (
        path !== '/login' &&
        path !== '/registration' &&
        path !== '/oferta' &&
        path !== '/delivery' &&
        path !== '/replacement' &&
        path !== '/about-us' &&
        path !== '/' &&
        path !== '' &&
        !path.startsWith('/catalog')
      ) {
        window.location.assign(`${baseUrl}/login`);
      }
    }
    return err;
  }
}
