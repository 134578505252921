import { CSSProperties, FC, ReactNode } from 'react';
import clsx from 'clsx';
import { isMobileDevice } from 'environment';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

import Icon from 'components/shared/Icon';
import { Link2, TextSubTitle } from 'components/shared/text';
import { baseUrl } from 'shared/constants';

import styles from './styles';

interface IProps {
  classes?: any;
  title: string;
  onClose: () => void;
  action?: ReactNode;
  catalogHeader?: boolean;
  accountHeaderHide?: boolean;
  fullWidth?: boolean;
  crossmarkClose?: boolean;
  style?: CSSProperties;
}

const AsideDeviceTopPanel: FC<IProps> = ({
  classes,
  title,
  onClose,
  action,
  catalogHeader,
  accountHeaderHide,
  fullWidth,
  crossmarkClose,
  style
}) => {
  const isCheckout = window.location.pathname.startsWith(`${baseUrl}/checkout`);
  const isMobile = Boolean(isMobileDevice(window.navigator));

  return (
    <div
      className={clsx(classes.root, 'devicePanel', {
        [classes.catalogHeader || '']: !!catalogHeader,
        [classes.hideAccountHeader || '']: accountHeaderHide,
        [classes.fullWidth || '']: fullWidth
      })}
      style={style}
    >
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item xs={2} className={clsx(classes.alignLeft, { [classes.minWidth]: crossmarkClose })}>
          {crossmarkClose ? (
            <Icon type="cross" size={11} onClick={onClose} opacity={1} className={classes.iconClose} />
          ) : (
            <div className={classes.backButton}>
              <Icon
                type="arrowExpand"
                size={9}
                onClick={onClose}
                opacity={1}
                offset={13}
                style={{ transform: 'rotate(90deg)', minWidth: 'auto', marginTop: 4 }}
              />
              <Link2 onClick={onClose}>{isMobile ? 'Назад' : 'Сховати'}</Link2>
            </div>
          )}
        </Grid>
        <Grid item xs>
          <TextSubTitle align="center" className={clsx(classes.title, { [classes.titleCheckout]: isCheckout })}>
            {title}
          </TextSubTitle>
        </Grid>
        <Grid item xs={2} className={classes.alignRight}>
          {action}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles<any>(styles)(AsideDeviceTopPanel);
