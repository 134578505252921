import { SyntheticEvent, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from 'models/product';
import { getPriceType } from 'store/layout/selectors';
import { Skeleton } from 'components/cards/product';
import { watchProductAsync } from 'store/catalog/actions';
import { getUserAccount, getUserProfile } from 'store/account/selectors';
import MobileCard from './MobileCard';
import DesktopCard from './DesktopCard';

interface ICatalogProductCardMemoizedProps {
  key: string;
  style: object;
  index: number;
  productRow: Product[];
  handleSelectProduct: any;
  selectedProductId: string | null;
  withPrice: boolean;
  isMobile: boolean;
  prodsMap?: any;
}

export const CatalogProductCardMemoized = (props: any) => {
  const { item, isSelected, isMobile } = props;
  const dispatch = useDispatch();
  const userProfile = useSelector(getUserProfile);
  const user = useSelector(getUserAccount);
  const priceType = useSelector(getPriceType);
  const isPriceByPackage = priceType === 'package';

  const isWatch = userProfile && userProfile.watchList.includes(item.id);
  const onFavoriteClick = (ev: SyntheticEvent | MouseEvent) => {
    ev.stopPropagation();
    dispatch(watchProductAsync.request({ productId: item.id, watch: !isWatch }));
  };

  return useMemo(
    () =>
      isMobile ? (
        <MobileCard
          onFavoriteClick={onFavoriteClick}
          isWatch={isWatch}
          user={user}
          isPriceByPackage={isPriceByPackage}
          {...props}
        />
      ) : (
        <DesktopCard
          onFavoriteClick={onFavoriteClick}
          isWatch={isWatch}
          isPriceByPackage={isPriceByPackage}
          {...props}
        />
      ),
    // eslint-disable-next-line
    [item, isSelected, isWatch, isPriceByPackage]
  );
};

export const CatalogProductCardContainer = ({
  key,
  style,
  index,
  productRow,
  handleSelectProduct,
  selectedProductId,
  withPrice,
  isMobile,
  prodsMap
}: ICatalogProductCardMemoizedProps) =>
  useMemo(
    () => (
      <div key={key} style={style}>
        {productRow &&
          productRow.map(product => {
            return prodsMap.has(product.id) ? (
              <CatalogProductCardMemoized
                key={`product-card-${index}-${product.id}`}
                item={prodsMap.get(product.id)}
                onClick={handleSelectProduct}
                isSelected={selectedProductId === product.id}
                withPrice={withPrice}
                isMobile={isMobile}
              />
            ) : (
              <Skeleton key={`product-card-${index}-${product.id}`} className={'catalog-card'} />
            );
          })}
      </div>
    ),
    // eslint-disable-next-line
    [productRow, selectedProductId, prodsMap]
  );
