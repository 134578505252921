import React from 'react';
import { withStyles } from '@mui/styles';
import styles from './styles';

const DetailPanel = ({ classes, children: ChildComponent, ...rest }: any) => (
  <div className={classes.detailPanelWrapper}>
    <ChildComponent {...rest} />
  </div>
);

export const DetailPanelWrapper = withStyles<any>(styles)(DetailPanel);
