import clsx from 'clsx';
import { IconButton, Link } from '@mui/material';
import { withStyles } from '@mui/styles';

import Icon from 'components/shared/Icon';

import styles from './SocialLinks.styles';

export interface ISocialIcon {
  icon: string;
  title: string;
  link: string;
  disabled?: boolean;
}
const chatId = 'u816689430-1596692233vid50ce5bac-86f3-4129-a85f-f7cf6c85edbb';
const socialIconsDefault: ISocialIcon[] = [
  { icon: 'viber', title: 'Viber', link: `viber://pa?chatURI=floroteka&context=${chatId}` },
  { icon: 'telegram', title: 'Telegram', link: 'https://t.me/@FlorotekaBot' },
  { icon: 'call', title: 'WhatsApp', link: 'whatsapp://send?phone=+120345678910', disabled: true }
];

interface IProps {
  icons?: ISocialIcon[];
  withTitle?: boolean;
  scale?: number;
  dark?: boolean;
  containerWidth?: string;
  classes?: any;
  classNameWrapper?: string;
}

const SocialLinks = ({
  icons = socialIconsDefault,
  withTitle = false,
  scale = 1,
  containerWidth = 'auto',
  dark,
  classes,
  classNameWrapper
}: IProps) => (
  <div className={classNameWrapper}>
    {icons.map((item, index) => (
      <IconButton
        className={clsx(classes.socialIconButton, {
          [classes.socialIconButtonDark]: dark
        })}
        key={`social${index}`}
        component={Link}
        href={item.link}
        disabled={item.disabled}
        size="large"
      >
        <Icon type={item.icon} size={24} opacity={dark ? 1 : 0.8} />
        {withTitle && <span style={{ marginTop: 8 }}>{item.title.toUpperCase()}</span>}
      </IconButton>
    ))}
  </div>
);

export default withStyles<any>(styles)(SocialLinks);
