import clsx from 'clsx';
import { FC, SetStateAction, useEffect, Dispatch } from 'react';
import { Events, Link as ScrollLink, scrollSpy } from 'react-scroll';
import { upperFirst } from 'lodash';

import { CartGroup, CartItem } from 'models';
import { TextHelper, TextSubTitle } from 'components/shared/text';
import { defaultMaterialTheme } from 'utils/styled';

import { getMinMaxPreOrderDates } from 'utils/time';

import classes from './CartLeftSideItem.module.scss';

interface IProps {
  groupKey: string;
  group: CartGroup;
  index: number;
  setActiveElement?: (index: number) => void;
  anchor: string;
  anchorOffset?: number;
  preOrder?: boolean;
  activeGroup: string;
  setActiveGroup: Dispatch<SetStateAction<string>>;
}

const CartLeftSideItem: FC<IProps> = ({
  groupKey,
  group,
  anchor,
  anchorOffset,
  setActiveGroup,
  preOrder,
  activeGroup
}) => {
  const deliveryOptions: any[] = getMinMaxPreOrderDates(
    group.items.map((i: CartItem) => i.product),
    preOrder
  );
  useEffect(() => {
    Events.scrollEvent.register('begin', (to: string, element: HTMLElement | null) => {
      element?.scrollIntoView();
      setActiveGroup(to);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ScrollLink
      className={clsx(classes.leftSideItemAnchor, { [classes.active]: activeGroup === `${anchor}-${groupKey}` })}
      to={`${anchor}-${groupKey}`}
      containerId={`${anchor}-container-inner`}
      spy={true}
      smooth={true}
      isDynamic={true}
      duration={defaultMaterialTheme.transitions.duration.shorter}
      offset={anchorOffset}
    >
      <div className={classes.wrapper}>
        <TextSubTitle color="inherit" align={'left'} className={classes.warehouseName}>
          {group.preOrder ? 'Флоротека' : group.warehouse.displayName}
        </TextSubTitle>
        <TextHelper className={classes.deliveryDate}>
          {upperFirst(deliveryOptions[0] ? deliveryOptions[0].format('dd DD.MM') : '')}
          {' - '}
          {preOrder ? '∞' : upperFirst(deliveryOptions[1] ? deliveryOptions[1].format('dd DD.MM') : '')}
        </TextHelper>
      </div>
    </ScrollLink>
  );
};

export default CartLeftSideItem;
