import { palette } from './theme/default';
import { fadeSize } from 'shared/constants';

const scrollBarWidth = 2;

export const scrollBar: any = {
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0,0,0,0.25) transparent',

  '&::-webkit-scrollbar': {
    width: scrollBarWidth,
    height: scrollBarWidth
  },

  '&::-webkit-scrollbar-track': {
    borderRadius: 10,
    backgroundColor: 'transparent'
  },

  '&::-webkit-scrollbar-thumb': {
    minHeight: scrollBarWidth * 10,
    borderRadius: 2,
    backgroundColor: 'rgba(0,0,0,0.15)'
  }
};

export const scrollBarV5: any = {
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0,0,0,0.25) transparent',

  '&::-webkit-scrollbar': {
    width: scrollBarWidth,
    height: scrollBarWidth
  },

  '&::-webkit-scrollbar-track': {
    borderRadius: 10,
    backgroundColor: 'transparent'
  },

  '&::-webkit-scrollbar-thumb': {
    minHeight: scrollBarWidth * 10,
    borderRadius: 2,
    backgroundColor: 'rgba(0,0,0,0.15)'
  }
};

export const scrollBarMaterial = {
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0,0,0,0.25) transparent',
  transition: 'all 1s ease-in-out',
  '&::-webkit-scrollbar': {
    width: scrollBarWidth,
    height: scrollBarWidth,
    transition: 'all 1s ease-in-out'
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: 10,
    backgroundColor: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    minHeight: scrollBarWidth * 10,
    borderRadius: 2,
    backgroundColor: 'rgba(0,0,0,0.15)',
    transition: 'all 1s ease-in-out'
  },
  '&.no-scroll': {
    scrollbarColor: 'transparent transparent',
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent'
    }
  }
};

export const slickDots = {
  '& .slick-dots': {
    bottom: 25
  },

  '& .slick-dots li.slick-active button:before': {
    color: palette.primary.main,
    fontSize: 16,
    opacity: 1
  },
  '& .slick-dots li button:before': {
    top: 8,
    fontSize: 8,
    opacity: 1,
    color: palette.common.helperBlack
  }
};

export const textOverflow: any = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
};

export const textOverflow2Lines: any = {
  wordBreak: 'break-word',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden'
};

export const contentFade: any = {
  '-webkit-mask-image': `-webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(255,255,255,1) ${fadeSize}px, rgba(255,255,255,1) calc(100% - ${fadeSize}px), rgba(0,0,0,0) 100%)`
};

export const contentFadeMaterial = {
  '-webkit-mask-image': `-webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(255,255,255,1) ${fadeSize}px, rgba(255,255,255,1) calc(100% - ${fadeSize}px), rgba(0,0,0,0) 100%);`
};
