import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import FlrCheckboxWithLabel from 'components/shared/form-elements/FlrCheckbox';
import FlrSelect2 from 'components/shared/form-elements/select2/FlrSelect2';
import FlrDialogTitle from 'components/shared/modals/components/DialogTitle';
import { IRegionData, ISupplierData } from 'models';
import { IFiltersData } from 'store/catalog/actions';
import { filtersMessages } from 'translations/catalog/filters';

import classes from './DeliveryModal.module.scss';

export interface IDeliveryState {
  deliveryRegion: string;
  supplier: string;
  doNotShowDeliveryModal: boolean;
}

interface IProps {
  filters: IFiltersData;
  regions: IRegionData[];
  suppliers: ISupplierData[];
  setRegionAndSupplier: (data: IDeliveryState) => void;
  selectedDeliveryRegion?: string;
  showDeliveryModal?: boolean;
}

const DeliveryModal: FC<IProps> = ({
  filters,
  regions,
  suppliers,
  selectedDeliveryRegion,
  showDeliveryModal,
  setRegionAndSupplier
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deliveryData, setDeliveryData] = useState<IDeliveryState>({
    deliveryRegion: '',
    supplier: '',
    doNotShowDeliveryModal: false
  });

  useEffect(() => {
    if (regions?.length && !selectedDeliveryRegion && filters.productType) {
      if (showDeliveryModal) {
        setIsOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regions, filters.productType]);

  const selectedRegion = regions.find((r) => r.alias === deliveryData.deliveryRegion);

  const regionOptions = regions.map((region) => ({
    label: region.name,
    value: region.alias
  }));
  const supplierOptions = suppliers
    .filter((s) => selectedRegion?.suppliers.includes(s.value))
    .map((s) => ({
      label: s.label,
      value: s.value
    }));

  const handleDeliveryChange = (type: string) => (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target?.value) {
      setDeliveryData({ ...deliveryData, [type]: event.target?.value || '' });
    }
  };

  const handleCheck = (checked: boolean) => {
    setDeliveryData({ ...deliveryData, doNotShowDeliveryModal: checked });
  };

  const applyDelivery = () => {
    setRegionAndSupplier(deliveryData);
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="body"
      classes={{
        paperScrollBody: classes.dialogContainer
      }}
    >
      <FlrDialogTitle text={filtersMessages.deliveryModalTitle} classNameTitle={classes.dialogTitle} />
      <DialogContent className={classes.dialogContent}>
        <FlrSelect2
          label={filtersMessages.deliveryFrom.defaultMessage}
          value={deliveryData.deliveryRegion}
          onChange={handleDeliveryChange('deliveryRegion')}
          options={regionOptions}
          qtySelect
        />
        <FlrSelect2
          className={classes.selectRoot}
          label={filtersMessages.supplier.defaultMessage}
          value={deliveryData.supplier}
          onChange={handleDeliveryChange('supplier')}
          options={supplierOptions}
        />
        <FlrCheckboxWithLabel
          classNameLabel={classes.checkbox}
          checked={deliveryData.doNotShowDeliveryModal}
          label="Не показувати більше"
          onChange={(event: any, checked: boolean) => handleCheck(checked)}
        />
      </DialogContent>
      <DialogActions className={classes.modalActions}>
        {/* <Button variant="outlined" color="primary" onClick={onCloseModal}>
          {filtersMessages.deliveryModalCancel.defaultMessage}
        </Button> */}
        <Button
          disabled={!deliveryData.deliveryRegion || !deliveryData.supplier}
          variant="contained"
          color="primary"
          onClick={applyDelivery}
        >
          {filtersMessages.deliveryModalApply.defaultMessage}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DeliveryModal };
