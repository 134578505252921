import * as React from 'react';
import styled from '@emotion/styled/macro';
import { IThemed } from '../../../utils/styled';
import { Grid } from '@mui/material';

const Root: React.FC = ({ children }) => <Wrapper>{children}</Wrapper>;

const Wrapper = styled<any>(Grid)(({ theme }: IThemed) => ({
  backgroundColor: theme.colors.background,
  color: theme.colors.brandSecondary,
  fontFamily: theme.fonts.body,
  fontSize: theme.fontSizes.default
})) as any;

// const Wrapper = styled<any>(Grid)`
//   background-color: ${(props: IThemed) => props.theme.colors.background};
//   color: ${(props: IThemed) => props.theme.colors.brandSecondary};
//   font-family: ${(props: IThemed) => props.theme.fonts.body};
//   font-size: ${(props: IThemed) => props.theme.fontSizes.default};
// `;

export default Root;
