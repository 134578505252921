import { ITheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  noVerticalMargin: {
    marginBottom: 0,
    marginTop: 0
  },
  timerBlock: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.info.main,
    '&.expired': {
      color: theme.palette.text.disabled
    }
  }
});

export default styles;
