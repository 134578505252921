import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { IThemed } from 'utils/styled';
import { FlrInput } from 'components/shared/form-elements/textfield';
import Icon from 'components/shared/Icon';
import { scrollBarMaterial } from 'styles/mixins';
import { devicePanelHeight } from 'shared/constants';

const styles = (theme: any) => ({
  root: {
    ...scrollBarMaterial,

    '& .final-h4': {
      textAlign: 'center',
      fontSize: 14,
      fontWeight: 700
    }
  },
  wrapper: {
    maxWidth: 1138,
    margin: '0 auto',
    marginTop: -57, // top bar
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap'
    }
  },
  LogoWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    zIndex: 1,

    [theme.breakpoints.down('lg')]: {
      marginTop: 120,
      marginBottom: 40,
      alignItems: 'center'
    }
  },
  LogoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  headerMobile: {
    position: 'fixed',
    width: '100%',
    zIndex: theme.zIndex.drawer
  },
  bodyMobile: {
    position: 'absolute',
    top: devicePanelHeight,
    bottom: 0,
    padding: 0,
    display: 'block',
    width: '100%',
    margin: 0,

    ...scrollBarMaterial,

    '& .MuiPaper-rounded': {
      borderRadius: 0
      // width: '100%',
      // boxShadow: 'none',
      // padding: 25
    }
  },
  authTopBar: {
    position: 'relative',
    zIndex: 10,
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),

    '& .button-reg': {
      marginRight: theme.spacing(1)
    }
  },
  headerSingle: {
    marginBottom: theme.spacing(5)
  },
  header: {
    color: theme.palette.common.black
  },
  headerCaption: {
    marginBottom: theme.spacing(3),
    color: theme.palette.text.secondary
  },
  headerCaptionRegistration: {
    color: theme.palette.text.secondary
  },
  commonControl: {
    marginBottom: theme.spacing(3)
  },
  loginControl: {
    marginBottom: theme.spacing(3)
  },
  checkboxControl: {},
  checkboxLabelControl: {
    '&&': {
      marginTop: theme.spacing(3),
      '& .MuiFormControlLabel-label': {
        fontSize: 14,
        lineHeight: 1,
        color: theme.palette.common.black
      }
    }
  },
  formInputAuthId: {
    marginBottom: theme.spacing(3)
  },
  formInputPassword: {
    marginBottom: theme.spacing(1)
  },
  formInputCheckbox: {
    '& + .MuiTypography-body1': {
      color: theme.palette.common.black
    }
  },
  logoCaption: {
    marginTop: theme.spacing(3),
    width: 320,
    lineHeight: 1.6,
    color: theme.palette.common.white,
    [(theme as any).breakpoints.down('xl')]: {
      width: 'auto'
    }
  },
  underFormBlock: {
    width: 464,
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [(theme as any).breakpoints.down('md')]: {
      width: '100%'
    }
  },
  bottomCaption: {
    marginRight: theme.spacing(3),
    fontSize: 14,
    color: theme.palette.common.white
  },
  bottomLink: {
    marginTop: -4,
    '& a[href]': {
      color: `${theme.palette.common.white} !important`,
      borderColor: `${theme.palette.common.white} !important`,
      fontSize: 14,

      '&:hover': {
        opacity: 0.9
      },
      '&:active': {
        opacity: 0.7
      }
    }
  }
});

// TODO remove this part when implemented login with socials
export const SocialLoginSection = styled<any>('section')(() => ({
  filter: 'grayscale()',
  position: 'relative',
  ':before': {
    position: 'absolute',
    display: 'block',
    zIndex: 2,
    content: "''",
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  }
}));

export const SocialButtons = styled<any>('div')({
  display: 'grid',
  gridGap: 10,
  gridTemplate: '"1fr 1fr"'
});

export const SocialLoginSectionHeading = styled<any>('div')(({ theme }: IThemed) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,

  '& > span': {
    whiteSpace: 'nowrap',
    margin: 24
  },

  ':before, :after': {
    display: 'block',
    content: "''",
    height: 1,
    background: theme.palette.common.disabledBlackBackground,
    width: '100%'
  }
}));

export const FormLink = styled<any>(NavLink)(({ theme }: IThemed) => ({
  marginLeft: 8,
  color: theme.palette.primary.main
}));

export const NewCustomerMessage = styled<any>('p')(({ theme }: IThemed) => ({
  color: theme.colors.gray
}));

export const FormInput = styled<any>(FlrInput)({
  '&.MuiFormControl-root': {
    marginTop: 20
  }
});

export const Visibility = styled<any>(Icon)(({ theme }: IThemed) => ({
  color: theme.colors.brandSecondary,
  opacity: 0.35
}));

export default styles;
