export class Location {
  public approved: boolean;
  public deliveryPricePerCity: number;
  public groupedLocation: boolean;
  public id: string;
  public isCoveredByDeliveryNetwork: boolean;
  public isDeliveryFree: boolean;

  constructor(location: any) {
    this.approved = location.approved;
    this.deliveryPricePerCity = location.deliveryPricePerCity;
    this.groupedLocation = location.groupedLocation;
    this.id = location.id;
    this.isCoveredByDeliveryNetwork = location.isCoveredByDeliveryNetwork;
    this.isDeliveryFree = location.isDeliveryFree;
  }
}