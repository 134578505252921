import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Events, Link as ScrollLink, scrollSpy } from 'react-scroll';
import clsx from 'clsx';

import { SplitedPrice } from 'components/prices';
import { TextBody2, TextFounds, TextSubTitle } from 'components/shared/text';
import { Order, ORDER_STATUS_PENDING_APPROVAL, ORDER_STATUS_PREORDER_PENDING_APPROVAL } from 'models';
import messages from 'translations/checkout/common';
import { defaultMaterialTheme } from 'utils/styled';

import classes from './CheckoutRightSideItem.module.scss';

interface IProps {
  allOrdersCount: number;
  order: Order;
  index: number;
  setActiveElement?: (index: number) => void;
  anchor: string;
  anchorOffset?: number;
  activeGroup: string;
  groupKey: string;
  setActiveGroup: Dispatch<SetStateAction<string>>;
}

const CheckoutRightSideItem: FC<IProps> = ({
  allOrdersCount,
  order,
  index,
  anchor,
  anchorOffset = 0,
  setActiveElement,
  activeGroup,
  groupKey,
  setActiveGroup
}) => {
  const isLast = allOrdersCount === index;
  const isFirst = index === 1;
  const isFinalized = [ORDER_STATUS_PREORDER_PENDING_APPROVAL, ORDER_STATUS_PENDING_APPROVAL].includes(
    order.orderStatus
  );
  const onSetActive = () => setActiveElement && setActiveElement(index);
  useEffect(() => {
    Events.scrollEvent.register('begin', (to: string, element: HTMLElement | null) => {
      element?.scrollIntoView();
      setActiveGroup(to);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
    };
    // eslint-disable-next-line
  }, []);
  return (
    <ScrollLink
      className={clsx(classes.rightSideItemAnchor, { [classes.active]: activeGroup === `${anchor}-${groupKey}` })}
      to={`${anchor}-${order.id}`}
      containerId={`${anchor}-container-inner`}
      spy={true}
      smooth={true}
      isDynamic={true}
      duration={defaultMaterialTheme.transitions.duration.shorter}
      offset={anchorOffset}
    >
      <div
        className={clsx(classes.container, {
          last: isLast,
          first: isFirst
        })}
      >
        <div onClick={onSetActive} className={classes.content}>
          <div>
            <TextSubTitle className={classes.outletName}>{order.outlet && order.outlet.name}</TextSubTitle>
            <TextBody2>
              {messages.delivery.defaultMessage} - {index}
            </TextBody2>
          </div>
          <div className={classes.deliveryWrapper}>
            <SplitedPrice
              value={order.totalQty}
              bold={false}
              fontSize={'body2'}
              hideDecimal={true}
              postfix={'шт'}
              className={classes.totalQty}
            />
            {!isFinalized ? (
              <SplitedPrice value={order.totalSum} fontColor={'textSecondary'} fontSize={'h3'} fontSizeDecimal={'h5'} />
            ) : (
              <TextFounds className={classes.fundsPositive}>Оплачено</TextFounds>
            )}
          </div>
        </div>
      </div>
    </ScrollLink>
  );
};

export default CheckoutRightSideItem;
