import { defineMessages } from 'react-intl';

export default defineMessages({
  found: {
    id: 'catalog.common.found',
    description: 'catalog.common.found',
    defaultMessage: 'Знайдено'
  },
  inStock: {
    id: 'catalog.common.inStock',
    description: 'catalog.common.inStock',
    defaultMessage: 'Усі в наявності'
  },
  specialOffers: {
    id: 'catalog.common.specialOffers',
    description: 'catalog.common.specialOffers',
    defaultMessage: 'Акційні в наявності'
  },
  transitOffers: {
    id: 'catalog.common.transitOffers',
    description: 'catalog.common.transitOffers',
    defaultMessage: 'В дорозі'
  },
  specialTransitOffers: {
    id: 'catalog.common.specialTransitOffers',
    description: 'catalog.common.specialTransitOffers',
    defaultMessage: 'Акційні в дорозі'
  },
  preOrder: {
    id: 'catalog.common.preOrder',
    description: 'catalog.common.preOrder',
    defaultMessage: 'Під замовлення'
  }
});
