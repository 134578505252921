import { IDeliveryLocation } from './order';
import { Location } from './location';

export interface IMethod {
  alias: string;
  description: string;
  id: string;
  isSynced: false;
  name: string;
  code: string;
}

interface IShippingData {
  days: any[];
  methods: IMethod[];
}

export class Outlet implements IDeliveryLocation {
  public id: string;
  public code: string;
  public region: string;
  public city: string;
  public street: string;
  public building: string;
  public unit?: string;
  public room?: string;
  public phone: string;
  public name: string;
  public shippingData: IShippingData;
  public location: Location | undefined;
  public latitude: string;
  public longitude: string;
  public isDefault: boolean;
  public customAddress: boolean;
  public systemOutlet: boolean;

  constructor(outlet: any) {
    this.id = outlet.id;
    this.code = outlet.outletId;
    this.region = outlet.region;
    this.city = outlet.city;
    this.street = outlet.street;
    this.building = outlet.building;
    this.unit = outlet.unit;
    this.room = outlet.room;
    this.phone = outlet.phone;
    this.name = outlet.name;
    this.isDefault = outlet.isDefault;
    this.systemOutlet = outlet.systemOutlet;
    this.customAddress = Boolean(outlet.customAddress); // todo remove Boolean wrapper after that backend will save this field
    this.shippingData = outlet.shippingData;
    this.latitude = outlet.latitude || outlet.lat;
    this.longitude = outlet.longitude || outlet.lng;
    this.location = outlet.location ? new Location(outlet.location) : undefined;
  }

  get address(): string {
    return `${this.city ? this.city + ', ' : ''}${this.street || `${this.latitude}, ${this.longitude}`} ${this
      .building || ''}${this.unit ? `-${this.unit}` : ''}${this.room ? `-${this.room}` : ''}`;
  }

  get label(): string {
    return this.address;
  }
}
