import React, { useMemo } from 'react';
import clsx from 'classnames';

import Icon from 'components/shared/Icon';
import { TextBody2, TextSubTitle } from 'components/shared/text';
import Tooltip from 'components/shared/tooltip';

interface IProps {
  details: any;
  discount?: number;
  children?: any;
  className?: string;
  classes?: any;
  specialPriceDiscount?: number;
}

type ICardDiscountIcon = (props: IProps) => JSX.Element | null;

const CardDiscountIcon: ICardDiscountIcon = ({
  discount,
  details,
  children,
  className,
  classes,
  specialPriceDiscount
}) =>
  useMemo(() => {
    const DiscountDescription = () => {
      return (
        <>
          {details?.length > 0 ? (
            details.map((d: any, idx: any) => (
              <TextBody2 key={idx} color={'inherit'} style={{ color: 'white', whiteSpace: 'normal' }}>
                {d.percent}%&nbsp;-&nbsp;{d.name}
                <br />
              </TextBody2>
            ))
          ) : null}
          {specialPriceDiscount ? (
            <TextBody2 color={'inherit'} style={{ color: 'white', whiteSpace: 'normal' }}>
              До&nbsp;{specialPriceDiscount}%&nbsp;-&nbsp;Акційні в наявності
              <br />
            </TextBody2>
          ) : null}
        </>
      );
    };
    return !!discount ? (
      <Tooltip
        classes={classes}
        icon={<Icon type={'loyalty'} size={24} offset={8} opacity={1} />}
        title={<DiscountDescription />}
      >
        <div className={clsx('discount', className)}>{children || <TextSubTitle>-{discount}%</TextSubTitle>}</div>
      </Tooltip>
    ) : null;
  }, [discount, details, children, className, classes, specialPriceDiscount]);

export { CardDiscountIcon };
