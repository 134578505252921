import { IRequestCartItem, Order } from 'models';
import { HttpClient } from 'utils/http';
import { cartGroupCheckoutStorageKey } from 'shared/constants';

export class CheckoutRepository {
  private client = new HttpClient(`${process.env.REACT_APP_API_ENDPOINT}/sales/checkout`);

  // checkout requests
  public async fetch() {
    const cartGroup = window.localStorage.getItem(cartGroupCheckoutStorageKey);
    return await this.client.get<Order[]>(`/${cartGroup}`).then(orders => orders.data);
  }

  public async updateOrders(orders: Order[]) {
    const requestOrders = orders.map(order => order.toRequest());
    const cartGroup = window.localStorage.getItem(cartGroupCheckoutStorageKey);
    return await this.client.post(`/${cartGroup}`, requestOrders).then(updatedOrders => updatedOrders.data);
  }

  public async finalizeOrder(order: Order) {
    const cartGroup = window.localStorage.getItem(cartGroupCheckoutStorageKey);
    return await this.client
      .post(`/${cartGroup}/finalize/${order.id}`, { token: order.token })
      .then(updatedOrder => updatedOrder.data);
  }

  public async finalizeAllOrders(orderToken: string) {
    const cartGroup = window.localStorage.getItem(cartGroupCheckoutStorageKey);
    return await this.client.post(`/${cartGroup}/finalize`, { token: orderToken }).then(res => {
      window.localStorage.removeItem(cartGroupCheckoutStorageKey);
      return res;
    });
  }

  public async renew(newCartGroup: string) {
    const cartGroup = window.localStorage.getItem(cartGroupCheckoutStorageKey);
    window.localStorage.setItem(cartGroupCheckoutStorageKey, newCartGroup);
    if (cartGroup) {
      return await this.client.delete(`/${cartGroup}`);
    } else {
      return await this.client.delete(`/all`);
    }
  }

  public async finalizeTransit({ orderId, offer, qty }: IRequestCartItem) {
    return await this.client
      .post(`/finalize-transit/${orderId}`, { offerId: offer, qty })
      .then(response => response.data);
  }
}
