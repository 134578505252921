import React, { useEffect } from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Cart } from 'models';
import { cartGroupCheckoutStorageKey } from 'shared/constants';
import { holdCartAsync } from 'store/cart/actions';

interface IProps {
  holdCart: typeof holdCartAsync.request;
}
interface IOwnProps {
  cart?: Cart;
}

function getGroupType(group: any) {
  if (group.transit) { return 'transit'; }
  if (group.preOrder) { return 'preorder'; }
  return 'stock';
}

const UnconnectedHoldCart: React.FC<IProps & IOwnProps> = ({ holdCart, cart }) => {
  const checkoutGroup = window.localStorage.getItem(cartGroupCheckoutStorageKey);

  useEffect(() => {
    if (checkoutGroup) {
      const cartGroup = cart?.groups[checkoutGroup] || {};
      const groupType = getGroupType(cartGroup);
      if (groupType !== 'preorder') {
        holdCart({ groupType, cartGroupId: checkoutGroup });
        const timerHoldInterval = setInterval(() => holdCart({ groupType, cartGroupId: checkoutGroup }), 5000);
        return () => {
          clearInterval(timerHoldInterval);
        };
      }
    }
    // eslint-disable-next-line
  }, [holdCart, checkoutGroup]);

  return null;
};

const mapDispatchToProps: MapDispatchToProps<IProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      holdCart: holdCartAsync.request
    },
    dispatch
  )
});

export default connect(null, mapDispatchToProps)(UnconnectedHoldCart);
