import { call, put, takeEvery } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { createAsyncAction, createReducer, PayloadAction } from 'typesafe-actions';

import { Order } from 'models/order';
import { IOrderReaction, OrderReaction } from 'models/orderReaction';
import {
  errorDataWrapper,
  IAsyncDataWrapper,
  loadedDataWrapper,
  loadingDataWrapper,
  REQUEST_ACTIONS
} from 'store/actions';

import { baseUrl } from '../../shared/constants';
import { enqueueSnackbarError } from '../layout';
import { OrderRepository } from './request';

const httpClient = new OrderRepository();

export const prefix = '@@order/';
export const prefixGet = '@@order/get/';
export const prefixGetReaction = '@@order/get/reaction/';

export const ORDER_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const ORDER_REQUEST_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const ORDER_REQUEST_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

export const ORDER_GET_REQUEST = `${prefixGet}${REQUEST_ACTIONS.REQUEST}`;
export const ORDER_GET_REQUEST_SUCCESS = `${prefixGet}${REQUEST_ACTIONS.SUCCESS}`;
export const ORDER_GET_REQUEST_FAILURE = `${prefixGet}${REQUEST_ACTIONS.FAILURE}`;

export const ORDER_REACTION_GET_REQUEST = `${prefixGetReaction}${REQUEST_ACTIONS.REQUEST}`;
export const ORDER_REACTION_GET_SUCCESS = `${prefixGetReaction}${REQUEST_ACTIONS.SUCCESS}`;
export const ORDER_REACTION_GET_FAILURE = `${prefixGetReaction}${REQUEST_ACTIONS.FAILURE}`;

export interface IOrderStateSync {
  docs: Order[];
  totalDocs: number;
  selectedOrder: Order | null;
  orderReaction: OrderReaction | null;
  filters: {
    orderType: IOrderTypeFilter[];
    orderStatus: IOrderStatusFilter[];
  };
}

export interface IOrderTypeFilter {
  name: string;
  alias: string;
  itemsQty: number;
}

export interface IOrderStatusFilter {
  name: string;
  alias: string;
  itemsQty: number;
}


export type IOrdersState = IAsyncDataWrapper<IOrderStateSync>;

export const ordersInitialState: IOrdersState = {
  loading: false,
  loaded: false,
  data: {
    docs: [],
    totalDocs: 0,
    selectedOrder: null,
    orderReaction: null,
    filters: {
      orderType: [],
      orderStatus: []
    }
  },
  error: null
};

type OrderActionTypes = typeof ORDER_REQUEST | typeof ORDER_REQUEST_SUCCESS | typeof ORDER_REQUEST_FAILURE;

export const fetchOrderListAsync = createAsyncAction(ORDER_REQUEST, ORDER_REQUEST_SUCCESS, ORDER_REQUEST_FAILURE)<
  string,
  Order[],
  Error
>();

export const createOrderAsync = createAsyncAction(ORDER_REQUEST, ORDER_REQUEST_SUCCESS, ORDER_REQUEST_FAILURE)<
  Order,
  Order,
  Error
>();

export const getOrderAsync = createAsyncAction(ORDER_GET_REQUEST, ORDER_GET_REQUEST_SUCCESS, ORDER_GET_REQUEST_FAILURE)<
  string,
  Order,
  Error
>();

export const getOrderReactionAsync = createAsyncAction(
  ORDER_REACTION_GET_REQUEST,
  ORDER_REACTION_GET_SUCCESS,
  ORDER_REACTION_GET_FAILURE
)<string, OrderReaction | null, Error>();

export function* orderSaga(action: ReturnType<typeof fetchOrderListAsync.request>): Generator {
  try {
    // TODO: add special query for checkout finalized, ?status=all&type=all
    const response: any = yield call(() => httpClient.fetch(action.payload));

    yield put(createOrderAsync.success(response));
  } catch (err) {
    yield put(createOrderAsync.failure(err as Error));
  }
}

// function* ordersListSaga(action: ReturnType<typeof createOrderAsync.request>): Generator {
//   try {
//     const response: any = yield call(() => httpClient.fetch());
//     const allStatuses = [
//       ORDER_STATUS_PREORDER,
//       ORDER_STATUS_PREORDER_PENDING_APPROVAL,
//       ORDER_STATUS_NEW,
//       ORDER_STATUS_PENDING_APPROVAL,
//       // ORDER_STATUS_APPROVED,
//       ORDER_STATUS_RESERVED,
//       ORDER_STATUS_PACKED,
//       ORDER_STATUS_SHIPPED,
//       ORDER_STATUS_DELIVERED,
//       ORDER_STATUS_DONE,
//       ORDER_STATUS_CANCELED
//     ];
//
//     const fakeData = [...Array(0).fill(response[0])].map((item: Order) => {
//       const randomVal = Math.random();
//       const randomStatus = Math.floor(randomVal * allStatuses.length);
//       const newItem = { ...item };
//       newItem.id = Math.round(randomVal * 10000000).toString();
//       newItem.orderStatus = allStatuses[randomStatus] as OrderStatus;
//       newItem.orderId = newItem.id;
//       return newItem as Order;
//     });
//     yield put(fetchOrderListAsync.success(fakeData.length > 0 ? fakeData : response));
//   } catch (err) {
//     yield put(fetchOrderListAsync.failure(err as Error));
//   }
// }

function* orderGetSaga(action: ReturnType<typeof getOrderAsync.request>): Generator {
  try {
    const resp: any = yield call(() => httpClient.get(action.payload));
    if (resp && resp.response && resp.response.status === 404) {
      yield put(enqueueSnackbarError({ message: 'Замовлення не знайдено!' }));
      yield put(replace(`${baseUrl}/account/orders`));
    } else if (resp && resp.data) {
      yield put(getOrderAsync.success(new Order(resp.data)));
    }
  } catch (err) {
    console.error(err);
    yield put(getOrderAsync.failure(err as Error));
  }
}

function* orderReactionGetSaga(action: ReturnType<typeof getOrderReactionAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.getReaction(action.payload));

    yield put(getOrderReactionAsync.success(response ? new OrderReaction(response) : null));
  } catch (err) {
    yield put(getOrderReactionAsync.failure(err as Error));
  }
}

// function* orderReactionPostSaga(action: ReturnType<typeof putOrderAsync.request>): Generator {
//   try {
//     const response: any = yield call(() => httpClient.get(action.payload));
//     yield put(putOrderAsync.success(new Order(response)));
//   } catch (err) {
//     yield put(putOrderAsync.failure(err));
//   }
// }

export function* orderRequestSaga() {
  yield takeEvery(fetchOrderListAsync.request, orderSaga);
  // yield takeEvery(createOrderAsync.request, ordersListSaga);
  yield takeEvery(getOrderAsync.request, orderGetSaga);
  yield takeEvery(getOrderReactionAsync.request, orderReactionGetSaga);
}

export default createReducer({})
  .handleAction(fetchOrderListAsync.request, (state: IOrdersState) => loadingDataWrapper(state.data))
  .handleAction(
    fetchOrderListAsync.success,
    (state: IOrdersState, action: PayloadAction<typeof ORDER_REQUEST_SUCCESS, IOrderStateSync>) =>
      loadedDataWrapper({
        ...state.data,
        docs: action.payload.docs.map((order: any) => new Order(order)),
        totalDocs: action.payload.totalDocs,
        filters: action.payload.filters,
      })
  )
  .handleAction(fetchOrderListAsync.failure, (state: IOrdersState) =>
    errorDataWrapper(state.data, new Error('Failed to load address list'))
  )
  .handleAction(getOrderAsync.success, (state: IOrdersState, action: PayloadAction<OrderActionTypes, Order>) =>
    loadedDataWrapper({
      ...state.data,
      selectedOrder: action.payload
    })
  )
  .handleAction(getOrderAsync.failure, (state: IOrdersState) =>
    errorDataWrapper(state.data, new Error('Failed to load address list'))
  )
  .handleAction(
    getOrderReactionAsync.request,
    (state: IOrdersState, action: PayloadAction<typeof ORDER_REACTION_GET_REQUEST, IOrderReaction>) =>
      loadingDataWrapper({
        ...state.data,
        orderReaction: null
      })
  )
  .handleAction(
    getOrderReactionAsync.success,
    (state: IOrdersState, action: PayloadAction<typeof ORDER_REACTION_GET_SUCCESS, IOrderReaction>) =>
      loadedDataWrapper({
        ...state.data,
        orderReaction: action.payload
      })
  )
  .handleAction(getOrderReactionAsync.failure, (state: IOrdersState) =>
    errorDataWrapper(state.data, new Error('Failed to load address list'))
  );
