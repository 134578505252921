import * as React from 'react';
import FlrButton, { IButtonProps } from './FlrButton';
import { withTheme } from '@mui/styles';

class FlrButtonKhaki extends React.Component<IButtonProps> {
  public static defaultProps = {
    variant: 'contained',
    color: 'primary'
  };

  public render(): React.ReactNode {
    const { children, ...rest } = this.props;
    return <FlrButton {...rest}>{children}</FlrButton>;
  }
}

export default withTheme(FlrButtonKhaki);
