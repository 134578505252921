import moment, { Moment } from 'moment';

const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const weekDaysObj = {
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false
};

export const isToday = (date: Moment) => date.isSame(new Date(), 'day');
export const isTomorrow = (date: Moment) => date.add(-1, 'days').isSame(new Date(), 'day');

export const getPreOrderExtraDays = (items: any[]): number =>
  Math.max(...items.map((i: any) => (i.preOrderShippingData ? i.preOrderShippingData.dayMargin : 0)));

export const getNearShippingWeekday = (items: any[]): any => {
  return items.reduce((acc, i) => {
    const result = {};
    weekDays.forEach(d => {
      result[d] = i.preOrderShippingData && i.preOrderShippingData.days ? i.preOrderShippingData.days[d] : acc[d];
    });
    return result;
  }, weekDaysObj);
};

export const getMinMaxPreOrderDates = (items: any, isPreorder: boolean | undefined, transitDeliveryDate?: string, isTransit?: boolean) => {
  const nowDate = moment();
  const combinedWeekDays = getNearShippingWeekday(items);
  const minDate = moment(transitDeliveryDate || nowDate).add(getPreOrderExtraDays(items) || 5, 'days');
  const minDay = moment(minDate).day();

  const nearestDay: any = (!minDay ? weekDays : [...weekDays.slice(minDay - 1), ...weekDays.slice(0, minDay - 1)]).find(
    d => combinedWeekDays[d]
  );

  if (nearestDay) {
    minDate.add(weekDays.indexOf(nearestDay) + 1 - minDay, 'days');
  }

  const maxDate = moment(isTransit ? transitDeliveryDate || nowDate : nowDate).add(6, 'days');
  return [
    isPreorder
      ? minDate
      : transitDeliveryDate && isTransit
      ? moment(transitDeliveryDate)
      : moment(nowDate).add(nowDate.hours() >= 16 ? 1 : 0, 'days'),
    isPreorder ? null : maxDate
  ];
};
