import React, { useState } from 'react';
import { defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Popup from 'reactjs-popup';
import styled from '@emotion/styled/macro';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import { defaultMaterialTheme, IThemed } from 'utils/styled';
import { getFilterOptionSupplier } from 'store/filter-options/selectors';
import Icon from 'components/shared/Icon';
import { defaultCurrency, defaultLocale } from 'shared/constants';
import { TextBody2 } from 'components/shared/text';
import FlrSelect2 from 'components/shared/form-elements/select2/FlrSelect2';

import message from 'translations/account/settings';
import ButtonCommon from './StyledButtonCommon';

export interface ISettingsItem {
  value: string;
  label: string;
  isDisabled?: boolean;
}

export const languagesMap = {
  uk: { lang: 'Українська', label: 'Українська', shortLabel: 'Укр', locale: 'uk', value: 'uk' },
  en: { lang: 'English', label: 'English', shortLabel: 'En', locale: 'en', value: 'en', isDisabled: true }
};
export const languages: ISettingsItem[] = Object.keys(languagesMap).map(key => ({ value: key, ...languagesMap[key] }));

export const languagePicker = defineMessages({
  label: {
    id: 'header.languagePicker.label',
    description: 'language picker selected state',
    defaultMessage: 'Мова:'
  }
});

const currenciesMap = {
  UAH: { label: '₴ грн' },
  USD: { label: '$ usd', isDisabled: true }
};
export const currencies: ISettingsItem[] = Object.keys(currenciesMap).map(key => ({
  value: key,
  ...currenciesMap[key]
}));

interface IOwnProps {
  // put them here
  dark?: boolean;
  onlyLang?: boolean;
  onlyIcon?: boolean;
}

interface IStateProps {}

interface IDispatchProps {}

export type IProps = IOwnProps & IStateProps & IDispatchProps;

// TODO: extract re-usable part of styled components(atomic blocks)
// TODO: is not authorized only show only locale
const HeaderTopSettings: React.FC<IProps> = ({ dark, onlyLang, onlyIcon }) => {
  const location = useSelector(getFilterOptionSupplier);
  const [language, setLanguage] = useState(defaultLocale);
  const [currency, setCurrency] = useState(defaultCurrency);

  const [popupOpen, setPopupOpen] = useState(false);
  const closePopup = () => setPopupOpen(false);
  const openPopup = () => setPopupOpen(true);

  const trigger = (
    <ButtonCommon
      dark={dark}
      className={clsx('trigger', {
        active: popupOpen,
        onlyIcon,
        'auth-page': !!dark
      })}
      onClick={openPopup}
    >
      {!onlyLang && <Icon type={'world'} size={24} className={'only-icon'} />}
      <TextBody2 className={'only-icon'} color={'inherit'} style={{ marginRight: 24 }}>
        {languagesMap[language].shortLabel}
      </TextBody2>
      {!onlyLang && <Icon type={'world'} size={24} offset={8} />}
      <TextBody2 className={'trigger-text'} color={'inherit'}>
        {!onlyLang && (
          <span>
            {(location && location.label) || message.deliveryRegion.defaultMessage}
            {' | '}
          </span>
        )}
        <span>{languagesMap[language].label}</span>
        {!onlyLang && <span> | {currenciesMap[currency].label}</span>}
      </TextBody2>
    </ButtonCommon>
  );

  return (
    <Wrapper style={onlyLang ? { minWidth: 'unset' } : {}}>
      <Popup
        open={popupOpen}
        closeOnDocumentClick
        onClose={closePopup}
        onOpen={openPopup}
        on={['click']}
        contentStyle={{
          width: 340,
          height: onlyLang ? 90 : 230,
          borderRadius: 8,
          borderColor: 'transparent',
          padding: 16,
          paddingTop: 24,
          zIndex: defaultMaterialTheme.zIndex.modal,
          boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.16)'
        }}
        trigger={trigger}
        position="bottom right"
      >
        {() => (
          <div className={'popup-content'}>
            <ValidatorForm
              noValidate
              autoComplete="off"
              onSubmit={() => ({})}
              onError={() => ({})}
              style={{ gridArea: 'form' }}
            >
              {!onlyLang && (
                <ControlWrapper>
                  <TextValidator
                    fullWidth
                    disabled
                    variant={'outlined'}
                    name="name"
                    label={message.city.defaultMessage}
                    value={(location && location.label) || ''}
                    // TODO not changeble for now
                    // onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    //   setLocation(ev.target.value);
                    // }}
                    validators={['required', 'minStringLength:3']}
                    errorMessages={['this field is required', 'String it soo short']}
                  />
                </ControlWrapper>
              )}

              <ControlWrapper>
                <FlrSelect2
                  fullWidth
                  label={message.language.defaultMessage}
                  value={language}
                  options={languages}
                  onChange={(ev: any) => setLanguage(ev.target.value)}
                />
              </ControlWrapper>

              {!onlyLang && (
                <ControlWrapper>
                  <FlrSelect2
                    fullWidth
                    label={message.currency.defaultMessage}
                    value={currency}
                    options={currencies}
                    onChange={(ev: any) => setCurrency(ev.target.value)}
                  />
                </ControlWrapper>
              )}
            </ValidatorForm>
          </div>
        )}
      </Popup>
    </Wrapper>
  );
};

const ControlWrapper = styled<any>('div')(() => ({
  marginBottom: 24
}));

const Wrapper = styled<any>('div')(({ theme }: IThemed) => ({
  width: 'fit-content',
  fontFamily: theme.fonts.headings,
  letterSpacing: theme.letterSpacing,
  color: theme.colors.gray,

  '& .trigger': {
    position: 'relative',
    marginLeft: 'auto',
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
    color: theme.palette.text.secondary,
    '& .only-icon': {
      display: 'none'
    },
    '& .trigger-content': {
      padding: '0 4px',
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center'
    },

    '&.active, &:hover': {
      color: theme.palette.text.primary
    },

    'auth-page': {
      marginLeft: 'auto'
    },

    '&:active': {
      color: theme.palette.common.primaryHoverPress,
      '& .trigger-content': {
        borderRadius: 8,
        backgroundColor: theme.palette.common.primaryClickablePressBackground
      },
      '& svg': {
        color: theme.palette.common.primaryHoverPress
      }
    }
  },
  '& .popup-content': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    '& > .popup-arrow': {
      boxShadow: 'none !important'
    },
    '&-lang': {
      position: 'relative',
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-start',
      alignItems: 'center',
      ':hover': {
        backgroundColor: theme.colors.lightGray
      },
      [`${Icon}`]: {
        position: 'absolute',
        left: 10
      },
      '&-item': {
        fontSize: 14,
        lineHeight: '21px',
        paddingLeft: 33,
        margin: 0,
        cursor: 'pointer'
      }
    }
  }
}));

export default HeaderTopSettings;
