import { Product } from 'models/product';
import { Offer } from 'models/offer';

const SORT_ORDER = ['special', 'inStock', 'preOrder'];

function sortOffers(offers: Offer[]): Offer[] {
  const sorted: any = [];
  SORT_ORDER.forEach(offerType => {
    offers.forEach(o => {
      if (o[offerType] && !sorted.some((i: Offer) => i.id === o.id)) {
        sorted.push(o);
      }
    });
  });
  return sorted;
}

export class DetailedProduct extends Product {
  public offers: Offer[] = [];
  public fullName: string;

  constructor(data: any) {
    super(data);
    this.offers = sortOffers(data.offers).map((offerData: any) => new Offer(offerData));
    this.fullName = data.fullName;
  }
}
