import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { DiscountProduct } from 'models';

import { FlrTable } from 'components/shared/table';
import { TextBody2, TextSubTitle } from 'components/shared/text';
import FlrCardNarrow from 'components/shared/card/FlrCardNarrow';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';

import columns from './table-columns/ProductTransactions';
import messages from 'translations/account/loyalty';
import styles from '../styles';

interface IProps {
  loading: boolean;
  data: DiscountProduct[];
  classes: any;
  group?: string;
  mobile?: boolean;
}

const ProductGroupDiscountProductsHistoryCard: React.FC<IProps> = ({ group, loading, data, mobile, classes }) => {
  const cols = mobile
    ? columns
    : [...columns].map(column => {
        column.sorting = false;
        return column;
      });
  return (
    <>
      <FlrCardNarrow
        className={clsx(classes.historyChart, classes.paperCard, classes.paperCardFullHeight)}
        style={{ width: '100%' }}
      >
        <FlrCardTitle isUnderline>
          <Grid container spacing={1} direction={'column'}>
            <Grid item sm>
              <TextSubTitle align={'left'}>
                <FormattedMessage
                  {...messages.productsWithDiscountTitle}
                  values={{ group, amount: data.length || 0 }}
                />
              </TextSubTitle>
            </Grid>
            <Grid item sm>
              <TextBody2 color={'textSecondary'} className={classes.productsWithDiscount}>
                {messages.productsWithDiscountText.defaultMessage}
              </TextBody2>
            </Grid>
          </Grid>
        </FlrCardTitle>
        <FlrTable
          classes={{ tableContainer: classes.tableContainer }}
          columns={cols}
          data={data}
          isLoading={loading}
          options={{ pageSize: 10 }}
        />
      </FlrCardNarrow>
    </>
  );
};

export default withStyles<any>(styles)(ProductGroupDiscountProductsHistoryCard);
