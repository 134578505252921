export enum REQUEST_ACTIONS {
  REQUEST = 'REQUEST',
  SUCCESS = 'RESPONSE_SUCCESS',
  FAILURE = 'RESPONSE_FAILURE'
}

export enum ADD_REQUEST_ACTIONS {
  REQUEST = 'ADD',
  SUCCESS = 'ADD_RESPONSE_SUCCESS',
  FAILURE = 'ADD_RESPONSE_FAILURE'
}

export enum REMOVE_REQUEST_ACTIONS {
  REQUEST = 'REMOVE',
  SUCCESS = 'REMOVE_RESPONSE_SUCCESS',
  FAILURE = 'REMOVE_RESPONSE_FAILURE'
}

export enum UPDATE_REQUEST_ACTIONS {
  REQUEST = 'UPDATE',
  SUCCESS = 'UPDATE_RESPONSE_SUCCESS',
  FAILURE = 'UPDATE_RESPONSE_FAILURE'
}

export interface IAsyncDataWrapper<T> {
  loading: boolean;
  loaded: boolean;
  data: T;
  error: Error | null;
}

export interface IAsyncCatalogDataWrapper<T> {
  loading: boolean;
  loaded?: boolean;
  data: T;
  error: Error | null;
  sorted: boolean;
}

export const catalogDataWrapper = <T>(
  payload: T,
  error: Error | null = null,
  loading: boolean = false,
  sorted: boolean = false
): IAsyncCatalogDataWrapper<T> => ({
  loading,
  data: payload,
  error,
  sorted
});

export const defaultDataWrapper = <T>(
  payload: T,
  error: Error | null = null,
  loading: boolean = false,
  loaded: boolean = false
): IAsyncDataWrapper<T> => ({
  loading,
  loaded,
  data: payload,
  error
});

export const loadedDataWrapper = <T>(
  payload: T,
  error: Error | null = null,
  loading: boolean = false,
  loaded: boolean = true
): IAsyncDataWrapper<T> => ({
  loading,
  loaded,
  data: payload,
  error
});

export const loadingDataWrapper = <T>(
  payload: T,
  error: Error | null = null,
  loading: boolean = true,
  loaded: boolean = false
): IAsyncDataWrapper<T> => ({
  loading,
  loaded,
  data: payload,
  error
});

export const refreshDataWrapper = <T>(
  payload: T,
  error: Error | null = null,
  loading: boolean = true,
  loaded: boolean = true
): IAsyncDataWrapper<T> => ({
  loading,
  loaded,
  data: payload,
  error
});

export const errorDataWrapper = <T>(
  payload: T,
  error: Error,
  loading: boolean = false,
  loaded: boolean = false
): IAsyncDataWrapper<T> => ({
  loading,
  loaded,
  data: payload,
  error
});
