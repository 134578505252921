import React from "react";
import errorHandler from "./errorHandler";
import PlaceholderPage from "../../pages/placeholder";

const DefaultFallbackView = () => <PlaceholderPage message="Виникла помилка. Спробуйте перезавантажити сторінку." />;

// To log errors use custom callback instead of null
export const withErrorLogger = errorHandler(null) as any;

// fallback views
export const withErrorHandler = withErrorLogger(DefaultFallbackView);
// For another fallback view use this
// export const withErrorHandlerCustomFallback = withErrorLogger(CustomFallback);
