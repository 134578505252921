import * as React from 'react';
import FlrButton, { IButtonProps } from './FlrButton';
import { withTheme } from '@mui/styles';

class FlrButtonOutlinedWhite extends React.Component<IButtonProps> {
  public static defaultProps = {
    variant: 'outlined'
  };

  public render(): React.ReactNode {
    return <FlrButton {...this.props}>{this.props.children}</FlrButton>;
  }
}

export default withTheme(FlrButtonOutlinedWhite);
