import React, { useCallback, useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import { useRouteMatch } from 'react-router';
import { Grid } from '@mui/material';

// import { ICartTime } from 'store/cart/actions';

import { Text } from 'components/layout/content/Text';
import { TextBody2, TextSubTitle } from 'components/shared/text';
import Tooltip from 'components/shared/tooltip';
import Icon from 'components/shared/Icon';
import HoldCart from './HoldCart';

import { baseUrl, CART_WARNING_NOTIFICATION_MINUTES_LEFT } from 'shared/constants';
import messages from 'translations/cart/timer';
// import { CartTimerDispatchContext } from './CartTimerContext';
// import moment from 'moment';

interface IProps {
  type?: string;
  end: any;
  shouldRestart: boolean;
  shouldPause?: boolean;
  onExpire?: () => void;
  onChange?: (end: number) => void;
  setCartTimer?: (args: any) => void;
}

const Timer: React.FC<IProps> = ({
  type,
  end,
  shouldRestart,
  shouldPause,
  onExpire,
  onChange,
  setCartTimer: setTimer
}) => {
  // const cartTimerDispatch = useContext(CartTimerDispatchContext);
  // console.log('-> ', moment(end));
  // console.log('-> ', new Date(end).getTime());
  // console.log('---', end);
  const { seconds: secondsLeft, minutes: minutesLeft, hours: hoursLeft, restart, pause, resume } = useTimer({
    expiryTimestamp: end,
    // expiryTimestamp: moment(end)
    //   .add(3, 'hours')
    //   .milliseconds(),
    onExpire
  });

  // console.log(start, end);
  let minutes = minutesLeft.toFixed(0);
  let seconds = secondsLeft.toFixed(0);

  if (onChange) {
    onChange(end);
  }

  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }
  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }

  useCallback(() => {
    if (hoursLeft === 0 && minutesLeft === 0 && secondsLeft === 0) {
      return onExpire && onExpire();
    }
  }, [hoursLeft, minutesLeft, secondsLeft, onExpire]);

  // shouldRestart timer with new expire date
  useEffect(() => {
    // if (setTimer !== undefined && type) {
    //   setTimer(end);
    // }
    if (type) {
      // cartTimerDispatch({ type, payload: { minutes, seconds } });
      // sessionStorage.setItem('timers', JSON.stringify({ [type]: { minutes, seconds } }));
      // window.dispatchEvent(new Event('timersStore'));
      window.dispatchEvent(new CustomEvent('timersStore', { detail: { [type]: { minutes, seconds } } }));
    }
    if (shouldRestart) {
      restart(end);
    }
    // eslint-disable-next-line
  }, [end, shouldRestart]);

  useEffect(() => {
    // if (setTimer !== undefined && type) {
    // setTimer({ [type]: { minutes, seconds } });
    // setTimer({ type, payload: { minutes, seconds } });
    // }
    if (type) {
      // cartTimerDispatch({ type, payload: { minutes, seconds } });
      // sessionStorage.setItem('timers', JSON.stringify({ [type]: { minutes, seconds } }));
      // window.dispatchEvent(new Event('timersStore'));
      window.dispatchEvent(new CustomEvent('timersStore', { detail: { [type]: { minutes, seconds } } }));
    }
    // eslint-disable-next-line
  }, [minutes, seconds]);

  const isPaused = !!useRouteMatch(`${baseUrl}/checkout`);
  if (isPaused || shouldPause) {
    pause();
  } else {
    resume();
  }

  const fontColor = isPaused
    ? 'lighterGray'
    : +minutes >= CART_WARNING_NOTIFICATION_MINUTES_LEFT
    ? 'alertInfo'
    : +minutes > 0
    ? 'warning'
    : 'red';

  return (
    <Grid container alignItems={'center'} alignContent={'center'} style={{ display: shouldPause ? 'none' : 'block' }}>
      <Grid item xs style={{ whiteSpace: 'nowrap', display: 'flex' }}>
        <Tooltip
          icon={<Icon type={isPaused ? 'pause' : 'timer'} size={24} offset={8} opacity={1} />}
          title={
            <div>
              <TextSubTitle color={'inherit'} style={{ marginTop: 0, whiteSpace: 'normal' }}>
                {messages.title.defaultMessage}
              </TextSubTitle>
              <br />
              <TextBody2 color={'inherit'} style={{ color: 'white', whiteSpace: 'normal' }}>
                {isPaused ? messages.bodyCheckout.defaultMessage : messages.body.defaultMessage}
              </TextBody2>
              <br />
              {/*<Link1 color={"secondary"} to={"/dev"}>
                Детальніше
              </Link1>*/}
            </div>
          }
        >
          <Text fontColor={fontColor} style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <span style={{ display: 'inline-block', minWidth: 20, textAlign: 'left' }}>{minutes}</span>
              <span style={{ display: 'inline-block', minWidth: 5, textAlign: 'center' }}>:</span>
              <span style={{ display: 'inline-block', minWidth: 20 }}>{seconds}</span>
            </div>
            <Icon type={isPaused ? 'pause' : 'timer'} size={16} />
          </Text>
        </Tooltip>
      </Grid>
      {isPaused && <HoldCart />}
    </Grid>
  );
};

export default Timer;
