import React from 'react';
import Slider from 'react-slick';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withStyles } from '@mui/styles';

import { IApplicationState } from 'store/reducers';
import { Product } from 'models';
import { selectProductAsync } from 'store/catalog/actions';
import { hotOfferProductsAsync, IHotOffer } from 'store/main/actions';
import { getHotOffersData, getHotOffersLoadingState } from 'store/main/selectors';
import HotOfferItem from './HotOfferItem';
import styles from './styles';

interface IOwnProps {
  // put own props here
  classes?: any;
}

interface IStateProps {
  hotOffers: IHotOffer[] | null;
  hotOfferProductLoading: boolean;
}

interface IDispatchProps {
  selectProduct: typeof selectProductAsync.request;
  getHotOffers: typeof hotOfferProductsAsync.request;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const defaultSettings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  respondTo: 'min',
  infinite: false,
  initialSlide: 0,
  nextArrow: <React.Fragment />,
  prevArrow: <React.Fragment />
};

// tslint:disable-next-line:no-shadowed-variable
const MainHotOffers: React.FC<IProps> = ({ classes, hotOffers, getHotOffers, selectProduct }) => {
  React.useEffect(() => {
    getHotOffers();
  }, [getHotOffers]);

  if (
    !hotOffers ||
    (hotOffers && hotOffers.length && hotOffers.map(o => Number(new Date(o.expiredTime)) < Date.now()))
  ) {
    return null;
  }

  return (
    <section className={classes.root}>
      <Slider {...defaultSettings}>
        {hotOffers.map(item => (
          <HotOfferItem key={item._id} classes={classes} hotOffer={item} selectProduct={selectProduct} />
        ))}
      </Slider>
    </section>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state: IApplicationState) => ({
  hotOffers: getHotOffersData(state),
  hotOfferProductLoading: getHotOffersLoadingState(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      selectProduct: (product: Product) => selectProductAsync.request(product),
      getHotOffers: hotOfferProductsAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(MainHotOffers as any));
