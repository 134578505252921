import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Popup from 'reactjs-popup';
import { withStyles } from '@mui/styles';
import { Collapse } from '@mui/material';
import { IApplicationState } from 'store/reducers';
import { mainMenuAsync } from 'store/main/actions';
import { getMainMenuData } from 'store/main/selectors';
import { defaultMaterialTheme, ITheme } from 'utils/styled';
import Icon from 'components/shared/Icon';
import { TitleH2, Link1, Link2, TextBody1, TextSubTitle } from 'components/shared/text';
import { scrollBarMaterial } from 'styles/mixins';
import {
  baseUrl,
  catalogProductTypeAccessories,
  catalogProductTypePotted,
  catalogProductTypeShear,
  catalogProductTypeStable,
  catalogProductTypeCeramic,
  defaultLocale
} from 'shared/constants';
import messages from 'translations/layout/header';
import ImgShear from './assets/share.png';
import ImgPotted from './assets/potted.png';
import ImgStabilized from './assets/stabilized.png';
import ImgAccessories from './assets/accessories.png';
import ImgCeramics from './assets/ceramics.png';

const CATEGORIES_LIST_MAX = 10;
const categoriesMapper = {
  [catalogProductTypeShear]: ImgShear,
  [catalogProductTypePotted]: ImgPotted,
  [catalogProductTypeStable]: ImgStabilized,
  [catalogProductTypeAccessories]: ImgAccessories,
  [catalogProductTypeCeramic]: ImgCeramics
};

const categoriesOrder = {
  [catalogProductTypeShear]: 0,
  [catalogProductTypePotted]: 1,
  [catalogProductTypeAccessories]: 2,
  [catalogProductTypeStable]: 3,
  [catalogProductTypeCeramic]: 4
};

function sortByOrder(a: any, b: any) {
  if (!(a.productType && b.productType)) {
    return 0;
  }
  if (categoriesOrder[a.productType.alias] > categoriesOrder[b.productType.alias]) {
    return 1;
  }
  if (categoriesOrder[a.productType.alias] < categoriesOrder[b.productType.alias]) {
    return -1;
  }
  return 0;
}

interface IOwnProps {
  classes?: any;
}

interface IStateProps {
  mainMenu: any | null;
}

interface IDispatchProps {
  getMainMenu: typeof mainMenuAsync.request;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const HeaderCatalog: React.FC<IProps> = ({ classes, mainMenu, getMainMenu }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const closePopup = () => setPopupOpen(false);
  const openPopup = () => setPopupOpen(true);

  useEffect(() => {
    getMainMenu();
  }, [getMainMenu]);

  const trigger = (
    <div className={classes.CatalogButton}>
      <Icon type="catalog" size={24} offset={8} />
      <TitleH2
        className={clsx(classes.CatalogLinkTitle, {
          [classes.CatalogLinkTitleActive]: popupOpen
        })}
      >
        {messages.catalogMenu.defaultMessage}
      </TitleH2>
    </div>
  );

  const [shearCollapsed, setShearCollapsed] = useState(false);
  const toggleShear = () => setShearCollapsed((state) => !state);

  const content = mainMenu ? (
    <div className={classes.containerCategories}>
      {mainMenu.sort(sortByOrder).map((itemCategory: any, index: number) => {
        if (!itemCategory.productType) {
          return null;
        }

        const items = [...(itemCategory.items || [])].sort((a, b) =>
          a.name.localeCompare(b.name, defaultLocale, { sensitivity: 'base' })
        );
        const firstItems = items
          .splice(0, CATEGORIES_LIST_MAX)
          .sort((a, b) => a.name.localeCompare(b.name, defaultLocale, { sensitivity: 'base' }));
        const alias = itemCategory.productType.alias;
        return (
          <div key={index} className={classes.category}>
            <div className={classes.categoryTitle}>
              <div onClick={closePopup}>
                <Link1 to={`${baseUrl}/catalog/${alias}`}>
                  <TextSubTitle className={classes.categoryTitleText} color={'inherit'}>
                    {itemCategory.productType ? itemCategory.productType.name : messages.titleOther.defaultMessage}
                  </TextSubTitle>
                </Link1>
              </div>

              <div className={classes.categoryTitleImg}>
                <img src={(alias && categoriesMapper[alias]) || ImgShear} alt="" />
              </div>
            </div>

            <div>
              {firstItems &&
                firstItems.map((item: any, indexItem: number) => (
                  <div onClick={closePopup} key={indexItem}>
                    <Link2
                      to={`${baseUrl}/catalog/${alias}/category=${item.code}`}
                      className={classes.categorySubTitleLink}
                    >
                      <TextBody1 color={'inherit'} className={classes.subTitle}>
                        {item.name}
                      </TextBody1>
                    </Link2>
                  </div>
                ))}

              {items && !!items.length && (
                <>
                  <Collapse in={shearCollapsed}>
                    {items.map((item: any, indexItem: number) => (
                      <div onClick={closePopup} key={indexItem}>
                        <Link2
                          to={`${baseUrl}/catalog/${alias}/category=${item.code}`}
                          className={classes.categorySubTitleLink}
                        >
                          <TextBody1 color={'inherit'} className={classes.subTitle}>
                            {item.name}
                          </TextBody1>
                        </Link2>
                      </div>
                    ))}
                  </Collapse>
                  <Link2 component={'div'} className={classes.toggler} onClick={toggleShear}>
                    {shearCollapsed
                      ? `${messages.hideCategories.defaultMessage} (${items.length})`
                      : `${messages.showAll.defaultMessage} (${items.length})`}
                  </Link2>
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  ) : null;
  return (
    <Popup
      open={popupOpen}
      closeOnDocumentClick
      onClose={closePopup}
      onOpen={mainMenu ? openPopup : () => ({})}
      on={['click']}
      contentStyle={{
        width: 'auto',
        maxWidth: 1050,
        minHeight: 300,
        maxHeight: 474,
        borderRadius: 8,
        borderColor: 'transparent',
        padding: 40,
        zIndex: defaultMaterialTheme.zIndex.modal,
        boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.16)'
      }}
      trigger={trigger}
      position="bottom left"
    >
      {(close) => <nav className={classes.popupContent}>{content}</nav>}
    </Popup>
  );
};

const styles = (theme: ITheme) => ({
  popupContent: {
    overflow: 'hidden',
    maxHeight: '100%'
  },
  CatalogButton: {
    display: 'flex',
    margin: '0 auto',
    userSelect: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    [(theme as any).breakpoints.up('xl')]: {
      margin: 0
    }
  },
  CatalogLinkTitle: {
    margin: '0 !important',
    fontWeight: '500 !important',
    color: theme.palette.primary.main,
    textTransform: 'uppercase'
  },
  CatalogLinkTitleActive: {
    color: theme.palette.common.black
  },
  containerCategories: {
    paddingBottom: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxHeight: 415,
    overflow: 'auto',
    ...scrollBarMaterial
  },
  category: {
    '&:not(:last-of-type)': {
      marginRight: 60
    },
    '&:last-of-type': {
      marginRight: theme.spacing(4.5)
    }
  },

  categoryTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.common.disabledBlack}`
  },

  categoryTitleText: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },

  categoryTitleImg: {
    '& img': {
      maxWidth: 'initial'
    }
  },
  subTitle: {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.text.secondary,

    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  categorySubTitleLink: {
    display: 'block',
    marginBottom: theme.spacing(1)
  },

  toggler: {
    marginTop: theme.spacing(2)
  }
});

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state: IApplicationState) => ({
  mainMenu: getMainMenuData(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      getMainMenu: mainMenuAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(HeaderCatalog as any));
