import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import apexUaLocale from 'apexcharts/dist/locales/ua.json';
import clsx from 'clsx';
import moment from 'moment';
import { withStyles } from '@mui/styles';

import FlrLoader from 'components/loading/LoadingSpinner';
import FlrCardNarrow from 'components/shared/card/FlrCardNarrow';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import { TextHelper, TextSubTitle } from 'components/shared/text';
import { getAccountDiscountsData } from 'store/account-discounts/selectors';
import { fetchBalanceHistoryAsync } from 'store/balance-history/actions';
import {
  getBalanceHistory,
  getBalanceHistoryLoaded,
  getBalanceHistoryLoadingState
} from 'store/balance-history/selectors';
import { palette } from 'styles/theme/default';
import commonMessages from 'translations/account/finance';

import styles from '../styles';

interface IDataSeries {
  name: string;
  data: IChardData[];
}
interface IChardData {
  x: string;
  y: number;
}

interface IProps {
  classes: any;
  intl: any;
  mobile?: boolean;
}

const BalanceHistoryCard: React.FC<IProps> = ({ classes, mobile }) => {
  const dispatch = useDispatch();
  const discountConditions = useSelector(getAccountDiscountsData);
  const balanceHistory = useSelector(getBalanceHistory);
  const balanceHistoryLoaded = useSelector(getBalanceHistoryLoaded);
  const balanceHistoryLoadingState = useSelector(getBalanceHistoryLoadingState);

  useEffect(() => {
    dispatch(fetchBalanceHistoryAsync.request());
    // eslint-disable-next-line
  }, []);
  if (!balanceHistory || !discountConditions?.balance) {
    return null;
  }

  const conditions = discountConditions?.balance[0]?.conditions || [];
  const balanceValues = Object.values(balanceHistory || {});
  const numericBalanceValues = balanceValues.map((value) => Number(value));
  let minBalancePoint = Math.min(...numericBalanceValues, 0);

  let colorBreakpoints = [0];
  if (discountConditions?.balance[0]) {
    const numberedConditions = Object.values(conditions).map((i) => Number(i));
    colorBreakpoints = [...numberedConditions];

    // * increase tick amount according to negative ballance
    if (minBalancePoint < 0) {
      const minConditionPoint = numberedConditions.find((i) => i > Math.abs(minBalancePoint));
      if (minConditionPoint) {
        minBalancePoint = -(minConditionPoint || 0);
      } else {
        const additionalTicks = Math.abs(minBalancePoint / numberedConditions[0]);
        minBalancePoint = -Math.ceil(additionalTicks) * numberedConditions[0];
      }
    }
  }

  const colorGroups = {
    negative: palette.error.main,
    low: '#c1c1c1',
    medium: '#009BDD',
    high: '#CDDC39'
  };

  const chartData: IChardData[][] = [];
  if (balanceHistoryLoaded && balanceHistory) {
    Object.keys(balanceHistory).forEach((date: string, index: number) => {
      const balancePoint = balanceHistory ? balanceHistory[date] : 0;
      let groupIndex = colorBreakpoints.length;
      while (colorBreakpoints[groupIndex] >= balancePoint && groupIndex > 0) {
        groupIndex--;
      }
      if (!chartData[groupIndex]) {
        chartData[groupIndex] = [];
      }
      chartData.forEach((data) => data.push({ x: date, y: 0 }));
      chartData[groupIndex][chartData[groupIndex].length - 1].y = balancePoint;
    });
  }

  const series: IDataSeries[] = [];
  const colors: string[] = [];
  chartData.forEach((data, index) => {
    if (data.length) {
      let name = '';
      const yValue = data[0].y;
      if (yValue < 0) {
        name = 'Менше 0';
      } else if (yValue > 0 && yValue <= 10000) {
        name = 'До 10 000';
      } else if (yValue > 10000 && yValue <= 50000) {
        name = 'Від 10 000 до 50 000';
      } else {
        name = 'Вище 50 000';
      }

      series.push({ name, data });
    }
  });

  const chartSeries = mobile
    ? series.map((ds) => ({
        ...ds,
        data: ds.data.slice(-10)
      }))
    : series;

  const chartOptions = {
    chart: {
      id: 'balance-history-chart',
      type: 'bar',
      stacked: true,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      locales: [apexUaLocale],
      defaultLocale: apexUaLocale.name
    },
    xaxis: {
      tickAmount: mobile ? 10 : chartData[0]?.length,
      tickPlacement: 'between',
      type: 'category',
      labels: {
        show: true,
        formatter: (value: number) => {
          return moment(value).format('DD.MM.YY');
        },
        rotate: 270,
        style: {
          fontSize: '12px',
          padding: '10px'
        }
      }
    },
    yaxis: {
      tickAmount: 4,
      max: 100000,
      min: minBalancePoint,
      labels: {
        formatter: (value: number) => {
          if (Math.abs(value) < 1000) {
            return Math.floor(value);
          }
          if (Math.abs(value) < 5000) {
            return `${(value / 1000).toFixed(1)} 000`;
          }
          return `${(value / 1000).toFixed()} 000`;
        }
      },
      title: {
        show: true
      },
      axisBorder: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true,
      floating: true,
      position: 'top',
      horizontalAlign: mobile ? 'left' : 'right',
      onItemClick: {
        toggleDataSeries: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '90%',
        borderRadius: 4,
        colors: {
          ranges: [
            {
              from: -Number.MAX_VALUE,
              to: 0,
              color: colorGroups.negative
            },
            {
              from: 0,
              to: 10000,
              color: colorGroups.low
            },
            {
              from: 10000,
              to: 50000,
              color: colorGroups.medium
            },
            {
              from: 50000,
              to: Number.MAX_VALUE,
              color: colorGroups.high
            }
          ]
        }
      }
    },
    tooltip: {
      x: {
        show: true
      }
    },
    colors
  };

  return (
    <>
      <FlrCardNarrow
        className={clsx(classes.historyChart, classes.paperCard, classes.paperCardFullHeight)}
        style={{ minWidth: '100%' }}
      >
        <FlrCardTitle isUnderline>
          <TextSubTitle align={'left'} style={{ marginBottom: mobile ? 30 : 0 }}>
            {commonMessages.history.defaultMessage}
          </TextSubTitle>
        </FlrCardTitle>
        {balanceHistoryLoadingState ? (
          <FlrLoader size={50} />
        ) : balanceHistory ? (
          <div style={{ position: 'relative' }}>
            <TextHelper className={classes.historyChartYTitle}>Гривні</TextHelper>
            {minBalancePoint < 0 && (
              <TextHelper className={clsx('subtitle', classes.historyChartYTitle)}>Кредитна лінія</TextHelper>
            )}
            <Chart options={chartOptions} series={chartSeries} width="100%" height={400} type={'bar'} />
            <TextHelper className={classes.historyChartXTitle}>Тижні</TextHelper>
          </div>
        ) : (
          <span>Немає даних</span>
        )}
      </FlrCardNarrow>
    </>
  );
};

export default withStyles<any>(styles)(injectIntl(BalanceHistoryCard));
