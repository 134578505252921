import { defineMessages } from "react-intl";

import {
  ORDER_STATUS_NEW,
  ORDER_STATUS_SHIPPED,
  ORDER_STATUS_DONE,
  ORDER_STATUS_CANCELED, ORDER_STATUS_PREORDER_PENDING_APPROVAL
} from 'models/order';

export default defineMessages({
  [ORDER_STATUS_NEW]: {
    id: "order.status.new",
    description: "order.status.new",
    defaultMessage: ""
  },
  [ORDER_STATUS_PREORDER_PENDING_APPROVAL]: {
    id: "order.status.pending",
    description: "order.status.pending",
    defaultMessage: "Прийнято"
  },
  [ORDER_STATUS_SHIPPED]: {
    id: "order.status.shipped",
    description: "order.status.shipped",
    defaultMessage: "Відправлено"
  },
  [ORDER_STATUS_DONE]: {
    id: "order.status.done",
    description: "order.status.done",
    defaultMessage: "Виконано"
  },
  [ORDER_STATUS_CANCELED]: {
    id: "order.status.canceled",
    description: "order.status.canceled",
    defaultMessage: "Зачинено"
  }
});
