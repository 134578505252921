import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import messages from 'translations/account/finance';
import { TextBody2 } from 'components/shared/text';
import useStyles from './styles';

export const CreditPaymentContent = () => {
  const classes = useStyles();

  return (
    <DialogContent>
      <TextBody2 className={classes.dialogText}>{messages.balanceModalCreditText.defaultMessage}</TextBody2>
    </DialogContent>
  );
};
