import * as React from 'react';
import FlrButton, { IButtonProps } from './FlrButton';
import { withTheme } from '@mui/styles';
import { transparentize } from 'polished';

class FlrDisabledButton extends React.Component<IButtonProps> {
  public render(): React.ReactNode {
    const {
      theme: { colors },
      ...rest
    } = this.props;
    const textColor = transparentize(0.65, colors.black);
    const bgColor = transparentize(0.85, colors.black);
    return (
      <FlrButton textcolor={textColor} bg={bgColor} {...rest}>
        {this.props.children}
      </FlrButton>
    );
  }
}

export default withTheme(FlrDisabledButton);
