import { FC, HTMLAttributes, useState } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import clsx from 'clsx';
import { isMobileDevice } from 'environment';
import { Button } from '@mui/material';

import { SplitedPrice } from 'components/prices';
import FlrCard from 'components/shared/card/FlrCard';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import Icon from 'components/shared/Icon';
import PaymentModal from 'components/shared/modals/payment/PaymentModal';
import { TextBody2, TextSubTitle, TitleH1 } from 'components/shared/text';
import { User } from 'models';
import DataCardSkeleton from 'pages/account/finance/CardSkeleton';
import { getAccountLoadingState, getUserAccount } from 'store/account/selectors';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/account/finance';

import classes from './BalanceCard.module.scss';

export const DEFAULT_AMOUNT = 10000;

const isMobile = Boolean(isMobileDevice(window.navigator));

interface IProps extends HTMLAttributes<HTMLDivElement> {
  buttonVariant?: 'text' | 'outlined' | 'contained';
  classNameWrapper?: string;
  withoutTitle?: boolean;
}

interface IStateProps {
  account: User | null;
  accountLoadingState: boolean;
}

type IComponentProps = IProps & IStateProps;

const BalanceCard: FC<IComponentProps> = ({ buttonVariant, account, accountLoadingState, classNameWrapper, withoutTitle }) => {
  const [modalPayOpen, setModalPayOpen] = useState(false);

  const handlePayCancel = () => {
    setModalPayOpen(false);
  };
  const handleOpenPayModal = () => {
    setModalPayOpen(true);
  };

  const creditUsed = account && account.profile.balance < 0 ? -1 * account.profile.balance : DEFAULT_AMOUNT;

  return (
    <>
      {!withoutTitle ? <div className={classes.title}>
        <Icon type={'wallet'} opacity={1} />
        <TitleH1 className={classes.titleCommon}>{messages.titleFinance.defaultMessage}</TitleH1>
      </div> : null}
      <FlrCard className={clsx(classes.paperCard, classNameWrapper)} hoverShadow>
        <FlrCardTitle isUnderline className={classes.label}>
          <TextSubTitle className={classes.subTitle}>{messages.balance.defaultMessage}</TextSubTitle>
          <Button
            color="primary"
            variant={buttonVariant || 'contained'}
            size="small"
            onClick={handleOpenPayModal}
            className={classes.btn}
          >
            {messages.topUp.defaultMessage}
          </Button>
        </FlrCardTitle>

        {!accountLoadingState && account ? (
          <div className={classes.cardContent}>
            <Icon type="finance" offset={8} />

            <div className={classes.balanceContent}>
              <SplitedPrice
                value={account.profile.balance}
                fontSize={isMobile ? 'h2' : 'h1'}
                fontSizeDecimal={isMobile ? 'h3' : 'h2'}
                fontColor="black"
                negative={account.profile.balance < 0}
                className={clsx(classes.balanceValue, { [classes.negativeBalance]: account.profile.balance < 0 })}
                priceDisplayStyle={{ fontWeight: 700, letterSpacing: '1px' }}
              />
              <TextBody2 className={classes.cardBalanceBody}>Власні кошти на рахунку</TextBody2>
            </div>
          </div>
        ) : (
          <DataCardSkeleton />
        )}
      </FlrCard>

      {modalPayOpen && <PaymentModal open={modalPayOpen} handleClose={handlePayCancel} amount={creditUsed} />}
    </>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  account: getUserAccount(state),
  accountLoadingState: getAccountLoadingState(state)
});

export default connect(mapStateToProps)(BalanceCard);
