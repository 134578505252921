import { defineMessages } from "react-intl";

export default defineMessages({
  catalogMenu: {
    id: "header.catalogMenu",
    description: "",
    defaultMessage: "Каталог"
  },
  searchPlaceholder: {
    id: "header.searchPlaceholder",
    description: "searchPlaceholder",
    defaultMessage: "Хризантемы авиньон"
  },
  search: {
    id: "header.search",
    description: "Search",
    defaultMessage: "Знайти"
  },
  "shear-flowers": {
    id: "header.categories.titleSharedFlowers",
    description: "header.categories.titleSharedFlowers",
    defaultMessage: "Зрізані квіти"
  },
  "potted-flowers": {
    id: "header.categories.titlePotted",
    description: "header.categories.titlePotted",
    defaultMessage: "Кімнатні рослини"
  },
  titleStabilized: {
    id: "header.categories.titleStabilized",
    description: "header.categories.titleStabilized",
    defaultMessage: "Стабілізовані квіти"
  },
  titleOther: {
    id: "header.categories.titleOther",
    description: "header.categories.titleOther",
    defaultMessage: "Інші"
  },
  accessories: {
    id: "header.categories.titleAccessories",
    description: "header.categories.titleAccessories",
    defaultMessage: "Фурнітура"
  },
  showAll: {
    id: "header.categories.showAll",
    description: "header.categories.showAll",
    defaultMessage: "Показати всі"
  },
  hideCategories: {
    id: "header.categories.hideCategories",
    description: "header.categories.hideCategories",
    defaultMessage: "Сховати"
  }
});
