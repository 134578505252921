import { defineMessages } from "react-intl";

export default defineMessages({
  noTimeLeft: {
    id: "expiredCart.noTimeLeft",
    description: "expiredCart.noTimeLeft",
    defaultMessage: "Час вийшов"
  },
  expiredCartShort: {
    id: "expiredCart.title.short",
    description: "Cart title short",
    defaultMessage: "Неоформлені товари"
  },
  expiredCartShortWithNumber: {
    id: "expiredCart.title.short",
    description: "Cart title short",
    defaultMessage: "Неоформлені товари ({items})"
  },
  expiredCart: {
    id: "expiredCart.title",
    description: "Cart title",
    defaultMessage: "Неоформлені товари, які можливо відновити для замовлення ({items})"
  },
  priceDecreased: {
    id: "expiredCart.priceDecreased",
    description: "expiredCart.priceDecreased",
    defaultMessage: "Ціна знизилась на <price>{amount}</price>"
  },
  priceIncreased: {
    id: "expiredCart.priceIncreased",
    description: "expiredCart.priceIncreased",
    defaultMessage: "Ціна зросла на <price>{amount}</price>"
  },
  qtyDecreased: {
    id: "expiredCart.qtyDecreased",
    description: "expiredCart.qtyDecreased",
    defaultMessage: "Доступно менше на {amount} шт"
  },
  outOfStock: {
    id: "expiredCart.outOfStock",
    description: "expiredCart.outOfStock",
    defaultMessage: "Товар закінчився"
  },
  preOrderAvailable: {
    id: "expiredCart.preOrderAvailable",
    description: "expiredCart.preOrderAvailable",
    defaultMessage: "Можливе передзамовлення"
  },
  notAvailable: {
    id: "expiredCart.notAvailable",
    description: "expiredCart.notAvailable",
    defaultMessage: "Недоступно для замовлення"
  },
  restore: {
    id: "expiredCart.restore",
    description: "expiredCart.restore",
    defaultMessage: "Відновити"
  },
  restoreAndPreOrder: {
    id: "expiredCart.restoreAndPreOrder",
    description: "expiredCart.restoreAndPreOrder",
    defaultMessage: "Відновити та передзамовити"
  }
});
