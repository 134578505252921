import { FC, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import axios from 'axios';
import env from 'environment';
import { useSnackbar } from 'notistack';
import { Button, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { fetchAccountAsync } from 'store/account/actions';
import { AuthService } from 'store/auth/service';
import messages from 'translations/account/finance';

import SnackbarContent from '../../layout/notistack-provider/SnackbarWithTitle';
import { SplitedPrice } from '../../prices';
import { FlrButtonKhaki } from '../../shared/buttons';
import FlrCheckboxWithLabel from '../../shared/form-elements/FlrCheckbox';
import { FlrInput } from '../../shared/form-elements/textfield';
import { TextBody2, TitleH1 } from '../../shared/text';

import classes from './CardCreditLine.module.scss';

interface IChangeCreditLineModalProps {
  creditCurrent: number;
  loadUser: typeof fetchAccountAsync.request;
  isOpen: boolean;
  onClose: () => void;
}

const ChangeCreditLineModal: FC<IChangeCreditLineModalProps> = ({ creditCurrent, loadUser, isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [onceRequest, setOnceRequest] = useState(false);
  const [creditRequest, setCreditRequest] = useState(0);

  const handleCancel = () => {
    onClose();
  };

  const handleModalConfirm = () => {
    handleCancel();
    setOnceRequest(false);
    setCreditRequest(0);

    enqueueSnackbar(<SnackbarContent title={messages.creditLineRequestSent.defaultMessage} />, {
      variant: 'info'
    });
  };

  const handleChangeCreditRequest = (ev: any) => {
    setCreditRequest(Number(ev.currentTarget.value));
  };

  const handleRequestCredit = () => {
    const urlMock = `${env.apiUrl}/account/credit/increase-request`;
    const service = new AuthService();
    setLoading(true);

    axios
      .post(
        `${urlMock}`,
        {
          amount: String(creditRequest),
          once: String(onceRequest)
        },
        {
          headers: {
            Authorization: service.authToken
          }
        }
      )
      .then(() => {
        loadUser();
        handleModalConfirm();
      })
      .catch((err) => {
        let data;
        if (err.response && err.response.data) {
          data = err.response.data;
        }

        if (data === 'The client already has a credit') {
          return enqueueSnackbar(<SnackbarContent title={messages.creditLineRequestErrorAlready.defaultMessage} />, {
            variant: 'error'
          });
        }
        enqueueSnackbar(<SnackbarContent title={messages.creditLineRequestError.defaultMessage} />, {
          variant: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll={'body'}
      classes={{
        paperScrollBody: classes.dialogContainer
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <TitleH1 className={classes.dialogTitle}>{messages.creditLineModalTitle.defaultMessage}</TitleH1>
      </DialogTitle>
      <DialogContent>
        <TextBody2 className={classes.dialogText}>{messages.creditLineModalText.defaultMessage}</TextBody2>

        <ValidatorForm noValidate instantValidate={true} autoComplete="off" onSubmit={handleModalConfirm}>
          <FlrInput
            className={classes.creditInput}
            name="name"
            variant="outlined"
            required
            placeholder={messages.creditLineInputLabel.defaultMessage}
            value={String(creditRequest || '')}
            onChange={handleChangeCreditRequest}
            helperText={
              <span>
                {messages.creditLineInputHelpText.defaultMessage}{' '}
                <SplitedPrice value={creditCurrent} fontSize={'tiny'} postfix={`₴`} />
              </span>
            }
          />
        </ValidatorForm>

        <FlrCheckboxWithLabel
          name={'onceRequest'}
          checked={onceRequest}
          onChange={(event: any, checked: boolean) => setOnceRequest(checked)}
          label={messages.creditLineOnceCheckbox.defaultMessage}
        />
      </DialogContent>
      <DialogActions>
        <Button variant={'text'} onClick={handleCancel} color="primary" disabled={loading}>
          {messages.cancelBtnLabel.defaultMessage}
        </Button>
        <FlrButtonKhaki onClick={handleRequestCredit} color="primary" disabled={loading || creditRequest <= 0}>
          {messages.creditLineIncrease.defaultMessage}
          {loading && <CircularProgress size={14} />}
        </FlrButtonKhaki>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeCreditLineModal;
