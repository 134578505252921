import { defineMessages } from "react-intl";

export default defineMessages({
  mobileTitle: {
    id: "catalog.search.mobileTitle",
    description: "catalog.search.mobileTitle",
    defaultMessage: "Пошук"
  },
  mobilePlaceholder: {
    id: "catalog.search.mobilePlaceholder",
    description: "catalog.search.mobilePlaceholder",
    defaultMessage: "Знайти"
  },
  mobileSubTitle: {
    id: "catalog.search.mobileSubTitle",
    description: "catalog.search.mobileSubTitle",
    defaultMessage: "Пошук в категорії товарів "
  },
  clearHistory: {
    id: "catalog.search.clearHistory",
    description: "catalog.search.clearHistory",
    defaultMessage: "Очистити історію"
  },
  searchEmptyResult: {
    id: "catalog.search.searchEmptyResult",
    description: "catalog.search.searchEmptyResult",
    defaultMessage: "За вашим запитом нічого не знайдено."
  },
  searchItem_history: {
    id: "catalog.search.searchItem_history",
    description: "catalog.search.searchItem_history",
    defaultMessage: "Історія пошуку"
  },
  searchItem_product: {
    id: "catalog.search.searchItem_products",
    description: "catalog.search.searchItem_products",
    defaultMessage: "Варіанти "
  },

  "searchItem_attributes.catalogCategory": {
    id: "catalog.search.searchItem_attributes.catalogCategory",
    description: "catalog.search.searchItem_attributes.catalogCategory",
    defaultMessage: "Пошук за категорії"
  },
  "searchItem_attributes.color": {
    id: "catalog.search.searchItem_attributes.color",
    description: "catalog.search.searchItem_attributes.color",
    defaultMessage: "Пошук за Кольором"
  },
  "searchItem_attributes.height": {
    id: "catalog.search.searchItem_attributes.height",
    description: "catalog.search.searchItem_attributes.height",
    defaultMessage: "Пошук за Висотою"
  },
  "searchItem_attributes.country": {
    id: "catalog.search.searchItem_attributes.country",
    description: "catalog.search.searchItem_attributes.country",
    defaultMessage: "Пошук за Краіною виробника"
  },
  "searchItem_attributes.manufacturer": {
    id: "catalog.search.searchItem_attributes.manufacturer",
    description: "catalog.search.searchItem_attributes.manufacturer",
    defaultMessage: "Пошук за виробником"
  }
});
