import { FC } from 'react';
import { Grid } from '@mui/material';

import FlrLoader from 'components/loading/LoadingSpinner';
import FlrCardNarrow from 'components/shared/card/FlrCardNarrow';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import FlrProgress from 'components/shared/progress-bar/FlrProgressBar';
import { TextSubTitle } from 'components/shared/text';
import { IPurchasedQty } from 'models/account-discounts';
import messages from 'translations/account/loyalty';
import messagesCommon from 'translations/common';
import { formatNumber } from 'utils/helpers';

interface IComponentProps {
  discount?: IPurchasedQty;
  progressValue?: number;
  loading: boolean;
}

const ProductGroupDiscountProgressCard: FC<IComponentProps> = ({ discount, progressValue, loading }) => {
  if (loading) {
    return (
      <FlrCardNarrow>
        <FlrCardTitle isUnderline={true}>
          <TextSubTitle>{messages.discountProgress.defaultMessage}</TextSubTitle>
        </FlrCardTitle>
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Grid item>
            <FlrLoader size={32} />
          </Grid>
        </Grid>
      </FlrCardNarrow>
    );
  }

  const conditions = discount ? discount.conditions : {};
  const conditionsArray = Object.keys(conditions);
  const steps = conditionsArray.map((key) => ({
    value: `${key}%`,
    label: Number(conditions[key]) > 0 ? formatNumber(Number(conditions[key])) : messagesCommon.start.defaultMessage
  }));
  const arraySteps = [{value: '0%', label: '0'}].concat(steps);

  const activeIndex = conditionsArray.reduce(
    (acc, k, idx) => (Number(conditions[k]) > (progressValue || 0) ? acc : idx + 1),
    0
  );
  let progress = 0;
  if (conditionsArray.length === activeIndex) {
    progress = 100;
  } else if (conditions[activeIndex]) {
    progress =
      (((progressValue || 0) - Number(conditions[activeIndex])) * 100) /
        (Number(conditions[activeIndex + 1]) - Number(conditions[activeIndex])) /
        conditionsArray.length +
      (100 / conditionsArray.length) * activeIndex;
  } else {
    progress =
      ((progressValue || 0) * 100) / Number(conditions[activeIndex + 1]) / conditionsArray.length +
      (100 / conditionsArray.length) * activeIndex +
      1;
  }

  return <FlrProgress steps={arraySteps} progress={progress} activeIndex={activeIndex} />;
};

export default ProductGroupDiscountProgressCard;
