import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { getUserAccount } from 'store/account/selectors';
import { Balance } from './Balance';
import InCart from './InCart';
import { Orders } from './Orders';

interface IUserInfoProps {
  className: string;
}

const UserInfo: FC<IUserInfoProps> = ({ className }) => {
  const user = useSelector(getUserAccount);

  const balance = user ? user.profile.balance : 0;
  const availableBalance = user ? user.profile.availableFunds : 0;
  const ordersSummary = user ? user.profile.ordersSummary : null;
  return (
    <div className={className}>
      <InCart />
      <Balance balance={balance} availableCredit={availableBalance} />
      <Orders ordersSummary={ordersSummary} />
    </div>
  );
};

export default UserInfo;
