import React from "react";
import Skeleton from '@mui/material/Skeleton';

import { getViewportHeight } from "utils/helpers";
import { FiltersWrapper } from "./styles";

const HEADER_HEIGHT = 200;
const SKELETON_HEIGHT = 80;

export const SkeletonLeftBar: React.FC<any> = () => {
  const viewportHeight = getViewportHeight() - HEADER_HEIGHT; // Header
  const skeletonsCount = Math.max(Math.floor(viewportHeight / SKELETON_HEIGHT), 1); // height skeleton

  return (
    <FiltersWrapper>
      <Skeleton variant="rectangular" width={"80%"} height={42} style={{ margin: 16, marginTop: 24 }} />
      {[...Array(skeletonsCount)].map((_, i) => (
        <Skeleton key={i} variant="rectangular" width={"80%"} height={40} style={{ margin: 16, marginTop: 24 }} />
      ))}
    </FiltersWrapper>
  );
};

export default SkeletonLeftBar;
