import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import useStyles from './product-row-styles';

interface IProps {
  className?: string;
  style?: object;
}

export const ProductRowSkeleton: React.FC<IProps> = ({ style = {} }) => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Grid className={classes.rowContainer} container>
        <Grid item className={classes.mainContainer} wrap="wrap">
          <Skeleton variant="rectangular" width="70%" height={14} />
          <Skeleton variant="rectangular" width="60%" height={14} />
          <Skeleton variant="rectangular" width="51%" height={14} />
        </Grid>
        <Grid item container className={classes.price}>
          <Skeleton variant="rectangular" width="100%" height={16} />
        </Grid>
      </Grid>
    );
  }
  return (
    <div style={style}>
      <Grid className={classes.rowContainer} alignItems="center" wrap="nowrap" container>
        <Grid item className={classes.nameWrapper}>
          <Skeleton variant="rectangular" width="100%" height={16} />
        </Grid>
        <Grid item className={classes.firstAttrWrapper}>
          <Skeleton variant="rectangular" width="100%" height={16} />
        </Grid>
        <Grid item className={classes.manufacturer}>
          <Skeleton variant="rectangular" width="100%" height={16} />
        </Grid>
        <Grid item className={classes.attributesWrapper}>
          <Skeleton variant="rectangular" width="100%" height={16} />
        </Grid>
        <Grid item className={classes.categoryWrapper}>
          <Skeleton variant="rectangular" width="100%" height={16} />
        </Grid>
        <Grid item className={classes.price}>
          <Skeleton variant="rectangular" width="100%" height={16} />
        </Grid>
        <Grid item className={classes.discountBadgeHeader}>
          <Skeleton variant="rectangular" width={54} height={16} />
        </Grid>
      </Grid>
    </div>
  );
};
