import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { ICoordinates } from '../../contacts/OfficesMap';
import './style.css';

export interface IMapProps {
  address: string;
  place: ICoordinates;
  setPlace: (arg1: ICoordinates) => void;
}

const containerStyle = {
  width: '100%',
  height: '100%'
};

const Map = ({ address, place, setPlace }: any) => {
  const position = place.lng ? place : { lng: +place.longitude, lat: +place.latitude };
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={8}
      center={{ lat: 50.455999, lng: 30.544342 }} // Kyiv
      clickableIcons={true}
      onClick={(event: any) =>
        setPlace({
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        })
      }
    >
      {place && (
        <div className={'google-map-marker-wrapper'}>
          <Marker position={position} icon={'images/delivery.svg'}>
            {address && (
              <InfoWindow position={position}>
                <div>{address}</div>
              </InfoWindow>
            )}
          </Marker>
        </div>
      )}
    </GoogleMap>
  );
};

export default Map;
