import { ChangeEvent, FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import Icon from '../../Icon';

import classes from './PaginationTable.module.scss';

interface IPaginationTable {
  count: number;
  page: number;
  rowsPerPage: number;
}

const PaginationTable: FC<IPaginationTable> = ({ count, page, rowsPerPage }) => {
  const history = useHistory();
  const location = useLocation();

  const handlePageChange = (event: ChangeEvent<unknown>, newPage: number) => {
    const params = new URLSearchParams(location.search);
    params.set('page', newPage.toString());

    const query = decodeURIComponent(params.toString());
    history.push({ search: query });
  };

  return (
    <Pagination
      count={Math.ceil(count / rowsPerPage)}
      page={page}
      onChange={handlePageChange}
      color="standard"
      className={classes.paginationRoot}
      renderItem={(item) => (
        <PaginationItem
          {...item}
          components={{
            previous: () => <Icon size={24} type="arrowPagination" style={{ transform: 'rotate(180deg)' }} />,
            next: () => <Icon size={24} type="arrowPagination" style={{ transform: 'rotate(360deg)' }} />
          }}
          classes={{ root: classes.item, selected: classes.itemSelected }}
          selected={item.page === page}
        />
      )}
    />
  );
};

export { PaginationTable };
