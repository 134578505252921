const styles = (theme: any) => ({
  root: {
    padding: theme.spacing(2),

    [theme.breakpoints.down('lg')]: {
      padding: 0
    }
  },
  TabsRoot: {
    '&&': {
      marginLeft: theme.spacing(0)
    }
  },
  RootContent: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  ContentGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column'
    }
  },
  TabsContent: {
    '&&': {
      width: 375
    }
  },
  TabContent: {
    '&&': {
      maxWidth: '100%'
    }
  },

  Content: {
    flex: 1
  }
});

export default styles;
