import { ITheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  dialogTitleContainer: {
    paddingBottom: theme.spacing(1)
  },
  dialogTitle: {
    margin: 0,
    fontSize: 24
  }
});

export default styles;
