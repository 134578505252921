const styles = (theme: any) => ({
  root: {
    gridArea: 'promo',
    width: '100%',
    order: 10,

    [theme.breakpoints.up('md')]: {
      // minHeight: 470,
      order: 'unset'
    }
  },

  container: {
    position: 'relative',
    width: '100%',

    borderRadius: 8,
    backgroundColor: theme.palette.common.primaryClickablePressBackground,

    '&&': {
      padding: theme.spacing(3),
      paddingBottom: 20
    },

    [theme.breakpoints.up('md')]: {
      height: '100%',

      '&&': {
        padding: theme.spacing(5),
        paddingTop: theme.spacing(4.5)
      }
    },
    [theme.breakpoints.up('lg')]: {}
  },

  title: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    color: theme.palette.common.black,

    [theme.breakpoints.up('lg')]: {
      fontSize: 32
    }
  },
  count: {
    marginBottom: theme.spacing(3),
    color: theme.palette.common.black,

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4)
    }
  },
  linkButton: {}
});

export default styles;
