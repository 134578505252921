import { Store, createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
// import { createLogger } from "redux-logger";
// import { last } from "lodash";

// Import the state interface and our combined reducers/sagas.
import rootReducer, { IApplicationState } from "./reducers";
import { rootSaga } from "./sagas";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// ! TODO comment logger for dev env
// const logIgnoreActions: string[] = ["@@cart/SET_TIMER", "@@cart/hold/REQUEST", "@@cart/hold/RESPONSE_SUCCESS"];

// const logger = createLogger({
//   collapsed: true,
//   predicate: (getState, action) => {
//     if (logIgnoreActions.includes(action.type)) {
//       return false;
//     }
//     return true;
//   },
//   colors: {
//     title({ type }) {
//       const isSuccess = (action: string = ""): boolean => last(action.split("_")) === "SUCCESS";
//       const isError = (action: string = ""): boolean => last(action.split("_")) === "FAILURE";
//       if (isSuccess(type)) {
//         return "green";
//       } else if (isError(type)) {
//         return "red";
//       } else {
//         return "";
//       }
//     }
//   }
// });

export default function store(history: any, initialState: IApplicationState): Store<IApplicationState> {
  const sagaMiddleware = createSagaMiddleware({});
  const appStore = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
  );

  sagaMiddleware.run(rootSaga);

  return appStore;
}
