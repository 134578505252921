import { ChangeEvent, FC } from 'react';
import { useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';

import { ExpandPanel } from 'components/shared';
import { FlrRadio } from 'components/shared/form-elements/FlrRadio';
import { IFiltersData } from 'store/catalog/actions';
import { getFilterOptionsData } from 'store/filter-options/selectors';
import { filtersMessages } from 'translations/catalog/filters';

import FilterLabel from './FilterLabel';

function getStatus(filters: IFiltersData) {
  if (filters.inStock) {
    return 'inStock';
  } else if (filters.specialOffers) {
    return 'specialOffers';
  } else if (filters.transitOffers) {
    return 'transitOffers';
  } else if (filters.specialTransitOffers) {
    return 'specialTransitOffers';
  } else if (filters.preOrder) {
    return 'preOrder';
  } else {
    return '';
  }
}

interface IProps {
  filters: IFiltersData;
  handleFilterChange: (type: string) => (event: ChangeEvent<HTMLInputElement>, checked?: boolean) => void;
  label: string;
  classes: any;
}

export const StatusFilters: FC<IProps> = ({ label: labelSection, classes, filters, handleFilterChange }) => {
  const filterOptionsData = useSelector(getFilterOptionsData);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleFilterChange(event.target?.value)(event, true);
  };

  const stockStatuses = filterOptionsData.stockStatuses;
  const status = getStatus(filters);

  const labelComp = (
    <FilterLabel
      classes={classes}
      label={labelSection}
      defaultCaption={
        status && filtersMessages[`${status}Message`] ? filtersMessages[`${status}Message`].defaultMessage : ''
      }
    />
  );

  return (
    <ExpandPanel
      defaultExpanded={true}
      label={labelComp}
      className={classes.paddedExpandPanel}
      classNameSummary={classes.expansionSummary}
      classNameButton={classes.expansionButton}
    >
      <RadioGroup aria-label="status-filter" name="status-filter" value={status} onChange={handleChange}>
        <FormControlLabel
          value="inStock"
          control={<FlrRadio className={classes.statusFilterRadio} />}
          disabled={!stockStatuses?.inStock}
          label={`${filtersMessages.inStock.defaultMessage} (${stockStatuses.inStock || 0})`}
        />
        <FormControlLabel
          value="specialOffers"
          control={<FlrRadio className={classes.statusFilterRadio} />}
          disabled={!stockStatuses?.specialOffers}
          label={`${filtersMessages.specialOffers.defaultMessage} (${stockStatuses.specialOffers || 0})`}
        />
        <FormControlLabel
          value="transitOffers"
          control={<FlrRadio className={classes.statusFilterRadio} />}
          disabled={!stockStatuses?.transitOffers}
          label={`${filtersMessages.transitOffers.defaultMessage} (${stockStatuses.transitOffers || 0})`}
        />
        <FormControlLabel
          value="specialTransitOffers"
          control={<FlrRadio className={classes.statusFilterRadio} />}
          disabled={!stockStatuses?.specialTransitOffers}
          label={`${filtersMessages.specialTransitOffers.defaultMessage} (${stockStatuses.specialTransitOffers || 0})`}
        />
        <FormControlLabel
          value="preOrder"
          control={<FlrRadio className={classes.statusFilterRadio} />}
          disabled={!stockStatuses?.preOrder}
          label={`${filtersMessages.preOrder.defaultMessage} (${stockStatuses.preOrder || 0})`}
        />
      </RadioGroup>
    </ExpandPanel>
  );
};
