import * as React from 'react';
import styled from '@emotion/styled/macro';
import StepConnector, { StepConnectorProps } from '@mui/material/StepConnector';
import { IThemed } from '../../../utils/styled';

const FlrStepConnector = (props: StepConnectorProps) => {
  return <StyledStepConnector {...props} />;
};

const StyledStepConnector = styled<any>(StepConnector)(({ theme }: IThemed) => ({
  '&.MuiStepConnector': {
    '&-active, &-completed': {
      '& .MuiStepConnector-line': {
        borderColor: theme.palette.primary.main
      }
    }
  }
}));

export default FlrStepConnector;
