import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { getCatalogFilters, getCatalogLayoutFiltersOpened } from 'store/catalog/selectors';
import Icon from '../../shared/Icon';
import classes from './FilterButton.module.scss';
import { CATALOG_TOGGLE_FILTERS } from 'store/catalog/actions';

const FilterButton = () => {
  const dispatch = useDispatch();
  const filtersOpened = useSelector(getCatalogLayoutFiltersOpened);
  const filtersData = useSelector(getCatalogFilters);

  const toggleFilters = () => dispatch({ type: CATALOG_TOGGLE_FILTERS, payload: !filtersOpened });
  const filtersDataLength = filtersData.fast.length;

  return (
    <Button onClick={toggleFilters} className={classes.filterButton}>
      <Icon type="filter" size={14} opacity={1} className={classes.icon} offset={8} />
      <span className={classes.label}>Фільтри</span>
      &nbsp;
      {filtersDataLength > 0 ? <span className={classes.label}>({filtersDataLength})</span> : null}
    </Button>
  );
};

export default FilterButton;
