import { homeMaxWidth } from 'shared/constants';
import ImageBg from 'pages/main/assets/head.webp';

const styles = (theme: any) => ({
  departmentCard: {
    background: theme.palette.common.cardBlackBackground,
    boxShadow: 'none'
  },
  root: {
    margin: '0 auto',
    padding: '0 24px',
    [(theme as any).breakpoints.up('sm')]: {
      maxWidth: 600
    },
    [(theme as any).breakpoints.up('md')]: {
      maxWidth: 960
    },
    [(theme as any).breakpoints.up('lg')]: {
      maxWidth: 1280
    },
    [(theme as any).breakpoints.up('xl')]: {
      maxWidth: homeMaxWidth
    }
  },
  aboutContainer: {
    backgroundImage: `url(${ImageBg})`,
    backgroundPosition: '110px 0',
    backgroundSize: 'cover',
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      background: 'none'
    }
  },
  contactsContainer: {
    marginLeft: 40,

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  warehousesContainer: {
    padding: theme.spacing(4),
    flexWrap: 'nowrap',

    [theme.breakpoints.down('sm')]: {
      padding: 0,
      flexWrap: 'wrap',
      '& > div': {
        marginBottom: theme.spacing(2)
      }
    }
  }
});

export default styles;
