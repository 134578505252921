import React from "react";
import { withStyles } from "@mui/styles";
import styles from "./styles";
import { TextBody1 } from "components/shared/text";

interface IComponentProps {
  classes: any;
}

const AccountAssistFaq: React.FC<IComponentProps> = ({ classes }) => {
  return (
    <div className={classes.ContentGrid}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://docs.google.com/document/u/1/d/e/2PACX-1vT9kg48w35ZxEW3z_1SDA36we8WaMfCy0YHAVNvRtvODFrejDbeQVjZpj3t2Qhbm26spB6s-IpKVzA7/pub"
      >
        <TextBody1 style={{textDecoration: 'underline'}}>
          https://docs.google.com/document/u/1/d/e/2PACX-1vT9kg48w35ZxEW3z_1SDA36we8WaMfCy0YHAVNvRtvODFrejDbeQVjZpj3t2Qhbm26spB6s-IpKVzA7/pub
        </TextBody1>
      </a>
      {/*<Tabs*/}
      {/*  className={classes.TabsContent}*/}
      {/*  orientation={"vertical"}*/}
      {/*  value={tabValue}*/}
      {/*  onChange={handleTabChange}*/}
      {/*  // classes={tabsClasses}*/}
      {/*  indicatorColor="primary"*/}
      {/*>*/}
      {/*  {faqTopics.map(item => {*/}
      {/*    return (*/}
      {/*      <Tab*/}
      {/*        key={item.path}*/}
      {/*        className={classes.TabContent}*/}
      {/*        label={item.label}*/}
      {/*        component={Link}*/}
      {/*        to={`${assistRouteFaq}/${item.path}`}*/}
      {/*      />*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</Tabs>*/}

      {/*<div className={classes.Content}>*/}
      {/*  {faqTopics.map((item, index) => {*/}
      {/*    return (*/}
      {/*      tabValue === index && (*/}
      {/*        <div key={item.path}>*/}
      {/*          <H1>{item.label}</H1>*/}
      {/*          <LoremFish />*/}
      {/*        </div>*/}
      {/*      )*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</div>*/}
    </div>
  );
};

export default withStyles<any>(styles)(AccountAssistFaq);
