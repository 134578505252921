import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { getProductImage } from 'utils/helpers';
import { createCartItemQtySelector } from 'store/cart/selectors';
import { ITheme } from 'utils/styled';
import Icon from 'components/shared/Icon';
import clsx from 'clsx';

const productGradient = 'linear-gradient(57.47deg, #8E3FBE 0%, rgba(142, 63, 190, 0) 100%)';

interface IProps {
  isSelected: boolean;
  productId: string;
  isMobile?: boolean;
  productImages?: string[];
  productUnit: string;
  classNameWrapper?: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme & ITheme) =>
  createStyles({
    inCartWrapper: {
      position: 'absolute',
      width: '100%',
      height: '148px',
      background: theme.palette.common.helperBlack,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        height: '139px',
        borderRadius: '8px 8px 0 0'
      }
    },
    text: {
      fontSize: 14,
      color: theme.palette.common.white,
      textAlign: 'center',
      marginTop: 4
    },
    icon: {
      '& > svg > path': {
        stroke: '#fff',
      }
    }
  })
);

export const ProductCardImageCmp: React.FC<IProps> = ({
  productId,
  isSelected,
  productUnit,
  productImages,
  isMobile,
  classNameWrapper,
  className
}) => {
  const image = getProductImage({ images: productImages } as any);
  const classes = useStyles();

  const cartItemQty = useSelector(createCartItemQtySelector(productId));

  return (
    <div className={clsx(classNameWrapper, 'photo-container')}>
      <div
        className={className || 'photo'}
        style={{
          backgroundImage: `${isSelected ? `${productGradient},` : ''} url(${image})`,
          backgroundSize: isMobile ? 'cover' : ''
        }}
      >
        {!isSelected && cartItemQty > 0 ? (
          <div className={classes.inCartWrapper}>
            <Icon type='shoppingCartOne' opacity={1} className={classes.icon} />
            <div className={classes.text}>
              {cartItemQty} {productUnit}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const ProductCardImage = memo(ProductCardImageCmp);
