import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer, { IApplicationState } from "./reducers";
import { rootSaga } from "./sagas";
import { routerMiddleware } from "connected-react-router";

export default function configureStore(history: any, initialState: IApplicationState) {
  const sagaMiddleware = createSagaMiddleware({});
  const store = createStore(
    rootReducer(history),
    initialState,
    compose(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
