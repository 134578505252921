import createStyles from '@mui/styles/createStyles';

import {
  catalogProductTypeAccessories,
  catalogProductTypePotted,
  catalogProductTypeShear,
  catalogProductTypeStable
} from 'shared/constants';

const getStyles = (theme: any, rowLg: number, rowMd: number, rowSm: number) =>
  createStyles({
    wrapper: {
      maxWidth: 1717,
      margin: '0 auto',
      '@media (max-width: 1700px)': {
        maxWidth: '100%',
        margin: '0 103px'
      },
      '@media (max-width: 720px)': {
        margin: '0 16px'
      }
    },
    categoryRoot: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: 64,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),

      [theme.breakpoints.up('md')]: {
        // marginLeft: theme.spacing(2),
        // marginRight: theme.spacing(2),
        width: 'calc(100% - 16px)',
        marginLeft: 20,
        marginRight: 20,
        marginBottom: theme.spacing(12),
        paddingLeft: 0,
        paddingRight: 0
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(15)
      },
      '@media (max-width: 1700px)': {
        marginRight: 0,
        marginLeft: 0,
        width: '100%'
      }
    },

    categoriesProducts: {
      marginRight: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateRows: `${rowLg}px ${rowLg}px ${rowLg}px ${rowSm}px`,
      gap: '16px 16px',

      [theme.breakpoints.down('md')]: {
        margin: '16px -8px',
        gridTemplateColumns: '1fr 1fr'
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateRows: `${rowMd}px ${rowMd}px ${rowMd}px`,
        gridTemplateAreas:
          "'product0 promo product1' 'product2 promo product3' 'product4 product5 product6' 'product7 product8 product9'"
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridTemplateRows: `${rowMd}px ${rowMd}px ${rowMd}px ${rowMd}px`,
        gridTemplateAreas:
          "'product0 promo product1 product2' 'product3 promo product4 product5' 'product6 product7 product8 product9' 'product10 product11 product12 product13'"
      },
      [`&.${catalogProductTypeShear}`]: {
        gridTemplateAreas: "'product0 product1' 'product2 product3' 'product4 product5' 'promo promo'",
        [theme.breakpoints.up('md')]: {
          gridTemplateRows: `${rowMd}px ${rowMd}px ${rowMd}px ${rowMd}px`,
          gridTemplateAreas:
            // "'product0 promo' 'product1 promo' 'product2 product3' 'product4 product5' 'product6 product7' 'product6 product7'"
            "'product0 promo product1' 'product2 promo product3' 'product4 product5 product6' 'product7 product8 product9'"
        },
        [theme.breakpoints.up('lg')]: {
          gridTemplateAreas:
            "'product0 promo product1 product2' 'product3 promo product4 product5' 'product6 product7 product8 product9' 'product10 product11 product12 product13'"
        }
      },
      [`&.${catalogProductTypePotted}`]: {
        gridTemplateAreas: "'product0 product1' 'product2 product3' 'product4 product5' 'promo promo'",
        [theme.breakpoints.up('md')]: {
          gridTemplateAreas:
            "'product0 promo product1' 'product2 promo product3' 'product4 product5 product6' 'product7 product8 product9'"
        },
        [theme.breakpoints.up('lg')]: {
          gridTemplateAreas:
            "'product0 product1 product2 product3' 'product4 product5 promo product6' 'product7 product8 promo product9' 'product10 product11 product12 product13'"
        }
      },
      [`&.${catalogProductTypeStable}`]: {
        gridTemplateAreas: "'product0 product1' 'product2 product3' 'product4 product5' 'promo promo'",
        [theme.breakpoints.up('md')]: {
          gridTemplateAreas:
            "'product0 promo product1' 'product2 promo product3' 'product4 product5 product6' 'product7 product8 product9'"
        },
        [theme.breakpoints.up('lg')]: {
          gridTemplateAreas:
            "'product0 product1 product2 promo' 'product3 product4 product5 promo' 'product6 product7 product8 product9' 'product10 product11 product12 product13'"
        }
      },
      [`&.${catalogProductTypeAccessories}`]: {
        gridTemplateAreas: "'product0 product1' 'product2 product3' 'product4 product5' 'promo promo'",
        [theme.breakpoints.up('md')]: {
          gridTemplateAreas:
            "'product0 promo product1' 'product2 promo product3' 'product4 product5 product6' 'product7 product8 product9'"
        },
        [theme.breakpoints.up('lg')]: {
          gridTemplateAreas:
            "'product0 product1 product2 product3' 'promo product4 product5 product6' 'promo product7 product8 product9' 'product10 product11 product12 product13'"
        }
      }
    },
    categoryHeader: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(3)
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(5)
      },
      [theme.breakpoints.down('md')]: {
        alignItems: 'center'
      }
    },
    categoryTitleContainer: {
      paddingTop: theme.spacing(2),
      paddingLeft: 6,
      [theme.breakpoints.up('md')]: {
        paddingLeft: 0,
        marginLeft: theme.spacing(-4)
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '29px',
      }
    },
    categoryTitle: {
      marginBottom: theme.spacing(0),
      color: theme.palette.common.black,

      [theme.breakpoints.up('md')]: {
        fontSize: 48
      }
    },
    titleLink: {
      marginLeft: 'auto'
    },

    // default imagePromoCard used for type1, other types rewrite only specific rules
    imagePromoCard: {
      position: 'absolute',
      objectFit: 'contain',
      [`&.${catalogProductTypeShear}`]: {
        bottom: -21,
        right: -14,

        width: 134,
        height: 128,

        // eslint-disable-next-line
        ['@media (min-width:375px)']: {
          bottom: -11,
          right: -14,
          width: 154,
          height: 128
        },

        [theme.breakpoints.up('md')]: {
          right: 'unset',

          bottom: -24,
          left: -22,

          width: 351,
          height: 224
        },
        [theme.breakpoints.up('lg')]: {}
      },
      [`&.${catalogProductTypePotted}`]: {
        left: 'unset',
        bottom: -24,
        right: 26,

        width: 88,
        height: 176,

        [theme.breakpoints.up('md')]: {
          bottom: -18,
          right: -2,

          width: 200,
          height: 400
        },
        [theme.breakpoints.up('lg')]: {
          right: -11,

          width: 212,
          height: 423
        }
      },
      [`&.${catalogProductTypeStable}`]: {
        left: 'unset',
        bottom: -32,
        right: 0,

        width: 99,
        height: 160,

        [theme.breakpoints.up('md')]: {
          bottom: -40,
          right: 0,

          width: 204,
          height: 329
        },
        [theme.breakpoints.up('lg')]: {
          bottom: -82,
          right: -11,

          width: 212,
          height: 423
        }
      },
      [`&.${catalogProductTypeAccessories}`]: {
        bottom: -42,
        right: -30,

        width: 185,
        height: 165,

        [theme.breakpoints.up('md')]: {
          right: 'unset',

          bottom: -112,
          left: -28,

          width: 491,
          height: 408
        }
      }
    },
    productCardSkeleton: {
      gridArea: 'product',
      height: 'auto'
    },
    productCardWrapper: {
      gridArea: 'product',
      textDecoration: 'none',

      '&:hover, &:active': {
        textDecoration: 'none'
      }
    },
    productCard: {
      [theme.breakpoints.up('md')]: {}
    },
    productCardMargin: {
      marginBottom: theme.spacing(2)
    }
  });

export default getStyles;
