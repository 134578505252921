import { FC } from 'react';

import { GridDivider } from 'components/shared/table/dividers';
import { Link3, TextBody2 } from 'components/shared/text';
import { Transaction } from 'models/transaction';
import messages from 'translations/account/finance';

import classes from './DetailsExpandPanel.module.scss';

interface IDetailsExpandPanelProps {
  data: Transaction;
}

const DetailsExpandPanel: FC<IDetailsExpandPanelProps> = ({ data }) => {
  const orderId = data.operation && data.operation.on ? data.operation.on.orderId : '';
  const paymentId = data.operation && data.operation.on ? data.operation.on.paymentId : '';

  return (
    <div className={classes.container}>
      <div className={classes.expandedMobileRow}>
        <TextBody2 color="textSecondary">{messages.transactionNumber.defaultMessage}</TextBody2>
        <TextBody2 align="right">
          {data.operationType === 'debit' ? (
            `№ ${paymentId}`
          ) : (
            <Link3 to={`orders/${orderId}`}>№ {orderId}</Link3>
          )}
        </TextBody2>
      </div>
      <GridDivider />
      <div className={classes.expandedMobileRow}>
        <TextBody2 color="textSecondary">{messages.date.defaultMessage}</TextBody2>
        <TextBody2 color="textSecondary" align="right">
          {data.dateString}
        </TextBody2>
      </div>
    </div>
  );
};

export default DetailsExpandPanel;
