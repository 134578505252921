import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';

import { Grid, Hidden } from '@mui/material';
import { withStyles } from '@mui/styles';

import { MainPromo } from 'models/mainPromo';
import { IApplicationState } from 'store/reducers';
import { getContactsData, getMainPromoData } from 'store/main/selectors';
import { IMainContactsData, mainPromoAsync } from 'store/main/actions';

import { TitleH1 } from 'components/shared/text';

// import ImagePromo from "../assets/promo.webp";
// import ImagePromoMobile from "../assets/promo-mobile.webp";
// import ImagePromoTablet from "../assets/promo-tablet.webp";

import styles from './styles';

// import messages from "translations/main/promo";

interface IOwnProps {
  // put own props here
}

interface IStateProps {
  mainPromo: MainPromo | null;
  contacts: IMainContactsData | null;
  classes?: any;
}

interface IDispatchProps {
  loadPromo: typeof mainPromoAsync.request;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const MainPromoComponent: React.FC<IProps> = ({ classes, contacts, mainPromo, loadPromo }) => {
  React.useEffect(() => {
    loadPromo();
  }, [loadPromo]);

  const advantagesContent = contacts && contacts.advantages && contacts.advantages.value;
  if (!mainPromo) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <Grid container className={classes.root} component={'section'} spacing={2}>
        <Grid item xs={1} implementation="css" smDown component={Hidden} />

        <Grid item sm={10} lg={10} className={classes.container}>
          <img
            className={classes.image}
            src={mainPromo.img}
            // srcSet={`${ImagePromoMobile} 300w, ${ImagePromoTablet} 960w, ${ImagePromo} 1280w`}
            alt={''}
          />

          <TitleH1 className={classes.title}>{mainPromo.title}</TitleH1>

          <div
            className={classes.contentWrapper}
            dangerouslySetInnerHTML={{ __html: advantagesContent || mainPromo.text }}
          />
        </Grid>

        {/*<Grid item sm={10} lg={10} className={classes.container}>*/}
        {/*  <img*/}
        {/*    className={classes.image}*/}
        {/*    src={ImagePromoMobile}*/}
        {/*    srcSet={`${ImagePromoMobile} 300w, ${ImagePromoTablet} 960w, ${ImagePromo} 1280w`}*/}
        {/*    alt={""}*/}
        {/*  />*/}

        {/*  <H1 className={classes.title} component={"h2"}>*/}
        {/*    {messages.title.defaultMessage}*/}
        {/*  </H1>*/}

        {/*  <div className={classes.contentWrapper}>*/}
        {/*    <H2 className={classes.subTitle} component={"h3"}>*/}
        {/*      {messages.subTitle1.defaultMessage}*/}
        {/*    </H2>*/}
        {/*    <TextBody1 className={classes.text}>{messages.text1.defaultMessage}</TextBody1>*/}
        {/*    <TextBody1>{messages.text1_2.defaultMessage}</TextBody1>*/}

        {/*    <H2 className={classes.subTitle} component={"h3"}>*/}
        {/*      {messages.subTitle2.defaultMessage}*/}
        {/*    </H2>*/}
        {/*    <TextBody1 className={classes.text}>{messages.text2.defaultMessage}</TextBody1>*/}
        {/*    <TextBody1>{messages.text2_2.defaultMessage}</TextBody1>*/}

        {/*    <H2 className={classes.subTitle} component={"h3"}>*/}
        {/*      {messages.subTitle3.defaultMessage}*/}
        {/*    </H2>*/}
        {/*    <TextBody1>{messages.text3.defaultMessage}</TextBody1>*/}
        {/*  </div>*/}
        {/*</Grid>*/}
      </Grid>
    </div>
  );
};

// export default withStyles<any>(styles)(MainPromoComponent);

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state: IApplicationState) => ({
  mainPromo: getMainPromoData(state),
  contacts: getContactsData(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      loadPromo: mainPromoAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(MainPromoComponent as any));
