import { call, put, takeEvery } from 'redux-saga/effects';
import { createAsyncAction, createReducer, PayloadAction } from 'typesafe-actions';

import { IFilterAttribute, IFilterOptions, IFilterOptionsRequest } from 'models/filter-options';
import { categories } from 'shared/constants';
import {
  defaultDataWrapper,
  errorDataWrapper,
  IAsyncDataWrapper,
  loadedDataWrapper,
  loadingDataWrapper,
  REQUEST_ACTIONS
} from 'store/actions';

import { FilterOptionsRepository } from './request';

export const prefix = '@@filterOptions/';

export const FILTER_OPTIONS_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const FILTER_OPTIONS_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const FILTER_OPTIONS_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

export const SET_FILTER_OPTIONS = `${prefix}SET_OPTIONS`;

export type IFilterOptionsState = IAsyncDataWrapper<IFilterOptions>;

export const filterOptionsInitialState: IFilterOptionsState = defaultDataWrapper({
  attributes: [],
  price: [],
  type: [],
  group: [],
  catalogCategory: [],
  suppliers: [],
  regions: [],
  stockStatuses: {
    inStock: 0,
    preOrder: 0,
    specialOffers: 0,
    specialTransitOffers: 0,
    transitOffers: 0
  }
});

export const fetchFilterOptionsAsync = createAsyncAction(
  FILTER_OPTIONS_REQUEST,
  FILTER_OPTIONS_SUCCESS,
  FILTER_OPTIONS_FAILURE
)<IFilterOptionsRequest, IFilterOptions, Error>();

const httpClient = new FilterOptionsRepository();

function* filterOptionsSaga(action: ReturnType<typeof fetchFilterOptionsAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.fetch(action.payload));
    (response as IFilterOptions).type.forEach((typeOption) => {
      for (const alias of Object.keys(categories)) {
        if (typeOption && categories[alias] === typeOption.name) {
          typeOption.alias = alias;
          return typeOption;
        }
      }
    });
    // sort regions by name
    response.regions = (response as IFilterOptions).regions.sort((a, b) => a.name.localeCompare(b.name));
    yield put(fetchFilterOptionsAsync.success(response));
  } catch (err) {
    yield put(fetchFilterOptionsAsync.failure(err as Error));
  }
}

export function* filterOptionsRequestSaga() {
  yield takeEvery(fetchFilterOptionsAsync.request, filterOptionsSaga);
}

export default createReducer(filterOptionsInitialState)
  .handleAction(
    fetchFilterOptionsAsync.success,
    (state: IFilterOptionsState, action: PayloadAction<typeof FILTER_OPTIONS_SUCCESS, IFilterOptions>) =>
      loadedDataWrapper({
        ...state.data,
        ...action.payload
      })
  )
  .handleAction(fetchFilterOptionsAsync.request, (state: IFilterOptionsState) => loadingDataWrapper(state.data))
  .handleAction(fetchFilterOptionsAsync.failure, (state: IFilterOptionsState) =>
    errorDataWrapper(state.data, new Error('Failed to load filterOptions'))
  )
  .handleAction(
    SET_FILTER_OPTIONS,
    (state: IFilterOptionsState, action: PayloadAction<typeof FILTER_OPTIONS_SUCCESS, IFilterAttribute[]>) => ({
      ...state,
      data: {
        ...state.data,
        attributes: action.payload
      }
    })
  );
