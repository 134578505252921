import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { Paper } from '@mui/material';
import { withStyles } from '@mui/styles';

interface IProps {
  id?: string;
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  classes?: any;
  color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  hoverShadow?: boolean;
  zeroPadding?: boolean;
  elevation?: number;
}

const FlrCard: React.FC<IProps> = ({ children, hoverShadow, zeroPadding, classes = {}, className, ...rest }) => {
  return (
    <Paper
      classes={{
        root: clsx(classes.root, className, {
          [classes.rootShadow]: hoverShadow,
          [classes.rootZeroPadding]: zeroPadding
        })
      }}
      {...rest}
    >
      {children}
    </Paper>
  );
};

const styles = (theme: any) => ({
  root: {
    paddingTop: theme.spacing(2.2),
    paddingBottom: theme.spacing(2.2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: theme.spacing(1),
    ...theme.shadow.cardActive,

    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  rootShadow: {
    '&:hover': {
      ...theme.shadow.cardHover
    }
  },
  rootZeroPadding: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0
  }
});

export default withStyles<any>(styles)(FlrCard);
