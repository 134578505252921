import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';

import App from './app';
// import * as serviceWorker from "./serviceWorker";
import configureStore from './store/configureStore';
import { initialState } from 'store/reducers';
import { GTM } from 'controllers';

// import "typeface-ibm-plex-sans";
import './styles';
import { Provider } from 'react-redux';

const history = createBrowserHistory({
  basename: '/'
});
export const store = configureStore(history, initialState);

// initialize Google Tag Manager
GTM.init('GTM-MB6N7QG');

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
