import * as React from 'react';
import { withTheme } from '@mui/styles';
import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';

const FlrInputPhone: React.FC<any> = (props: any) => {
  const onChange = (e: any) => {
    props.onChange(e.target.value);
  };
  return (
    <InputMask {...props} mask="+99 (999) 999 99 99" onChange={onChange}>
      {(maskProps: any) => <TextField {...maskProps} type={'tel'} />}
    </InputMask>
  );
};

export default withTheme(FlrInputPhone);
