import { FC, useCallback, useMemo } from 'react';

import { SplitedPrice } from 'components/prices';
import { TextBody2, TitleH1 } from 'components/shared/text';
import IconCartTimer from 'components/timer/IconCartTimer';
import { CartItem, Product } from 'models';
import messages from 'translations/cart/common';

import MiniCartDetailsCardItem from './MiniCartDetailsCardItem';

import classes from './MiniCartDetailsCard.module.scss';

interface IProps {
  timerType?: 'inStock' | 'transit' | undefined;
  items: CartItem[];
  onUpdate: (items: CartItem[]) => void;
  preOrder?: boolean;
  transit?: boolean;
}

interface IProductWithItems {
  product: Product;
  items: CartItem[];
  qty: number;
  total: number;
}

const MiniCartDetailsCard: FC<IProps> = ({ items, preOrder, transit, onUpdate, timerType }) => {
  const totalQty = items.reduce((t: number, item: CartItem) => t + item.qty, 0);
  const totalSum = items.reduce((t: number, item: CartItem) => t + item.total, 0);

  const getHeaderTitle = useCallback(() => {
    if (transit) {
      return messages.transitOrders.defaultMessage;
    }
    if (preOrder) {
      return messages.preOrders.defaultMessage;
    }
    return messages.orders.defaultMessage;
  }, [preOrder, transit]);

  const productsWithItems = useMemo(() => {
    // tslint:disable-next-line:variable-name
    const _productsWithItems: Record<string, IProductWithItems> = {};
    items.forEach((item) => {
      const itemProduct = new Product({ ...item.product });
      const productId = (item.offer && item.offer.productId) || itemProduct.id;
      itemProduct.id = productId;

      if (!_productsWithItems.hasOwnProperty(productId)) {
        _productsWithItems[productId] = {
          product: itemProduct,
          items: [item],
          qty: item.qty,
          total: item.total
        };
      } else {
        _productsWithItems[productId].items.push(item);
        _productsWithItems[productId].qty += item.qty;
        _productsWithItems[productId].total += item.total;
      }
    });
    return _productsWithItems;
  }, [items]);

  const totalUniqueProducts = Object.keys(productsWithItems).length;

  return (
    <div className={classes.miniCartCard}>
      <div className={classes.miniCartCardHeader}>
        <TitleH1 component="h5" className={classes.title}>
          {getHeaderTitle()}
        </TitleH1>
        {!preOrder ? <IconCartTimer type={timerType} className={classes.miniCartCardHeaderTimer} /> : null}
      </div>

      <div className={classes.container}>
        <div className={classes.labels}>
          <TextBody2 className={classes.label}>Товар ({items.length})</TextBody2>
          <TextBody2 className={classes.label}>Сума</TextBody2>
        </div>
        <div className={classes.content}>
          {Object.keys(productsWithItems).map((productId: string, index: number) => {
            const product = productsWithItems[productId].product;
            return (
              <MiniCartDetailsCardItem
                prefix={preOrder ? 'preOrder' : ''}
                key={`rb-${product.id}-${index}`}
                allItemsCount={totalUniqueProducts}
                index={index}
                onApplyEdit={onUpdate}
                {...productsWithItems[productId]}
              />
            );
          })}

          <div className={classes.miniCartCardSummary}>
            <TextBody2 color={'inherit'} className={classes.totalSumLabel}>
              {preOrder ? messages.totalToPreOrder.defaultMessage : messages.totalToOrder.defaultMessage}
            </TextBody2>
            <div className={classes.summaryInfo}>
              <SplitedPrice value={totalQty} bold={false} fontSize={'body2'} hideDecimal={true} postfix={'шт'} className={classes.totalQtyValue}/>
              <SplitedPrice fontColor={'inherit'} value={totalSum} fontSize="h3" fontSizeDecimal="h5" className={classes.totalSum}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniCartDetailsCard;
