import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Box, Button, Grid, Hidden } from '@mui/material';
import { Column } from 'material-table';
import { withStyles } from '@mui/styles';

import { Outlet } from 'models/outlet';
import { fetchOutletsAsync, removeOutletAsync, updateOutletAsync } from 'store/outlets/actions';
import { IApplicationState } from 'store/reducers';
import { getOutletsData, getOutletsLoadingState } from 'store/outlets/selectors';

import { FlrTable } from 'components/shared/table';
import FlrInputPhone from 'components/shared/form-elements/textfield/FlrInputPhone';
import DropMenu, { IDropMenuItem } from 'components/shared/DropMenu';
import Icon from 'components/shared/Icon';
import { TitleH2, Link2, TextBody2, TextCaption, TextHelper } from 'components/shared/text';

import messages from 'translations/account/settings';

import styles from './styles';

const columns: Array<Column<Outlet>> = [
  { title: messages.name.defaultMessage, field: 'name', sorting: false },
  {
    title: messages.phone.defaultMessage,
    field: 'phone',
    sorting: false,
    editComponent: props => <FlrInputPhone {...props} />
  },
  {
    title: messages.address.defaultMessage,
    field: 'address',
    sorting: false
  }
];

const columnsMobile: Array<Column<Outlet>> = [
  {
    title: messages.name.defaultMessage,
    field: 'name',
    sorting: false,
    render: outlet => {
      return (
        <Grid container spacing={1} direction={'column'}>
          <Grid item>
            <TextCaption>{outlet.name}</TextCaption>
          </Grid>
          <Grid item>
            <TextBody2>{outlet.address}</TextBody2>
          </Grid>
          <Grid item>
            <TextHelper>{outlet.phone}</TextHelper>
          </Grid>
        </Grid>
      );
    }
  }
];

interface IProps {
  // component own props
  classes: any;
  toggleAddOutletModal: () => void;
  toggleEditOutletModal: (id: string, clearAfter?: boolean) => void;
  confirmDeleteOutlet: (outlet: Outlet, clearAfter?: boolean) => void;
}

interface IStateProps {
  // Props passed to the component by `connect`
  outlets: Outlet[];
  outletsLoadingState: boolean;
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
  loadOutlets: typeof fetchOutletsAsync.request;
  removeOutlet: typeof removeOutletAsync.request;
  updateOutlet: typeof updateOutletAsync.request;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

class Table extends React.Component<IComponentProps> {
  constructor(props: IComponentProps) {
    super(props);
    this.onRemoveOutlet = this.onRemoveOutlet.bind(this);
    this.onUpdateOutlet = this.onUpdateOutlet.bind(this);
  }

  public componentDidMount(): void {
    this.props.loadOutlets();
  }

  public onRemoveOutlet = (outlet: Outlet) => {
    return new Promise<void>(resolve => {
      this.props.removeOutlet(outlet.id);
      this.props.loadOutlets();
      resolve();
    });
  };

  public onUpdateOutlet = (newData: Outlet | undefined) => {
    return new Promise<void>(resolve => {
      if (newData) {
        this.props.updateOutlet(newData);
        this.props.loadOutlets();
        resolve();
      }
    });
  };

  public render() {
    const {
      classes,
      outlets,
      outletsLoadingState,
      toggleAddOutletModal,
      toggleEditOutletModal,
      confirmDeleteOutlet
    } = this.props;

    const commonProps = {
      data: Array.isArray(outlets) ? outlets : [outlets],
      isLoadingExternal: outletsLoadingState,
      components: {
        Toolbar: () => (
          <Box className={classes.addressBar}>
            <TitleH2 align={'left'} className={classes.outletHead}>
              {messages.addressTitle.defaultMessage}
              <Hidden smUp>
                <Link2 onClick={toggleAddOutletModal}>{messages.addOutletBtnLabel.defaultMessage}</Link2>
              </Hidden>
              <Hidden smDown>
                <Button color={'primary'} onClick={toggleAddOutletModal}>
                  {messages.addAddressButton.defaultMessage}
                </Button>
              </Hidden>
            </TitleH2>
            <Hidden smDown>
              <TextBody2 className={classes.outletHeadCaption}>{messages.addressSubtitle.defaultMessage}</TextBody2>
            </Hidden>
          </Box>
        )
      }
    };
    const toggledTableActions = [
      (outlet: Outlet) => ({
        icon: () => {
          const options: IDropMenuItem[] = [];
          options.push({
            text: messages.editButton.defaultMessage,
            icon: <Icon type={'edit'} size={24} />,
            onClick: () => toggleEditOutletModal(outlet.id, true)
          });
          options.push({
            text: messages.deleteBtnLabel.defaultMessage,
            icon: <Icon type={'trash'} size={24} />,
            onClick: () => confirmDeleteOutlet(outlet, true)
          });
          return <DropMenu options={options} />;
        },
        onClick: () => null
      })
    ];
    return (
      <React.Fragment>
        <Hidden smDown>
          <FlrTable
            columns={columns}
            editable={{
              onRowDelete: this.onRemoveOutlet
            }}
            {...commonProps}
            actions={[
              {
                icon: () => <Icon type={'edit'} size={24} style={{ color: '#000' }} />,
                onClick: (event: any, rowData: Outlet) => {
                  toggleEditOutletModal(rowData.id);
                }
              }
            ]}
          />
        </Hidden>
        <Hidden smUp>
          <FlrTable
            columns={columnsMobile}
            options={{ header: false, paging: false, pageSize: 10, actionsColumnIndex: -1 }}
            actions={toggledTableActions}
            {...commonProps}
          />
        </Hidden>
      </React.Fragment>
    );
  }
}

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  outlets: getOutletsData(state),
  outletsLoadingState: getOutletsLoadingState(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      loadOutlets: fetchOutletsAsync.request,
      removeOutlet: removeOutletAsync.request,
      updateOutlet: updateOutletAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(Table as any));
