import { Product } from 'models/product';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserAccount } from 'store/account/selectors';
import { GTM } from 'controllers';

export function getProductsFields(data: Product) {
  const { fullName: name, code, catalogCategory, productType, attributes, preOrderPrice: price } = data;
  const category: string = `${productType}/${catalogCategory}`;
  const variant: string = attributes && attributes.color ? attributes.color.value : '';
  const brand: string = attributes && attributes.manufacturer ? attributes.manufacturer.value : '';
  const id = code.replace(/^0+(?!$)/, '');

  return { name, id, price, brand, category, variant };
}

export function useTrackInitialPageLoad() {
  const userData = useSelector(getUserAccount);

  useEffect(() => {
    if (userData && userData.email) {
      GTM.trackPage(userData.email);
    }
    // eslint-disable-next-line
  }, []);
}
