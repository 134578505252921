export const colorsMap = {
  Блакитний: '#31b1e0',
  Бордо: '#4a0021',
  Бузковий: '#61419b',
  Біколор: 'linear-gradient(180deg, #52ACFF 0%, #FFE32C 100%)',
  Білий: '#FFFFFF',
  Жовтий: '#f8da37',
  Зелений: '#1a754b',
  Золотий: '#ffd700',
  Кораловий: '#f53c42',
  Коричневий: '#752624',
  Кремовий: '#f8e7d9',
  Лососевий: '#f77c79',
  Малиновий: '#d2244d',
  Мідний: '#b87333',
  Мікс: 'linear-gradient(45deg, rgb(250, 139, 255) 29%, rgb(43, 210, 255) 89%, rgb(43, 255, 136) 105%)',
  Персиковий: '#eeb389',
  Помаранчевий: '#e77c21',
  Рожевий: '#f783be',
  'Світло-рожевий': '#f8d5d5',
  Синій: '#00249f',
  Сірий: '#92959c',
  Срібний: '#C0C0C0',
  Терракотовий: '#db401a',
  Фарбований: 'linear-gradient(135deg, #00C9FF 0%, #92FE9D 100%)',
  Фіолетовий: '#b11597',
  Червоний: '#eb2739',
  Чорний: '#050304',
  Алюміній: '#8F8F8F',
  Бежевий: '#f5f5dc',
  Бірюзовий: '#30D5C8',
  Бронзовий: '#cd7f32',
  Бурштиновий: '#FFBF00',
  Гірчичний: '#ffdb58',
  Графітовий: '#494d4e',
  Лавандовий: '#e6e6fa',
  Марсала: '#975a58',
  Оливковий: '#808000',
  Прозорий: 'transparent',
  Салатовий: '#7fff00',
  'Світло сірий': '#d8d8d8',
  'Слонова кістка': '#fffff0',
  Хакі: '#78866b',
  Малина: '#c82361',
  'Темно сірий': '#cbcbcb',
  Інший: 'transparent',
  Небесний: '#009BDD',
  Мандариновий: '#FFB200',
  Лаймовий: '#CDDC39',
  Ягідний: '#E91E63',
  'Залізно-сірий': '#333',
  other: 'transparent',
  default: '#FFFFFF'
};
