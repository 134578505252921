import { createReducer, PayloadAction, createAsyncAction, createAction } from 'typesafe-actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  loadedDataWrapper,
  IAsyncDataWrapper,
  REQUEST_ACTIONS,
  UPDATE_REQUEST_ACTIONS,
  refreshDataWrapper,
  loadingDataWrapper,
  errorDataWrapper
} from 'store/actions';
import { cartSaga, setCartMobileTab } from 'store/cart/actions';
import { orderSaga } from 'store/order/actions';
import { accountSaga } from 'store/account/actions';

import { IRequestCartItem, Order, ORDER_TYPE_PREORDER } from 'models';
import { CheckoutRepository } from './request';
import { baseUrl, UAH } from 'shared/constants';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from '../layout';
import messages from 'translations/checkout/common';
import { getCheckoutOrders } from './selectors';

export const prefix = '@@cart/checkout/';

export const CHECKOUT_CANCEL = `${prefix}CANCEL`;
export const CHECKOUT_SELECT_ORDER = `${prefix}SELECT_ORDER`;
export const CHECKOUT_SELECT_ORDER_ITEM = `${prefix}SELECT_ORDER_ITEM`;
export const CHECKOUT_UNSELECT_ORDER_ITEM = `${prefix}UNSELECT_ORDER_ITEM`;
export const CHECKOUT_UPDATE_ORDER_ITEM = `${prefix}UPDATE_ORDER_ITEM`;

export const CHECKOUT_RENEW = `${prefix}RENEW_${REQUEST_ACTIONS.REQUEST}`;
export const CHECKOUT_RENEW_SUCCESS = `${prefix}RENEW_${REQUEST_ACTIONS.SUCCESS}`;
export const CHECKOUT_RENEW_FAILURE = `${prefix}RENEW_${REQUEST_ACTIONS.FAILURE}`;

export const CHECKOUT_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const CHECKOUT_REQUEST_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const CHECKOUT_REQUEST_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

export const CHECKOUT_ORDERS_UPDATE = `${prefix}${UPDATE_REQUEST_ACTIONS.REQUEST}`;
export const CHECKOUT_ORDERS_UPDATE_SUCCESS = `${prefix}${UPDATE_REQUEST_ACTIONS.SUCCESS}`;
export const CHECKOUT_ORDERS_UPDATE_FAILURE = `${prefix}${UPDATE_REQUEST_ACTIONS.FAILURE}`;

export const CHECKOUT_ORDER_FINALIZE = `${prefix}FINALIZE_${REQUEST_ACTIONS.REQUEST}`;
export const CHECKOUT_ORDER_FINALIZE_SUCCESS = `${prefix}FINALIZE_${REQUEST_ACTIONS.SUCCESS}`;
export const CHECKOUT_ORDER_FINALIZE_FAILURE = `${prefix}FINALIZE_${REQUEST_ACTIONS.FAILURE}`;

export const CHECKOUT_ORDER_FINALIZE_ALL = `${prefix}FINALIZE_ALL_${REQUEST_ACTIONS.REQUEST}`;
export const CHECKOUT_ORDER_FINALIZE_ALL_SUCCESS = `${prefix}FINALIZE_ALL_${REQUEST_ACTIONS.SUCCESS}`;
export const CHECKOUT_ORDER_FINALIZE_ALL_FAILURE = `${prefix}FINALIZE_ALL_${REQUEST_ACTIONS.FAILURE}`;

export const CHECKOUT_FINALIZE_TRANSIT_REQUEST = `${prefix}FINALIZE_TRANSIT_REQUEST`;
export const CHECKOUT_FINALIZE_TRANSIT_SUCCESS = `${prefix}FINALIZE_TRANSIT_SUCCESS}`;
export const CHECKOUT_FINALIZE_TRANSIT_FAILURE = `${prefix}FINALIZE_TRANSIT_FAILURE`;

export interface ISetActiveOrder {
  index: number;
  shouldScroll?: boolean;
}

interface ICheckout {
  finalizingOrders: boolean;
  selectedOrderItem: string;
  activeOrder: ISetActiveOrder;
  orders: Order[];
  finalizedOrders: Order[];
}

export type ICheckoutState = IAsyncDataWrapper<ICheckout>;

export const checkoutInitialState: ICheckoutState = {
  loading: false,
  loaded: false,
  data: {
    finalizingOrders: false,
    selectedOrderItem: '',
    activeOrder: { index: 0 },
    orders: [],
    finalizedOrders: []
  },
  error: null
};

export const cancelCheckout = createAction(CHECKOUT_CANCEL)<void>();
export const setActiveOrder = createAction(CHECKOUT_SELECT_ORDER)<ISetActiveOrder>();
export const selectOrderItem = createAction(CHECKOUT_SELECT_ORDER_ITEM)<string>();
export const unselectOrderItem = createAction(CHECKOUT_UNSELECT_ORDER_ITEM)<void>();
export const updateOrderItem = createAction(CHECKOUT_UPDATE_ORDER_ITEM)<Order>();

export const renewCheckoutAsync = createAsyncAction(CHECKOUT_RENEW, CHECKOUT_RENEW_SUCCESS, CHECKOUT_RENEW_FAILURE)<
  string,
  Order[],
  Error
>();

export const fetchCheckoutAsync = createAsyncAction(
  CHECKOUT_REQUEST,
  CHECKOUT_REQUEST_SUCCESS,
  CHECKOUT_REQUEST_FAILURE
)<string, Order[], Error>();

export const updateOrdersAsync = createAsyncAction(
  CHECKOUT_ORDERS_UPDATE,
  CHECKOUT_ORDERS_UPDATE_SUCCESS,
  CHECKOUT_ORDERS_UPDATE_FAILURE
)<Order[], Order[], Error>();

export const finalizeAllOrdersAsync = createAsyncAction(
  CHECKOUT_ORDER_FINALIZE_ALL,
  CHECKOUT_ORDER_FINALIZE_ALL_SUCCESS,
  CHECKOUT_ORDER_FINALIZE_ALL_FAILURE
)<string, void, Error>();

export const finalizeOrderAsync = createAsyncAction(
  CHECKOUT_ORDER_FINALIZE,
  CHECKOUT_ORDER_FINALIZE_SUCCESS,
  CHECKOUT_ORDER_FINALIZE_FAILURE
)<Order, Order, Error>();

export const finalizeTransitAsync = createAsyncAction(
  CHECKOUT_FINALIZE_TRANSIT_REQUEST,
  CHECKOUT_FINALIZE_TRANSIT_SUCCESS,
  CHECKOUT_FINALIZE_TRANSIT_FAILURE
)<IRequestCartItem, void, Error>();

const httpClient = new CheckoutRepository();

function* cartResetMobileTab(): Generator {
  yield put(setCartMobileTab(-1));
}

function* checkoutRedirectSaga() {
  yield put(push(`${baseUrl}/checkout`));
}

function* checkoutRedirectToCartSaga() {
  yield put(push(`${baseUrl}/cart`));
}

function* renewCheckoutSaga(action: ReturnType<typeof renewCheckoutAsync.request>): Generator {
  try {
    if (!action.payload) {
      return yield put(renewCheckoutAsync.success([]));
    }
    const response: any = yield call(() => httpClient.renew(action.payload));
    yield put(renewCheckoutAsync.success(response));
  } catch (err) {
    yield put(renewCheckoutAsync.failure(err as Error));
  }
}

function* checkoutSaga(action: ReturnType<typeof fetchCheckoutAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.fetch());
    if (Array.isArray(response)) {
      yield put(fetchCheckoutAsync.success(response.map((item: any) => new Order(item))));
    }
  } catch (err) {
    yield put(fetchCheckoutAsync.failure(err as Error));
  }
}

function* finalizeOrderSaga(action: ReturnType<typeof finalizeOrderAsync.request>): Generator {
  try {
    const ordersFromStore: any = yield select(getCheckoutOrders);
    const updatedOrdersResponse: any = yield call(() => httpClient.updateOrders(ordersFromStore));
    yield put(updateOrdersAsync.success(updatedOrdersResponse));

    if (updatedOrdersResponse && updatedOrdersResponse.length) {
      const updatedOrder = updatedOrdersResponse.filter((o: Order) => o.orderId === action.payload.orderId)[0];
      const response: any = yield call(() => httpClient.finalizeOrder(updatedOrder));

      yield put(finalizeOrderAsync.success(response));
      // todo translations https://formatjs.io/docs/react-intl/api#createintl
      const title = `Замовлення №${response.orderId} оплачено`;
      const ballanceMessage =
        response.client && response.client.balance ? ` | Баланс ${response.client.balance.toFixed(2)} ${UAH}` : '';
      const message = `Списано ${response.totalSum.toFixed(2)} ${UAH}${ballanceMessage}`;
      yield put(enqueueSnackbarSuccess({ title, message }));
    }
  } catch (err) {
    yield put(finalizeOrderAsync.failure(err as Error));
    const notEnoughBalance: string =
      (err as any).response.data === 'not enough balance' ? messages.finalizeOrdersError.defaultMessage : '';
    yield put(enqueueSnackbarError({ message: notEnoughBalance }));
  }
}

function* finalizeAllOrdersSaga(action: ReturnType<typeof finalizeAllOrdersAsync.request>): Generator {
  try {
    const ordersFromStore: any = yield select(getCheckoutOrders);
    const updatedOrdersResponse: any = yield call(() => httpClient.updateOrders(ordersFromStore));
    yield put(updateOrdersAsync.success(updatedOrdersResponse));

    if (updatedOrdersResponse && updatedOrdersResponse.length) {
      const orderWithToken = updatedOrdersResponse.filter((o: Order) => o.orderId === action.payload)[0];
      const response: any = yield call(() =>
        httpClient.finalizeAllOrders((orderWithToken && orderWithToken.token) || '')
      );
      yield put(finalizeAllOrdersAsync.success(response));
      yield put(
        push(
          `${baseUrl}/account/orders/${
            orderWithToken && orderWithToken.orderType === ORDER_TYPE_PREORDER ? 'pre-orders' : 'deliveries'
          }`
        )
      );

      if (response && response.totalSum && response.orderId && response.client.balance) {
        // todo translations https://formatjs.io/docs/react-intl/api#createintl
        const title = `"Замовлення №${response.orderId} оплачено`;
        const message = `Списано ${response.totalSum.toFixed(2)} ${UAH} | Баланс ${response.client.balance.toFixed(
          2
        )} ${UAH}`;
        yield put(enqueueSnackbarSuccess({ title, message }));
      } else {
        yield put(enqueueSnackbarSuccess({ message: messages.finalizeOrdersSuccess.defaultMessage }));
      }
    }
  } catch (err) {
    yield put(finalizeAllOrdersAsync.failure(err as Error));
    const notEnoughBalance: string =
      (err as any).response.data === 'not enough balance' ? messages.finalizeOrdersError.defaultMessage : '';
    yield put(enqueueSnackbarError({ message: notEnoughBalance }));
  }
}

function* updateOrdersSaga(action: ReturnType<typeof updateOrdersAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.updateOrders(action.payload));

    yield put(updateOrdersAsync.success(response));
  } catch (err) {
    yield put(updateOrdersAsync.failure(err as Error));
  }
}

function* finalizeTransitSaga(action: ReturnType<typeof finalizeTransitAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.finalizeTransit(action.payload));
    // close transit to order modal -> saga in cart/actions
    yield put(finalizeTransitAsync.success(response));
    if (response && response.data && response.data.orderId) {
      const title = (
        <span>
          Товар оплачено та додано до Замовлення{' '}
          <a style={{ textDecoration: 'underline' }} href={`/account/orders/${response.data.orderId}`}>
            №{response.data.orderId}
          </a>
          !
        </span>
      );
      const message = `Списано ${response.data.totalSum.toFixed(
        2
      )} ${UAH} | Баланс ${response.data.client.balance.toFixed(2)} ${UAH}`;
      yield put(enqueueSnackbarSuccess({ title, message }));
    }
  } catch (err) {
    yield put(finalizeTransitAsync.failure(err as Error));
  }
}

export function* checkoutRequestSaga() {
  yield takeEvery(renewCheckoutAsync.request, renewCheckoutSaga);
  yield takeEvery(fetchCheckoutAsync.request, checkoutSaga);
  yield takeEvery(fetchCheckoutAsync.failure, checkoutRedirectToCartSaga);

  yield takeEvery(updateOrdersAsync.request, updateOrdersSaga);
  yield takeEvery(finalizeOrderAsync.request, finalizeOrderSaga);
  yield takeEvery(finalizeAllOrdersAsync.request, finalizeAllOrdersSaga);

  yield takeEvery(renewCheckoutAsync.success, cartResetMobileTab);
  yield takeEvery(renewCheckoutAsync.success, checkoutRedirectSaga);
  yield takeEvery(finalizeOrderAsync.success, cartSaga);
  yield takeEvery(finalizeOrderAsync.success, orderSaga);
  yield takeEvery(finalizeOrderAsync.success, accountSaga);

  yield takeEvery(finalizeAllOrdersAsync.success, cartSaga);
  yield takeEvery(finalizeAllOrdersAsync.success, orderSaga);
  yield takeEvery(finalizeAllOrdersAsync.success, accountSaga);
  yield takeEvery(finalizeAllOrdersAsync.success, cartResetMobileTab);
  yield takeEvery(finalizeTransitAsync.request, finalizeTransitSaga);
  yield takeEvery(finalizeTransitAsync.success, cartSaga);
  yield takeEvery(finalizeTransitAsync.success, accountSaga);
  yield takeEvery(finalizeTransitAsync.success, cartResetMobileTab);

  yield takeEvery(cancelCheckout, cartResetMobileTab);
  yield takeEvery(cancelCheckout, checkoutRedirectToCartSaga);
}

export default createReducer(checkoutInitialState)
  .handleAction(renewCheckoutAsync.success, () => checkoutInitialState)
  .handleAction(
    setActiveOrder,
    (state: ICheckoutState, action: PayloadAction<typeof CHECKOUT_SELECT_ORDER, number>) => ({
      ...state,
      data: {
        ...state.data,
        activeOrder: action.payload
      }
    })
  )
  .handleAction(
    selectOrderItem,
    (state: ICheckoutState, action: PayloadAction<typeof CHECKOUT_SELECT_ORDER_ITEM, string>) => ({
      ...state,
      data: {
        ...state.data,
        selectedOrderItem: action.payload
      }
    })
  )
  .handleAction(unselectOrderItem, (state: ICheckoutState) => ({
    ...state,
    data: {
      ...state.data,
      selectedOrderItem: ''
    }
  }))
  .handleAction(cancelCheckout, () => checkoutInitialState)
  .handleAction(finalizeAllOrdersAsync.request, (state: ICheckoutState) => ({
    ...state,
    data: {
      ...state.data,
      finalizingOrders: true
    }
  }))
  .handleAction(finalizeAllOrdersAsync.success, () => checkoutInitialState)
  .handleAction(fetchCheckoutAsync.request, (state: ICheckoutState) => loadingDataWrapper(state.data))
  .handleAction(
    fetchCheckoutAsync.success,
    (state: ICheckoutState, action: PayloadAction<typeof CHECKOUT_REQUEST_SUCCESS, Order[]>) =>
      loadedDataWrapper({
        ...state.data,
        orders: action.payload.map(item => new Order(item))
      })
  )
  .handleAction(
    updateOrderItem,
    (state: ICheckoutState, action: PayloadAction<typeof CHECKOUT_UPDATE_ORDER_ITEM, Order>) => ({
      ...state,
      data: {
        ...state.data,
        orders: state.data.orders.map(o => (o.orderId === action.payload.orderId ? action.payload : o))
      }
    })
  )
  .handleAction(updateOrdersAsync.request, (state: ICheckoutState) => refreshDataWrapper(state.data, null, false))
  .handleAction(
    updateOrdersAsync.failure,
    (state: ICheckoutState, action: PayloadAction<typeof CHECKOUT_ORDERS_UPDATE_FAILURE, Order[]>) =>
      errorDataWrapper({ ...state.data }, new Error('Failed to update order'))
  )
  .handleAction(
    updateOrdersAsync.success,
    (state: ICheckoutState, action: PayloadAction<typeof CHECKOUT_ORDERS_UPDATE_SUCCESS, Order[]>) =>
      loadedDataWrapper({
        ...state.data,
        orders: action.payload.map(item => new Order(item))
      })
  )
  .handleAction(
    finalizeOrderAsync.request,
    (state: ICheckoutState, action: PayloadAction<typeof CHECKOUT_ORDER_FINALIZE, Order>) => {
      const order = new Order(action.payload);
      const newOrders = [...state.data.orders];
      const orderIndex = newOrders.findIndex((check: Order) => check.orderId === order.orderId);
      if (orderIndex > -1) {
        order.processing = true;
        newOrders[orderIndex] = order;
      }
      return refreshDataWrapper({
        ...state.data,
        orders: newOrders
      });
    }
  )
  .handleAction(
    finalizeOrderAsync.success,
    (state: ICheckoutState, action: PayloadAction<typeof CHECKOUT_ORDER_FINALIZE_SUCCESS, Order>) => {
      const newOrder = new Order(action.payload);
      const newOrders = [...state.data.orders].filter((check: Order) => check.orderId !== newOrder.orderId);
      const newFinalizedOrders = [...state.data.finalizedOrders];
      newFinalizedOrders.push(newOrder);
      return refreshDataWrapper({
        ...state.data,
        orders: newOrders,
        finalizedOrders: newFinalizedOrders
      });
    }
  );
