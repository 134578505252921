import React, { useState } from 'react';
import Slider, { SliderProps } from '@mui/material/Slider';
import InputAdornment from '@mui/material/InputAdornment';

import FlrInput from './textfield/FlrInput';
import { withStyles } from '@mui/styles';
import { ITheme } from 'utils/styled';
import { iOS } from 'environment';

const isIOS = iOS(window.navigator.userAgent);

export interface ISliderProps extends SliderProps {
  classes?: any;
  withInputs?: boolean;
  onChange?: (value: any) => void;
  adornment?: string;
}

const FlrSlider: React.FC<ISliderProps> = ({ classes, withInputs, ...other }) => {
  const adornment = other.adornment || '';
  const min = Math.floor(other.min || 0);
  const max = Math.ceil(other.max || 1);
  const defaultValue = (other.value as number[]) || [min, max];
  const step =
    other.step !== undefined
      ? other.step
      : Math.floor(Math.max(1, Math.min(Math.abs(defaultValue[1] - defaultValue[0]) / 10, (max - min) / 20)));
  const [value, setValue] = useState(defaultValue);
  const [valueApplied, setValueApplied] = useState(defaultValue);

  const valueProp = other.value;

  const valueString = value && value.toString();
  const valuePropString = valueProp && valueProp.toString();
  React.useEffect(() => {
    if (valueProp && Array.isArray(valueProp) && valueString !== valuePropString) {
      setValue(valueProp);
    }
    // eslint-disable-next-line
  }, [valuePropString, valueProp]);

  const handleChange = (event: Event | React.SyntheticEvent<Element, Event>, rangeValue: number | number[]): void => {
    if (event.type === 'mousedown' && isIOS) {
      return;
    }
    setValue(typeof rangeValue === 'number' ? [rangeValue] : rangeValue);
  };

  const handleRangeChanged = (
    event: Event | React.SyntheticEvent<Element, Event>,
    rangeValue: number | number[]
  ): void => {
    if (event.type === 'mouseup' && isIOS) {
      return;
    }
    if (other.onChange) {
      other.onChange(typeof rangeValue === 'number' ? [rangeValue] : rangeValue);
    }
  };

  const setTempValue = (isMin?: boolean) => (event: any) => {
    const rawValue = event.target && event.target.value;

    const valueComputed: number = +rawValue || -1;

    setValue(isMin ? [valueComputed, value[1]] : [value[0], valueComputed]);
  };

  const setMinValue = (event: any) => {
    let minValue = +(event.target && event.target.value) || min;
    if (!minValue && minValue !== 0) {
      minValue = min;
    }
    if (value[0] !== valueApplied[0]) {
      const newRange = [minValue, value[1]];
      setValueApplied(newRange);
      handleChange(event, newRange);
      handleRangeChanged(event, newRange);
    }
  };

  const setMaxValue = (event: any) => {
    let maxValue = +(event.target && event.target.value);
    if (!maxValue) {
      maxValue = max;
    }
    if (value[1] !== valueApplied[1]) {
      const newRange = [value[0], +maxValue];
      setValueApplied(newRange);
      handleChange(event, newRange);
      handleRangeChanged(event, newRange);
    }
  };

  const markValues = [min, max];
  if (!withInputs) {
    markValues.concat(...value);
  }

  const marks = other.marks
    ? other.marks
    : markValues
        .filter((v, i, a) => a.indexOf(v) === i)
        .map(price => {
          return {
            value: price,
            label: `${price}`
          };
        });

  return (
    <div>
      <Slider
        {...other}
        classes={{
          root: classes.root,
          track: classes.track,
          rail: classes.rail,
          thumb: classes.thumb,
          active: classes.active,
          mark: classes.mark,
          markLabel: classes.markLabel
        }}
        valueLabelDisplay="off"
        value={value}
        marks={marks}
        onChange={handleChange}
        onChangeCommitted={handleRangeChanged}
        step={step}
      />
      {withInputs && (
        <div style={{ display: 'flex' }}>
          <FlrInput
            min={min}
            max={value[1] - 1}
            variant="outlined"
            label={'Від'}
            placeholder={`${min}`}
            value={value[0] < 0 ? '' : value[0]}
            onChange={setTempValue(true)}
            onBlur={setMinValue}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{adornment}</InputAdornment>
            }}
          />
          <span>&nbsp;</span>
          <FlrInput
            max={max}
            min={value[0] + 1}
            variant="outlined"
            label={'До'}
            placeholder={`${max}`}
            value={value[1] < 0 ? '' : value[1]}
            onChange={setTempValue()}
            onBlur={setMaxValue}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{adornment}</InputAdornment>
            }}
          />
        </div>
      )}
    </div>
  );
};

const styles = (theme: ITheme): any => ({
  root: {
    height: 4,
    width: 'calc(100% - 24px)',
    marginLeft: 12,
    boxSizing: 'border-box'
  },
  track: {
    background: '#957BB0',
    height: 4,
    borderRadius: 2
  },
  rail: {
    height: 4,
    width: 'calc(100% + 24px)',
    marginLeft: -12,
    background: '#957BB0',
    borderRadius: 2
  },
  trackFalse: {},
  trackInverted: {},
  active: {
    boxShadow: '0px 0px 0px 8px #F1E8F7',
    '&:hover$thumb': {
      background: theme.palette.common.primaryHoverPress,
      boxShadow: '0px 0px 0px 8px #F1E8F7'
    },
    '&$thumb': {
      background: theme.palette.common.primaryHoverPress,
      boxShadow: '0px 0px 0px 8px #F1E8F7'
    }
  },
  thumb: {
    height: 24,
    width: 24,
    background: theme.palette.primary.main,

    '&:hover': {
      background: theme.palette.common.primaryHoverPress,
      boxShadow: 'inherit'
    }
  },
  mark: { display: 'none' },
  markLabel: { display: 'none' }
});

export default withStyles(styles)(FlrSlider);
