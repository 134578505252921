import { defineMessages } from 'react-intl';

export default defineMessages({
  emptyTable: {
    id: 'account.transaction.emptyTable',
    description: '',
    defaultMessage: 'Немає записів для відображення'
  },
  rows: {
    id: 'account.transaction.rows',
    description: '',
    defaultMessage: 'Pядків'
  },
  rowsOf: {
    id: 'account.transaction.rowsOf',
    description: '',
    defaultMessage: 'з'
  },
  editTooltip: {
    id: 'account.transaction.editTooltip',
    description: '',
    defaultMessage: 'Редагувати'
  },
  deleteTooltip: {
    id: 'account.transaction.deleteTooltip',
    description: '',
    defaultMessage: 'Видалити'
  },
  deleteText: {
    id: 'account.transaction.deleteText',
    description: '',
    defaultMessage: 'Ви впевнені?'
  },
  cancelTooltip: {
    id: 'account.transaction.cancelText',
    description: '',
    defaultMessage: 'Скасувати'
  },
  saveTooltip: {
    id: 'account.transaction.deleteText',
    description: '',
    defaultMessage: 'Зберегти'
  }
});
