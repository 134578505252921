import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './FooterNavGroup.module.scss';

export interface INavLinkData {
  url: string;
  title: string;
}

export interface IFooterNavGroupData {
  title: string;
  links: INavLinkData[];
}

const FooterNavGroup: FC<IFooterNavGroupData> = ({ title, links }) => (
  <div className={classes.container}>
    <h3 className={classes.heading}>{title}</h3>
    <ul className={classes.list}>
      {links.map((link, key) => (
        <li key={key} className={classes.item}>
          <NavLink to={link.url}>{link.title}</NavLink>
        </li>
      ))}
    </ul>
  </div>
);

export default FooterNavGroup;
