import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import clsx from "clsx";
import { IconButton, Collapse } from "@mui/material";
import { withStyles } from "@mui/styles";

import { Cart } from "models";
import Timer from "components/timer/Timer";
import Icon from "components/shared/Icon";
import { IApplicationState } from "store/reducers";
import { getCart } from "store/cart/selectors";
import styles from "./style";
import AlertIconWarning from "./AlertIconWarning";
import AlertIconError from "./AlertIconError";
import AlertIconSuccess from "./AlertIconSuccess";
import AlertIconInfo from "./AlertIconInfo";

interface IOwnProps {
  withTimer?: boolean;
  closable?: boolean;
  leftShift?: boolean;
  type?: "info" | "error" | "warning" | "success" | undefined;
  icon?: string;
  classes: any;
  className?: string;
  title?: React.ReactNode | string | undefined;
  action?: React.ReactNode | string | undefined;
}

interface IStateProps {
  cart: Cart;
}

interface IDispatchProps {}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const Alert: React.FC<IProps> = ({
  cart,
  classes,
  className,
  type = "info",
  icon,
  title,
  children,
  action,
  closable,
  withTimer,
  leftShift
}) => {
  const [open, setOpen] = React.useState(true);
  const isCartNotEmpty = cart.items.length > 0;

  return (
    <Collapse in={open}>
      <div
        className={clsx(classes.root, className, {
          [classes.alertInfo]: type === "info",
          [classes.alertError]: type === "error",
          [classes.alertWarning]: type === "warning",
          [classes.alertSuccess]: type === "success",
          [classes.leftShift]: leftShift,
          closable
        })}
      >
        <div className={classes.iconRoot}>
          {type === "warning" && <AlertIconWarning type={icon} />}
          {type === "error" && <AlertIconError type={icon} />}
          {type === "success" && <AlertIconSuccess type={icon} />}
          {type === "info" && <AlertIconInfo type={icon} />}
        </div>

        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            {title && <div className={classes.contentTitle}>{title}</div>}
            {children && <div className={classes.contentText}>{children}</div>}
          </div>

          {/* ACTION timer */}
          {withTimer && isCartNotEmpty && (
            <div className={classes.alertAction}>
              <Timer shouldRestart={false} end={cart.expiresOn} />
            </div>
          )}
          {!withTimer && action && <div className={classes.alertAction}>{action}</div>}
        </div>

        {closable && (
          <IconButton
            className={classes.closeButton}
            onClick={() => setOpen(false)}
            size="large">
            <Icon type={"cross"} size={12} />
          </IconButton>
        )}
      </div>
    </Collapse>
  );
};

// export default withStyles<any>(styles)(Alert);
const mapStateToProps = (state: IApplicationState) => ({
  cart: getCart(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(Alert as any));
