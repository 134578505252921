import { createReducer, PayloadAction, createAsyncAction, createAction } from "typesafe-actions";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  loadedDataWrapper,
  REQUEST_ACTIONS,
  ADD_REQUEST_ACTIONS,
  UPDATE_REQUEST_ACTIONS,
  REMOVE_REQUEST_ACTIONS,
  IAsyncDataWrapper,
  refreshDataWrapper,
  loadingDataWrapper,
  errorDataWrapper
} from "store/actions";
import { cartSaga, ICartTime, ICartTimer, setCartMobileTab } from "store/cart/actions";
import { ExpiredCartRepository } from "./request";

import { Cart } from "models/cart";
import { CartItem, IRequestCartItem } from "models/cart-item";
import { enqueueSnackbarError, enqueueSnackbarSuccess } from "../layout";

import messages from "translations/cart/common";

export const prefix = "@@expired-cart/";

export const EXPIRED_CART_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const EXPIRED_CART_REQUEST_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const EXPIRED_CART_REQUEST_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

export const EXPIRED_CART_RESTORE = `${prefix}${ADD_REQUEST_ACTIONS.REQUEST}`;
export const EXPIRED_CART_RESTORE_SUCCESS = `${prefix}${ADD_REQUEST_ACTIONS.SUCCESS}`;
export const EXPIRED_CART_RESTORE_FAILURE = `${prefix}${ADD_REQUEST_ACTIONS.FAILURE}`;

export const EXPIRED_CART_REMOVE = `${prefix}${REMOVE_REQUEST_ACTIONS.REQUEST}`;
export const EXPIRED_CART_REMOVE_SUCCESS = `${prefix}${REMOVE_REQUEST_ACTIONS.SUCCESS}`;
export const EXPIRED_CART_REMOVE_FAILURE = `${prefix}${REMOVE_REQUEST_ACTIONS.FAILURE}`;

export const EXPIRED_CART_GROUP_REMOVE = `${prefix}group/${REMOVE_REQUEST_ACTIONS.REQUEST}`;
export const EXPIRED_CART_GROUP_REMOVE_SUCCESS = `${prefix}group/${REMOVE_REQUEST_ACTIONS.SUCCESS}`;
export const EXPIRED_CART_GROUP_REMOVE_FAILURE = `${prefix}group/${REMOVE_REQUEST_ACTIONS.FAILURE}`;

export const EXPIRED_CART_UPDATE_ITEM = `${prefix}item/${UPDATE_REQUEST_ACTIONS.REQUEST}`;
export const EXPIRED_CART_UPDATE_ITEM_SUCCESS = `${prefix}item/${UPDATE_REQUEST_ACTIONS.SUCCESS}`;
export const EXPIRED_CART_UPDATE_ITEM_FAILURE = `${prefix}item/${UPDATE_REQUEST_ACTIONS.FAILURE}`;

export const EXPIRED_CART_REMOVE_ITEM = `${prefix}item/${REMOVE_REQUEST_ACTIONS.REQUEST}`;
export const EXPIRED_CART_REMOVE_ITEM_SUCCESS = `${prefix}item/${REMOVE_REQUEST_ACTIONS.SUCCESS}`;
export const EXPIRED_CART_REMOVE_ITEM_FAILURE = `${prefix}item/${REMOVE_REQUEST_ACTIONS.FAILURE}`;

export const EXPIRED_CART_SET_TIMER = `${prefix}SET_TIMER`;
export const EXPIRED_CART_RESET = `${prefix}RESET`;

interface IExpiredCart {
  expiredCart: Cart;
  timer: ICartTimer;
}

export type IExpiredCartState = IAsyncDataWrapper<IExpiredCart>;

export const expiredCartInitialState: IExpiredCartState = {
  loading: false,
  loaded: false,
  data: {
    expiredCart: new Cart({
      items: []
    }),
    timer: {
      time: null
    }
  },
  error: null
};

export const fetchExpiredCartAsync = createAsyncAction(
  EXPIRED_CART_REQUEST,
  EXPIRED_CART_REQUEST_SUCCESS,
  EXPIRED_CART_REQUEST_FAILURE
)<void, Cart, Error>();

export const restoreExpiredCartAsync = createAsyncAction(
  EXPIRED_CART_RESTORE,
  EXPIRED_CART_RESTORE_SUCCESS,
  EXPIRED_CART_RESTORE_FAILURE
)<string, string, Error>();

export const removeExpiredCartAsync = createAsyncAction(
  EXPIRED_CART_REMOVE,
  EXPIRED_CART_REMOVE_SUCCESS,
  EXPIRED_CART_REMOVE_FAILURE
)<void, void, Error>();

export const removeExpiredCartGroupAsync = createAsyncAction(
  EXPIRED_CART_GROUP_REMOVE,
  EXPIRED_CART_GROUP_REMOVE_SUCCESS,
  EXPIRED_CART_GROUP_REMOVE_FAILURE
)<string, void, Error>();

export const updateExpiredCartItemAsync = createAsyncAction(
  EXPIRED_CART_UPDATE_ITEM,
  EXPIRED_CART_UPDATE_ITEM_SUCCESS,
  EXPIRED_CART_UPDATE_ITEM_FAILURE
)<IRequestCartItem, CartItem, Error>();

export const removeExpiredCartItemAsync = createAsyncAction(
  EXPIRED_CART_REMOVE_ITEM,
  EXPIRED_CART_REMOVE_ITEM_SUCCESS,
  EXPIRED_CART_REMOVE_ITEM_FAILURE
)<IRequestCartItem, void, Error>();

export const resetExpiredCart = createAction(EXPIRED_CART_RESET)<void>();
export const setExpiredCartTimer = createAction(EXPIRED_CART_SET_TIMER)<ICartTime>();

const httpClient = new ExpiredCartRepository();

function* expiredCartResetSaga(): Generator {
  yield put(resetExpiredCart());
}

export function* expiredCartSaga(
  action: ReturnType<
    | typeof fetchExpiredCartAsync.request
    | typeof restoreExpiredCartAsync.success
    | typeof updateExpiredCartItemAsync.success
  >
): Generator {
  try {
    const response: any = yield call(() => httpClient.fetch());

    yield put(fetchExpiredCartAsync.success(response));
  } catch (err) {
    yield put(fetchExpiredCartAsync.failure(err as Error));
  }
}

function* expiredCartRestoreSaga(action: ReturnType<typeof restoreExpiredCartAsync.request>): Generator {
  try {
    yield call(() => httpClient.restore(action.payload));
    yield put(restoreExpiredCartAsync.success(action.payload));
    yield put(enqueueSnackbarSuccess({ message: messages.expiredCartRestore.defaultMessage }));
  } catch (err) {
    const message =
      (err as any).response && (err as any).response.data === "out-of-stock" && messages.expiredCartOutOfStock.defaultMessage;
    yield put(restoreExpiredCartAsync.failure(err as Error));
    yield put(enqueueSnackbarError({ message }));
  }
}

function* expiredCartGroupRemoveSaga(action: ReturnType<typeof removeExpiredCartGroupAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.removeGroup(action.payload));

    yield put(removeExpiredCartGroupAsync.success(response));
    yield put(enqueueSnackbarSuccess({ message: messages.notificationExpiredCartRemoved.defaultMessage }));
  } catch (err) {
    yield put(removeExpiredCartGroupAsync.failure(err as Error));
    yield put(enqueueSnackbarError());
  }
}

function* expiredCartRemoveSaga(): Generator {
  try {
    const response: any = yield call(() => httpClient.remove());

    yield put(removeExpiredCartAsync.success(response));
    yield put(enqueueSnackbarSuccess({ message: messages.notificationExpiredCartRemoved.defaultMessage }));
  } catch (err) {
    yield put(removeExpiredCartAsync.failure(err as Error));
    yield put(enqueueSnackbarError());
  }
}

function* expiredCartItemUpdateSaga(action: ReturnType<typeof updateExpiredCartItemAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.updateItem(action.payload));

    yield put(updateExpiredCartItemAsync.success(response));
  } catch (err) {
    yield put(updateExpiredCartItemAsync.failure(err as Error));
  }
}

function* expiredCartItemRemoveSaga(action: ReturnType<typeof removeExpiredCartItemAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.removeItem(action.payload));

    yield put(removeExpiredCartItemAsync.success(response));
  } catch (err) {
    yield put(removeExpiredCartItemAsync.failure(err as Error));
  }
}

export function* expiredCartTabsCallbackSaga(): Generator {
  yield put(setCartMobileTab(-1));
}

export function* expiredCartRequestSaga() {
  yield takeEvery(fetchExpiredCartAsync.request, expiredCartSaga);
  yield takeEvery(restoreExpiredCartAsync.request, expiredCartRestoreSaga);
  yield takeEvery(removeExpiredCartAsync.request, expiredCartRemoveSaga);
  yield takeEvery(updateExpiredCartItemAsync.request, expiredCartItemUpdateSaga);
  yield takeEvery(removeExpiredCartItemAsync.request, expiredCartItemRemoveSaga);
  yield takeEvery(removeExpiredCartGroupAsync.request, expiredCartGroupRemoveSaga);

  yield takeEvery(restoreExpiredCartAsync.success, expiredCartSaga);
  yield takeEvery(restoreExpiredCartAsync.success, cartSaga);
  yield takeEvery(restoreExpiredCartAsync.success, expiredCartTabsCallbackSaga);
  yield takeEvery(removeExpiredCartAsync.success, expiredCartSaga);
  yield takeEvery(updateExpiredCartItemAsync.success, expiredCartSaga);
  yield takeEvery(removeExpiredCartItemAsync.success, expiredCartSaga);
  yield takeEvery(removeExpiredCartGroupAsync.success, expiredCartSaga);

  yield takeEvery(resetExpiredCart, expiredCartResetSaga);
}

export default createReducer(expiredCartInitialState)
  // expired cart layout actions
  .handleAction(
    EXPIRED_CART_SET_TIMER,
    (state: IExpiredCartState, action: PayloadAction<typeof EXPIRED_CART_SET_TIMER, ICartTimer>) =>
      refreshDataWrapper({
        ...state.data,
        timer: {
          time: action.payload
        }
      })
  )

  // expired cart
  .handleAction(fetchExpiredCartAsync.request, (state: IExpiredCartState) => loadingDataWrapper(state.data))
  .handleAction(
    fetchExpiredCartAsync.success,
    (state: IExpiredCartState, action: PayloadAction<typeof EXPIRED_CART_REQUEST_SUCCESS, Cart>) =>
      loadedDataWrapper({
        ...state.data,
        expiredCart: new Cart(action.payload)
      })
  )
  .handleAction(fetchExpiredCartAsync.failure, (state: IExpiredCartState) =>
    errorDataWrapper(state.data, new Error("Failed to load expired cart"))
  )

  // expired cart global actions
  .handleAction(restoreExpiredCartAsync.request, () => refreshDataWrapper(expiredCartInitialState.data))
  .handleAction(
    restoreExpiredCartAsync.success,
    (state: IExpiredCartState, action: PayloadAction<typeof EXPIRED_CART_RESTORE_SUCCESS, string>) => {
      const newStateData = { ...state.data };
      const cartGroups = state.data.expiredCart.groups;
      const { [action.payload]: excludedGroup, ...other } = cartGroups;
      newStateData.expiredCart.groups = other;
      return loadedDataWrapper(newStateData);
      /*
      const expiredCartGroupKeys = Object.keys(cartGroups);
      const groupsLeft = expiredCartGroupKeys.filter((key: string) => key !== action.payload).reduce((groups, groupKey) => groups[groupKey] = groups, {});
      loadedDataWrapper({
        ...state.data,
        expiredCart: new Cart(action.payload)
      })
      return loadedDataWrapper(state);*/
    }
  )
  .handleAction(restoreExpiredCartAsync.success, () => expiredCartInitialState)
  .handleAction(restoreExpiredCartAsync.failure, () => expiredCartInitialState)

  .handleAction(removeExpiredCartAsync.request, () => refreshDataWrapper(expiredCartInitialState.data))
  .handleAction(removeExpiredCartAsync.success, () => expiredCartInitialState)

  // update expired cart item
  .handleAction(
    updateExpiredCartItemAsync.request,
    (state: IExpiredCartState, action: PayloadAction<typeof EXPIRED_CART_UPDATE_ITEM, CartItem>) =>
      refreshDataWrapper({
        ...state.data,
        expiredCart: state.data.expiredCart.update(action.payload)
      })
  )
  .handleAction(updateExpiredCartItemAsync.success, (state: IExpiredCartState) => loadedDataWrapper(state.data))
  .handleAction(updateExpiredCartItemAsync.failure, (state: IExpiredCartState) =>
    errorDataWrapper(state.data, new Error("Failed to update expired cart item"), false, true)
  )

  // remove expired cart item
  .handleAction(
    removeExpiredCartItemAsync.request,
    (state: IExpiredCartState, action: PayloadAction<typeof EXPIRED_CART_REMOVE_ITEM, CartItem>) =>
      refreshDataWrapper({
        ...state.data,
        expiredCart: state.data.expiredCart.remove(action.payload)
      })
  )
  .handleAction(removeExpiredCartItemAsync.success, (state: IExpiredCartState) => loadedDataWrapper(state.data))
  .handleAction(removeExpiredCartItemAsync.failure, (state: IExpiredCartState) =>
    errorDataWrapper(state.data, new Error("Failed to remove expired cart item"), false, true)
  );
