import { eternal } from "shared/constants";

export function setCookie(cname: string, cvalue: string, hours: number = eternal) {
  const d = new Date();
  d.setTime(d.getTime() + hours * 60 * 60 * 1000); // (exdays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname: string): string {
  const name = cname + "=";
  const ca = document.cookie.split(";");

  for (let c of ca) {
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
}

export function checkCookie() {
  const user = getCookie("token");
  if (user !== "") {
    return user;
  } else {
    return null;
  }
}
