import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { defaultMaterialTheme, ITheme } from 'utils/styled';

const slickThumb = {
  '& .slick-thumb li img': {
    filter: 'opacity(.3)'
  },

  '& .slick-thumb li.slick-active img': {
    filter: 'opacity(1)'
  }
};

const styles = (theme: any) => ({
  mainSliderImg: {
    objectFit: 'cover',
    minHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minHeight: '340px'
    }
  },
  wrapper: {
    position: 'relative',
    maxWidth: '1410px',
    '@media (max-width: 1400px)': {
      maxWidth: '1150px',
      maxHeight: '690px'
    },
    '@media (max-width: 720px)': {
      maxWidth: '100%',
      position: 'static'
    }
  },
  attributeName: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '22px',
    margin: 0
  },
  attributeContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 4
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '24px',
    color: theme.palette.text.primary,
    position: 'absolute',
    zIndex: 1,
    top: 64,
    right: 96,
    width: 272,
    textAlign: 'center',
    '@media (max-width: 720px)': {
      display: 'none'
    }
  },
  attrContainer: {
    position: 'absolute',
    zIndex: 1,
    top: 96,
    width: 272,
    right: 96,
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    '@media (max-width: 720px)': {
      display: 'none'
    }
  },
  colorCircle: {
    margin: 0
  },
  closeIcon: {
    zIndex: 1,
    position: 'absolute',
    top: 18,
    right: 22,
    cursor: 'pointer',
    '@media (max-width: 720px)': {
      display: 'none'
    }
  },
  thumbnail: {
    '&.image-gallery-thumbnail+.image-gallery-thumbnail': {
      marginLeft: 0
    },
    position: 'relative',
    width: 80,
    height: 80,
    '&.active': {
      border: '3px solid #8e3fbe',
      borderRadius: 8
    },
    '&:hover': {
      '@media (min-width: 720px)': {
        borderColor: '#8e3fbe',
        borderRadius: 8,
        cursor: 'pointer',
      }
    },
    '& > span ': {
      height: '100%',
      '& > img': {
        height: '100%',
        borderRadius: 4,
        objectFit: 'cover'
      }
    },
    '@media (max-width: 720px)': {
      width: 104,
      height: 104
    }
  },
  videoPlayIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 1000,
    transform: 'translate(-50%, -50%)'
  },
  imageGallery: {
    '& .image-gallery-content': {
      '@media (min-width: 720px)': {
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
        padding: '64px 96px',
        borderRadius: 8
      }
    },
    '& .image-gallery-slides': {
      '@media (min-width: 720px)': {
        textAlign: 'left'
      }
    },
    '& .image-gallery-slide img': {
      width: '100%',
      borderRadius: 8,
      height: '100%',
      objectFit: 'cover',
      maxHeight: 740,
      maxWidth: 900,
      '@media (max-width: 1400px)': {
        maxWidth: 620,
        maxHeight: 510
      },
      '@media (max-width: 720px)': {
        maxWidth: '100%',
        maxHeight: '310px',
      }
    },
    '& .image-gallery-thumbnails-wrapper': {
      '@media (min-width: 720px)': {
        maxWidth: '272px',
        width: '100%',
        margin: '0 0 0 46px'
      }
    },
    '& div.image-gallery-slide-wrapper': {
      '@media (min-width: 720px)': {
        width: 'auto'
      },
      '& button': {
        '&:focus': {
          outline: 'none'
        }
      }
    },
    '& .image-gallery-thumbnails': {
      paddingTop: 16,
      '& > nav': {
        textAlign: 'left',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 16,
      },
      '@media (min-width: 720px)': {
        marginTop: 88
      }
    },
    '@media (max-width: 720px)': {
      height: '100vw'
    }
  },
  video: {
    borderRadius: '8px',
    minWidth: '900px',
    minHeight: '740px',
    '@media (max-width: 1400px)': {
      minWidth: '620px',
      minHeight: '510px'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      minHeight: '340px',
      pointerEvents: 'auto'
    }
  },
  card: {
    '& >div': {
      height: 'auto !important',
      padding: '24px 24px 0',
      [theme.breakpoints.down('md')]: {
        padding: '8px 15px 0'
      }
    }
  },
  quantityContainer: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    paddingTop: 10,
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.cardBlackBackground}`,
    borderRadius: theme.spacing(1),
    ...theme.shadow.popoverDroplist,

    [theme.breakpoints.down('sm')]: {
      '&&&': {
        position: 'fixed',
        bottom: 0,
        zIndex: 1,
        width: '100%',
        margin: 0,

        borderRadius: 0,
        border: '1px solid rgba(0, 0, 0, 0.02)',
        ...theme.shadow.cardHover
      }
    }
  },
  quantityTitle: {
    marginBottom: 10,
    color: theme.palette.text.secondary
  },
  offersSwitch: {
    marginRight: 0
  },
  offersSort: {
    width: '100%',

    [defaultMaterialTheme.breakpoints.down('sm')]: {
      marginLeft: -8
    },

    '& .MuiInputBase-input .flr-icon': {
      minWidth: 24
    },
    '& .MuiInputBase-input .MuiTypography-body1': {
      fontSize: theme.fontSizes.body1,
      fontWeight: 400,
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },
  offersSortIcon: {
    color: theme.palette.text.secondary
  },
  offersEmpty: {
    marginTop: theme.spacing(2),
    color: theme.palette.common.helperBlack
  },
  offer: {
    margin: '0 0 16px 0',
    '&:last-of-type': {
      margin: '0 0 -8px 0'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '0 0 8px 0'
    }
  },
  offerContainer: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),

    [theme.breakpoints.down('lg')]: {
      '&&&': {
        marginBottom: 0
      }
    },

    [defaultMaterialTheme.breakpoints.down('sm')]: {
      marginLeft: 14,
      marginRight: theme.spacing(2)
    }
  },
  offerTitle: {
    marginTop: theme.spacing(4),
    marginBottom: 6
  },
  loginToSeeOffers: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),

    color: theme.palette.common.black
  },
  loginButton: {
    marginTop: theme.spacing(2.5)
  },
  registrationButton: {
    marginTop: theme.spacing(2)
  },
  totalInCart: {
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(4),
    background: theme.palette.text.primary,
    borderRadius: 4
  },
  totalInCartIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.secondary.main
  },
  totalInCartIconData: {
    flex: 1,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    display: 'flex',
    justifyContent: 'space-between',

    '& > .MuiTypography-root': {
      color: theme.palette.common.white
    }
  },
  galleryScrollBody: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    padding: 0,
    marginLeft: '0 !important',
    marginRight: '0 !important',
    marginTop: '0 !important',
    marginBottom: '0 !important',
    maxWidth: '100% !important',
    boxShadow: 'none',
    background: 'transparent',
    height: '100vh'
  },
  galleryContent: {
    ...slickThumb,
    marginBottom: 0,
    padding: 0,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',

    '& > div:first-child': {
      flex: 1
    },
    '& .react-transform-component': {
      minHeight: 740,
      minWidth: 900,
      '@media (max-width: 1400px)': {
        minWidth: 620,
        minHeight: 510
      },
      '@media (max-width: 720px)': {
        minWidth: '100%',
        minHeight: '310px'
      }
    },
    '& .slick-thumb': {
      display: 'flex !important',
      padding: 0,
      margin: 0,
      listStyle: 'none',
      textAlign: 'center',
      overflowX: 'scroll',
      overflowY: 'hidden',
      transform: 'translateY(-100%)',
      justifyContent: 'center'
    },

    '& .slick-thumb li': {
      width: 80,
      height: 80,
      flex: 'none',
      position: 'relative',
      display: 'inline-block',
      margin: '5px 5px 10px',
      padding: 0,

      [theme.breakpoints.up('sm')]: {
        width: 140,
        height: 140
      }
    },

    '&:first-child': {
      padding: 0,
      height: '100%'
    },

    '& > .slick-slider': {
      height: 'inherit'
    },

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      padding: '0 16px 0 15px',
      '& .react-transform-wrapper': {
        height: '80vh'
      },

      '& .react-transform-component': {
        width: '100%',
        height: '100%'
      }
    }
  },
  navSliderImg: {
    maxHeight: 64,
    objectFit: 'contain'
  },
  imageContainer: {
    height: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    position: 'relative'
  },
  addressWrapper: {
    width: '100%',
    marginTop: 15,
    marginBottom: 10,
    backgroundColor: theme.palette.common.cardBlackBackgroundHex,
    borderRadius: 8,
    padding: '10px 5px',
    textAlign: 'center',

    '& > *': {
      margin: 0,
      padding: 0
    }
  },
  paperWidthSm: {
    width: '100% !important'
  },
  dialogTitle: {
    padding: '0 0 78px',
    width: '100%',
    '@media(min-width: 720px)': {
      display: 'none'
    }
  },
  closeButton: {
    color: '#fff',
    position: 'relative'
  },
  dialogRoot: {
    '& > .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0.6)',
      '@media(max-width: 720px)': {
        backgroundColor: '#fff'
      }
    }
  },
  scrollBody: {
    overflowY: 'hidden'
  },

  containerClassName: {
    padding: 0,
    height: '70vh'
  },
  filterSortContainer: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',

    [defaultMaterialTheme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  checkFilterContainer: {
    marginLeft: 6,

    [defaultMaterialTheme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }
});

export const useModalStyles = makeStyles((theme: ITheme) =>
  createStyles({
    dialogContainer: {
      padding: '64px 96px',
      overflowY: 'visible',

      [defaultMaterialTheme.breakpoints.down('md')]: {
        padding: theme.spacing(4)
      },
      [defaultMaterialTheme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),

        '&.MuiDialog-paperScrollBody': {
          maxWidth: 'initial',
          width: '100%',
          height: '100%',
          margin: 0,
          paddingTop: 78,
          overflowY: 'auto'
        }
      }
    },
    closeButton: {
      [defaultMaterialTheme.breakpoints.down('sm')]: {
        left: 22,
        right: 'auto'
      }
    },
    alertContainer: {
      margin: '0px -96px 0 -100px',
      [defaultMaterialTheme.breakpoints.down('sm')]: {
        margin: '0px -16px 0 -20px'
      }
    },
    rowContainer: {
      textAlign: 'center',
      marginBottom: theme.spacing(2),
      gap: 2,
      [defaultMaterialTheme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1)
      }
    },
    transitProductContainer: {
      marginBottom: theme.spacing(3),
      fontSize: theme.fontSizes.small,
      flexWrap: 'nowrap',

      [defaultMaterialTheme.breakpoints.down('sm')]: {
        flexWrap: 'wrap'
      }
    },
    transitProductContainerMobile: {
      [defaultMaterialTheme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0)
      }
    },
    transitProductColumnTitle: {
      fontSize: theme.fontSizes.tiny,
      color: theme.palette.common.helperBlack,
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(0.5)
    },
    whiteSpaceWrap: {
      whiteSpace: 'initial',
      width: 110
    },
    titleSecondary: {
      textAlign: 'center',
      marginBottom: theme.spacing(4),
      '@media(max-width: 720px)': {
        marginBottom: 24,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '14px',
        color: '#666'
      }
    },
    transitModalBottomText: {
      fontSize: theme.fontSizes.medium,
      textAlign: 'center',
      marginBottom: theme.spacing(4),
      '@media(max-width: 720px)': {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '14px',
        color: '#333',
        marginBottom: 24
      }
    },
    transitToOrderBottomText: {
      marginBottom: theme.spacing(3),
      color: theme.palette.text.secondary,
      [defaultMaterialTheme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
        textAlign: 'center'
      }
    },
    productName: {
      fontSize: theme.fontSizes.medium,
      fontWeight: '500',
      '@media(max-width: 720px)': {
        marginBottom: 2
      }
    },
    actionButtonsContainer: {
      justifyContent: 'flex-end',

      [defaultMaterialTheme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        flexDirection: 'column-reverse'
      }
    },
    actionButton: {
      [defaultMaterialTheme.breakpoints.down('sm')]: {
        width: '100%',
        fontWeight: 700,

        '&:not(:first-of-type)': {
          margin: '0 0 16px 0'
        }
      }
    },
    actionButtonCancel: {
      marginRight: 'auto'
    },
    transitOrderHint: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    optionContainer: {
      textAlign: 'initial'
    },
    sumContainer: {
      alignItems: 'flex-end',
      '@media(max-width: 720px)': {
        alignItems: 'flex-start'
      }
    },
    quantityContainer: {
      width: '100%',
      '& .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root': {
        width: '100%'
      }
    },
    infoDelivery: {
      marginBottom: 24
    },
    transitOfferTitle: {
      padding: 0,
      marginBottom: 16,
      '@media(max-width: 720px)': {
        marginBottom: 8
      }
    },
    transitItemInfo: {
      alignItems: 'flex-end',
      '@media(max-width: 720px)': {
        width: 'auto'
      }
    },
    transitOfferPrice: {
      '& > *': {
        fontWeight: 700
      }
    },
    perhapsDeliveryDate: {
      '@media(max-width: 720px)': {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '16px',
        color: '#333'
      }
    },
    perhapsDeliveryDateLast: {
      '@media(max-width: 720px)': {
        color: '#666',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '14px'
      }
    },
    priceWithDiscount: {
      '@media(max-width: 720px)': {
        '&>*': {
          fontWeight: 700
        }
      }
    }
  })
);

export default styles;
