import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import * as React from 'react';
import clsx from 'clsx';

import { ITheme, IThemed } from 'utils/styled';

interface IProps {
  children: React.ReactNode;
  classes?: any;
  dark?: boolean;
  onClick?: () => void;
  className?: any;
}

// const ButtonCommon: React.FC<IProps> = ({ children, classes, dark }) => {
//   return (
//     <button
//       className={clsx(classes.buttonCommon, {
//         [classes.buttonCommonDark]: dark
//       })}
//     >
//       {children}
//     </button>
//   );
// };
// type ButtonProps = React.HTMLProps<HTMLButtonElement>;

const ButtonCommon: React.FC<IProps> = React.forwardRef<HTMLButtonElement, IProps>(
  ({ children, classes, dark, className, ...rest }, ref) => {
    return (
      <Button
        variant={'text'}
        ref={ref}
        className={clsx(classes.buttonCommon, {
          [classes.buttonCommonDark]: dark,
          [className]: !!className
        })}
        {...rest}
      >
        {children}
      </Button>
    );
  }
);

const styles = (theme: ITheme) => ({
  buttonCommon: {
    // padding: 0,
    height: 24,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    textTransform: 'none',
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    outline: 'none',
    whiteSpace: 'nowrap',

    '&:hover p,&:hover svg, &:hover span': {
      color: theme.palette.primary.main
    },
    '&:active p,  &:active svg, &:active span': {
      color: theme.palette.common.primaryHoverPress
    },
    '&:active': {
      backgroundColor: theme.palette.common.primaryClickablePressBackground
    }
  },
  buttonCommonDark: {
    '&&&&': {
      color: theme.palette.common.white,

      '&:focus p, &:focus svg, &:focus span': {
        borderColor: theme.palette.secondary.main
      },
      '&:hover p, &:hover svg, &:hover span': {
        color: theme.palette.secondary.main
      },
      '&:active p, &:active svg, &:active span': {
        color: theme.palette.secondary.main
      },
      '&:active': {
        backgroundColor: theme.palette.common.primaryHoverPress
      }
    }
  }
});

export default withStyles<any>(styles)(ButtonCommon);

export const StyledButtonCommon = styled<any>('div')(({ theme }: IThemed) => ({
  '&&': {
    cursor: 'pointer',
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    gap: 2,
    '&:hover p, &:hover svg, &:hover span': {
      color: theme.palette.primary.main
    },
    '&:active p, &:active svg, &:active span': {
      color: theme.palette.common.primaryHoverPress
    },
    '&:active': {
      backgroundColor: theme.palette.common.primaryClickablePressBackground
    },
  }
}));
