import { IFilterOptions, IFilterProductCatalogCategory } from 'models';
import { countriesMap } from 'shared/countriesMap';
import {
  ATTRIBUTE_COUNTRY_PATH,
  ATTRIBUTE_HEIGHT_PATH,
  ATTRIBUTE_PRICE_PATH,
  FILTER_CATEGORY_PATH
} from 'shared/filters';
import { IFilterProp, IFilterProps } from 'store/catalog/actions';
import { filtersMessages } from 'translations/catalog/filters';

export interface IParamFilterObject {
  fast: IFilterProp[];
  inStock: boolean | undefined;
  preOrder: boolean | undefined;
  discount: boolean | undefined;
  supplier: string | undefined;
  product: string | undefined;
  specialOffers: boolean | undefined;
  transitOffers: boolean | undefined;
  specialTransitOffers: boolean | undefined;
  deliveryRegion: string | undefined;
}

export function getFilterLabel(item: IFilterProp, filterOptionsData: IFilterOptions): string {
  if (item.path === ATTRIBUTE_COUNTRY_PATH && countriesMap[item.value]) {
    return countriesMap[item.value].name;
  }

  if (item.path === 'attributes.color' && item.value === '') {
    return filtersMessages.colorFilterOther.defaultMessage;
  }

  if (item.path === 'category') {
    const flat = flatCategories(filterOptionsData.catalogCategory);
    const category = flat.find((itemCategory) => itemCategory.code === item.value);

    if (category && category.name) {
      return category.name;
    } else {
      return '';
    }
  }

  return item.label || item.value;
}

export function flatCategories(array: IFilterProductCatalogCategory[]): IFilterProductCatalogCategory[] {
  let result: any[] = [];
  array.forEach((item) => {
    result.push(item);
    if (Array.isArray(item.items)) {
      result = result.concat(flatCategories(item.items));
    }
  });
  return result;
}

export function filterStringToJson(
  str: string,
  categoryArray: IFilterProductCatalogCategory[] | undefined
): IParamFilterObject {
  const array = str.split(';');

  const categoryMap = {};
  if (categoryArray?.length) {
    const flatCategoryArray: IFilterProductCatalogCategory[] = flatCategories(categoryArray);
    flatCategoryArray.forEach((item) => {
      categoryMap[item.code] = { label: item.name, item };
    });
  }

  const fast: any[] = [];
  let inStock;
  let product;
  let preOrder;
  let supplier;
  let discount;
  let specialOffers;
  let transitOffers;
  let specialTransitOffers;
  let deliveryRegion;
  array.forEach((filter: string) => {
    const [key, values] = filter.split('=');
    if (!key) {
      return;
    }
    if (key === 'inStock') {
      return (inStock = !!+values);
    }
    if (key === 'product') {
      return (product = values);
    }
    if (key === 'preOrder') {
      return (preOrder = !!+values);
    }
    if (key === 'supplier') {
      return (supplier = values);
    }
    if (key === 'deliveryRegion') {
      return (deliveryRegion = values);
    }
    if (key === 'discount') {
      return (discount = values);
    }
    if (key === 'specialOffers') {
      return (specialOffers = !!+values);
    }
    if (key === 'transitOffers') {
      return (transitOffers = !!+values);
    }
    if (key === 'specialTransitOffers') {
      return (specialTransitOffers = !!+values);
    }

    values.split(',').forEach((value) => {
      const rule = [ATTRIBUTE_HEIGHT_PATH, ATTRIBUTE_PRICE_PATH].indexOf(key) > -1 ? 'range' : 'include';

      const filterItem: any = {
        rule,
        path: key,
        value
      };
      if (key === ATTRIBUTE_COUNTRY_PATH) {
        filterItem.label = (countriesMap[value] && countriesMap[value].name) || value;
      }

      if (key === FILTER_CATEGORY_PATH && categoryArray) {
        if (categoryMap[value] && categoryMap[value].item.items && categoryMap[value].item.items.length) {
          fast.push({
            rule: 'include',
            path: FILTER_CATEGORY_PATH,
            value: categoryMap[value].item.code,
            label: categoryMap[value].item.name || categoryMap[value].item.code
          });
          const allChildren = getAllCategoryChildrenFilters(categoryMap[value].item.items, categoryMap);
          fast.push(...allChildren);
        } else {
          // if no children add particular category
          filterItem.label = (categoryMap[value] && categoryMap[value].label) || value;
          fast.push(filterItem);
        }
        return;
      }
      fast.push(filterItem);
    });
  });

  return {
    fast,
    inStock,
    product,
    preOrder,
    supplier,
    discount,
    specialOffers,
    transitOffers,
    specialTransitOffers,
    deliveryRegion
  };
}

function getAllCategoryChildrenFilters(items: IFilterProductCatalogCategory[], categoryMap: any): IFilterProp[] {
  const arr: IFilterProp[] = [];

  const filterItem: any = {
    rule: 'include',
    path: FILTER_CATEGORY_PATH
  };

  items.forEach((item) => {
    if (Boolean(item.productsCount)) {
      // check if category is already exist in filters
      if (arr.some((i) => i.value === item.code)) {
        if (item.items && item.items.length) {
          arr.push(...getAllCategoryChildrenFilters(item.items, categoryMap));
        }

        arr.push({ ...filterItem, value: item.code, label: categoryMap[item.code].label });
      }
    }
  });
  return arr;
}

export function filterJsonToString(json: object): string {
  const s: string[] = [];
  Object.keys(json).forEach((itemKey) => {
    const value = json[itemKey];
    s.push(`${itemKey}=${value.join ? value.join(',') : value}`);
  });

  return s.join(';');
}

export function filtersToString(filters: IFilterProps) {
  const filterMap = {};
  filters.forEach((item) => {
    if (!filterMap[item.path]) {
      filterMap[item.path] = [];
    }

    filterMap[item.path].push(item.value);
  });

  return filterMap;
}
